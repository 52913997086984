var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { createActionIcon, changeObjectTexture } from './three';
import { defaultContactEntryFormState } from './contactEntryTypes';
import { defaultFormFieldFormState } from './formFields';
import mediaUrl from './mediaUrl';
var getActionIconUrl = function (action, buttons, showStopIcon) {
    if (showStopIcon === void 0) { showStopIcon = false; }
    if (action.audioData && showStopIcon) {
        return action.audioData.stopicon;
    }
    if (action.button) {
        return action.button.image;
    }
    return buttons.find(function (_a) {
        var id = _a.id;
        return id === action.buttonId;
    }).image;
};
export var createAction = function (_a) {
    var action = _a.action, buttons = _a.buttons, authType = _a.authType, t = _a.t, styling = _a.styling, showStopIcon = _a.showStopIcon;
    return __awaiter(void 0, void 0, void 0, function () {
        var iconUrl;
        return __generator(this, function (_b) {
            iconUrl = mediaUrl(getActionIconUrl(action, buttons, showStopIcon), 'thumbnail');
            return [2 /*return*/, createActionIcon(action, iconUrl, authType, t, styling)];
        });
    });
};
export var changeIconTexture = function (_a) {
    var action = _a.action, object = _a.object, authType = _a.authType, showStopIcon = _a.showStopIcon;
    return __awaiter(void 0, void 0, void 0, function () {
        var buttons, iconUrl;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    buttons = [{ id: action.button.id }];
                    iconUrl = mediaUrl(getActionIconUrl(action, buttons, showStopIcon), 'thumbnail');
                    return [4 /*yield*/, changeObjectTexture(object, iconUrl, authType)];
                case 1:
                    _b.sent();
                    return [2 /*return*/];
            }
        });
    });
};
export var cancelVideo = function (video) {
    // see https://stackoverflow.com/a/28060352
    video.pause();
    video.removeAttribute('src');
    video.load();
};
export var actionTypes = [
    { label: 'Szene', value: 'SCENE' },
    { label: 'Aussteller', value: 'EXHIBITOR' },
    { label: 'Info-Box', value: 'INFOBOX' },
    { label: 'Kontakt', value: 'CONTACT' },
    { label: 'Bildergalerie', value: 'GALLERY' },
    { label: 'Download', value: 'DOWNLOAD' },
    { label: 'Videos', value: 'VIDEO' },
    { label: 'Externe URL', value: 'EXTERNAL' },
    { label: 'Login', value: 'LOGIN' },
    { label: 'Audioplayer', value: 'AUDIO' },
    { label: 'Visitenkarte', value: 'CALLING_CARD' },
];
export var getActionType = function (action) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    if (action === void 0) { action = {}; }
    if (action.type) {
        return action.type;
    }
    if ((_a = action.sceneData) === null || _a === void 0 ? void 0 : _a.id)
        return 'SCENE';
    if ((_b = action.exhibitorData) === null || _b === void 0 ? void 0 : _b.id)
        return 'EXHIBITOR';
    if ((_c = action.infoboxData) === null || _c === void 0 ? void 0 : _c.id)
        return 'INFOBOX';
    if ((_d = action.contactData) === null || _d === void 0 ? void 0 : _d.id)
        return 'CONTACT';
    if ((_e = action.galleryData) === null || _e === void 0 ? void 0 : _e.id)
        return 'GALLERY';
    if ((_f = action.downloadData) === null || _f === void 0 ? void 0 : _f.id)
        return 'DOWNLOAD';
    if ((_g = action.videoData) === null || _g === void 0 ? void 0 : _g.id)
        return 'VIDEO';
    if ((_h = action.externalData) === null || _h === void 0 ? void 0 : _h.id)
        return 'EXTERNAL';
    if ((_j = action.loginData) === null || _j === void 0 ? void 0 : _j.id)
        return 'LOGIN';
    if ((_k = action.audioData) === null || _k === void 0 ? void 0 : _k.id)
        return 'AUDIO';
    if ((_l = action.callingCardData) === null || _l === void 0 ? void 0 : _l.id)
        return 'CALLING_CARD';
    return '';
};
export var defaultActionFormState = function () { return ({
    id: undefined,
    name: { _translation: 'text' },
    active: true,
    mobile: true,
    withLabel: false,
    type: {
        _default: '',
        _key: '_parent',
        _modify: getActionType,
    },
    x: 0.5,
    y: 0.5,
    position: {
        id: undefined,
        x: 0,
        y: 0,
        z: 0,
    },
    rotation: {
        id: undefined,
        x: 0,
        y: 0,
        z: 0,
    },
    scale: {
        id: undefined,
        x: 1,
        y: 1,
        z: 1,
    },
    buttonId: {
        _default: '',
        _key: 'button',
        _modify: function (_a) {
            var id = _a.id;
            return id;
        },
    },
    restrictions: {
        _map: function (_a) {
            var id = _a.id;
            return id;
        },
    },
    tasks: {
        _map: function (_a) {
            var id = _a.id;
            return id;
        },
    },
    sceneData: {
        id: undefined,
        sceneId: {
            _default: '',
            _key: 'scene',
            _modify: function (_a) {
                var id = _a.id;
                return id;
            },
        },
    },
    exhibitorData: {
        id: undefined,
        sceneId: {
            _default: '',
            _key: 'scene',
            _modify: function (_a) {
                var id = _a.id;
                return id;
            },
        },
    },
    galleryData: {
        id: undefined,
        title: { _translation: 'text' },
        images: {
            _sort: function (a, b) { return a.order - b.order; },
            _map: function () { return ({
                id: undefined,
                file: { _translation: 'media' },
                description: { _translation: 'text' },
            }); },
        },
    },
    infoboxData: {
        id: undefined,
        title: { _translation: 'text' },
        content: { _translation: 'text' },
    },
    contactData: {
        id: undefined,
        title: { _translation: 'text' },
        sections: {
            // TODO use default values (which requires access to the projects default translation)
            // Default values should look like this:
            // [
            //     { entries: [{ type: 'TEXT', value: defaultTranslation('') }] },
            //     {
            //         entries: [
            //             { type: 'TITLE', value: defaultTranslation('Dein Name') },
            //             { type: 'TEXT', value: defaultTranslation('Deine Position') },
            //             { type: 'TITLE', value: defaultTranslation('Kontakt') },
            //             { type: 'PHONE', value: defaultTranslation('') },
            //             { type: 'EMAIL', value: defaultTranslation('') },
            //             { type: 'WEBSITE', value: defaultTranslation('') },
            //         ],
            //     },
            // ],
            _sort: function (a, b) { return a.order - b.order; },
            _map: function () { return (__assign({ id: undefined, image: { _translation: 'media' } }, defaultContactEntryFormState())); },
        },
    },
    downloadData: {
        id: undefined,
        title: { _translation: 'text' },
        downloads: {
            _sort: function (a, b) { return a.order - b.order; },
            _map: function () { return ({
                id: undefined,
                file: { _translation: 'media' },
                thumbnailMediaId: '',
                label: { _translation: 'text' },
                description: { _translation: 'text' },
            }); },
        },
    },
    videoData: {
        id: undefined,
        title: { _translation: 'text' },
        items: {
            _sort: function (a, b) { return a.order - b.order; },
            _map: function () { return ({
                id: undefined,
                url: { _translation: 'text' },
                label: { _translation: 'text' },
            }); },
        },
    },
    audioData: {
        id: undefined,
        title: { _translation: 'text' },
        file: { _translation: 'media' },
        stopIconMediaId: {
            _default: '',
            _key: 'stopicon',
            _modify: function (_a) {
                var id = _a.id;
                return id;
            },
        },
    },
    externalData: {
        id: undefined,
        url: { _translation: 'text' },
        openInNewTab: true,
    },
    loginData: {
        id: undefined,
        hideWhenLoggedIn: true,
    },
    callingCardData: {
        id: undefined,
        contactEmail: '',
        contactSubject: '',
        contactInstructions: { _translation: 'text' },
        contactTitle: { _translation: 'text' },
        form: {
            id: undefined,
            fields: {
                _sort: function (a, b) { return a.order - b.order; },
                _map: defaultFormFieldFormState,
            },
        },
    },
}); };
