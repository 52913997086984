import EmailValidator from 'email-validator';
import { resolveLanguageName } from 'helpers/languages';

const validateUrl = (value) => {
    try {
        const url = new URL(value);
        return !!url;
    } catch (e) {
        return false;
    }
};

const stripHtml = (html) => {
    const tmpElem = document.createElement('div');
    tmpElem.innerHTML = html;
    return tmpElem.textContent || tmpElem.innerText || '';
};

const contactEntryTypes = [
    {
        label: 'Titel',
        value: 'TITLE',
        maxLength: 50,
        isValid: (value) => value.length <= 50,
        message: (value) => `Es sind nicht mehr als 50 Zeichen erlaubt. (${value.length} verwendet)`,
    },
    {
        label: 'Text',
        value: 'TEXT',
        isMultiline: true,
        maxLength: 300,
        isValid: (value) => stripHtml(value).length <= 300,
        message: (value) => `Es sind nicht mehr als 300 Zeichen erlaubt. (${stripHtml(value).length} verwendet)`,
    },
    {
        label: 'Telefon',
        value: 'PHONE',
        isValid: () => true,
    },
    {
        label: 'Fax',
        value: 'FAX',
        isValid: () => true,
    },
    {
        label: 'eMail',
        value: 'EMAIL',
        isValid: EmailValidator.validate,
        message: () => 'Bitte gib eine gültige E-Mail-Adresse an.',
    },
    {
        label: 'Website',
        value: 'WEBSITE',
        isValid: validateUrl,
        message: () => 'Bitte gib eine gültige URL an.',
    },
    {
        label: 'Zeiten',
        value: 'TIMES',
        isMultiline: true,
        maxLength: 50,
        isValid: (value) => stripHtml(value).length <= 50,
        message: (value) => `Es sind nicht mehr als 50 Zeichen erlaubt. (${stripHtml(value).length} verwendet)`,
    },
    {
        label: 'Videokonferenz',
        value: 'VIDEOCONFERENCE',
        maxLength: 100,
        isValid: (value) => validateUrl(value) && value.length <= 100,
        message: (value) => `Bitte gib eine gültige URL an, die kürzer als 100 Zeichen lang ist. (${value.length} verwendet)`,
    },
    {
        label: 'Postanschrift',
        value: 'ADDRESS',
        isMultiline: true,
        maxLength: 100,
        isValid: (value) => stripHtml(value).length <= 100,
        message: (value) => `Es sind nicht mehr als 100 Zeichen erlaubt. (${stripHtml(value).length} verwendet)`,
    },
    {
        label: 'LinkedIn',
        value: 'LINKEDIN',
        isValid: validateUrl,
        message: () => 'Bitte gib eine gültige URL an.',
    },
    {
        label: 'Xing',
        value: 'XING',
        isValid: validateUrl,
        message: () => 'Bitte gib eine gültige URL an.',
    },
    {
        label: 'Facebook',
        value: 'FACEBOOK',
        isValid: validateUrl,
        message: () => 'Bitte gib eine gültige URL an.',
    },
    {
        label: 'Instagram',
        value: 'INSTAGRAM',
        isValid: validateUrl,
        message: () => 'Bitte gib eine gültige URL an.',
    },
    {
        label: 'Twitter',
        value: 'TWITTER',
        isValid: validateUrl,
        message: () => 'Bitte gib eine gültige URL an.',
    },
];

export default contactEntryTypes;

export const findContactEntryType = (contactEntryType) => (
    contactEntryTypes.find(({ value }) => value === contactEntryType)
);

export const contactEntryValidator = (type, language) => {
    const contactEntryType = findContactEntryType(type);
    return ({
        message: (value) => {
            const languageName = resolveLanguageName(language.code);
            const localizedValue = value.entries.find(({ languageId }) => languageId === language.id);
            return `${languageName}: ${contactEntryType.message(localizedValue.text)}`;
        },
        isValid: (value) => {
            const localizedValue = value.entries.find(({ languageId }) => languageId === language.id);
            return !localizedValue || contactEntryType.isValid(localizedValue.text);
        },
    });
};

export const defaultContactEntryFormState = () => ({
    entries: {
        _sort: (a, b) => a.order - b.order,
        _map: () => ({
            id: undefined,
            value: { _translation: 'text' },
            type: '',
        }),
    },
});
