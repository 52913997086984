import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as MuiIcon from '@material-ui/icons';
import FaxIcon from './icons/Fax';
import XingIcon from './icons/Xing';

const Icon = (props) => {
    const iconComponent = () => {
        switch (props.type) {
            case 'account': return MuiIcon.AccountCircle;
            case 'add': return MuiIcon.Add;
            case 'back': return MuiIcon.ArrowBackIos;
            case 'business': return MuiIcon.Business;
            case 'cancel': return MuiIcon.Cancel;
            case 'code': return MuiIcon.Code;
            case 'copy': return MuiIcon.FileCopy;
            case 'dashboard': return MuiIcon.Dashboard;
            case 'delete': return MuiIcon.Delete;
            case 'download': return MuiIcon.CloudDownload;
            case 'drag': return MuiIcon.DragIndicator;
            case 'edit': return MuiIcon.Edit;
            case 'email': return MuiIcon.EmailSharp;
            case 'empty': return MuiIcon.NotInterested;
            case 'facebook': return MuiIcon.Facebook;
            case 'fax': return FaxIcon;
            case 'fullscreen': return MuiIcon.Fullscreen;
            case 'help': return MuiIcon.HelpOutline;
            case 'image': return MuiIcon.Image;
            case 'data': return MuiIcon.Assignment;
            case 'instagram': return MuiIcon.Instagram;
            case 'language': return MuiIcon.Language;
            case 'linkedin': return MuiIcon.LinkedIn;
            case 'lock': return MuiIcon.Lock;
            case 'logout': return MuiIcon.PowerSettingsNew;
            case 'menu': return MuiIcon.Menu;
            case 'newsticker': return MuiIcon.Subject;
            case 'pan': return MuiIcon.PanTool;
            case 'phone': return MuiIcon.PhoneRounded;
            case 'play': return MuiIcon.PlayArrow;
            case 'repeat_off': return MuiIcon.RepeatOne;
            case 'repeat_on': return MuiIcon.Repeat;
            case 'schedule': return MuiIcon.Schedule;
            case 'search': return MuiIcon.Search;
            case 'settings': return MuiIcon.Settings;
            case 'start': return MuiIcon.PlayCircleFilled;
            case 'twitter': return MuiIcon.Twitter;
            case 'upload': return MuiIcon.CloudUpload;
            case 'video': return MuiIcon.VideoLibrary;
            case 'videocam': return MuiIcon.Videocam;
            case 'view': return MuiIcon.Visibility;
            case 'warning': return MuiIcon.Warning;
            case 'xing': return XingIcon;
            default: return null;
        }
    };
    const IconComponent = iconComponent();

    return (
        <IconComponent
            className={classNames(
                'ui-icon',
                `ui-icon--${props.type}`
            )}
        />
    );
};

Icon.propTypes = {
    type: PropTypes.string.isRequired,
};

export default Icon;
