import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { ensureFormStateValues } from 'helpers/form';
import { defaultSceneObjectFormState } from 'helpers/sceneObjects';
import { hasRequiredTranslation } from 'helpers/languages';
import LocaleContext from 'contexts/locale';
import Icon from 'Icon';
import TextField from 'cms/atoms/TextField';
import TranslationMediaSelect from 'cms/molecules/TranslationMediaSelect';
import Select from 'cms/atoms/Select';
import Switch from 'cms/atoms/Switch';
import Dialog from 'cms/molecules/Dialog';
import KeyValueTable from 'cms/molecules/KeyValueTable';

const Editor = (props) => {
    const localeContext = useContext(LocaleContext);
    const [state, setState] = useState(
        ensureFormStateValues(props.objects[props.sceneObject], defaultSceneObjectFormState())
    );

    const typeSpecificValidators = () => {
        switch (state.type) {
            case 'IMAGE':
                return [{
                    name: 'imageData.file',
                    message: () => 'Bitte wähle eine Datei für die Standard-Sprache aus.',
                    isValid: (value) => hasRequiredTranslation(value, localeContext.defaultLanguage.id),
                }];

            case 'VIDEO':
                return [{
                    name: 'videoData.file',
                    message: () => 'Bitte wähle eine Datei für die Standard-Sprache aus.',
                    isValid: (value) => hasRequiredTranslation(value, localeContext.defaultLanguage.id),
                }];

            default:
                return [];
        }
    };

    const validators = [{
        name: 'name',
        message: () => 'Bitte gib einen Namen ein.',
        isValid: (value) => !!value.trim(),
    }, {
        name: 'type',
        message: () => 'Bitte wähle eine Funktion aus.',
        isValid: (value) => !!value,
    }, ...typeSpecificValidators()];

    useEffect(() => {
        if (props.isOpen) {
            setState(
                ensureFormStateValues(props.objects[props.sceneObject], defaultSceneObjectFormState())
            );
        }
    }, [props.isOpen]);

    const saveObject = () => {
        props.onSave(props.sceneObject, state);
        props.onClose();
    };

    const typeSpecificOptions = (errors, onChangeByEvent, onChangeByValue) => {
        switch (state.type) {
            case 'IMAGE':
                return [{
                    key: 'Bild',
                    value: (
                        <TranslationMediaSelect
                            label="Datei"
                            name="imageData.file"
                            value={state.imageData.file}
                            onChange={onChangeByValue('imageData.file')}
                            error={errors['imageData.file']}
                            projectFiles={props.projectFiles}
                            sceneFiles={props.sceneFiles}
                            mimeType="image"
                        />
                    ),
                    align: 'top',
                }];

            case 'VIDEO':
                return [{
                    key: 'Video',
                    value: (
                        <TranslationMediaSelect
                            label="Datei"
                            name="videoData.file"
                            value={state.videoData.file}
                            onChange={onChangeByValue('videoData.file')}
                            error={errors['videoData.file']}
                            projectFiles={props.projectFiles}
                            sceneFiles={props.sceneFiles}
                            mimeType="video"
                        />
                    ),
                }, {
                    key: 'Automatisch starten',
                    value: (
                        <Switch
                            name="videoData.autoplay"
                            checked={state.videoData.autoplay}
                            onChange={onChangeByEvent}
                        />
                    ),
                }, {
                    key: 'Schleife',
                    value: (
                        <Switch
                            name="videoData.loop"
                            checked={state.videoData.loop}
                            onChange={onChangeByEvent}
                        />
                    ),
                }, {
                    key: 'Stumm',
                    value: (
                        <Switch
                            name="videoData.mute"
                            checked={state.videoData.mute}
                            onChange={onChangeByEvent}
                        />
                    ),
                }];

            default:
                return [];
        }
    };

    return (
        <Dialog
            title={props.sceneObject === null ? 'Neues Objekt erstellen' : 'Objekt bearbeiten'}
            isOpen={props.isOpen}
            onClose={props.onClose}
            onConfirm={saveObject}
            confirmLabel="Fertig"
            onChange={setState}
            values={state}
            validators={validators}
        >
            {({ errors, onChangeByEvent, onChangeByValue }) => (
                <KeyValueTable
                    items={[
                        {
                            key: 'Name',
                            value: (
                                <TextField
                                    name="name"
                                    value={state.name}
                                    onChange={onChangeByEvent}
                                    error={errors.name}
                                />
                            ),
                        },
                        {
                            key: 'Funktion',
                            value: (
                                <Select
                                    name="type"
                                    value={state.type}
                                    onChange={onChangeByEvent}
                                    items={[
                                        {
                                            label: 'Bild',
                                            value: 'IMAGE',
                                            image: <Icon type="image" />,
                                        },
                                        {
                                            label: 'Video',
                                            value: 'VIDEO',
                                            image: <Icon type="video" />,
                                        },
                                    ]}
                                    error={errors.type}
                                />
                            ),
                        },
                        ...typeSpecificOptions(errors, onChangeByEvent, onChangeByValue),
                    ]}
                />
            )}
        </Dialog>
    );
};

Editor.defaultProps = {
    sceneObject: null,
};

Editor.propTypes = {
    sceneObject: PropTypes.number,
    isOpen: PropTypes.bool.isRequired,
    objects: PropTypes.array.isRequired,
    onSave: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    projectFiles: PropTypes.array.isRequired,
    sceneFiles: PropTypes.array.isRequired,
};

export default React.memo(Editor, (prevProps, nextProps) => (
    prevProps.isOpen === nextProps.isOpen
));
