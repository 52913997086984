const isNestedConfig = (value) => (
    value && typeof value === 'object' && value.constructor === Object // ignore classes like moment
);

/**
 * Converts API format to React state with defaults, transformations, sorting, etc.
 *
 * See docs/workflows/forms.md for a detailed explanation.
 */
export const apiResponseToFormState = (source, defaults) => (
    Object.entries(defaults).reduce((result, [key, value]) => {
        let config = {
            _default: value,
            _key: key,
            _modify: null,
            _map: null,
            _sort: null,
        };

        if (value && typeof value === 'object') {
            if (value._translation === 'text') {
                config._modify = () => ({
                    id: undefined,
                    entries: {
                        _map: () => ({
                            id: undefined,
                            text: '',
                            languageId: '',
                        }),
                    },
                });
            } else if (value._translation === 'media') {
                config._modify = () => ({
                    id: undefined,
                    mediaEntries: {
                        _map: () => ({
                            id: undefined,
                            mediaId: undefined,
                            media: {
                                id: undefined,
                                url: undefined,
                                filename: undefined,
                                mimeType: undefined,
                            },
                            languageId: '', // TODO why not undefined?
                        }),
                    },
                });
            } else {
                config = { ...config, ...value };
            }
        }

        const sourceValue = config._key === '_parent'
            ? source
            : source?.[config._key];
        let finalValue = sourceValue;

        if (config._map) {
            finalValue = [];

            if (sourceValue) {
                finalValue = [...sourceValue];

                if (config._sort) {
                    finalValue.sort(config._sort);
                }

                finalValue = finalValue.map((item) => {
                    const mappedItem = config._map(item);

                    if (isNestedConfig(mappedItem)) {
                        return apiResponseToFormState(item, mappedItem);
                    }

                    return mappedItem;
                });
            }
        } else if (config._modify) {
            finalValue = sourceValue !== null && sourceValue !== undefined
                ? config._modify(sourceValue)
                : null;

            if (isNestedConfig(finalValue)) {
                finalValue = apiResponseToFormState(sourceValue, finalValue);
            }
        } else if (isNestedConfig(config._default)) {
            finalValue = apiResponseToFormState(sourceValue, config._default);
        }

        return {
            ...result,
            [key]: finalValue ?? config._default,
        };
    }, {})
);

/** Use existing form state (i.e. from parent component). */
export const ensureFormStateValues = (parentState, defaults) => (
    parentState || apiResponseToFormState(null, defaults)
);
