import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import VideoPlayer from 'react-player/lazy';
import useToggle from 'hooks/useToggle';
import useTranslate from 'hooks/useTranslate';
import useTracking from 'hooks/useTracking';
import { TRACKING_PLAY_VIDEO } from 'helpers/tracking';
import Icon from 'Icon';

const Video = (props) => {
    const [isFullScreenEnabled, enableFullScreen, disableFullScreen] = useToggle(false);
    const tracking = useTracking();
    const t = useTranslate();
    const url = t(props.url);

    const onVideoPlay = () => {
        if (props.sceneName && !props.preview) {
            //  TRACKCORE:
            //   event:'playVideo', ressource: url, data: {customer: customerId, scene: props.sceneName }
            //   SCENE CONTENT: overlay video
            // console.log('%cTRACKCORE: playVideo', 'background: #00a8ff; font-weight: bold;', url);
            tracking.trackCoreEvent({
                action: 'playVideo',
                resourceType: 'url',
                resourceName: url,
            });
            tracking.trackEvent(props.sceneName, TRACKING_PLAY_VIDEO, url);
        }
    };

    const renderContent = () => (
        <div
            className={classNames(
                'ui-shared-overlay-video',
                {
                    'ui-shared-overlay-video--with-fullscreen': props.controls === 'FULLSCREEN',
                    'ui-shared-overlay-video--fullscreen-enabled': isFullScreenEnabled,
                }
            )}
        >
            <div
                className="ui-shared-overlay-video__backdrop"
                onClick={disableFullScreen}
            >
                <div className="ui-shared-overlay-video__close">
                    <Icon type="cancel" />
                </div>
            </div>

            <VideoPlayer
                className="ui-shared-overlay-video__video"
                url={url}
                playing={!props.preview && props.autoplay}
                loop={props.loop}
                controls={props.controls === 'NATIVE' || isFullScreenEnabled}
                muted={!props.preview && props.mute && !isFullScreenEnabled}
                width="100%"
                height="100%"
                onPlay={onVideoPlay}
            />

            <div
                className="ui-shared-overlay-video__overlay"
                onClick={enableFullScreen}
            >
                <Icon type="fullscreen" />
            </div>
        </div>
    );

    return isFullScreenEnabled
        ? ReactDOM.createPortal(renderContent(), document.getElementById('app'))
        : renderContent();
};

Video.defaultProps = {
    preview: false,
};

Video.propTypes = {
    url: PropTypes.object.isRequired,
    autoplay: PropTypes.bool.isRequired,
    mute: PropTypes.bool.isRequired,
    loop: PropTypes.bool.isRequired,
    controls: PropTypes.string.isRequired,
    sceneName: PropTypes.string,
    preview: PropTypes.bool,
};

export default Video;
