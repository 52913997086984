import { useMutation } from 'apollo-augmented-hooks';

const mutation = `
    mutation prepareUpload($input: PrepareUploadInput!) {
        prepareUpload(input: $input) {
            key
            media {
                id
            }
        }
    }
`;

export default () => {
    const [mutate] = useMutation(mutation);
    return (input) => mutate({ input });
};
