import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import useTranslate from 'hooks/useTranslate';
import useUpdateProject from 'hooks/graphql/mutations/updateProject';
import useProject from 'hooks/graphql/queries/project';
import LocaleContext from 'contexts/locale';
import MeContext from 'contexts/me';
import { apiResponseToFormState } from 'helpers/form';
import loginTypes, { findLoginType } from 'helpers/loginTypes';
import loginDialogTypes from 'helpers/loginDialogTypes';
import { formFieldTypes, defaultFormFieldType, defaultFormFieldFormState } from 'helpers/formFields';
import {
    emptyTranslation,
    createUpdateTranslationInput,
    hasRequiredTranslation, hasOptionalTranslation,
} from 'helpers/languages';
import EditingAdmin from 'EditingAdmin';
import TextField from 'cms/atoms/TextField';
import TranslationField from 'cms/molecules/TranslationField';
import TranslationCodeInput from 'cms/molecules/TranslationCodeInput';
import Select from 'cms/atoms/Select';
import Button from 'cms/atoms/Button';
import Switch from 'cms/atoms/Switch';
import KeyValueTable from 'cms/molecules/KeyValueTable';
import EditorList from 'cms/molecules/EditorList';
import Dialog from 'cms/molecules/Dialog';
import LabelList from 'cms/atoms/LabelList';
import FormFieldOptionEditor from 'FormFieldOptionEditor';

const defaultValues = {
    id: undefined,
    loginType: 'NONE',
    loginDialogType: 'REGISTER',
    loginInstructions: { _translation: 'text' },
    registrationInstructions: { _translation: 'text' },
    registrationFields: {
        _sort: (a, b) => a.order - b.order,
        _map: defaultFormFieldFormState,
    },
    password: '',
    externalEventId: '',
    externalClientId: '',
    externalClientSecret: '',
    loginTracking: false,
    loginLabelUser: { _translation: 'text' },
    loginLabelPassword: { _translation: 'text' },
    registrationEnabled: false,
    registrationExternalUrl: '',
    referrerConstraint: {
        _default: [],
        _modify: (value) => (value === '' ? [] : value.split(',')),
    },
    referrerErrorMessage: { _translation: 'text' },
    accessGroups: [],
    ipWhitelist: '',
};

const Login = (props) => {
    const t = useTranslate();
    const params = useParams();
    const localeContext = useContext(LocaleContext);
    const me = useContext(MeContext);
    const { data, loading } = useProject(params.id);
    const updateProject = useUpdateProject();

    const { project } = !loading && data;
    const [state, setState] = useState(apiResponseToFormState(project, defaultValues));
    const [registrationOptionEditorState, setRegistrationOptionEditorState] = useState({
        isOpen: false,
        index: null,
    });

    const validators = [
        {
            name: 'loginInstructions',
            message: () => 'Bitte gib ebenfalls einen Inhalt für die Standard-Sprache ein.',
            isValid: (value) => (
                !(state.loginType !== 'NONE')
                || hasOptionalTranslation(value, localeContext.defaultLanguage.id)
            ),
        },
        {
            name: 'loginLabelUser',
            message: () => 'Bitte gib ebenfalls einen Inhalt für die Standard-Sprache ein.',
            isValid: (value) => (
                !findLoginType(state.loginType).withUserLabel
                || hasOptionalTranslation(value, localeContext.defaultLanguage.id)
            ),
        },
        {
            name: 'loginLabelPassword',
            message: () => 'Bitte gib ebenfalls einen Inhalt für die Standard-Sprache ein.',
            isValid: (value) => (
                !findLoginType(state.loginType).withPasswordLabel
                || hasOptionalTranslation(value, localeContext.defaultLanguage.id)
            ),
        },
        {
            name: 'registrationInstructions',
            message: () => 'Bitte gib ebenfalls einen Inhalt für die Standard-Sprache ein.',
            isValid: (value) => (
                !(['ACCOUNTS'].includes(state.loginType) && state.registrationEnabled)
                || hasOptionalTranslation(value, localeContext.defaultLanguage.id)
            ),
        },
        {
            name: 'referrerErrorMessage',
            message: () => 'Bitte gib ebenfalls einen Inhalt für die Standard-Sprache ein.',
            isValid: (value) => hasOptionalTranslation(value, localeContext.defaultLanguage.id),
        },
        {
            name: 'password',
            message: () => 'Bitte gib ein Passwort ein.',
            isValid: (value, { loginType }) => loginType !== 'PASSWORD' || !!value.trim(),
        },
        {
            name: 'ipWhitelist',
            message: () => 'Ungültige IP Adresse',
            isValid: (value, { loginType }) => loginType !== 'IP' || value.match(/^([0-9]{1,3}\.){3}[0-9]{1,3}(\/([0-9]|[1-2][0-9]|3[0-2]))?$/),
        },
        {
            name: 'externalEventId',
            message: () => 'Bitte gib eine Event-ID ein.',
            isValid: (value, { loginType }) => (
                !findLoginType(loginType).withEventId
                || !!value.trim()
            ),
        },
        {
            name: 'externalClientId',
            message: () => 'Bitte gib eine ID ein.',
            isValid: (value, { loginType }) => (
                !findLoginType(loginType).withOauth
                || !!value.trim()
            ),
        },
        ...state.registrationFields.reduce((result, current, index) => [
            ...result,
            {
                name: `registrationFields.${index}.label`,
                message: () => 'Bitte gib eine Beschriftung für die Standard-Sprache ein.',
                isValid: (value) => hasRequiredTranslation(value, localeContext.defaultLanguage.id),
            },
            {
                name: `registrationFields.${index}.description`,
                message: () => 'Bitte gib ebenfalls eine Beschreibung für die Standard-Sprache ein.',
                isValid: (value) => hasOptionalTranslation(value, localeContext.defaultLanguage.id),
            },
        ], []),
        ...state.accessGroups.reduce((result, current, index) => [
            ...result, {
                name: `accessGroups.${index}.name`,
                message: () => 'Bitte gib einen Namen ein.',
                isValid: (value) => !!value.trim(),
            },
        ], []),
        ...state.referrerConstraint.reduce((result, current, index) => [
            ...result, {
                name: `referrerConstraint.${index}`,
                message: () => 'Bitte gib eine Domain ein.',
                isValid: (value) => !!value.trim(),
            },
        ], []),
    ];

    useEffect(() => {
        if (props.isOpen) {
            setState(apiResponseToFormState(project, defaultValues));
        }
    }, [props.isOpen]);

    const addRegistrationField = () => {
        setState((previous) => ({
            ...previous,
            registrationFields: [
                ...previous.registrationFields,
                {
                    label: emptyTranslation,
                    mandatory: false,
                    type: defaultFormFieldType,
                    description: emptyTranslation,
                    options: [],
                },
            ],
        }));
    };

    const removeRegistrationField = (index) => {
        setState((previous) => ({
            ...previous,
            registrationFields: [
                ...previous.registrationFields.slice(0, index),
                ...previous.registrationFields.slice(index + 1),
            ],
        }));
    };

    const changeRegistrationFieldOrder = (newIndex, oldIndex) => {
        const registrationFields = [...state.registrationFields];
        const movedField = registrationFields[oldIndex];

        registrationFields.splice(oldIndex, 1);
        registrationFields.splice(newIndex, 0, movedField);

        setState({ ...state, registrationFields });
    };

    const addAccessGroup = () => {
        setState((previous) => ({
            ...previous,
            accessGroups: [
                { name: '' },
                ...previous.accessGroups,
            ],
        }));
    };

    const removeAccessGroup = (index) => {
        setState((previous) => ({
            ...previous,
            accessGroups: [
                ...previous.accessGroups.slice(0, index),
                ...previous.accessGroups.slice(index + 1),
            ],
        }));
    };

    const addReferrerConstraint = () => {
        setState((previous) => ({
            ...previous,
            referrerConstraint: [
                '',
                ...previous.referrerConstraint,
            ],
        }));
    };

    const removeReferrerConstraint = (index) => {
        setState((previous) => ({
            ...previous,
            referrerConstraint: [
                ...previous.referrerConstraint.slice(0, index),
                ...previous.referrerConstraint.slice(index + 1),
            ],
        }));
    };

    const save = async (values) => {
        await updateProject({
            loginType: values.loginType,
            loginDialogType: values.loginDialogType,
            loginInstructions: createUpdateTranslationInput(values.loginInstructions),
            registrationInstructions: createUpdateTranslationInput(values.registrationInstructions),
            registrationFields: values.registrationFields.map((registrationField, fieldIndex) => ({
                id: registrationField.id,
                label: createUpdateTranslationInput(registrationField.label),
                description: createUpdateTranslationInput(registrationField.description),
                mandatory: registrationField.mandatory,
                type: registrationField.type,
                options: (registrationField.options || []).map((option, optionIndex) => ({
                    id: option.id,
                    label: createUpdateTranslationInput(option.label),
                    value: option.value.trim(),
                    order: optionIndex,
                })),
                order: fieldIndex,
            })),
            password: values.password.trim(),
            externalEventId: values.externalEventId.trim(),
            loginTracking: values.loginTracking,
            loginLabelUser: createUpdateTranslationInput(values.loginLabelUser),
            loginLabelPassword: createUpdateTranslationInput(values.loginLabelPassword),
            externalClientId: values.externalClientId.trim(),
            externalClientSecret: values.externalClientSecret.trim() || undefined,
            registrationEnabled: values.registrationEnabled,
            registrationExternalUrl: values.registrationExternalUrl.trim(),
            referrerConstraint: values.referrerConstraint
                .map((constraint) => constraint.trim())
                .join(','),
            referrerErrorMessage: createUpdateTranslationInput(values.referrerErrorMessage),
            accessGroups: values.accessGroups.map((accessGroup) => ({
                id: accessGroup.id,
                name: accessGroup.name,
            })),
            id: project.id,
            ipWhitelist: values.ipWhitelist.trim(),
        });

        props.onClose();
    };

    const openRegistrationOptionEditorHandler = (index) => () => {
        setRegistrationOptionEditorState({ isOpen: true, index });
    };

    const closeRegistrationOptionEditor = () => {
        setRegistrationOptionEditorState({ ...registrationOptionEditorState, isOpen: false });
    };

    const changeRegistrationOptions = (options) => {
        setState((previous) => {
            const registrationFields = previous.registrationFields;
            return {
                ...previous,
                registrationFields: [
                    ...previous.registrationFields.slice(0, registrationOptionEditorState.index),
                    {
                        ...registrationFields[registrationOptionEditorState.index],
                        options,
                    },
                    ...previous.registrationFields.slice(registrationOptionEditorState.index + 1),
                ],
            };
        });
    };

    const renderRegistrationField = (
        registrationField, index, onChangeByEvent, onChangeByValue, errors
    ) => (
        <React.Fragment key={registrationField.id || index}>
            <TranslationField
                label="Beschriftung"
                name={`registrationFields.${index}.label`}
                value={registrationField.label}
                onChange={onChangeByValue(`registrationFields.${index}.label`)}
                error={errors[`registrationFields.${index}.label`]}
            />

            <Switch
                label="Erforderlich"
                name={`registrationFields.${index}.mandatory`}
                checked={registrationField.mandatory}
                onChange={onChangeByEvent}
            />

            <Select
                name={`registrationFields.${index}.type`}
                label="Typ"
                value={registrationField.type}
                onChange={onChangeByEvent}
                items={formFieldTypes}
            />

            {registrationField.type === 'CHECKBOX' && (
                <TranslationCodeInput
                    label="Beschreibung"
                    value={registrationField.description}
                    onChange={onChangeByValue(`registrationFields.${index}.description`)}
                    error={errors[`registrationFields.${index}.description`]}
                />
            )}

            {registrationField.type === 'DROPDOWN' && (
                <>
                    <Button
                        icon="edit"
                        onClick={openRegistrationOptionEditorHandler(index)}
                    >
                        Werte Bearbeiten
                    </Button>
                    <LabelList
                        labels={
                            registrationField.options.map((option) => ({
                                label: t(option.label),
                            }))
                        }
                    />
                </>
            )}
        </React.Fragment>
    );

    const renderRegistrationFields = (onChangeByEvent, onChangeByValue, errors) => (
        <>
            <EditorList
                addLabel="Feld hinzufügen"
                onAdd={addRegistrationField}
                removeLabel="Feld löschen"
                onRemove={removeRegistrationField}
                onSort={changeRegistrationFieldOrder}
                sortModeContents={(
                    state.registrationFields.map((registrationField) => (
                        t(registrationField.label) || 'Ohne Beschriftung'
                    ))
                )}
            >
                {state.registrationFields.map((registrationField, index) => (
                    renderRegistrationField(
                        registrationField,
                        index,
                        onChangeByEvent,
                        onChangeByValue,
                        errors
                    )
                ))}
            </EditorList>

            <FormFieldOptionEditor
                isOpen={registrationOptionEditorState.isOpen}
                onClose={closeRegistrationOptionEditor}
                onSave={changeRegistrationOptions}
                project={project}
                field={state.registrationFields[registrationOptionEditorState.index]}
            />
        </>
    );

    return !loading && (
        <Dialog
            title="Login-Einstellungen bearbeiten"
            isOpen={props.isOpen}
            onClose={props.onClose}
            onConfirm={save}
            onChange={setState}
            values={state}
            validators={validators}
        >
            {({ errors, onChangeByEvent, onChangeByValue }) => (
                <>
                    <EditingAdmin name={`project-login-${project.id}`} />

                    <KeyValueTable
                        items={[{
                            key: 'Login-Art',
                            value: (
                                <Select
                                    name="loginType"
                                    value={state.loginType}
                                    onChange={onChangeByEvent}
                                    items={loginTypes.filter((loginType) => loginType.active)}
                                />
                            ),
                        }, {
                            disabled: !me.hasWriteAccessToFeature('login.loginType'),
                            key: 'Login-Anweisung (individuell)',
                            help: 'Erscheint über Benutzer- und Passwort-Feld.',
                            value: (
                                <TranslationCodeInput
                                    value={state.loginInstructions}
                                    onChange={onChangeByValue('loginInstructions')}
                                    projectFiles={project.files}
                                    error={errors.loginInstructions}
                                />
                            ),
                            available: state.loginType !== 'NONE',
                        }, {
                            key: 'Login-Anweisung (generiert)',
                            help: 'Beeinflusst die Formulierung der automatisch generierten Login-Anweisung. Erscheint unterhalb der individuellen Anweisung.',
                            value: (
                                <Select
                                    name="loginDialogType"
                                    value={state.loginDialogType}
                                    onChange={onChangeByEvent}
                                    items={loginDialogTypes}
                                />
                            ),
                            disabled: !me.hasWriteAccessToFeature('login.loginType'),
                            available: findLoginType(state.loginType).withLoginDialogType,
                        }, {
                            key: 'Benutzer-Feld',
                            help: 'Beschriftung des Benutzer-Felds während Login, Registrierung, etc.',
                            value: (
                                <TranslationField
                                    name="loginLabelUser"
                                    value={state.loginLabelUser}
                                    onChange={onChangeByValue('loginLabelUser')}
                                    error={errors.loginLabelUser}
                                />
                            ),
                        }, {
                            key: 'Erlaubte IP-Adressen',
                            help: 'Subnetz von erlaubten IP-Adressen. "0.0.0.0/0" erlaubt alle IPs (Standard). \nEine einzelne Adresse kann über das Subnetz "/32" angesprochen werden (Bsp.: 10.10.10.10/32).',
                            value: (
                                <TextField
                                    name="ipWhitelist"
                                    value={state.ipWhitelist}
                                    onChange={onChangeByEvent}
                                    error={errors.ipWhitelist}
                                />
                            ),
                            available: state.loginType === 'IP',
                        }, {
                            key: 'Passwort-Feld',
                            help: 'Beschriftung des Passwort-Felds während Login, Registrierung, etc.',
                            value: (
                                <TranslationField
                                    name="loginLabelPassword"
                                    value={state.loginLabelPassword}
                                    onChange={onChangeByValue('loginLabelPassword')}
                                    error={errors.loginLabelPassword}
                                />
                            ),
                            available: findLoginType(state.loginType).withPasswordLabel,
                        }, {
                            key: 'Passwort',
                            value: (
                                <TextField
                                    name="password"
                                    value={state.password}
                                    onChange={onChangeByEvent}
                                    error={errors.password}
                                />
                            ),
                            available: state.loginType === 'PASSWORD',
                        }, {
                            key: 'Externe Event-ID',
                            help: 'Ein Code, der beim Login bei einem externen Diensleister übergeben wird. Wird beim externen Diensleister definiert.',
                            value: (
                                <TextField
                                    name="externalEventId"
                                    value={state.externalEventId}
                                    onChange={onChangeByEvent}
                                    error={errors.externalEventId}
                                />
                            ),
                            available: findLoginType(state.loginType).withEventId,
                        }, {
                            key: 'Externe Client-ID',
                            help: 'Eine Identifikationsnummer, der beim Login bei einem externen Diensleister übergeben wird. Wird beim externen Diensleister definiert.',
                            value: (
                                <TextField
                                    name="externalClientId"
                                    value={state.externalClientId}
                                    onChange={onChangeByEvent}
                                    error={errors.externalClientId}
                                />
                            ),
                            available: findLoginType(state.loginType).withOauth,
                        }, {
                            key: 'Externes Client-Secret',
                            help: findLoginType(state.loginType).withOauth ? 'Wird vom externen Diensleister definiert. Feld leer lassen, um altes secret beizubehalten' : null,
                            value: (
                                <TextField
                                    name="externalClientSecret"
                                    value={state.externalClientSecret}
                                    onChange={onChangeByEvent}
                                    error={errors.externalClientSecret}
                                />
                            ),
                            available: findLoginType(state.loginType).withOauth,
                        }, {
                            key: 'API-Key',
                            help: findLoginType(state.loginType).withApiKey ? 'Wird vom externen Diensleister definiert. Feld leer lassen, um alten API-Key beizubehalten' : null,
                            value: (
                                <TextField
                                    name="externalClientSecret"
                                    value={state.externalClientSecret}
                                    onChange={onChangeByEvent}
                                    error={errors.externalClientSecret}
                                />
                            ),
                            available: findLoginType(state.loginType).withApiKey,
                        }, {
                            key: 'Login-Tracking',
                            value: (
                                <Switch
                                    name="loginTracking"
                                    checked={state.loginTracking}
                                    onChange={onChangeByEvent}
                                />
                            ),
                            available: findLoginType(state.loginType).withLoginTracking,
                        }, {
                            key: 'Registrierung',
                            help: 'Wenn ein Gast nicht angemeldet ist und die Veranstaltung betreten möchte, werden ihm beide Optionen angeboten: Registrierung und Login.',
                            value: (
                                <Switch
                                    name="registrationEnabled"
                                    checked={state.registrationEnabled}
                                    onChange={onChangeByEvent}
                                />
                            ),
                            available: ['ACCOUNTS'].includes(state.loginType),
                        }, {
                            key: 'Registrierungs-Anweisung',
                            help: 'Erscheint über den Registrierungs-Feldern.',
                            value: (
                                <TranslationCodeInput
                                    value={state.registrationInstructions}
                                    onChange={onChangeByValue('registrationInstructions')}
                                    projectFiles={project.files}
                                    error={errors.registrationInstructions}
                                />
                            ),
                            available: ['ACCOUNTS'].includes(state.loginType) && state.registrationEnabled,
                        }, {
                            key: 'Registrierungs-Felder',
                            help: 'Zusätzliche Daten-Felder während der Registrierung. Checkboxen zeigen eine mehrzeilige "Beschreibung" mit HTML-Unterstützung. Die einzeilige "Beschriftung" für Checkboxen wird hingegen nur für die Auswertung verwendet.',
                            value: renderRegistrationFields(
                                onChangeByEvent,
                                onChangeByValue,
                                errors
                            ),
                            align: 'top',
                            available: ['ACCOUNTS'].includes(state.loginType) && state.registrationEnabled,
                        }, {
                            key: 'Externe Registrierung',
                            help: 'Wenn eine URL angegeben ist, wird der Benutzer zur Registrierung zu der eingegebenen Veranstalter-Website weitergeleitet, statt einen Dialog in der Seite zu öffnen.',
                            value: (
                                <TextField
                                    name="registrationExternalUrl"
                                    enabled={state.registrationEnabled}
                                    value={state.registrationExternalUrl}
                                    onChange={onChangeByEvent}
                                />
                            ),
                            available: ['ACCOUNTS'].includes(state.loginType) && state.registrationEnabled,
                        }, {
                            key: 'Zugriffsgruppen',
                            help: 'Zugriffsgruppen können als Beschränkungen an Szenen und Aktionen hinterlegt werden. Nur Benutzer, die einer passenden Zugriffsgruppe angehören, können diese Szenen betreten oder Aktionen sehen.',
                            value: (
                                <EditorList
                                    addLabel="Gruppe hinzufügen"
                                    onAdd={addAccessGroup}
                                    removeLabel="Gruppe löschen"
                                    onRemove={removeAccessGroup}
                                >
                                    {state.accessGroups.map((accessGroup, index) => (
                                        <TextField
                                            key={index}
                                            label="Name"
                                            name={`accessGroups.${index}.name`}
                                            value={accessGroup.name}
                                            onChange={onChangeByEvent}
                                            error={errors[`accessGroups.${index}.name`]}
                                        />
                                    ))}
                                </EditorList>
                            ),
                            available: findLoginType(state.loginType).withAccessGroups,
                            align: 'top',
                        }, {
                            key: 'Referrer-Einschränkung',
                            help: 'Die Messe darf nur von den hier angegebenen Domains aus verlinkt werden. Betritt man die Messe direkt oder von einer anderen Domain, gibt es eine Fehlermeldung. Sollte hier gar keine Domain angegeben werden, darf die Messe von überall aus besucht werden.',
                            value: (
                                <EditorList
                                    addLabel="Domain hinzufügen"
                                    onAdd={addReferrerConstraint}
                                    removeLabel="Domain löschen"
                                    onRemove={removeReferrerConstraint}
                                >
                                    {state.referrerConstraint.map((constraint, index) => (
                                        <TextField
                                            key={index}
                                            label="Name"
                                            name={`referrerConstraint.${index}`}
                                            value={constraint}
                                            onChange={onChangeByEvent}
                                            error={errors[`referrerConstraint.${index}`]}
                                        />
                                    ))}
                                </EditorList>
                            ),
                            align: 'top',
                        }, {
                            key: 'Referrer-Fehlermeldung',
                            help: 'Diese Fehlermeldung erscheint, wenn jemand die Messe von einer Domain aus besucht, die nicht Teil der Liste unter der Referrer-Einschränkung ist.',
                            value: (
                                <TranslationCodeInput
                                    value={state.referrerErrorMessage}
                                    onChange={onChangeByValue('referrerErrorMessage')}
                                    files={project.files}
                                    error={errors.referrerErrorMessage}
                                />
                            ),
                            align: 'top',
                        }]}
                    />
                </>
            )}
        </Dialog>
    );
};

Login.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default Login;
