import React from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Home from './Web/Home';
import SetPasswordWidget from './Web/SetPasswordWidget';
import RegistrationWidget from './Web/RegistrationWidget';
import Project from './Web/Project';

const Web = () => {
    const location = useLocation();

    return (
        <>
            <Helmet>
                <body className="web" />
            </Helmet>

            <Switch location={location}>
                <Route path="/set-password/:projectPath" component={SetPasswordWidget} />
                <Route path="/registration/:projectPath" component={RegistrationWidget} />
                <Route path="/event/:projectPath/:scenePath?" component={Project} />
                <Route path="/" component={Home} />
            </Switch>
        </>
    );
};

export default Web;
