import csvStringify from 'csv-stringify';
import { saveAs } from 'file-saver';

export function generateCsv(rows, columns) {
    return new Promise((resolve, reject) => {
        csvStringify(
            rows,
            {
                header: true,
                columns,
            },
            (err, output) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(output);
                }
            }
        );
    });
}

export function saveFile(data, filename) {
    const blob = new Blob([data], { type: 'text/csv;charset=utf-8' });
    saveAs(blob, filename);
}
