import React from 'react';
import PropTypes from 'prop-types';
import { findFormFieldType } from 'helpers/formFields';
import useTranslate from 'hooks/useTranslate';

const FormFieldList = (props) => {
    const t = useTranslate();

    const renderFormField = ({ id, label, type }) => (
        <div
            key={id}
            className="ui-cms-form-list__field"
        >
            {t(label)} ({findFormFieldType(type).label})
        </div>
    );
    return (
        <div className="ui-cms-form-list">
            {props.fields.map(renderFormField)}
        </div>
    );
};

FormFieldList.defaultProps = {
    fields: [],
};

FormFieldList.propTypes = {
    fields: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        label: PropTypes.object,
        mandatory: PropTypes.bool,
        description: PropTypes.object,
        options: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string,
            label: PropTypes.object,
            value: PropTypes.string,
        })),
    })),
};

export default FormFieldList;
