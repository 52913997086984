import React from 'react';
import PropTypes from 'prop-types';
import { trackingLabelMap } from 'helpers/tracking';
import KeyValueTable from 'cms/molecules/KeyValueTable';
import StatList from './shared/StatList';

const shouldShowAction = (action) => {
    if (trackingLabelMap[action.label]) {
        // This is a predefined event
        return trackingLabelMap[action.label].visibleInStatistics;
    }

    // Custom events should always be visible
    return true;
};

const Events = (props) => (
    <KeyValueTable
        wrappingDividers
        items={
            props.eventActions
                .filter(shouldShowAction)
                .map((action) => ({
                    key: (
                        trackingLabelMap[action.label].label
                        || `Benutzerdefiniertes Ereignis: ${action.label}`
                    ),
                    value: (
                        <StatList
                            items={action.names || [{
                                label: 'Ohne Wert',
                                totalClicks: action.totalClicks,
                                uniqueClicks: action.uniqueClicks,
                            }]}
                        />
                    ),
                    align: 'top',
                }))
                .sort((a, b) => a.key.localeCompare(b.key))
        }
    />
);

Events.propTypes = {
    eventActions: PropTypes.array.isRequired,
};

export default Events;
