import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useToggle from 'hooks/useToggle';
import useSettings from 'hooks/graphql/queries/settings';
import useUpdateDefaultButtons from 'hooks/graphql/mutations/updateDefaultButtons';
import mediaUrl from 'helpers/mediaUrl';
import { apiResponseToFormState } from 'helpers/form';
import { actionTypes } from 'helpers/actions';
import EditingAdmin from 'EditingAdmin';
import ImageUpload from 'cms/atoms/ImageUpload';
import TextField from 'cms/atoms/TextField';
import Select from 'cms/atoms/Select';
import Confirm from 'components/ui/cms/molecules/Confirm';
import Grid from 'cms/molecules/Grid';
import EditorList from 'cms/molecules/EditorList';
import Dialog from 'cms/molecules/Dialog';
import KeyValueTable from 'cms/molecules/KeyValueTable';

const defaultValues = {
    buttons: [],
};

const Settings = (props) => {
    const { data } = useSettings();
    const [state, setState] = useState(apiResponseToFormState(data, defaultValues));
    const updateDefaultButtons = useUpdateDefaultButtons();
    const [isRemoveAlertOpen, openRemoveAlert, closeRemoveAlert] = useToggle(false);
    const validators = state.buttons.reduce((result, current, index) => [
        ...result, {
            name: `buttons.${index}.image`,
            message: () => 'Bitte lade ein Bild hoch.',
            isValid: (value) => !!value.url,
        }, {
            name: `buttons.${index}.name`,
            message: () => 'Bitte gib einen Namen ein.',
            isValid: (value) => !!value.trim(),
        }, {
            name: `buttons.${index}.type`,
            message: () => 'Bitte wähle eine Art aus.',
            isValid: (value) => !!value,
        },
    ], []);

    useEffect(() => {
        if (props.isOpen) {
            setState(apiResponseToFormState(data, defaultValues));
        }
    }, [props.isOpen]);

    const addButton = () => {
        setState((previous) => ({
            ...previous,
            buttons: [
                {
                    name: '',
                    type: '',
                    image: {},
                },
                ...previous.buttons,
            ],
        }));
    };

    const removeButton = (index) => {
        if (state.buttons[index].inUseByActions) {
            openRemoveAlert();
            return;
        }

        setState((previous) => ({
            ...previous,
            buttons: [
                ...previous.buttons.slice(0, index),
                ...previous.buttons.slice(index + 1),
            ],
        }));
    };

    const save = async (values) => {
        await updateDefaultButtons({
            buttons: values.buttons.map((button) => ({
                id: button.id,
                name: button.name,
                type: button.type,
                imageMediaId: button.image.id,
            })),
        });

        props.onClose();
    };

    const renderButtons = (errors, onChangeByEvent, onChangeByValue) => (
        <EditorList
            addLabel="Button hinzufügen"
            onAdd={addButton}
            removeLabel="Button löschen"
            onRemove={removeButton}
        >
            {state.buttons.map((button, index) => (
                <Grid key={index}>
                    <Grid item size={12}>
                        <ImageUpload
                            onUpload={onChangeByValue(`buttons.${index}.image`)}
                            url={mediaUrl(button.image, 'mini')}
                            error={errors[`buttons.${index}.image`]}
                            maxWidth={64}
                        />
                    </Grid>

                    <Grid item size={12}>
                        <TextField
                            label="Name"
                            name={`buttons.${index}.name`}
                            value={button.name}
                            onChange={onChangeByEvent}
                            error={errors[`buttons.${index}.name`]}
                        />
                    </Grid>

                    <Grid item size={12}>
                        <Select
                            label="Art"
                            name={`buttons.${index}.type`}
                            value={button.type}
                            onChange={onChangeByEvent}
                            items={actionTypes}
                            error={errors[`buttons.${index}.type`]}
                        />
                    </Grid>
                </Grid>
            ))}
        </EditorList>
    );

    return (
        <>
            <Dialog
                title="Szenen-Einstellungen bearbeiten"
                isOpen={props.isOpen}
                onClose={props.onClose}
                onConfirm={save}
                onChange={setState}
                values={state}
                validators={validators}
            >
                {({ errors, onChangeByEvent, onChangeByValue }) => (
                    <>
                        <EditingAdmin name="buttons" />

                        <KeyValueTable
                            items={[
                                {
                                    key: 'Default-Buttons',
                                    value: renderButtons(errors, onChangeByEvent, onChangeByValue),
                                    align: 'top',
                                },
                            ]}
                        />
                    </>
                )}
            </Dialog>

            <Confirm
                title="Dieser Button kann nicht gelöscht werden."
                onConfirm={closeRemoveAlert}
                isOpen={isRemoveAlertOpen}
            >
                Es gibt noch Szenen, in denen dieser Button genutzt wird.
            </Confirm>
        </>
    );
};

Settings.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default Settings;
