import moment from 'moment';

/**
 * Requirement by customer to reset challenge items every day.
 * This is a momentjs time unit.
 */
export const CHALLENGE_RESET_TIME_UNIT = 'day';

export const removeDuplicateIds = (ids) => Array.from(new Set(ids));

export const removeDuplicateItems = (items) => (
    items.filter((item, index) => {
        const otherIndex = items.findIndex((other) => (
            // unique by id and type
            other.type === item.type && other.id === item.id
        ));
        return index === otherIndex;
    })
);

export const removeOutdatedItems = (items, now) => (
    items.filter(({ timestamp }) => (
        timestamp && moment(timestamp).isSame(now, CHALLENGE_RESET_TIME_UNIT)
    ))
);

export const availableItemsForTask = ({ scenes, actions, triggers, systemTriggers }, allScenes) => {
    const otherItems = scenes.length + actions.length + triggers.length;
    let systemItems = 0;
    if (systemTriggers.includes('OPEN_CALLING_CARD')) {
        const scenesWithCallingCard = allScenes.filter(({ contactEmail }) => !!contactEmail);
        systemItems += scenesWithCallingCard.length;
    }
    return otherItems + systemItems;
};

/** @returns challenge with completed status and tasks progress */
export const challengeProgress = (project, completedItems) => {
    if (!project.challenge || !project.challenge.active) return null;

    // add progress info to each task
    const tasks = [...project.challenge.tasks]
        .sort((a, b) => a.order - b.order)
        .map((task) => {
            const completedItemIds = completedItems
                .filter(({ type }) => !type)
                .map(({ id }) => id);
            const itemProgress = [...task.scenes, ...task.actions, ...task.triggers]
                .filter(({ id }) => completedItemIds.includes(id))
                .length;
            const systemItemProgress = completedItems
                .filter(({ type }) => task.systemTriggers.includes(type))
                .length;
            const progress = itemProgress + systemItemProgress;
            const completed = progress >= task.count;
            return {
                ...task,
                progress,
                completed,
            };
        });

    // add progress info to challenge
    const progress = tasks.filter(({ completed }) => completed).length;
    const completed = progress >= project.challenge.tasks.length;
    return {
        ...project.challenge,
        progress,
        completed,
        tasks,
        completedItems,
    };
};
