import React from 'react';
import InnerHTML from 'InnerHTML';
import PropTypes from 'prop-types';

const NewstickerContent = (props) => (
    <InnerHTML allowedTags={['b', 'strong', 'em', 'i', 'a']}>
        {props.children}
    </InnerHTML>
);

NewstickerContent.propTypes = {
    children: PropTypes.string.isRequired,
};

export default NewstickerContent;
