import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Drawer from '@material-ui/core/Drawer';
import MenuIcon from '@material-ui/icons/Menu';
import Icon from 'Icon';
import Avatar from 'cms/atoms/Avatar';

const Header = (props) => (
    <>
        <AppBar position="fixed">
            <Toolbar>
                <IconButton
                    edge="start"
                    className="ui-cms-header__menu-button"
                    color="inherit"
                    onClick={props.openMenu}
                >
                    <MenuIcon />
                </IconButton>
            </Toolbar>
        </AppBar>

        <Toolbar className="ui-cms-header__spacing" />

        <Drawer
            anchor="left"
            open={props.isMenuOpen}
            onClose={props.closeMenu}
        >
            {props.account && (
                <div className="ui-cms-header__menu-me">
                    <Avatar person={props.account.admin} />

                    {`${props.account.admin.firstName} ${props.account.admin.lastName}`}
                </div>
            )}

            <List className="ui-cms-header__menu-list">
                {props.menu.map((item) => (
                    item.available !== false ? (
                        <ListItem
                            button
                            key={item.label}
                            onClick={item.onClick}
                        >
                            <ListItemIcon className="ui-cms-header__menu-icon">
                                <Icon type={item.icon} />
                            </ListItemIcon>

                            <ListItemText primary={item.label} />
                        </ListItem>
                    ) : null
                ))}
            </List>
        </Drawer>
    </>
);

Header.defaultProps = {
    account: null,
};

Header.propTypes = {
    menu: PropTypes.array.isRequired,
    isMenuOpen: PropTypes.bool.isRequired,
    openMenu: PropTypes.func.isRequired,
    closeMenu: PropTypes.func.isRequired,
    account: PropTypes.object,
};

export default Header;
