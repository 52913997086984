import { useLazyQuery, gql } from '@apollo/client';
import { useQuery } from 'apollo-augmented-hooks';
import { ADMIN } from './admins';

const ME = `
    id
    name
    company
    position
    email
    salutation
    title
    phone
    preferredLanguage {
        code
    }
    videoConferenceUrl
    externalId
`;

const query = gql`
    query me {
        me {
            ${ME}
        }
        myAccount {
            id
            admin {
                ${ADMIN}
            }
        }
    }
`;

export default () => (
    useQuery(query)
);

export const useMeLazy = () => (
    useLazyQuery(query, { fetchPolicy: 'network-only' })
);
