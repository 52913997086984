import { useQuery } from 'apollo-augmented-hooks';
import { SCENE } from './scene';
import { QUOTA } from './customer';

const query = `
    query sceneEditor($id: ID!, $hasId: Boolean!) {
        scene(id: $id) @include(if: $hasId) {
            ${SCENE}
            panoramaChildScenes {
                ${SCENE}
            }
            project {
                id
                name
                path
                loginType
                defaultLanguage {
                    id
                    code
                    systemCode
                }
                languages {
                    id
                    code
                    systemCode
                }
                accessGroups {
                    id
                    name
                }
                buttons {
                    id
                    name
                    type
                    image {
                        id
                        url
                        mini: variation(type: MINI) {
                            url
                        }
                    }
                }
                scenes {
                    id
                    name
                    type
                }
                customer {
                    id
                    name
                    quotas {
                        ${QUOTA}
                    }
                }
                files {
                    file {
                        id
                        url
                        filename
                        mimeType
                    }
                    inUseByActions
                }
                audioBackgrounds {
                    id
                    media {
                        id
                        filename
                    }
                }
            }
        }
    }
`;

export default (id, params) => (
    useQuery(query, {
        variables: {
            id: id,
            hasId: !!id,
        },
        ...params,
    })
);
