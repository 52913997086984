import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useUpdateProject from 'hooks/graphql/mutations/updateProject';
import { apiResponseToFormState } from 'helpers/form';
import EditingAdmin from 'EditingAdmin';
import ColorPicker from 'cms/molecules/ColorPicker';
import FontUpload from 'cms/molecules/FontUpload';
import Switch from 'cms/atoms/Switch';
import KeyValueTable from 'cms/molecules/KeyValueTable';
import Dialog from 'cms/molecules/Dialog';

const defaultValues = {
    id: undefined,
    styling: {
        backgroundColor: '#000000',
        textColor: '#ffffff',
        font: undefined,
        transparentNavbar: false,
    },
};

const Styling = (props) => {
    const [state, setState] = useState(apiResponseToFormState(props.project, defaultValues));
    const updateProject = useUpdateProject();

    const save = async (values) => {
        await updateProject({
            styling: {
                id: props.project.styling.id || undefined,
                backgroundColor: values.styling.backgroundColor.trim() || null,
                textColor: values.styling.textColor.trim() || null,
                fontMediaId: values.styling.font ? values.styling.font.id : null,
                transparentNavbar: values.styling.transparentNavbar,
            },
            id: props.project.id,
        });

        props.onClose();
    };

    const changeFile = (data) => {
        setState((previous) => ({
            ...previous,
            styling: {
                ...previous.styling,
                font: data,
            },
        }));
    };

    const removeFile = () => {
        setState((previous) => ({
            ...previous,
            styling: {
                ...previous.styling,
                font: undefined,
            },
        }));
    };

    return (
        <Dialog
            title="Styling bearbeiten"
            isOpen={props.isOpen}
            onClose={props.onClose}
            onConfirm={save}
            onChange={setState}
            values={state}
        >
            {({ onChangeByValue, onChangeByEvent }) => (
                <>
                    <EditingAdmin name={`project-styling-${props.project.id}`} />

                    <KeyValueTable
                        items={[{
                            key: 'Hintergrundfarbe',
                            value: (
                                <ColorPicker
                                    value={state.styling.backgroundColor}
                                    onChange={onChangeByValue('styling.backgroundColor')}
                                    disableAlpha
                                />
                            ),
                            align: 'top',
                        }, {
                            key: 'Textfarbe',
                            value: (
                                <ColorPicker
                                    value={state.styling.textColor}
                                    onChange={onChangeByValue('styling.textColor')}
                                />
                            ),
                            align: 'top',
                        }, {
                            key: 'Schriftart',
                            value: (
                                <FontUpload
                                    onUpload={changeFile}
                                    onRemove={removeFile}
                                    file={state.styling.font && state.styling.font.filename}
                                />
                            ),
                            align: 'top',
                        }, {
                            key: 'Transparente Menüleiste',
                            value: (
                                <Switch
                                    name="styling.transparentNavbar"
                                    checked={state.styling.transparentNavbar}
                                    onChange={onChangeByEvent}
                                />
                            ),
                        }]}
                    />
                </>
            )}
        </Dialog>
    );
};

Styling.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    project: PropTypes.object.isRequired,
};

export default Styling;
