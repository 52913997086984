import { useSubscription } from 'apollo-augmented-hooks';
import { TRANSLATION } from 'hooks/graphql/helpers/translations';

const NOTIFICATION = `
    id
    title {
        ${TRANSLATION}
    }
    body {
        ${TRANSLATION}
    }
    sentAt
    position
    duration
`;

const subscription = `
    subscription notificationSent($projectId: ID!) {
        notificationSent(projectId: $projectId) {
            ${NOTIFICATION}
        }
    }
`;

export default (projectId) => (
    useSubscription(subscription, {
        variables: {
            projectId,
        },
    })
);
