import { useEffect, useState } from 'react';

export default function useDeviceDetect() {
    const [platform, setPlatform] = useState(false);
    const [browser, setBrowser] = useState(false);
    const [device, setDevice] = useState(false);
    const [res, setRes] = useState(false);

    useEffect(() => {
        const userAgent = typeof navigator === 'undefined' ? '' : navigator.userAgent;
        const navPlatform = typeof navigator === 'undefined' ? '' : navigator.platform;
        const resWidth = window.screen.width;
        const resHeight = window.screen.height;

        const handleBrowserData = (ua, browserName) => {
            if (ua.match(/opr\//i)) {
                browserName = 'opera';
            } else if (ua.match(/firefox|fxios/i)) {
                browserName = 'firefox';
            } else if (ua.match(/edg/i)) {
                browserName = 'edge';
            } else if ((ua.match(/chrome|crios/i)) && (ua.match(/safari/i))) {
                browserName = 'chrome';
            } else if (ua.match(/safari/i)) {
                browserName = 'safari';
            } else {
                browserName = 'No browser detection';
            }
            return browserName;
        };

        const handleDeviceType = (ua, deviceType) => {
            if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
                deviceType = 'tablet';
            }
            if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
                deviceType = 'mobile';
            }
            if (ua === '') {
                deviceType = 'No deviceType detection';
            } else {
                deviceType = 'desktop';
            }
            return deviceType;
        };

        setPlatform(navPlatform);
        setBrowser(handleBrowserData(userAgent, browser));
        setDevice(handleDeviceType(userAgent, device));
        setRes(`${resWidth}x${resHeight}`);
    }, []);
    return { platform, browser, device, res };
}
