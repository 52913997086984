import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from 'cms/atoms/Button';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Paper from '@material-ui/core/Paper';
import Sortable from 'Sortable';
import Switch from 'cms/atoms/Switch';
import Icon from 'Icon';

const EditorList = (props) => {
    const [sortMode, setSortMode] = useState(false);

    const removeHandler = (index) => () => {
        props.onRemove(index);
    };

    const toggleSortMode = () => {
        setSortMode(!sortMode);
    };

    return (
        <FormControl fullWidth error={!!props.error}>
            <div
                className={classNames(
                    'ui-cms-editor-list',
                    { 'ui-cms-editor-list--sort-mode': sortMode }
                )}
            >
                <div className="ui-cms-editor-list__add">
                    {props.onAdd && (
                        <Button
                            icon="add"
                            onClick={props.onAdd}
                        >
                            {props.addLabel}
                        </Button>
                    )}

                    {props.onSort && (
                        <Switch
                            label="Sortieren"
                            checked={sortMode}
                            onChange={toggleSortMode}
                        />
                    )}
                </div>

                <Sortable
                    handle=".ui-cms-editor-list__drag"
                    onChange={sortMode ? props.onSort : null}
                >
                    {React.Children.map(props.children, (child, index) => (
                        <Paper
                            className="ui-cms-editor-list__item"
                            elevation={4}
                            key={index}
                        >
                            <div className="ui-cms-editor-list__drag">
                                <Icon type="drag" />
                            </div>

                            <div className="ui-cms-editor-list__child">
                                {sortMode && props.sortModeContents ? (
                                    <div className="ui-cms-editor-list__sort-mode-content">
                                        {props.sortModeContents[index]}
                                    </div>
                                ) : (
                                    <>
                                        {child}
                                        {props.onRemove && (
                                            <div className="ui-cms-editor-list__remove">
                                                <Button
                                                    icon="delete"
                                                    color="secondary"
                                                    size="small"
                                                    onClick={removeHandler(index)}
                                                >
                                                    {props.removeLabel}
                                                </Button>
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        </Paper>
                    ))}
                </Sortable>
            </div>

            <FormHelperText>{props.error}</FormHelperText>
        </FormControl>
    );
};

EditorList.defaultProps = {
    error: null,
    onSort: null,
    onAdd: null,
    addLabel: '',
    onRemove: null,
    removeLabel: '',
    sortModeContents: null,
};

EditorList.propTypes = {
    addLabel: PropTypes.string,
    onAdd: PropTypes.func,
    removeLabel: PropTypes.string,
    onRemove: PropTypes.func,
    onSort: PropTypes.func,
    children: PropTypes.node.isRequired,
    error: PropTypes.string,
    sortModeContents: PropTypes.arrayOf(PropTypes.string),
};

export default EditorList;
