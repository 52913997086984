import React from 'react';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import Container from 'cms/organisms/Container';
import useProjectStatistics from 'hooks/graphql/queries/projectStatistics';
import Selection from '../../statistics/Selection';

const Content = (props) => {
    const params = useParams();
    const { data, error, loading } = useProjectStatistics(
        params.id,
        props.startDate,
        props.endDate,
    );

    const renderContent = () => (
        <Selection
            statistics={!error ? data.project?.statistics : {}}
            error={error}
        />
    );
    return (
        <Container>{!error ? !loading && renderContent() : renderContent()}</Container>
    );
};

Content.propTypes = {
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
};

export default Content;
