import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import useTranslate from 'hooks/useTranslate';
import InnerHTML from 'InnerHTML';
import Jumbotron from 'web/organisms/Jumbotron';

const Closed = (props) => {
    const t = useTranslate();
    const title = props.startingOn
        ? t('web.closedScreen.upcoming.title')
        : t('web.closedScreen.passed.title');
    const content = props.startingOn
        ? t('web.closedScreen.upcoming.content', { startingOn: moment().to(props.startingOn) })
        : t('web.closedScreen.passed.content');
    const customScreen = t(props.customScreen);

    return customScreen ? (
        <InnerHTML>{customScreen}</InnerHTML>
    ) : (
        <div className="web-project-closed">
            <Jumbotron title={title}>
                {content}
            </Jumbotron>
        </div>
    );
};

Closed.defaultProps = {
    startingOn: null,
    customScreen: [],
};

Closed.propTypes = {
    startingOn: PropTypes.string,
    customScreen: PropTypes.array,
};

export default Closed;
