import React from 'react';
import PropTypes from 'prop-types';
import ErrorScreen from './ErrorBoundary/ErrorScreen';

const infoMessages = {
    'client-offline': 'Es konnte keine Internetverbindung hergestellt werden. Bitte verbinde dein Gerät mit dem Internet.',
};

class ErrorBoundary extends React.Component {
    static getDerivedStateFromError(error) {
        if (!navigator.onLine) {
            return { info: infoMessages['client-offline'] };
        }

        return { message: error.toString() };
    }

    constructor() {
        super();

        this.state = {
            info: null,
            message: null,
            stackTrace: null,
        };
    }

    componentDidCatch(error, info) {
        this.setState({
            message: error.toString(),
            stackTrace: info.componentStack,
        });
    }

    render() {
        if (this.state.message || this.state.info) {
            return <ErrorScreen {...this.state} />;
        }

        return this.props.children;
    }
}

ErrorBoundary.propTypes = {
    children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
