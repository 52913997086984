import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { apiResponseToFormState } from 'helpers/form';
import useProject from 'hooks/graphql/queries/project';
import useCreateVisitorWithCouponCode from 'hooks/graphql/mutations/createVisitorWithCouponCode';
import useUpdateVisitorWithCouponCode from 'hooks/graphql/mutations/updateVisitorWithCouponCode';
import EditingAdmin from 'EditingAdmin';
import TextField from 'cms/atoms/TextField';
import CheckboxGroup from 'cms/atoms/CheckboxGroup';
import KeyValueTable from 'cms/molecules/KeyValueTable';
import Dialog from 'cms/molecules/Dialog';

const defaultValues = {
    couponCode: '',
    privileges: {
        _map: ({ id }) => id,
    },
};

const Visitor = (props) => {
    const params = useParams();

    const { data, loading } = useProject(params.id);
    const { project } = !loading && data;

    const createVisitorWithCouponCode = useCreateVisitorWithCouponCode();
    const updateVisitorWithCouponCode = useUpdateVisitorWithCouponCode();
    const [state, setState] = useState(apiResponseToFormState(props.visitor, defaultValues));
    const validators = [
        {
            name: 'couponCode',
            message: () => 'Bitte gib einen Gutschein ein.',
            isValid: (value) => !!value.trim(),
        },
    ];

    useEffect(() => {
        if (props.isOpen) {
            setState(apiResponseToFormState(props.visitor, defaultValues));
        }
    }, [props.isOpen]);

    const save = async (values) => {
        const mutation = props.visitor ? updateVisitorWithCouponCode : createVisitorWithCouponCode;

        await mutation({
            id: props.visitor ? props.visitor.id : undefined,
            projectId: props.visitor ? undefined : project.id,
            couponCode: values.couponCode.trim(),
            privileges: values.privileges.map((id) => ({ id })),
        });

        props.onClose();
    };

    return !loading && (
        <Dialog
            title={props.visitor ? 'Gutschein bearbeiten' : 'Neuen Gutschein erstellen'}
            isOpen={props.isOpen}
            onClose={props.onClose}
            onConfirm={save}
            onChange={setState}
            values={state}
            validators={validators}
        >
            {({ errors, onChangeByEvent, onChangeByValue }) => (
                <>
                    {props.visitor && (
                        <EditingAdmin name={`project-visitor-${props.visitor.id}`} />
                    )}

                    <KeyValueTable
                        items={[{
                            key: 'Gutschein',
                            value: (
                                <TextField
                                    name="couponCode"
                                    value={state.couponCode}
                                    onChange={onChangeByEvent}
                                    error={errors.couponCode}
                                />
                            ),
                        }, {
                            key: 'Privilegien',
                            value: (
                                <CheckboxGroup
                                    value={state.privileges}
                                    onChange={onChangeByValue('privileges')}
                                    items={
                                        project.accessGroups
                                            .map(({ id, name }) => ({
                                                label: name,
                                                value: id,
                                            }))
                                    }
                                />
                            ),
                            align: 'top',
                        }]}
                    />
                </>
            )}
        </Dialog>
    );
};

Visitor.defaultProps = {
    visitor: null,
};

Visitor.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    visitor: PropTypes.object,
};

export default Visitor;
