import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'Icon';

const EmptyMessage = (props) => (
    <div className="ui-cms-empty-message">
        <Icon type="empty" /> {props.children}
    </div>
);

EmptyMessage.propTypes = {
    children: PropTypes.node.isRequired,
};

export default EmptyMessage;
