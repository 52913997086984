import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useTranslate from 'hooks/useTranslate';
import useTracking from 'hooks/useTracking';
import useToggle from 'hooks/useToggle';
import { TRACKING_SET_PASSWORD } from 'helpers/tracking';
import ProjectModel from 'models/web/project';
import TextField from 'web/atoms/TextField';
import Dialog from 'web/molecules/Dialog';
import FormDialog from 'web/molecules/FormDialog';
// import useGeoLocation from 'hooks/useGeoLocation';
import useDeviceDetect from 'hooks/useDeviceDetect';

const PASSWORD_MIN_LENGTH = 8;

const SetPassword = (props) => {
    const t = useTranslate();
    const [success, showSuccess, hideSuccess] = useToggle(false);
    const tracking = useTracking();
    const [state, setState] = useState({
        password: '',
    });

    const validators = [{
        name: 'password',
        message: () => t('web.setPassword.error.password', { minLength: PASSWORD_MIN_LENGTH }),
        isValid: (value) => value.length >= PASSWORD_MIN_LENGTH,
    }, {
        name: 'password',
        message: () => t('web.setPassword.error.invalidToken'),
        serverError: 'TOKEN_NOT_VALID',
    }, {
        name: 'password',
        message: () => t('web.setPassword.error.emptyToken'),
        serverError: 'NO_TOKEN_PROVIDED',
    }];

    useEffect(() => () => {
        hideSuccess();
    }, [props.isOpen]);
    // const geolocation = useGeoLocation();
    const deviceDetect = useDeviceDetect();
    const confirm = async (values) => {
        const { data } = await props.setVisitorPassword({
            token: props.token,
            password: values.password, // whitespace is valid -> do not trim.
        });
        const visitor = data.setVisitorPassword;
        //  TRACKCORE:
        //   event:'setPasswort', ressource: visitor.account.email, data: {customer: customerId, registration: 'Registration', Latitude: Num, Longitude: Num, Device: String, Browser: String}
        //   NOTE: project is not available here.
        tracking.trackCoreEvent({ resourceType: 'visitorEmail',
            resourceName: visitor.account.email,
            action: 'setPasswort',
            data: [
                {
                    name: 'customer',
                    value: 'customerName',
                },
                {
                    name: 'Registrierung',
                    value: 'Registrierung',
                },
                {
                    name: 'platform',
                    value: deviceDetect.platform,
                },
                {
                    name: 'browser',
                    value: deviceDetect.browser,
                },
                {
                    name: 'device',
                    value: deviceDetect.device,
                },
                {
                    name: 'resolution',
                    value: deviceDetect.res,
                },
            ] });
        tracking.trackCoreEvent({ resourceType: 'visitorEmail',
            resourceName: visitor.account.email,
            action: 'setPasswort',
            data: [
                {
                    name: 'customer',
                    value: 'customerName',
                },
                {
                    name: 'Registrierung',
                    value: 'Registrierung',
                },
                // {
                //     name: 'latitude',
                //     value: geolocation.latitude,
                // },
                // {
                //     name: 'longitude',
                //     value: geolocation.longitude,
                // },
            ] });
        tracking.trackEvent('Registration', TRACKING_SET_PASSWORD, visitor.account.email);
        showSuccess();
    };

    const renderSuccess = () => (
        <Dialog
            isOpen={props.isOpen && success}
            onClose={props.onClose}
            title={t('web.setPassword.title')}
        >
            {t('web.setPassword.success', {
                projectLink: (
                    <a href={`/event/${props.projectPath}/`} rel="noreferrer">
                        {t('web.setPassword.projectLinkText')}
                    </a>
                ),
            })}
        </Dialog>
    );

    const renderForm = () => (
        <FormDialog
            isOpen={props.isOpen && !success}
            title={t('web.setPassword.title')}
            values={state}
            onConfirm={confirm}
            onChange={setState}
            onClose={props.onClose}
            validators={validators}
            withSubmitButton
        >
            {({ errors, onChangeByEvent }) => (
                <>
                    <TextField
                        type="password"
                        label={t(props.loginLabelPassword) || t('web.login.password')}
                        value={state.password}
                        name="password"
                        onChange={onChangeByEvent}
                        error={errors.password}
                    />
                </>
            )}
        </FormDialog>
    );

    return (
        <>
            {renderSuccess()}
            {renderForm()}
        </>
    );
};

SetPassword.defaultProps = {
    loginLabelPassword: null,
    onClose: null,
};

SetPassword.propTypes = {
    setVisitorPassword: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    loginLabelPassword: PropTypes.object,
    token: PropTypes.string.isRequired,
    projectPath: PropTypes.string.isRequired,
};

export default ProjectModel.graphql(SetPassword, { withoutQuery: true });
