export default [
    {
        variable: 'nextStartTime',
        description: 'Startzeitpunkt der nächsten Öffnungszeit',
    },
    {
        variable: 'trigger(name)',
        description: 'Löst den Trigger aus.',
    },
    {
        variable: 'trackEvent(event, optional_data)',
        description: 'Trackt ein beliebiges Ereignis (Analytics).',
    },
    {
        variable: 'visitor',
        description: 'Informationen über den aktuell angemeldeten Nutzer',
    },
];
