import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import useToggle from 'hooks/useToggle';
import useProject from 'hooks/graphql/queries/project';
import useCreateProject from 'hooks/graphql/mutations/createProject';
import useUpdateProject from 'hooks/graphql/mutations/updateProject';
import MeContext from 'contexts/me';
import LocaleContext from 'contexts/locale';
import mediaUrl from 'helpers/mediaUrl';
import {
    systemLanguages, userLanguages,
    hasOptionalTranslation, hasRequiredTranslation,
    resolveLanguageName, filterRemovedLanguage,
    createUpdateTranslationInput,
} from 'helpers/languages';
import { actionTypes } from 'helpers/actions';
import exposedVariables from 'helpers/exposedVariables';
import { apiResponseToFormState } from 'helpers/form';
import filterPath from 'helpers/filterPath';
import { presenceTrackingIntervals, defaultPresenceTrackingInterval } from 'helpers/presenceTracking';
import EditingAdmin from 'EditingAdmin';
import TextField from 'cms/atoms/TextField';
import Switch from 'cms/atoms/Switch';
import Select from 'cms/atoms/Select';
import VariableList from 'cms/atoms/VariableList';
import ImageUpload from 'cms/atoms/ImageUpload';
import FileUpload from 'cms/molecules/FileUpload';
import Confirm from 'components/ui/cms/molecules/Confirm';
import KeyValueTable from 'cms/molecules/KeyValueTable';
import EditorList from 'cms/molecules/EditorList';
import Dialog from 'cms/molecules/Dialog';
import Grid from 'cms/molecules/Grid';
import TranslationField from 'cms/molecules/TranslationField';
import useLocalStorage from '@rehooks/local-storage';
import TranslationCodeInput from 'cms/molecules/TranslationCodeInput';

const defaultValues = {
    id: undefined,
    name: '',
    contact: {
        id: undefined,
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        street: '',
        zip: '',
        city: '',
    },
    path: '',
    websiteTitle: { _translation: 'text' },
    closedScreen: { _translation: 'text' },
    scaleType: 'CENTER_INSIDE',
    widget: { _translation: 'text' },
    demoMode: false,
    presenceTrackingEnabled: false,
    presenceTrackingInterval: defaultPresenceTrackingInterval,
    audioOnLabel: { _translation: 'text' },
    audioOffLabel: { _translation: 'text' },
    defaultLanguageId: {
        _default: '',
        _key: 'defaultLanguage',
        _modify: ({ id }) => id,
    },
    languages: {
        _map: () => ({
            id: undefined,
            code: '',
            systemCode: '',
        }),
    },
    buttons: {
        _map: () => ({
            id: undefined,
            name: '',
            type: '',
            image: {
                id: undefined,
                url: '',
            },
        }),
    },
    files: {
        _map: () => ({
            id: {
                _default: undefined,
                _key: 'file',
                _modify: ({ id }) => id,
            },
            filename: {
                _default: undefined,
                _key: 'file',
                _modify: ({ filename }) => filename,
            },
        }),
    },
    audioBackgrounds: {
        _sort: (a, b) => a.media.filename.localeCompare(b.media.filename),
        _map: () => ({
            id: undefined,
            loop: false,
            media: {
                id: undefined,
                url: '',
                filename: '',
            },
        }),
    },
    logo: {
        id: undefined,
        url: '',
    },
    startSceneId: {
        _default: '',
        _key: 'startScene',
        _modify: ({ id }) => id,
    },
};

const Project = (props) => {
    const params = useParams();
    const { data } = useProject(params.id, { skip: !props.isOpen });
    const project = data?.project;

    const createProject = useCreateProject();
    const updateProject = useUpdateProject();

    const [state, setState] = useState(apiResponseToFormState(project, defaultValues));
    const [activeLanguageCode, setActiveLanguageCode] = useLocalStorage('language', project?.defaultLanguage.code);
    const [isFileErrorOpen, openFileError, closeFileError] = useToggle(false);
    const [
        isAudioBackgroundErrorOpen,
        openAudioBackgroundError,
        closeAudioBackgroundError,
    ] = useToggle(false);
    const [isButtonAlertOpen, openButtonAlert, closeButtonAlert] = useToggle(false);
    const [isDefaultLanguageAlertOpen, openDefaultLanguageAlert, closeDefaultLanguageAlert] = useToggle(false);
    const [deleteLanguageDialogState, setDeleteLanguageDialogState] = useState({
        isOpen: false,
        index: null,
    });
    const me = useContext(MeContext);

    const isUpdateToExistingProject = !!project;
    const usedCodes = state.languages.map(({ code }) => code);
    const remainingLanguages = userLanguages.filter(({ code }) => !usedCodes.includes(code));
    const activeLanguage = state.languages.find(({ code }) => code === activeLanguageCode);
    const setActiveLanguage = (language) => setActiveLanguageCode(language?.code);

    const validators = [
        {
            name: 'name',
            message: () => 'Bitte gib einen Namen ein.',
            isValid: (value) => !!value.trim(),
        },
        {
            name: 'name',
            message: () => 'Es gibt bereits ein Projekt mit diesem Namen.',
            serverError: 'PROJECT_HAS_DUPLICATE_NAME',
        },
        {
            name: 'contact.firstName',
            message: () => 'Bitte gib einen Vornamen ein.',
            isValid: (value, { contact }) => (
                // contact data are not required, but if any are provided, first and last name need
                // to be provided, too
                (
                    !contact.lastName.trim()
                    && !contact.email.trim()
                    && !contact.phone.trim()
                    && !contact.street.trim()
                    && !contact.zip.trim()
                    && !contact.city.trim()
                )
                || !!value.trim()
            ),
        },
        {
            name: 'contact.lastName',
            message: () => 'Bitte gib einen Nachnamen ein.',
            isValid: (value, { contact }) => (
                (
                    !contact.firstName.trim()
                    && !contact.email.trim()
                    && !contact.phone.trim()
                    && !contact.street.trim()
                    && !contact.zip.trim()
                    && !contact.city.trim()
                )
                || !!value.trim()
            ),
        },
        {
            name: 'path',
            message: () => 'Bitte gib einen Pfad ein.',
            isValid: (value) => !!filterPath(value).trim(),
        },
        {
            name: 'path',
            message: () => 'Es gibt bereits ein Projekt mit diesem Pfad.',
            serverError: 'PROJECT_HAS_DUPLICATE_PATH',
        },
        {
            name: 'defaultLanguageId',
            message: () => 'Bitte wähle eine Standard-Sprache.',
            isValid: (value) => !!value,
        },
        {
            name: 'websiteTitle',
            message: () => 'Bitte gib einen Titel für die Standard-Sprache ein.',
            isValid: (value) => hasRequiredTranslation(value, state.defaultLanguageId),
        },
        {
            name: 'closedScreen',
            message: () => 'Bitte gib ebenfalls einen Inhalt für die Standard-Sprache ein.',
            isValid: (value) => hasOptionalTranslation(value, state.defaultLanguageId),
        },
        {
            name: 'audioOnLabel',
            message: () => 'Bitte gib ebenfalls einen Inhalt für die Standard-Sprache ein.',
            isValid: (value) => state.audioBackgrounds.length === 0
                || hasOptionalTranslation(value, state.defaultLanguageId),
        },
        {
            name: 'audioOffLabel',
            message: () => 'Bitte gib ebenfalls einen Inhalt für die Standard-Sprache ein.',
            isValid: (value) => state.audioBackgrounds.length === 0
                || hasOptionalTranslation(value, state.defaultLanguageId),
        },
        {
            name: 'widget',
            message: () => 'Bitte gib ebenfalls einen Inhalt für die Standard-Sprache ein.',
            isValid: (value) => hasOptionalTranslation(value, state.defaultLanguageId),
        },
        {
            name: 'presenceTrackingInterval',
            message: () => 'Bitte gib eine Abtastrate an.',
            isValid: (value) => !state.presenceTrackingEnabled || !!value,
        },
        ...state.buttons.reduce((result, current, index) => [
            ...result, {
                name: `buttons.${index}.image`,
                message: () => 'Bitte lade ein Bild hoch.',
                isValid: (value) => !!value.url,
            }, {
                name: `buttons.${index}.name`,
                message: () => 'Bitte gib einen Namen ein.',
                isValid: (value) => !!value.trim(),
            }, {
                name: `buttons.${index}.type`,
                message: () => 'Bitte wähle eine Art aus.',
                isValid: (value) => !!value,
            },
        ], []),
    ];

    useEffect(() => {
        if (props.isOpen) {
            setState(apiResponseToFormState(project, defaultValues));
        }
    }, [props.isOpen]);

    const generatePath = () => {
        if (!state.path.trim() && me.hasWriteAccessToFeature('project.path')) {
            setState({
                ...state,
                path: filterPath(state.name),
            });
        }
    };

    const changeLogo = (file) => {
        setState((previous) => ({
            ...previous,
            logo: {
                ...previous.logo,
                ...file,
            },
        }));
    };

    const deleteLogo = () => {
        setState({ ...state, logo: {} });
    };

    const changeFile = (newData) => {
        setState((previous) => {
            const index = previous.files.findIndex(({ key }) => key === newData.key);
            const files = index >= 0 ? [
                ...previous.files.slice(0, index),
                {
                    ...previous.files[index],
                    ...newData,
                },
                ...previous.files.slice(index + 1),
            ] : [
                ...previous.files,
                newData,
            ];

            return { ...previous, files };
        });
    };

    const changeAudioBackground = (newData) => {
        setState((previous) => {
            const index = previous.audioBackgrounds
                .findIndex((audioBackground) => audioBackground.media.key === newData.key);
            const files = index >= 0 ? [
                ...previous.audioBackgrounds.slice(0, index),
                {
                    ...previous.audioBackgrounds[index],
                    media: {
                        ...previous.audioBackgrounds[index].audio,
                        ...newData,
                    },
                },
                ...previous.audioBackgrounds.slice(index + 1),
            ] : [
                ...previous.audioBackgrounds,
                {
                    media: newData,
                    loop: true,
                },
            ];

            return { ...previous, audioBackgrounds: files };
        });
    };

    const removeAudioBackground = (index) => {
        const audioBackgrounds = [...state.audioBackgrounds]
            .map((background) => ({ ...background }));

        const isAudioBackgroundUsedBySceneVersion = (scene) => (
            scene.versions
                .filter((sceneVersion) => sceneVersion.audioBackground)
                .some((sceneVersion) => (
                    sceneVersion.audioBackground.id
                    && sceneVersion.audioBackground.id === audioBackgrounds[index].id
                ))
        );

        if (project) {
            const isUsed = project.scenes
                .filter((scene) => scene.audioBackground)
                .some((scene) => ((
                    scene.audioBackground.id
                    && scene.audioBackground.id === audioBackgrounds[index].id
                )
            || isAudioBackgroundUsedBySceneVersion(scene)));

            if (isUsed) {
                openAudioBackgroundError();
                return;
            }
        }

        setState({
            ...state,
            audioBackgrounds: [
                ...audioBackgrounds.slice(0, index),
                ...audioBackgrounds.slice(index + 1),
            ],
        });
    };

    const removeFile = (index) => {
        const files = [...state.files].sort((a, b) => a.filename.localeCompare(b.filename));

        if (project) {
            const toDelete = project.files.find(({ file }) => (
                file.id === files[index].id
            ));

            if (toDelete?.inUseByActions) {
                openFileError();
                return;
            }
        }

        setState({
            ...state,
            files: [
                ...files.slice(0, index),
                ...files.slice(index + 1),
            ],
        });
    };

    const addButton = () => {
        setState((previous) => ({
            ...previous,
            buttons: [
                {
                    name: '',
                    type: '',
                    image: {},
                },
                ...previous.buttons,
            ],
        }));
    };

    const removeButton = (index) => {
        if (state.buttons[index].inUseByActions) {
            openButtonAlert();
            return;
        }

        setState((previous) => ({
            ...previous,
            buttons: [
                ...previous.buttons.slice(0, index),
                ...previous.buttons.slice(index + 1),
            ],
        }));
    };

    const addLanguage = () => {
        if (remainingLanguages.length === 0) {
            return;
        }
        const newLanguage = {
            id: uuid(),
            code: remainingLanguages[0].code,
            systemCode: remainingLanguages[0].systemCode || 'en',
        };
        const isFirstNewLanguage = state.languages.length === 0;
        if (isFirstNewLanguage) {
            setActiveLanguage(newLanguage);
        }
        setState((previous) => ({
            ...previous,
            defaultLanguageId: isFirstNewLanguage ? newLanguage.id : previous.defaultLanguageId,
            languages: [newLanguage, ...previous.languages],
        }));
    };

    const openDeleteLanguageDialog = (index) => {
        const removedLanguage = state.languages[index];
        if (!isUpdateToExistingProject) {
            removeLanguage(index); // no dialog needed
        } else if (removedLanguage.id === state.defaultLanguageId) {
            openDefaultLanguageAlert();
        } else {
            setDeleteLanguageDialogState({ isOpen: true, index });
        }
    };

    const closeDeleteLanguageDialog = () => {
        setDeleteLanguageDialogState({ ...setDeleteLanguageDialogState, isOpen: false });
    };

    const removeLanguage = (index) => {
        const removedLanguage = state.languages[index];
        const newLanguages = [
            ...state.languages.slice(0, index),
            ...state.languages.slice(index + 1),
        ];
        const fallbackLanguage = newLanguages[0] || null;
        if (removedLanguage.id === activeLanguage.id) {
            setActiveLanguage(fallbackLanguage);
        }
        setState((previous) => ({
            ...previous,
            defaultLanguageId: removedLanguage.id === previous.defaultLanguageId
                ? fallbackLanguage?.id
                : previous.defaultLanguageId,
            websiteTitle: filterRemovedLanguage(previous.websiteTitle, removedLanguage.id),
            audioOnLabel: filterRemovedLanguage(previous.audioOnLabel, removedLanguage.id),
            audioOffLabel: filterRemovedLanguage(previous.audioOffLabel, removedLanguage.id),
            closedScreen: filterRemovedLanguage(previous.closedScreen, removedLanguage.id),
            widget: filterRemovedLanguage(previous.widget, removedLanguage.id),
            languages: newLanguages,
        }));
    };

    const save = async (values) => {
        const mutation = project ? updateProject : createProject;

        await mutation({
            id: values.id,
            customerId: props.customerId || undefined,
            name: values.name.trim(),
            contact: values.contact.firstName.trim() && values.contact.lastName.trim() ? {
                ...values.contact,
                firstName: values.contact.firstName.trim(),
                lastName: values.contact.lastName.trim(),
                email: values.contact.email.trim(),
                phone: values.contact.phone.trim(),
                street: values.contact.street.trim(),
                zip: values.contact.zip.trim(),
                city: values.contact.city.trim(),
            } : null,
            path: values.path.trim(),
            audioBackgrounds: values.audioBackgrounds.map((audioBackground) => ({
                id: audioBackground.id || undefined,
                mediaId: audioBackground.media.id,
                loop: audioBackground.loop,
            })),
            websiteTitle: createUpdateTranslationInput(values.websiteTitle),
            closedScreen: createUpdateTranslationInput(values.closedScreen),
            scaleType: values.scaleType,
            widget: createUpdateTranslationInput(values.widget),
            logoMediaId: values.logo.id || null,
            demoMode: values.demoMode,
            defaultLanguageId: values.defaultLanguageId,
            languages: values.languages.map((language) => ({
                id: language.id,
                code: language.code,
                systemCode: language.systemCode,
            })),
            files: values.files.map(({ id }) => ({ id })),
            buttons: values.buttons.map((button) => ({
                id: button.id,
                name: button.name,
                type: button.type,
                imageMediaId: button.image.id,
            })),
            startSceneId: project ? (values.startSceneId || null) : undefined,
            audioOnLabel: createUpdateTranslationInput(values.audioOnLabel),
            audioOffLabel: createUpdateTranslationInput(values.audioOffLabel),
            presenceTrackingEnabled: values.presenceTrackingEnabled,
            presenceTrackingInterval: values.presenceTrackingInterval,
        });

        props.onClose();
    };

    const toggleLoop = (id) => () => {
        setState((previous) => {
            const newBackgrounds = previous.audioBackgrounds.map((background) => ({
                ...background,
                loop: background.id === id
                    ? !background.loop // toggle
                    : background.loop,
            }));

            return {
                ...previous,
                audioBackgrounds: newBackgrounds,
            };
        });
    };

    const renderDialog = () => (
        <Dialog
            title={project ? 'Projekt bearbeiten' : 'Neues Projekt erstellen'}
            isOpen={props.isOpen}
            onClose={props.onClose}
            onConfirm={save}
            onChange={setState}
            values={state}
            validators={validators}
        >
            {({ errors, onChangeByEvent, onChangeByValue }) => (
                <>
                    {project && (
                        <EditingAdmin name={`project-${project}`} />
                    )}

                    <KeyValueTable
                        items={[{
                            key: 'Name',
                            value: (
                                <TextField
                                    name="name"
                                    value={state.name}
                                    onChange={onChangeByEvent}
                                    onBlur={generatePath}
                                    error={errors.name}
                                />
                            ),
                            disabled: !me.hasWriteAccessToFeature('project.name'),
                        }, {
                            key: 'Ansprechpartner',
                            value: (
                                <Grid>
                                    <Grid item size={6}>
                                        <TextField
                                            label="Vorname"
                                            name="contact.firstName"
                                            value={state.contact.firstName}
                                            onChange={onChangeByEvent}
                                            error={errors['contact.firstName']}
                                        />
                                    </Grid>

                                    <Grid item size={6}>
                                        <TextField
                                            label="Nachname"
                                            name="contact.lastName"
                                            value={state.contact.lastName}
                                            onChange={onChangeByEvent}
                                            error={errors['contact.lastName']}
                                        />
                                    </Grid>

                                    <Grid item size={12}>
                                        <TextField
                                            label="E-Mail-Adresse"
                                            name="contact.email"
                                            value={state.contact.email}
                                            onChange={onChangeByEvent}
                                        />
                                    </Grid>

                                    <Grid item size={12}>
                                        <TextField
                                            label="Telefonnummer"
                                            name="contact.phone"
                                            value={state.contact.phone}
                                            onChange={onChangeByEvent}
                                        />
                                    </Grid>

                                    <Grid item size={12}>
                                        <TextField
                                            label="Straße"
                                            name="contact.street"
                                            value={state.contact.street}
                                            onChange={onChangeByEvent}
                                        />
                                    </Grid>

                                    <Grid item size={6}>
                                        <TextField
                                            label="Postleitzahl"
                                            name="contact.zip"
                                            value={state.contact.zip}
                                            onChange={onChangeByEvent}
                                        />
                                    </Grid>

                                    <Grid item size={6}>
                                        <TextField
                                            label="Stadt"
                                            name="contact.city"
                                            value={state.contact.city}
                                            onChange={onChangeByEvent}
                                        />
                                    </Grid>
                                </Grid>
                            ),
                            align: 'top',
                            disabled: !me.hasWriteAccessToFeature('project.contact'),
                        }, {
                            key: 'Demo-Modus',
                            value: (
                                <Switch
                                    name="demoMode"
                                    checked={state.demoMode}
                                    onChange={onChangeByEvent}
                                />
                            ),
                            help: 'Versteckt den Header mit Logo und Navigation und deaktiviert die Zugriffskontrollen, so dass z.B. Links zu Szenen direkt an die Aussteller zum Testen gegeben werden können, ohne dass sie sich über das Menü durch die Messe oder zu anderen Ausstellern klicken können.',
                            disabled: !me.hasWriteAccessToFeature('project.demoMode'),
                        }, {
                            key: 'Pfad in der URL',
                            value: (
                                <TextField
                                    name="path"
                                    value={state.path}
                                    onChange={onChangeByEvent}
                                    filter={filterPath}
                                    error={errors.path}
                                />
                            ),
                            disabled: !me.hasWriteAccessToFeature('project.path'),
                        }, {
                            key: 'Startszene',
                            available: props.scenes.length > 0,
                            value: (
                                <Select
                                    name="startSceneId"
                                    value={state.startSceneId}
                                    onChange={onChangeByEvent}
                                    items={
                                        props.scenes
                                            .filter(({ type }) => type === 'SCENE')
                                            .map(({ name, id }) => ({
                                                label: name,
                                                value: id,
                                            }))
                                    }
                                    error={errors.startSceneId}
                                />
                            ),
                            disabled: !me.hasWriteAccessToFeature('project.startScene'),
                        }, {
                            key: 'Sprachen',
                            help: 'Mindestens eine Sprache wird benötigt. System-Komponenten (Login, Dialoge, etc.) sind noch nicht in allen Sprachen verfügbar.',
                            value: (
                                <>
                                    <EditorList
                                        addLabel="Sprache hinzufügen"
                                        onAdd={addLanguage}
                                        removeLabel="Sprache löschen"
                                        onRemove={openDeleteLanguageDialog}
                                    >
                                        {state.languages.map((language, index) => (
                                            <Grid key={language.id}>
                                                <Grid item size={12}>
                                                    <Select
                                                        label="Sprache"
                                                        name={`languages.${index}.code`}
                                                        value={language.code}
                                                        onChange={onChangeByEvent}
                                                        items={
                                                            [language, ...remainingLanguages].map(({ code }) => ({
                                                                label: resolveLanguageName(code),
                                                                value: code,
                                                            }))
                                                        }
                                                        error={errors[`languages.${index}.code`]}
                                                    />
                                                </Grid>
                                                <Grid item size={12}>
                                                    <Select
                                                        label="System-Sprache"
                                                        name={`languages.${index}.systemCode`}
                                                        value={language.systemCode}
                                                        onChange={onChangeByEvent}
                                                        items={
                                                            systemLanguages.map(({ name, code }) => ({
                                                                label: name,
                                                                value: code,
                                                            }))
                                                        }
                                                        error={errors[`languages.${index}.systemCode`]}
                                                    />
                                                </Grid>
                                            </Grid>
                                        ))}
                                    </EditorList>

                                    <Confirm
                                        title="Soll diese Sprache wirklich gelöscht werden?"
                                        onConfirm={() => {
                                            removeLanguage(deleteLanguageDialogState.index);
                                            closeDeleteLanguageDialog();
                                        }}
                                        confirmLabel="Ja, löschen"
                                        onCancel={closeDeleteLanguageDialog}
                                        cancelLabel="Nein, abbrechen"
                                        isOpen={deleteLanguageDialogState.isOpen}
                                        destructive
                                    >
                                        Alle Übersetzungen für diese Sprache gehen verloren.
                                    </Confirm>

                                    <Confirm
                                        title="Die Standard-Sprache kann nicht gelöscht werden."
                                        onConfirm={closeDefaultLanguageAlert}
                                        isOpen={isDefaultLanguageAlertOpen}
                                    >
                                        Andernfalls könnte es im Projekt Text ohne Übersetzung für
                                        die Standard-Sprache geben. <br /><br />

                                        Der Eintrag für diese Sprache kann jedoch per Dropdown
                                        auf eine andere Sprache geändert werden. Dabei bleiben
                                        alle bestehenden Texte für diese Sprache unverändert
                                        erhalten und müssen für die neue Sprache übersetzt werden.
                                    </Confirm>
                                </>
                            ),
                            align: 'top',
                            disabled: !me.hasWriteAccessToFeature('project.language'),
                        }, {
                            key: 'Standard-Sprache',
                            value: (
                                <Select
                                    name="defaultLanguageId"
                                    value={state.defaultLanguageId}
                                    onChange={onChangeByEvent}
                                    items={state.languages.map(({ id, code }) => ({
                                        label: resolveLanguageName(code),
                                        value: id,
                                    }))}
                                    error={errors.defaultLanguageId}
                                />
                            ),
                            disabled: !me.hasWriteAccessToFeature('project.language') || isUpdateToExistingProject,
                        }, {
                            key: 'Webseiten-Titel',
                            value: (
                                <TranslationField
                                    name="websiteTitle"
                                    value={state.websiteTitle}
                                    onChange={onChangeByValue('websiteTitle')}
                                    error={errors.websiteTitle}
                                />
                            ),
                            disabled: !me.hasWriteAccessToFeature('project.websiteTitle'),
                        }, {
                            key: 'Projektspezifische Buttons',
                            value: (
                                <>
                                    <EditorList
                                        addLabel="Button hinzufügen"
                                        onAdd={addButton}
                                        removeLabel="Button löschen"
                                        onRemove={removeButton}
                                    >
                                        {state.buttons.map((button, index) => (
                                            <Grid key={index}>
                                                <Grid item size={12}>
                                                    <ImageUpload
                                                        onUpload={onChangeByValue(`buttons.${index}.image`)}
                                                        url={mediaUrl(button.image, 'mini')}
                                                        error={errors[`buttons.${index}.image`]}
                                                        maxWidth={64}
                                                    />
                                                </Grid>

                                                <Grid item size={12}>
                                                    <TextField
                                                        label="Name"
                                                        name={`buttons.${index}.name`}
                                                        value={button.name}
                                                        onChange={onChangeByEvent}
                                                        error={errors[`buttons.${index}.name`]}
                                                    />
                                                </Grid>

                                                <Grid item size={12}>
                                                    <Select
                                                        label="Art"
                                                        name={`buttons.${index}.type`}
                                                        value={button.type}
                                                        onChange={onChangeByEvent}
                                                        items={actionTypes}
                                                        error={errors[`buttons.${index}.type`]}
                                                    />
                                                </Grid>
                                            </Grid>
                                        ))}
                                    </EditorList>

                                    <Confirm
                                        title="Dieser Button kann nicht gelöscht werden."
                                        onConfirm={closeButtonAlert}
                                        isOpen={isButtonAlertOpen}
                                    >
                                        Es gibt noch Szenen, in denen dieser Button genutzt wird.
                                    </Confirm>
                                </>
                            ),
                            align: 'top',
                            disabled: !me.hasWriteAccessToFeature('project.buttons'),
                        }, {
                            key: 'Projektdateien',
                            value: (
                                <>
                                    <FileUpload
                                        onUpload={changeFile}
                                        onRemove={removeFile}
                                        files={
                                            [...state.files]
                                                .map(({ filename }) => ({ label: filename }))
                                                .sort((a, b) => a.label.localeCompare(b.label))
                                        }
                                    />

                                    <Confirm
                                        title="Diese Datei kann nicht gelöscht werden."
                                        onConfirm={closeFileError}
                                        isOpen={isFileErrorOpen}
                                    >
                                        Es gibt noch Szenen, in denen sie genutzt wird.
                                    </Confirm>
                                </>
                            ),
                            align: 'top',
                            disabled: !me.hasWriteAccessToFeature('project.files'),
                        }, {
                            key: 'Audiohintergründe',
                            value: (
                                <>
                                    <FileUpload
                                        onUpload={changeAudioBackground}
                                        onRemove={removeAudioBackground}
                                        files={state.audioBackgrounds
                                            .map((audioBackground) => ({
                                                label: audioBackground.media.filename,
                                                button: {
                                                    icon: audioBackground.loop ? 'repeat_on' : 'repeat_off',
                                                    onClick: toggleLoop(audioBackground.id),
                                                },
                                            }))}
                                    />

                                    <Confirm
                                        title="Diese Audiokulisse kann nicht gelöscht werden."
                                        onConfirm={closeAudioBackgroundError}
                                        isOpen={isAudioBackgroundErrorOpen}
                                    >
                                        Es gibt noch Szenen, in denen sie genutzt wird.
                                    </Confirm>
                                </>
                            ),
                            align: 'top',
                            disabled: !me.hasWriteAccessToFeature('project.audioBackgrounds'),
                        }, {
                            key: 'Audio an-Menüpunkt',
                            value: (
                                <TranslationField
                                    name="audioOnLabel"
                                    value={state.audioOnLabel}
                                    onChange={onChangeByValue('audioOnLabel')}
                                    error={errors.audioOnLabel}
                                />
                            ),
                            available: state.audioBackgrounds.length > 0,
                            disabled: !me.hasWriteAccessToFeature('project.audioBackgrounds'),
                        }, {
                            key: 'Audio aus-Menüpunkt',
                            value: (
                                <TranslationField
                                    name="audioOffLabel"
                                    value={state.audioOffLabel}
                                    onChange={onChangeByValue('audioOffLabel')}
                                    error={errors.audioOffLabel}
                                />
                            ),
                            available: state.audioBackgrounds.length > 0,
                            disabled: !me.hasWriteAccessToFeature('project.audioBackgrounds'),
                        }, {
                            key: 'Eigener Code',
                            value: (
                                <TranslationCodeInput
                                    value={state.widget}
                                    onChange={onChangeByValue('widget')}
                                    projectFiles={project && project.files}
                                    error={errors.widget}
                                />
                            ),
                            help: <VariableList items={exposedVariables} />,
                            align: 'top',
                            disabled: !me.hasWriteAccessToFeature('project.widget'),
                        }, {
                            key: 'Geschlossen-Screen',
                            value: (
                                <TranslationCodeInput
                                    value={state.closedScreen}
                                    onChange={onChangeByValue('closedScreen')}
                                    projectFiles={project && project.files}
                                    error={errors.closedScreen}
                                />
                            ),
                            help: <VariableList items={exposedVariables} />,
                            align: 'top',
                            disabled: !me.hasWriteAccessToFeature('project.closedScreen'),
                        }, {
                            key: 'Hintergrund-Skalierung',
                            value: (
                                <Select
                                    name="scaleType"
                                    value={state.scaleType}
                                    onChange={onChangeByEvent}
                                    items={[
                                        { label: 'Im Fenster zentrieren', value: 'CENTER_INSIDE' },
                                        { label: 'Breite ausfüllen', value: 'FILL_WIDTH' },
                                    ]}
                                />
                            ),
                            disabled: !me.hasWriteAccessToFeature('project.scaleType'),
                        }, {
                            key: 'Logo',
                            value: (
                                <ImageUpload
                                    onUpload={changeLogo}
                                    onDelete={deleteLogo}
                                    url={mediaUrl(state.logo, 'thumbnail')}
                                    error={errors.logo}
                                    maxWidth={400}
                                />
                            ),
                            align: 'top',
                        }, {
                            key: 'Anwesenheitstracking',
                            value: (
                                <Switch
                                    name="presenceTrackingEnabled"
                                    checked={state.presenceTrackingEnabled}
                                    onChange={onChangeByEvent}
                                />
                            ),
                            disabled: !me.hasWriteAccessToFeature('project.presenceTracking'),
                        }, {
                            key: 'Anwesenheitstracking Abtastrate',
                            value: (
                                <Select
                                    name="presenceTrackingInterval"
                                    value={state.presenceTrackingInterval}
                                    onChange={onChangeByEvent}
                                    error={errors.presenceTrackingInterval}
                                    items={presenceTrackingIntervals}
                                />
                            ),
                            available: state.presenceTrackingEnabled,
                            disabled: !me.hasWriteAccessToFeature('project.presenceTracking'),
                        }]}
                    />
                </>
            )}
        </Dialog>
    );

    // We handle the locale context here, as languages can be edited, deleted or created, which
    // interferes with translatable fields.
    return (
        <LocaleContext.Provider
            value={{
                language: activeLanguage,
                languages: state.languages,
                defaultLanguage: state.languages.find(({ id }) => id === state.defaultLanguageId),
                setLanguage: setActiveLanguage,
            }}
        >
            {renderDialog()}
        </LocaleContext.Provider>
    );
};

Project.defaultProps = {
    customerId: null,
    scenes: [],
};

Project.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    customerId: PropTypes.string,
    scenes: PropTypes.array,
};

export default Project;
