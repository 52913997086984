import React from 'react';
import PropTypes from 'prop-types';
import Dialog from 'web/molecules/Dialog';
import VideoContent from './shared/VideoContent';

const VideoDialog = (props) => (
    <Dialog
        isOpen={props.isOpen}
        onClose={props.onClose}
        title={props.title}
    >
        <VideoContent
            title={props.title}
            videos={props.videos}
            sceneName={props.sceneName}
        />
    </Dialog>
);

VideoDialog.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    videos: PropTypes.array.isRequired,
    sceneName: PropTypes.string.isRequired,
};

export default VideoDialog;
