import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import StylingContext from 'contexts/styling';

const CloseButton = (props) => {
    const styling = useContext(StylingContext);

    return (
        <button
            type="button"
            className="close"
            style={{ color: styling.textColor }}
            onClick={props.onClose}
        >
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
        </button>
    );
};

CloseButton.propTypes = {
    onClose: PropTypes.func.isRequired,
};

export default CloseButton;
