import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import FileContext from 'contexts/files';
import LocaleContext from 'contexts/locale';
import StylingContext from 'contexts/styling';
import { findSupportedLanguage } from 'helpers/languages';
import Container from 'web/organisms/Container';
import ProjectModel from 'models/web/project';
import Helmet from './Project/Helmet';
import Registration from './Project/Registration';

const RegistrationWidget = (props) => {
    const renderForm = ({
        id, loginType, loginLabelUser, registrationInstructions,
        registrationFields,
    }) => (
        <Registration
            projectId={id}
            loginType={loginType}
            registrationInstructions={registrationInstructions}
            registrationFields={registrationFields}
            loginLabelUser={loginLabelUser}
            isOpen
        />
    );

    const renderContent = () => {
        if (!props.data.projectByPath) {
            // TODO: Handle 404
            return <div>404</div>;
        }

        const {
            websiteTitle, languages, defaultLanguage, styling, files,
        } = props.data.projectByPath;

        const language = findSupportedLanguage(window, languages, defaultLanguage);

        return (
            <>
                <LocaleContext.Provider value={{ language, languages, defaultLanguage }}>
                    <StylingContext.Provider value={styling}>
                        <FileContext.Provider value={files}>
                            <Helmet title={websiteTitle} />
                            {renderForm(props.data.projectByPath)}
                        </FileContext.Provider>
                    </StylingContext.Provider>
                </LocaleContext.Provider>
            </>
        );
    };

    return <Container>{!props.data.loading && renderContent()}</Container>;
};

RegistrationWidget.propTypes = {
    data: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};

export default withRouter(ProjectModel.graphql(RegistrationWidget));
