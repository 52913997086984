import { useMutation } from 'apollo-augmented-hooks';
var mutation = "\n    mutation deleteCustomer($id: ID!) {\n        deleteCustomer(id: $id) {\n            id\n        }\n    }\n";
export default (function () {
    var mutate = useMutation(mutation)[0];
    return function (input) { return (mutate({
        input: input,
        optimisticResponse: {
            __typename: 'Customer',
            id: input,
        },
        modifiers: [{
                cacheObject: function (item) { return item; },
                evict: true,
            }],
    })); };
});
