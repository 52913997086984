import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import useDeleteQuota from 'hooks/graphql/mutations/deleteQuota';
import MeContext from 'contexts/me';
import Button from 'cms/atoms/Button';
import Headline from 'cms/atoms/Headline';
import TextField from 'cms/atoms/TextField';
import DataTable from 'cms/molecules/DataTable';
import Confirm from 'components/ui/cms/molecules/Confirm';
import QuotaEditor from '../editors/Customer/Quota';

const Quotas = (props) => {
    const me = useContext(MeContext);
    const deleteQuota = useDeleteQuota();
    const [quotaEditorState, setQuotaEditorState] = useState({ isOpen: false, id: null });
    const [filter, setFilter] = useState('');
    const [deleteQuotaDialogState, setDeleteQuotaDialogState] = useState({
        isOpen: false, id: null,
    });

    const filterByName = (event) => {
        setFilter(event.target.value);
    };

    const openQuotaEditorHandler = (id) => () => {
        setQuotaEditorState({ isOpen: true, id });
    };

    const closeQuotaEditor = () => {
        setQuotaEditorState({ ...quotaEditorState, isOpen: false });
    };

    const openDeleteQuotaDialogHandler = (id) => () => {
        setDeleteQuotaDialogState({ isOpen: true, id });
    };

    const closeDeleteQuotaDialog = () => {
        setDeleteQuotaDialogState({ ...deleteQuotaDialogState, isOpen: false });
    };

    const deleteQuotaHandler = () => {
        deleteQuota(deleteQuotaDialogState.id);
        closeDeleteQuotaDialog();
    };

    return (
        <>
            <Headline
                action={me.hasWriteAccessToFeature('customer.quotas') && (
                    <Button
                        icon="add"
                        onClick={openQuotaEditorHandler(null)}
                    >
                        Neues Kontingent
                    </Button>
                )}
            >
                Kontingente
            </Headline>

            <QuotaEditor
                isOpen={quotaEditorState.isOpen}
                onClose={closeQuotaEditor}
                customerId={props.customer.id}
                quota={props.customer.quotas.find(({ id }) => id === quotaEditorState.id)}
                quotaSubjects={props.quotaSubjects}
            />

            <Confirm
                title="Soll dieses Kontingent wirklich gelöscht werden?"
                onConfirm={deleteQuotaHandler}
                confirmLabel="Ja, löschen"
                onCancel={closeDeleteQuotaDialog}
                cancelLabel="Nein, abbrechen"
                isOpen={deleteQuotaDialogState.isOpen}
                destructive
            >
                Elemente (z.B. Szenen), welche gerade dieses Kontingent besitzen, verlieren
                ihr Koningent und sind dann nicht mehr in ihren Rechten
                eingeschränkt.
            </Confirm>

            <DataTable
                emptyMessage="Es wurden keine Kontingente gefunden."
                filters={(
                    <TextField
                        value={filter}
                        onChange={filterByName}
                        label="Nach Name filtern"
                        icon="search"
                    />
                )}
                columns={[
                    { dataKey: 'name', label: 'Name' },
                    { dataKey: 'actions', label: 'Aktionen', width: 100, align: 'center' },
                ]}
                rows={
                    props.customer.quotas
                        .filter((quota) => {
                            const sanitizedFilter = filter.trim().toLowerCase();

                            return (
                                !sanitizedFilter
                                || quota.name.toLowerCase().includes(sanitizedFilter)
                            );
                        })
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((quota) => ({
                            name: quota.name,
                            actions: (
                                <>
                                    <Button
                                        icon="edit"
                                        onClick={openQuotaEditorHandler(quota.id)}
                                    />

                                    {me.hasWriteAccessToFeature('customer.quotas') && (
                                        <Button
                                            icon="delete"
                                            onClick={openDeleteQuotaDialogHandler(quota.id)}
                                            color="secondary"
                                        />
                                    )}
                                </>
                            ),
                        }))
                }
            />
        </>
    );
};

Quotas.propTypes = {
    customer: PropTypes.object.isRequired,
    quotaSubjects: PropTypes.array.isRequired,
};

export default Quotas;
