import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

const Headline = (props) => (
    <div className="ui-cms-headline">
        <Typography
            variant="h4"
            component="h2"
            className="ui-cms-headline__content"
        >
            {props.children}
        </Typography>

        <div className="ui-cms-headline__action">
            {props.action}
        </div>
    </div>
);

Headline.defaultProps = {
    action: null,
};

Headline.propTypes = {
    children: PropTypes.node.isRequired,
    action: PropTypes.node,
};

export default Headline;
