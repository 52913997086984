import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import MuiDialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogHelper from 'DialogHelper';
import Icon from 'Icon';
import Button from 'cms/atoms/Button';
import Loader from 'cms/atoms/Loader';

const useStyles = makeStyles(() => ({
    paper: (props) => ({
        minWidth: `${props.minWidth}%`,
        position: 'relative',
    }),
    submit: {
        display: 'none',
    },
    progress: {
        alignItems: 'center',
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        left: 0,
        opacity: 0,
        pointerEvents: 'none',
        position: 'absolute',
        top: 0,
        transition: 'opacity 300ms',
        width: '100%',
    },
    progressLoading: {
        opacity: 1,
        pointerEvents: null,
    },
    progressBackground: {
        backgroundColor: 'rgba(255, 255, 255, .5)',
        height: '100%',
        left: 0,
        position: 'absolute',
        top: 0,
        transition: 'opacity 300ms',
        width: '100%',
    },
    close: {
        color: 'white',
        cursor: 'pointer',
        right: 32,
        position: 'fixed',
        top: 32,
    },
}));

const Dialog = (props) => {
    const classes = useStyles(props);

    return (
        <DialogHelper
            values={props.values}
            isOpen={props.isOpen}
            onConfirm={props.onConfirm}
            onChange={props.onChange}
            validators={props.validators}
            preventFirstTextFieldFocus={props.preventFirstTextFieldFocus}
            firstInputSelector=".ui-cms-text-field input"
        >
            {({
                onConfirm,
                onChangeByEvent,
                onChangeByValue,
                loading,
                uploadProgress,
                errors,
                dialogRef,
            }) => (
                <MuiDialog
                    open={props.isOpen}
                    classes={{ paper: classes.paper }}
                    ref={dialogRef}
                    scroll="paper"
                    disableEnforceFocus // otherwise TinyCME's dialogs are not editable
                >
                    <DialogTitle className={classes.actions}>
                        {props.title}
                    </DialogTitle>

                    <DialogContent dividers>
                        <form onSubmit={onConfirm}>
                            {props.children({ errors, onChangeByEvent, onChangeByValue, dialogRef })}

                            {/* hidden submit button so saving on enter works */}
                            <div className={classes.submit}>
                                <Button type="submit" />
                            </div>
                        </form>
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={onConfirm} color="primary">
                            {props.confirmLabel}
                        </Button>
                    </DialogActions>

                    <div
                        className={classNames(
                            classes.progress,
                            { [classes.progressLoading]: loading }
                        )}
                    >
                        <div className={classes.progressBackground} />

                        <Loader value={uploadProgress} />
                    </div>

                    <div
                        className={classes.close}
                        onClick={props.onClose}
                    >
                        <Icon type="cancel" />
                    </div>
                </MuiDialog>
            )}
        </DialogHelper>
    );
};

Dialog.defaultProps = {
    minWidth: 50,
    confirmLabel: 'Speichern',
    validators: [],
    preventFirstTextFieldFocus: false,
};

Dialog.propTypes = {
    values: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    children: PropTypes.func.isRequired,
    minWidth: PropTypes.number,
    confirmLabel: PropTypes.string,
    validators: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        message: PropTypes.func.isRequired,
        isValid: PropTypes.func,
        serverError: PropTypes.string,
    })),
    preventFirstTextFieldFocus: PropTypes.bool,
};

export default Dialog;
