import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

const Popup = (props) => (
    <Dialog
        fullWidth
        maxWidth="lg"
        open={props.isOpen}
    >
        { props.title && (
            <DialogTitle>
                {props.title}
            </DialogTitle>
        )}

        { props.children && (
            <DialogContent dividers>
                {props.children}
            </DialogContent>
        )}

        <DialogActions>
            <Button
                color="primary"
                onClick={props.onClose}
            >
                Schließen
            </Button>
        </DialogActions>
    </Dialog>
);

Popup.defaultProps = {
    title: null,
    children: null,
};

Popup.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.node,
    children: PropTypes.node,
};

export default Popup;
