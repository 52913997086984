/**
 * Canvas does not support automatic linebreaks, so this is done manually
 * Important: Canvas width and font styling must already be set.
 */
export const calculateLines = (text, context, maxWidth) => {
    const words = text.split(' ');
    const lines = [];
    let currentLine = '';
    for (const word of words) {
        const width = context.measureText(`${currentLine} ${word}`).width;
        if (width < maxWidth) {
            currentLine += ` ${word}`;
        } else {
            lines.push(currentLine);
            currentLine = word;
        }
    }
    lines.push(currentLine);
    return lines;
};

/** @see https://newfivefour.com/javascript-canvas-rounded-rectangle.html */
export const roundedRectangle = (context, x, y, width, height, radius) => {
    const halfRadians = (2 * Math.PI) / 2;
    const quarterRadians = (2 * Math.PI) / 4;

    // top left arc
    context.arc(radius + x, radius + y, radius, -quarterRadians, halfRadians, true);

    // line from top left to bottom left
    context.lineTo(x, y + height - radius);

    // bottom left arc
    context.arc(radius + x, height - radius + y, radius, halfRadians, quarterRadians, true);

    // line from bottom left to bottom right
    context.lineTo(x + width - radius, y + height);

    // bottom right arc
    context.arc(x + width - radius, y + height - radius, radius, quarterRadians, 0, true);

    // line from bottom right to top right
    context.lineTo(x + width, y + radius);

    // top right arc
    context.arc(x + width - radius, y + radius, radius, 0, -quarterRadians, true);

    // line from top right to top left
    context.lineTo(x + radius, y);
};
