import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import useToggle from 'hooks/useToggle';
import useTranslate from 'hooks/useTranslate';
import useProject from 'hooks/graphql/queries/project';
import useDeleteProject from 'hooks/graphql/mutations/deleteProject';
import MeContext from 'contexts/me';
import mediaUrl from 'helpers/mediaUrl';
import { resolveLanguageName } from 'helpers/languages';
import { presenceTrackingIntervalMap } from 'helpers/presenceTracking';
import Image from 'Image';
import Button from 'cms/atoms/Button';
import Headline from 'cms/atoms/Headline';
import Warning from 'cms/atoms/Warning';
import ButtonList from 'cms/atoms/ButtonList';
import FileList from 'cms/atoms/FileList';
import LabelList from 'cms/atoms/LabelList';
import Confirm from 'components/ui/cms/molecules/Confirm';
import KeyValueTable from 'cms/molecules/KeyValueTable';
import ProjectEditor from '../editors/Project';

const General = (props) => {
    const history = useHistory();
    const params = useParams();
    const t = useTranslate();
    const me = useContext(MeContext);

    const { data, loading } = useProject(params.id);
    const { project } = !loading && data;
    const deleteProject = useDeleteProject();

    const [isProjectEditorOpen, openProjectEditor, closeProjectEditor] = useToggle(false);
    const [isDeleteDialogOpen, openDeleteDialog, closeDeleteDialog] = useToggle(false);
    const { contact } = !loading && project;

    const handleDeleteProject = () => {
        deleteProject(project.id);
        history.replace(`/cms/customer/${project.customer.id}`);
    };

    return !loading && (
        <>
            <Headline
                action={me.hasWriteAccessToFeature('project.general') ? (
                    <Button
                        icon="edit"
                        onClick={openProjectEditor}
                    >
                        Bearbeiten
                    </Button>
                ) : null}
            >
                Allgemeines
            </Headline>

            <ProjectEditor
                isOpen={isProjectEditorOpen}
                onClose={closeProjectEditor}
                project={project}
                scenes={props.scenes}
            />

            <KeyValueTable
                wrappingDividers
                items={[
                    {
                        key: 'Name',
                        value: project.name,
                    },
                    {
                        key: 'Ansprechpartner',
                        value: contact ? (
                            <>
                                <div>{`${contact.firstName} ${contact.lastName}`}</div>
                                <div>{contact.street}</div>
                                {contact.zip && contact.city && (
                                    <div>{`${contact.zip} ${contact.city}`}</div>
                                )}
                                <div>{contact.phone}</div>
                                <div>{contact.email}</div>
                            </>
                        ) : 'Nicht angegeben',
                        align: 'top',
                    },
                    {
                        key: 'Demo-Modus',
                        value: project.demoMode
                            ? (
                                <>
                                    <Warning>
                                        Aktiv. Nicht vergessen, den Demo-Modus vor Messebeginn
                                        wieder abzuschalten!
                                    </Warning>
                                </>
                            )
                            : 'Inaktiv',
                    },
                    {
                        key: 'Vorschau',
                        value: (
                            <Button
                                icon="view"
                                href={`/event/${project.path}`}
                                newTab
                            >
                                Ansehen
                            </Button>
                        ),
                    },
                    {
                        key: 'Pfad in der URL',
                        value: project.path,
                    },
                    {
                        key: 'Startszene',
                        value: project.startScene ? (
                            project.startScene.name
                        ) : (
                            <Warning>Es wurde noch keine Startszene festgelegt</Warning>
                        ),
                    },
                    {
                        key: 'Sprachen',
                        value: (
                            <LabelList
                                labels={
                                    project.languages.map(((language) => ({
                                        label: resolveLanguageName(language.code),
                                        remark: project.defaultLanguage.id === language.id
                                            ? 'Standard'
                                            : null,
                                    })))
                                }
                            />
                        ),
                        align: 'top',
                    },
                    {
                        key: 'Webseiten-Titel',
                        value: t(project.websiteTitle),
                    },
                    {
                        key: 'Projektspezifische Buttons',
                        value: project.buttons.length > 0 ? (
                            <ButtonList
                                buttons={project.buttons.map((button) => ({
                                    image: mediaUrl(button.image, 'mini'),
                                    name: button.name,
                                    type: button.type,
                                }))}
                            />
                        ) : 'Keine',
                        align: 'top',
                    },
                    {
                        key: 'Projektdateien',
                        value: project.files.length > 0 ? (
                            <FileList
                                files={
                                    [...project.files]
                                        .map((({ file }) => ({ label: file.filename })))
                                        .sort((a, b) => a.label.localeCompare(b.label))
                                }
                            />
                        ) : 'Keine',
                        align: 'top',
                    },
                    {
                        key: 'Audiokulissen',
                        value: project.audioBackgrounds.length > 0 ? (
                            <FileList
                                files={
                                    [...project.audioBackgrounds]
                                        .map((({ media }) => ({ label: media.filename })))
                                        .sort((a, b) => a.label.localeCompare(b.label))
                                }
                            />
                        ) : 'Keine',
                        align: 'top',
                    },
                    {
                        key: 'Eigener Code',
                        value: t(project.widget) ? 'Ja' : 'Nein',
                    },
                    {
                        key: 'Geschlossen-Screen',
                        value: t(project.closedScreen) ? 'Eigener' : 'Standard',
                    },
                    {
                        key: 'Hintergrund-Skalierung',
                        value: {
                            CENTER_INSIDE: 'Im Fenster zentrieren',
                            FILL_WIDTH: 'Breite ausfüllen',
                        }[project.scaleType],
                    },
                    {
                        key: 'Logo',
                        value: project.logo ? (
                            <Image
                                url={mediaUrl(project.logo, 'thumbnail')}
                                maxWidth={400}
                            />
                        ) : (
                            'Ohne Logo'
                        ),
                        align: 'top',
                    },
                    {
                        key: 'Anwesenheitstracking',
                        value: project.presenceTrackingEnabled
                            ? 'Aktiv'
                            : 'Inaktiv',
                    },
                    {
                        key: 'Anwesenheitstracking Abtastrate',
                        available: project.presenceTrackingEnabled,
                        value: presenceTrackingIntervalMap[project.presenceTrackingInterval],
                    },
                ]}
            />

            <div className="ui-cms-button-container--centered">
                {me.hasWriteAccessToFeature('project.delete') && (
                    <>
                        <Button
                            icon="delete"
                            onClick={openDeleteDialog}
                            className="ui-cms-button--danger"
                        >
                            Projekt löschen
                        </Button>
                        <Confirm
                            title="Soll dieses Projekt inklusive all seiner Szenen wirklich gelöscht werden?"
                            confirmationCode="delete"
                            onConfirm={handleDeleteProject}
                            confirmLabel="Ja, löschen"
                            onCancel={closeDeleteDialog}
                            cancelLabel="Nein, abbrechen"
                            isOpen={isDeleteDialogOpen}
                            destructive
                        >
                            <Warning>
                                Achtung: Das gesamte Projekt inklusive all seiner Szenen wird
                                unwiderruflich gelöscht!
                            </Warning>
                        </Confirm>
                    </>
                )}
            </div>
        </>
    );
};

General.propTypes = {
    scenes: PropTypes.array.isRequired,
};

export default General;
