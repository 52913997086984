import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { apiResponseToFormState } from 'helpers/form';
import useUpdateProject from 'hooks/graphql/mutations/updateProject';
import openingTypes from 'helpers/openingTypes';
import EditingAdmin from 'EditingAdmin';
import DateTimePicker from 'cms/atoms/DateTimePicker';
import Select from 'cms/atoms/Select';
import KeyValueTable from 'cms/molecules/KeyValueTable';
import EditorList from 'cms/molecules/EditorList';
import Dialog from 'cms/molecules/Dialog';
import Grid from 'cms/molecules/Grid';

const defaultValues = {
    openingType: 'OPEN',
    openingTimes: {
        _map: () => ({
            id: undefined,
            startTime: {
                _modify: (value) => moment(value),
            },
            endTime: {
                _modify: (value) => moment(value),
            },
        }),
    },
    onDemandStartTime: {
        _default: null,
        _modify: (value) => moment(value),
    },
    onDemandEndTime: {
        _default: null,
        _modify: (value) => moment(value),
    },
};

const OpeningTimes = (props) => {
    const [state, setState] = useState(apiResponseToFormState(props.project, defaultValues));
    const updateProject = useUpdateProject();
    const validators = [
        {
            name: 'onDemandStartTime',
            message: () => 'Bitte gib einen Startzeitpunkt ein.',
            isValid: (value, otherValues) => !otherValues.onDemandEndTime || !!value,
        },
        {
            name: 'onDemandEndTime',
            message: () => 'Bitte gib einen Endzeitpunkt ein.',
            isValid: (value, otherValues) => !otherValues.onDemandStartTime || !!value,
        }, {
            name: 'onDemandEndTime',
            message: () => 'Der Endzeitpunkt muss nach dem Startzeitpunkt liegen.',
            isValid: (value, { onDemandStartTime }) => (
                !value || value.isAfter(onDemandStartTime)
            ),
        },
        {
            name: 'onDemandStartTime',
            message: () => 'Die On-Demand-Zeit darf sich nicht mit einer Öffnungszeit überschneiden',
            isValid: (value, { openingTimes }) => (
                !value
                || !openingTimes.some(({ startTime, endTime }) => (
                    value.isBetween(startTime, endTime)
                ))
            ),
        },
        {
            name: 'onDemandEndTime',
            message: () => 'Die On-Demand-Zeit darf sich nicht mit einer Öffnungszeit überschneiden',
            isValid: (value, { openingTimes }) => (
                !value
                || !openingTimes.some(({ startTime, endTime }) => (
                    value.isBetween(startTime, endTime)
                ))
            ),
        },
        ...state.openingTimes.reduce((result, current, index) => [
            ...result,
            {
                name: `openingTimes.${index}.startTime`,
                message: () => 'Bitte gib einen Startzeitpunkt ein.',
                isValid: (value) => !!value,
            }, {
                name: `openingTimes.${index}.endTime`,
                message: () => 'Bitte gib einen Endzeitpunkt ein.',
                isValid: (value) => !!value,
            }, {
                name: `openingTimes.${index}.endTime`,
                message: () => 'Der Endzeitpunkt muss nach dem Startzeitpunkt liegen.',
                isValid: (value, { openingTimes }) => (
                    value.isAfter(openingTimes[index].startTime)
                ),
            }, {
                name: `openingTimes.${index}.startTime`,
                message: () => 'Die Öffnungszeit darf sich nicht mit einer anderen Öffnungszeit oder der On-Demand-Zeit überschneiden',
                isValid: (value, { openingTimes, onDemandStartTime, onDemandEndTime }) => (
                    !openingTimes.some(({ startTime, endTime }, timeIndex) => (
                        index !== timeIndex
                        && value.isBetween(startTime, endTime)
                    ))
                    && (
                        !onDemandStartTime
                        || !value.isBetween(onDemandStartTime, onDemandEndTime)
                    )
                ),
            }, {
                name: `openingTimes.${index}.endTime`,
                message: () => 'Die Öffnungszeit darf sich nicht mit einer anderen Öffnungszeit oder der On-Demand-Zeit überschneiden',
                isValid: (value, { openingTimes, onDemandStartTime, onDemandEndTime }) => (
                    !openingTimes.some(({ startTime, endTime }, timeIndex) => (
                        index !== timeIndex
                        && value.isBetween(startTime, endTime)
                    ))
                    && (
                        !onDemandStartTime
                        || !value.isBetween(onDemandStartTime, onDemandEndTime)
                    )
                ),
            },
        ], []),
    ];

    const addOpeningTime = () => {
        setState({
            ...state,
            openingTimes: [
                {
                    startTime: null,
                    endTime: null,
                },
                ...state.openingTimes,
            ],
        });
    };

    const removeOpeningTime = (index) => {
        setState({
            ...state,
            openingTimes: [
                ...state.openingTimes.slice(0, index),
                ...state.openingTimes.slice(index + 1),
            ],
        });
    };

    const save = async (values) => {
        await updateProject({
            id: props.project.id,
            openingType: values.openingType,
            openingTimes: values.openingTimes.map((openingTime) => ({
                id: openingTime.id,
                startTime: openingTime.startTime.toISOString(),
                endTime: openingTime.endTime.toISOString(),
            })),
        });

        props.onClose();
    };

    return (
        <Dialog
            title="Öffnungszeiten bearbeiten"
            isOpen={props.isOpen}
            onClose={props.onClose}
            onConfirm={save}
            onChange={setState}
            values={state}
            validators={validators}
        >
            {({ errors, onChangeByEvent, onChangeByValue }) => (
                <>
                    <EditingAdmin name={`project-opening-times-${props.project.id}`} />

                    <KeyValueTable
                        items={[
                            {
                                key: 'Öffnungsart',
                                value: (
                                    <Select
                                        name="openingType"
                                        value={state.openingType}
                                        onChange={onChangeByEvent}
                                        items={openingTypes}
                                    />
                                ),
                            }, {
                                key: 'Öffnungszeiten',
                                value: (
                                    <EditorList
                                        addLabel="Öffnungszeit hinzufügen"
                                        onAdd={addOpeningTime}
                                        removeLabel="Öffnungszeit löschen"
                                        onRemove={removeOpeningTime}
                                        error={errors.openingTimes}
                                    >
                                        {state.openingTimes.map((openingTime, index) => (
                                            <Grid key={index}>
                                                <Grid item size={6}>
                                                    <DateTimePicker
                                                        label="von"
                                                        value={openingTime.startTime}
                                                        onChange={onChangeByValue(`openingTimes.${index}.startTime`)}
                                                        error={errors[`openingTimes.${index}.startTime`]}
                                                    />
                                                </Grid>
                                                <Grid item size={6}>
                                                    <DateTimePicker
                                                        label="bis"
                                                        value={openingTime.endTime}
                                                        onChange={onChangeByValue(`openingTimes.${index}.endTime`)}
                                                        error={errors[`openingTimes.${index}.endTime`]}
                                                    />
                                                </Grid>
                                            </Grid>
                                        ))}
                                    </EditorList>
                                ),
                                align: 'top',
                                available: state.openingType === 'TIME_CONTROL',
                            }, {
                                key: 'On-Demand-Laufzeit',
                                help: 'Erlaubt es die Messe (ohne Interaktionsmöglichkeiten) zu besuchen, nachdem die regulären Öffnungszeiten vorbei sind.',
                                value: (
                                    <Grid>
                                        <Grid item size={6}>
                                            <DateTimePicker
                                                label="von"
                                                value={state.onDemandStartTime}
                                                onChange={onChangeByValue('onDemandStartTime')}
                                                error={errors.onDemandStartTime}
                                            />
                                        </Grid>

                                        <Grid item size={6}>
                                            <DateTimePicker
                                                label="bis"
                                                value={state.onDemandEndTime}
                                                onChange={onChangeByValue('onDemandEndTime')}
                                                error={errors.onDemandEndTime}
                                            />
                                        </Grid>
                                    </Grid>
                                ),
                                available: false, // functionality is not implemented yet
                            }]}
                    />
                </>
            )}
        </Dialog>
    );
};

OpeningTimes.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    project: PropTypes.object.isRequired,
};

export default OpeningTimes;
