import { useState } from 'react';
export default (function (value) {
    var _a = useState(value), state = _a[0], setState = _a[1];
    var setStateActive = function () {
        setState(true);
    };
    var setStateInactive = function () {
        setState(false);
    };
    return [
        state,
        setStateActive,
        setStateInactive,
    ];
});
