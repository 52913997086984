import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import LocaleContext from 'contexts/locale';
import { resolveLanguageName } from 'helpers/languages';
import Select from 'cms/atoms/Select';
import update from 'immutability-helper';
import classNames from 'classnames';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import VisualEditor from 'cms/atoms/VisualEditor';
import CodeEditor from 'cms/atoms/CodeEditor';
import { Tab, Tabs } from '@material-ui/core';

const TranslationCodeInput = (props) => {
    const localeContext = useContext(LocaleContext);
    const [focused, setFocused] = useState(false);
    const [tab, setTab] = useState('code');

    const entries = props.value?.entries || [];
    const activeLanguageId = localeContext.language?.id;
    const currentEntryIndex = entries
        .findIndex(({ languageId }) => languageId === activeLanguageId);
    const currentEntry = entries[currentEntryIndex];

    const handleTextChange = (text) => {
        let newEntries;
        if (currentEntry) {
            // update
            newEntries = update(entries, {
                [currentEntryIndex]: {
                    text: {
                        $set: text,
                    },
                },
            });
        } else {
            // insert
            newEntries = update(entries, {
                $push: [{
                    text,
                    languageId: activeLanguageId,
                }],
            });
        }
        props.onChange({
            id: props.value?.id,
            entries: newEntries,
        });
    };

    const handleLanguageChanged = (event) => {
        const languageId = event.target.value;
        const selectedLanguage = localeContext.languages.find(({ id }) => id === languageId);
        localeContext.setLanguage(selectedLanguage);
    };

    const renderLabel = () => (
        <InputLabel
            focused={focused}
            shrink
        >
            {props.label}
        </InputLabel>
    );

    const renderLanguageSelect = () => (
        <Select
            value={activeLanguageId || ''}
            onChange={handleLanguageChanged}
            items={localeContext.languages.map(({ id, code }) => ({
                label: resolveLanguageName(code),
                value: id,
            }))}
        />
    );

    const renderTabs = () => (
        <Tabs value={tab} onChange={(event, value) => setTab(value)} aria-label="Art des Editors">
            <Tab label="Skript" value="code" />
            <Tab label="HTML" value="visual" />
        </Tabs>
    );

    const renderVisualEditor = () => (
        <VisualEditor
            value={currentEntry?.text || ''}
            onChange={handleTextChange}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            files={[
                ...props.projectFiles || [],
                ...props.sceneFiles || [],
            ]}
        />
    );

    const renderCodeEditor = () => (
        <CodeEditor
            value={currentEntry ? currentEntry.text : ''}
            onChange={handleTextChange}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            projectFiles={props.projectFiles}
            sceneFiles={props.sceneFiles}
        />
    );

    return (
        <FormControl
            fullWidth
            error={!!props.error}
        >
            {props.label && renderLabel()}
            <div
                className={classNames(
                    'ui-cms-code-input',
                    { 'ui-cms-code-input--with-label': !!props.label }
                )}
            >
                { renderLanguageSelect() }
                { renderTabs() }
                {
                    tab === 'visual'
                        ? renderVisualEditor()
                        : renderCodeEditor()
                }
            </div>
            <FormHelperText>{props.error}</FormHelperText>
        </FormControl>
    );
};

TranslationCodeInput.defaultProps = {
    error: null,
    label: null,
    projectFiles: null,
    sceneFiles: null,
    value: {
        entries: [],
    },
};

TranslationCodeInput.propTypes = {
    value: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    error: PropTypes.string,
    label: PropTypes.string,
    projectFiles: PropTypes.array,
    sceneFiles: PropTypes.array,
};

export default TranslationCodeInput;
