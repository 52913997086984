import React, { useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { deleteAuthTokens } from 'auth';
import MeContext from 'contexts/me';
import useToggle from 'hooks/useToggle';
import UiHeader from 'cms/organisms/Header';

const Header = () => {
    const history = useHistory();
    const location = useLocation();
    const me = useContext(MeContext);
    const [isMenuOpen, openMenu, closeMenu] = useToggle(false);

    const menuHandler = (path) => () => {
        if (location.pathname === path) {
            return;
        }

        history.push(path);
        closeMenu();
    };

    const logout = () => {
        deleteAuthTokens();

        window.location.href = '/cms/login';
    };

    return (
        <UiHeader
            isMenuOpen={isMenuOpen}
            openMenu={openMenu}
            closeMenu={closeMenu}
            account={me}
            menu={[
                {
                    label: 'Dashboard',
                    icon: 'dashboard',
                    onClick: menuHandler(
                        me.admin.isCustomerAdmin
                            ? `/cms/customer/${me.admin.customer.id}`
                            : '/cms'
                    ),
                },
                {
                    label: 'Admin-Accounts',
                    icon: 'account',
                    available: me.admin.isSuperAdmin,
                    onClick: menuHandler('/cms/admins'),
                },
                {
                    label: 'Einstellungen',
                    icon: 'settings',
                    available: me.admin.isBaseAdmin,
                    onClick: menuHandler('/cms/settings'),
                },
                {
                    label: 'Ausloggen',
                    icon: 'logout',
                    onClick: logout,
                },
            ]}
        />
    );
};

export default Header;
