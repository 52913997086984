import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import useEditingAdmin from 'hooks/graphql/queries/editingAdmin';
import useUpdateAdminEditing from 'hooks/graphql/mutations/updateAdminEditing';
import Icon from 'Icon';
import Avatar from 'cms/atoms/Avatar';

const EditingAdmin = (props) => {
    const [editingAdmin, setEditingAdmin] = useState(null);
    const myAdminId = useRef(null);
    const { data, loading } = useEditingAdmin();
    const updateAdminEditing = useUpdateAdminEditing();

    useEffect(() => {
        if (!loading) {
            const currentAdmin = data.editingAdmins.find(({ lastEditedName }) => (
                lastEditedName === props.name
            ));

            setEditingAdmin(currentAdmin);
            myAdminId.current = data.myAccount.admin.id;

            updateAdminEditing({
                id: data.myAccount.admin.id,
                name: props.name,
            });
        }
    }, [loading, data]);

    useEffect(() => () => {
        updateAdminEditing({ id: myAdminId.current });
    }, []);

    return editingAdmin && editingAdmin.id !== myAdminId.current ? (
        <div className="editing-admin">
            <Icon type="warning" />

            <Avatar person={editingAdmin} />

            {editingAdmin.firstName} {editingAdmin.lastName} bearbeitet gerade diesen Eintrag.
        </div>
    ) : null;
};

EditingAdmin.propTypes = {
    name: PropTypes.string.isRequired,
};

export default EditingAdmin;
