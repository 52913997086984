import React, { useEffect, useState, useContext } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useLocalStorage, deleteFromStorage } from '@rehooks/local-storage';
import moment from 'moment';
import Image, { makeBlobUrl } from 'Image';
import InnerHTML from 'InnerHTML';
import queryString from 'query-string';
import { deleteAuthTokens, AUTH_TYPE_ADMIN, AUTH_TYPE_VISITOR, TERMS_TYPE_VISITOR, AUTH_TYPE_NONE } from 'auth';
import AuthContext from 'contexts/auth';
import FileContext from 'contexts/files';
import LocaleContext from 'contexts/locale';
import StylingContext from 'contexts/styling';
import {
    removeDuplicateItems,
    removeOutdatedItems,
    challengeProgress,
    CHALLENGE_RESET_TIME_UNIT,
} from 'helpers/challenge';
import { findSupportedLanguage } from 'helpers/languages';
import { findSceneByPath } from 'helpers/scenes';
import useForceUpdate from 'hooks/useForceUpdate';
import useToggle from 'hooks/useToggle';
import useProjectByPath from 'hooks/graphql/queries/projectByPath';
import useTranslate from 'hooks/useTranslate';
import useTracking from 'hooks/useTracking';
import useTimeout from 'hooks/useTimeout';
import useReferrerConstraint from 'hooks/useReferrerConstraint';
import { useMeLazy } from 'hooks/graphql/queries/me';
import Container from 'web/organisms/Container';
import Widget from 'Widget';
import Dialog from 'web/molecules/Dialog';
import Helmet from './Project/Helmet';
import Background from './Project/Background';
import Closed from './Project/Closed';
import Login from './Project/Login';
import PreloadImages from './Project/PreloadImages';
import Header from './Project/Header';
import TermsOfService from './Project/TermsOfService';
import Registration from './Project/Registration';
import ResetPassword from './Project/ResetPassword';
import ReferrerError from './Project/ReferrerError';
import NotificationWidget from './NotificationWidget';
import AudioPlayer from './Project/Background/Audioplayer';

const SYSTEM_NOT_RESPONDING_TIMEOUT = 20 * 1000;

const Project = () => {
    const history = useHistory();
    const params = useParams();
    const location = useLocation();
    const tracking = useTracking();
    const t = useTranslate();
    const visitorTokenKey = `${AUTH_TYPE_VISITOR}-${params.projectPath}`;
    const visitorTermsKey = `${TERMS_TYPE_VISITOR}-${params.projectPath}`;
    const [isLoginOpen, openLogin, closeLogin] = useToggle(false);
    const [isRegistrationOpen, openRegistration, closeRegistration] = useToggle(false);
    const [isResetPasswordOpen, openResetPassword, closeResetPassword] = useToggle(false);
    const [isTermsOfServiceOpen, openTermsOfService, closeTermsOfService] = useToggle(false);
    const [locationStack, setLocationStack] = useState([]);
    const [cameraOrientation, setCameraOrientation] = useState(null);
    const [systemNotResponding, setSystemNotResponding] = useState(false);
    const [languageCode, setLanguageCode] = useLocalStorage('language');
    const authType = useContext(AuthContext);
    const [, setAdminToken] = useLocalStorage(AUTH_TYPE_ADMIN);
    const [visitorToken, setVisitorToken] = useLocalStorage(visitorTokenKey);
    const [visitorTerms, setVisitorTerms] = useLocalStorage(visitorTermsKey);
    const [, setReturnAction] = useLocalStorage('returnAction');
    const [challengeItems, setChallengeItems] = useLocalStorage('challengeItems', []);
    const [submittedChallenges, setSubmittedChallenges] = useLocalStorage('challengeSubmissions', []);
    const forceUpdate = useForceUpdate();

    const projectByPathQuery = useProjectByPath(params.projectPath);
    const { projectByPath } = !projectByPathQuery.loading && projectByPathQuery.data;

    const [executeMeQuery, meQuery] = useMeLazy();
    // const data = meQuery.called && !meQuery.loading && meQuery.data;
    const [data, setData] = useState(meQuery.called && !meQuery.loading && meQuery.data);
    useEffect(() => {
        setData(meQuery.data);
    }, [meQuery.data]);

    const { myAccount, me } = data || {};

    const loading = meQuery.loading || projectByPathQuery.loading;

    const fetchMe = () => {
        executeMeQuery({ context: { authType } });
    };

    // This should only be set to override the version that is rendered by default.
    const [selectedSceneVersionId, setSelectedSceneVersionId] = useState(null);
    const [navbarExpanded, setNavbarExpanded] = useState(false);
    const hasReferrerError = useReferrerConstraint(
        projectByPath,
        params.scenePath,
        document.referrer
    );

    const [playAudioBackground, setPlayAudioBackground] = useState(true);

    const language = projectByPath?.languages.find(({ code }) => code === languageCode);
    const setLanguage = (newLanguage) => setLanguageCode(newLanguage.code);

    const searchParams = queryString.parse(window.location.search);

    useEffect(() => {
        fetchMe();
    }, [authType]);

    useTimeout(() => {
        if (meQuery.called && loading) {
            setSystemNotResponding(true);
        }
    }, SYSTEM_NOT_RESPONDING_TIMEOUT);

    useEffect(() => {
        if (meQuery.called && !loading) {
            setSystemNotResponding(false);
        }
    }, [loading]);

    useEffect(() => {
        removeOutdatedChallengeData(moment());

        // remove challenge items and completed challenges at end of day
        // setTimeout drifts, so we check regularly via interval
        let startMoment = moment();
        const interval = setInterval(() => {
            const now = moment();
            if (!now.isSame(startMoment, CHALLENGE_RESET_TIME_UNIT)) {
                removeOutdatedChallengeData(now);
                startMoment = now;
            }
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    const removeOutdatedChallengeData = (now) => {
        const newItems = removeOutdatedItems(challengeItems, now);
        const newSubmissions = removeOutdatedItems(submittedChallenges, now);
        setChallengeItems(newItems);
        setSubmittedChallenges(newSubmissions);
    };

    useEffect(() => (
        history.listen(({ pathname }, action) => {
            if (action === 'POP') {
                setLocationStack((previous) => [...previous].slice(0, -1));
            } else if (action === 'PUSH') {
                setLocationStack((previous) => [...previous, pathname]);
            }
        })
    ), []);

    useEffect(() => {
        if (projectByPath) {
            const { scenes } = projectByPath;

            const { projectPath, scenePath } = params;
            const scene = findSceneByPath(scenePath, scenes);

            if (!scene && scenePath) {
                history.replace({
                    pathname: `/event/${projectPath}`,
                    search: location.search,
                });

                if (!isLoggedIn()) {
                    setReturnAction({
                        type: 'scene',
                        value: scenePath,
                    });
                    openLogin();
                }
            }
        }
    }, [projectByPath, params.scenePath, isTermsOfServiceOpen]);

    useEffect(() => {
        // load project font
        if (projectByPath) {
            const { font } = projectByPath.styling;
            if (font) {
                loadFont(font);
            }
        }
    }, [projectByPath]);

    /**
    * In order to use scripts to interact with the environment, we expose the
    * `window.__cms` object. It contains information about opening times and the
    * current visitor. Furthermore, it exposes a function to trigger certain
    * events in the backend.
    *
    * The exposed information is described in the helpers/exposedVariables.js
    * file. The `window.__cms` object itself is populated in this useEffect
    * hook.
    */
    useEffect(() => {
        if (projectByPath) {
            const { scenes, startScene, openingTimes } = projectByPath;
            const { scenePath } = params;
            const scene = findSceneByPath(scenePath, scenes, startScene);

            // expose tracking
            const sceneName = scene ? scene.name : 'No Scene';
            window.__cms = window.__cms || {};
            window.__cms.trackEvent = (action, item) => (
                tracking.trackEvent(sceneName, action, item)
            );

            // expose starting time
            const nextOpeningTime = openingTimes
                .map(({ startTime }) => startTime)
                .find((startTime) => moment().isBefore(startTime));
            window.__cms.nextStartTime = nextOpeningTime ? new Date(nextOpeningTime) : null;

            // expose user information
            window.__cms.visitor = me;
        }
    }, [projectByPath, me]);

    useEffect(() => {
        // expose triggers
        if (projectByPath) {
            const { triggers } = projectByPath;
            window.__cms = window.__cms || {};
            window.__cms.trigger = (name) => {
                const trigger = triggers.find((value) => value.name === name);
                if (trigger) {
                    onChallengeItemCompleted(trigger.id);
                } else {
                    console.error('Trigger not found', name);
                }
            };
        }
    }, [projectByPath, challengeItems]);

    useEffect(() => {
        if (projectByPath) {
            const {
                tosEnabled, scenes, startScene, loginType,
            } = projectByPath;

            if (tosEnabled && !visitorTerms) {
                const { scenePath } = params;
                const scene = findSceneByPath(scenePath, scenes, startScene);

                if (scene && scene.id === startScene.id) {
                    openTermsOfService();
                }
            }

            const queryParamMap = {
                EXTERNAL_MANAGE: 'code',
                EXTERNAL_REGASUS: 'jwt',
                EXTERNAL_EVENTSAIR: 'id',
                EXTERNAL_HAPAG_LLOYD: 'h',
                EXTERNAL_PLAZZ: 'session',
            };

            if (searchParams[queryParamMap[loginType]]) {
                // The visitor was redirected back to the lobby from an external login provider.
                // Open the login popup to handle the rest of the login process.
                openLogin();
            }
        }
    }, [projectByPath]);

    useEffect(() => {
        // set initial language
        if (projectByPath && !language) {
            const { languages, defaultLanguage } = projectByPath;
            const initialLanguage = findSupportedLanguage(window, languages, defaultLanguage);
            setLanguage(initialLanguage);
        }
    }, [projectByPath]);

    useEffect(() => {
        // update moment locale, after language was changed
        if (language) {
            moment.locale(language.code);
            forceUpdate();
        }
    }, [languageCode]);

    const loadFont = async (font) => {
        const url = await makeBlobUrl(font.url, authType);
        const fontFace = new FontFace('Project_Font', `url(${url})`);
        const loadedFont = await fontFace.load();
        document.fonts.add(loadedFont);
        document.body.style.fontFamily = 'Project_Font';
    };

    const getCurrentSceneVersion = (scene) => scene.versions.find(({ startTime, endTime }) => (
        moment().isBetween(startTime, endTime)
    )) || scene;

    const confirmTerms = () => {
        setVisitorTerms('confirmed', { expires: Infinity });
        closeTermsOfService();
    };

    const onChallengeItemCompleted = (id, type = null) => {
        const { challenge } = projectByPath;
        if (challenge && challenge.active) {
            const now = moment();
            let newItems = [...challengeItems, { id, type, timestamp: now.toISOString() }];
            newItems = removeOutdatedItems(newItems, now);
            newItems = removeDuplicateItems(newItems);
            setChallengeItems(newItems);
        } else {
            setChallengeItems([]);
        }
    };

    const onChallengeSubmitted = (id) => {
        const now = moment();
        let newSubmissions = [...submittedChallenges, { id, timestamp: now.toISOString() }];
        newSubmissions = removeOutdatedItems(newSubmissions, now);
        setSubmittedChallenges(newSubmissions);
    };

    const deleteUserData = () => {
        deleteAuthTokens(visitorTokenKey);

        deleteFromStorage('eventsairContactId');
        deleteFromStorage('trackingUserId');
        deleteFromStorage('trackingUserValue');
    };

    const isAdmin = () => (
        myAccount && (
            !myAccount.admin.customer
            || myAccount.admin.customer.id === projectByPath.customer.id
        )
    );

    const isLoggedIn = () => (
        projectByPath.demoMode
        || isAdmin()
        || !!visitorToken
        || projectByPath.loginType === 'NONE'
    );

    const togglePlayAudioBackground = () => setPlayAudioBackground(!playAudioBackground);

    const toggleNavbarState = () => setNavbarExpanded(!navbarExpanded);

    /**
     * This function returns the version of the scene that will be rendered.
     *
     * There used to be a bug where selectedSceneVersionId was set *after* the
     * first render cycle, which triggered a rerender, which in turn caused the
     * scene widget to execute the main scene widget *and* and scene version
     * widget.
     */
    const getActiveSceneVersion = (scene) => {
        if (selectedSceneVersionId === scene.id) {
            return scene;
        }

        const selectedSceneVersion = scene.versions.find(({ id }) => id === selectedSceneVersionId);
        return selectedSceneVersion || getCurrentSceneVersion(scene);
    };

    const handleCloseLogin = () => {
        closeLogin();
    };

    const renderScene = (project, scene) => {
        const isStartScene = scene.id === project.startScene.id;

        const version = getActiveSceneVersion(scene);

        const progress = challengeProgress(project, challengeItems);
        const canChallengeBeSubmitted = !!project.challenge && !submittedChallenges
            .map(({ id }) => id)
            .includes(project.challenge.id);
        const exhibitors = project.scenes.filter(({ type }) => type === 'EXHIBITOR');
        const files = [
            ...project.files,
            ...scene.files,
            ...(scene.parentScene ? scene.parentScene.files : []),
        ];

        const menuItems = isLoggedIn()
            ? [
                ...[...project.menuItems]
                    .sort((a, b) => a.order - b.order)
                    .map((menuItem) => ({
                        label: menuItem.label,
                        data: menuItem,
                        available: (
                            !menuItem.sceneData
                            || project.scenes.some(({ id }) => id === menuItem.sceneData.scene.id)
                        ),
                    })),
                {
                    type: 'exhibitor-list',
                    available: project.exhibitorListEnabled && exhibitors.length > 0,
                },
                {
                    type: 'challenge',
                    available: progress !== null,
                },
                {
                    type: 'language',
                    available: project.languages.length > 1,
                },
                {
                    type: 'logout',
                    available: (
                        authType === AUTH_TYPE_ADMIN
                        || (
                            project.loginType !== 'NONE'
                            && authType !== AUTH_TYPE_NONE
                        )
                    ),
                }, {
                    type: 'audio-switch',
                    label: playAudioBackground ? project.audioOffLabel : project.audioOnLabel,
                    isAudioOn: playAudioBackground,
                    onClick: togglePlayAudioBackground,
                    available: project.audioBackgrounds.length > 0,
                },
            ].filter(({ available }) => available)
            : [];

        return (
            <FileContext.Provider value={files}>
                {!project.demoMode && (
                    <Header
                        menuItems={menuItems}
                        logo={project.logo}
                        currentScene={scene}
                        version={version}
                        exhibitors={exhibitors}
                        deleteUserData={deleteUserData}
                        isAdmin={isAdmin()}
                        exhibitorListLabel={project.exhibitorListLabel}
                        canChallengeBeSubmitted={canChallengeBeSubmitted}
                        onChallengeSubmitted={onChallengeSubmitted}
                        challengeProgress={progress}
                        me={me}
                        onSceneVersionSelected={setSelectedSceneVersionId}
                        navbarExpanded={navbarExpanded}
                        transparent={project.styling.transparentNavbar}
                        toggleNavbarState={toggleNavbarState}
                    />
                )}
                <TransitionGroup component={React.Fragment}>
                    <CSSTransition
                        key={scene.id}
                        classNames="web-project-background-"
                        mountOnEnter
                        timeout={1000}
                        unmountOnExit
                    >
                        {(transitionState) => (
                            <Background
                                scene={scene}
                                version={version}
                                project={project}
                                showBackButton={locationStack.length > 0}
                                cameraOrientation={cameraOrientation}
                                setCameraOrientation={setCameraOrientation}
                                me={me}
                                transitionState={transitionState}
                                openLogin={openLogin}
                                isLoggedIn={isLoggedIn()}
                                isStartScene={isStartScene}
                                onChallengeItemCompleted={onChallengeItemCompleted}
                                showViewModeToggle={
                                    scene.parentScene ? (
                                        scene.parentScene.showViewModeToggle
                                    ) : (
                                        !scene.panorama
                                                    && scene.showViewModeToggle
                                                    && (
                                                        !!scene.panoramaChildScenes
                                                        && scene.panoramaChildScenes.length > 0
                                                    )
                                    )
                                }
                                toggleNavbarState={toggleNavbarState}
                                showMenuButton={menuItems.length > 0}
                            />
                        )}
                    </CSSTransition>
                </TransitionGroup>
                {playAudioBackground && (
                    <AudioPlayer
                        sceneName={version.name}
                        file={version.audioBackground?.media}
                        loop={version.audioBackground?.loop}
                        onEnded={() => setPlayAudioBackground(false)}
                        fadeOut
                    />
                )}

                <Widget>{project.widget}</Widget>

                <Login
                    isOpen={isLoginOpen}
                    onClose={handleCloseLogin}
                    onShowRegistration={openRegistration}
                    onShowResetPassword={openResetPassword}
                    projectId={projectByPath.id}
                    loginType={project.loginType}
                    loginDialogType={project.loginDialogType}
                    loginInstructions={project.loginInstructions}
                    visitorTokenKey={visitorTokenKey}
                    setAdminToken={setAdminToken}
                    setVisitorToken={setVisitorToken}
                    externalLoginManageLoginUrl={project.externalLoginManageLoginUrl}
                    externalLoginRegasusLoginUrl={project.externalLoginRegasusLoginUrl}
                    externalLoginEventsairLoginUrl={project.externalLoginEventsairLoginUrl}
                    externalLoginHapagLloydLoginUrl={project.externalLoginHapagLloydLoginUrl}
                    externalLoginPlazzLoginUrl={project.externalLoginPlazzLoginUrl}
                    sceneName={scene.name}
                    loginTracking={project.loginTracking}
                    registrationEnabled={project.registrationEnabled}
                    registrationExternalUrl={project.registrationExternalUrl}
                    loginLabelUser={project.loginLabelUser}
                    loginLabelPassword={project.loginLabelPassword}
                    token={searchParams.token}
                />

                <Registration
                    isOpen={isRegistrationOpen}
                    onClose={closeRegistration}
                    projectId={projectByPath.id}
                    registrationInstructions={project.registrationInstructions}
                    loginLabelUser={project.loginLabelUser}
                    registrationFields={project.registrationFields}
                />

                <ResetPassword
                    isOpen={isResetPasswordOpen}
                    onClose={closeResetPassword}
                    projectId={projectByPath.id}
                    loginLabelUser={project.loginLabelUser}
                />

                <TermsOfService
                    isOpen={isTermsOfServiceOpen}
                    onConfirm={confirmTerms}
                    type={project.tosType}
                    customContent={project.tosCustomContent}
                    customCheckbox={project.tosCustomCheckbox}
                />

                <PreloadImages
                    actions={scene.actions}
                    scenes={project.scenes}
                />

                <NotificationWidget
                    projectId={project.id}
                />
            </FileContext.Provider>
        );
    };

    const renderContent = () => {
        if (!projectByPath) {
            // TODO: Handle 404
            return <div>404</div>;
        }

        const {
            scenes,
            startScene,
            openingType,
            openingTimes,
            closedScreen,
            websiteTitle,
            defaultLanguage,
            languages,
        } = projectByPath;

        if (!startScene) {
            return (
                <div className="web-project" />
            );
        }

        const { scenePath } = params;
        const scene = findSceneByPath(scenePath, scenes, startScene);
        const now = moment();
        const nextOpeningTime = openingType === 'TIME_CONTROL'
            ? openingTimes.find(({ startTime }) => now.isBefore(startTime))
            : undefined;

        // The backend checks whether a scene is closed. If so, it is not sent
        // to the frontend.
        const isClosed = !scene;

        const renderBody = () => {
            if (hasReferrerError) {
                return (
                    <ReferrerError
                        isOpen={hasReferrerError}
                        scene={scene}
                        project={projectByPath}
                        onClose={() => history.replace(`/event/${projectByPath.path}`)}
                    />
                );
            } if (isClosed) {
                return (
                    <Closed
                        customScreen={closedScreen}
                        startingOn={nextOpeningTime ? nextOpeningTime.startTime : null}
                    />
                );
            }
            return renderScene(projectByPath, scene);
        };

        return (
            <LocaleContext.Provider value={{ language, languages, defaultLanguage, setLanguage }}>
                <StylingContext.Provider value={projectByPath.styling}>
                    <Helmet title={websiteTitle} />
                    <div className="web-project">
                        { renderBody() }
                    </div>
                </StylingContext.Provider>
            </LocaleContext.Provider>
        );
    };

    return (
        <>
            <Dialog
                isOpen={meQuery.called && loading && systemNotResponding}
                title={t('web.alert.systemOverloaded.title')}
                compact
            >
                <div className="d-flex flex-column text-center align-items-center">
                    <h1><InnerHTML>{t('web.alert.systemOverloaded.header')}</InnerHTML></h1>
                    <Image className="w-75 p-4" url="/images/engineering_team.svg" />
                    <p><InnerHTML>{t('web.alert.systemOverloaded.description')}</InnerHTML></p>
                </div>
            </Dialog>
            <Container>
                {meQuery.called && !loading && renderContent()}
            </Container>
        </>
    );
};

/**
 * This wrapper ensures that the project component has access to the auth context.
 */
const ProjectWithAuth = (props) => {
    const params = useParams();
    const visitorTokenKey = `${AUTH_TYPE_VISITOR}-${params.projectPath}`;

    const [adminToken] = useLocalStorage(AUTH_TYPE_ADMIN);
    const [visitorToken] = useLocalStorage(visitorTokenKey);

    let authType = AUTH_TYPE_NONE;
    if (adminToken) {
        authType = AUTH_TYPE_ADMIN;
    } else if (visitorToken) {
        authType = visitorTokenKey;
    }

    return (
        <AuthContext.Provider value={authType}>
            <Project {...props} />
        </AuthContext.Provider>
    );
};

export default ProjectWithAuth;
