import React, { useContext } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import StylingContext from 'contexts/styling';
import CloseButton from 'web/atoms/CloseButton';

const Dialog = (props) => {
    const styling = useContext(StylingContext);

    return (
        <Modal
            className={classNames(
                'ui-web-dialog',
                { 'ui-web-dialog--compact': props.compact }
            )}
            show={props.isOpen}
            onHide={props.onClose}
            backdrop={props.onClose ? true : 'static'}
            scrollable
            centered
            container={document.getElementById('app')}
        >
            <Modal.Header
                className="ui-web-dialog__header"
                style={{ backgroundColor: styling?.backgroundColor }}
            >
                <Modal.Title style={{ color: styling?.textColor }}>
                    {props.title}
                </Modal.Title>
                {props.onClose && <CloseButton onClose={props.onClose} />}
            </Modal.Header>

            <Modal.Body>
                {props.children}
            </Modal.Body>
        </Modal>
    );
};

Dialog.defaultProps = {
    onClose: null,
    compact: false,
    title: '',
};

Dialog.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    title: PropTypes.string,
    children: PropTypes.node.isRequired,
    compact: PropTypes.bool,
};

export default Dialog;
