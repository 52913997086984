import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Modal from 'react-bootstrap/Modal';
import StylingContext from 'contexts/styling';
import useTranslate from 'hooks/useTranslate';
import DialogHelper from 'DialogHelper';
import Loader from 'web/atoms/Loader';
import Button from 'web/atoms/Button';
import CloseButton from 'web/atoms/CloseButton';

const FormDialog = (props) => {
    const styling = useContext(StylingContext);
    const t = useTranslate();

    return (
        <DialogHelper
            values={props.values}
            isOpen={props.isOpen}
            onConfirm={props.onConfirm}
            onChange={props.onChange}
            validators={props.validators}
            firstInputSelector=".ui-web-text-field input"
        >
            {({
                onConfirm,
                onChangeByEvent,
                onChangeByValue,
                loading,
                errors,
                dialogRef,
            }) => (
                <Modal
                    className={classNames(
                        'ui-web-form-dialog',
                        { 'ui-web-form-dialog--loading': loading },
                    )}
                    show={props.isOpen}
                    onHide={props.onClose}
                    backdrop={props.onClose ? true : 'static'}
                    centered
                    scrollable
                    container={document.getElementById('app')}
                >
                    <Modal.Header
                        className="ui-web-form-dialog__header"
                        style={{ backgroundColor: styling.backgroundColor }}
                    >
                        <Modal.Title style={{ color: styling.textColor }}>
                            {props.title}
                        </Modal.Title>
                        {props.onClose && <CloseButton onClose={props.onClose} />}
                    </Modal.Header>

                    <Modal.Body ref={dialogRef}>
                        {props.children({ errors, onChangeByEvent, onChangeByValue, onConfirm })}
                    </Modal.Body>

                    {props.withSubmitButton && (
                        <Modal.Footer className="ui-web-form-dialog__footer">
                            <Button onClick={onConfirm}>
                                {props.confirmLabel || t('web.dialog.submit')}
                            </Button>
                        </Modal.Footer>
                    )}

                    <div className="ui-web-form-dialog__loader">
                        <div className="ui-web-form-dialog__loader-background" />

                        <Loader />
                    </div>
                </Modal>
            )}
        </DialogHelper>
    );
};

FormDialog.defaultProps = {
    onClose: null,
    validators: [],
    withSubmitButton: true,
    confirmLabel: null,
};

FormDialog.propTypes = {
    values: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onClose: PropTypes.func,
    title: PropTypes.string.isRequired,
    children: PropTypes.func.isRequired,
    confirmLabel: PropTypes.string,
    validators: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        message: PropTypes.func.isRequired,
        isValid: PropTypes.func,
        serverError: PropTypes.string,
    })),
    withSubmitButton: PropTypes.bool,
};

export default FormDialog;
