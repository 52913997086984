import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'Icon';

const Warning = (props) => (
    <div className="ui-cms-warning">
        <Icon type="warning" /> {props.children}
    </div>
);

Warning.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Warning;
