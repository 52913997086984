import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-markup';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from 'cms/atoms/Select';

const CodeEditor = (props) => {
    const containerRef = useRef();
    const inputRef = useRef();

    useEffect(() => {
        // Hacky way to access the editor's textarea ref because it is not exposed by the library
        inputRef.current = containerRef.current.querySelector('.ui-cms-code-editor__input');
        inputRef.current.addEventListener('focus', props.onFocus);
        inputRef.current.addEventListener('blur', props.onBlur);

        return () => {
            inputRef.current.removeEventListener('focus', props.onFocus);
            inputRef.current.removeEventListener('blur', props.onBlur);
        };
    }, []);

    const handleHighlight = (code) => (
        highlight(code, languages.markup)
    );

    const fileSelectItems = () => {
        const items = [
            { label: 'Projektdateien' },
            ...[...props.projectFiles]
                .sort((a, b) => (
                    a.file.filename.localeCompare(b.file.filename)
                ))
                .map(({ file }) => ({
                    label: file.filename,
                    value: file.id,
                })),
        ];

        if (!props.sceneFiles) {
            return items;
        }

        return [
            ...items,
            { label: 'Szenendateien' },
            ...[...props.sceneFiles]
                .sort((a, b) => (
                    a.file.filename.localeCompare(b.file.filename)
                ))
                .map(({ file }) => ({
                    label: file.filename,
                    value: file.id,
                })),
        ];
    };

    return (
        <FormControl
            fullWidth
            error={!!props.error}
        >
            <div
                className="ui-cms-code-editor"
                ref={containerRef}
            >
                <Editor
                    className="ui-cms-code-editor__editor"
                    textareaClassName="ui-cms-code-editor__input"
                    preClassName="ui-cms-code-editor__pre"
                    value={props.value}
                    onValueChange={props.onChange}
                    highlight={handleHighlight}
                    padding={8}
                />
            </div>

            {props.projectFiles && (
                <Select
                    label="Datei als Bild-URL einfügen"
                    value=""
                    onChange={(event) => {
                        const startIndex = inputRef.current.selectionStart;
                        const endIndex = inputRef.current.selectionEnd;
                        const startText = inputRef.current.value.substring(0, startIndex);
                        const endText = inputRef.current.value.substring(endIndex);

                        props.onChange(`${startText}[image:${event.target.value}]${endText}`);
                    }}
                    items={fileSelectItems()}
                />
            )}

            <FormHelperText>{props.error}</FormHelperText>
        </FormControl>
    );
};

CodeEditor.defaultProps = {
    projectFiles: null,
    sceneFiles: null,
    error: null,
    onFocus: () => null,
    onBlur: () => null,
};

CodeEditor.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    projectFiles: PropTypes.array,
    sceneFiles: PropTypes.array,
    error: PropTypes.string,
};

export default CodeEditor;
