import { useMutation } from 'apollo-augmented-hooks';

const mutation = `
    mutation deleteQuota($id: ID!) {
        deleteQuota(id: $id) {
            id
        }
    }
`;

export default () => {
    const [mutate] = useMutation(mutation);

    return (input) => (
        mutate({
            input,
            optimisticResponse: {
                __typename: 'Quota',
                id: input,
            },
            modifiers: [{
                cacheObject: (item) => item,
                evict: true,
            }],
        })
    );
};
