import { createPlane } from 'helpers/three';
import {
    createImageMaterial,
    createVideoMaterial,
} from 'helpers/materials';
import { createUpdateTranslationInput } from 'helpers/languages';

export const createSceneObject = async (
    { sceneObject, translateFile, authType, showVideoPlaceholder }
) => {
    const type = getSceneObjectType(sceneObject);
    const { material, video } = type === 'VIDEO'
        ? await createVideoMaterial({
            url: translateFile(sceneObject.videoData.file, type).url,
            loop: sceneObject.videoData.loop,
            muted: sceneObject.videoData.mute,
            autoplay: sceneObject.videoData.autoplay,
            authType,
            showVideoPlaceholder,
        })
        : await createImageMaterial(
            translateFile(sceneObject.imageData.file, type),
            authType,
        );

    const plane = createPlane(material, sceneObject);
    if (!showVideoPlaceholder && sceneObject.videoData) {
        plane.video = video;
    }
    return plane;
};

export const getSceneObjectType = (object) => {
    if (object.type) {
        return object.type;
    }

    if (object.imageData?.id) return 'IMAGE';
    if (object.videoData?.id) return 'VIDEO';

    return '';
};

export const sceneObjectToSaveableJson = (sceneObject, shouldDuplicate) => ({
    id: (!shouldDuplicate && sceneObject.id) || undefined,
    name: sceneObject.name,
    position: sceneObject.position ? {
        ...sceneObject.position,
        id: (!shouldDuplicate && sceneObject.position.id) || undefined,
    } : undefined,
    rotation: sceneObject.rotation ? {
        ...sceneObject.rotation,
        id: (!shouldDuplicate && sceneObject.rotation.id) || undefined,
    } : undefined,
    scale: sceneObject.scale ? {
        ...sceneObject.scale,
        id: (!shouldDuplicate && sceneObject.scale.id) || undefined,
    } : undefined,
    imageData: sceneObject.type === 'IMAGE' ? {
        id: (!shouldDuplicate && sceneObject.imageData.id) || undefined,
        file: createUpdateTranslationInput(sceneObject.imageData.file),
    } : null,
    videoData: sceneObject.type === 'VIDEO' ? {
        id: (!shouldDuplicate && sceneObject.videoData.id) || undefined,
        file: createUpdateTranslationInput(sceneObject.videoData.file),
        autoplay: sceneObject.videoData.autoplay,
        loop: sceneObject.videoData.loop,
        mute: sceneObject.videoData.mute,
    } : null,
});

export const defaultSceneObjectFormState = () => ({
    id: undefined,
    name: '',
    mobile: true,
    type: {
        _default: '',
        _key: '_parent',
        _modify: getSceneObjectType,
    },
    position: {
        id: undefined,
        x: 0,
        y: 0,
        z: 0,
    },
    rotation: {
        id: undefined,
        x: 0,
        y: 0,
        z: 0,
    },
    scale: {
        id: undefined,
        x: 1,
        y: 1,
        z: 1,
    },
    imageData: {
        id: undefined,
        file: { _translation: 'media' },
    },
    videoData: {
        id: undefined,
        file: { _translation: 'media' },
        autoplay: true,
        mute: true,
        loop: false,
    },
});
