import BaseModel from 'react-apollo-models';

export default class HomeModel extends BaseModel {
    static query = {
        query: `
            query customers {
                customers {
                    id
                    name
                }
            }
        `,
        events: [],
    }
}
