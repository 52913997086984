import React, { useContext, useEffect, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import PropTypes from 'prop-types';
import useWindowResize from 'hooks/useWindowResize';
import useForceUpdate from 'hooks/useForceUpdate';
import * as THREE from 'three';
import { isIOS } from 'react-device-detect';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { TransformControls } from 'three/examples/jsm/controls/TransformControls';
import mediaUrl from 'helpers/mediaUrl';
import { createAction, cancelVideo } from 'helpers/actions';
import { makeBlobUrl } from 'Image';
import LocaleContext from 'contexts/locale';
import StylingContext from 'contexts/styling';
import AuthContext from 'contexts/auth';
import MeContext from 'contexts/me';
import * as dat from 'dat.gui';
import Confirm from 'components/ui/cms/molecules/Confirm';
import useTranslate from 'hooks/useTranslate';
import { getTransformInFrontOfCamera, initializeRaycaster } from 'helpers/three';
import { createSceneObject } from 'helpers/sceneObjects';
import ActionEditor from './shared/Actions/Editor';
import SceneObjectEditor from './Panorama/SceneObjects/Editor';

const Panorama = (props) => {
    const baseGuiRef = useRef();
    const panoramaContainerRef = useRef();
    const forceUpdate = useForceUpdate();
    const renderer = useRef();
    const camera = useRef();
    const baseGroup = useRef();
    const rayCaster = useRef();
    const scene = useRef();
    const orbitControls = useRef();
    const transformControls = useRef();
    const animationFrameId = useRef();
    const panoramaRef = useRef();
    const [actionToReload, setActionToReload] = useState();
    const [sceneObjectToReload, setSceneObjectToReload] = useState();
    const authType = useContext(AuthContext);
    const localeContext = useContext(LocaleContext);
    const styling = useContext(StylingContext);
    const me = useContext(MeContext);
    const [actionEditorState, setActionEditorState] = useState({
        isOpen: false,
        item: null,
    });
    const [mediaEditorState, setMediaEditorState] = useState({
        isOpen: false,
        item: null,
    });
    const [deleteDialogState, setDeleteDialogState] = useState({
        isOpen: false,
        item: null,
        entityType: null,
    });
    const t = useTranslate();

    dat.GUI.TEXT_OPEN = 'Menü öffnen';
    dat.GUI.TEXT_CLOSED = 'Menü schließen';

    useWindowResize('panorama', forceUpdate);

    useEffect(() => {
        // otherwise props.actions and props.objects are still unupdated
        if (transformControls.current) {
            // removeEventListener doesn't work here
            transformControls.current._listeners.mouseUp = [];
            transformControls.current.addEventListener('mouseUp', transformMouseUpListener);
        }
    });

    useEffect(() => {
        (async () => {
            const rendererInstance = setUpRenderer();
            scene.current = new THREE.Scene();
            rayCaster.current = new THREE.Raycaster();
            baseGroup.current = new THREE.Group();
            await setUpBackground(scene.current);
            const cameraInstance = setUpCamera();
            setUpOrbitControls(rendererInstance, scene.current, cameraInstance);

            if (me.hasWriteAccessToFeature('scene.actions')) {
                setUpTransformControls(rendererInstance, scene.current, cameraInstance);
            }

            scene.current.add(baseGroup.current);
            baseGuiRef.current = createBaseGui();
            panoramaRef.current.appendChild(rendererInstance.domElement);

            animate();
        })();

        return () => {
            window.cancelAnimationFrame(animationFrameId.current);
            if (renderer.current) {
                renderer.current.forceContextLoss();
                renderer.current.dispose();
                panoramaRef.current?.removeChild(renderer.current.domElement);
                renderer.current = undefined;
            }
            if (baseGroup.current) {
                cancelVideos();
            }
            window.removeEventListener('keydown', transformKeydownListener);
            window.removeEventListener('keyup', transformKeyUpListener);
        };
    }, [props.background]);

    useEffect(() => {
        if (localeContext.language && baseGroup.current) {
            // this will also be called once after creation and initialize content
            resetActionsAndObjects();
        }
    }, [localeContext.language, props.background]);

    useEffect(() => {
        if (actionToReload && baseGroup.current) {
            reloadAction(actionToReload);
        }
    }, [actionToReload]);

    useEffect(() => {
        if (sceneObjectToReload && baseGroup.current) {
            reloadSceneObject(sceneObjectToReload);
        }
    }, [sceneObjectToReload]);

    const resetActionsAndObjects = async () => {
        cancelVideos();
        await Promise.all(props.actions.map(reloadAction));
        await Promise.all(props.objects.map(reloadSceneObject));
    };

    const cancelVideos = () => {
        baseGroup.current.children
            .filter((child) => child.video)
            .map((child) => cancelVideo(child.video));
    };

    const createBaseGui = () => {
        const gui = new dat.GUI({
            autoPlace: false,
            closeOnTop: true,
        });
        if (!me.hasWriteAccessToFeature('scene.actions')) {
            return gui;
        }
        const guiConfig = {
            createAction: () => {
                openActionEditor();
            },
            createMedia: () => {
                openMediaEditor();
            },
        };
        const createFolder = gui.addFolder('Erstellen');
        createFolder.add(guiConfig, 'createAction').name('Aktion Erstellen');
        createFolder.add(guiConfig, 'createMedia').name('Bild-/Video-Overlay Erstellen');
        panoramaContainerRef.current.appendChild(gui.domElement);
        if (baseGuiRef.current) {
            baseGuiRef.current.destroy();
            baseGuiRef.current.domElement.remove();
        }
        return gui;
    };

    const configureGui = (object, entityType) => {
        const gui = new dat.GUI({
            autoPlace: false,
            closeOnTop: true,
        });
        const guiConfig = {
            openEditor: () => {
                if (entityType === 'actions') {
                    const actionIndex = props
                        .actions
                        .findIndex((action) => action.id === object.id);
                    if (actionIndex !== -1) {
                        openActionEditor(actionIndex);
                    }
                } else if (entityType === 'objects') {
                    const sceneObjectIndex = props
                        .objects
                        .findIndex((sceneObject) => sceneObject.id === object.id);
                    if (sceneObjectIndex !== -1) {
                        openMediaEditor(sceneObjectIndex);
                    }
                }
            },
            deselect: () => {
                transformControls.current.detach();
                gui.destroy();
                gui.domElement.remove();
                baseGuiRef.current = createBaseGui();
            },
            deleteEntity: () => {
                let entityIndex;
                if (entityType === 'actions') {
                    entityIndex = props
                        .actions
                        .findIndex((action) => action.id === object.id);
                } else if (entityType === 'objects') {
                    entityIndex = props
                        .objects
                        .findIndex((sceneObject) => sceneObject.id === object.id);
                }

                if (entityIndex !== -1) {
                    setDeleteDialogState({
                        ...deleteDialogState,
                        isOpen: true,
                        item: entityIndex,
                        entityType,
                    });
                }
            },
        };

        const action = gui.addFolder('Ausgewählte Aktion');
        action.open();
        action.add(guiConfig, 'openEditor').name('Bearbeiten');
        action.add(guiConfig, 'deselect').name('Abwählen');
        action.add(guiConfig, 'deleteEntity').name('Löschen');
        panoramaContainerRef.current.appendChild(gui.domElement);
        if (baseGuiRef.current) {
            baseGuiRef.current.destroy();
            baseGuiRef.current.domElement.remove();
        }
        return gui;
    };

    const setUpRenderer = () => {
        const instance = new THREE.WebGLRenderer({ alpha: true, antialias: true });

        instance.setSize(panoramaRef.current.clientWidth, panoramaRef.current.clientHeight);
        instance.setClearColor(0x000000, 0);

        // Setting pixel ratio on iOS devices crashes the browser tab after ~8 scene changes.
        // Setting antialias to false still crashes after ~20 scene changes.
        // See https://stackoverflow.com/questions/41084172/three-js-setpixelratio-crash-on-ios-browser
        if (!isIOS) {
            instance.setPixelRatio(window.devicePixelRatio);
        }

        renderer.current = instance;

        return instance;
    };

    const setUpCamera = () => {
        const aspect = panoramaRef.current.clientWidth / panoramaRef.current.clientHeight;
        const instance = new THREE.PerspectiveCamera(75, aspect, 0.1, 1000);
        instance.position.set(1, 0, 0);
        camera.current = instance;
        return instance;
    };

    const setUpBackground = async (sceneInstance) => {
        const worldSphere = sceneInstance.getObjectByName('world-sphere');
        if (worldSphere) {
            sceneInstance.remove(worldSphere);
        }
        const geometry = new THREE.SphereBufferGeometry(500, 60, 40);
        geometry.scale(-1, 1, 1); // Invert the geometry, so the faces point inward.

        const texture = new THREE
            .TextureLoader()
            .load(await makeBlobUrl(mediaUrl(props.background, 'standard'), authType));
        texture.minFilter = THREE.LinearFilter;
        const material = new THREE.MeshBasicMaterial({ map: texture });
        material.needsUpdate = true;
        const sphere = new THREE.Mesh(geometry, material);
        sphere.name = 'world-sphere';
        sceneInstance.add(sphere);
        return sphere;
    };

    const setUpOrbitControls = (rendererInstance, sceneInstance, cameraInstance) => {
        const control = new OrbitControls(cameraInstance, rendererInstance.domElement);
        control.rotateSpeed = -0.2;
        control.enableZoom = false;
        control.autoRotate = false;
        orbitControls.current = control;
    };

    const transformKeydownListener = (event) => {
        const control = transformControls.current;

        switch (event.key) {
            case 'q':
                control.setSpace(control.space === 'local' ? 'world' : 'local');
                break;
            case 'Shift':
                control.setTranslationSnap(100);
                control.setRotationSnap(THREE.MathUtils.degToRad(15));
                control.setScaleSnap(0.25);
                break;
            case 'w':
                control.setMode('translate');
                break;
            case 'e':
                control.setMode('rotate');
                break;
            case 'r':
                control.setMode('scale');
                break;
            default:
                break;
        }
    };

    const transformKeyUpListener = (event) => {
        const control = transformControls.current;
        if (event.key === 'Shift') {
            control.setTranslationSnap(null);
            control.setRotationSnap(null);
            control.setScaleSnap(null);
        }
    };

    const transformMouseUpListener = (event) => {
        const object = event.target.object;

        let entities;
        if (object.entityType === 'actions') {
            entities = props.actions;
        } else if (object.entityType === 'objects') {
            entities = props.objects;
        }

        if (entities) {
            const entityIndex = entities
                .findIndex((entity) => object.uuid === entity.id);
            const entity = entities[entityIndex];

            if (entity) {
                entity.position = {
                    id: entity.position?.id,
                    x: object.position.x,
                    y: object.position.y,
                    z: object.position.z,
                };
                entity.rotation = {
                    id: entity.rotation?.id,
                    x: object.rotation.x,
                    y: object.rotation.y,
                    z: object.rotation.z,
                };
                entity.scale = {
                    id: entity.scale?.id,
                    x: object.scale.x,
                    y: object.scale.y,
                    z: object.scale.z,
                };
                props.onSave(object.entityType, entityIndex, entity);
            }
        }
    };

    const setUpTransformControls = (rendererInstance, sceneInstance, cameraInstance) => {
        const control = new TransformControls(cameraInstance, rendererInstance.domElement);

        control.addEventListener('dragging-changed', (event) => {
            orbitControls.current.enabled = !event.value;
        });

        control.addEventListener('change', () => {
            const object = control.object; // control is an instance of TransformControls
            if (object) {
                object.position.clampLength(0, 400);
            }
        });

        control.addEventListener('mouseUp', transformMouseUpListener);

        window.addEventListener('keydown', transformKeydownListener);
        window.addEventListener('keyup', transformKeyUpListener);

        transformControls.current = control;
        scene.current.add(control);
    };

    const animate = () => {
        renderer.current.render(scene.current, camera.current);
        animationFrameId.current = window.requestAnimationFrame(animate);
    };

    const setUpAction = async (action) => baseGroup.current.add(
        await createAction({
            action,
            buttons: [...props.buttons, ...props.projectButtons],
            authType,
            t,
            styling,
        })
    );

    const setUpSceneObject = async (sceneObject) => baseGroup.current.add(
        await createSceneObject({
            sceneObject,
            translateFile,
            authType,
            showVideoPlayer: true,
        })
    );

    const translateFile = (file) => {
        const files = [...props.projectFiles, ...props.sceneFiles]
            .map((projectFile) => projectFile.file);
        // add media object, so cms structure can be translated like web structure
        const fileWithMedia = {
            ...file,
            mediaEntries: file.mediaEntries.map((entry) => ({
                ...entry,
                media: { id: entry.mediaId },
            })),
        };
        const translatedFile = t(fileWithMedia);
        return files.find((projectFile) => projectFile.id === translatedFile.id);
    };

    const deselect = () => {
        transformControls.current.detach();
        baseGuiRef.current = createBaseGui();
    };

    const onDoubleClick = deselect;

    const onClick = (event) => {
        initializeRaycaster(event, panoramaRef.current, rayCaster.current, camera.current);
        const intersectedObjects = rayCaster
            .current
            .intersectObjects(baseGroup.current.children, true)
            .filter((intersect) => intersect.object.name
                && intersect.object.type !== 'Line'
                && intersect.object.name !== 'world-sphere');

        intersectedObjects
            .forEach((intersect) => {
                deselect();
                const group = intersect.object.parent;
                const entity = [
                    ...props.actions,
                    ...props.objects,
                ].find(({ id }) => id === group.uuid);
                baseGuiRef.current = configureGui(entity, group.entityType);
                transformControls.current.attach(group);
            });
    };

    const closeDeleteDialog = async () => {
        setDeleteDialogState({
            isOpen: false,
            item: null,
            entityType: null,
        });
    };

    const deleteEntity = async () => {
        const { entityType, item } = deleteDialogState;
        const entity = props[entityType][item];
        const objectNamePrefix = entityType === 'actions' ? 'action-group' : 'scene-object-group';
        const object = baseGroup.current.getObjectByName(`${objectNamePrefix}-${entity.id}`);
        deselect();
        if (object.video) {
            cancelVideo(object.video);
        }
        baseGroup.current.remove(object);
        await props.onDelete(entityType, item);
        setDeleteDialogState({
            isOpen: false,
            item: null,
            entityType: null,
        });
    };

    const openMediaEditor = (index) => {
        setMediaEditorState({
            isOpen: true,
            item: index,
        });
    };

    const closeMediaEditor = () => {
        transformControls.current.detach();
        setMediaEditorState({
            ...mediaEditorState,
            isOpen: false,
        });
    };

    const openActionEditor = (index) => {
        setActionEditorState({
            isOpen: true,
            item: index,
        });
    };

    const closeActionEditor = () => {
        transformControls.current.detach();
        setActionEditorState({
            ...actionEditorState,
            isOpen: false,
        });
    };

    const reloadAction = async (action) => {
        const oldObject = baseGroup.current.getObjectByName(`action-group-${action.id}`);
        transformControls.current?.detach();
        baseGroup.current.remove(oldObject);
        await setUpAction(action);
    };

    const reloadSceneObject = async (object) => {
        transformControls.current?.detach();
        const oldObject = baseGroup.current.getObjectByName(`scene-object-group-${object.id}`);
        if (oldObject?.video) {
            cancelVideo(oldObject.video);
        }
        baseGroup.current.remove(oldObject);
        await setUpSceneObject(object);
    };

    const onActionSave = async (type, index, action) => {
        if (index === null) {
            action.id = uuid();
            const { position } = getTransformInFrontOfCamera(camera.current, 300);
            action.position = position;
        }
        await props.onSave(type, index, action);
        setActionToReload(action);
    };

    const onObjectSave = async (index, object) => {
        if (index === null) {
            object.id = uuid();
            const { position, rotation, scale } = getTransformInFrontOfCamera(camera.current, 300);
            object.position = position;
            object.rotation = rotation;
            object.scale = scale;
        }
        await props.onSave('objects', index, object);
        setSceneObjectToReload(object);
    };

    const renderPanInfo = () => (
        <div className="cms-background-panorama-info">
            <span className="cms-background-panorama-info__text">
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                "W" Verschieben | "E" Rotieren | "R" Skalieren<br />
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                "Q" Wechsel Welt/Lokaler Raum |  "Shift" An Raster ausrichten
            </span>
        </div>
    );

    return (
        <>
            <div
                className="cms-background-panorama"
                ref={panoramaContainerRef}
            >
                <div
                    className="cms-background-panorama__container"
                    ref={panoramaRef}
                    onClick={onClick}
                    onDoubleClick={onDoubleClick}
                    onTouchStart={onClick}
                />
                {renderPanInfo()}
            </div>
            <Confirm
                title="Soll dieses Objekt wirklich gelöscht werden?"
                onConfirm={deleteEntity}
                confirmLabel="Ja, löschen"
                onCancel={closeDeleteDialog}
                cancelLabel="Nein, abbrechen"
                isOpen={deleteDialogState.isOpen}
                destructive
            />
            <ActionEditor
                actions={props.actions}
                action={actionEditorState.item}
                isOpen={actionEditorState.isOpen}
                onClose={closeActionEditor}
                onSave={onActionSave}
                buttons={props.buttons}
                projectButtons={props.projectButtons}
                addButtonX={0}
                addButtonY={0}
                scenes={props.scenes}
                projectFiles={props.projectFiles}
                sceneFiles={props.sceneFiles}
                loginType={props.loginType}
                accessGroups={props.accessGroups}
                challenge={props.challenge}
                isPanoramaChildScene={props.isPanoramaChildScene}
            />
            <SceneObjectEditor
                objects={props.objects}
                sceneObject={mediaEditorState.item}
                isOpen={mediaEditorState.isOpen}
                onClose={closeMediaEditor}
                onSave={onObjectSave}
                buttons={props.buttons}
                projectButtons={props.projectButtons}
                scenes={props.scenes}
                projectFiles={props.projectFiles}
                sceneFiles={props.sceneFiles}
                loginType={props.loginType}
                accessGroups={props.accessGroups}
                challenge={props.challenge}
                isPanoramaChildScene={props.isPanoramaChildScene}
            />
        </>
    );
};

Panorama.defaultProps = {
    projectFiles: [],
    sceneFiles: [],
    loginType: null,
    accessGroups: [],
    withMailbox: false,
    objects: null,
    overlays: null,
    challenge: null,
    isPanoramaChildScene: false,
};

Panorama.propTypes = {
    background: PropTypes.any.isRequired,
    actions: PropTypes.array.isRequired,
    objects: PropTypes.array,
    overlays: PropTypes.array,
    buttons: PropTypes.array.isRequired,
    projectButtons: PropTypes.array.isRequired,
    scenes: PropTypes.array.isRequired,
    onSave: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    projectFiles: PropTypes.array,
    sceneFiles: PropTypes.array,
    withMailbox: PropTypes.bool,
    loginType: PropTypes.string,
    accessGroups: PropTypes.array,
    challenge: PropTypes.object,
    isPanoramaChildScene: PropTypes.bool,
};

export default Panorama;
