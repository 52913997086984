import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import useToggle from 'hooks/useToggle';
import useTracking from 'hooks/useTracking';
import useTranslate from 'hooks/useTranslate';
import { apiResponseToFormState } from 'helpers/form';
import { isValidFormValue, defaultFormFieldFormState } from 'helpers/formFields';
import { TRACKING_SHOW_MAILBOX, TRACKING_SUBMIT_MAILBOX } from 'helpers/tracking';
import ProjectModel from 'models/web/project';
import TextField from 'web/atoms/TextField';
import Checkbox from 'web/atoms/Checkbox';
import Select from 'web/atoms/Select';
import Grid from 'web/molecules/Grid';
import FormDialog from 'web/molecules/FormDialog';
import Dialog from 'web/molecules/Dialog';
import InnerHTML from 'InnerHTML';

const defaultValues = {
    formFields: {
        _key: 'fields',
        _map: defaultFormFieldFormState,
        _sort: (a, b) => a.order - b.order,
    },
};

const Mailbox = (props) => {
    const tracking = useTracking();
    const t = useTranslate();
    const [isSuccessAlertOpen, openSuccessAlert, closeSuccessAlert] = useToggle(false);

    const contactInstructions = props.action?.callingCardData?.contactInstructions || props.scene.contactInstructions;
    const contactTitle = props.action?.callingCardData?.contactTitle || props.scene.contactTitle;
    const form = props.action?.callingCardData?.form || props.scene.callingCardForm;

    const [state, setState] = useState(
        apiResponseToFormState(form, defaultValues)
    );

    const validators = [
        ...state.formFields.reduce((result, current, index) => [
            ...result, {
                name: `formFields.${index}`,
                message: () => t('web.common.error.fieldRequired'),
                isValid: isValidFormValue,
            },
        ], []),
    ];

    useEffect(() => {
        if (props.isOpen) {
            setState(apiResponseToFormState(form, defaultValues));
            // TRACKCORE:
            //  event:'showMailbox', ressource: , data: {scene: props.scene.name, customer: customerId}
            //  SCENE CONTENT: mailbox button clicked
            // console.log('%cTRACKCORE: showMailbox', 'background: #00a8ff; font-weight: bold;', props.scene.name);
            tracking.trackCoreEvent({
                action: 'showMailbox',
                resourceType: 'sceneName',
                resourceName: props.scene.name,
            });
            tracking.trackEvent(props.scene.name, TRACKING_SHOW_MAILBOX);
        }
    }, [props.isOpen]);

    const confirm = async (values) => {
        // TRACKCORE:
        //  event:'submitMailbox', ressource: , data: {scene: props.scene.name, customer: customerId}
        //  SCENE CONTENT: mailbox button clicked and submit button clicked
        // console.log('%cTRACKCORE: submitMailbox', 'background: #00a8ff; font-weight: bold;', props.scene.name);
        tracking.trackCoreEvent({
            action: 'submitMailbox',
            resourceType: '',
            resourceName: '',
        });
        tracking.trackEvent(props.scene.name, TRACKING_SUBMIT_MAILBOX);
        props.onChallengeItemCompleted(props.scene.id, 'OPEN_CALLING_CARD');

        const callingCard = values.formFields.map(({ value, id }) => ({
            value: value.toString().trim(),
            fieldId: id,
        }));

        if (props.action) {
            await props.sendCallingCardViaAction({
                actionId: props.action.id,
                callingCard,
            });
        } else {
            await props.sendCallingCardViaScene({
                sceneId: props.scene.id,
                callingCard,
            });
        }

        openSuccessAlert();
    };

    const closeAll = () => {
        closeSuccessAlert();
        props.onClose();
    };

    const renderFormField = (dataItem, index, onChangeByEvent, onChangeByValue, errors) => {
        switch (dataItem.type) {
            case 'CHECKBOX':
                return (
                    <Checkbox
                        checked={!!dataItem.value}
                        key={index}
                        name={`formFields.${index}.value`}
                        onChange={onChangeByValue(`formFields.${index}.value`)}
                        error={errors[`formFields.${index}`]}
                    >
                        <InnerHTML>{t(dataItem.description)}</InnerHTML>
                    </Checkbox>
                );
            case 'DROPDOWN':
                return (
                    <Select
                        key={index}
                        value={dataItem.value}
                        label={t(dataItem.label)}
                        name={`formFields.${index}.value`}
                        onChange={onChangeByEvent}
                        options={[...dataItem.options]
                            .map((option) => ({
                                label: t(option.label),
                                value: option.value,
                            }))}
                        emptyOption={{ label: t('web.form.chooseDropdownValue') }}
                        error={errors[`formFields.${index}`]}
                    />
                );
            case 'TEXT':
            default:
                return (
                    <TextField
                        key={index}
                        type="text"
                        label={t(dataItem.label)}
                        value={dataItem.value}
                        name={`formFields.${index}.value`}
                        onChange={onChangeByEvent}
                        error={errors[`formFields.${index}`]}
                    />
                );
        }
    };

    return props.scene && (
        <>
            <Dialog
                isOpen={isSuccessAlertOpen}
                onClose={closeAll}
                title={t('web.callingCard.success.title')}
            >
                {t('web.callingCard.success.content')}
            </Dialog>

            <FormDialog
                isOpen={props.isOpen}
                title={t(contactTitle) || t('web.callingCard.title')}
                values={state}
                onClose={props.onClose}
                onConfirm={confirm}
                onChange={setState}
                validators={validators}
            >
                {({ errors, onChangeByEvent, onChangeByValue }) => (
                    <Grid>
                        <Grid row>
                            <Grid column size={12}>
                                <InnerHTML allowedTags={['br', 'b', 'strong', 'em', 'i', 'a']}>
                                    {t(contactInstructions) || t('web.callingCard.instructions')}
                                </InnerHTML>
                                <br />
                            </Grid>
                        </Grid>
                        <Grid row>
                            <Grid column size={12}>
                                {[...state.formFields]
                                    .map((dataItem, index) => renderFormField(
                                        dataItem,
                                        index,
                                        onChangeByEvent,
                                        onChangeByValue,
                                        errors
                                    ))}
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </FormDialog>
        </>
    );
};

Mailbox.defaultProps = {
    me: null,
    action: null,
};

Mailbox.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    scene: PropTypes.object.isRequired,
    action: PropTypes.object,
    sendCallingCardViaScene: PropTypes.func.isRequired,
    sendCallingCardViaAction: PropTypes.func.isRequired,
    me: PropTypes.object,
    onChallengeItemCompleted: PropTypes.func.isRequired,
};

export default withRouter(ProjectModel.graphql(Mailbox, { withoutQuery: true }));
