import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { AutoSizer, Column, Table } from 'react-virtualized';
import Paper from '@material-ui/core/Paper';
import TableCell from '@material-ui/core/TableCell';
import EmptyMessage from 'cms/atoms/EmptyMessage';

const ROW_HEIGHT = 36;

const DataTable = (props) => {
    const rowGetter = ({ index }) => props.rows[index];

    const headerRenderer = ({ label }) => (
        <TableCell
            className="ui-cms-data-table__cell"
            component="div"
            variant="head"
            style={{ height: ROW_HEIGHT }}
        >
            <span>{label}</span>
        </TableCell>
    );

    const cellRenderer = ({ cellData }) => (
        <TableCell
            className="ui-cms-data-table__cell"
            component="div"
            variant="body"
            style={{ height: ROW_HEIGHT }}
        >
            {cellData}
        </TableCell>
    );

    const renderColumn = (column) => {
        const columnClassNames = classNames(
            'ui-cms-data-table__column',
            `ui-cms-data-table__column--align-${column.align}`,
            { 'ui-cms-data-table__column--full-width': !column.width }
        );

        return (
            <Column
                key={column.dataKey}
                dataKey={column.dataKey}
                headerRenderer={headerRenderer}
                cellRenderer={cellRenderer}
                width={column.width || 0}
                label={column.label}
                className={columnClassNames}
                headerClassName={columnClassNames}
            />
        );
    };

    return (
        <Paper className="ui-cms-data-table">
            {props.filters && (
                <div className="ui-cms-data-table__filters">
                    {props.filters}
                </div>
            )}

            {props.rows.length === 0 ? (
                <EmptyMessage>
                    {props.emptyMessage || 'Es wurden keine Daten gefunden.'}
                </EmptyMessage>
            ) : (
                <div style={{ maxHeight: 400, height: ((props.rows.length + 1) * ROW_HEIGHT) }}>
                    <AutoSizer>
                        {({ height, width }) => (
                            <Table
                                height={height}
                                width={width}
                                rowHeight={ROW_HEIGHT}
                                headerHeight={ROW_HEIGHT}
                                rowGetter={rowGetter}
                                rowCount={props.rows.length}
                                rowClassName="ui-cms-data-table__row"
                            >
                                {props.columns
                                    .filter((column) => column.available !== false)
                                    .map(renderColumn)}
                            </Table>
                        )}
                    </AutoSizer>
                </div>
            )}
        </Paper>
    );
};

DataTable.defaultProps = {
    filters: null,
    emptyMessage: null,
};

DataTable.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.shape({
        dataKey: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        width: PropTypes.number,
        align: PropTypes.string,
        available: PropTypes.bool,
    })).isRequired,
    rows: PropTypes.arrayOf(PropTypes.object).isRequired,
    filters: PropTypes.node,
    emptyMessage: PropTypes.string,
};

export default DataTable;
