import React, { useContext, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useLocalStorage } from '@rehooks/local-storage';
import StylingContext from 'contexts/styling';
import MeContext from 'contexts/me';
import LocaleContext from 'contexts/locale';
import useScene from 'hooks/graphql/queries/scene';
import useDeleteScene from 'hooks/graphql/mutations/deleteScene';
import useDeleteSceneVersion from 'hooks/graphql/mutations/deleteSceneVersion';
import Tabs from 'cms/molecules/Tabs';
import BreadCrumbs from 'cms/organisms/BreadCrumbs';
import Container from 'cms/organisms/Container';
import General from './Scene/General';
import SceneVersions from './Scene/SceneVersions';
import PanoramaChildScenes from './Scene/PanoramaChildScenes';
import Statistics from './Scene/Statistics';
import Changelog from './Scene/Changelog';

const Scene = () => {
    const me = useContext(MeContext);
    const [languageCode, setLanguageCode] = useLocalStorage('language');
    // FIXME error when user does not have access to active tab
    const [activeTab, setActiveTab] = useLocalStorage('sceneTab', 'general');

    const params = useParams();
    const history = useHistory();

    const { data, loading } = useScene(params.id);
    const { scene, buttons } = !loading && data;

    // Mutations
    const deleteScene = useDeleteScene();
    const deleteSceneVersion = useDeleteSceneVersion();

    const language = scene?.project.languages.find(({ code }) => code === languageCode);
    const setLanguage = (newLanguage) => setLanguageCode(newLanguage.code);

    useEffect(() => {
        // set initial language
        if (scene && !language) {
            setLanguage(scene.project.defaultLanguage);
        }
    }, [scene]);

    const renderContent = () => {
        const { languages, defaultLanguage, presenceTrackingEnabled } = scene.project;
        return (
            <LocaleContext.Provider
                value={{ language, languages, defaultLanguage, setLanguage }}
            >
                <StylingContext.Provider value={scene.project.styling}>
                    <BreadCrumbs
                        items={[
                            { label: 'Dashboard', href: '/cms', available: me.admin.isBaseAdmin },
                            { label: scene.project.customer.name, href: `/cms/customer/${scene.project.customer.id}` },
                            { label: scene.project.name, href: `/cms/project/${scene.project.id}` },
                            { label: scene.name },
                        ]}
                        navigate={history.push}
                    />

                    <Tabs
                        items={[
                            { value: 'general', label: 'Allgemeines' },
                            { value: 'versions', label: 'Zeitgesteuerte Versionen', available: me.hasReadAccessToFeature('scene.versions') },
                            { value: 'panorama', label: 'Panorama-Unterszenen', available: me.hasReadAccessToFeature('scene.panorama') && !scene.panorama },
                            { value: 'statistics', label: 'Statistik', available: me.hasReadAccessToFeature('scene.statistics') },
                            { value: 'changelog', label: 'Bearbeitungsprotokoll', available: me.hasReadAccessToFeature('scene.activitylog') },
                        ]}
                        value={activeTab}
                        onChange={setActiveTab}
                    >
                        {activeTab === 'general' && (
                            <General
                                scene={scene}
                                buttons={buttons}
                                deleteScene={deleteScene}
                                presenceTrackingEnabled={presenceTrackingEnabled}
                            />
                        )}

                        {activeTab === 'versions' && (
                            <SceneVersions
                                scene={scene}
                                buttons={buttons}
                                deleteSceneVersion={deleteSceneVersion}
                            />
                        )}

                        {activeTab === 'panorama' && (
                            <PanoramaChildScenes
                                scene={scene}
                                buttons={buttons}
                                deleteScene={deleteScene}
                            />
                        )}

                        {activeTab === 'statistics' && (
                            <Statistics />
                        )}

                        {activeTab === 'changelog' && (
                            <Changelog scene={scene} />
                        )}

                    </Tabs>
                </StylingContext.Provider>
            </LocaleContext.Provider>
        );
    };

    return (
        <Container>{!loading && renderContent()}</Container>
    );
};

export default Scene;
