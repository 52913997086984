import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import useToggle from 'hooks/useToggle';
import MeContext from 'contexts/me';
import Button from 'cms/atoms/Button';
import Headline from 'cms/atoms/Headline';
import Grid from 'cms/molecules/Grid';
import SceneEditor from '../editors/Scene';
import Scene from './Scenes/Scene';

const Scenes = (props) => {
    const [isSceneEditorOpen, openSceneEditor, closeSceneEditor] = useToggle(false);
    const me = useContext(MeContext);

    return (
        <>
            <Headline
                action={me.hasWriteAccessToFeature('scene.new') ? (
                    <Button
                        icon="add"
                        onClick={openSceneEditor}
                    >
                        Neue Szene
                    </Button>
                ) : null}
            >
                Szenen
            </Headline>

            <SceneEditor
                isOpen={isSceneEditorOpen}
                onClose={closeSceneEditor}
                projectId={props.project.id}
                buttons={props.buttons}
                projectButtons={props.project.buttons}
                scenes={props.scenes}
                challenge={props.project.challenge}
                projectFiles={props.project.files}
                project={props.project}
                accessGroups={props.project.accessGroups}
                loginType={props.project.loginType}
                tags={props.project.tags}
                projectPresenceTrackingEnabled={props.project.presenceTrackingEnabled}
            />

            <Grid>
                {props.scenes
                    .filter((scene) => me.hasAccessToScene(scene.id))
                    .map((scene) => (
                        <Grid item size={4} key={scene.id}>
                            <Scene
                                id={scene.id}
                                name={scene.name}
                                type={scene.type}
                                background={scene.background}
                                actions={scene.actions}
                                scenes={props.scenes}
                                startScene={props.project.startScene}
                            />
                        </Grid>
                    ))}
            </Grid>
        </>
    );
};

Scenes.propTypes = {
    buttons: PropTypes.array.isRequired,
    project: PropTypes.object.isRequired,
    scenes: PropTypes.array.isRequired,
};

export default Scenes;
