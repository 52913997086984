import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import MuiSelect from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

const SELECT_ALL = '__select_all__';

const groupItems = (items) => (
    items.reduce((groupsToValues, item) => {
        const existingGroup = (groupsToValues[item.group] || []);
        const newGroup = existingGroup.concat(item);
        groupsToValues[item.group] = newGroup;
        return groupsToValues;
    }, {})
);

const MultiSelect = (props) => {
    const groupedItems = useMemo(() => groupItems(props.items), [props.items]);

    const onChange = (event) => {
        const values = event.target.value;
        if (values.includes(SELECT_ALL)) {
            props.onSelectAllChange(true);
            props.onValuesChange([]);
        } else {
            props.onSelectAllChange(false);
            props.onValuesChange(values);
        }
    };

    const renderSelectedValues = (values) => {
        if (values.includes(SELECT_ALL)) {
            return 'Alle';
        }
        const selectedItems = props.items.filter((item) => values.includes(item.value));
        return selectedItems.map((item) => item.label).join(', ');
    };

    const renderSelectAll = () => (
        <MenuItem
            key="all"
            value={SELECT_ALL}
        >
            <Checkbox checked={props.selectAll} />
            <ListItemText primary="Alle" />
        </MenuItem>
    );

    const renderGroups = () => {
        const groupEntries = Object.entries(groupedItems);
        if (groupEntries.length === 1) {
            // just one group -> show no header
            const items = groupEntries[0][1];
            return items.map(renderItem);
        }
        // <Select/> does not support fragments, so use array for header and items
        return groupEntries.flatMap(([groupName, items]) => ([
            (<ListSubheader key={groupName}>{groupName}</ListSubheader>),
            items.map(renderItem),
        ]));
    };

    const renderItem = ({ label, value }) => (
        <MenuItem
            key={`${label}${value}`}
            value={value}
            disabled={props.selectAll}
        >
            <Checkbox checked={props.values.includes(value)} />
            <ListItemText primary={label} />
        </MenuItem>
    );

    return (
        <FormControl fullWidth error={!!props.error}>
            {props.label && (
                <InputLabel>{props.label}</InputLabel>
            )}

            <MuiSelect
                className="ui-cms-select"
                value={props.selectAll ? [SELECT_ALL] : props.values}
                onChange={onChange}
                name={props.name}
                disabled={props.disabled}
                renderValue={renderSelectedValues}
                multiple
            >
                {[renderSelectAll(), ...renderGroups()]}
            </MuiSelect>

            <FormHelperText>{props.error || props.helper}</FormHelperText>
        </FormControl>
    );
};

MultiSelect.defaultProps = {
    error: null,
    label: null,
    name: null,
    disabled: false,
    helper: null,
};

MultiSelect.propTypes = {
    selectAll: PropTypes.bool.isRequired,
    onSelectAllChange: PropTypes.func.isRequired,
    values: PropTypes.arrayOf(PropTypes.string).isRequired,
    onValuesChange: PropTypes.func.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.node.isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        group: PropTypes.string,
    })).isRequired,
    name: PropTypes.string,
    label: PropTypes.string,
    error: PropTypes.string,
    disabled: PropTypes.bool,
    helper: PropTypes.string,
};

export default MultiSelect;
