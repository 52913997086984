import React from 'react';
import PropTypes from 'prop-types';
import useTranslate from 'hooks/useTranslate';
import Header from './MobileSceneAccordion/Header';
import Element from './MobileSceneAccordion/Element';

const MobileSceneAccordion = (props) => {
    const {
        actionClickHandler,
        openAccordionItems,
        project,
        scene,
        setAccordionRefHandler,
        showBackButton,
        version,
    } = props;
    const t = useTranslate();

    return (
        <div
            className="mobile-scene-accordion"
        >
            <Header
                showBackButton={showBackButton}
                toggleNavbarState={props.toggleNavbarState}
                showMenuButton={props.showMenuButton}
            />
            {version.overlays.filter((overlay) => overlay.mobile)
                .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
                .map(
                    (overlay) => (
                        <Element
                            element={overlay}
                            project={project}
                            open={openAccordionItems.includes(overlay.id)}
                            accordionRef={setAccordionRefHandler(overlay.id)}
                            sceneName={scene.name}
                            key={overlay.id}
                            actionClickHandler={actionClickHandler}
                        />
                    )
                )}
            {version.actions.filter((action) => action.mobile)
                .sort((a, b) => t(a.name).toLowerCase().localeCompare(t(b.name).toLowerCase()))
                .map((action) => (
                    <Element
                        element={action}
                        project={project}
                        open={openAccordionItems.includes(action.id)}
                        sceneName={scene.name}
                        accordionRef={setAccordionRefHandler(action.id)}
                        key={action.id}
                        actionClickHandler={actionClickHandler}
                    />
                ))}
        </div>
    );
};

MobileSceneAccordion.defaultProps = {
    top: 0,
    openAccordionItems: [],
    project: {},
    scene: {},
    showBackButton: false,
    version: {},
};

MobileSceneAccordion.propTypes = {
    top: PropTypes.number,
    openAccordionItems: PropTypes.array,
    project: PropTypes.object,
    scene: PropTypes.object,
    setAccordionRefHandler: PropTypes.func.isRequired,
    showBackButton: PropTypes.bool,
    version: PropTypes.object,
    actionClickHandler: PropTypes.func.isRequired,
    toggleNavbarState: PropTypes.func.isRequired,
    showMenuButton: PropTypes.bool.isRequired,
};

export default MobileSceneAccordion;
