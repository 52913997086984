import * as THREE from 'three';
import mediaUrl from 'helpers/mediaUrl';
import { makeBlobUrl } from 'Image';

export const createImageMaterial = async (image, authType) => {
    const imageUrl = mediaUrl(image, 'thumbnail');
    const url = await makeBlobUrl(imageUrl, authType);
    return createImageMaterialByUrl(url);
};

export const createImageMaterialByUrl = (url) => {
    const texture = new THREE.TextureLoader().load(url);
    const material = new THREE.MeshBasicMaterial({
        map: texture,
        transparent: true,
        alphaTest: 0.5,
    });
    return {
        material,
        video: null,
    };
};

export const createVideoMaterial = async ({
    url,
    loop,
    muted,
    autoplay,
    authType,
    showPlaceholder,
}) => {
    let material;
    let video = null;
    if (showPlaceholder) {
        material = await createImageMaterialByUrl('/images/video-icon.png').material;
    } else {
        video = document.createElement('video');
        if (loop) {
            video.setAttribute('loop', 'loop');
        }
        if (muted) {
            // setAttribute doesn't work
            video.muted = 'muted';
        }
        video.setAttribute('playsinline', 'playsinline');

        const source = document.createElement('source');
        source.type = 'video/mp4';
        source.src = await makeBlobUrl(url, authType);
        video.appendChild(source);
        if (autoplay) {
            video.play();
        }
        const texture = new THREE.VideoTexture(video);
        material = new THREE.MeshBasicMaterial({
            map: texture,
            transparent: true,
            alphaTest: 0.5,
        });
    }
    return {
        material,
        video,
    };
};
