import { useMutation } from 'apollo-augmented-hooks';
import { SCENE } from '../queries/scene';

const mutation = `
    mutation updateScene($input: UpdateSceneInput!) {
        updateScene(input: $input) {
            ${SCENE}
            project {
                id
                tags {
                    id
                    name
                }
            }
        }
    }
`;

export default () => {
    const [mutate] = useMutation(mutation);

    return (input) => (
        mutate({ input })
    );
};
