import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import axios from 'axios';
import appConfig from 'appConfig';
import AuthContext from 'contexts/auth';
import Loader from 'cms/atoms/Loader';
import Icon from 'Icon';

const imageCache = {};

export const makeBlobUrl = async (url, authType) => {
    if (!url) {
        return 'default';
    }

    if (!url.startsWith(`${appConfig.apiUrl}media`) || url === 'placeholder') {
        return url;
    }

    const cacheUrl = url.replace(/\?key=.*/, '');

    if (imageCache[`${cacheUrl}${authType}`]) {
        if (imageCache[`${cacheUrl}${authType}`] === 'fetching') {
            return new Promise((resolve) => {
                const interval = window.setInterval(() => {
                    if (imageCache[`${cacheUrl}${authType}`] !== 'fetching') {
                        window.clearInterval(interval);
                        resolve(imageCache[`${cacheUrl}${authType}`]);
                    }
                }, 500);
            });
        }

        return imageCache[`${cacheUrl}${authType}`];
    }

    imageCache[`${cacheUrl}${authType}`] = 'fetching';

    const response = await axios.get(url, {
        headers: {
            Authorization: `Bearer ${window.localStorage.getItem(authType)}`,
        },
        responseType: 'blob',
    });

    if (response.status === 200) {
        imageCache[`${cacheUrl}${authType}`] = URL.createObjectURL(response.data);

        return imageCache[`${cacheUrl}${authType}`];
    }

    imageCache[`${cacheUrl}${authType}`] = 'default';

    return imageCache[`${cacheUrl}${authType}`];
};

const Image = (props) => {
    const [url, setUrl] = useState(null);
    const authType = useContext(AuthContext);

    useEffect(() => {
        (async () => {
            setUrl(await makeBlobUrl(props.url, authType));
        })();
    }, []);

    useEffect(() => {
        (async () => {
            setUrl(await makeBlobUrl(props.url, authType));
        })();
    }, [props.url, authType, url]);

    if (!url) {
        return null;
    }

    if (url === 'default') {
        return (
            <div className="image image--default">
                <Icon type="image" />
            </div>
        );
    }

    if (url === 'placeholder') {
        return (
            <div className="image image--placeholder">
                <Loader />
            </div>
        );
    }

    return (
        <img
            alt=""
            className={classNames(
                'image',
                props.className
            )}
            src={url}
            style={{
                maxHeight: props.maxWidth,
                maxWidth: props.maxWidth,
                ...props.style,
            }}
            onLoad={props.onLoad}
            ref={props.imageRef}
        />
    );
};

Image.defaultProps = {
    url: null,
    maxWidth: null,
    style: {},
    onLoad: null,
    imageRef: null,
    className: null,
};

Image.propTypes = {
    url: PropTypes.string,
    maxWidth: PropTypes.number,
    style: PropTypes.object,
    onLoad: PropTypes.func,
    imageRef: PropTypes.object,
    className: PropTypes.string,
};

export default Image;
