import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Divider from '@material-ui/core/Divider';
import Help from 'cms/atoms/Help';

const KeyValueTable = (props) => (
    <div className="ui-cms-key-value-table">
        {props.wrappingDividers && (
            <Divider />
        )}

        {props.items.map((item, index) => item.available !== false && (
            <React.Fragment key={item.key}>
                {index > 0 && (
                    <Divider />
                )}

                <div
                    className={classNames(
                        'ui-cms-key-value-table__row',
                        { 'ui-cms-key-value-table__row--disabled': item.disabled }
                    )}
                    data-disabled={item.disabled}
                >
                    <div
                        className="ui-cms-key-value-table__key"
                        style={{
                            alignItems: item.align === 'top' ? 'flex-start' : 'center',
                        }}
                    >
                        {item.key}

                        {item.help && (
                            <Help>{item.help}</Help>
                        )}
                    </div>

                    <div className="ui-cms-key-value-table__value">
                        {item.value}
                    </div>
                </div>
            </React.Fragment>
        ))}

        {props.wrappingDividers && (
            <Divider />
        )}
    </div>
);

KeyValueTable.defaultProps = {
    wrappingDividers: false,
};

KeyValueTable.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string.isRequired,
        value: PropTypes.node,
        help: PropTypes.node,
        align: PropTypes.oneOf(['top', 'center']),
        available: PropTypes.bool,
        disabled: PropTypes.bool,
    })).isRequired,
    wrappingDividers: PropTypes.bool,
};

export default KeyValueTable;
