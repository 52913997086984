import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import StylingContext from 'contexts/styling';
import useTranslate from 'hooks/useTranslate';
import Image from 'Image';
import Draggable from 'Draggable';
import FontRatio from 'FontRatio';

const Action = (props) => {
    const styling = useContext(StylingContext);
    const t = useTranslate();

    const calculateStyle = () => {
        if (!props.position) {
            return null;
        }

        const { type, left, top } = props.position;

        // Position is in relation to the scene's width and height
        if (type === 'within-scene') {
            return {
                left: `${left}%`,
                top: `${top}%`,
            };
        }

        // Position is in relation to the scene's width only
        if (type === 'over-scene') {
            return {
                left: `${left}%`,
                marginTop: `${top}%`,
            };
        }

        return null;
    };

    const renderImage = () => {
        const image = (
            <Image url={props.image} />
        );

        if (props.onDrag) {
            return (
                <Draggable
                    onDragStart={props.onDragStart}
                    onDrag={props.onDrag}
                    onDragEnd={props.onDragEnd}
                >
                    {image}
                </Draggable>
            );
        }

        return image;
    };

    return (
        <div
            className={classNames(
                'ui-shared-action',
                {
                    'ui-shared-action--with-click': !!props.onClick,
                    'ui-shared-action--with-animation': props.withAnimation,
                    'ui-shared-action--with-position': !!props.position,
                },
            )}
            style={calculateStyle()}
            onMouseEnter={props.onMouseEnter}
            onMouseMove={props.onMouseMove}
            onClick={props.onClick}
        >
            <div className="ui-shared-action__image">
                {renderImage()}
            </div>

            {props.label && (
                <div
                    className="ui-shared-action__label"
                    style={{
                        backgroundColor: styling.backgroundColor,
                        color: styling.textColor,
                    }}
                >
                    <FontRatio value={0.01}>{t(props.label)}</FontRatio>
                </div>
            )}
        </div>
    );
};

Action.defaultProps = {
    onClick: null,
    onMouseEnter: null,
    onMouseMove: null,
    onDragStart: null,
    onDrag: null,
    onDragEnd: null,
    withAnimation: false,
    label: null,
    position: null,
};

Action.propTypes = {
    onClick: PropTypes.func,
    onMouseEnter: PropTypes.func,
    onMouseMove: PropTypes.func,
    onDragStart: PropTypes.func,
    onDrag: PropTypes.func,
    onDragEnd: PropTypes.func,
    withAnimation: PropTypes.bool,
    label: PropTypes.object,
    image: PropTypes.string.isRequired,
    position: PropTypes.shape({
        type: PropTypes.oneOf(['within-scene', 'over-scene']).isRequired,
        left: PropTypes.number.isRequired,
        top: PropTypes.number.isRequired,
    }),
};

export default Action;
