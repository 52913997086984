export const AUTH_TYPE_NONE = 'vrtualx-token-none';
export const AUTH_TYPE_ADMIN = 'vrtualx-token-admin';
export const RENEWAL_TYPE_ADMIN = 'vrtualx-renewal-admin';
export const CUSTOMER_ID = 'vrtualx-customer-id';
export const AUTH_TYPE_VISITOR = 'vrtualx-token-visitor';
export const TERMS_TYPE_VISITOR = 'vrtualx-terms-visitor';

export const getAuthType = (projectPath) => (
    window.localStorage.getItem(AUTH_TYPE_ADMIN)
        ? AUTH_TYPE_ADMIN
        : `${AUTH_TYPE_VISITOR}-${projectPath}`
);

export const deleteAuthTokens = (authType) => {
    if (authType) {
        window.localStorage.removeItem(authType);
    }

    window.localStorage.removeItem(AUTH_TYPE_ADMIN);
    window.localStorage.removeItem(CUSTOMER_ID);
    window.localStorage.removeItem(RENEWAL_TYPE_ADMIN);
};
