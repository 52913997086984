const deDE = {
    // Root
    noRowsLabel: 'Keine Einträge',
    noResultsOverlayLabel: 'Keine Ergebnisse gefunden.',
    errorOverlayDefaultLabel: 'Ein unerwarteter Fehler ist aufgetreten.',

    // Density selector toolbar button text
    toolbarDensity: 'Zeilenhöhe',
    toolbarDensityLabel: 'Zeilenhöhe',
    toolbarDensityCompact: 'Kompakt',
    toolbarDensityStandard: 'Standard',
    toolbarDensityComfortable: 'Breit',

    // Columns selector toolbar button text
    toolbarColumns: 'Spalten',
    toolbarColumnsLabel: 'Zeige Spaltenauswahl',

    // Filters toolbar button text
    toolbarFilters: 'Filter',
    toolbarFiltersLabel: 'Zeige Filter',
    toolbarFiltersTooltipHide: 'Verberge Filter',
    toolbarFiltersTooltipShow: 'Zeige Filter',
    toolbarFiltersTooltipActive: (count) => (count !== 1 ? `${count} aktive Filter` : `${count} aktiver Filter`),

    // Export selector toolbar button text
    toolbarExport: 'Exportieren',
    toolbarExportLabel: 'Exportieren',
    toolbarExportCSV: 'Download als CSV',

    // Columns panel text
    columnsPanelTextFieldLabel: 'Finde Spalte',
    columnsPanelTextFieldPlaceholder: 'Spaltenüberschrift',
    columnsPanelDragIconLabel: 'Spalte umsortieren',
    columnsPanelShowAllButton: 'Zeige alle',
    columnsPanelHideAllButton: 'Verberge alle',

    // Filter panel text
    filterPanelAddFilter: 'Filter hinzufügen',
    filterPanelDeleteIconLabel: 'Löschen',
    filterPanelOperators: 'Operatoren',
    filterPanelOperatorAnd: 'Und',
    filterPanelOperatorOr: 'Oder',
    filterPanelColumns: 'Spalten',
    filterPanelInputLabel: 'Wert',
    filterPanelInputPlaceholder: 'Wert filtern',

    // Filter operators text
    filterOperatorContains: 'beinhaltet',
    filterOperatorEquals: 'ist gleich',
    filterOperatorStartsWith: 'beginnt mit',
    filterOperatorEndsWith: 'endet mit',
    filterOperatorIs: 'ist',
    filterOperatorNot: 'ist nicht',
    filterOperatorOnOrAfter: 'ist an oder nach',
    filterOperatorBefore: 'ist vor',
    filterOperatorOnOrBefore: 'ist an oder vor',
    filterOperatorAfter: 'ist nach',
    filterOperatorIsEmpty: 'ist leer',
    filterOperatorIsNotEmpty: 'ist nicht leer',

    // Column menu text
    columnMenuLabel: 'Menü',
    columnMenuShowColumns: 'Zeige alle Spalten',
    columnMenuFilter: 'Filter',
    columnMenuHideColumn: 'Verbergen',
    columnMenuUnsort: 'Sortierung deaktivieren',
    columnMenuSortAsc: 'Sortiere aufsteigend',
    columnMenuSortDesc: 'Sortiere absteigend',

    // Column header text
    columnHeaderFiltersTooltipActive: (count) => (count !== 1 ? `${count} aktive Filter` : `${count} aktiver Filter`),
    columnHeaderFiltersLabel: 'Zeige Filter',
    columnHeaderSortIconLabel: 'Sortieren',

    // Rows selected footer text
    footerRowSelected: (count) => (count !== 1
        ? `${count.toLocaleString()} Einträge ausgewählt`
        : `${count.toLocaleString()} Eintrag ausgewählt`),

    // Total rows footer text
    footerTotalRows: 'Gesamt:',

    // Total visible rows footer text
    footerTotalVisibleRows: (visibleCount, totalCount) => `${visibleCount.toLocaleString()} von ${totalCount.toLocaleString()}`,
};

export default deDE;
