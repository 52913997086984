import React, { useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import useToggle from 'hooks/useToggle';
import useProject from 'hooks/graphql/queries/project';
import useDeleteVisitors from 'hooks/graphql/mutations/deleteVisitors';
import MeContext from 'contexts/me';
import Button from 'cms/atoms/Button';
import Headline from 'cms/atoms/Headline';
import Datagrid from 'cms/atoms/Datagrid';
import Confirm from 'components/ui/cms/molecules/Confirm';
import VisitorEditor from '../../editors/Project/Login/VisitorWithCouponCode';
import CSVImportEditor from '../../editors/Project/Login/CSVImport';

const VisitorsWithCouponCode = () => {
    const params = useParams();

    const { data, loading } = useProject(params.id);
    const { project } = !loading && data;

    const deleteVisitors = useDeleteVisitors(project);

    const [visitorEditorState, setVisitorEditorState] = useState({ isOpen: false, id: null });
    const [isVisitorsEditorOpen, openVisitorsEditor, closeVisitorsEditor] = useToggle(false);
    const [deleteVisitorDialogState, setDeleteVisitorDialogState] = useState({
        isOpen: false,
        selectedVisitorIds: [],
    });
    const me = useContext(MeContext);

    const openVisitorEditorHandler = (id) => () => {
        setVisitorEditorState({ isOpen: true, id });
    };

    const closeVisitorEditor = () => {
        setVisitorEditorState({ ...visitorEditorState, isOpen: false });
    };

    const openDeleteVisitorDialogHandler = (selectedVisitorIds) => () => {
        setDeleteVisitorDialogState({ isOpen: true, selectedVisitorIds });
    };

    const closeDeleteVisitorDialog = () => {
        setDeleteVisitorDialogState({ ...deleteVisitorDialogState, isOpen: false });
    };

    const handleDeleteVisitor = async () => {
        await deleteVisitors(deleteVisitorDialogState.selectedVisitorIds);
        closeDeleteVisitorDialog();
    };

    return !loading && (
        <>
            <Headline
                action={(
                    <>
                        {me.admin.isBaseAdmin && (
                            <Button
                                icon="upload"
                                onClick={openVisitorsEditor}
                            >
                                CSV-Import
                            </Button>
                        )}

                        {me.hasWriteAccessToFeature('project.login') && (
                            <Button
                                icon="add"
                                onClick={openVisitorEditorHandler(null)}
                            >
                                Neuer Account
                            </Button>
                        )}
                    </>
                )}
            >
                Gutscheine
            </Headline>

            <CSVImportEditor
                isOpen={isVisitorsEditorOpen}
                onClose={closeVisitorsEditor}
                project={project}
            />

            <VisitorEditor
                isOpen={visitorEditorState.isOpen}
                onClose={closeVisitorEditor}
                project={project}
                visitor={
                    project.visitors.find(({ id }) => id === visitorEditorState.id)
                }
            />

            <Confirm
                title="Soll die Auswahl wirklich gelöscht werden?"
                onConfirm={handleDeleteVisitor}
                confirmLabel="Ja, löschen"
                onCancel={closeDeleteVisitorDialog}
                cancelLabel="Nein, abbrechen"
                isOpen={deleteVisitorDialogState.isOpen}
                destructive
            />

            <Datagrid
                checkboxSelection={me.hasWriteAccessToFeature('project.login')}
                pageSize={25}
                onDelete={me.hasWriteAccessToFeature('project.login') && openDeleteVisitorDialogHandler}
                onEdit={me.hasWriteAccessToFeature('project.login') && openVisitorEditorHandler}
                columns={[
                    { field: 'couponCode', headerName: 'Gutschein', width: 200 },
                    { field: 'privileges', headerName: 'Privilegien', width: 200 },
                ]}
                rows={
                    project.visitors
                        .filter(({ couponCode }) => !!couponCode)
                        .sort((a, b) => a.couponCode.localeCompare(b.couponCode))
                        .map((visitor) => ({
                            id: visitor.id,
                            couponCode: visitor.couponCode,
                            privileges: visitor.privileges.map(({ name }) => name).join(', '),
                        }))
                }
            />
        </>
    );
};

export default VisitorsWithCouponCode;
