import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import useTranslate from 'hooks/useTranslate';
import Dialog from 'web/molecules/Dialog';
import Image from 'Image';

const PanoramaDialog = (props) => {
    const history = useHistory();
    const params = useParams();
    const t = useTranslate();

    const goTo2DScene = () => {
        props.onClose();
        history.push(`/event/${params.projectPath}/${props.scene.path}`);
    };

    const goToPanoramaScene = () => {
        const scenePath = props.scene.panoramaStartScene
            ? props.scene.panoramaStartScene.path
            : props.scene.panoramaChildScenes[0].path;

        props.onClose();
        history.push(`/event/${params.projectPath}/${scenePath}`);
    };

    return (
        <Dialog
            isOpen={!!props.scene}
            onClose={props.onClose}
            title={t('web.panoramaDialog.title')}
        >
            <div className="web-panorama-dialog">
                <div
                    className="web-panorama-dialog__image"
                    onClick={goTo2DScene}
                >
                    <Image url="/images/2d.png" />
                </div>

                <div
                    className="web-panorama-dialog__image"
                    onClick={goToPanoramaScene}
                >
                    <Image url="/images/360.png" />
                </div>
            </div>
        </Dialog>
    );
};

PanoramaDialog.defaultProps = {
    scene: null,
};

PanoramaDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    scene: PropTypes.object,
};

export default PanoramaDialog;
