import React from 'react';
import PropTypes from 'prop-types';
import MuiSelect from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

const Select = (props) => (
    <FormControl fullWidth error={!!props.error}>
        {props.label && (
            <InputLabel>{props.label}</InputLabel>
        )}

        <MuiSelect
            className="ui-cms-select"
            value={props.value}
            onChange={props.onChange}
            name={props.name}
            disabled={props.disabled}
        >
            {props.items.map(({ label, value, image }) => (
                <MenuItem
                    key={`${label}${value}`}
                    value={value}
                    disabled={value === undefined}
                >
                    <div className="ui-cms-select__item">
                        {image && (
                            <div className="ui-cms-select__image">
                                {image}
                            </div>
                        )}

                        {label}
                    </div>
                </MenuItem>
            ))}
        </MuiSelect>

        <FormHelperText>{props.error || props.helper}</FormHelperText>
    </FormControl>
);

Select.defaultProps = {
    error: null,
    label: null,
    name: null,
    disabled: false,
    helper: null,
};

Select.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.node.isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        image: PropTypes.node,
    })).isRequired,
    name: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    onChange: PropTypes.func.isRequired,
    error: PropTypes.string,
    disabled: PropTypes.bool,
    helper: PropTypes.string,
};

export default Select;
