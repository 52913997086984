import React from 'react';
import PropTypes from 'prop-types';
import useTranslate from 'hooks/useTranslate';
import Toast from 'web/molecules/Toast';
import Dialog from 'web/molecules/Dialog';
import InnerHTML from 'InnerHTML';

const Notification = (props) => {
    const t = useTranslate();

    const duration = props.notification?.duration;
    const delayMs = duration ? duration * 1000 : null;

    const renderToastNotification = (notification) => (
        <Toast
            isOpen={props.isOpen}
            onClose={props.onClose}
            title={t(notification.title)}
            delay={delayMs}
        >
            <InnerHTML>{t(notification.body)}</InnerHTML>
        </Toast>
    );

    const renderDialogNotification = (notification) => (
        <Dialog
            isOpen={props.isOpen}
            onClose={props.onClose}
            title={t(notification.title)}
        >
            <InnerHTML>{t(notification.body)}</InnerHTML>
        </Dialog>
    );

    const renderNotification = (notification) => {
        if (notification.position === 'CENTERED') {
            return renderDialogNotification(notification);
        }

        return renderToastNotification(notification);
    };

    return renderNotification(props.notification);
};

Notification.defaultProps = {
    onClose: null,
};

Notification.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    notification: PropTypes.object.isRequired,
};

export default Notification;
