var basePath = window.location.origin;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
var isProd = basePath === 'https://app.virtuell-x.com';
export var trackingNotice = {
    name: 'Tracking Banner',
    description: 'Zeigt einen Banner, dass Statistiken eventuell ungenau sind',
    isEnabled: function () { return true; },
};
export var openingTimesExpectedVisitors = {
    name: 'Besucherzahl bei Öffnungszeiten',
    description: 'Aktiviert die Möglichkeit, bei Öffnungszeiten die erwartete Besucheranzahl anzugeben',
    isEnabled: function () { return false; },
};
