import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import EmailValidator from 'email-validator';
import useTranslate from 'hooks/useTranslate';
import useTracking from 'hooks/useTracking';
import useToggle from 'hooks/useToggle';
import { TRACKING_REQUEST_RESET_PASSWORD } from 'helpers/tracking';
import ProjectModel from 'models/web/project';
import TextField from 'web/atoms/TextField';
import Dialog from 'web/molecules/Dialog';
import FormDialog from 'web/molecules/FormDialog';
// import useGeoLocation from 'hooks/useGeoLocation';
import useDeviceDetect from 'hooks/useDeviceDetect';

const ResetPassword = (props) => {
    const t = useTranslate();
    const [success, showSuccess, hideSuccess] = useToggle(false);
    const tracking = useTracking();
    const [state, setState] = useState({
        email: '',
    });

    const validators = [{
        name: 'email',
        message: () => t('web.common.error.email'),
        isValid: (value) => !!value.trim() && EmailValidator.validate(value),
    }, {
        name: 'email',
        message: () => t('web.resetPassword.error.notFound'),
        serverError: 'VISITOR_DOES_NOT_EXIST',
    }];

    useEffect(() => () => {
        hideSuccess();
    }, [props.isOpen]);
    // const geolocation = useGeoLocation();
    const deviceDetect = useDeviceDetect();
    const confirm = async (values) => {
        await props.requestPasswordReset({
            projectId: props.projectId,
            email: values.email.trim(),
        });
        // TRACKCORE:
        //  event:'requestResetPasswort', ressource: values.email, data: {customer: customerId, registration: 'Registration', Latitude: Num, Longitude: Num, Device: String, Browser: String}
        tracking.trackCoreEvent({
            resourceType: 'email',
            resourceName: values.email,
            action: 'requestResetPasswort',
            data: [
                {
                    name: 'Registrierung',
                    value: 'Registrierung',
                },
                {
                    name: 'platform',
                    value: deviceDetect.platform,
                },
                {
                    name: 'browser',
                    value: deviceDetect.browser,
                },
                {
                    name: 'device',
                    value: deviceDetect.device,
                },
                {
                    name: 'resolution',
                    value: deviceDetect.res,
                },
            ],
        });
        tracking.trackCoreEvent({
            resourceType: 'email',
            resourceName: values.email,
            action: 'requestResetPasswort',
            data: [
                {
                    name: 'Registrierung',
                    value: 'Registrierung',
                },
                // {
                //     name: 'latitude',
                //     value: geolocation.latitude,
                // },
                // {
                //     name: 'longitude',
                //     value: geolocation.longitude,
                // },
            ],
        });
        tracking.trackEvent('Registration', TRACKING_REQUEST_RESET_PASSWORD, values.email);
        showSuccess();
    };

    const renderSuccess = () => (
        <Dialog
            isOpen={props.isOpen && success}
            onClose={props.onClose}
            title={t('web.resetPassword.title')}
        >
            {t('web.resetPassword.success')}
        </Dialog>
    );

    const renderForm = () => (
        <FormDialog
            isOpen={props.isOpen && !success}
            title={t('web.resetPassword.title')}
            values={state}
            onConfirm={confirm}
            onChange={setState}
            onClose={props.onClose}
            validators={validators}
            withSubmitButton
        >
            {({ errors, onChangeByEvent }) => (
                <>
                    <TextField
                        type="email"
                        label={t(props.loginLabelUser) || t('web.login.user')}
                        value={state.email}
                        name="email"
                        onChange={onChangeByEvent}
                        error={errors.email}
                    />
                </>
            )}
        </FormDialog>
    );

    return (
        <>
            {renderSuccess()}
            {renderForm()}
        </>
    );
};

ResetPassword.defaultProps = {
    loginLabelUser: null,
    onClose: null,
};

ResetPassword.propTypes = {
    requestPasswordReset: PropTypes.func.isRequired,
    projectId: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    loginLabelUser: PropTypes.object,
};

export default ProjectModel.graphql(ResetPassword, { withoutQuery: true });
