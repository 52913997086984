import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import MuiButton from '@material-ui/core/Button';
import Icon from 'Icon';
import CircularProgress from '@material-ui/core/CircularProgress';

const Button = (props) => (
    <MuiButton
        className={classNames(
            'ui-cms-button',
            { 'ui-cms-button--empty': !props.children },
            props.className,
        )}
        color={props.color}
        disabled={props.disabled || props.isPending}
        href={props.href}
        onClick={props.onClick}
        size={props.size}
        startIcon={props.icon ? <Icon type={props.icon} /> : null}
        target={props.newTab ? '_blank' : null}
        type={props.type}
    >
        {props.isPending && (
            <div className="ui-cms-button__pending-indicator">
                <CircularProgress color="inherit" size={16} />
            </div>
        )}
        {props.children}
    </MuiButton>
);

Button.defaultProps = {
    children: '',
    className: null,
    color: 'primary',
    disabled: false,
    href: null,
    icon: null,
    isPending: false,
    newTab: false,
    onClick: null,
    size: 'medium',
    type: 'button',
};

Button.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    color: PropTypes.string,
    disabled: PropTypes.bool,
    href: PropTypes.string,
    icon: PropTypes.string,
    isPending: PropTypes.bool,
    newTab: PropTypes.bool,
    onClick: PropTypes.func,
    size: PropTypes.string,
    type: PropTypes.string,
};

export default Button;
