import React from 'react';
import PropTypes from 'prop-types';
import Lightbox from 'yet-another-react-lightbox';
import { Inline, /* Thumbnails, */ Zoom, Fullscreen, Captions } from 'yet-another-react-lightbox/dist/plugins';
/* import Image from 'Image'; */
/* Add thumbnails if there is a fix for this issue: https://github.com/igordanchenko/yet-another-react-lightbox/discussions/21 */
const Gallery = (props) => (
    <Lightbox
        slides={props.items}
        plugins={[Inline, /* Thumbnails, */ Zoom, Fullscreen, Captions]}
        /* thumbnails={{ vignette: false }} */
        inline={{ style: { aspectRatio: '3 / 2' } }}
        render={{ slide: props.render }}
        /* thumbnail: ({ slide }) => (
                <Image className="yarl__slide_image" url={slide.srcSet[0].src} />
            ) }} */
        captions={{ descriptionTextAlign: 'center' }}
        zoom={{
            scrollToZoom: true,
            maxZoomPixelRatio: 3,
        }}
    />
);
Gallery.defaultProps = {
    onSlide: null,
    render: {},
};

Gallery.propTypes = {
    items: PropTypes.array.isRequired,
    render: PropTypes.func,
    onSlide: PropTypes.func,
};

export default Gallery;
