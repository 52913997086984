import React from 'react';
import PropTypes from 'prop-types';
import { ChromePicker } from 'react-color';

const ColorPicker = (props) => {
    const onChange = (color) => {
        props.onChange(color.hex);
    };

    return (
        <div className="ui-cms-color-picker">
            <ChromePicker
                color={props.value}
                onChange={onChange}
                disableAlpha={props.disableAlpha}
            />
        </div>
    );
};

ColorPicker.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    disableAlpha: PropTypes.bool,
};

ColorPicker.defaultProps = {
    disableAlpha: false,
};

export default ColorPicker;
