import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import useDeleteAdmin from 'hooks/graphql/mutations/deleteAdmin';
import Button from 'cms/atoms/Button';
import Headline from 'cms/atoms/Headline';
import TextField from 'cms/atoms/TextField';
import DataTable from 'cms/molecules/DataTable';
import Confirm from 'components/ui/cms/molecules/Confirm';
import MeContext from 'contexts/me';
import AdminEditor from '../editors/Admin';

const Admins = (props) => {
    const [adminEditorState, setAdminEditorState] = useState({ isOpen: false, id: null });
    const [filter, setFilter] = useState('');
    const [deleteAdminDialogState, setDeleteAdminDialogState] = useState({
        isOpen: false, id: null,
    });
    const me = useContext(MeContext);

    const deleteAdmin = useDeleteAdmin();

    const filterByNameOrEmail = (event) => {
        setFilter(event.target.value);
    };

    const openAdminEditorHandler = (id) => () => {
        setAdminEditorState({ isOpen: true, id });
    };

    const closeAdminEditor = () => {
        setAdminEditorState({ ...adminEditorState, isOpen: false });
    };

    const openDeleteAdminDialogHandler = (id) => () => {
        setDeleteAdminDialogState({ isOpen: true, id });
    };

    const closeDeleteAdminDialog = () => {
        setDeleteAdminDialogState({ ...deleteAdminDialogState, isOpen: false });
    };

    const handleDeleteAdmin = () => {
        deleteAdmin(deleteAdminDialogState.id);
        closeDeleteAdminDialog();
    };

    const renderAccess = (admin) => {
        if (!admin.fullSceneAccess) {
            const sceneCount = admin.accessibleScenes.length;
            if (sceneCount === 1) {
                return '1 Szene';
            }
            return `${sceneCount} Szenen`;
        }
        if (!admin.fullProjectAccess) {
            const projectCount = admin.accessibleProjects.length;
            if (projectCount === 1) {
                return '1 Projekt';
            }
            return `${projectCount} Projekte`;
        }
        return 'Alle Projekte';
    };

    const renderActions = (admin) => (
        <>
            <Button
                icon="edit"
                onClick={openAdminEditorHandler(admin.id)}
            />

            <Button
                icon="delete"
                onClick={openDeleteAdminDialogHandler(admin.id)}
                color="secondary"
            />
        </>
    );

    return (
        <>
            <Headline
                action={!me.hasWriteAccessToFeature('customer.accounts.new') ? null : (
                    <Button
                        icon="add"
                        onClick={openAdminEditorHandler(null)}
                    >
                        Neuer Account
                    </Button>
                )}
            >
                Kunden-Accounts
            </Headline>

            <AdminEditor
                isOpen={adminEditorState.isOpen}
                onClose={closeAdminEditor}
                customerId={props.customer.id}
                customerProjects={props.customer.projects}
                admin={props.customer.admins.find(({ id }) => id === adminEditorState.id)}
                roles={props.customer.roles}
            />

            <Confirm
                title="Soll dieser Account wirklich gelöscht werden?"
                onConfirm={handleDeleteAdmin}
                confirmLabel="Ja, löschen"
                onCancel={closeDeleteAdminDialog}
                cancelLabel="Nein, abbrechen"
                isOpen={deleteAdminDialogState.isOpen}
                destructive
            />

            <DataTable
                emptyMessage="Es wurden keine Kunden-Accounts gefunden."
                filters={(
                    <TextField
                        value={filter}
                        onChange={filterByNameOrEmail}
                        label="Nach Name oder E-Mail-Adresse filtern"
                        icon="search"
                    />
                )}
                columns={[
                    { dataKey: 'name', label: 'Name' },
                    { dataKey: 'email', label: 'E-Mail-Adresse' },
                    { dataKey: 'role', label: 'Rolle' },
                    { dataKey: 'access', label: 'Zugriff' },
                    { dataKey: 'actions', label: 'Aktionen', width: 100, align: 'center' },
                ]}
                rows={
                    props.customer.admins
                        .filter((admin) => {
                            const sanitizedFilter = filter.trim().toLowerCase();

                            return (
                                !sanitizedFilter
                                || admin.account.email.toLowerCase().includes(sanitizedFilter)
                                || `${admin.firstName} ${admin.lastName}`.toLowerCase().includes(sanitizedFilter)
                            );
                        })
                        .sort((a, b) => a.account.email.localeCompare(b.account.email))
                        .map((admin) => ({
                            name: `${admin.firstName} ${admin.lastName}`,
                            email: admin.account.email,
                            role: admin.role ? admin.role.name : 'Keine',
                            access: renderAccess(admin),
                            actions: renderActions(admin),
                        }))
                }
            />
        </>
    );
};

Admins.propTypes = {
    customer: PropTypes.object.isRequired,
};

export default Admins;
