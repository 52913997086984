import React, { useState } from 'react';
import moment from 'moment';
import Headline from 'cms/atoms/Headline';
import DateTimePicker from 'cms/atoms/DateTimePicker';
import Grid from 'cms/molecules/Grid';
import Content from './Statistics/Content';

const Statistics = () => {
    const [startDate, setStartDate] = useState(moment().subtract(1, 'day'));
    const [endDate, setEndDate] = useState(moment().subtract(1, 'day'));

    return (
        <>
            <Headline>Statistik</Headline>

            <Grid>
                <Grid item size={6}>
                    <DateTimePicker
                        label="von"
                        value={startDate}
                        onChange={setStartDate}
                        onlyDate
                        disableFuture
                    />
                </Grid>

                <Grid item size={6}>
                    <DateTimePicker
                        label="bis"
                        value={endDate}
                        onChange={setEndDate}
                        onlyDate
                        disableFuture
                    />
                </Grid>
            </Grid>

            <Content
                startDate={startDate.toISOString()}
                endDate={endDate.toISOString()}
            />
        </>
    );
};

export default Statistics;
