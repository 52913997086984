import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Chart,
    ArgumentAxis,
    ValueAxis,
    BarSeries,
    Legend,
    Tooltip,
} from '@devexpress/dx-react-chart-material-ui';
import { Stack, HoverState, EventTracker } from '@devexpress/dx-react-chart';

const TooltipContent = (props) => (
    <Tooltip.Content text={props.text} />
);

const Time = (props) => {
    const [hover, setHover] = useState(null);
    const [tooltipTarget, setTooltipTarget] = useState(null);

    return (
        <Chart data={props.hours}>
            <ArgumentAxis />
            <ValueAxis />

            <BarSeries
                name="Eindeutige Besucher"
                valueField="uniqueClicks"
                argumentField="label"
            />

            <BarSeries
                name="Besuche"
                valueField="totalClicks"
                argumentField="label"
            />

            <Legend position="bottom" />

            <Stack
                stacks={[{
                    series: ['Eindeutige Besucher', 'Besuche'],
                }]}
            />

            <EventTracker />

            <HoverState
                hover={hover}
                onHoverChange={setHover}
            />

            <Tooltip
                targetItem={tooltipTarget}
                onTargetItemChange={setTooltipTarget}
                contentComponent={TooltipContent}
            />
        </Chart>
    );
};

Time.propTypes = {
    hours: PropTypes.array.isRequired,
};

export default Time;
