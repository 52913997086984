var loginTypes = [
    {
        label: 'Ohne Login',
        value: 'NONE',
        active: true,
        withAccessGroups: false,
        withLoginTracking: false,
        withUserLabel: false,
        withPasswordLabel: false,
        withEventId: false,
        withOauth: false,
        withApiKey: false,
        withSubmitButton: false,
        withLoginDialogType: false,
    },
    /*
    https://vrtual-x.monday.com/boards/583307710/pulses/1052955786/posts/950895943
    IP Whitelist has been disabled, since it's currently not needed.
    Full comment:

    > "Da wir diese Feature nun vorerst doch nicht mehr benötigen und es aber noch nicht richtig funktioniert, sollten wir es ausblenden.
    > Bitte im System lassen, aber aus er Login-Art-Liste ausblenden."
    */
    /*     {
        label: 'IP Whitelist',
        value: 'IP',
        withAccessGroups: false,
        withLoginTracking: false,
        withUserLabel: false,
        withPasswordLabel: false,
        withEventId: false,
        withSubmitButton: false,
    }, */
    {
        label: 'Passwort',
        value: 'PASSWORD',
        active: true,
        withAccessGroups: false,
        withLoginTracking: false,
        withUserLabel: false,
        withPasswordLabel: true,
        withEventId: false,
        withOauth: false,
        withApiKey: false,
        withSubmitButton: true,
        withLoginDialogType: false,
    },
    {
        label: 'Accounts',
        value: 'ACCOUNTS',
        active: true,
        withAccessGroups: true,
        withLoginTracking: true,
        withUserLabel: true,
        withPasswordLabel: true,
        withEventId: false,
        withOauth: false,
        withApiKey: false,
        withSubmitButton: true,
        withLoginDialogType: false,
    },
    {
        label: 'Gutscheine',
        value: 'COUPON_CODES',
        active: true,
        withAccessGroups: true,
        withLoginTracking: false,
        withUserLabel: false,
        withPasswordLabel: true,
        withEventId: false,
        withOauth: false,
        withApiKey: false,
        withSubmitButton: true,
        withLoginDialogType: false,
    },
    {
        label: 'Extern (m-anage)',
        value: 'EXTERNAL_MANAGE',
        active: true,
        withAccessGroups: true,
        withLoginTracking: true,
        withUserLabel: false,
        withPasswordLabel: false,
        withEventId: true,
        withOauth: true,
        withApiKey: false,
        withSubmitButton: false,
        withLoginDialogType: true,
    },
    {
        label: 'Extern (Regasus)',
        value: 'EXTERNAL_REGASUS',
        active: true,
        withAccessGroups: true,
        withLoginTracking: true,
        withUserLabel: false,
        withPasswordLabel: false,
        withEventId: true,
        withOauth: false,
        withApiKey: false,
        withSubmitButton: false,
        withLoginDialogType: true,
    },
    {
        label: 'Extern (EventsAIR)',
        value: 'EXTERNAL_EVENTSAIR',
        active: true,
        withAccessGroups: true,
        withLoginTracking: true,
        withUserLabel: false,
        withPasswordLabel: false,
        withEventId: true,
        withOauth: false,
        withApiKey: false,
        withSubmitButton: false,
        withLoginDialogType: true,
    },
    {
        label: 'Extern (Hapag Lloyd)',
        value: 'EXTERNAL_HAPAG_LLOYD',
        active: true,
        withAccessGroups: true,
        withLoginTracking: true,
        withUserLabel: false,
        withPasswordLabel: false,
        withEventId: false,
        withOauth: false,
        withApiKey: false,
        withSubmitButton: false,
        withLoginDialogType: true,
    },
    {
        label: 'Extern (Plazz)',
        value: 'EXTERNAL_PLAZZ',
        active: true,
        withAccessGroups: true,
        withLoginTracking: true,
        withUserLabel: false,
        withPasswordLabel: false,
        withEventId: true,
        withOauth: false,
        withApiKey: false,
        withSubmitButton: false,
        withLoginDialogType: true,
    },
    {
        label: 'Extern (XING Events)',
        value: 'EXTERNAL_XING_EVENTS',
        active: true,
        withAccessGroups: true,
        withLoginTracking: true,
        withUserLabel: false,
        withPasswordLabel: false,
        withEventId: true,
        withOauth: false,
        withApiKey: true,
        withSubmitButton: true,
        withLoginDialogType: false,
    },
];
export default loginTypes;
export var findLoginType = function (loginType) { return (loginTypes.find(function (_a) {
    var value = _a.value;
    return value === loginType;
})); };
