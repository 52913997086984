import { useMutation } from 'apollo-augmented-hooks';
import { QUOTA } from '../queries/customer';

const mutation = `
    mutation updateQuota($input: UpdateQuotaInput!) {
        updateQuota(input: $input) {
            ${QUOTA}
        }
    }
`;

export default () => {
    const [mutate] = useMutation(mutation);

    return (input) => (
        mutate({ input })
    );
};
