import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Icon from 'Icon';

const Help = (props) => {
    const [isOpen, setIsOpen] = useState(false);

    const open = () => {
        setIsOpen(true);
    };

    const close = () => {
        setIsOpen(false);
    };

    return (
        <ClickAwayListener onClickAway={close}>
            <div>
                <Tooltip
                    arrow
                    title={props.children}
                    className="ui-cms-help"
                    open={isOpen}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    PopperProps={{ disablePortal: true }}
                    interactive
                >
                    <div onClick={open}>
                        <Icon type="help" />
                    </div>
                </Tooltip>
            </div>
        </ClickAwayListener>
    );
};

Help.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Help;
