import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from 'cms/atoms/TextField';

const Confirm = (props) => {
    const [confirmation, setConfirmation] = useState('');

    const changeConfirmation = (event) => {
        setConfirmation(event.target.value);
    };

    useEffect(() => {
        if (props.isOpen) {
            setConfirmation('');
        }
    }, [props.isOpen]);

    return (
        <Dialog open={props.isOpen}>
            <DialogTitle>
                {props.title}
            </DialogTitle>

            {(props.children || props.confirmationCode) && (
                <DialogContent dividers>
                    {props.children}

                    {props.confirmationCode && (
                        <div className="ui-cms-confirm__confirmation">
                            Bitte gib zur Bestätigung im folgenden Feld &quot;DELETE&quot; ein.

                            <TextField
                                name="confirmation"
                                value={confirmation}
                                onChange={changeConfirmation}
                            />
                        </div>
                    )}
                </DialogContent>
            )}

            <DialogActions>
                {props.onCancel && (
                    <Button onClick={props.onCancel}>
                        {props.cancelLabel}
                    </Button>
                )}

                <Button
                    color={props.destructive ? 'secondary' : 'primary'}
                    onClick={props.onConfirm}
                    disabled={!!props.confirmationCode && confirmation.toLowerCase() !== 'delete'}
                >
                    {props.confirmLabel}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

Confirm.defaultProps = {
    children: null,
    confirmLabel: 'OK',
    onCancel: null,
    cancelLabel: null,
    destructive: false,
    confirmationCode: null,
};

Confirm.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.node,
    onConfirm: PropTypes.func.isRequired,
    confirmLabel: PropTypes.string,
    onCancel: PropTypes.func,
    cancelLabel: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    destructive: PropTypes.bool,
    confirmationCode: PropTypes.string,
};

export default Confirm;
