import React from 'react';
import PropTypes from 'prop-types';
import FormCheck from 'react-bootstrap/FormCheck';
import Feedback from 'react-bootstrap/Feedback';

const Checkbox = (props) => {
    const onChange = () => {
        props.onChange(!props.checked);
    };

    return (
        <FormCheck>
            <FormCheck.Label>
                <FormCheck.Input
                    type="checkbox"
                    onChange={onChange}
                    checked={props.checked}
                />
                {props.children}
            </FormCheck.Label>
            {props.error && (
                <Feedback
                    type="invalid"
                    className="ui-web-checkbox--feedback"
                >
                    {props.error}
                </Feedback>
            )}
        </FormCheck>
    );
};

Checkbox.defaultProps = {
    error: null,
};

Checkbox.propTypes = {
    error: PropTypes.string,
    children: PropTypes.node.isRequired,
    onChange: PropTypes.func.isRequired,
    checked: PropTypes.bool.isRequired,
};

export default Checkbox;
