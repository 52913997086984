import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import StylingContext from 'contexts/styling';
import Button from 'web/atoms/Button';

const Alert = (props) => {
    const styling = useContext(StylingContext);

    return (
        <Modal
            className="ui-web-alert"
            show={props.isOpen}
            backdrop="static"
            scrollable
            centered
            container={document.getElementById('app')}
        >
            <Modal.Header
                className="ui-web-alert__header"
                style={{ backgroundColor: styling.backgroundColor }}
            >
                <Modal.Title style={{ color: styling.textColor }}>
                    {props.title}
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {props.children}
            </Modal.Body>

            <Modal.Footer className="ui-web-alert__footer">
                <Button
                    onClick={props.onCancel}
                    secondary
                >
                    {props.cancelLabel}
                </Button>

                <Button onClick={props.onConfirm}>
                    {props.confirmLabel}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

Alert.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    onConfirm: PropTypes.func.isRequired,
    confirmLabel: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired,
    cancelLabel: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
};

export default Alert;
