export const getMenuItemType = (menuItem = {}) => {
    const {
        sceneData, infoboxData, externalData,
    } = menuItem;

    if (sceneData?.id) return 'SCENE';
    if (infoboxData?.id) return 'INFOBOX';
    if (externalData?.id) return 'EXTERNAL';

    return '';
};

export const defaultMenuItemFormState = () => ({
    id: undefined,
    label: { _translation: 'text' },
    type: {
        _default: '',
        _key: '_parent',
        _modify: getMenuItemType,
    },
    sceneData: {
        id: undefined,
        sceneId: {
            _default: '',
            _key: 'scene',
            _modify: ({ id }) => id,
        },
    },
    infoboxData: {
        id: undefined,
        title: { _translation: 'text' },
        content: { _translation: 'text' },
    },
    externalData: {
        id: undefined,
        url: { _translation: 'text' },
        openInNewTab: true,
    },
});
