import React from 'react';
import PropTypes from 'prop-types';

const LabelList = (props) => {
    const renderLabel = ({ label, remark }, index) => (
        <div
            key={index}
            className="ui-cms-label-list-label"
        >
            {label}{remark && ` (${remark})`}
        </div>
    );

    return (
        <div className="ui-cms-label-list">
            {props.labels.map(renderLabel)}
        </div>
    );
};

LabelList.propTypes = {
    labels: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        remark: PropTypes.string,
    })).isRequired,
};

export default LabelList;
