import React from 'react';
import PropTypes from 'prop-types';
import BsJumbotron from 'react-bootstrap/Jumbotron';

const Jumbotron = (props) => (
    <BsJumbotron className="ui-web-jumbotron">
        <h1>{props.title}</h1>

        <div>
            {props.children}
        </div>
    </BsJumbotron>
);

Jumbotron.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
};

export default Jumbotron;
