import React from 'react';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Icon from 'Icon';

const TextField = (props) => {
    const onBlur = (event) => {
        if (props.filter) {
            event.target.value = props.filter(props.value);
            props.onChange(event);
        }

        if (props.onBlur) {
            props.onBlur();
        }
    };

    return (
        <FormControl
            fullWidth={props.fullWidth}
            error={!!props.error}
            className="ui-cms-text-field"
        >
            {props.label && (
                <InputLabel>{props.label}</InputLabel>
            )}

            <Input
                value={props.value}
                name={props.name}
                onChange={props.onChange}
                onKeyDown={props.onKeyDown}
                onBlur={onBlur}
                type={props.type}
                placeholder={props.placeholder}
                inputProps={{
                    maxLength: props.maxLength || undefined,
                    min: props.min || undefined,
                    max: props.max || undefined,
                }}
                multiline={props.multiline}
                disabled={props.disabled}
                startAdornment={props.icon ? (
                    <InputAdornment position="start">
                        <Icon type={props.icon} />
                    </InputAdornment>
                ) : null}
            />

            <FormHelperText>
                {props.error || (props.maxLength && `${props.value.length}/${props.maxLength}`)}
            </FormHelperText>
        </FormControl>
    );
};

TextField.defaultProps = {
    name: null,
    label: null,
    type: null,
    error: null,
    onBlur: null,
    multiline: false,
    disabled: false,
    filter: null,
    icon: null,
    min: null,
    max: null,
    maxLength: null,
    fullWidth: true,
    placeholder: null,
    onKeyDown: () => {},
};

TextField.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onKeyDown: PropTypes.func,
    onBlur: PropTypes.func,
    type: PropTypes.string,
    error: PropTypes.string,
    multiline: PropTypes.bool,
    disabled: PropTypes.bool,
    filter: PropTypes.func,
    icon: PropTypes.string,
    min: PropTypes.number,
    max: PropTypes.number,
    maxLength: PropTypes.number,
    fullWidth: PropTypes.bool,
    placeholder: PropTypes.string,
};

export default TextField;
