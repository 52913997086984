import React from 'react';
import PropTypes from 'prop-types';
import BsBadge from 'react-bootstrap/Badge';

const Badge = (props) => (
    <BsBadge variant={props.variant} pill>
        {props.children}
    </BsBadge>
);

Badge.defaultProps = {
    variant: 'primary',
};

Badge.propTypes = {
    children: PropTypes.node.isRequired,
    variant: PropTypes.string,
};

export default Badge;
