import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import FileContext from 'contexts/files';
import LocaleContext from 'contexts/locale';
import StylingContext from 'contexts/styling';
import { findSupportedLanguage } from 'helpers/languages';
import Container from 'web/organisms/Container';
import ProjectModel from 'models/web/project';
import Helmet from './Project/Helmet';
import SetPassword from './Project/SetPassword';

const SetPasswordWidget = (props) => {
    const { token } = queryString.parse(window.location.search);

    const renderForm = ({ loginLabelPassword }) => (
        <SetPassword
            token={token || ''}
            loginLabelPassword={loginLabelPassword}
            projectPath={props.match.params.projectPath}
            isOpen
        />
    );

    const renderContent = () => {
        if (!props.data.projectByPath) {
            // TODO: Handle 404
            return <div>404</div>;
        }

        const {
            websiteTitle, languages, defaultLanguage, styling, files,
        } = props.data.projectByPath;

        const language = findSupportedLanguage(window, languages, defaultLanguage);

        return (
            <>
                <LocaleContext.Provider value={{ language, languages, defaultLanguage }}>
                    <StylingContext.Provider value={styling}>
                        <FileContext.Provider value={files}>
                            <Helmet title={websiteTitle} />
                            {renderForm(props.data.projectByPath)}
                        </FileContext.Provider>
                    </StylingContext.Provider>
                </LocaleContext.Provider>
            </>
        );
    };

    return (
        <>
            <Container>{!props.data.loading && renderContent()}</Container>
        </>
    );
};

SetPasswordWidget.propTypes = {
    data: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};

export default withRouter(ProjectModel.graphql(SetPasswordWidget));
