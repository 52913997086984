import { useMutation } from 'apollo-augmented-hooks';

const mutation = `
    mutation deleteScene($id: ID!) {
        deleteScene(id: $id) {
            id
        }
    }
`;

export default () => {
    const [mutate] = useMutation(mutation);

    return (input) => (
        mutate({
            input,
            optimisticResponse: {
                __typename: 'Scene',
                id: input,
            },
            modifiers: [
                {
                    cacheObject: (item) => item,
                    evict: true,
                },
                {
                    cacheObject: (item) => item.project,
                    fields: {
                        scenes: ({ includeIf }) => includeIf(false),
                        startScene: ({ setIf, item, cacheObject }) => setIf(
                            item.id === input && cacheObject.scenes.length === 1, null
                        ),
                    },
                },
            ],
        })
    );
};
