import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useTranslate from 'hooks/useTranslate';
import Checkbox from 'web/atoms/Checkbox';
import TextField from 'web/atoms/TextField';
import FormDialog from 'web/molecules/FormDialog';
import InnerHTML from 'InnerHTML';

const TrackingDialog = (props) => {
    const [state, setState] = useState({
        checkbox: false,
        trackingText: '',
    });
    const t = useTranslate();

    const validators = [{
        name: 'checkbox',
        message: () => t('web.tracking.error.checkbox'),
        isValid: (value) => props.checkboxMode !== 'REQUIRED' || !!value,
    }, {
        name: 'trackingText',
        message: () => t('web.tracking.error.textfield'),
        isValid: (value) => props.textFieldMode !== 'REQUIRED' || !!value.trim(),
    }];

    const confirm = async (values) => {
        props.onConfirmTracking(
            props.checkboxMode === 'DISABLED' ? null : values.checkbox,
            props.textFieldMode === 'DISABLED' ? null : values.trackingText,
        );
    };

    const renderRationale = (rationale) => (
        <div className="web-tracking-dialog__rationale">
            <InnerHTML>{rationale}</InnerHTML>
        </div>
    );

    const renderCheckbox = ({ errors, onChangeByValue }) => (
        <Checkbox
            checked={state.checkbox}
            name="checkbox"
            onChange={onChangeByValue('checkbox')}
            error={errors.checkbox}
        >
            <InnerHTML>{t(props.checkboxDescription)}</InnerHTML>
        </Checkbox>
    );

    const renderTextfield = ({ errors, onChangeByEvent }) => (
        <TextField
            label={t(props.textFieldDescription)}
            value={state.trackingText}
            name="trackingText"
            onChange={onChangeByEvent}
            error={errors.trackingText}
        />
    );

    const renderContent = (dialogParams) => {
        const rationale = t(props.rationale);
        const showTextField = props.textFieldMode !== 'DISABLED';
        const showCheckbox = props.checkboxMode !== 'DISABLED';
        return (
            <div className="web-tracking-dialog">
                {rationale && renderRationale(rationale)}
                {showTextField && renderTextfield(dialogParams)}
                {showCheckbox && renderCheckbox(dialogParams)}
            </div>
        );
    };

    return (
        <FormDialog
            isOpen={props.isOpen}
            title={t('web.tracking.title')}
            values={state}
            onConfirm={confirm}
            onChange={setState}
            validators={validators}
            withSubmitButton
        >
            {renderContent}
        </FormDialog>
    );
};

TrackingDialog.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onConfirmTracking: PropTypes.func.isRequired,
    rationale: PropTypes.object.isRequired,
    checkboxMode: PropTypes.string.isRequired,
    checkboxDescription: PropTypes.object.isRequired,
    textFieldMode: PropTypes.string.isRequired,
    textFieldDescription: PropTypes.object.isRequired,
};

export default TrackingDialog;
