import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import useToggle from 'hooks/useToggle';
import useTranslate from 'hooks/useTranslate';
import { useLocalStorage } from '@rehooks/local-storage';
import mediaUrl from 'helpers/mediaUrl';
import { TRACKING_CLICK_EXTERNAL_LINK } from 'helpers/tracking';
import Image from 'Image';
import Select from 'web/atoms/Select';
import UiHeader from 'web/organisms/Header';
import useTracking from 'hooks/useTracking';
import ExhibitorList from './Header/ExhibitorList';
import Challenge from './Header/Challenge';
import InfoboxDialog from './shared/InfoboxDialog';
import LanguageSelector from './Header/LanguageSelector';

const Header = (props) => {
    const t = useTranslate();
    const [isExhibitorListOpen, openExhibitorList, closeExhibitorList] = useToggle(false);
    const [eventsairContactId] = useLocalStorage('eventsairContactId');
    const [infoboxState, setInfoboxState] = useState({
        isOpen: false,
        title: null,
        content: null,
    });
    const history = useHistory();
    const params = useParams();
    const tracking = useTracking();

    const goToStartScene = () => {
        history.push(`/event/${params.projectPath}`);
    };

    const logout = () => {
        props.deleteUserData();
        goToStartScene();
        window.location.reload();
    };

    const openInfobox = (title, content) => {
        setInfoboxState({
            isOpen: true,
            title,
            content,
        });
    };

    const closeInfobox = () => {
        setInfoboxState({
            ...infoboxState,
            isOpen: false,
        });
    };

    const audioSwitchLabel = (label, isAudioOn) => {
        if (t(label)) {
            return t(label);
        }
        return isAudioOn ? t('web.header.disableAudio') : t('web.header.enableAudio');
    };

    const formatMenuItem = (menuItem) => {
        const result = { label: t(menuItem.label) || '' };

        switch (menuItem.type) {
            case 'exhibitor-list':
                return {
                    ...result,
                    label: t(props.exhibitorListLabel) || t('web.header.exhibitorList.title'),
                    onClick: openExhibitorList,
                };
            case 'challenge':
                return {
                    ...result,
                    label: t(props.challengeProgress?.name) || t('web.header.challenge.title'),
                    dropdownContent: (
                        <Challenge
                            progress={props.challengeProgress}
                            sceneName={props.currentScene.name}
                            onChallengeSubmitted={props.onChallengeSubmitted}
                            canBeSubmitted={props.canChallengeBeSubmitted}
                            me={props.me}
                        />
                    ),
                };
            case 'language':
                return {
                    ...result,
                    label: t('web.header.language'),
                    dropdownContent: <LanguageSelector />,
                };
            case 'logout':
                return {
                    ...result,
                    label: t('web.header.logout'),
                    onClick: logout,
                };
            case 'audio-switch':
                return {
                    ...result,
                    label: audioSwitchLabel(menuItem.label, menuItem.isAudioOn),
                    onClick: menuItem.onClick,
                    active: false,
                };
            default:
                return {
                    ...result,
                    active: (
                        menuItem.data.sceneData
                        && props.currentScene.id === menuItem.data.sceneData.scene.id
                    ),
                    onClick: () => onMenuItemClick(menuItem.data),
                };
        }
    };

    const onMenuItemClick = ({ sceneData, infoboxData, externalData }) => {
        if (sceneData) {
            history.push(`/event/${params.projectPath}/${sceneData.scene.path}`);
        } else if (infoboxData) {
            openInfobox(
                infoboxData.title,
                infoboxData.content,
            );
        } else if (externalData) {
            // TODO: Find a clean way to extract and reuse templates in other places (see Background.jsx).
            /* eslint-disable no-template-curly-in-string */
            const url = t(externalData.url)
                ?.replace('${externalId}', props.me?.externalId)
                ?.replace('${eventsairContactId}', eventsairContactId);
            /* eslint-enable no-template-curly-in-string */
            // TRACKCORE:
            //  event:'clickExternalLink', resource: url, data: {type: 'Navigation', customer: customerName}
            //  HEADER CONTENT: Create in Editor: Projekt --> Navigation --> External Link & Open Scene --> Click on External Link in Header
            // console.log('%cTRACKCORE: clickExternalLink', 'background: #00a8ff; font-weight: bold;', url);
            tracking.trackCoreEvent({
                action: 'clickExternalLink',
                resourceType: 'url',
                resourceName: url,
                data: [{
                    name: 'type',
                    value: 'Navigation',
                }],
            });
            tracking.trackEvent('Navigation', TRACKING_CLICK_EXTERNAL_LINK, url);
            window.open(url, externalData.openInNewTab ? '_blank' : '_self');
        }
    };

    const setSceneVersionPreview = (event) => {
        props.onSceneVersionSelected(event.target.value);
    };

    const renderSceneVersionSelection = (scene) => (
        <Select
            value={props.version.id}
            options={scene.versions.map(({ id, name }) => ({
                value: id,
                label: `Version der Szene: ${name}`,
            }))}
            emptyOption={{
                id: props.currentScene.id,
                label: 'Version der Szene: Hauptszene',
            }}
            onChange={setSceneVersionPreview}
        />
    );

    return (
        <>
            <UiHeader
                infoArea={props.isAdmin && (
                    <div className="web-project-header__admin">
                        Admin-Modus
                    </div>
                )}
                logo={props.logo ? (
                    <Image url={mediaUrl(props.logo, 'thumbnail')} />
                ) : null}
                onLogoClick={goToStartScene}
                items={props.menuItems.map(formatMenuItem)}
                sceneVersionSelection={
                    props.isAdmin
                    && props.currentScene.versions.length > 0
                    && renderSceneVersionSelection(props.currentScene)
                }
                navbarExpanded={props.navbarExpanded}
                toggleNavbarState={props.toggleNavbarState}
                transparent={props.transparent}
            />

            <InfoboxDialog
                isOpen={infoboxState.isOpen}
                onClose={closeInfobox}
                title={t(infoboxState.title)}
                sceneName={props.currentScene.name}
            >
                {t(infoboxState.content)}
            </InfoboxDialog>

            <ExhibitorList
                title={props.exhibitorListLabel}
                isOpen={isExhibitorListOpen}
                onClose={closeExhibitorList}
                exhibitors={props.exhibitors}
            />
        </>
    );
};

Header.defaultProps = {
    logo: null,
    exhibitorListLabel: null,
    me: null,
    challengeProgress: null,
    isAdmin: false,
    onSceneVersionSelected: () => {},
    transparent: false,
};

Header.propTypes = {
    menuItems: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.object,
        data: PropTypes.object,
        type: PropTypes.string,
    })).isRequired,
    logo: PropTypes.object,
    currentScene: PropTypes.object.isRequired,
    version: PropTypes.object.isRequired,
    exhibitors: PropTypes.array.isRequired,
    deleteUserData: PropTypes.func.isRequired,
    isAdmin: PropTypes.bool,
    exhibitorListLabel: PropTypes.object,
    canChallengeBeSubmitted: PropTypes.bool.isRequired,
    onChallengeSubmitted: PropTypes.func.isRequired,
    challengeProgress: PropTypes.object,
    me: PropTypes.object,
    onSceneVersionSelected: PropTypes.func,
    navbarExpanded: PropTypes.bool.isRequired,
    toggleNavbarState: PropTypes.func.isRequired,
    transparent: PropTypes.bool,
};

export default Header;
