var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import MuiAlert from '@material-ui/lab/Alert';
import MuiAlertTitle from '@material-ui/lab/AlertTitle';
var Alert = function (props) { return (React.createElement(MuiAlert, __assign({}, props, { className: "ui-cms-alert" }),
    props.title && React.createElement(MuiAlertTitle, null, props.title),
    props.children)); };
Alert.defaultProps = {
    severity: 'warning',
    variant: 'outlined',
};
export default Alert;
