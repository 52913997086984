import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useTranslate from 'hooks/useTranslate';
import { apiResponseToFormState } from 'helpers/form';
import EditingAdmin from 'EditingAdmin';
import CheckboxGroup from 'cms/atoms/CheckboxGroup';
import TextField from 'cms/atoms/TextField';
import VariableList from 'cms/atoms/VariableList';
import KeyValueTable from 'cms/molecules/KeyValueTable';
import Dialog from 'cms/molecules/Dialog';

const defaultValues = {
    id: undefined,
    name: '',
    tasks: {
        _map: ({ id }) => id,
    },
};

const Trigger = (props) => {
    const t = useTranslate();
    const [state, setState] = useState(apiResponseToFormState(props.trigger, defaultValues));
    const validators = [
        {
            name: 'name',
            message: () => 'Bitte gib einen Namen ein.',
            isValid: (value) => !!value.trim(),
        },
        {
            name: 'name',
            message: () => 'Dieser Name ist bereits vergeben.',
            isValid: (value) => props.triggers
                .filter(({ id }) => id !== state.id)
                .filter(({ name }) => name === value.trim())
                .length === 0,
        },
    ];

    useEffect(() => {
        if (props.isOpen) {
            setState(apiResponseToFormState(props.trigger, defaultValues));
        }
    }, [props.isOpen]);

    const save = async (values) => {
        await props.updateTrigger(values);
        props.onClose();
    };

    const renderName = (errors, onChangeByEvent) => (
        <TextField
            name="name"
            value={state.name}
            disabled={!props.nameEnabled}
            onChange={onChangeByEvent}
            error={errors.name}
        />
    );

    const renderTasks = (onChangeByValue) => (
        <CheckboxGroup
            value={state.tasks}
            onChange={onChangeByValue('tasks')}
            items={
                [...((props.project.challenge && props.project.challenge.tasks) || [])]
                    .sort((a, b) => a.order - b.order)
                    .map(({ id, name }) => ({
                        label: t(name),
                        value: id,
                    }))
            }
        />
    );

    return (
        <Dialog
            title={props.trigger ? 'Trigger bearbeiten' : 'Neuen Trigger erstellen'}
            isOpen={props.isOpen}
            onClose={props.onClose}
            onConfirm={save}
            onChange={setState}
            values={state}
            validators={validators}
        >
            {({ errors, onChangeByEvent, onChangeByValue }) => (
                <>
                    {props.trigger && (
                        <EditingAdmin name={`project-challenge-trigger-${props.trigger.id}`} />
                    )}

                    <KeyValueTable
                        items={[{
                            key: 'Name',
                            value: renderName(errors, onChangeByEvent),
                            help: props.nameEnabled ? (
                                <VariableList
                                    items={[{
                                        variable: `trigger('${state.name || 'name'}')`,
                                        description: 'Löst den Trigger aus.',
                                    }]}
                                />
                            ) : false,
                        }, {
                            key: 'Aufgaben für Rally',
                            value: renderTasks(onChangeByValue),
                            align: 'top',
                        }]}
                    />
                </>
            )}
        </Dialog>
    );
};

Trigger.defaultProps = {
    trigger: null,
    nameEnabled: true,
};

Trigger.propTypes = {
    updateTrigger: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    project: PropTypes.object.isRequired,
    triggers: PropTypes.array.isRequired,
    trigger: PropTypes.object,
    nameEnabled: PropTypes.bool,
};

export default Trigger;
