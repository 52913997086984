import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import MeContext from 'contexts/me';
import useToggle from 'hooks/useToggle';
import useTranslate from 'hooks/useTranslate';
import Button from 'cms/atoms/Button';
import Headline from 'cms/atoms/Headline';
import NavigationEditor from '../editors/Project/Navigation';

const Navigation = (props) => {
    const t = useTranslate();
    const [isNavigationEditorOpen, openNavigationEditor, closeNavigationEditor] = useToggle(false);
    const me = useContext(MeContext);
    const menuItems = [
        ...[...props.project.menuItems]
            .sort((a, b) => a.order - b.order)
            .map((menuItem) => ({
                label: t(menuItem.label),
                available: true,
            })),
        {
            label: t(props.project.exhibitorListLabel) || 'Ausstellerverzeichnis',
            available: props.project.exhibitorListEnabled
                && props.scenes.filter(({ type }) => type === 'EXHIBITOR').length > 0,
            systemItem: true,
        },
        {
            label: (!!props.project.challenge && t(props.project.challenge.name)) || 'Rally',
            available: !!props.project.challenge && props.project.challenge.active,
            systemItem: true,
        },
        {
            label: 'Logout',
            available: props.loginType !== 'NONE',
            systemItem: true,
        },
    ];

    return (
        <>
            <Headline
                action={me.hasWriteAccessToFeature('project.navigation') ? (
                    <Button
                        icon="edit"
                        onClick={openNavigationEditor}
                    >
                        Bearbeiten
                    </Button>
                ) : null}
            >
                Navigation
            </Headline>

            <div className="cms-project-navigation">
                {menuItems.map((menuItem) => menuItem.available && (
                    <div
                        key={menuItem.label}
                        className={classNames(
                            'cms-project-navigation__item',
                            { 'cms-project-navigation__item--system': menuItem.systemItem }
                        )}
                    >
                        {menuItem.label}
                    </div>
                ))}
            </div>

            <NavigationEditor
                isOpen={isNavigationEditorOpen}
                onClose={closeNavigationEditor}
                project={props.project}
                scenes={props.scenes}
            />
        </>
    );
};

Navigation.propTypes = {
    project: PropTypes.object.isRequired,
    scenes: PropTypes.array.isRequired,
    loginType: PropTypes.string.isRequired,
};

export default Navigation;
