const loginDialogTypes = [
    {
        label: 'Registrierung',
        value: 'REGISTER',
    },
    {
        label: 'Login',
        value: 'LOGIN',
    },
    {
        label: 'Versteckt',
        value: 'HIDDEN',
    },
];

export default loginDialogTypes;

export const findLoginDialogType = (loginDialogType) => (
    loginDialogTypes.find(({ value }) => value === loginDialogType)
);
