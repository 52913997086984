import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';

const CheckboxGroup = (props) => {
    const [isSelectedAll, setIsSelectedAll] = useState(false);

    const onChangeHandler = (item) => () => {
        const newValue = [...props.value];
        const index = newValue.indexOf(item.value);

        if (index >= 0) {
            newValue.splice(index, 1);
        } else {
            newValue.push(item.value);
        }

        setIsSelectedAll(props.items.length === newValue.length);
        props.onChange(newValue);
    };

    const onChangeSelectAllHandler = () => {
        const newValue = isSelectedAll
            ? []
            : props.items.map(({ value }) => value);
        setIsSelectedAll((oldVal) => !oldVal);
        props.onChange(newValue);
    };

    return (
        <FormControl
            fullWidth
            error={!!props.error}
            className="ui-checkbox-group"
        >
            {props.withSelectAll && (
                <FormGroup>
                    <FormControlLabel
                        control={(
                            <Checkbox
                                color="primary"
                                onChange={onChangeSelectAllHandler}
                                checked={isSelectedAll}
                            />
                        )}
                        label="Alle"
                    />
                    <Divider className="ui-checkbox-group__divider" />
                </FormGroup>
            )}
            <FormGroup>
                {props.items.map((item) => (
                    <FormControlLabel
                        key={item.value}
                        control={(
                            <Checkbox
                                color="primary"
                                checked={props.value.includes(item.value)}
                                onChange={onChangeHandler(item)}
                            />
                        )}
                        label={item.label}
                    />
                ))}
            </FormGroup>

            <FormHelperText>{props.error}</FormHelperText>
        </FormControl>
    );
};

CheckboxGroup.defaultProps = {
    error: null,
    withSelectAll: false,
};

CheckboxGroup.propTypes = {
    error: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
    })).isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.arrayOf(PropTypes.string).isRequired,
    withSelectAll: PropTypes.bool,
};

export default CheckboxGroup;
