import React, { useState } from 'react';
import VideoPlayer from 'react-player/lazy';
import PropTypes from 'prop-types';

const BackgroundVideo = (props) => {
    const [ratio, setRatio] = useState(0);

    const calculateRatio = (player) => {
        const internalPlayer = player.getInternalPlayer();

        setRatio(internalPlayer.element.height / internalPlayer.element.width);
    };

    return (
        <VideoPlayer
            url={props.url}
            playing
            muted
            loop
            controls={false}
            width={props.width}
            height={props.width * ratio}
            className="background-video"
            onReady={calculateRatio}
        />
    );
};
BackgroundVideo.defaultProps = {
    width: 0,
};
BackgroundVideo.propTypes = {
    width: PropTypes.number,
    url: PropTypes.string.isRequired,
};
export default BackgroundVideo;
