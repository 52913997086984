import React from 'react';
import PropTypes from 'prop-types';
import useTranslate from 'hooks/useTranslate';
import InnerHTML from 'InnerHTML';
import Dialog from 'web/molecules/Dialog';

const ReferrerError = (props) => {
    const t = useTranslate();
    const { scene, project } = props;

    const errorTranslation = t(scene?.referrerConstraintErrorMessage)
        ? scene?.referrerConstraintErrorMessage
        : project?.referrerErrorMessage;

    return (
        <Dialog
            isOpen={props.isOpen}
            title={t('web.referrer.error.title')}
            onClose={props.onClose}
        >
            <InnerHTML>{t(errorTranslation) || t('web.referrer.error.message')}</InnerHTML>
        </Dialog>
    );
};

ReferrerError.defaultProps = {
    isOpen: false,
    onClose: null,
};

ReferrerError.propTypes = {
    isOpen: PropTypes.bool,
    scene: PropTypes.object.isRequired,
    project: PropTypes.object.isRequired,
    onClose: PropTypes.func,
};

export default ReferrerError;
