import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import useToggle from 'hooks/useToggle';
import useTranslate from 'hooks/useTranslate';
import useSceneEditor from 'hooks/graphql/queries/sceneEditor';
import useCreateScene from 'hooks/graphql/mutations/createScene';
import useUpdateScene from 'hooks/graphql/mutations/updateScene';
import exposedVariables from 'helpers/exposedVariables';
import { formFieldTypes, defaultFormFieldType, defaultFormFieldFormState, isFormEmpty } from 'helpers/formFields';
import FontRatioContext from 'contexts/fontRatio';
import MeContext from 'contexts/me';
import LocaleContext from 'contexts/locale';
import { findLoginType } from 'helpers/loginTypes';
import { defaultActionFormState } from 'helpers/actions';
import { defaultOverlayFormState } from 'helpers/overlays';
import { defaultSceneObjectFormState, sceneObjectToSaveableJson } from 'helpers/sceneObjects';
import {
    emptyTranslation,
    createUpdateTranslationInput,
    hasOptionalTranslation,
    createUpdateTranslationInputNoIds,
    hasRequiredTranslation,
} from 'helpers/languages';
import mediaUrl from 'helpers/mediaUrl';
import viewModes from 'helpers/viewModes';
import { apiResponseToFormState } from 'helpers/form';
import { buildQuotaError } from 'helpers/scenes';
import { trackingFieldModes } from 'helpers/presenceTracking';
import filterPath from 'helpers/filterPath';
import EditingAdmin from 'EditingAdmin';
import Select from 'cms/atoms/Select';
import Switch from 'cms/atoms/Switch';
import TextField from 'cms/atoms/TextField';
import EditorList from 'cms/molecules/EditorList';
import TranslationField from 'cms/molecules/TranslationField';
import TranslationCodeInput from 'cms/molecules/TranslationCodeInput';
import ImageUpload from 'cms/atoms/ImageUpload';
import CheckboxGroup from 'cms/atoms/CheckboxGroup';
import VariableList from 'cms/atoms/VariableList';
import Grid from 'cms/molecules/Grid';
import FileUpload from 'cms/molecules/FileUpload';
import KeyValueTable from 'cms/molecules/KeyValueTable';
import Confirm from 'components/ui/cms/molecules/Confirm';
import Dialog from 'cms/molecules/Dialog';
import TagInput from 'cms/molecules/TagInput';
import Button from 'cms/atoms/Button';
import LabelList from 'cms/atoms/LabelList';
import FormFieldOptionEditor from 'FormFieldOptionEditor';
import Background from './Scene/Background';
import Panorama from './Scene/Panorama';

const defaultValues = {
    id: undefined,
    name: '',
    type: '',
    path: '',
    widget: { _translation: 'text' },
    contactInstructions: { _translation: 'text' },
    contactTitle: { _translation: 'text' },
    contactEmail: '',
    contactSubject: '',
    panorama: false,
    videoBackground: { _translation: 'text' },
    videoBackgroundEnabled: false,
    audioBackgroundId: '',
    presenceTrackingEnabled: false,
    presenceTrackingRationale: { _translation: 'text' },
    presenceTrackingTextFieldMode: 'DISABLED',
    presenceTrackingTextFieldDescription: { _translation: 'text' },
    presenceTrackingCheckboxMode: 'DISABLED',
    presenceTrackingCheckboxDescription: { _translation: 'text' },
    defaultViewMode: 'FLAT',
    referrerConstraint: {
        _default: [],
        _modify: (value) => (value === '' ? [] : value.split(',')),
    },
    referrerConstraintOverridesProjectConstraints: false,
    referrerConstraintErrorMessage: { _translation: 'text' },
    showViewModeToggle: true,
    sponsor: {
        _default: false,
        _key: 'sponsorType',
        _modify: (sponsorType) => sponsorType === 'STANDARD',
    },
    background: {
        _default: {},
        _modify: () => ({
            id: undefined,
            url: '',
        }),
    },
    panoramaStartSceneId: {
        _default: '',
        _key: 'panoramaStartScene',
        _modify: ({ id }) => id,
    },
    actions: {
        _map: defaultActionFormState,
    },
    objects: {
        _map: defaultSceneObjectFormState,
    },
    overlays: {
        _sort: (a, b) => a.width * a.height - b.width * b.height,
        _map: defaultOverlayFormState,
    },
    tags: {
        _map: ({ name }) => name,
    },
    tasks: {
        _map: ({ id }) => id,
    },
    files: {
        _map: ({ file }) => file,
    },
    restrictions: {
        _map: ({ id }) => id,
    },
    quotaId: {
        _default: '',
        _key: 'quota',
        _modify: ({ id }) => id,
    },
    callingCardForm: {
        id: undefined,
        fields: {
            _sort: (a, b) => a.order - b.order,
            _map: defaultFormFieldFormState,
        },
    },
};

/** This is the main scene editor */
const Scene = (props) => {
    const t = useTranslate();

    const { data, loading } = useSceneEditor(props.id);
    const { scene } = !loading && data;

    const createScene = useCreateScene();
    const updateScene = useUpdateScene();

    const [state, setState] = useState(apiResponseToFormState(scene, defaultValues));
    const [isRemoveFileErrorOpen, openRemoveFileError, closeRemoveFileError] = useToggle(false);
    const [imageSize, setImageSize] = useState(0);
    const [isContactEmailActive, setIsContactEmailActive] = useState(!!state.contactEmail);
    const [formFieldOptionEditorState, setFormFieldOptionEditorState] = useState({
        isOpen: false,
        index: null,
    });

    const me = useContext(MeContext);
    const localeContext = useContext(LocaleContext);
    const trackingWillBeUsed = props.projectPresenceTrackingEnabled && state.presenceTrackingEnabled;
    const quotas = props.project.customer.quotas;

    const isExistingScene = !props.isDuplicatedScene && !!props.id;

    const validators = [{
        name: 'name',
        message: () => 'Bitte gib einen Namen ein.',
        isValid: (value) => !!value.trim(),
    }, {
        name: 'path',
        message: () => 'Bitte gib einen Pfad ein.',
        isValid: (value) => !!filterPath(value).trim(),
    }, {
        name: 'type',
        message: () => 'Bitte wähle eine Art aus.',
        isValid: (value) => !!value,
    }, {
        name: 'widget',
        message: () => 'Bitte gib ebenfalls einen Inhalt für die Standard-Sprache ein.',
        isValid: (value) => hasOptionalTranslation(value, localeContext.defaultLanguage.id),
    }, {
        name: 'path',
        message: () => 'Es gibt in diesem Projekt bereits eine Szene mit diesem Pfad.',
        serverError: 'SCENE_HAS_DUPLICATE_PATH',
    }, {
        name: 'background',
        message: () => 'Bitte lade ein Hintergrundbild hoch.',
        isValid: (value) => !!value.url,
    }, {
        name: 'background',
        message: (_, error) => buildQuotaError(t, error.subjects),
        serverError: 'QUOTA_EXCEEDED',
    }, {
        name: 'contactEmail',
        message: () => 'Bitte gib eine E-Mail-Adresse ein oder schalte den Visitenkarten-Briefkasten ab.',
        isValid: (value) => !isContactEmailActive || !!value.trim(),
    }, {
        name: 'contactInstructions',
        message: () => 'Bitte gib ebenfalls eine Anleitung für die Standard-Sprache ein.',
        isValid: (value) => !isContactEmailActive || hasOptionalTranslation(value, localeContext.defaultLanguage.id),
    }, {
        name: 'contactTitle',
        message: () => 'Bitte gib ebenfalls einen Titel für die Standard-Sprache ein.',
        isValid: (value) => !isContactEmailActive || hasOptionalTranslation(value, localeContext.defaultLanguage.id),
    }, {
        name: 'videoBackground',
        message: () => 'Bitte gib einen Vimeo-Link für die Standard-Sprache ein.',
        isValid: (value) => !state.videoBackgroundEnabled || hasRequiredTranslation(value, localeContext.defaultLanguage.id),
    }, {
        name: 'videoBackground',
        message: () => 'Bitte verwende nur gültige Vimeo-Links.',
        isValid: (value) => !state.videoBackgroundEnabled || value?.entries?.every((entry) => (
            !entry.text.trim() || entry.text.includes('vimeo.com')
        )),
    }, {
        name: 'presenceTrackingRationale',
        message: () => 'Bitte gib ebenfalls eine Text für die Standard-Sprache ein.',
        isValid: (value) => (
            !trackingWillBeUsed
            || (state.presenceTrackingTextFieldMode === 'DISABLED' && state.presenceTrackingCheckboxMode === 'DISABLED')
            || hasOptionalTranslation(value, localeContext.defaultLanguage.id)
        ),
    }, {
        name: 'presenceTrackingTextFieldDescription',
        message: () => 'Bitte gib einen Text für die Standard-Sprache ein.',
        isValid: (value) => (
            !trackingWillBeUsed
            || state.presenceTrackingTextFieldMode === 'DISABLED'
            || hasRequiredTranslation(value, localeContext.defaultLanguage.id)
        ),
    }, {
        name: 'presenceTrackingCheckboxDescription',
        message: () => 'Bitte gib einen Text für die Standard-Sprache ein.',
        isValid: (value) => (
            !trackingWillBeUsed
            || state.presenceTrackingCheckboxMode === 'DISABLED'
            || hasRequiredTranslation(value, localeContext.defaultLanguage.id)
        ),
    },
    ...state.referrerConstraint.reduce((result, current, index) => [
        ...result, {
            name: `referrerConstraint.${index}`,
            message: () => 'Bitte gib eine Domain ein.',
            isValid: (value) => !!value.trim(),
        },
    ], []),
    ...state.callingCardForm.fields.reduce((result, field, index) => [
        ...result,
        {
            name: `callingCardForm.fields.${index}.label`,
            message: () => 'Bitte gib eine Beschriftung für die Standard-Sprache ein.',
            isValid: (value) => hasRequiredTranslation(value, localeContext.defaultLanguage.id),
        },
        {
            name: `callingCardForm.fields.${index}.description`,
            message: () => 'Bitte gib ebenfalls eine Beschreibung für die Standard-Sprache ein.',
            isValid: (value) => hasOptionalTranslation(value, localeContext.defaultLanguage.id),
        },
    ], []),
    {
        name: 'referrerConstraintErrorMessage',
        message: () => 'Bitte gib ebenfalls einen Inhalt für die Standard-Sprache ein.',
        isValid: (value) => hasOptionalTranslation(value, localeContext.defaultLanguage.id),
    },
    ];

    useEffect(() => {
        if (props.isOpen) {
            setState(apiResponseToFormState(scene, defaultValues));
        } else {
            // Reset image size on close so its recalculation on the next image load triggers a
            // re-render and updates the container ref
            setImageSize(0);
        }
    }, [props.isOpen]);

    const storeImageSize = (event) => {
        setImageSize(event.target.width);
    };

    const generatePath = () => {
        if (!state.path.trim() && me.hasWriteAccessToFeature('scene.path')) {
            setState({
                ...state,
                path: filterPath(state.name),
            });
        }
    };

    const changeBackground = (file) => {
        setState((previous) => ({
            ...previous,
            background: {
                ...previous.background,
                ...file,
            },
        }));
    };

    const changeBackgroundItems = (type, index, action) => {
        setState((previous) => ({
            ...previous,
            [type]: index === null ? [
                ...previous[type],
                action,
            ] : [
                ...previous[type].slice(0, index),
                {
                    ...previous[type][index],
                    ...action,
                },
                ...previous[type].slice(index + 1),
            ],
        }));
    };

    const deleteBackgroundItem = (type, index) => {
        setState((previous) => ({
            ...previous,
            [type]: [
                ...previous[type].slice(0, index),
                ...previous[type].slice(index + 1),
            ],
        }));
    };

    const changeFile = (newData) => {
        setState((previous) => {
            const index = previous.files.findIndex(({ key }) => key === newData.key);
            const files = index >= 0 ? [
                ...previous.files.slice(0, index),
                {
                    ...previous.files[index],
                    ...newData,
                },
                ...previous.files.slice(index + 1),
            ] : [
                ...previous.files,
                newData,
            ];

            return { ...previous, files };
        });
    };

    const isInUseByAction = (file) => (
        state.actions
            .filter(({ downloadData }) => downloadData)
            .map(({ downloadData }) => downloadData.downloads)
            .reduce((acc, current) => [...acc, ...current], []) // flatten
            .some((download) => (
                download.thumbnailMediaId === file.id
                || download.file.mediaEntries.some(({ mediaId }) => file.id === mediaId)
            ))
        || state.actions
            .filter(({ galleryData }) => galleryData)
            .map(({ galleryData }) => galleryData.images)
            .reduce((acc, current) => [...acc, ...current], []) // flatten
            .map((image) => image.file.mediaEntries)
            .reduce((acc, current) => [...acc, ...current], []) // flatten
            .some(({ mediaId }) => file.id === mediaId)
        || state.overlays
            .flatMap((overlay) => overlay.imageData?.file?.mediaEntries)
            .some((entry) => file.id === entry?.mediaId)
        || state.objects
            .flatMap((object) => object.imageData?.file?.mediaEntries)
            .some((entry) => file.id === entry?.mediaId)
    );

    const removeFile = (index) => {
        const files = [...state.files].sort((a, b) => a.filename.localeCompare(b.filename));
        if (isInUseByAction(files[index])) {
            openRemoveFileError();
            return;
        }
        setState({
            ...state,
            files: [
                ...files.slice(0, index),
                ...files.slice(index + 1),
            ],
        });
    };

    const toggleContactEmail = () => {
        setIsContactEmailActive(!isContactEmailActive);
    };

    const addReferrerConstraint = () => {
        setState((previous) => ({
            ...previous,
            referrerConstraint: [
                '',
                ...previous.referrerConstraint,
            ],
        }));
    };

    const removeReferrerConstraint = (index) => {
        setState((previous) => ({
            ...previous,
            referrerConstraint: [
                ...previous.referrerConstraint.slice(0, index),
                ...previous.referrerConstraint.slice(index + 1),
            ],
        }));
    };

    const createUpdateTranslationSceneInput = (translation) => (
        isExistingScene
            ? createUpdateTranslationInput(translation)
            : createUpdateTranslationInputNoIds(translation)
    );

    const getIdIfNeeded = (id) => (!props.isDuplicatedScene && id) || undefined;

    const createUpdateFormFieldInput = (field, fieldIndex) => ({
        id: getIdIfNeeded(field.id),
        label: createUpdateTranslationSceneInput(field.label),
        description: createUpdateTranslationSceneInput(field.description),
        mandatory: field.mandatory,
        type: field.type,
        options: (field.options || []).map((option, optionIndex) => ({
            id: getIdIfNeeded(option.id),
            label: createUpdateTranslationSceneInput(option.label),
            value: option.value.trim(),
            order: optionIndex,
        })),
        order: fieldIndex,
    });

    const save = async (values) => {
        const mutation = isExistingScene ? updateScene : createScene;

        const input = {
            id: isExistingScene ? values.id : undefined,
            projectId: !isExistingScene ? props.projectId : undefined,
            name: values.name.trim(),
            type: values.type,
            path: values.path.trim(),
            panorama: values.panorama,
            widget: createUpdateTranslationSceneInput(values.widget),
            backgroundMediaId: values.background.id,
            quotaId: values.quotaId || null,
            files: values.files.map(({ id }) => ({ id })),
            restrictions: values.restrictions.map((id) => ({ id })),
            sponsorType: values.sponsor ? 'STANDARD' : 'NONE',
            referrerConstraint: values.referrerConstraint
                .map((constraint) => constraint.trim())
                .join(','),
            referrerConstraintOverridesProjectConstraints: values.referrerConstraintOverridesProjectConstraints,
            referrerConstraintErrorMessage: createUpdateTranslationSceneInput(values.referrerConstraintErrorMessage),
            contactInstructions: isContactEmailActive
                ? createUpdateTranslationSceneInput(values.contactInstructions)
                : emptyTranslation,
            contactTitle: isContactEmailActive
                ? createUpdateTranslationSceneInput(values.contactTitle)
                : emptyTranslation,
            contactEmail: isContactEmailActive ? values.contactEmail.trim() : null,
            contactSubject: isContactEmailActive ? values.contactSubject.trim() : null,
            callingCardForm: isFormEmpty(values.callingCardForm) ? null : {
                id: getIdIfNeeded(values.callingCardForm.id),
                fields: values.callingCardForm.fields.map(createUpdateFormFieldInput),
            },
            tags: values.tags.map((tag) => ({
                id: (props.tags.find(({ name }) => name === tag) || {}).id,
                projectId: isExistingScene ? scene.project.id : props.projectId,
                name: tag,
            })),
            audioBackgroundId: values.audioBackgroundId || null,
            tasks: values.tasks.map((id) => ({ id })),
            actions: values.actions.map((action) => ({
                id: getIdIfNeeded(action.id),
                name: createUpdateTranslationSceneInput(action.name),
                active: action.active,
                mobile: action.mobile,
                x: action.x,
                y: action.y,
                position: action.position ? {
                    ...action.position,
                    id: getIdIfNeeded(action.position.id),
                } : undefined,
                rotation: action.rotation ? {
                    ...action.rotation,
                    id: getIdIfNeeded(action.rotation.id),
                } : undefined,
                scale: action.scale ? {
                    ...action.scale,
                    id: getIdIfNeeded(action.scale.id),
                } : undefined,
                withLabel: action.withLabel,
                buttonId: action.buttonId,
                restrictions: action.restrictions.map((id) => ({ id })),
                tasks: action.tasks.map((id) => ({ id })),
                sceneData: action.type === 'SCENE' ? {
                    id: getIdIfNeeded(action.sceneData.id),
                    sceneId: action.sceneData.sceneId,
                } : null,
                exhibitorData: action.type === 'EXHIBITOR' ? {
                    id: getIdIfNeeded(action.exhibitorData.id),
                    sceneId: action.exhibitorData.sceneId,
                } : null,
                infoboxData: action.type === 'INFOBOX' ? {
                    id: getIdIfNeeded(action.infoboxData.id),
                    title: createUpdateTranslationSceneInput(action.infoboxData.title),
                    content: createUpdateTranslationSceneInput(action.infoboxData.content),
                } : null,
                contactData: action.type === 'CONTACT' ? {
                    id: getIdIfNeeded(action.contactData.id),
                    title: createUpdateTranslationSceneInput(action.contactData.title),
                    sections: action.contactData.sections.map((section, order) => ({
                        id: getIdIfNeeded(section.id),
                        image: createUpdateTranslationSceneInput(section.image),
                        entries: section.entries.map((entry, entryOrder) => ({
                            id: getIdIfNeeded(entry.id),
                            type: entry.type,
                            value: createUpdateTranslationSceneInput(entry.value),
                            order: entryOrder,
                        })),
                        order,
                    })),
                } : null,
                galleryData: action.type === 'GALLERY' ? {
                    id: getIdIfNeeded(action.galleryData.id),
                    title: createUpdateTranslationSceneInput(action.galleryData.title),
                    images: action.galleryData.images.map((image, order) => ({
                        id: getIdIfNeeded(image.id),
                        file: createUpdateTranslationSceneInput(image.file),
                        order,
                        description: createUpdateTranslationSceneInput(image.description),
                    })),
                } : null,
                downloadData: action.type === 'DOWNLOAD' ? {
                    id: getIdIfNeeded(action.downloadData.id),
                    title: createUpdateTranslationSceneInput(action.downloadData.title),
                    downloads: action.downloadData.downloads.map((download, order) => ({
                        id: getIdIfNeeded(download.id),
                        file: createUpdateTranslationSceneInput(download.file),
                        thumbnailMediaId: download.thumbnailMediaId || undefined,
                        order,
                        label: createUpdateTranslationSceneInput(download.label),
                        description: createUpdateTranslationSceneInput(download.description),
                    })),
                } : null,
                videoData: action.type === 'VIDEO' ? {
                    id: getIdIfNeeded(action.videoData.id),
                    title: createUpdateTranslationSceneInput(action.videoData.title),
                    items: action.videoData.items.map((item, order) => ({
                        id: getIdIfNeeded(item.id),
                        order,
                        label: createUpdateTranslationSceneInput(item.label),
                        url: createUpdateTranslationSceneInput(item.url),
                    })),
                } : null,
                audioData: action.type === 'AUDIO' ? {
                    id: getIdIfNeeded(action.audioData.id),
                    file: createUpdateTranslationSceneInput(action.audioData.file),
                    stopiconMediaId: action.audioData.stopIconMediaId,
                } : null,
                externalData: action.type === 'EXTERNAL' ? {
                    id: getIdIfNeeded(action.externalData.id),
                    url: createUpdateTranslationSceneInput(action.externalData.url),
                    openInNewTab: action.externalData.openInNewTab,
                } : null,
                loginData: action.type === 'LOGIN' ? {
                    id: getIdIfNeeded(action.loginData.id),
                    hideWhenLoggedIn: action.loginData.hideWhenLoggedIn,
                } : null,
                callingCardData: action.type === 'CALLING_CARD' ? {
                    id: getIdIfNeeded(action.callingCardData.id),
                    contactEmail: action.callingCardData.contactEmail,
                    contactSubject: action.callingCardData.contactSubject,
                    contactInstructions: createUpdateTranslationSceneInput(action.callingCardData.contactInstructions),
                    contactTitle: createUpdateTranslationSceneInput(action.callingCardData.contactTitle),
                    form: isFormEmpty(action.callingCardData.form) ? null : {
                        id: getIdIfNeeded(action.callingCardData.form.id),
                        fields: action.callingCardData.form.fields.map(createUpdateFormFieldInput),
                    },
                } : null,
            })),
            objects: values.objects.map((sceneObject) => sceneObjectToSaveableJson(sceneObject, props.isDuplicatedScene)),
            overlays: values.overlays.map((overlay) => ({
                id: getIdIfNeeded(overlay.id),
                name: overlay.name,
                mobile: overlay.mobile,
                x: overlay.x,
                y: overlay.y,
                width: overlay.width,
                height: overlay.height,
                imageData: overlay.type === 'IMAGE' ? {
                    id: getIdIfNeeded(overlay.imageData.id),
                    file: createUpdateTranslationSceneInput(overlay.imageData.file),
                } : null,
                videoData: overlay.type === 'VIDEO' ? {
                    id: getIdIfNeeded(overlay.videoData.id),
                    url: createUpdateTranslationSceneInput(overlay.videoData.url),
                    autoplay: overlay.videoData.autoplay,
                    loop: overlay.videoData.loop,
                    mute: overlay.videoData.mute,
                    controls: overlay.videoData.controls,
                } : null,
                newstickerData: overlay.type === 'NEWSTICKER' ? {
                    id: getIdIfNeeded(overlay.newstickerData.id),
                    text: createUpdateTranslationSceneInput(overlay.newstickerData.text),
                } : null,
                htmlData: overlay.type === 'HTML' ? {
                    id: getIdIfNeeded(overlay.htmlData.id),
                    html: createUpdateTranslationSceneInput(overlay.htmlData.html),
                } : null,
            })),
            // TODO: Duplicate panorama scene and link to it here
            panoramaStartSceneId: (!props.isDuplicatedScene && props.id) ? (values.panoramaStartSceneId || null) : undefined,
            defaultViewMode: values.defaultViewMode,
            showViewModeToggle: values.showViewModeToggle,
            videoBackground: createUpdateTranslationSceneInput(values.videoBackground),
            videoBackgroundEnabled: values.videoBackgroundEnabled,
            presenceTrackingEnabled: values.presenceTrackingEnabled,
            presenceTrackingRationale: createUpdateTranslationSceneInput(values.presenceTrackingRationale),
            presenceTrackingTextFieldMode: values.presenceTrackingTextFieldMode,
            presenceTrackingTextFieldDescription: createUpdateTranslationSceneInput(values.presenceTrackingTextFieldDescription),
            presenceTrackingCheckboxMode: values.presenceTrackingCheckboxMode,
            presenceTrackingCheckboxDescription: createUpdateTranslationSceneInput(values.presenceTrackingCheckboxDescription),
        };

        await mutation(input);
        props.onClose();
    };

    const renderTrackingItemRationale = (onChangeByEvent, onChangeByValue, errors) => (
        <TranslationField
            key="presenceTrackingRationale"
            name="presenceTrackingRationale"
            label="Begründung"
            value={state.presenceTrackingRationale}
            onChange={onChangeByValue('presenceTrackingRationale')}
            error={errors.presenceTrackingRationale}
            disabled={state.presenceTrackingTextFieldMode === 'DISABLED' && state.presenceTrackingCheckboxMode === 'DISABLED'}
        />
    );

    const renderTrackingItemTextField = (onChangeByEvent, onChangeByValue, errors) => (
        <React.Fragment key="presenceTrackingTextField">
            <Select
                name="presenceTrackingTextFieldMode"
                label="Texteingabefeld"
                value={state.presenceTrackingTextFieldMode}
                onChange={onChangeByEvent}
                items={trackingFieldModes}
            />
            <TranslationField
                name="presenceTrackingTextFieldDescription"
                label="Beschreibung"
                value={state.presenceTrackingTextFieldDescription}
                onChange={onChangeByValue('presenceTrackingTextFieldDescription')}
                error={errors.presenceTrackingTextFieldDescription}
                disabled={state.presenceTrackingTextFieldMode === 'DISABLED'}
            />
        </React.Fragment>
    );

    const renderTrackingItemCheckbox = (onChangeByEvent, onChangeByValue, errors) => (
        <React.Fragment key="presenceTrackingCheckbox">
            <Select
                name="presenceTrackingCheckboxMode"
                label="Bestätigungskästchen"
                value={state.presenceTrackingCheckboxMode}
                onChange={onChangeByEvent}
                items={trackingFieldModes}
            />
            <TranslationField
                name="presenceTrackingCheckboxDescription"
                label="Beschreibung"
                value={state.presenceTrackingCheckboxDescription}
                onChange={onChangeByValue('presenceTrackingCheckboxDescription')}
                error={errors.presenceTrackingCheckboxDescription}
                disabled={state.presenceTrackingCheckboxMode === 'DISABLED'}
            />
        </React.Fragment>
    );

    const addFormField = () => {
        setState((previous) => ({
            ...previous,
            callingCardForm: {
                id: previous.callingCardForm.id,
                fields: [
                    ...previous.callingCardForm.fields,
                    {
                        label: emptyTranslation,
                        mandatory: false,
                        type: defaultFormFieldType,
                        description: emptyTranslation,
                        options: [],
                    },
                ],
            },
        }));
    };

    const removeFormField = (index) => {
        setState((previous) => ({
            ...previous,
            callingCardForm: {
                id: previous.callingCardForm.id,
                fields: [
                    ...previous.callingCardForm.fields.slice(0, index),
                    ...previous.callingCardForm.fields.slice(index + 1),
                ],
            },
        }));
    };

    const changeFormFieldOrder = (newIndex, oldIndex) => {
        const fields = [...state.callingCardForm.fields];
        const movedField = fields[oldIndex];

        fields.splice(oldIndex, 1);
        fields.splice(newIndex, 0, movedField);

        setState({ ...state, callingCardForm: { id: state.callingCardForm.id, fields } });
    };

    const handleOpenFormFieldOptionEditor = (index) => () => {
        setFormFieldOptionEditorState({ isOpen: true, index });
    };

    const handleCloseFormFieldOptionEditor = () => {
        setFormFieldOptionEditorState({ ...formFieldOptionEditorState, isOpen: false });
    };

    const handleSaveFormFieldOptionEditor = (options) => {
        setState((previous) => {
            const formFields = previous.callingCardForm.fields;
            return {
                ...previous,
                callingCardForm: {
                    id: previous.callingCardForm.id,
                    fields: [
                        ...previous.callingCardForm.fields.slice(0, formFieldOptionEditorState.index),
                        {
                            ...formFields[formFieldOptionEditorState.index],
                            options,
                        },
                        ...previous.callingCardForm.fields.slice(formFieldOptionEditorState.index + 1),
                    ],
                },
            };
        });
    };

    const renderFormFields = (errors, onChangeByEvent, onChangeByValue) => (
        <>
            <EditorList
                addLabel="Formularfeld hinzufügen"
                onAdd={addFormField}
                removeLabel="Formularfeld löschen"
                onRemove={removeFormField}
                onSort={changeFormFieldOrder}
                sortModeContents={state.callingCardForm.fields.map((field) => t(field.label) || 'Keine Bezeichnung')}
            >
                {state.callingCardForm.fields.map((field, index) => (
                    <Grid key={index}>
                        <Grid item size={12}>
                            <TranslationField
                                label="Beschriftung"
                                name={`callingCardForm.fields.${index}.label`}
                                value={field.label}
                                onChange={onChangeByValue(`callingCardForm.fields.${index}.label`)}
                                error={errors[`callingCardForm.fields.${index}.label`]}
                            />
                        </Grid>
                        <Grid item size={12}>
                            <Switch
                                label="Erforderlich"
                                name={`callingCardForm.fields.${index}.mandatory`}
                                checked={field.mandatory}
                                onChange={onChangeByEvent}
                            />
                        </Grid>
                        <Grid item size={12}>
                            <Select
                                name={`callingCardForm.fields.${index}.type`}
                                label="Typ"
                                value={field.type}
                                onChange={onChangeByEvent}
                                items={formFieldTypes}
                            />
                        </Grid>

                        {field.type === 'CHECKBOX' && (
                            <Grid item size={12}>
                                <TranslationCodeInput
                                    label="Beschreibung"
                                    value={field.description}
                                    onChange={onChangeByValue(`callingCardForm.fields.${index}.description`)}
                                    error={errors[`callingCardForm.fields.${index}.description`]}
                                />
                            </Grid>
                        )}

                        {field.type === 'DROPDOWN' && (
                            <Grid item size={12}>
                                <Button
                                    icon="edit"
                                    onClick={handleOpenFormFieldOptionEditor(index)}
                                >
                                    Werte Bearbeiten
                                </Button>
                                <LabelList
                                    labels={
                                        field.options.map((option) => ({
                                            label: t(option.label),
                                        }))
                                    }
                                />
                            </Grid>
                        )}
                    </Grid>
                ))}
            </EditorList>
            <FormFieldOptionEditor
                isOpen={formFieldOptionEditorState.isOpen}
                onClose={handleCloseFormFieldOptionEditor}
                onSave={handleSaveFormFieldOptionEditor}
                field={
                    state
                        .callingCardForm
                        ?.fields[formFieldOptionEditorState.index]
                }
            />
        </>
    );

    const sceneFiles = state.files.map((file) => ({ file }));
    const showPanoramaChildSceneOptions = (
        !!scene
        && scene.panoramaChildScenes.length > 0
        && !state.panorama
        && !props.isDuplicatedScene
    );

    return (
        <Dialog
            title={isExistingScene ? 'Szene bearbeiten' : 'Neue Szene erstellen'}
            isOpen={props.isOpen}
            onClose={props.onClose}
            onConfirm={save}
            onChange={setState}
            values={state}
            validators={validators}
            minWidth={80}
            preventFirstTextFieldFocus={!!props.id}
        >
            {({ errors, onChangeByEvent, onChangeByValue }) => (
                <>
                    {props.id && (
                        <EditingAdmin name={`scene-${props.id}`} />
                    )}

                    <KeyValueTable
                        items={[
                            {
                                key: 'Art',
                                value: (
                                    <Select
                                        name="type"
                                        value={state.type}
                                        onChange={onChangeByEvent}
                                        items={[
                                            { label: 'Szene', value: 'SCENE' },
                                            { label: 'Aussteller', value: 'EXHIBITOR' },
                                        ]}
                                        error={errors.type}
                                    />
                                ),
                                disabled: !me.hasWriteAccessToFeature('scene.type'),
                            },
                            {
                                key: 'Name',
                                value: (
                                    <TextField
                                        name="name"
                                        value={state.name}
                                        onChange={onChangeByEvent}
                                        onBlur={generatePath}
                                        error={errors.name}
                                    />
                                ),
                                disabled: !me.hasWriteAccessToFeature('scene.name'),
                            },
                            {
                                key: 'Pfad in der URL',
                                value: (
                                    <TextField
                                        name="path"
                                        value={state.path}
                                        onChange={onChangeByEvent}
                                        filter={filterPath}
                                        error={errors.path}
                                    />
                                ),
                                disabled: !me.hasWriteAccessToFeature('scene.path'),
                            },
                            {
                                key: 'Sponsor',
                                value: (
                                    <Switch
                                        name="sponsor"
                                        checked={state.sponsor}
                                        onChange={onChangeByEvent}
                                    />
                                ),
                                available: state.type === 'EXHIBITOR',
                                disabled: !me.hasWriteAccessToFeature('scene.sponsor'),
                            },
                            {
                                key: 'Schlagwörter',
                                value: (
                                    <TagInput
                                        value={state.tags}
                                        onChange={onChangeByValue('tags')}
                                        availableTags={props.tags.map(({ name }) => name)}
                                    />
                                ),
                                available: state.type === 'EXHIBITOR',
                                disabled: !me.hasWriteAccessToFeature('scene.tags'),
                            },
                            {
                                key: 'Visitenkarten-Briefkasten',
                                value: (
                                    <Grid>
                                        <Grid item size={2}>
                                            <Switch
                                                checked={isContactEmailActive}
                                                onChange={toggleContactEmail}
                                            />
                                        </Grid>

                                        <Grid item size={10}>
                                            <TranslationField
                                                name="contactTitle"
                                                label="Dialog-Titel"
                                                value={
                                                    isContactEmailActive
                                                        ? state.contactTitle
                                                        : emptyTranslation
                                                }
                                                onChange={onChangeByValue('contactTitle')}
                                                error={errors.contactTitle}
                                                disabled={!isContactEmailActive}
                                            />
                                            <TextField
                                                name="contactEmail"
                                                label="E-Mail-Adresse"
                                                value={isContactEmailActive ? state.contactEmail : ''}
                                                onChange={onChangeByEvent}
                                                disabled={!isContactEmailActive}
                                                error={errors.contactEmail}
                                            />
                                            <TranslationField
                                                name="contactInstructions"
                                                label="Anleitung"
                                                value={
                                                    isContactEmailActive
                                                        ? state.contactInstructions
                                                        : emptyTranslation
                                                }
                                                onChange={onChangeByValue('contactInstructions')}
                                                error={errors.contactInstructions}
                                                disabled={!isContactEmailActive}
                                            />
                                            <TextField
                                                name="contactSubject"
                                                label="E-Mail-Betreff"
                                                value={isContactEmailActive ? state.contactSubject : ''}
                                                onChange={onChangeByEvent}
                                                disabled={!isContactEmailActive}
                                            />
                                        </Grid>
                                    </Grid>
                                ),
                                available: state.type === 'EXHIBITOR',
                                disabled: !me.hasWriteAccessToFeature('scene.callingCard'),
                                align: 'top',
                            },
                            {
                                key: 'Visitenkarten-Formular',
                                available: isContactEmailActive,
                                disabled: !me.hasWriteAccessToFeature('scene.callingCard'),
                                value: renderFormFields(errors, onChangeByEvent, onChangeByValue),
                                align: 'top',
                            },
                            {
                                key: 'Szenendateien',
                                value: (
                                    <>
                                        <FileUpload
                                            onUpload={changeFile}
                                            onRemove={removeFile}
                                            files={
                                                [...state.files]
                                                    .map(({ filename }) => ({ label: filename }))
                                                    .sort((a, b) => a.label.localeCompare(b.label))
                                            }
                                        />

                                        <Confirm
                                            title="Diese Datei kann nicht gelöscht werden."
                                            onConfirm={closeRemoveFileError}
                                            isOpen={isRemoveFileErrorOpen}
                                        >
                                            Sie wird in dieser Szene noch genutzt.
                                        </Confirm>
                                    </>
                                ),
                                disabled: !me.hasWriteAccessToFeature('scene.files'),
                                align: 'top',
                            },
                            {
                                key: 'Eigener Code',
                                value: (
                                    <TranslationCodeInput
                                        value={state.widget}
                                        onChange={onChangeByValue('widget')}
                                        projectFiles={props.projectFiles}
                                        sceneFiles={sceneFiles}
                                        error={errors.widget}
                                    />
                                ),
                                help: <VariableList items={exposedVariables} />,
                                align: 'top',
                                disabled: !me.hasWriteAccessToFeature('scene.widget'),
                            },
                            {
                                key: 'Zugriffsbeschränkungen',
                                value: (
                                    <CheckboxGroup
                                        value={state.restrictions}
                                        onChange={onChangeByValue('restrictions')}
                                        items={
                                            props.accessGroups
                                                .map(({ id, name }) => ({
                                                    label: name,
                                                    value: id,
                                                }))
                                        }
                                    />
                                ),
                                available: (
                                    props.accessGroups.length > 0
                                    && findLoginType(props.loginType).withAccessGroups
                                ),
                                disabled: !me.hasWriteAccessToFeature('scene.accessGroups'),
                                align: 'top',
                            },
                            {
                                key: 'Referrer-Einschränkung',
                                help: 'Die Szene darf nur von den hier angegebenen Domains aus betreten werden. Betritt man die Szene direkt oder von einer anderen Domain, gibt es eine Fehlermeldung. Sollte hier gar keine Domain angegeben werden, darf die Szene von überall aus besucht werden.',
                                value: (
                                    <>
                                        <Switch
                                            name="referrerConstraintOverridesProjectConstraints"
                                            label="Projektweite Einschränkungen überschreiben?"
                                            checked={state.referrerConstraintOverridesProjectConstraints}
                                            onChange={onChangeByEvent}
                                        />
                                        <EditorList
                                            addLabel="Domain hinzufügen"
                                            onAdd={addReferrerConstraint}
                                            removeLabel="Domain löschen"
                                            onRemove={removeReferrerConstraint}
                                        >
                                            {state.referrerConstraint.map((constraint, index) => (
                                                <TextField
                                                    key={index}
                                                    label="Name"
                                                    name={`referrerConstraint.${index}`}
                                                    value={constraint}
                                                    onChange={onChangeByEvent}
                                                    error={errors[`referrerConstraint.${index}`]}
                                                />
                                            ))}
                                        </EditorList>
                                    </>
                                ),
                                align: 'top',
                                disabled: !me.hasWriteAccessToFeature('scene.referrerConstraint'),
                            },
                            {
                                key: 'Referrer-Fehlermeldung',
                                help: 'Diese Fehlermeldung erscheint, wenn jemand die Szene von einer Domain aus besucht, die nicht Teil der Liste unter der Referrer-Einschränkung ist.',
                                value: (
                                    <TranslationCodeInput
                                        value={state.referrerConstraintErrorMessage}
                                        error={errors.referrerConstraintErrorMessage}
                                        onChange={onChangeByValue('referrerConstraintErrorMessage')}
                                    />
                                ),
                                align: 'top',
                                disabled: !me.hasWriteAccessToFeature('scene.referrerConstraint'),
                            },
                            {
                                key: 'Aufgaben für Rally',
                                value: (
                                    <CheckboxGroup
                                        value={state.tasks}
                                        onChange={onChangeByValue('tasks')}
                                        items={
                                            [...((props.challenge && props.challenge.tasks) || [])]
                                                .sort((a, b) => a.order - b.order)
                                                .map(({ id, name }) => ({
                                                    label: t(name),
                                                    value: id,
                                                }))
                                        }
                                    />
                                ),
                                available: !!props.challenge && props.challenge.tasks.length > 0,
                                disabled: !me.hasWriteAccessToFeature('scene.tasks'),
                                align: 'top',
                            },
                            {
                                key: 'Panorama-Szene',
                                value: (
                                    <Switch
                                        name="panorama"
                                        checked={state.panorama}
                                        onChange={onChangeByEvent}
                                    />
                                ),
                                available: !props.id,
                                disabled: !me.hasWriteAccessToFeature('scene.panorama'),
                            },
                            {
                                key: 'Start-Panorama-Unterszene',
                                value: scene && (
                                    <Select
                                        name="panoramaStartSceneId"
                                        value={state.panoramaStartSceneId}
                                        onChange={onChangeByEvent}
                                        items={
                                            scene.panoramaChildScenes
                                                .map(({ name, id }) => ({
                                                    label: name,
                                                    value: id,
                                                }))
                                        }
                                    />
                                ),
                                available: showPanoramaChildSceneOptions,
                                disabled: !me.hasWriteAccessToFeature('scene.panorama'),
                            },
                            {
                                key: 'Initiale Ansicht',
                                value: scene && (
                                    <Select
                                        name="defaultViewMode"
                                        value={state.defaultViewMode}
                                        onChange={onChangeByEvent}
                                        items={viewModes}
                                    />
                                ),
                                available: showPanoramaChildSceneOptions,
                                disabled: !me.hasWriteAccessToFeature('scene.panorama'),
                            },
                            {
                                key: 'Wechsel der Ansicht erlauben',
                                value: (
                                    <Switch
                                        name="showViewModeToggle"
                                        checked={state.showViewModeToggle}
                                        onChange={onChangeByEvent}
                                    />
                                ),
                                available: showPanoramaChildSceneOptions,
                                disabled: !me.hasWriteAccessToFeature('scene.panorama'),
                            },
                            {
                                key: 'Audiokulisse',
                                value: props.project.audioBackgrounds ? (
                                    <Select
                                        name="audioBackgroundId"
                                        value={state.audioBackgroundId}
                                        onChange={onChangeByEvent}
                                        items={
                                            [
                                                {
                                                    label: 'Ohne',
                                                    value: '',
                                                },
                                                ...props.project.audioBackgrounds
                                                    .map((audioBackground) => ({
                                                        label: audioBackground.media.filename,
                                                        value: audioBackground.id,
                                                    })),
                                            ]
                                        }
                                    />
                                ) : null,
                                available: props.project.audioBackgrounds.length > 0,
                                disabled: !me.hasWriteAccessToFeature('scene.audioBackground'),
                            },
                            {
                                key: 'Hintergrundbild',
                                value: state.panorama ? (
                                    <ImageUpload
                                        onUpload={changeBackground}
                                        url={mediaUrl(state.background, 'mini')}
                                        error={errors.background}
                                        imageDecorator={() => (
                                            <Panorama
                                                background={state.background}
                                                actions={state.actions}
                                                objects={state.objects}
                                                buttons={props.buttons}
                                                projectButtons={props.projectButtons}
                                                scenes={props.scenes}
                                                challenge={props.challenge}
                                                onSave={changeBackgroundItems}
                                                onDelete={deleteBackgroundItem}
                                                projectFiles={props.projectFiles}
                                                sceneFiles={sceneFiles}
                                                isPanoramaChildScene
                                            />
                                        )}
                                    />
                                ) : (
                                    <ImageUpload
                                        onUpload={changeBackground}
                                        url={mediaUrl(state.background, 'standard')}
                                        error={errors.background}
                                        onImageLoad={storeImageSize}
                                        imageDecorator={(image) => (
                                            <FontRatioContext.Provider value={imageSize}>
                                                <Background
                                                    image={image}
                                                    actions={state.actions}
                                                    overlays={state.overlays}
                                                    buttons={props.buttons}
                                                    projectButtons={props.projectButtons}
                                                    scenes={props.scenes}
                                                    onSave={changeBackgroundItems}
                                                    onDelete={deleteBackgroundItem}
                                                    projectFiles={props.projectFiles}
                                                    sceneFiles={sceneFiles}
                                                    withMailbox={isContactEmailActive}
                                                    loginType={props.loginType}
                                                    accessGroups={props.accessGroups}
                                                    challenge={props.challenge}
                                                />
                                            </FontRatioContext.Provider>
                                        )}
                                    />
                                ),
                                align: 'top',
                                disabled: !me.hasWriteAccessToFeature('scene.background'),
                            },
                            {
                                key: 'Videohintergrund',
                                value: (
                                    <Grid>
                                        <Grid item size={2}>
                                            <Switch
                                                name="videoBackgroundEnabled"
                                                checked={state.videoBackgroundEnabled}
                                                onChange={onChangeByEvent}
                                                error={errors.videoBackgroundEnabled}
                                            />
                                        </Grid>

                                        <Grid item size={10}>
                                            <TranslationField
                                                name="videoBackground"
                                                label="Vimeo-Link"
                                                value={state.videoBackground}
                                                onChange={onChangeByValue('videoBackground')}
                                                error={errors.videoBackground}
                                                disabled={!state.videoBackgroundEnabled}
                                            />
                                        </Grid>
                                    </Grid>
                                ),
                                align: 'top',
                                available: me.hasReadAccessToFeature('scene.videoBackground'),
                                disabled: !me.hasWriteAccessToFeature('scene.videoBackground'),
                            },
                            {
                                key: 'Kontingent',
                                value: quotas ? (
                                    <Select
                                        name="quotaId"
                                        value={state.quotaId}
                                        onChange={onChangeByEvent}
                                        items={
                                            [
                                                {
                                                    label: 'Keine Einschränkung',
                                                    value: '',
                                                },
                                                ...quotas
                                                    .map((quota) => ({
                                                        label: quota.name,
                                                        value: quota.id,
                                                    })),
                                            ]
                                        }
                                    />
                                ) : null,
                                available: quotas?.length > 0,
                                disabled: !me.hasWriteAccessToFeature('customer.quotas'),
                            },
                            {
                                key: 'Anwesenheitstracking',
                                value: (
                                    <Switch
                                        name="presenceTrackingEnabled"
                                        checked={state.presenceTrackingEnabled}
                                        onChange={onChangeByEvent}
                                    />
                                ),
                                align: 'top',
                                available: props.projectPresenceTrackingEnabled,
                                disabled: !me.hasWriteAccessToFeature('scene.presenceTracking'),
                            },
                            {
                                key: 'Anwesenheitstracking Abfrage',
                                help: 'Wenn das Bestätigungskästchen oder das Texteingabefeld eingeschaltet sind, wird beim Betreten der Szene ein Dialog angezeigt.',
                                value: (
                                    <EditorList>
                                        {[
                                            renderTrackingItemRationale(onChangeByEvent, onChangeByValue, errors),
                                            renderTrackingItemTextField(onChangeByEvent, onChangeByValue, errors),
                                            renderTrackingItemCheckbox(onChangeByEvent, onChangeByValue, errors),
                                        ]}
                                    </EditorList>
                                ),
                                align: 'top',
                                available: trackingWillBeUsed,
                                disabled: !me.hasWriteAccessToFeature('scene.presenceTracking'),
                            },
                        ]}
                    />
                </>
            )}
        </Dialog>
    );
};

Scene.defaultProps = {
    id: null,
    projectId: null,
    challenge: null,
    projectPresenceTrackingEnabled: false,
    isDuplicatedScene: false,
};

Scene.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    isDuplicatedScene: PropTypes.bool, // TODO: Find a better name
    id: PropTypes.string,
    projectId: PropTypes.string,
    buttons: PropTypes.array.isRequired,
    projectButtons: PropTypes.array.isRequired,
    scenes: PropTypes.array.isRequired,
    projectFiles: PropTypes.array.isRequired,
    project: PropTypes.object.isRequired,
    accessGroups: PropTypes.array.isRequired,
    challenge: PropTypes.object,
    loginType: PropTypes.string.isRequired,
    tags: PropTypes.array.isRequired,
    projectPresenceTrackingEnabled: PropTypes.bool,
};

export default Scene;
