import { useMutation } from 'apollo-augmented-hooks';
import { SCENE } from '../queries/scene';
import { PROJECT } from '../queries/project';

const mutation = `
    mutation createScene($input: CreateSceneInput!) {
        createScene(input: $input) {
            ${SCENE}
            project {
                ${PROJECT}
            }
            parentScene {
                id
            }
            background {
                id
                url
                thumbnail: variation(type: THUMBNAIL) {
                    url
                }
            }
            versions {
                id
                audioBackgroundId
            }
            panoramaChildScenes {
                ${SCENE}
            }
        }
    }
`;

export default () => {
    const [mutate] = useMutation(mutation);

    return (input) => mutate({
        input,
        modifiers: [{
            cacheObject: (item) => item.parentScene || item,
            fields: {
                panoramaChildScenes: ({ includeIf, item }) => includeIf(!!item.parentScene),
            },
        }, {
            cacheObject: (item) => item.project,
            fields: {
                scenes: ({ includeIf, item }) => includeIf(!item.parentScene),
                startScene: ({ setIf, cacheObject }) => setIf(cacheObject.scenes.length === 0),
            },
        },
        ],
    });
};
