import { useQuery } from 'apollo-augmented-hooks';
import { ADMIN, ROLE } from './admins';
export var QUOTA_SUBJECT = "\n    id\n    name\n    value\n    label\n    active\n";
export var QUOTA = "\n    id\n    name\n    subjects {\n        ".concat(QUOTA_SUBJECT, "\n    }\n    customer {\n        id\n    }\n");
export var CUSTOMER = "\n    id\n    name\n    contact {\n        id\n        firstName\n        lastName\n        email\n        phone\n        street\n        zip\n        city\n    }\n    admins {\n        ".concat(ADMIN, "\n    }\n    roles {\n        ").concat(ROLE, "\n    }\n    quotas {\n        ").concat(QUOTA, "\n    }\n");
var query = "\n    query customer($id: ID!) {\n        customer(id: $id) {\n            ".concat(CUSTOMER, "\n            projects {\n                id\n                name\n                openingTimes {\n                    id\n                    startTime\n                    endTime\n                    description\n                    expectedVisitors\n                }\n                scenes {\n                    id\n                    name\n                }\n            }\n        }\n        roleFeatures {\n            label\n            name\n            writeAccess\n            readAccess\n            behavior\n        }\n        quotaSubjects {\n            ").concat(QUOTA_SUBJECT, "\n        }\n    }\n");
export default (function (id) { return (useQuery(query, {
    variables: {
        id: id,
    },
})); });
