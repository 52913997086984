import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { apiResponseToFormState } from 'helpers/form';
import useCreateCustomer from 'hooks/graphql/mutations/createCustomer';
import useUpdateCustomer from 'hooks/graphql/mutations/updateCustomer';
import EditingAdmin from 'EditingAdmin';
import TextField from 'cms/atoms/TextField';
import KeyValueTable from 'cms/molecules/KeyValueTable';
import Dialog from 'cms/molecules/Dialog';
import Grid from 'cms/molecules/Grid';

const defaultValues = {
    id: undefined,
    name: '',
    contact: {
        id: undefined,
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        street: '',
        zip: '',
        city: '',
    },
};

const Customer = (props) => {
    const createCustomer = useCreateCustomer();
    const updateCustomer = useUpdateCustomer();

    const [state, setState] = useState(apiResponseToFormState(props.customer, defaultValues));
    const validators = [{
        name: 'name',
        message: () => 'Bitte gib einen Namen ein.',
        isValid: (value) => !!value.trim(),
    }, {
        name: 'name',
        message: () => 'Es gibt bereits einen Kunden mit diesem Namen.',
        serverError: 'CUSTOMER_HAS_DUPLICATE_NAME',
    }, {
        name: 'contact.firstName',
        message: () => 'Bitte gib einen Vornamen ein.',
        isValid: (value, { contact }) => (
            // contact data are not required, but if any are provided, first and last name need to
            // be provided, too
            (
                !contact.lastName.trim()
                && !contact.email.trim()
                && !contact.phone.trim()
                && !contact.street.trim()
                && !contact.zip.trim()
                && !contact.city.trim()
            )
            || !!value.trim()
        ),
    }, {
        name: 'contact.lastName',
        message: () => 'Bitte gib einen Nachnamen ein.',
        isValid: (value, { contact }) => (
            (
                !contact.firstName.trim()
                && !contact.email.trim()
                && !contact.phone.trim()
                && !contact.street.trim()
                && !contact.zip.trim()
                && !contact.city.trim()
            )
            || !!value.trim()
        ),
    }];

    useEffect(() => {
        if (props.isOpen) {
            setState(apiResponseToFormState(props.customer, defaultValues));
        }
    }, [props.isOpen]);

    const save = async (values) => {
        const mutation = props.customer ? updateCustomer : createCustomer;
        await mutation({
            id: values.id,
            name: values.name.trim(),
            contact: values.contact.firstName.trim() && values.contact.lastName.trim() ? {
                ...values.contact,
                firstName: values.contact.firstName.trim(),
                lastName: values.contact.lastName.trim(),
                email: values.contact.email.trim(),
                phone: values.contact.phone.trim(),
                street: values.contact.street.trim(),
                zip: values.contact.zip.trim(),
                city: values.contact.city.trim(),
            } : null,
        });

        props.onClose();
    };

    return (
        <Dialog
            title={props.customer ? 'Kunden bearbeiten' : 'Neuen Kunden erstellen'}
            isOpen={props.isOpen}
            onClose={props.onClose}
            onConfirm={save}
            onChange={setState}
            values={state}
            validators={validators}
        >
            {({ errors, onChangeByEvent }) => (
                <>
                    {props.customer && (
                        <EditingAdmin name={`customer-${props.customer.id}`} />
                    )}

                    <KeyValueTable
                        items={[
                            {
                                key: 'Name',
                                value: (
                                    <TextField
                                        name="name"
                                        value={state.name}
                                        onChange={onChangeByEvent}
                                        error={errors.name}
                                    />
                                ),
                            },
                            {
                                key: 'Ansprechpartner',
                                value: (
                                    <Grid>
                                        <Grid item size={6}>
                                            <TextField
                                                label="Vorname"
                                                name="contact.firstName"
                                                value={state.contact.firstName}
                                                onChange={onChangeByEvent}
                                                error={errors['contact.firstName']}
                                            />
                                        </Grid>

                                        <Grid item size={6}>
                                            <TextField
                                                label="Nachname"
                                                name="contact.lastName"
                                                value={state.contact.lastName}
                                                onChange={onChangeByEvent}
                                                error={errors['contact.lastName']}
                                            />
                                        </Grid>

                                        <Grid item size={12}>
                                            <TextField
                                                label="E-Mail-Adresse"
                                                name="contact.email"
                                                value={state.contact.email}
                                                onChange={onChangeByEvent}
                                            />
                                        </Grid>

                                        <Grid item size={12}>
                                            <TextField
                                                label="Telefonnummer"
                                                name="contact.phone"
                                                value={state.contact.phone}
                                                onChange={onChangeByEvent}
                                            />
                                        </Grid>

                                        <Grid item size={12}>
                                            <TextField
                                                label="Straße"
                                                name="contact.street"
                                                value={state.contact.street}
                                                onChange={onChangeByEvent}
                                            />
                                        </Grid>

                                        <Grid item size={6}>
                                            <TextField
                                                label="Postleitzahl"
                                                name="contact.zip"
                                                value={state.contact.zip}
                                                onChange={onChangeByEvent}
                                            />
                                        </Grid>

                                        <Grid item size={6}>
                                            <TextField
                                                label="Stadt"
                                                name="contact.city"
                                                value={state.contact.city}
                                                onChange={onChangeByEvent}
                                            />
                                        </Grid>
                                    </Grid>
                                ),
                                align: 'top',
                            },
                        ]}
                    />
                </>
            )}
        </Dialog>
    );
};

Customer.defaultProps = {
    customer: null,
};

Customer.propTypes = {
    customer: PropTypes.object,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default Customer;
