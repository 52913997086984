import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import useUpdateProject from 'hooks/graphql/mutations/updateProject';
import MeContext from 'contexts/me';
import Button from 'cms/atoms/Button';
import Headline from 'cms/atoms/Headline';
import DataTable from 'cms/molecules/DataTable';
import Confirm from 'components/ui/cms/molecules/Confirm';
import { removeDuplicateIds } from 'helpers/challenge';
import TriggerEditor from '../../editors/Project/Challenge/Trigger';

const systemTriggers = [{
    id: 'OPEN_CALLING_CARD',
    name: 'Visitenkarten abschicken',
}];

const Triggers = (props) => {
    const updateProject = useUpdateProject();
    const [triggerEditorState, setTriggerEditorState] = useState({
        isOpen: false,
        id: null,
    });
    const [deleteTriggerDialogState, setDeleteTriggerDialogState] = useState({
        isOpen: false,
        id: null,
    });
    const me = useContext(MeContext);

    const updateInputTriggers = props.project.triggers.map((trigger) => ({
        ...trigger,
        tasks: trigger.tasks.map(({ id }) => id),
    }));

    const openTriggerEditorHandler = (id) => () => {
        setTriggerEditorState({ isOpen: true, id });
    };

    const closeTriggerEditor = () => {
        setTriggerEditorState({ ...triggerEditorState, isOpen: false });
    };

    const openDeleteTriggerDialogHandler = (id) => () => {
        setDeleteTriggerDialogState({ isOpen: true, id });
    };

    const closeDeleteTriggerDialog = () => {
        setDeleteTriggerDialogState({ ...deleteTriggerDialogState, isOpen: false });
    };

    const deleteTrigger = async () => {
        const triggers = updateInputTriggers.filter(({ id }) => (
            id !== deleteTriggerDialogState.id
        ));
        await saveTriggers(triggers);
        closeDeleteTriggerDialog();
    };

    const updateTrigger = async (values) => {
        const systemTrigger = systemTriggers.find(({ id }) => id === values.id);
        if (systemTrigger) {
            await saveSystemTrigger(systemTrigger.id, values.tasks);
        } else {
            const filtered = updateInputTriggers.filter(({ id }) => id !== values.id);
            const triggers = [...filtered, values].sort((a, b) => a.name.localeCompare(b.name));
            await saveTriggers(triggers);
        }
    };

    const saveSystemTrigger = async (triggerId, taskIds) => {
        const challenge = props.project.challenge;
        await updateProject({
            id: props.project.id,
            challenge: {
                id: challenge.id,
                tasks: props.project.challenge.tasks.map((task) => ({
                    id: task.id,
                    systemTriggers: taskIds.includes(task.id)
                        ? removeDuplicateIds([...task.systemTriggers, triggerId])
                        : task.systemTriggers.filter(
                            (systemTrigger) => systemTrigger !== triggerId
                        ),
                })),
            },
        });
    };

    const saveTriggers = async (triggers) => {
        await updateProject({
            id: props.project.id,
            triggers: triggers.map((trigger) => ({
                id: trigger.id,
                name: trigger.name.trim(),
                tasks: trigger.tasks.map((id) => ({ id })),
            })),
        });
    };

    const currentUserTrigger = props.project.triggers
        .find(({ id }) => id === triggerEditorState.id);
    let currentSystemTrigger = systemTriggers.find(({ id }) => id === triggerEditorState.id);
    if (currentSystemTrigger) {
        currentSystemTrigger = {
            ...currentSystemTrigger,
            tasks: props.project.challenge
                ? props.project.challenge.tasks.filter((task) => (
                    task.systemTriggers.includes(currentSystemTrigger.id)
                ))
                : [],
        };
    }

    const systemTriggerRows = systemTriggers
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(({ name, id }) => ({
            name,
            actions: <Button icon="edit" onClick={openTriggerEditorHandler(id)} />,
        }));

    const userTriggerRows = [...props.project.triggers]
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(({ name, id }) => ({
            name,
            actions: (
                <>
                    <Button
                        icon="edit"
                        onClick={openTriggerEditorHandler(id)}
                    />

                    <Button
                        icon="delete"
                        onClick={openDeleteTriggerDialogHandler(id)}
                        color="secondary"
                    />
                </>
            ),
        }));

    return (
        <>
            <Headline
                action={me.hasWriteAccessToFeature('project.challenge') ? (
                    <Button
                        icon="add"
                        onClick={openTriggerEditorHandler(null)}
                    >
                        Neuer Trigger
                    </Button>
                ) : null}
            >
                Trigger
            </Headline>

            <TriggerEditor
                isOpen={triggerEditorState.isOpen}
                onClose={closeTriggerEditor}
                project={props.project}
                updateTrigger={updateTrigger}
                triggers={props.project.triggers}
                trigger={currentUserTrigger || currentSystemTrigger}
                nameEnabled={!currentSystemTrigger}
            />

            <Confirm
                title="Soll dieser Trigger wirklich gelöscht werden?"
                onConfirm={deleteTrigger}
                confirmLabel="Ja, löschen"
                onCancel={closeDeleteTriggerDialog}
                cancelLabel="Nein, abbrechen"
                isOpen={deleteTriggerDialogState.isOpen}
                destructive
            />

            <DataTable
                columns={[
                    { dataKey: 'name', label: 'Name' },
                    {
                        dataKey: 'actions',
                        label: 'Aktionen',
                        width: 100,
                        align: 'center',
                        available: me.hasWriteAccessToFeature('project.challenge'),
                    },
                ]}
                rows={[...systemTriggerRows, ...userTriggerRows]}
            />
        </>
    );
};

Triggers.propTypes = {
    project: PropTypes.object.isRequired,
};

export default Triggers;
