import { useMutation } from 'apollo-augmented-hooks';
import moment from 'moment';

const mutation = `
    mutation stopProject($id: ID!) {
        stopProject(id: $id) {
            id
            openingTimes {
                id
                startTime
                endTime
                description
                expectedVisitors
            }
        }
    }
`;

export default (project) => {
    const [mutate] = useMutation(mutation);

    return (input) => {
        const now = moment();
        const index = project.openingTimes.findIndex(({ startTime, endTime }) => (
            now.isBetween(startTime, endTime)
        ));

        return mutate({
            input,
            optimisticResponse: {
                __typename: 'Project',
                openingTimes: [
                    ...project.openingTimes.slice(0, index),
                    {
                        ...project.openingTimes[index],
                        endTime: now.startOf('minute').toISOString(),
                    },
                    ...project.openingTimes.slice(index + 1),
                ],
            },
        });
    };
};
