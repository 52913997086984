import React from 'react';
import PropTypes from 'prop-types';
import MuiContainer from '@material-ui/core/Container';
import Loader from 'cms/atoms/Loader';

const Container = (props) => (
    props.children ? (
        <MuiContainer className="ui-cms-container" maxWidth="md">
            {props.children}
        </MuiContainer>
    ) : (
        <div className="ui-cms-container__loading">
            <Loader />
        </div>
    )
);

Container.defaultProps = {
    children: null,
};

Container.propTypes = {
    children: PropTypes.node,
};

export default Container;
