import { useMutation } from 'apollo-augmented-hooks';
import { VISITOR } from '../queries/project';

const mutation = `
    mutation createProjectVisitorsWithAccounts($input: CreateProjectVisitorsInput!) {
        createProjectVisitorsWithAccounts(input: $input) {
            id
            visitors {
                ${VISITOR}
            }
        }
    }
`;

export default () => {
    const [mutate] = useMutation(mutation);

    return (input) => mutate({
        input,
        modifiers: [{
            cacheObject: (item) => item,
            fields: {
                visitors: ({ includeIf, item }) => includeIf(!!item.project),
            },
        },
        ],
    });
};
