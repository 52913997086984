import { useMutation } from 'apollo-augmented-hooks';
import { PROJECT } from '../queries/project';

const mutation = `
    mutation updateProject($input: UpdateProjectInput!) {
        updateProject(input: $input) {
            ${PROJECT}
        }
    }
`;

export default () => {
    const [mutate] = useMutation(mutation);
    return (input) => mutate({ input });
};
