import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import LocaleContext from 'contexts/locale';
import useUpdateProject from 'hooks/graphql/mutations/updateProject';
import { apiResponseToFormState } from 'helpers/form';
import tosTypes from 'helpers/tosTypes';
import {
    createUpdateTranslationInput,
    hasOptionalTranslation,
} from 'helpers/languages';
import EditingAdmin from 'EditingAdmin';
import TranslationCodeInput from 'cms/molecules/TranslationCodeInput';
import Select from 'cms/atoms/Select';
import Switch from 'cms/atoms/Switch';
import KeyValueTable from 'cms/molecules/KeyValueTable';
import Dialog from 'cms/molecules/Dialog';

const defaultValues = {
    id: undefined,
    tosEnabled: true,
    tosType: 'POPUP',
    tosCustomContent: { _translation: 'text' },
    tosCustomCheckbox: { _translation: 'text' },
};

const TermsOfService = (props) => {
    const localeContext = useContext(LocaleContext);
    const [state, setState] = useState(apiResponseToFormState(props.project, defaultValues));

    const updateProject = useUpdateProject();

    const validators = [{
        name: 'tosCustomContent',
        message: () => 'Bitte gib ebenfalls einen Inhalt für die Standard-Sprache ein.',
        isValid: (value) => hasOptionalTranslation(value, localeContext.defaultLanguage.id),
    }, {
        name: 'tosCustomCheckbox',
        message: () => 'Bitte gib ebenfalls einen Inhalt für die Standard-Sprache ein.',
        isValid: (value) => hasOptionalTranslation(value, localeContext.defaultLanguage.id),
    }];

    const save = async (values) => {
        await updateProject({
            tosEnabled: values.tosEnabled,
            tosType: values.tosType,
            tosCustomContent: createUpdateTranslationInput(values.tosCustomContent),
            tosCustomCheckbox: createUpdateTranslationInput(values.tosCustomCheckbox),
            id: props.project.id,
        });

        props.onClose();
    };

    return (
        <Dialog
            title="AGB-Einstellungen bearbeiten"
            isOpen={props.isOpen}
            onClose={props.onClose}
            onConfirm={save}
            onChange={setState}
            values={state}
            validators={validators}
        >
            {({ errors, onChangeByEvent, onChangeByValue }) => (
                <>
                    <EditingAdmin name={`project-terms-of-service-${props.project.id}`} />

                    <KeyValueTable
                        items={[{
                            key: 'AGB-Bestätigung',
                            value: (
                                <Switch
                                    name="tosEnabled"
                                    checked={state.tosEnabled}
                                    onChange={onChangeByEvent}
                                />
                            ),
                        }, {
                            key: 'Darstellung der AGB-Bestätigung',
                            value: (
                                <Select
                                    name="tosType"
                                    value={state.tosType}
                                    onChange={onChangeByEvent}
                                    items={tosTypes}
                                />
                            ),
                            available: state.tosEnabled,
                        }, {
                            key: 'Individueller Inhalt für AGB-Bestätigung',
                            value: (
                                <TranslationCodeInput
                                    value={state.tosCustomContent}
                                    onChange={onChangeByValue('tosCustomContent')}
                                    projectFiles={props.project.files}
                                    error={errors.tosCustomContent}
                                />
                            ),
                            available: state.tosEnabled,
                            align: 'top',
                        }, {
                            key: 'Individueller Checkbox-Text für AGB-Bestätigung',
                            value: (
                                <TranslationCodeInput
                                    value={state.tosCustomCheckbox}
                                    onChange={onChangeByValue('tosCustomCheckbox')}
                                    projectFiles={props.project.files}
                                    error={errors.tosCustomCheckbox}
                                />
                            ),
                            available: state.tosEnabled && state.tosType === 'POPUP',
                            align: 'top',
                        }]}
                    />
                </>
            )}
        </Dialog>
    );
};

TermsOfService.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    project: PropTypes.object.isRequired,
};

export default TermsOfService;
