import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const scrollPositions = {};
let lastPathname = null;

const RestoredScroll = () => {
    const history = useHistory();

    useEffect(() => {
        lastPathname = history.location.pathname;

        return history.listen(({ pathname }, action) => {
            if (action === 'PUSH') {
                scrollPositions[lastPathname] = window.scrollY;
            }

            window.scrollTo(0, 0);

            if (action === 'POP' && scrollPositions[pathname] !== undefined) {
                window.scrollTo(0, scrollPositions[pathname]);
            }

            lastPathname = pathname;
        });
    }, []);

    return null;
};

export default RestoredScroll;
