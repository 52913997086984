import React from 'react';
import PropTypes from 'prop-types';
import BsButton from 'react-bootstrap/Button';

const Button = (props) => {
    let variant = props.secondary ? 'outline-primary' : 'primary';
    variant = props.link ? 'link' : variant;
    return (
        <BsButton
            variant={variant}
            onClick={props.onClick}
            disabled={props.disabled}
            className="ui-web-button"
        >
            {props.children}
        </BsButton>
    );
};

Button.defaultProps = {
    secondary: false,
    link: false,
    disabled: false,
};

Button.propTypes = {
    secondary: PropTypes.bool,
    link: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    disabled: PropTypes.bool,
};

export default Button;
