export const getOverlayType = (overlay) => {
    if (overlay.type) {
        return overlay.type;
    }

    if (overlay.imageData?.id) return 'IMAGE';
    if (overlay.videoData?.id) return 'VIDEO';
    if (overlay.newstickerData?.id) return 'NEWSTICKER';
    if (overlay.htmlData?.id) return 'HTML';

    return '';
};

export const defaultOverlayFormState = () => ({
    id: undefined,
    name: '',
    mobile: true,
    type: {
        _default: '',
        _key: '_parent',
        _modify: getOverlayType,
    },
    x: 0.4,
    y: 0.4,
    width: 0.2,
    height: 0.2,
    imageData: {
        id: undefined,
        file: { _translation: 'media' },
    },
    videoData: {
        id: undefined,
        url: { _translation: 'text' },
        autoplay: true,
        mute: true,
        loop: false,
        controls: 'FULLSCREEN',
    },
    newstickerData: {
        id: undefined,
        text: { _translation: 'text' },
    },
    htmlData: {
        id: undefined,
        html: { _translation: 'text' },
    },
});
