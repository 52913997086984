import React from 'react';
import PropTypes from 'prop-types';
import MuiSwitch from '@material-ui/core/Switch';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const Switch = (props) => {
    const renderSwitch = () => (
        <MuiSwitch
            checked={props.checked}
            onChange={props.onChange}
            name={props.name}
            color="primary"
            disabled={props.disabled}
        />
    );

    return (
        <FormControl className="ui-cms-switch">
            {props.label ? (
                <FormControlLabel
                    control={renderSwitch()}
                    label={props.label}
                />
            ) : (
                renderSwitch()
            )}
        </FormControl>
    );
};

Switch.defaultProps = {
    name: null,
    label: null,
    disabled: false,
};

Switch.propTypes = {
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string,
    label: PropTypes.string,
    disabled: PropTypes.bool,
};

export default Switch;
