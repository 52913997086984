import React from 'react';
import PropTypes from 'prop-types';
import { actionTypes } from 'helpers/actions';
import Image from 'Image';

const ButtonList = (props) => (
    <div className="ui-cms-button-list">
        {props.buttons.map((button) => (
            button.image ? (
                <div
                    className="ui-cms-button-list__button"
                    key={button.image}
                >
                    <Image
                        url={button.image}
                        maxWidth={32}
                    />

                    <div className="ui-cms-button-list__name">
                        {button.name}
                    </div>

                    <div className="ui-cms-button-list__type">
                        ({actionTypes.find(({ value }) => value === button.type).label})
                    </div>
                </div>
            ) : (
                null
            )
        ))}
    </div>
);

ButtonList.propTypes = {
    buttons: PropTypes.arrayOf(PropTypes.shape({
        image: PropTypes.string,
        name: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
    })).isRequired,
};

export default ButtonList;
