import React, { useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { saveAs } from 'file-saver';

import appConfig from 'appConfig';
import DateTimePicker from 'cms/atoms/DateTimePicker';
import Grid from 'cms/molecules/Grid';
import Headline from 'cms/atoms/Headline';
import useRestRequest from 'hooks/useRestRequest';
import CheckboxGroup from 'cms/atoms/CheckboxGroup';
import Button from 'cms/atoms/Button';

const Presences = (props) => {
    const [startDate, setStartDate] = useState(moment().subtract(1, 'day').startOf('day'));
    const [endDate, setEndDate] = useState(moment().subtract(1, 'day').endOf('day'));
    const exportRequestBuilder = useRestRequest();
    const [sceneIds, setSceneIds] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const exportPresenceTrackings = async () => {
        setIsLoading(true);
        const response = await exportRequestBuilder({
            url: `${appConfig.apiUrl}presences/export`,
            method: 'post',
            data: {
                endDate: endDate.toISOString(),
                startDate: startDate.toISOString(),
                sceneIds,
            },
            responseType: 'text',
        });
        setIsLoading(false);

        saveAs(
            new Blob([response.data], { type: 'text/csv;charset=utf-8' }),
            `presences_export_${Date.now()}.csv`
        );
    };

    const isExportButtonDisabled = !sceneIds.length
        || !endDate
        || !startDate
        || endDate < startDate
        || isLoading;

    return (
        <div className="ui-cms-presence-tracking-export">
            <Headline>Anwesenheiten</Headline>
            <Grid>
                <Grid item size={6}>
                    <DateTimePicker
                        label="von"
                        value={startDate}
                        onChange={setStartDate}
                        disableFuture
                    />
                </Grid>

                <Grid item size={6}>
                    <DateTimePicker
                        label="bis"
                        value={endDate}
                        onChange={setEndDate}
                        disableFuture
                    />
                </Grid>
            </Grid>
            <Grid>
                <Grid item size={12}>
                    <CheckboxGroup
                        withSelectAll
                        items={
                            props.project.scenes
                                .filter((s) => s.presenceTrackingEnabled)
                                .map(({ name: label, id: value }) => ({ label, value }))
                        }
                        onChange={setSceneIds}
                        value={sceneIds}
                    />
                </Grid>
            </Grid>
            <Grid>
                <Grid item size={12} className="ui-cms-grid--align-items-right">
                    <Button
                        isPending={isLoading}
                        disabled={isExportButtonDisabled}
                        onClick={exportPresenceTrackings}
                    >
                        Exportieren
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
};

Presences.propTypes = {
    project: PropTypes.object.isRequired,
};

export default Presences;
