import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { findOpeningType } from 'helpers/openingTypes';
import useToggle from 'hooks/useToggle';
import useStartProject from 'hooks/graphql/mutations/startProject';
import useStopProject from 'hooks/graphql/mutations/stopProject';
import useProject from 'hooks/graphql/queries/project';
import MeContext from 'contexts/me';
import Button from 'cms/atoms/Button';
import Headline from 'cms/atoms/Headline';
import Confirm from 'components/ui/cms/molecules/Confirm';
import KeyValueTable from 'cms/molecules/KeyValueTable';
import Editor from '../editors/Project/OpeningTimes';

const OpeningTimes = () => {
    const [isEditorOpen, openEditor, closeEditor] = useToggle(false);
    const [
        isStartProjectAlertOpen, openStartProjectAlert, closeStartProjectAlert,
    ] = useToggle(false);
    const [isStopProjectAlertOpen, openStopProjectAlert, closeStopProjectAlert] = useToggle(false);
    const [
        isStartProjectErrorOpen, openStartProjectError, closeStartProjectError,
    ] = useToggle(false);
    const [isStopProjectErrorOpen, openStopProjectError, closeStopProjectError] = useToggle(false);
    const me = useContext(MeContext);
    const params = useParams();

    // Queries
    const { data, loading } = useProject(params.id);
    const { project } = !loading && data;

    // Mutations
    const startProject = useStartProject(project);
    const stopProject = useStopProject(project);

    const handleStartProject = () => {
        closeStartProjectAlert();

        if (eventIsLive()) {
            openStartProjectError();
            return;
        }

        startProject(project.id);
    };

    const handleStopProject = () => {
        closeStopProjectAlert();

        if (!eventIsLive()) {
            openStopProjectError();
            return;
        }

        stopProject(project.id);
    };

    const eventIsLive = () => (
        project.openingTimes.some(({ startTime, endTime }) => (
            moment().isBetween(startTime, endTime, undefined, '[]')
        ))
    );

    const renderOpeningTime = ({ startTime, endTime }) => {
        const endTimeFormat = moment(startTime).isSame(endTime, 'day') ? 'HH:mm' : 'L, HH:mm';

        return (
            <div key={startTime}>
                {moment(startTime).format('L, HH:mm')} Uhr - {moment(endTime).format(endTimeFormat)} Uhr
            </div>
        );
    };

    return !loading && (
        <>
            <Headline
                action={me.hasWriteAccessToFeature('project.openingTimes') ? (
                    <Button
                        icon="edit"
                        onClick={openEditor}
                    >
                        Bearbeiten
                    </Button>
                ) : null}
            >
                Öffnungszeiten
            </Headline>

            <Editor
                isOpen={isEditorOpen}
                onClose={closeEditor}
                project={project}
            />

            <KeyValueTable
                wrappingDividers
                items={[
                    {
                        key: 'Öffnungsart',
                        value: findOpeningType(project.openingType).label,
                    },
                    {
                        key: 'Öffnungszeiten',
                        value: (
                            <div>
                                {project.openingTimes.map(renderOpeningTime)}

                                <Confirm
                                    title="Das Event kann nicht gestartet werden."
                                    onConfirm={closeStartProjectError}
                                    isOpen={isStartProjectErrorOpen}
                                >
                                    Es ist bereits aktiv.
                                </Confirm>

                                <Confirm
                                    title="Das Event kann nicht geschlossen werden."
                                    onConfirm={closeStopProjectError}
                                    isOpen={isStopProjectErrorOpen}
                                >
                                    Es ist bereits geschlossen.
                                </Confirm>

                                <Confirm
                                    title="Soll das Event wirklich gestartet werden?"
                                    onConfirm={handleStartProject}
                                    confirmLabel="Ja, starten"
                                    onCancel={closeStartProjectAlert}
                                    cancelLabel="Nein, abbrechen"
                                    isOpen={isStartProjectAlertOpen}
                                />

                                <Confirm
                                    title="Soll das Event wirklich gestartet werden?"
                                    onConfirm={handleStartProject}
                                    confirmLabel="Ja, starten"
                                    onCancel={closeStartProjectAlert}
                                    cancelLabel="Nein, abbrechen"
                                    isOpen={isStartProjectAlertOpen}
                                />

                                <Confirm
                                    title="Soll das Event wirklich geschlossen werden?"
                                    onConfirm={handleStopProject}
                                    confirmLabel="Ja, schließen"
                                    onCancel={closeStopProjectAlert}
                                    cancelLabel="Nein, abbrechen"
                                    isOpen={isStopProjectAlertOpen}
                                />

                                {me.hasWriteAccessToFeature('project.openingTimes') && (
                                    <div>
                                        {eventIsLive() ? (
                                            <Button
                                                icon="cancel"
                                                onClick={openStopProjectAlert}
                                            >
                                                Event schließen
                                            </Button>
                                        ) : (
                                            <Button
                                                icon="start"
                                                onClick={openStartProjectAlert}
                                            >
                                                Event starten
                                            </Button>
                                        )}
                                    </div>
                                )}
                            </div>
                        ),
                        align: 'top',
                        available: project.openingType === 'TIME_CONTROL',
                    },
                    {
                        key: 'On-Demand-Laufzeit',
                        value: project.onDemandStartTime ? (
                            `${moment(project.onDemandStartTime).format('L, HH:mm')} Uhr - ${moment(project.onDemandEndTime).format('L, HH:mm')} Uhr`
                        ) : (
                            'Keine'
                        ),
                        align: 'top',
                        available: false, // functionality is not implemented yet
                    },
                ]}
            />
        </>
    );
};

export default OpeningTimes;
