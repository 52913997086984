import BaseModel from 'react-apollo-models';
import { AUTH_TYPE_NONE } from 'auth';

export default class LoginModel extends BaseModel {
    static mutations = [{
        mutation: `
            mutation createToken($input: CreateTokenInput!) {
                createToken(input: $input) {
                    identityToken
                    renewalToken
                    customer {
                        id
                    }
                }
            }
        `,
        optimisticResponse: null,
        context: { authType: AUTH_TYPE_NONE },
    }]
}
