import React from 'react';
import PropTypes from 'prop-types';
import { ResizableBox } from 'react-resizable';
import classNames from 'classnames';
import useTranslate from 'hooks/useTranslate';
import mediaUrl from 'helpers/mediaUrl';
import Draggable from 'Draggable';
import InnerHTML from 'InnerHTML';
import Image from 'Image';
import Icon from 'Icon';
import { getOverlayType } from 'helpers/overlays';
import Newsticker from './Overlay/Newsticker';
import Video from './Overlay/Video';

const Overlay = (props) => {
    const t = useTranslate();

    const onResize = (_event, { size }) => {
        props.onResize({
            width: size.width / props.containerWidth,
            height: size.height / props.containerHeight,
        });
    };

    const onResizeHandleClick = (event) => {
        event.stopPropagation();
    };

    const renderPlaceholder = (icon) => (
        <div className="ui-shared-overlay__placeholder">
            <Icon type={icon} />
        </div>
    );

    const renderActualContent = () => {
        // Some of these overlay types are a bit yanky when displayed in the
        // scene-editor, so we just display a placeholder.
        switch (getOverlayType(props.overlay)) {
            case 'IMAGE':
                return (
                    <div className="ui-shared-overlay__image">
                        <Image url={mediaUrl(t(props.overlay.imageData.file), 'standard')} />
                    </div>
                );

            case 'VIDEO':
                return props.placeholder
                    ? renderPlaceholder('video') : (
                        <Video
                            {...props.overlay.videoData}
                            sceneName={props.sceneName}
                            preview={props.preview}
                        />
                    );

            case 'NEWSTICKER':
                return props.placeholder || props.preview
                    ? renderPlaceholder('newsticker') : (
                        <Newsticker
                            containerHeight={props.containerHeight}
                            width={props.width}
                            height={props.height}
                        >
                            {t(props.overlay.newstickerData.text)}
                        </Newsticker>
                    );

            case 'HTML':
                return props.placeholder || props.preview
                    ? renderPlaceholder('code') : (
                        <InnerHTML>{t(props.overlay.htmlData.html)}</InnerHTML>
                    );

            default: return null;
        }
    };

    const renderContent = () => {
        const content = props.placeholder && props.onResize ? (
            <ResizableBox
                width={props.width * props.containerWidth}
                height={props.height * props.containerHeight}
                minConstraints={[32, 32]}
                onResize={onResize}
                maxConstraints={[
                    props.containerWidth - props.containerWidth * props.overlay.x,
                    props.containerHeight - props.containerHeight * props.overlay.y,
                ]}
                handle={(
                    <span
                        className="react-resizable-handle react-resizable-handle-se"
                        onClick={onResizeHandleClick}
                    />
                )}
            >
                {renderActualContent()}
            </ResizableBox>
        ) : renderActualContent();

        if (props.onDrag) {
            return (
                <Draggable
                    onDragStart={props.onDragStart}
                    onDrag={props.onDrag}
                    onDragEnd={props.onDragEnd}
                    ignoreSelector=".react-resizable-handle"
                >
                    {content}
                </Draggable>
            );
        }

        return content;
    };

    return (
        <div
            className={classNames(
                'ui-shared-overlay',
                { 'ui-shared-overlay--with-click': !!props.onClick }
            )}
            style={{
                ...props.style,
                width: `${props.width * 100}%`,
                height: `${props.height * 100}%`,
            }}
            onMouseEnter={props.onMouseEnter}
            onMouseMove={props.onMouseMove}
            onClick={props.onClick}
        >
            {renderContent()}
        </div>
    );
};

Overlay.defaultProps = {
    onClick: null,
    onMouseEnter: null,
    onMouseMove: null,
    onDragStart: null,
    onDrag: null,
    onDragEnd: null,
    onResize: null,
    style: null,
    placeholder: false,
    containerWidth: null,
    containerHeight: null,
    sceneName: null,
    preview: false,
};

Overlay.propTypes = {
    overlay: PropTypes.object.isRequired,
    onClick: PropTypes.func,
    onMouseEnter: PropTypes.func,
    onMouseMove: PropTypes.func,
    onDragStart: PropTypes.func,
    onDrag: PropTypes.func,
    onDragEnd: PropTypes.func,
    onResize: PropTypes.func,
    style: PropTypes.object,
    placeholder: PropTypes.bool,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    containerWidth: PropTypes.number,
    containerHeight: PropTypes.number,
    sceneName: PropTypes.string,
    preview: PropTypes.bool,
};

export default Overlay;
