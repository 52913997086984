import { useEffect } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { getAuthType } from '../auth';

const TRACK_PRESENCE = gql`
    mutation trackPresence($input: TrackPresenceInput!) {
        trackPresence(input: $input) {
            id
        }
    }
`;

export default (sceneId, trackingText, trackingCheckbox, projectPath, presenceTrackingInterval, isEnabled) => {
    const [trackPresence] = useMutation(TRACK_PRESENCE, { context: { authType: getAuthType(projectPath) } });

    useEffect(() => {
        if (!isEnabled) {
            return () => {};
        }

        const input = { sceneId, trackingText, trackingCheckbox };
        trackPresence({ variables: { input } });

        const interval = setInterval(
            () => trackPresence({ variables: { input } }),
            presenceTrackingInterval,
        );

        return () => clearInterval(interval);
    }, [isEnabled]);
};
