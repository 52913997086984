export const TRANSLATION = `
    id
    entries {
        id
        text
        languageId
        language {
            code
            systemCode
        }
    }
`;

export const MEDIA_TRANSLATION = `
    id
    mediaEntries {
        id
        languageId
        mediaId
        media {
            id
            url
            filename
            mimeType
        }
    }
`;
