import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import useWindowResize from 'hooks/useWindowResize';
import useForceUpdate from 'hooks/useForceUpdate';
import Tabs from 'cms/molecules/Tabs';
import Actions from './shared/Actions';
import Overlays from './Background/Overlays';

const Background = (props) => {
    const containerRef = useRef();
    const actionsRef = useRef();
    const overlaysRef = useRef();
    const [activeTab, setActiveTab] = useState('actions');
    const forceUpdate = useForceUpdate();

    useWindowResize('background', forceUpdate);

    const onMouseMove = (event) => {
        if (activeTab === 'actions') {
            actionsRef.current.onContainerMouseMove(event);
        } else if (activeTab === 'overlays') {
            overlaysRef.current.onContainerMouseMove(event);
        }
    };

    const onMouseLeave = (event) => {
        if (activeTab === 'actions') {
            actionsRef.current.onContainerMouseLeave(event);
        } else if (activeTab === 'overlays') {
            overlaysRef.current.onContainerMouseLeave(event);
        }
    };

    return (
        <div
            className={classNames(
                'cms-background',
                {
                    'cms-background--action-mode': activeTab === 'actions',
                    'cms-background--overlay-mode': activeTab === 'overlays',
                }
            )}
        >
            <Tabs
                items={[
                    { value: 'actions', label: 'Aktionen' },
                    { value: 'overlays', label: 'Overlays', available: !!props.overlays },
                ]}
                value={activeTab}
                onChange={setActiveTab}
            >
                <div
                    className="cms-background__image"
                    onMouseMove={onMouseMove}
                    onMouseLeave={onMouseLeave}
                    ref={containerRef}
                >
                    {props.image}

                    {props.overlays && (
                        <Overlays
                            overlays={props.overlays}
                            onSave={props.onSave}
                            onDelete={props.onDelete}
                            projectFiles={props.projectFiles}
                            sceneFiles={props.sceneFiles}
                            containerRef={containerRef}
                            ref={overlaysRef}
                        />
                    )}

                    <Actions
                        actions={props.actions}
                        buttons={props.buttons}
                        projectButtons={props.projectButtons}
                        scenes={props.scenes}
                        onSave={props.onSave}
                        onDelete={props.onDelete}
                        projectFiles={props.projectFiles}
                        sceneFiles={props.sceneFiles}
                        withMailbox={props.withMailbox}
                        loginType={props.loginType}
                        accessGroups={props.accessGroups}
                        challenge={props.challenge}
                        isPanoramaChildScene={props.isPanoramaChildScene}
                        containerRef={containerRef}
                        ref={actionsRef}
                    />
                </div>
            </Tabs>
        </div>
    );
};

Background.defaultProps = {
    projectFiles: [],
    sceneFiles: [],
    loginType: null,
    accessGroups: [],
    withMailbox: false,
    overlays: null,
    challenge: null,
    isPanoramaChildScene: false,
};

Background.propTypes = {
    image: PropTypes.node.isRequired,
    actions: PropTypes.array.isRequired,
    overlays: PropTypes.array,
    buttons: PropTypes.array.isRequired,
    projectButtons: PropTypes.array.isRequired,
    scenes: PropTypes.array.isRequired,
    onSave: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    projectFiles: PropTypes.array,
    sceneFiles: PropTypes.array,
    withMailbox: PropTypes.bool,
    loginType: PropTypes.string,
    accessGroups: PropTypes.array,
    challenge: PropTypes.object,
    isPanoramaChildScene: PropTypes.bool,
};

export default Background;
