import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import useTracking from 'hooks/useTracking';
import { TRACKING_SHOW_DOCUMENT } from 'helpers/tracking';
import Dialog from 'web/molecules/Dialog';

const DocumentDialog = (props) => {
    const tracking = useTracking();

    useEffect(() => {
        if (props.isOpen) {
            // TRACKCORE:
            //  event:'showDocument', ressource: props.title, data: {scene: props.sceneName, customer: customerId}
            //  SCENE CONTENT: download button clicked --> view button clicked
            // console.log('%cTRACKCORE: showDocument', 'background: #00a8ff; font-weight: bold;', props.title);
            tracking.trackCoreEvent({
                action: 'showDocument',
                resourceType: 'title',
                resourceName: props.title,
            });
            tracking.trackEvent(props.sceneName, TRACKING_SHOW_DOCUMENT, props.title);
        }
    }, [props.isOpen]);

    const isImage = () => props.mimeType.startsWith('image/');
    const isPdf = () => props.mimeType === 'application/pdf';

    const renderImage = () => (
        <img
            alt={props.title}
            className="web-document-image"
            src={props.fileUrl}
        />
    );

    const renderPdf = () => (
        <embed
            className="web-document-pdf"
            src={props.fileUrl}
            type="application/pdf"
        />
    );

    return (
        <Dialog
            isOpen={props.isOpen}
            onClose={props.onClose}
            title={props.title}
        >
            {isImage() && renderImage()}
            {isPdf() && renderPdf()}
        </Dialog>
    );
};

DocumentDialog.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    mimeType: PropTypes.string.isRequired,
    fileUrl: PropTypes.string.isRequired,
    sceneName: PropTypes.string.isRequired,
};

export default DocumentDialog;
