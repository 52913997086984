const openingTypes = [
    {
        label: 'Dauerhaft geöffnet',
        value: 'OPEN',
    },
    {
        label: 'Geschlossen',
        value: 'CLOSED',
    },
    {
        label: 'Zeitgesteuert',
        value: 'TIME_CONTROL',
    },
];

export default openingTypes;

export const findOpeningType = (openingType) => (
    openingTypes.find(({ value }) => value === openingType)
);
