import React from 'react';
import PropTypes from 'prop-types';
import Dialog from 'web/molecules/Dialog';
import GalleryContent from './shared/GalleryContent';

const GalleryDialog = (props) => (
    <Dialog
        isOpen={props.isOpen}
        onClose={props.onClose}
        title={props.title}
    >
        <div className="web-gallery-dialog">
            <GalleryContent
                images={props.images}
                sceneName={props.sceneName}
                title={props.title}
            />
        </div>
    </Dialog>
);

GalleryDialog.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    images: PropTypes.array.isRequired,
    sceneName: PropTypes.string.isRequired,
};

export default GalleryDialog;
