import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Upload from 'Upload';
import Button from 'cms/atoms/Button';
import FileList from 'cms/atoms/FileList';

const FileUpload = (props) => {
    const [error, setError] = useState(null);

    const files = props.file ? [props.file] : [];

    return (
        <FormControl fullWidth error={!!props.error || !!error}>
            <div className="ui-cms-font-upload">
                <Upload
                    onChange={props.onUpload}
                    onError={setError}
                    type="font"
                    uniqueFiles={files}
                >
                    <Button icon="upload">
                        Schriftart hochladen
                    </Button>
                </Upload>

                <FileList
                    onRemove={props.onRemove}
                    files={files.map((file) => ({ label: file }))}
                />
            </div>

            <FormHelperText>{props.error || error}</FormHelperText>
        </FormControl>
    );
};

FileUpload.defaultProps = {
    error: null,
    file: null,
};

FileUpload.propTypes = {
    onUpload: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    file: PropTypes.string,
    error: PropTypes.string,
};

export default FileUpload;
