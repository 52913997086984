import { useMutation } from 'apollo-augmented-hooks';
import { QUOTA } from '../queries/customer';

const mutation = `
    mutation createQuota($input: CreateQuotaInput!) {
        createQuota(input: $input) {
            ${QUOTA}
        }
    }
`;

export default () => {
    const [mutate] = useMutation(mutation);

    return (input) => (
        mutate({
            input,
            modifiers: [{
                fields: {
                    customers: ({ includeIf }) => includeIf(true),
                },
            }, {
                cacheObject: (item) => item.customer,
                fields: {
                    quotas: ({ includeIf, item }) => includeIf(!!item.customer),
                },
            }],
        })
    );
};
