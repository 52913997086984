import React, { useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useLocalStorage } from '@rehooks/local-storage';
import MeContext from 'contexts/me';
import useCustomer from 'hooks/graphql/queries/customer';
import Tabs from 'cms/molecules/Tabs';
import BreadCrumbs from 'cms/organisms/BreadCrumbs';
import Container from 'cms/organisms/Container';
import General from './Customer/General';
import Projects from './Customer/Projects';
import Admins from './Customer/Admins';
import Roles from './Customer/Roles';
import Quotas from './Customer/Quotas';

const Customer = () => {
    const history = useHistory();
    const params = useParams();
    const me = useContext(MeContext);
    const { data, loading } = useCustomer(params.id);

    // FIXME error when user does not have access to active tab
    const [activeTab, setActiveTab] = useLocalStorage('customerTab', 'general');

    const renderContent = () => {
        const { customer, quotaSubjects, roleFeatures } = data;
        const projects = [...customer.projects].sort((a, b) => (
            a.name.toLowerCase().localeCompare(b.name.toLowerCase())
        ));

        return (
            <>
                {me.admin.isBaseAdmin && (
                    <BreadCrumbs
                        items={[
                            { label: 'Dashboard', href: '/cms' },
                            { label: customer.name },
                        ]}
                        navigate={history.push}
                    />
                )}

                <Tabs
                    items={[
                        { value: 'general', label: 'Allgemeines' },
                        { value: 'projects', label: 'Projekte' },
                        { value: 'admins', label: 'Kunden-Accounts', available: me.hasReadAccessToFeature('customer.accounts') },
                        { value: 'roles', label: 'Rollen', available: me.admin.isBaseAdmin },
                        { value: 'quotas', label: 'Kontingente', available: me.hasReadAccessToFeature('customer.quotas') },
                    ]}
                    value={activeTab}
                    onChange={setActiveTab}
                >
                    {activeTab === 'general' && (
                        <General customer={customer} />
                    )}

                    {activeTab === 'projects' && (
                        <Projects
                            customer={customer}
                            projects={projects}
                        />
                    )}

                    {activeTab === 'admins' && (
                        <Admins customer={customer} />
                    )}

                    {activeTab === 'roles' && (
                        <Roles
                            customer={customer}
                            roleFeatures={roleFeatures}
                        />
                    )}

                    {activeTab === 'quotas' && (
                        <Quotas
                            customer={customer}
                            quotaSubjects={quotaSubjects}
                        />
                    )}
                </Tabs>
            </>
        );
    };

    return (
        <Container>{!loading && renderContent()}</Container>
    );
};

export default Customer;
