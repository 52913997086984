import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import LocaleContext from 'contexts/locale';
import Grid from 'cms/molecules/Grid';
import { resolveLanguageName } from 'helpers/languages';
import Select from 'cms/atoms/Select';
import update from 'immutability-helper';

const TranslationMediaSelect = (props) => {
    const localeContext = useContext(LocaleContext);

    const mediaEntries = props.value?.mediaEntries || [];
    const activeLanguageId = localeContext.language?.id;
    const currentEntryIndex = mediaEntries
        .findIndex(({ languageId }) => languageId === activeLanguageId);
    const currentEntry = mediaEntries[currentEntryIndex];

    const findMediaFileById = (id) => [...props.projectFiles, ...props.sceneFiles]
        .map(({ file }) => file)
        .find((file) => file.id === id);

    const handleMediaChange = (event) => {
        if (!activeLanguageId) {
            return; // no language chosen yet -> prevent input
        }
        const mediaId = event.target.value;

        let newEntries;
        if (currentEntry) {
            // update
            newEntries = update(mediaEntries, {
                [currentEntryIndex]: {
                    media: {
                        $set: findMediaFileById(mediaId),
                    },
                    mediaId: {
                        $set: mediaId,
                    },
                },
            });
        } else {
            // insert
            newEntries = update(mediaEntries, {
                $push: [{
                    mediaId,
                    media: findMediaFileById(mediaId),
                    languageId: activeLanguageId,
                }],
            });
        }
        props.onChange({
            id: props.value?.id,
            mediaEntries: newEntries,
        });
    };

    const handleLanguageChanged = (event) => {
        const languageId = event.target.value;
        const selectedLanguage = localeContext.languages.find(({ id }) => id === languageId);
        localeContext.setLanguage(selectedLanguage);
    };

    const fileFilter = ({ file }) => (
        !props.mimeType || file.mimeType.startsWith(props.mimeType)
    );

    return (
        <Grid>
            <Grid item size={8}>
                <Select
                    name={props.name}
                    label={props.label}
                    value={currentEntry?.mediaId || ''}
                    onChange={handleMediaChange}
                    items={[
                        ...(props.allowEmpty ? [{ label: 'Kein Bild', value: '' }] : []),
                        { label: 'Projektdateien' },
                        ...[...props.projectFiles]
                            .sort((a, b) => (
                                a.file.filename.localeCompare(b.file.filename)
                            ))
                            .filter(fileFilter)
                            .map(({ file }) => ({
                                label: file.filename,
                                value: file.id,
                            })),
                        { label: 'Szenendateien' },
                        ...[...props.sceneFiles]
                            .sort((a, b) => (
                                a.file.filename.localeCompare(b.file.filename)
                            ))
                            .filter(fileFilter)
                            .map(({ file }) => ({
                                label: file.filename,
                                value: file.id,
                            })),
                    ]}
                    error={props.error}
                    disabled={props.disabled}
                />
            </Grid>
            <Grid item size={4}>
                <Select
                    name={`${props.name}.activeLanguageId`}
                    label={props.label ? 'Sprache' : null} // keep height aligned with select
                    error={props.error ? ' ' : null} // keep height aligned with select
                    value={activeLanguageId || ''}
                    onChange={handleLanguageChanged}
                    items={localeContext.languages.map(({ id, code }) => ({
                        label: resolveLanguageName(code),
                        value: id,
                    }))}
                    disabled={props.disabled}
                />
            </Grid>
        </Grid>
    );
};

TranslationMediaSelect.defaultProps = {
    name: null,
    error: null,
    label: null,
    disabled: false,
    value: {
        mediaEntries: [],
    },
    mimeType: null,
    allowEmpty: false,
};

TranslationMediaSelect.propTypes = {
    name: PropTypes.string,
    value: PropTypes.object,
    label: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    error: PropTypes.string,
    disabled: PropTypes.bool,
    projectFiles: PropTypes.array.isRequired,
    sceneFiles: PropTypes.array.isRequired,
    mimeType: PropTypes.string,
    allowEmpty: PropTypes.bool,
};

export default TranslationMediaSelect;
