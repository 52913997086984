import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import MeContext from 'contexts/me';
import useToggle from 'hooks/useToggle';
import Button from 'cms/atoms/Button';
import Headline from 'cms/atoms/Headline';
import Card from 'cms/molecules/Card';
import Grid from 'cms/molecules/Grid';
import ProjectEditor from '../editors/Project';

const Projects = (props) => {
    const history = useHistory();
    const me = useContext(MeContext);
    const [isProjectEditorOpen, openProjectEditor, closeProjectEditor] = useToggle(false);

    const goToProjectHandler = (id) => () => {
        history.push(`/cms/project/${id}`);
    };

    const renderProject = (project) => {
        const { openingTimes } = project;

        // TODO show "dauerhaft geöffnet" / "geschlossen" instead
        let timeLabel = 'Keine Öffnungszeiten';

        if (openingTimes[0]) {
            const startTime = moment(openingTimes[0].startTime).format('L');
            const endTime = moment(openingTimes[openingTimes.length - 1].endTime).format('L');
            timeLabel = startTime === endTime ? startTime : `${startTime} - ${endTime}`;
        }

        return (
            <Grid item size={4} key={project.id}>
                <Card
                    headerLabel={project.name}
                    subLabel={timeLabel}
                    onClick={goToProjectHandler(project.id)}
                />
            </Grid>
        );
    };

    return (
        <>
            <Headline
                action={!me.hasWriteAccessToFeature('project.new') ? null : (
                    <Button
                        icon="add"
                        onClick={openProjectEditor}
                    >
                        Neues Projekt
                    </Button>
                )}
            >
                Projekte
            </Headline>

            <ProjectEditor
                isOpen={isProjectEditorOpen}
                onClose={closeProjectEditor}
                customerId={props.customer.id}
            />

            <Grid>
                {
                    props.projects
                        .filter((project) => me.hasAccessToProject(project.id))
                        .map(renderProject)
                }
            </Grid>
        </>
    );
};

Projects.propTypes = {
    customer: PropTypes.object.isRequired,
    projects: PropTypes.array.isRequired,
};

export default Projects;
