import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import EmailValidator from 'email-validator';
import InnerHTML from 'InnerHTML';
import { apiResponseToFormState } from 'helpers/form';
import { isValidFormValue } from 'helpers/formFields';
import { TRACKING_SUBMIT_CHALLENGE } from 'helpers/tracking';
import useToggle from 'hooks/useToggle';
import useTracking from 'hooks/useTracking';
import useTranslate from 'hooks/useTranslate';
import ProjectModel from 'models/web/project';
import Badge from 'web/atoms/Badge';
import Button from 'web/atoms/Button';
import TextField from 'web/atoms/TextField';
import FormDialog from 'web/molecules/FormDialog';
import Grid from 'web/molecules/Grid';
import Card from 'web/molecules/Card';
import Help from 'web/atoms/Help';
import ErrorAlert from 'web/atoms/ErrorAlert';
import Checkbox from 'web/atoms/Checkbox';
import Select from 'web/atoms/Select';

const defaultValues = {
    contactEmail: {
        _default: '',
        _key: 'email',
    },
    formData: {
        _key: 'fields',
        _map: () => ({
            id: undefined,
            value: '',
            type: undefined,
            description: { _translation: 'text' },
            label: undefined,
            options: {
                _sort: (a, b) => a.order - b.order,
                _map: () => ({
                    value: '',
                    label: { _translation: 'text' },
                }),
            },
        }),
    },
};

const Challenge = (props) => {
    const tracking = useTracking();
    const t = useTranslate();
    const formAndVisitor = { ...props.progress.form, ...props.me };
    const [state, setState] = useState(apiResponseToFormState(formAndVisitor, defaultValues));
    const [isSubmitAlertOpen, openSubmitAlert, closeSubmitAlert] = useToggle(false);

    const validators = [
        {
            name: 'contactEmail',
            message: () => t('web.common.error.email'),
            isValid: (value) => !!value.trim() && EmailValidator.validate(value),
        },
        ...state.formData.reduce((result, current, index) => [
            ...result, {
                name: `formData.${index}`,
                message: () => t('web.common.error.fieldRequired'),
                isValid: isValidFormValue,
            },
        ], []),
        {
            name: 'general',
            message: () => t('web.challenge.error.notActive'),
            serverError: 'CHALLENGE_NOT_ACTIVE',
        },
        {
            name: 'general',
            message: () => t('web.challenge.error.notCompleted'),
            serverError: 'CHALLENGE_NOT_COMPLETED',
        },
    ];

    const submit = async () => {
        // TRACKCORE:
        //  event:'submitChallenge', ressource: state.contactEmail, data: {scene: props.sceneName, customer: customerId}
        //  NOTE: props.project.name not available here
        //  HEADER CONTENT: create rally in editor --> go to scene --> go to navi --> click send
        // console.log('%cTRACKCORE: CHALLENGE', 'background: #F24822; color: #fff font-weight: bold;');
        tracking.trackCoreEvent({
            resourceType: 'contactEmail',
            resourceName: state.contactEmail,
            action: 'submitChallenge',
        });
        tracking.trackEvent(props.sceneName, TRACKING_SUBMIT_CHALLENGE, state.contactEmail);

        await props.finishChallenge({
            challengeId: props.progress.id,
            completedItems: props.progress.completedItems,
            contactEmail: state.contactEmail,
            formData: state.formData.map(({ value, id }) => ({
                value: value.toString().trim(),
                fieldId: id,
            })),
        });

        props.onChallengeSubmitted(props.progress.id);
        closeSubmitAlert();
    };

    const renderError = (error) => (
        <Grid row>
            <Grid column size={12}>
                <ErrorAlert>{error}</ErrorAlert>
            </Grid>
        </Grid>
    );

    const renderFormField = (dataItem, index, onChangeByEvent, onChangeByValue, errors) => {
        switch (dataItem.type) {
            case 'CHECKBOX':
                return (
                    <Checkbox
                        checked={!!dataItem.value}
                        key={index}
                        name={`formData.${index}.value`}
                        onChange={onChangeByValue(`formData.${index}.value`)}
                        error={errors[`formData.${index}`]}
                    >
                        <InnerHTML>{t(dataItem.description)}</InnerHTML>
                    </Checkbox>
                );
            case 'DROPDOWN':
                return (
                    <Select
                        key={index}
                        value={dataItem.value}
                        label={t(dataItem.label)}
                        name={`formData.${index}.value`}
                        onChange={onChangeByEvent}
                        options={[...dataItem.options]
                            .sort((a, b) => a.order - b.order)
                            .map((option) => ({
                                label: t(option.label),
                                value: option.value,
                            }))}
                        emptyOption={{ label: t('web.form.chooseDropdownValue') }}
                        error={errors[`formData.${index}`]}
                    />
                );
            case 'TEXT':
            default:
                return (
                    <TextField
                        key={index}
                        type="text"
                        label={t(dataItem.label)}
                        value={dataItem.value}
                        name={`formData.${index}.value`}
                        onChange={onChangeByEvent}
                        error={errors[`formData.${index}`]}
                    />
                );
        }
    };

    const renderSubmitDialog = () => (
        <FormDialog
            isOpen={isSubmitAlertOpen}
            onClose={closeSubmitAlert}
            onConfirm={submit}
            title={t('web.challenge.submit.title')}
            values={state}
            onChange={setState}
            validators={validators}
        >
            {({ errors, onChangeByEvent, onChangeByValue }) => (
                <Grid>

                    {errors.general && renderError(errors.general)}

                    <Grid row>
                        <Grid column size={12}>
                            <InnerHTML>{t(props.progress.submitInstructions) || t('web.challenge.submit.instructions')}</InnerHTML>
                            <br />
                        </Grid>
                    </Grid>

                    <Grid row>
                        <Grid column size={12}>
                            <TextField
                                label={t('web.challenge.email')}
                                value={state.contactEmail}
                                name="contactEmail"
                                onChange={onChangeByEvent}
                                error={errors.contactEmail}
                            />
                            {state.formData.map((dataItem, index) => renderFormField(
                                dataItem,
                                index,
                                onChangeByEvent,
                                onChangeByValue,
                                errors
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </FormDialog>
    );

    const renderChallengePanel = () => (
        <Card
            title={t(props.progress.name) || t('web.header.challenge.title')}
            subtitle={(
                <InnerHTML allowedTags={['br', 'b', 'strong', 'em', 'i', 'a']}>
                    {t(props.progress.description)}
                </InnerHTML>
            )}
            button={
                props.progress.email
                && props.canBeSubmitted
                && renderSubmitButton()
            }
            className="web-project-challenge"
        >
            <div className="web-project-task">
                {props.progress.tasks.map(renderTask)}
            </div>
        </Card>
    );

    const renderTask = (task) => {
        const description = t(task.description);
        return (
            <React.Fragment key={task.id}>
                <div className="web-project-task__name">
                    {t(task.name)}
                    {description && <Help>{description}</Help>}
                </div>
                <div className="web-project-task__progress">
                    <Badge variant={task.completed ? 'primary' : 'secondary'}>
                        {renderProgress(task)}
                    </Badge>
                </div>
            </React.Fragment>
        );
    };

    const renderProgress = (task) => {
        if (task.completed) {
            return <span>&#10003;</span>;
        }
        if (task.count === 1) {
            return <span>&#10007;</span>;
        }
        return `${task.progress}/${task.count}`;
    };

    const renderSubmitButton = () => (
        <Button
            onClick={openSubmitAlert}
            disabled={!props.progress.allowPartialSubmission && !props.progress.completed}
        >
            {t('web.dialog.submit')}
        </Button>
    );

    return (
        <>
            {renderSubmitDialog()}
            {renderChallengePanel()}
        </>
    );
};

Challenge.defaultProps = {
    me: null,
};

Challenge.propTypes = {
    finishChallenge: PropTypes.func.isRequired,
    onChallengeSubmitted: PropTypes.func.isRequired,
    canBeSubmitted: PropTypes.bool.isRequired,
    sceneName: PropTypes.string.isRequired,
    progress: PropTypes.object.isRequired,
    me: PropTypes.object,
};

export default withRouter(ProjectModel.graphql(Challenge, { withoutQuery: true }));
