import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import useTracking from 'hooks/useTracking';
import useTranslate from 'hooks/useTranslate';
import {
    TRACKING_SHOW_EXHIBITOR_LIST, TRACKING_VISIT_EXHIBITOR_FROM_LIST, TRACKING_SEARCH_EXHIBITOR,
} from 'helpers/tracking';
import Link from 'web/atoms/Link';
import TextField from 'web/atoms/TextField';
import Dialog from 'web/molecules/Dialog';

const sponsorOrder = ['STANDARD', 'NONE'];

const ExhibitorList = (props) => {
    const params = useParams();
    const history = useHistory();

    const tracking = useTracking();
    const t = useTranslate();
    const [search, setSearch] = useState('');
    const sortedExhibitors = props.exhibitors
        .filter(({ name, sponsorType, tags }) => (
            sponsorType === 'STANDARD'
            || name.toLowerCase().includes(search.trim().toLowerCase())
            || tags.some((tag) => tag.name.toLowerCase().includes(search.trim().toLowerCase()))
        ))
        .sort((a, b) => (
            sponsorOrder.indexOf(a.sponsorType) - sponsorOrder.indexOf(b.sponsorType)
            || a.name.localeCompare(b.name)
        ));

    useEffect(() => {
        if (props.isOpen) {
            // TRACKCORE:
            //  event:'showExhibitorList', resource: , data: {type: 'Navigation', customer: customerId, scene: name}
            //  HEADER CONTENT: create new exhibitor --> open scene --> open exhibitor list (Ausstellerverzeichnis)
            // console.log('%cTRACKCORE: showExhibitorList', 'background: #00a8ff; font-weight: bold;');
            tracking.trackCoreEvent({
                action: 'showExhibitorList',
                resourceType: '',
                resourceName: '',
                data: [{
                    name: 'type',
                    value: 'Navigation',
                }],
            });
            tracking.trackEvent('Navigation', TRACKING_SHOW_EXHIBITOR_LIST);
        }
    }, [props.isOpen]);

    const goToExhibitorHandler = (exhibitor) => () => {
        // TRACKCORE:
        //  event:'visitExhibitorFromList', resource: exhibitor.name, data: {type: 'Navigation', customer: customerId, scene: name}
        //  HEADER CONTENT: open exhibitor list (Ausstellerverzeichnis) --> click on exhibitor
        // console.log('%cTRACKCORE: visitExhibitorFromList', 'background: #00a8ff; font-weight: bold;');
        tracking.trackCoreEvent({
            action: 'visitExhibitorFromList',
            resourceType: 'name',
            resourceName: exhibitor.name,
            data: [{
                name: 'type',
                value: 'Navigation',
            }],
        });
        tracking.trackEvent('Navigation', TRACKING_VISIT_EXHIBITOR_FROM_LIST, exhibitor.name);
        history.push(`/event/${params.projectPath}/${exhibitor.path}`);
        props.onClose();
    };

    const changeSearch = (event) => {
        setSearch(event.target.value);
    };

    const trackSearch = () => {
        if (search.trim()) {
            // TRACKCORE:
            //  event:'searchExhibitor', resource: search.trim(), data: {navigation: 'Navigation', customer: customerId, scene: name}
            //  HEADER CONTENT: open exhibitor list (Ausstellerverzeichnis) --> search for exhibitor --> click on result
            // console.log('%cTRACKCORE: searchExhibitor', 'background: #00a8ff; font-weight: bold;');
            tracking.trackCoreEvent({
                action: 'searchExhibitor',
                resourceType: 'search',
                resourceName: search.trim(),
                data: [{
                    name: 'type',
                    value: 'Navigation',
                }],
            });
            tracking.trackEvent('Navigation', TRACKING_SEARCH_EXHIBITOR, search.trim());
        }
    };

    return (
        <Dialog
            isOpen={props.isOpen}
            onClose={props.onClose}
            title={t(props.title) || t('web.header.exhibitorList.title')}
        >
            <div className="web-project-exhibitor-list__header">
                <TextField
                    placeholder={t('web.header.exhibitorList.search')}
                    value={search}
                    onChange={changeSearch}
                    onBlur={trackSearch}
                />
            </div>

            {sortedExhibitors.length > 0 ? (
                sortedExhibitors.map((exhibitor) => (
                    <div
                        className={classNames(
                            'web-project-exhibitor-list__item',
                            { 'web-project-exhibitor-list__item--sponsor': exhibitor.sponsorType !== 'NONE' }
                        )}
                        key={exhibitor.id}
                    >
                        <Link onClick={goToExhibitorHandler(exhibitor)}>
                            {exhibitor.name}
                        </Link>
                    </div>
                ))
            ) : (
                t('web.header.exhibitorList.noExhibitors')
            )}
        </Dialog>
    );
};

ExhibitorList.defaultProps = {
    title: null,
};

ExhibitorList.propTypes = {
    title: PropTypes.object,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    exhibitors: PropTypes.array.isRequired,
};

export default ExhibitorList;
