import { ApolloClient, InMemoryCache, HttpLink, from, Observable, split } from '@apollo/client';
import { initAugmentedHooks, setGlobalContextHook } from 'apollo-augmented-hooks';
import { getMainDefinition } from '@apollo/client/utilities';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { WebSocketLink } from '@apollo/client/link/ws';
import gql from 'graphql-tag';
import fetch from 'unfetch';
import BaseModel from 'react-apollo-models';
import useGlobalApolloContext from 'hooks/useGlobalApolloContext';

import {
    getAuthType,
    deleteAuthTokens,
    AUTH_TYPE_ADMIN,
    CUSTOMER_ID,
    AUTH_TYPE_NONE,
    AUTH_TYPE_VISITOR,
    RENEWAL_TYPE_ADMIN,
} from 'auth';
import history from 'browserHistory';
import { resolvers, typeDefs } from './apolloClient/resolvers';

const promiseToObservable = (promise) => (
    new Observable((subscriber) => {
        promise.then(
            (value) => {
                if (subscriber.closed) {
                    return;
                }

                subscriber.next(value);
                subscriber.complete();
            },
            (error) => subscriber.error(error)
        );
    })
);

const cache = new InMemoryCache({
    dataIdFromObject: (object) => object.id,
    // This makes apollo throw an error if cache results are being mutated
    freezeResults: true,
});

const logout = () => {
    deleteAuthTokens();
    window.location.reload();
};

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
        for (const graphQLError of graphQLErrors) {
            if (graphQLError.name) {
                if (graphQLError.name === 'AuthorizationRequiredError') {
                    if (window.location.href.includes('/cms')) {
                        if (window.localStorage.getItem(CUSTOMER_ID)) {
                            history.replace(`/cms/customer/${window.localStorage.getItem(CUSTOMER_ID)}`);
                        } else {
                            logout();
                        }
                        return null;
                    }
                }

                if (graphQLError.name === 'InvalidRenewalTokenError') {
                    window.localStorage.removeItem(RENEWAL_TYPE_ADMIN);

                    if (window.location.href.includes('/cms')) {
                        logout();
                        return null;
                    }

                    window.location.reload();
                    return null;
                }

                if (graphQLError.name === 'TokenExpiredError') {
                    let authType = AUTH_TYPE_ADMIN;

                    if (!window.localStorage.getItem(AUTH_TYPE_ADMIN)) {
                        const match = window.location.pathname.match(/\/event\/([a-zäöüß0-9-]+)/);

                        if (match) {
                            authType = `${AUTH_TYPE_VISITOR}-${match[1]}`;
                        }
                    }

                    if (authType === AUTH_TYPE_ADMIN && window.location.href.includes('/cms')) {
                        const renewalToken = window.localStorage.getItem(
                            RENEWAL_TYPE_ADMIN,
                        );

                        if (!renewalToken) {
                            logout();
                            return null;
                        }

                        try {
                            return promiseToObservable((async () => {
                                const { data } = await client.mutate({
                                    mutation: gql`
                                                mutation renewToken($input: RenewTokenInput!) {
                                                    renewToken(input: $input) {
                                                        identityToken
                                                        renewalToken
                                                    }
                                                }
                                            `,
                                    variables: {
                                        input: { renewalToken },
                                    },
                                    context: {
                                        authType: AUTH_TYPE_NONE,
                                    },
                                });

                                window.localStorage.setItem(
                                    AUTH_TYPE_ADMIN,
                                    data.renewToken.identityToken,
                                );
                                window.localStorage.setItem(
                                    RENEWAL_TYPE_ADMIN,
                                    data.renewToken.renewalToken,
                                );
                            })()).flatMap(() => forward(operation));
                        } catch (error) {
                            logout();
                            return null;
                        }
                    } else { // User is in an event
                        window.localStorage.removeItem(authType);
                        window.location.reload();
                        return null;
                    }
                }

                console.log(`[Custom error]: Name: ${graphQLError.name}, Message: ${graphQLError.message}`);
            } else {
                console.log(`[GraphQL error]: Message: ${graphQLError.message}, Location: ${JSON.stringify(graphQLError.locations)}, Path: ${graphQLError.path}`);
            }
        }
    }

    if (networkError) {
        console.log('[Network error]:', networkError);
    }

    return null;
});

const authLink = setContext((_, context) => {
    const token = context.authType === AUTH_TYPE_NONE
        ? null
        : window.localStorage.getItem(context.authType);
    const headers = { ...context.headers };

    if (token) {
        headers.Authorization = `Bearer ${token}`;
    }

    return { ...context, headers };
});

const httpLink = new HttpLink({
    uri: window.appConfig.apiUrl,
    fetch,
});

const wsLink = new WebSocketLink({
    uri: window.appConfig.apiUrlWs,
    options: {
        reconnect: true,
        connectionParams: () => {
            // This authentication approach is not fully fleshed out yet. For
            // now, we don't really need to authenticate users over websockets,
            // but once this becomes relevant, we might have to rethink what
            // we're doing here.
            //
            // See the "Websocket Authentication" section of `notifications.md`
            // for more information.

            // TODO: Find a proper way to get the path of a project
            const projectPath = window.location.pathname.replace('/event/', '');

            const authType = getAuthType(projectPath);
            const authToken = window.localStorage.getItem(authType);

            return {
                authToken,
            };
        },
    },
});

// Determines if http or ws should be used for request.
const splitLink = split(
    (op) => {
        const definition = getMainDefinition(op.query);
        return (
            definition.kind === 'OperationDefinition'
      && definition.operation === 'subscription'
        );
    },
    wsLink,
    httpLink,
);

const coreHttpLink = new HttpLink({
    uri: window.appConfig.coreApiUrl,
    fetch,
});

const coreCmsSplit = split(
    (op) => {
        const definition = getMainDefinition(op.query);
        console.log('%c[coreCmsSplit]', 'color: #00f', op.query.definitions[0].name.value);
        return (
            definition.kind === 'OperationDefinition'
        && (definition.operation === 'query' || definition.operation === 'mutation')
        && op.query.definitions[0].name.value.includes('core_')
        );
    },
    coreHttpLink,
    splitLink
);

const client = new ApolloClient({
    link: from([
        errorLink,
        authLink,
        coreCmsSplit,
    ]),
    cache,
    typeDefs,
    resolvers,
    // This causes a significant performance improvement in conjunction with `freezeResults`
    // further above
    assumeImmutableResults: true,
    connectToDevTools: true,
});

BaseModel.configure({
    client,
    context: {
        authType: AUTH_TYPE_ADMIN,
    },
});

initAugmentedHooks(client);
setGlobalContextHook(useGlobalApolloContext);

export default client;
