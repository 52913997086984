import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import BsCard from 'react-bootstrap/Card';
import StylingContext from 'contexts/styling';

const Card = (props) => {
    const styling = useContext(StylingContext);

    const renderHead = (title, subtitle) => (
        <BsCard.Body>
            {title && <BsCard.Title>{title}</BsCard.Title>}
            {subtitle}
        </BsCard.Body>
    );

    const renderButton = (button) => (
        <BsCard.Body>
            {button}
        </BsCard.Body>
    );

    return (
        <BsCard
            className={props.className}
            style={{
                color: styling.textColor,
                backgroundColor: styling.backgroundColor,
            }}
        >
            {(props.title || props.subtitle) && renderHead(props.title, props.subtitle)}
            <BsCard.Body>
                {props.children}
            </BsCard.Body>
            {props.button && renderButton(props.button)}
        </BsCard>
    );
};

Card.defaultProps = {
    className: null,
    title: null,
    subtitle: null,
    button: null,
};

Card.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.node,
    button: PropTypes.node,
};

export default Card;
