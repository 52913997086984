import React from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Feedback from 'react-bootstrap/Feedback';

const Select = (props) => (
    <Form.Group className="ui-web-text-field has-fe">
        {props.label && (
            <Form.Label>{props.label}</Form.Label>
        )}

        <Form.Control
            name={props.name}
            type={props.type}
            value={props.value}
            placeholder={props.placeholder}
            onChange={props.onChange}
            isInvalid={!!props.error}
            as="select"
        >
            {props.emptyOption && (<option value={props.emptyOption.id || ''}>{props.emptyOption.label}</option>)}
            {props.options.map((option, index) => (
                <option key={index} value={option.value}>{option.label}</option>
            ))}
        </Form.Control>

        {props.error && (
            <Feedback
                type="invalid"
                className="ui-web-checkbox--feedback"
            >
                {props.error}
            </Feedback>
        )}
    </Form.Group>
);

Select.defaultProps = {
    emptyOption: null,
    type: 'text',
    name: null,
    error: null,
    label: null,
    placeholder: null,
    options: [],
};

Select.propTypes = {
    emptyOption: PropTypes.shape({
        id: PropTypes.string,
        label: PropTypes.string.isRequired,
    }),
    options: PropTypes.array,
    name: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    error: PropTypes.string,
};

export default Select;
