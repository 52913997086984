import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ComposableMap, Geographies, Geography, Marker } from 'react-simple-maps';
import Image from 'Image';
import geoData from './Geography/germany';
import StatList from './shared/StatList';

const GeographyData = (props) => {
    const [currentCity, setCurrentCity] = useState(null);

    const maxClicks = props.cities.reduce((result, current) => (
        Math.max(result, current.totalClicks)
    ), 0);

    const showCityDataHandler = (city) => () => {
        setCurrentCity(city);
    };

    const renderMarker = (city) => {
        const sizeRatio = Math.max(city.totalClicks / maxClicks, 0.1);

        return (
            <Marker
                key={city.label}
                coordinates={[city.longitude, city.latitude]}
                onClick={showCityDataHandler(city)}
                className="cms-scene-statistics-geography__marker"
            >
                <circle r={sizeRatio * 30} />

                {sizeRatio > 0.4 && (
                    <text>{city.totalClicks}</text>
                )}
            </Marker>
        );
    };

    return (
        <>
            <div className="cms-scene-statistics-geography">
                {currentCity && (
                    <div className="cms-scene-statistics-geography__city-name">
                        <div>{currentCity.label}</div>
                        <div>Besuche: {currentCity.totalClicks}</div>
                        <div>Eindeutige Besucher: {currentCity.uniqueClicks}</div>
                    </div>
                )}

                <ComposableMap
                    projection="geoAzimuthalEqualArea"
                    projectionConfig={{
                        rotate: [-10, -51.2, 0],
                        scale: 4200,
                    }}
                >
                    <Geographies geography={geoData}>
                        {({ geographies }) => (
                            geographies
                                .filter(({ properties }) => properties.NAME === 'Germany')
                                .map((geo) => (
                                    <Geography
                                        key={geo.rsmKey}
                                        geography={geo}
                                        className="cms-scene-statistics-geography__country"
                                    />
                                ))
                        )}
                    </Geographies>

                    {[...props.cities].sort((a, b) => a.totalClicks - b.totalClicks).map((city) => (
                        city.latitude && city.longitude && renderMarker(city)
                    ))}
                </ComposableMap>
            </div>

            <StatList
                items={props.countries.map((country) => ({
                    ...country,
                    key: country.label,
                    label: (
                        <>
                            <Image url={`https://matomo.vrtual-x.com/plugins/Morpheus/icons/dist/flags/${country.code}.png`} /> {country.label}
                        </>
                    ),
                }))}
            />
        </>
    );
};

GeographyData.propTypes = {
    cities: PropTypes.array.isRequired,
    countries: PropTypes.array.isRequired,
};

export default GeographyData;
