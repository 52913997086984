import React, { useState, forwardRef, useImperativeHandle, useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import MeContext from 'contexts/me';
import Icon from 'Icon';
import Overlay from 'shared/atoms/Overlay';
import Confirm from 'components/ui/cms/molecules/Confirm';
import Editor from './Overlays/Editor';
import AddButton from './Overlays/AddButton';

const defaultOverlayWidth = 150;
const defaultOverlayHeight = 100;

const Overlays = forwardRef((props, ref) => {
    const [origin, setOrigin] = useState({ x: 0, y: 0 });
    const [isAddButtonVisible, setIsAddButtonVisible] = useState(false);
    const [addButtonX, setAddButtonX] = useState(0);
    const [addButtonY, setAddButtonY] = useState(0);
    const [editorState, setEditorState] = useState({
        isOpen: false,
        item: null,
    });
    const [deleteDialogState, setDeleteDialogState] = useState({
        isOpen: false,
        item: null,
    });
    const deleteDialogStateRef = useRef(deleteDialogState);

    const me = useContext(MeContext);
    let clientDiff;

    // TODO: When `markForDeletion` is called, the `item` field of
    // `deleteDialogState` is set to the index of the item. `handleDelete` then
    // checks if the item is set, and opens the "Confirm deletion" dialog if
    // necessary.
    //
    // For some weird reason `deleteDialogState.item` is always null when
    // calling `handleDelete`, even if it's set in the state of the component.
    // To work around this, we throw the state in a ref and update it everytime
    // the state changes.
    useEffect(() => {
        deleteDialogStateRef.current = deleteDialogState;
    }, [deleteDialogState]);

    useImperativeHandle(ref, () => ({
        onContainerMouseMove: (event) => {
            if (editorState.isOpen || !me.hasWriteAccessToFeature('scene.actions')) {
                return;
            }

            const { left, top, width, height } = props.containerRef.current.getBoundingClientRect();
            const x = (event.clientX - Math.floor(left)) / width;
            const y = (event.clientY - Math.floor(top)) / height;

            const defaultOverlayIsOutOfBounds = x - (defaultOverlayWidth / 2 / width) < 0
                || x + (defaultOverlayWidth / 2 / width) > 1
                || y - (defaultOverlayHeight / 2 / height) < 0
                || y + (defaultOverlayHeight / 2 / height) > 1;

            if (defaultOverlayIsOutOfBounds || deleteDialogState.item !== null) {
                setIsAddButtonVisible(false);
                return;
            }

            setIsAddButtonVisible(true);
            setAddButtonX(x);
            setAddButtonY(y);
        },
        onContainerMouseLeave: () => {
            setIsAddButtonVisible(false);
        },
    }));

    const onOverlayMouseEnter = () => {
        setIsAddButtonVisible(false);
    };

    const onOverlayMouseMove = (event) => {
        event.stopPropagation();
    };

    const rememberOriginHandler = (x, y) => (event) => {
        const { left, top } = event.target.getBoundingClientRect();

        setOrigin({ x, y });
        clientDiff = {
            x: event.clientX - left,
            y: event.clientY - top,
        };
    };

    const calculatePosition = (event) => {
        const { left, top, width, height } = props.containerRef.current.getBoundingClientRect();
        const x = (event.clientX - left - clientDiff.x) / width;
        const y = (event.clientY - top - clientDiff.y) / height;

        return { x, y };
    };

    const isOutOfBounds = (objWidth, objHeight, boundX, boundY) => boundX + objWidth > 1
        || boundX < 0
        || boundY + objHeight > 1
        || boundY < 0;

    const changePositionHandler = (index) => (event) => {
        const { x, y } = calculatePosition(event);
        const overlay = props.overlays[index];

        markForDeletion(isOutOfBounds(overlay.width, overlay.height, x, y) ? index : null);
        props.onSave('overlays', index, { x, y });
    };

    const resizeHandler = (index) => (size) => {
        props.onSave('overlays', index, size);
    };

    const openEditorHandler = (index) => () => {
        setEditorState({
            isOpen: true,
            item: index,
        });
    };

    const closeEditor = () => {
        setEditorState({
            ...editorState,
            isOpen: false,
        });
        setIsAddButtonVisible(false);
    };

    const markForDeletion = (index) => {
        setDeleteDialogState({
            ...deleteDialogState,
            item: index,
        });
    };

    const openDeleteDialog = () => {
        setDeleteDialogState((previous) => ({
            ...previous,
            isOpen: true,
        }));
    };

    const handleDelete = () => {
        if (deleteDialogStateRef.current.item !== null) {
            openDeleteDialog();
        }
    };

    const closeDeleteDialog = () => {
        props.onSave('overlays', deleteDialogState.item, origin);
        setDeleteDialogState({
            isOpen: false,
            item: null,
        });
    };

    const deleteOverlay = () => {
        props.onDelete('overlays', deleteDialogState.item);
        setDeleteDialogState({
            isOpen: false,
            item: null,
        });
    };

    // The background container needs to be present before the overlays should
    // be rendered
    if (!props.containerRef.current) {
        return <></>;
    }

    return (
        <div
            className={classNames(
                'cms-overlays-editor',
                { 'cms-overlays-editor--add-visible': isAddButtonVisible }
            )}
        >
            <AddButton
                onClick={openEditorHandler(null)}
                x={addButtonX}
                y={addButtonY}
            />

            {props.overlays.map((overlay, index) => (
                deleteDialogState.item === index ? (
                    <div
                        key={index}
                        className="cms-overlays-editor__delete"
                        style={{
                            transform: `translate(${overlay.x * props.containerRef.current.offsetWidth}px, ${overlay.y * props.containerRef.current.offsetHeight}px)`,
                            width: overlay.width * props.containerRef.current.offsetWidth,
                            height: overlay.height * props.containerRef.current.offsetHeight,
                        }}
                    >
                        <Icon type="delete" />
                    </div>
                ) : (
                    <div key={index} className="cms-overlays-editor__overlay">
                        <Overlay
                            onMouseEnter={onOverlayMouseEnter}
                            onMouseMove={onOverlayMouseMove}
                            onClick={me.hasWriteAccessToFeature('scene.actions') ? openEditorHandler(index) : null}
                            onDragStart={rememberOriginHandler(overlay.x, overlay.y)}
                            onDrag={me.hasWriteAccessToFeature('scene.actions') ? changePositionHandler(index) : null}
                            onDragEnd={handleDelete}
                            onResize={resizeHandler(index)}
                            width={overlay.width}
                            height={overlay.height}
                            containerWidth={
                                props.containerRef.current
                                    ? props.containerRef.current.offsetWidth
                                    : 0
                            }
                            containerHeight={
                                props.containerRef.current
                                    ? props.containerRef.current.offsetHeight
                                    : 0
                            }
                            style={{
                                /* left and top for initial render (props.containerRef isn't there
                                yet) */
                                left: !props.containerRef.current ? `${overlay.x * 100}%` : null,
                                top: !props.containerRef.current ? `${overlay.y * 100}%` : null,
                                /* transform for each subsequent render because it is faster to
                                update with drag and drop */
                                transform: props.containerRef.current
                                    ? `translate(${overlay.x * props.containerRef.current.offsetWidth}px, ${overlay.y * props.containerRef.current.offsetHeight}px)`
                                    : null,
                            }}
                            overlay={overlay}
                            placeholder
                        />
                    </div>
                )
            ))}

            <Editor
                overlays={props.overlays}
                overlay={editorState.item}
                isOpen={editorState.isOpen}
                onClose={closeEditor}
                onSave={props.onSave}
                addButtonX={addButtonX}
                addButtonY={addButtonY}
                backgroundWidth={props.containerRef.current.clientWidth}
                backgroundHeight={props.containerRef.current.clientHeight}
                projectFiles={props.projectFiles}
                sceneFiles={props.sceneFiles}
            />

            <Confirm
                title="Soll dieses Overlay wirklich gelöscht werden?"
                onConfirm={deleteOverlay}
                confirmLabel="Ja, löschen"
                onCancel={closeDeleteDialog}
                cancelLabel="Nein, abbrechen"
                isOpen={deleteDialogState.isOpen}
                destructive
            />
        </div>
    );
});

Overlays.propTypes = {
    overlays: PropTypes.array.isRequired,
    onSave: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    projectFiles: PropTypes.array.isRequired,
    sceneFiles: PropTypes.array.isRequired,
    containerRef: PropTypes.object.isRequired,
};

export default Overlays;
