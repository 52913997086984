import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import FontRatioContext from 'contexts/fontRatio';

const FontRatio = (props) => {
    const referenceWidth = useContext(FontRatioContext) || document.body.offsetWidth;
    const fontSize = referenceWidth * props.value;
    const padding = `0 ${fontSize}px`;

    return (
        <div style={{ fontSize, padding }}>
            {props.children}
        </div>
    );
};

FontRatio.propTypes = {
    value: PropTypes.number.isRequired,
    children: PropTypes.string.isRequired,
};

export default FontRatio;
