import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useLocalStorage } from '@rehooks/local-storage';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { AUTH_TYPE_ADMIN, RENEWAL_TYPE_ADMIN, CUSTOMER_ID } from 'auth';
import LoginModel from 'models/cms/login';
import TextField from 'cms/atoms/TextField';
import Button from 'cms/atoms/Button';
import Loader from 'cms/atoms/Loader';
import Icon from 'Icon';

const Login = (props) => {
    const [authTypeAdmin, setAuthTypeAdmin] = useLocalStorage(AUTH_TYPE_ADMIN);
    const [, setRenewalTypeAdmin] = useLocalStorage(RENEWAL_TYPE_ADMIN);
    const [customerId, setCustomerId] = useLocalStorage(CUSTOMER_ID);
    const history = useHistory();
    const [state, setState] = useState({
        email: '',
        password: '',
    });
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (authTypeAdmin) {
            if (customerId) {
                history.replace(`/cms/customer/${customerId}`);
            } else {
                history.replace('/cms');
            }
        }
    }, []);

    const changeValue = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked || event.target.value });
    };

    const login = async () => {
        setLoading(true);

        try {
            const response = await props.createToken(state);
            const { identityToken, renewalToken, customer } = response.data.createToken;

            setAuthTypeAdmin(identityToken);
            setRenewalTypeAdmin(renewalToken);

            if (customer) {
                setCustomerId(customer.id);
                history.replace(`/cms/customer/${customer.id}`);
            } else {
                history.replace('/cms');
            }
        } catch ({ graphQLErrors }) {
            setLoading(false);

            if (graphQLErrors && graphQLErrors.some(({ name }) => name === 'InvalidCredentialsError')) {
                setErrors({ password: 'Die Zugangsdaten sind inkorrekt.' });
            } else {
                setErrors({ password: 'Ein unbekannter Fehler ist aufgetreten. Bitte versuche es später erneut.' });
            }
        }
    };

    return (
        <div
            className={classNames(
                'cms-login',
                { 'cms-login--loading': loading }
            )}
        >
            <div className="cms-login__content">
                <div className="cms-login__icon">
                    <Icon type="lock" />
                </div>

                <TextField
                    label="E-Mail-Adresse"
                    name="email"
                    value={state.email}
                    onChange={changeValue}
                    error={errors.email}
                />

                <TextField
                    label="Passwort"
                    name="password"
                    value={state.password}
                    onChange={changeValue}
                    onKeyDown={(e) => e.key === 'Enter' && login()}
                    error={errors.password}
                    type="password"
                />

                <Button onClick={login}>Login</Button>
            </div>

            <div className="cms-login__loader">
                <Loader />
            </div>
        </div>
    );
};

Login.propTypes = {
    createToken: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
};

export default LoginModel.graphql(Login);
