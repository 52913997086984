export const isReadEnabled = ({ behavior }) => {
    switch (behavior) {
        case 'WRITE_IMPLIES_READ':
            return false;
        case 'ONLY_WRITE_ALWAYS_READ':
            return false;
        case 'ONLY_READ_NEVER_WRITE':
            return true;
        case 'WRITE_REQUIRES_READ': // fall through
        default:
            return true;
    }
};

export const isWriteEnabled = ({ behavior, readAccess }) => {
    switch (behavior) {
        case 'WRITE_IMPLIES_READ':
            return true;
        case 'ONLY_WRITE_ALWAYS_READ':
            return true;
        case 'ONLY_READ_NEVER_WRITE':
            return false;
        case 'WRITE_REQUIRES_READ': // fall through
        default:
            return readAccess;
    }
};

export const isReadChecked = ({ behavior, readAccess, writeAccess }) => {
    switch (behavior) {
        case 'WRITE_IMPLIES_READ':
            return writeAccess;
        case 'ONLY_WRITE_ALWAYS_READ':
            return true;
        case 'ONLY_READ_NEVER_WRITE':
            return readAccess;
        case 'WRITE_REQUIRES_READ': // fall through
        default:
            return readAccess;
    }
};

export const isWriteChecked = ({ behavior, readAccess, writeAccess }) => {
    switch (behavior) {
        case 'WRITE_IMPLIES_READ':
            return writeAccess;
        case 'ONLY_WRITE_ALWAYS_READ':
            return writeAccess;
        case 'ONLY_READ_NEVER_WRITE':
            return false;
        case 'WRITE_REQUIRES_READ': // fall through
        default:
            return readAccess && writeAccess;
    }
};
