import { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocalStorage } from '@rehooks/local-storage';
import axios from 'axios';
import AuthContext from 'contexts/auth';

const Download = (props) => {
    const [downloadProgress, setDownloadProgress] = useState(null);
    const [cancelSource] = useState(axios.CancelToken.source());
    const [authToken] = useLocalStorage(useContext(AuthContext));

    useEffect(() => () => {
        // Cancel download if the component is unmounted
        cancelSource.cancel();
    }, []);

    const download = async () => {
        let { file } = props;

        if (props.fetchUrl) {
            file = await props.fetchUrl();

            if (!file) {
                return [null, null];
            }
        }

        setDownloadProgress(0);

        try {
            const response = await axios.get(file.url, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
                responseType: 'blob',
                cancelToken: cancelSource.token,
                onDownloadProgress: ({ loaded, total }) => {
                    const ratio = loaded / total;

                    setDownloadProgress(ratio);
                },
            });

            // Wait a bit before resetting the progress bar
            window.setTimeout(() => setDownloadProgress(null), 500);

            return [file, response.data];
        } catch (error) {
            if (!axios.isCancel(error)) {
                throw error;
            }
        }

        return [null, null];
    };

    const onClick = () => {
        if (downloadProgress !== null) {
            return;
        }

        download().then(([file, blob]) => {
            if (!file) {
                return;
            }

            props.onDownload(blob);
        });
    };

    return props.children(onClick, downloadProgress);
};

Download.defaultProps = {
    file: null,
    onDownload: () => null,
    fetchUrl: null,
};

Download.propTypes = {
    children: PropTypes.func.isRequired,
    file: PropTypes.object,
    onDownload: PropTypes.func,
    fetchUrl: PropTypes.func,
};

export default Download;
