import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';

const Loader = (props) => (
    <div className="ui-cms-loader">
        <CircularProgress
            className="ui-cms-loader"
            variant={props.value !== null ? 'determinate' : 'indeterminate'}
            value={props.value !== null ? props.value * 100 : null}
        />

        {props.value !== null && (
            <div className="ui-cms-loader__text">
                {Math.round(props.value * 100)}%
            </div>
        )}
    </div>
);

Loader.defaultProps = {
    value: null,
};

Loader.propTypes = {
    value: PropTypes.number,
};

export default Loader;
