import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import mediaUrl from 'helpers/mediaUrl';
import MeContext from 'contexts/me';
import Image from 'Image';
import Icon from 'Icon';
import Button from 'cms/atoms/Button';
import Headline from 'cms/atoms/Headline';
import Confirm from 'components/ui/cms/molecules/Confirm';
import Grid from 'cms/molecules/Grid';
import Card from 'cms/molecules/Card';
import useDeleteSceneVersion from 'hooks/graphql/mutations/deleteSceneVersion';
import SceneVersionEditor from '../editors/SceneVersion';

const SceneVersions = (props) => {
    const me = useContext(MeContext);
    const deleteSceneVersion = useDeleteSceneVersion();

    const [sceneVersionEditorState, setSceneVersionEditorState] = useState({
        isOpen: false,
        versionId: null,
    });
    const [deleteVersionDialogState, setDeleteVersionDialogState] = useState(null);

    const openSceneVersionEditorHandler = (versionId) => () => {
        setSceneVersionEditorState({
            isOpen: true,
            versionId,
        });
    };

    const closeSceneVersionEditor = () => {
        setSceneVersionEditorState({
            ...sceneVersionEditorState,
            isOpen: false,
        });
    };

    const openDeleteVersionDialogHandler = (versionId) => (event) => {
        event.stopPropagation();
        setDeleteVersionDialogState(versionId);
    };

    const closeDeleteVersionDialog = () => {
        setDeleteVersionDialogState(null);
    };

    const deleteVersion = () => {
        deleteSceneVersion(deleteVersionDialogState);
        closeDeleteVersionDialog();
    };

    const renderVersion = (version) => (
        <Grid item size={4} key={version.id}>
            <Card
                headerLabel={version.name}
                onClick={openSceneVersionEditorHandler(version.id)}
                image={<Image url={mediaUrl(version.background, 'thumbnail')} />}
            >
                <div className="cms-scene__version">
                    <div className="cms-scene__version-time">
                        {`${moment(version.startTime).format('L, HH:mm')} Uhr - ${moment(version.endTime).format('L, HH:mm')} Uhr`}
                    </div>

                    {me.hasWriteAccessToFeature('scene.delete') && (
                        <div
                            className="cms-scene__version-delete"
                            onClick={openDeleteVersionDialogHandler(version.id)}
                        >
                            <Icon type="delete" />
                        </div>
                    )}
                </div>
            </Card>
        </Grid>
    );

    return (
        <>
            <Headline
                action={me.hasWriteAccessToFeature('scene.versions.new') ? (
                    <Button
                        icon="add"
                        onClick={openSceneVersionEditorHandler(null)}
                    >
                        Neue Version
                    </Button>
                ) : null}
            >
                Zeitgesteuerte Versionen
            </Headline>

            <SceneVersionEditor
                version={
                    sceneVersionEditorState.versionId
                        ? props.scene.versions.find(({ id }) => (
                            id === sceneVersionEditorState.versionId
                        ))
                        : null
                }
                isOpen={sceneVersionEditorState.isOpen}
                onClose={closeSceneVersionEditor}
                scene={props.scene}
                buttons={props.buttons}
                projectButtons={props.scene.project.buttons}
                scenes={props.scene.project.scenes}
                challenge={props.scene.project.challenge}
                projectFiles={props.scene.project.files}
                accessGroups={props.scene.project.accessGroups}
                loginType={props.scene.project.loginType}
                project={props.scene.project}
            />

            <Grid>
                {
                    [...props.scene.versions]
                        .sort((a, b) => moment(a.startTime).diff(b.startTime))
                        .map(renderVersion)
                }
            </Grid>

            <Confirm
                title="Soll diese Version wirklich gelöscht werden?"
                onConfirm={deleteVersion}
                confirmLabel="Ja, löschen"
                onCancel={closeDeleteVersionDialog}
                cancelLabel="Nein, abbrechen"
                isOpen={deleteVersionDialogState !== null}
                destructive
            />
        </>
    );
};

SceneVersions.propTypes = {
    scene: PropTypes.object.isRequired,
    buttons: PropTypes.array.isRequired,
};

export default SceneVersions;
