import React from 'react';
import PropTypes from 'prop-types';
import EmptyMessage from 'cms/atoms/EmptyMessage';

const MatomoErrorHandler = (props) => {
    const hasError = (error) => (
        props.error?.graphQLErrors?.some(({ message }) => message === error)
    );

    if (hasError('MATOMO_NO_SEGMENT') || hasError('MATOMO_SEGMENT_NOT_READY')) {
        return (
            <EmptyMessage>
                Die Statistik-Daten werden noch verarbeitet. Bitte versuche es später erneut.
            </EmptyMessage>
        );
    }

    if (hasError('MATOMO_REQUEST_FAILED')) {
        return (
            <EmptyMessage>
                Die Statistik-Daten konnten leider nicht abgerufen werden. Bitte versuche es später
                erneut.
            </EmptyMessage>
        );
    }

    if (props.statistics && props.statistics.totalClicks === 0) {
        return (
            <EmptyMessage>
                Es gibt für diesen Zeitraum keine Statistik-Daten.
            </EmptyMessage>
        );
    }

    return props.children();
};

MatomoErrorHandler.defaultProps = {
    error: null,
    statistics: null,
};

MatomoErrorHandler.propTypes = {
    error: PropTypes.object,
    statistics: PropTypes.object,
    children: PropTypes.func.isRequired,
};

export default MatomoErrorHandler;
