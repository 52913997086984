import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import LocaleContext from 'contexts/locale';
import MeContext from 'contexts/me';
import exposedVariables from 'helpers/exposedVariables';
import { ensureFormStateValues } from 'helpers/form';
import { defaultOverlayFormState } from 'helpers/overlays';
import { hasRequiredTranslation } from 'helpers/languages';
import Icon from 'Icon';
import TextField from 'cms/atoms/TextField';
import TranslationField from 'cms/molecules/TranslationField';
import Select from 'cms/atoms/Select';
import Switch from 'cms/atoms/Switch';
import TranslationCodeInput from 'cms/molecules/TranslationCodeInput';
import TranslationMediaSelect from 'cms/molecules/TranslationMediaSelect';
import VariableList from 'cms/atoms/VariableList';
import Dialog from 'cms/molecules/Dialog';
import KeyValueTable from 'cms/molecules/KeyValueTable';

const Editor = (props) => {
    const defaultOverlayWidth = 150.0 / props.backgroundWidth;
    const defaultOverlayHeight = 100.0 / props.backgroundHeight;

    const defaultValues = {
        ...defaultOverlayFormState(),
        x: props.addButtonX - defaultOverlayWidth / 2,
        y: props.addButtonY - defaultOverlayHeight / 2,
        width: defaultOverlayWidth,
        height: defaultOverlayHeight,
    };

    const localeContext = useContext(LocaleContext);
    const me = useContext(MeContext);
    const [state, setState] = useState(
        ensureFormStateValues(props.overlays[props.overlay], defaultValues)
    );

    const typeSpecificValidators = () => {
        switch (state.type) {
            case 'IMAGE':
                return [{
                    name: 'imageData.file',
                    message: () => 'Bitte wähle eine Datei für die Standard-Sprache aus.',
                    isValid: (value) => hasRequiredTranslation(value, localeContext.defaultLanguage.id),
                }];

            case 'VIDEO':
                return [{
                    name: 'videoData.url',
                    message: () => 'Bitte gib eine Video-URL für die Standard-Sprache ein.',
                    isValid: (value) => hasRequiredTranslation(value, localeContext.defaultLanguage.id),
                }];

            case 'NEWSTICKER':
                return [{
                    name: 'newstickerData.text',
                    message: () => 'Bitte gib einen Text für die Standard-Sprache ein.',
                    isValid: (value) => hasRequiredTranslation(value, localeContext.defaultLanguage.id),
                }];

            case 'HTML':
                return [{
                    name: 'htmlData.html',
                    message: () => 'Bitte gib einen eigenen Code für die Standard-Sprache ein.',
                    isValid: (value) => hasRequiredTranslation(value, localeContext.defaultLanguage.id),
                }];

            default:
                return [];
        }
    };

    const validators = [{
        name: 'name',
        message: () => 'Bitte gib einen Namen ein.',
        isValid: (value) => !!value.trim(),
    }, {
        name: 'type',
        message: () => 'Bitte wähle eine Funktion aus.',
        isValid: (value) => !!value,
    }, ...typeSpecificValidators()];

    useEffect(() => {
        if (props.isOpen) {
            setState(
                ensureFormStateValues(props.overlays[props.overlay], defaultValues)
            );
        }
    }, [props.isOpen]);

    const saveOverlay = () => {
        props.onSave('overlays', props.overlay, state);
        props.onClose();
    };

    const typeSpecificOptions = (errors, onChangeByEvent, onChangeByValue) => {
        switch (state.type) {
            case 'IMAGE':
                return [{
                    key: 'Bild',
                    value: (
                        <TranslationMediaSelect
                            label="Datei"
                            name="imageData.file"
                            value={state.imageData.file}
                            onChange={onChangeByValue('imageData.file')}
                            error={errors['imageData.file']}
                            projectFiles={props.projectFiles}
                            sceneFiles={props.sceneFiles}
                            mimeType="image"
                        />
                    ),
                    align: 'top',
                }];

            case 'VIDEO':
                return [{
                    key: 'Video-URL',
                    value: (
                        <TranslationField
                            name="videoData.url"
                            value={state.videoData.url}
                            onChange={onChangeByValue('videoData.url')}
                            error={errors['videoData.url']}
                        />
                    ),
                }, {
                    key: 'Automatisch starten',
                    value: (
                        <Switch
                            name="videoData.autoplay"
                            checked={state.videoData.autoplay}
                            onChange={onChangeByEvent}
                        />
                    ),
                }, {
                    key: 'Schleife',
                    value: (
                        <Switch
                            name="videoData.loop"
                            checked={state.videoData.loop}
                            onChange={onChangeByEvent}
                        />
                    ),
                }, {
                    key: 'Stumm',
                    value: (
                        <Switch
                            name="videoData.mute"
                            checked={state.videoData.mute}
                            onChange={onChangeByEvent}
                        />
                    ),
                }, {
                    key: 'Steuerelemente',
                    value: (
                        <Select
                            name="videoData.controls"
                            value={state.videoData.controls}
                            onChange={onChangeByEvent}
                            items={[
                                { label: 'Ohne', value: 'NONE' },
                                { label: 'Nur Vollbild', value: 'FULLSCREEN' },
                                { label: 'Alle', value: 'NATIVE' },
                            ]}
                            error={errors['videoData.controls']}
                        />
                    ),
                }];

            case 'NEWSTICKER':
                return [{
                    key: 'Text',
                    value: (
                        <TranslationCodeInput
                            value={state.newstickerData.text}
                            onChange={onChangeByValue('newstickerData.text')}
                            error={errors['newstickerData.text']}
                            projectFiles={props.projectFiles}
                            sceneFiles={props.sceneFiles}
                        />
                    ),
                    align: 'top',
                    help: (
                        <div>
                            <p>Der Text kann folgendermaßen formatiert werden:</p>
                            <p>Fett: Lorem ipsum &lt;strong&gt;dolor&lt;/strong&gt; sit amet.</p>
                            <p>Kursiv: Lorem ipsum &lt;em&gt;dolor&lt;/em&gt; sit amet.</p>
                            <p>
                                Link: Lorem ipsum &lt;a
                                href=&quot;https:&#47;&#47;www.url.com&quot;&gt;dolor&lt;/a&gt;
                                sit amet.
                            </p>
                        </div>
                    ),
                }];

            case 'HTML':
                return [{
                    key: 'Inhalt',
                    value: (
                        <TranslationCodeInput
                            value={state.htmlData.html}
                            onChange={onChangeByValue('htmlData.html')}
                            error={errors['htmlData.html']}
                            projectFiles={props.projectFiles}
                            sceneFiles={props.sceneFiles}
                        />
                    ),
                    help: <VariableList items={exposedVariables} />,
                    align: 'top',
                    disabled: !me.hasWriteAccessToFeature('overlay.html'),
                }];

            default:
                return [];
        }
    };

    return (
        <Dialog
            title={props.overlay === null ? 'Neues Overlay erstellen' : 'Overlay bearbeiten'}
            isOpen={props.isOpen}
            onClose={props.onClose}
            onConfirm={saveOverlay}
            confirmLabel="Fertig"
            onChange={setState}
            values={state}
            validators={validators}
        >
            {({ errors, onChangeByEvent, onChangeByValue }) => (
                <KeyValueTable
                    items={[
                        {
                            key: 'Name',
                            value: (
                                <TextField
                                    name="name"
                                    value={state.name}
                                    onChange={onChangeByEvent}
                                    error={errors.name}
                                />
                            ),
                        },
                        {
                            key: 'Mobil verfügbar',
                            value: (
                                <Switch
                                    name="mobile"
                                    checked={state.mobile}
                                    onChange={onChangeByEvent}
                                />
                            ),
                        },
                        {
                            key: 'Funktion',
                            value: (
                                <Select
                                    name="type"
                                    value={state.type}
                                    onChange={onChangeByEvent}
                                    items={[
                                        {
                                            label: 'Bild',
                                            value: 'IMAGE',
                                            image: <Icon type="image" />,
                                        },
                                        {
                                            label: 'Video',
                                            value: 'VIDEO',
                                            image: <Icon type="video" />,
                                        },
                                        {
                                            label: 'Newsticker',
                                            value: 'NEWSTICKER',
                                            image: <Icon type="newsticker" />,
                                        },
                                        {
                                            label: 'Eigener Code',
                                            value: 'HTML',
                                            image: <Icon type="code" />,
                                        },
                                    ]}
                                    error={errors.type}
                                />
                            ),
                        },
                        ...typeSpecificOptions(errors, onChangeByEvent, onChangeByValue),
                    ]}
                />
            )}
        </Dialog>
    );
};

Editor.defaultProps = {
    overlay: null,
};

Editor.propTypes = {
    overlay: PropTypes.number,
    isOpen: PropTypes.bool.isRequired,
    overlays: PropTypes.array.isRequired,
    onSave: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    addButtonX: PropTypes.number.isRequired,
    addButtonY: PropTypes.number.isRequired,
    backgroundWidth: PropTypes.number.isRequired,
    backgroundHeight: PropTypes.number.isRequired,
    projectFiles: PropTypes.array.isRequired,
    sceneFiles: PropTypes.array.isRequired,
};

export default React.memo(Editor, (prevProps, nextProps) => (
    prevProps.isOpen === nextProps.isOpen
));
