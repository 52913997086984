import React, { useState } from 'react';
import moment from 'moment';
import Headline from 'cms/atoms/Headline';
import DateTimePicker from 'cms/atoms/DateTimePicker';
import Grid from 'cms/molecules/Grid';
import { trackingNotice } from 'features';
import Alert from 'components/ui/cms/atoms/Alert';
import Content from './Statistics/Content';

const Statistics = () => {
    const [startDate, setStartDate] = useState(moment().subtract(1, 'day'));
    const [endDate, setEndDate] = useState(moment().subtract(1, 'day'));

    const renderNoticeBanner = () => (
        <Alert title="Hinweis" severity="info">
            Wir haben aufgrund einer Gesetzesänderung zum Datenschutz (siehe § 25
            TTDSG) unser Tracking angepasst. Die Statistiken stellen aktuell nur noch einen groben
            Richtwert über die Aktivitäten auf der Plattform dar und liefern keine exakten Werte.
            Genauere Informationen findest du <a target="_blank" rel="noreferrer" href="https://vrtual-x.com/fileshare/CMS/Statistik-Info/Statistik-Info.pdf">hier</a>.
        </Alert>
    );

    return (
        <>
            <Headline>Statistik</Headline>
            {trackingNotice.isEnabled() && renderNoticeBanner()}

            <Grid>
                <Grid item size={6}>
                    <DateTimePicker
                        label="von"
                        value={startDate}
                        onChange={setStartDate}
                        onlyDate
                        disableFuture
                    />
                </Grid>

                <Grid item size={6}>
                    <DateTimePicker
                        label="bis"
                        value={endDate}
                        onChange={setEndDate}
                        onlyDate
                        disableFuture
                    />
                </Grid>
            </Grid>

            <Content
                startDate={startDate.toISOString()}
                endDate={endDate.toISOString()}
            />
        </>
    );
};

export default Statistics;
