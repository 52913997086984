import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import MeContext from 'contexts/me';
import LocaleContext from 'contexts/locale';
import useTranslate from 'hooks/useTranslate';
import exposedVariables from 'helpers/exposedVariables';
import { formFieldTypes, defaultFormFieldType } from 'helpers/formFields';
import mediaUrl from 'helpers/mediaUrl';
import {
    emptyTranslation, emptyMediaTranslation,
    hasRequiredTranslation, hasOptionalTranslation,
    findMediaId,
} from 'helpers/languages';
import { findLoginType } from 'helpers/loginTypes';
import { ensureFormStateValues } from 'helpers/form';
import { defaultActionFormState, actionTypes } from 'helpers/actions';
import Image from 'Image';
import Button from 'cms/atoms/Button';
import LabelList from 'cms/atoms/LabelList';
import TextField from 'cms/atoms/TextField';
import FormFieldOptionEditor from 'FormFieldOptionEditor';
import TranslationCodeInput from 'cms/molecules/TranslationCodeInput';
import TranslationMediaSelect from 'cms/molecules/TranslationMediaSelect';
import Switch from 'cms/atoms/Switch';
import CheckboxGroup from 'cms/atoms/CheckboxGroup';
import Select from 'cms/atoms/Select';
import VariableList from 'cms/atoms/VariableList';
import Dialog from 'cms/molecules/Dialog';
import EditorList from 'cms/molecules/EditorList';
import Grid from 'cms/molecules/Grid';
import KeyValueTable from 'cms/molecules/KeyValueTable';
import { findContactEntryType } from 'helpers/contactEntryTypes';
import TranslationField from 'cms/molecules/TranslationField';
import ContactSectionEditor from './Editor/ContactSectionEditor';

const Editor = (props) => {
    const defaultValues = {
        ...defaultActionFormState(),
        x: props.addButtonX,
        y: props.addButtonY,
    };
    const localeContext = useContext(LocaleContext);
    const t = useTranslate();
    const [state, setState] = useState(
        ensureFormStateValues(props.actions[props.action], defaultValues)
    );
    const [
        contactSectionEditorState,
        setContactSectionEditorState,
    ] = useState({ isOpen: false, index: null });

    const [
        formFieldOptionEditorState,
        setFormFieldOptionEditorState,
    ] = useState({
        isOpen: false,
        index: null,
    });

    const params = useParams();

    const me = useContext(MeContext);

    const typeSpecificValidators = () => {
        switch (state.type) {
            case 'SCENE':
                return [{
                    name: 'sceneData.sceneId',
                    message: () => 'Bitte wähle eine Szene aus.',
                    isValid: (value) => !!value,
                }];

            case 'EXHIBITOR':
                return [{
                    name: 'exhibitorData.sceneId',
                    message: () => 'Bitte wähle eine Szene aus.',
                    isValid: (value) => !!value,
                }];

            case 'INFOBOX':
                return [{
                    name: 'infoboxData.title',
                    message: () => 'Bitte gib einen Titel für die Standard-Sprache ein.',
                    isValid: (value) => hasRequiredTranslation(value, localeContext.defaultLanguage.id),
                }, {
                    name: 'infoboxData.content',
                    message: () => 'Bitte gib einen Inhalt für die Standard-Sprache ein.',
                    isValid: (value) => hasRequiredTranslation(value, localeContext.defaultLanguage.id),
                }];

            case 'GALLERY':
                return [
                    {
                        name: 'galleryData.title',
                        message: () => 'Bitte gib ebenfalls einen Titel für die Standard-Sprache ein.',
                        isValid: (value) => hasOptionalTranslation(value, localeContext.defaultLanguage.id),
                    },
                    {
                        name: 'galleryData.images',
                        message: () => 'Bitte füge mindestens ein Bild hinzu.',
                        isValid: (value) => value.length > 0,
                    },
                    ...state.galleryData.images.reduce((result, image, index) => [
                        ...result,
                        {
                            name: `galleryData.images.${index}.file`,
                            message: () => 'Bitte wähle ein Bild für die Standard-Sprache aus.',
                            isValid: (value) => hasRequiredTranslation(value, localeContext.defaultLanguage.id),
                        },
                        {
                            name: `galleryData.images.${index}.description`,
                            message: () => 'Bitte gib ebenfalls eine Beschreibung für die Standard-Sprache ein.',
                            isValid: (value) => hasOptionalTranslation(value, localeContext.defaultLanguage.id),
                        },
                    ], []),
                ];

            case 'CONTACT':
                return [
                    {
                        name: 'contactData.title',
                        message: () => 'Bitte gib ebenfalls einen Titel für die Standard-Sprache ein.',
                        isValid: (value) => hasOptionalTranslation(value, localeContext.defaultLanguage.id),
                    },
                    {
                        name: 'contactData.sections',
                        message: () => 'Bitte füge mindestens einen Abschnitt hinzu.',
                        isValid: (value) => value.length > 0,
                    },
                    ...state.contactData.sections.reduce((result, section, index) => [
                        ...result,
                        {
                            name: `contactData.sections.${index}.image`,
                            message: () => 'Bitte wähle ebenfalls eine Datei für die Standard-Sprache aus.',
                            isValid: (value) => hasOptionalTranslation(value, localeContext.defaultLanguage.id),
                        },
                    ], []),
                ];

            case 'DOWNLOAD':
                return [
                    {
                        name: 'downloadData.title',
                        message: () => 'Bitte gib ebenfalls einen Titel für die Standard-Sprache ein.',
                        isValid: (value) => hasOptionalTranslation(value, localeContext.defaultLanguage.id),
                    },
                    {
                        name: 'downloadData.downloads',
                        message: () => 'Bitte füge mindestens eine Datei hinzu.',
                        isValid: (value) => value.length > 0,
                    },
                    ...state.downloadData.downloads.reduce((result, download, index) => [
                        ...result,
                        {
                            name: `downloadData.downloads.${index}.label`,
                            message: () => 'Bitte gib ebenfalls einen Namen für die Standard-Sprache ein.',
                            isValid: (value) => hasOptionalTranslation(value, localeContext.defaultLanguage.id),
                        },
                        {
                            name: `downloadData.downloads.${index}.description`,
                            message: () => 'Bitte gib ebenfalls einen Beschreibung für die Standard-Sprache ein.',
                            isValid: (value) => hasOptionalTranslation(value, localeContext.defaultLanguage.id),
                        },
                        {
                            name: `downloadData.downloads.${index}.file`,
                            message: () => 'Bitte wähle eine Datei für die Standard-Sprache aus.',
                            isValid: (value) => hasRequiredTranslation(value, localeContext.defaultLanguage.id),
                        },
                    ], []),
                ];

            case 'VIDEO':
                return [
                    {
                        name: 'videoData.title',
                        message: () => 'Bitte gib ebenfalls einen Titel für die Standard-Sprache ein.',
                        isValid: (value) => hasOptionalTranslation(value, localeContext.defaultLanguage.id),
                    },
                    {
                        name: 'videoData.items',
                        message: () => 'Bitte füge mindestens ein Video hinzu.',
                        isValid: (value) => value.length > 0,
                    },
                    ...state.videoData.items.reduce((result, item, index) => [
                        ...result,
                        {
                            name: `videoData.items.${index}.url`,
                            message: () => 'Bitte gib eine URL für die Standard-Sprache ein.',
                            isValid: (value) => hasRequiredTranslation(value, localeContext.defaultLanguage.id),
                        },
                        {
                            name: `videoData.items.${index}.label`,
                            message: () => 'Bitte gib ebenfalls einen Beschriftung für die Standard-Sprache ein.',
                            isValid: (value) => hasOptionalTranslation(value, localeContext.defaultLanguage.id),
                        },
                    ], []),
                ];

            case 'EXTERNAL':
                return [{
                    name: 'externalData.url',
                    message: () => 'Bitte gib eine URL für die Standard-Sprache ein.',
                    isValid: (value) => hasRequiredTranslation(value, localeContext.defaultLanguage.id),
                }];

            case 'AUDIO':
                return [{
                    name: 'audioData.file',
                    message: () => 'Bitte wähle eine Datei für die Standard-Sprache aus.',
                    isValid: (value) => hasRequiredTranslation(value, localeContext.defaultLanguage.id),
                }, {
                    name: 'audioData.stopIconMediaId',
                    message: () => 'Bitte wähle ein Stop-Icon aus.',
                    isValid: (value) => value && !!value.trim(),
                }];

            case 'CALLING_CARD':
                return [{
                    name: 'callingCardData.contactEmail',
                    message: () => 'Bitte gib eine E-Mail-Adresse ein.',
                    isValid: (value) => value && !!value.trim(),
                }, {
                    name: 'callingCardData.contactInstructions',
                    message: () => 'Bitte gib ebenfalls eine Anleitung für die Standard-Sprache ein.',
                    isValid: (value) => hasOptionalTranslation(value, localeContext.defaultLanguage.id),
                }, {
                    name: 'callingCardData.contactTitle',
                    message: () => 'Bitte gib ebenfalls einen Titel für die Standard-Sprache ein.',
                    isValid: (value) => hasOptionalTranslation(value, localeContext.defaultLanguage.id),
                },
                ...state.callingCardData.form.fields.reduce((result, field, index) => [
                    ...result,
                    {
                        name: `callingCardData.form.fields.${index}.label`,
                        message: () => 'Bitte gib eine Beschriftung für die Standard-Sprache ein.',
                        isValid: (value) => hasRequiredTranslation(value, localeContext.defaultLanguage.id),
                    },
                    {
                        name: `callingCardData.form.fields.${index}.description`,
                        message: () => 'Bitte gib ebenfalls eine Beschreibung für die Standard-Sprache ein.',
                        isValid: (value) => hasOptionalTranslation(value, localeContext.defaultLanguage.id),
                    },
                ], []),
                ];

            default:
                return [];
        }
    };

    const validators = [{
        name: 'name',
        message: () => 'Bitte gib einen Namen für die Standard-Sprache ein.',
        isValid: (value) => hasRequiredTranslation(value, localeContext.defaultLanguage.id),
    }, {
        name: 'buttonId',
        message: () => 'Bitte wähle einen Button aus.',
        isValid: (value) => !!value,
    }, {
        name: 'type',
        message: () => 'Bitte wähle eine Funktion aus.',
        isValid: (value) => !!value,
    }, ...typeSpecificValidators()];

    useEffect(() => {
        if (props.isOpen) {
            setState(
                ensureFormStateValues(props.actions[props.action], defaultValues)
            );
        }
    }, [props.isOpen]);

    const changeButtonHandler = (onChangeByEvent) => (event) => {
        onChangeByEvent(event);
        setState((previous) => ({
            ...previous,
            type: previous.type,
        }));
    };

    const addEditorListItemHandler = (dataType, type, data) => () => {
        setState((previous) => ({
            ...previous,
            [dataType]: {
                ...previous[dataType],
                [type]: [
                    data,
                    ...previous[dataType][type],
                ],
            },
        }));
    };

    const removeEditorListItemHandler = (dataType, type) => (index) => {
        setState((previous) => ({
            ...previous,
            [dataType]: {
                ...previous[dataType],
                [type]: [
                    ...previous[dataType][type].slice(0, index),
                    ...previous[dataType][type].slice(index + 1),
                ],
            },
        }));
    };

    const changeEditorListItemOrderHandler = (dataType, type) => (newIndex, oldIndex) => {
        const items = [...state[dataType][type]];
        const movedItem = items[oldIndex];

        items.splice(oldIndex, 1);
        items.splice(newIndex, 0, movedItem);

        setState({
            ...state,
            [dataType]: {
                ...state[dataType],
                [type]: items,
            },
        });
    };

    const addCallingCardFormField = () => {
        setState((previous) => ({
            ...previous,
            callingCardData: {
                ...previous.callingCardData,
                form: {
                    ...previous.callingCardData.form,
                    fields: [
                        ...previous.callingCardData.form.fields,
                        {
                            label: emptyTranslation,
                            mandatory: false,
                            type: defaultFormFieldType,
                            description: emptyTranslation,
                            options: [],
                        },
                    ],
                },
            },
        }));
    };

    const removeCallingCardFormField = (index) => {
        setState((previous) => ({
            ...previous,
            callingCardData: {
                ...previous.callingCardData,
                form: {
                    ...previous.callingCardData.form,
                    fields: [
                        ...previous.callingCardData.form.fields.slice(0, index),
                        ...previous.callingCardData.form.fields.slice(index + 1),
                    ],
                },
            },
        }));
    };

    const changeFormFieldOrder = (newIndex, oldIndex) => {
        const fields = [...state.callingCardData.form.fields];
        const movedField = fields[oldIndex];

        fields.splice(oldIndex, 1);
        fields.splice(newIndex, 0, movedField);

        setState({
            ...state,
            callingCardData: {
                ...state.callingCardData,
                form: {
                    ...state.callingCardData.form,
                    fields,
                },
            },
        });
    };

    const handleOpenFormFieldOptionEditor = (index) => () => {
        setFormFieldOptionEditorState({ isOpen: true, index });
    };

    const handleCloseFormFieldOptionEditor = () => {
        setFormFieldOptionEditorState({ ...formFieldOptionEditorState, isOpen: false });
    };

    const handleSaveFormFieldOptionEditor = (options) => {
        setState((previous) => {
            const formFields = previous.callingCardData.form.fields;
            return {
                ...previous,
                callingCardData: {
                    ...previous.callingCardData,
                    form: {
                        ...previous.callingCardData.form,
                        fields: [
                            ...previous.callingCardData.form.fields.slice(0, formFieldOptionEditorState.index),
                            {
                                ...formFields[formFieldOptionEditorState.index],
                                options,
                            },
                            ...previous.callingCardData.form.fields.slice(formFieldOptionEditorState.index + 1),
                        ],
                    },

                },
            };
        });
    };

    const renderFormFields = (errors, onChangeByEvent, onChangeByValue) => (
        <>
            <EditorList
                addLabel="Formularfeld hinzufügen"
                onAdd={addCallingCardFormField}
                removeLabel="Formularfeld löschen"
                onRemove={removeCallingCardFormField}
                onSort={changeFormFieldOrder}
                sortModeContents={state.callingCardData.form.fields.map((field) => t(field.label) || 'Keine Bezeichnung')}
            >
                {state.callingCardData.form.fields.map((field, index) => (
                    <Grid key={index}>
                        <Grid item size={12}>
                            <TranslationField
                                label="Beschriftung"
                                name={`callingCardData.form.fields.${index}.label`}
                                value={field.label}
                                onChange={onChangeByValue(`callingCardData.form.fields.${index}.label`)}
                                error={errors[`callingCardData.form.fields.${index}.label`]}
                            />
                        </Grid>
                        <Grid item size={12}>
                            <Switch
                                label="Erforderlich"
                                name={`callingCardData.form.fields.${index}.mandatory`}
                                checked={field.mandatory}
                                onChange={onChangeByEvent}
                            />
                        </Grid>
                        <Grid item size={12}>
                            <Select
                                name={`callingCardData.form.fields.${index}.type`}
                                label="Typ"
                                value={field.type}
                                onChange={onChangeByEvent}
                                items={formFieldTypes}
                            />
                        </Grid>

                        {field.type === 'CHECKBOX' && (
                            <Grid item size={12}>
                                <TranslationCodeInput
                                    label="Beschreibung"
                                    value={field.description}
                                    onChange={onChangeByValue(`callingCardData.form.fields.${index}.description`)}
                                    error={errors[`callingCardData.form.fields.${index}.description`]}
                                />
                            </Grid>
                        )}

                        {field.type === 'DROPDOWN' && (
                            <Grid item size={12}>
                                <Button
                                    icon="edit"
                                    onClick={handleOpenFormFieldOptionEditor(index)}
                                >
                                    Werte Bearbeiten
                                </Button>
                                <LabelList
                                    labels={
                                        field.options.map((option) => ({
                                            label: t(option.label),
                                        }))
                                    }
                                />
                            </Grid>
                        )}
                    </Grid>
                ))}
            </EditorList>
            <FormFieldOptionEditor
                isOpen={formFieldOptionEditorState.isOpen}
                onClose={handleCloseFormFieldOptionEditor}
                onSave={handleSaveFormFieldOptionEditor}
                field={
                    state
                        .callingCardData.form
                        ?.fields[formFieldOptionEditorState.index]
                }
            />
        </>
    );

    const saveAction = () => {
        props.onSave('actions', props.action, state);
        props.onClose();
    };

    const handleOpenContactSectionEditor = (index) => () => {
        setContactSectionEditorState({ isOpen: true, index });
    };

    const handleCloseContactSectionEditor = () => {
        setContactSectionEditorState({ ...contactSectionEditorState, isOpen: false });
    };

    const handleSaveContactSectionEditor = (entries) => {
        setState((previous) => {
            const sections = previous.contactData.sections;
            return {
                ...previous,
                contactData: {
                    ...previous.contactData,
                    sections: [
                        ...previous.contactData.sections.slice(0, contactSectionEditorState.index),
                        {
                            ...sections[contactSectionEditorState.index],
                            entries,
                        },
                        ...previous.contactData.sections.slice(contactSectionEditorState.index + 1),
                    ],
                },
            };
        });
    };

    const renderFiles = (label, name, value, onChange, error, filter = () => true) => (
        <Select
            label={label}
            name={name}
            value={value}
            onChange={onChange}
            items={[
                { label: 'Projektdateien' },
                ...[...props.projectFiles]
                    .sort((a, b) => (
                        a.file.filename.localeCompare(b.file.filename)
                    ))
                    .filter(filter)
                    .map(({ file }) => ({
                        label: file.filename,
                        value: file.id,
                    })),
                { label: 'Szenendateien' },
                ...[...props.sceneFiles]
                    .sort((a, b) => (
                        a.file.filename.localeCompare(b.file.filename)
                    ))
                    .filter(filter)
                    .map(({ file }) => ({
                        label: file.filename,
                        value: file.id,
                    })),
            ]}
            error={error}
        />
    );

    const renderContactSection = (section, index, onChangeByValue, errors) => (
        <React.Fragment key={section.id || index}>
            <TranslationMediaSelect
                label="Bild"
                name={`contactData.sections.${index}.image`}
                value={section.image}
                onChange={onChangeByValue(`contactData.sections.${index}.image`)}
                error={errors[`contactData.sections.${index}.image`]}
                mimeType="image"
                projectFiles={props.projectFiles}
                sceneFiles={props.sceneFiles}
                allowEmpty
            />

            <Button
                icon="edit"
                onClick={handleOpenContactSectionEditor(index)}
            >
                Einträge verwalten
            </Button>

            <LabelList
                labels={(
                    section.entries.map((entry) => ({
                        label: findContactEntryType(entry.type).label,
                    }))
                )}
            />
        </React.Fragment>
    );

    const typeSpecificOptions = (errors, onChangeByEvent, onChangeByValue) => {
        switch (state.type) {
            case 'SCENE':
                return [{
                    key: 'Szene',
                    value: (
                        <Select
                            name="sceneData.sceneId"
                            value={state.sceneData.sceneId}
                            onChange={onChangeByEvent}
                            items={
                                props.scenes
                                    .filter(({ type, id }) => (
                                        type === 'SCENE' && params.id !== id
                                    ))
                                    .map((scene) => ({
                                        label: scene.name,
                                        value: scene.id,
                                    }))
                                    .sort((a, b) => a.label.localeCompare(b.label))
                            }
                            error={errors['sceneData.sceneId']}
                        />
                    ),
                }];

            case 'EXHIBITOR':
                return [{
                    key: 'Aussteller',
                    value: (
                        <Select
                            name="exhibitorData.sceneId"
                            value={state.exhibitorData.sceneId}
                            onChange={onChangeByEvent}
                            items={
                                props.scenes
                                    .filter(({ type, id }) => (
                                        type === 'EXHIBITOR' && params.id !== id
                                    ))
                                    .map((scene) => ({
                                        label: scene.name,
                                        value: scene.id,
                                    }))
                                    .sort((a, b) => a.label.localeCompare(b.label))
                            }
                            error={errors['exhibitorData.sceneId']}
                        />
                    ),
                }];

            case 'INFOBOX':
                return [{
                    key: 'Titel',
                    value: (
                        <TranslationField
                            name="infoboxData.title"
                            value={state.infoboxData.title}
                            onChange={onChangeByValue('infoboxData.title')}
                            error={errors['infoboxData.title']}
                        />
                    ),
                    disabled: !me.hasWriteAccessToFeature('action.infoBox'),
                }, {
                    key: 'Inhalt',
                    value: (
                        <TranslationCodeInput
                            value={state.infoboxData.content}
                            onChange={onChangeByValue('infoboxData.content')}
                            error={errors['infoboxData.content']}
                            projectFiles={props.projectFiles}
                            sceneFiles={props.sceneFiles}
                        />
                    ),
                    help: (
                        <VariableList
                            items={exposedVariables}
                            replacements={[
                                {
                                    replacement: 'externalId',
                                    description: 'Login-ID eines externen Anbieters',
                                },
                            ]}
                        />
                    ),
                    align: 'top',
                    disabled: !me.hasWriteAccessToFeature('action.infoBox'),
                }];

            case 'GALLERY':
                return [{
                    key: 'Titel',
                    value: (
                        <TranslationField
                            name="galleryData.title"
                            value={state.galleryData.title}
                            onChange={onChangeByValue('galleryData.title')}
                            error={errors['galleryData.title']}
                        />
                    ),
                }, {
                    key: 'Bilder',
                    value: (
                        <EditorList
                            addLabel="Bild hinzufügen"
                            onAdd={addEditorListItemHandler('galleryData', 'images', {
                                file: emptyMediaTranslation,
                                description: emptyTranslation,
                            })}
                            removeLabel="Bild entfernen"
                            onRemove={removeEditorListItemHandler('galleryData', 'images')}
                            onSort={changeEditorListItemOrderHandler('galleryData', 'images')}
                            sortModeContents={state.galleryData.images.map((image) => {
                                const mediaId = findMediaId(image.file, localeContext.language.id);
                                const foundFile = [...props.projectFiles, ...props.sceneFiles]
                                    .find(({ file }) => file.id === mediaId);
                                return foundFile ? foundFile.file.filename : 'Keine Datei';
                            })}
                            error={errors['galleryData.images']}
                        >
                            {state.galleryData.images.map((image, index) => (
                                <React.Fragment key={image.id || index}>
                                    <TranslationMediaSelect
                                        label="Bilddatei"
                                        name={`galleryData.images.${index}.file`}
                                        value={image.file}
                                        onChange={onChangeByValue(`galleryData.images.${index}.file`)}
                                        error={errors[`galleryData.images.${index}.file`]}
                                        mimeType="image"
                                        projectFiles={props.projectFiles}
                                        sceneFiles={props.sceneFiles}
                                    />
                                    <TranslationField
                                        label="Beschreibung"
                                        name={`galleryData.images.${index}.description`}
                                        value={image.description}
                                        onChange={onChangeByValue(`galleryData.images.${index}.description`)}
                                        error={errors[`galleryData.images.${index}.description`]}
                                    />
                                </React.Fragment>
                            ))}
                        </EditorList>
                    ),
                    align: 'top',
                }];

            case 'CONTACT':
                return [
                    {
                        key: 'Titel',
                        value: (
                            <TranslationField
                                name="contactData.title"
                                value={state.contactData.title}
                                onChange={onChangeByValue('contactData.title')}
                                error={errors['contactData.title']}
                            />
                        ),
                    },
                    {
                        key: 'Abschnitte',
                        value: (
                            <>
                                <EditorList
                                    addLabel="Abschnitt hinzufügen"
                                    removeLabel="Abschnitt entfernen"
                                    onRemove={removeEditorListItemHandler('contactData', 'sections')}
                                    onAdd={addEditorListItemHandler('contactData', 'sections', {
                                        imageMediaId: '',
                                        entries: [],
                                    })}
                                    sortModeContents={state.contactData.sections.map((section) => {
                                        const entryLabels = section.entries
                                            .map((entry) => findContactEntryType(entry.type).label);
                                        return [
                                            section.image || section.imageMediaId ? 'Bild' : null,
                                            ...entryLabels,
                                        ].filter(Boolean).join(', ');
                                    })}
                                    onSort={changeEditorListItemOrderHandler('contactData', 'sections')}
                                    error={errors['contactData.sections']}
                                >
                                    {state.contactData.sections.map((section, index) => (
                                        renderContactSection(
                                            section,
                                            index,
                                            onChangeByValue,
                                            errors
                                        )
                                    ))}
                                </EditorList>
                                <ContactSectionEditor
                                    isOpen={contactSectionEditorState.isOpen}
                                    onClose={handleCloseContactSectionEditor}
                                    onSave={handleSaveContactSectionEditor}
                                    section={
                                        state.contactData.sections[contactSectionEditorState.index]
                                    }
                                />
                            </>
                        ),
                        align: 'top',
                    },
                ];

            case 'DOWNLOAD':
                return [{
                    key: 'Titel',
                    value: (
                        <TranslationField
                            name="downloadData.title"
                            value={state.downloadData.title}
                            onChange={onChangeByValue('downloadData.title')}
                            error={errors['downloadData.title']}
                        />
                    ),
                }, {
                    key: 'Dateien',
                    value: (
                        <EditorList
                            addLabel="Datei hinzufügen"
                            onAdd={addEditorListItemHandler('downloadData', 'downloads', {
                                file: emptyMediaTranslation,
                                thumbnailMediaId: '',
                                label: emptyTranslation,
                                description: emptyTranslation,
                            })}
                            removeLabel="Datei entfernen"
                            onRemove={removeEditorListItemHandler('downloadData', 'downloads')}
                            onSort={changeEditorListItemOrderHandler('downloadData', 'downloads')}
                            sortModeContents={state.downloadData.downloads.map((download) => {
                                const mediaId = findMediaId(download.file, localeContext.language.id);
                                const foundFile = [...props.projectFiles, ...props.sceneFiles]
                                    .find(({ file }) => file.id === mediaId);
                                return foundFile ? foundFile.file.filename : 'Keine Datei';
                            })}
                            error={errors['downloadData.downloads']}
                        >
                            {state.downloadData.downloads.map((download, index) => (
                                <React.Fragment key={download.id || index}>
                                    <TranslationMediaSelect
                                        label="Datei"
                                        name={`downloadData.downloads.${index}.file`}
                                        value={download.file}
                                        onChange={onChangeByValue(`downloadData.downloads.${index}.file`)}
                                        error={errors[`downloadData.downloads.${index}.file`]}
                                        projectFiles={props.projectFiles}
                                        sceneFiles={props.sceneFiles}
                                    />

                                    {renderFiles(
                                        'Vorschau',
                                        `downloadData.downloads.${index}.thumbnailMediaId`,
                                        download.thumbnailMediaId || '',
                                        onChangeByEvent,
                                        errors[`downloadData.downloads.${index}.thumbnailMediaId`],
                                        ({ file }) => file.mimeType.startsWith('image')
                                    )}

                                    <TranslationField
                                        label="Name"
                                        name={`downloadData.downloads.${index}.label`}
                                        value={download.label}
                                        onChange={onChangeByValue(`downloadData.downloads.${index}.label`)}
                                        error={errors[`downloadData.downloads.${index}.label`]}
                                    />

                                    <TranslationField
                                        label="Beschreibung"
                                        name={`downloadData.downloads.${index}.description`}
                                        value={download.description}
                                        onChange={onChangeByValue(`downloadData.downloads.${index}.description`)}
                                        error={errors[`downloadData.downloads.${index}.description`]}
                                    />
                                </React.Fragment>
                            ))}
                        </EditorList>
                    ),
                    align: 'top',
                }];

            case 'VIDEO':
                return [{
                    key: 'Titel',
                    value: (
                        <TranslationField
                            name="videoData.title"
                            value={state.videoData.title}
                            onChange={onChangeByValue('videoData.title')}
                            error={errors['videoData.title']}
                        />
                    ),
                }, {
                    key: 'Videos',
                    value: (
                        <EditorList
                            addLabel="Video hinzufügen"
                            onAdd={addEditorListItemHandler('videoData', 'items', {
                                url: emptyTranslation,
                                label: emptyTranslation,
                            })}
                            removeLabel="Video entfernen"
                            onRemove={removeEditorListItemHandler('videoData', 'items')}
                            onSort={changeEditorListItemOrderHandler('videoData', 'items')}
                            sortModeContents={state.videoData.items.map((item) => (
                                t(item.label) || t(item.url) || 'Keine URL'
                            ))}
                            error={errors['videoData.items']}
                        >
                            {state.videoData.items.map((item, index) => (
                                <React.Fragment key={item.id || index}>
                                    <TranslationField
                                        label="URL"
                                        name={`videoData.items.${index}.url`}
                                        value={item.url}
                                        onChange={onChangeByValue(`videoData.items.${index}.url`)}
                                        error={errors[`videoData.items.${index}.url`]}
                                    />

                                    <TranslationField
                                        label="Beschriftung"
                                        name={`videoData.items.${index}.label`}
                                        value={item.label}
                                        onChange={onChangeByValue(`videoData.items.${index}.label`)}
                                        error={errors[`videoData.items.${index}.label`]}
                                    />
                                </React.Fragment>
                            ))}
                        </EditorList>
                    ),
                    align: 'top',
                }];

            case 'EXTERNAL':
                return [{
                    key: 'URL',
                    help: (
                        <div>
                            Folgender Platzhalter wird unterstützt:<br />
                            {
                                // eslint-disable-next-line no-template-curly-in-string
                                'https://www.example.com?contactId=${eventsairContactId}'
                            }
                        </div>
                    ),
                    value: (
                        <TranslationField
                            name="externalData.url"
                            value={state.externalData.url}
                            onChange={onChangeByValue('externalData.url')}
                            error={errors['externalData.url']}
                        />
                    ),
                }, {
                    key: 'In neuem Tab öffnen',
                    value: (
                        <Switch
                            name="externalData.openInNewTab"
                            checked={state.externalData.openInNewTab}
                            onChange={onChangeByEvent}
                        />
                    ),
                }];

            case 'LOGIN':
                return [{
                    key: 'Automatisch ausblenden',
                    help: 'Der Login-Button wird nur für ausgeloggte Benutzer angezeigt und verschwindet nach dem Login.',
                    value: (
                        <Switch
                            name="loginData.hideWhenLoggedIn"
                            checked={state.loginData.hideWhenLoggedIn}
                            onChange={onChangeByEvent}
                        />
                    ),
                }];

            case 'AUDIO':
                return [{
                    key: 'Audio-Datei',
                    value: (
                        <TranslationMediaSelect
                            label="Audio-Datei"
                            name="audioData.file"
                            value={state.audioData.file}
                            onChange={onChangeByValue('audioData.file')}
                            error={errors['audioData.file']}
                            mimeType="audio"
                            projectFiles={props.projectFiles}
                            sceneFiles={props.sceneFiles}
                        />
                    ),
                }, {
                    key: 'Stop-Icon',
                    value: renderFiles(
                        'Stop-Icon',
                        'audioData.stopIconMediaId',
                        state.audioData.stopIconMediaId,
                        onChangeByEvent,
                        errors['audioData.stopIconMediaId'],
                        ({ file }) => file.mimeType.startsWith('image')
                    ),
                }];

            case 'CALLING_CARD':
                return [{
                    key: 'Dialog-Titel',
                    value: (
                        <TranslationField
                            name="callingCardData.contactTitle"
                            label="Dialog-Titel"
                            value={state.callingCardData.contactTitle}
                            onChange={onChangeByValue('callingCardData.contactTitle')}
                            error={errors['callingCardData.contactTitle']}
                        />
                    ),
                }, {
                    key: 'E-Mail-Adresse',
                    value: (
                        <TextField
                            name="callingCardData.contactEmail"
                            label="E-Mail-Adresse"
                            value={state.callingCardData.contactEmail || ''}
                            onChange={onChangeByEvent}
                            error={errors['callingCardData.contactEmail']}
                        />
                    ),
                }, {
                    key: 'Anleitung',
                    value: (
                        <TranslationField
                            name="callingCardData.contactInstructions"
                            label="Anleitung"
                            value={state.callingCardData.contactInstructions}
                            onChange={onChangeByValue('callingCardData.contactInstructions')}
                            error={errors['callingCardData.contactInstructions']}
                        />
                    ),
                }, {
                    key: 'E-Mail-Betreff',
                    value: (
                        <TextField
                            name="callingCardData.contactSubject"
                            label="E-Mail-Betreff"
                            value={state.callingCardData.contactSubject}
                            onChange={onChangeByEvent}
                            error={errors['callingCardData.contactSubject']}
                        />
                    ),
                }, {
                    key: 'Visitenkarten-Formular',
                    value: renderFormFields(errors, onChangeByEvent, onChangeByValue),
                    align: 'top',
                },
                ];

            default:
                return [];
        }
    };

    return (
        <Dialog
            title={props.action === null ? 'Neue Aktion erstellen' : 'Aktion bearbeiten'}
            isOpen={props.isOpen}
            onClose={props.onClose}
            onConfirm={saveAction}
            confirmLabel="Fertig"
            onChange={setState}
            values={state}
            validators={validators}
        >
            {({ errors, onChangeByEvent, onChangeByValue }) => (
                <KeyValueTable
                    items={[
                        {
                            key: 'Name',
                            value: (
                                <TranslationField
                                    name="name"
                                    value={state.name}
                                    onChange={onChangeByValue('name')}
                                    error={errors.name}
                                />
                            ),
                        },
                        {
                            key: 'Aktiv',
                            value: (
                                <Switch
                                    name="active"
                                    checked={state.active}
                                    onChange={onChangeByEvent}
                                />
                            ),
                        },
                        {
                            key: 'Mobil verfügbar',
                            value: (
                                <Switch
                                    name="mobile"
                                    checked={state.mobile}
                                    onChange={onChangeByEvent}
                                />
                            ),
                            available: !props.isPanoramaChildScene,
                        },
                        {
                            key: 'Mit Beschriftung',
                            value: (
                                <Switch
                                    name="withLabel"
                                    checked={state.withLabel}
                                    onChange={onChangeByEvent}
                                />
                            ),
                        },
                        {
                            key: 'Button',
                            value: (
                                <Select
                                    name="buttonId"
                                    value={state.buttonId}
                                    onChange={changeButtonHandler(onChangeByEvent)}
                                    items={[
                                        { label: 'Default-Buttons' },
                                        ...[...props.buttons]
                                            .sort((a, b) => (
                                                a.name.localeCompare(b.name)
                                            ))
                                            .map((button) => ({
                                                label: button.name,
                                                value: button.id,
                                                image: <Image url={mediaUrl(button.image, 'mini')} />,
                                            })),
                                        { label: 'Projekt-Buttons' },
                                        ...[...props.projectButtons]
                                            .sort((a, b) => (
                                                a.name.localeCompare(b.name)
                                            ))
                                            .map((button) => ({
                                                label: button.name,
                                                value: button.id,
                                                image: <Image url={mediaUrl(button.image, 'mini')} />,
                                            })),
                                    ]}
                                    error={errors.buttonId}
                                />
                            ),
                        },
                        {
                            key: 'Funktion',
                            value: (
                                <Select
                                    name="type"
                                    value={state.type}
                                    onChange={onChangeByEvent}
                                    items={actionTypes}
                                    error={errors.type}
                                />
                            ),
                        },
                        ...typeSpecificOptions(errors, onChangeByEvent, onChangeByValue),
                        {
                            key: 'Zugriffsbeschränkungen',
                            value: (
                                <CheckboxGroup
                                    value={state.restrictions}
                                    onChange={onChangeByValue('restrictions')}
                                    items={
                                        props.accessGroups
                                            .map(({ id, name }) => ({
                                                label: name,
                                                value: id,
                                            }))
                                    }
                                />
                            ),
                            available: (
                                !props.isPanoramaChildScene
                                && props.loginType
                                && props.accessGroups.length > 0
                                && findLoginType(props.loginType).withAccessGroups
                                && state.type !== 'LOGIN'
                            ),
                            align: 'top',
                            disabled: !me.hasWriteAccessToFeature('action.accessGroups'),
                        },
                        {
                            key: 'Aufgaben für Rally',
                            value: (
                                <CheckboxGroup
                                    value={state.tasks}
                                    onChange={onChangeByValue('tasks')}
                                    items={
                                        [...((props.challenge && props.challenge.tasks) || [])]
                                            .sort((a, b) => a.order - b.order)
                                            .map(({ id, name }) => ({
                                                label: t(name),
                                                value: id,
                                            }))
                                    }
                                />
                            ),
                            available: !!props.challenge && props.challenge.tasks.length > 0,
                            align: 'top',
                        },
                    ]}
                />
            )}
        </Dialog>
    );
};

Editor.defaultProps = {
    action: null,
    loginType: null,
    challenge: null,
};

Editor.propTypes = {
    action: PropTypes.number,
    isOpen: PropTypes.bool.isRequired,
    actions: PropTypes.array.isRequired,
    buttons: PropTypes.array.isRequired,
    projectButtons: PropTypes.array.isRequired,
    scenes: PropTypes.array.isRequired,
    onSave: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    addButtonX: PropTypes.number.isRequired,
    addButtonY: PropTypes.number.isRequired,
    projectFiles: PropTypes.array.isRequired,
    sceneFiles: PropTypes.array.isRequired,
    loginType: PropTypes.string,
    accessGroups: PropTypes.array.isRequired,
    challenge: PropTypes.object,
    isPanoramaChildScene: PropTypes.bool.isRequired,
};

export default React.memo(Editor, (prevProps, nextProps) => (
    prevProps.isOpen === nextProps.isOpen
));
