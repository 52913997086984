import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import StylingContext from 'contexts/styling';
import Link from 'web/atoms/Link';

const Header = (props) => {
    const styling = useContext(StylingContext);

    const [activeDropdown, setActiveDropdown] = useState(null);

    const onDropdownToggled = (item) => (show) => (
        setActiveDropdown(show ? item.label : null)
    );

    const applyDropdownArrowStyle = () => (node) => {
        // can't access the arrow style directly, so we use a callback ref
        if (node) {
            const arrow = node.querySelector('.dropdown-toggle');
            if (arrow) {
                arrow.style.color = styling.textColor;
            }
        }
    };

    const renderDropdown = (item, index) => (
        <NavDropdown
            key={index}
            title={item.label}
            rootCloseEvent="none"
            active={item.label === activeDropdown}
            ref={applyDropdownArrowStyle(item)}
            onToggle={onDropdownToggled(item)}
        >
            {item.dropdownContent}
        </NavDropdown>
    );

    const renderLink = (item, index) => (
        <Link
            key={index}
            onClick={item.onClick}
            active={item.active}
            style={{
                color: styling.textColor,
                fontWeight: item.active ? 'bold' : 'normal',
            }}
        >
            {item.label}
        </Link>
    );

    const renderNavbar = (fixed) => (
        <Navbar
            className="ui-web-header"
            variant="dark"
            expand={props.transparent ? '' : 'lg'}
            fixed={fixed ? 'top' : null}
            style={{
                backgroundColor: props.transparent ? '#0000000' : styling.backgroundColor,
            }}
            expanded={props.navbarExpanded}
        >
            {props.infoArea}

            <Container className="ui-web-header__content">
                <Navbar.Brand
                    className="ui-web-header__logo"
                    onClick={props.onLogoClick}
                >
                    {props.logo}
                </Navbar.Brand>
                <Navbar.Text>
                    {props.sceneVersionSelection}
                </Navbar.Text>
                {props.items.length > 0 ? (
                    <Navbar.Toggle
                        aria-controls="basic-navbar-nav"
                        onClick={props.toggleNavbarState}
                    />
                ) : null}

                <Navbar.Collapse>
                    <Nav
                        className="ml-auto"
                        style={{
                            backgroundColor: styling.backgroundColor,
                        }}
                    >
                        {props.items.map((item, index) => (
                            item.dropdownContent
                                ? renderDropdown(item, index)
                                : renderLink(item, index)
                        ))}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );

    return (
        <>
            {/* The second navbar is a hacky way to keep the height of a sticky
            navbar while its position is fixed. If the navbar is transparent,
            we don't need this workaround */}
            {!props.transparent && renderNavbar(false)}
            {renderNavbar(true)}
        </>
    );
};

Header.defaultProps = {
    infoArea: null,
    logo: null,
    items: [],
    sceneVersionSelection: null,
    transparent: false,
};

Header.propTypes = {
    infoArea: PropTypes.node,
    logo: PropTypes.node,
    onLogoClick: PropTypes.func.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        onClick: PropTypes.func,
        dropdownContent: PropTypes.node,
    })),
    sceneVersionSelection: PropTypes.node,
    navbarExpanded: PropTypes.bool.isRequired,
    toggleNavbarState: PropTypes.func.isRequired,
    transparent: PropTypes.bool,
};

export default Header;
