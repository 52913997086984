import { useQuery } from 'apollo-augmented-hooks';

export const ROLE = `
    id
    name
    features {
        id
        name
        writeAccess
        readAccess
        behavior
    }
`;

export const ADMIN = `
    id
    firstName
    lastName
    account {
        id
        email
    }
    isSuperAdmin
    isBaseAdmin
    isCustomerAdmin
    fullProjectAccess
    fullSceneAccess
    accessibleProjects {
        id
    }
    accessibleScenes {
        id
    }
    role {
        ${ROLE}
    }
    customer {
        id
    }
    avatar {
        id
        url
        mini: variation(type: MINI) {
            url
        }
    }
`;

const query = `
    query admins {
        admins {
            ${ADMIN}
        }
    }
`;

export default () => (
    useQuery(query)
);
