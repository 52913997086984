import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Loader from 'web/atoms/Loader';

const useStyles = makeStyles(() => ({
    progress: {
        alignItems: 'center',
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        left: 0,
        position: 'absolute',
        top: 0,
        width: '100%',
    },
}));

const Container = (props) => {
    const classes = useStyles();

    return props.children ? (
        <div>
            {props.children}
        </div>
    ) : (
        <div className={classes.progress}>
            <Loader />
        </div>
    );
};

Container.defaultProps = {
    children: null,
};

Container.propTypes = {
    children: PropTypes.node,
};

export default Container;
