import { useMutation } from 'apollo-augmented-hooks';
import { ACTION, OVERLAY, SCENE_OBJECT } from '../queries/scene';
import { TRANSLATION } from '../helpers/translations';

const mutation = `
    mutation updateSceneVersion($input: UpdateSceneVersionInput!) {
        updateSceneVersion(input: $input) {
            id
            name
            startTime
            endTime
            audioBackgroundId
            widget {
                ${TRANSLATION}
            }
            background {
                id
                url
                standard: variation(type: STANDARD) {
                    url
                }
                thumbnail: variation(type: THUMBNAIL) {
                    url
                }
            }
            actions {
                ${ACTION}
            }
            overlays {
                ${OVERLAY}
            }
            objects {
                ${SCENE_OBJECT}
            }
        }
    }
`;

export default () => {
    const [mutate] = useMutation(mutation);

    return (input) => (
        mutate({ input })
    );
};
