import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from 'cms/molecules/Tabs';
import MatomoErrorHandler from './Selection/MatomoErrorHandler';
import Overview from './Selection/Overview';
import Events from './Selection/Events';
import Geography from './Selection/Geography';
import Time from './Selection/Time';

const Selection = (props) => {
    const [activeTab, setActiveTab] = useState('overview');

    return (
        <MatomoErrorHandler
            error={props.error}
            statistics={props.statistics}
        >
            {() => (
                <Tabs
                    items={[
                        { value: 'overview', label: 'Übersicht' },
                        { value: 'events', label: 'Ereignisse' },
                        { value: 'geography', label: 'Geografische Daten' },
                        { value: 'time', label: 'Zeitlicher Verlauf' },
                    ]}
                    value={activeTab}
                    onChange={setActiveTab}
                >
                    {activeTab === 'overview' && (
                        <Overview
                            totalClicks={props.statistics.totalClicks}
                            uniqueClicks={props.statistics.uniqueClicks}
                        />
                    )}

                    {activeTab === 'events' && (
                        <Events eventActions={props.statistics.eventActions} />
                    )}

                    {activeTab === 'geography' && (
                        <Geography
                            cities={props.statistics.cities}
                            countries={props.statistics.countries}
                        />
                    )}

                    {activeTab === 'time' && (
                        <Time hours={props.statistics.hours} />
                    )}
                </Tabs>
            )}
        </MatomoErrorHandler>
    );
};

Selection.defaultProps = {
    error: null,
    statistics: null,
};

Selection.propTypes = {
    error: PropTypes.object,
    statistics: PropTypes.object,
};

export default Selection;
