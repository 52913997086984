import React from 'react';
import PropTypes from 'prop-types';
import useTranslate from 'hooks/useTranslate';
import { Helmet as ReactHelmet } from 'react-helmet';

const Helmet = (props) => {
    const t = useTranslate();

    return (
        <ReactHelmet>
            <title>{t(props.title)}</title>
        </ReactHelmet>
    );
};

Helmet.propTypes = {
    title: PropTypes.object.isRequired,
};

export default Helmet;
