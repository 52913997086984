import Card from 'react-bootstrap/Card';
import React from 'react';
import Image from 'Image';
import PropTypes from 'prop-types';
import useTranslate from 'hooks/useTranslate';
import NewstickerContent from 'shared/atoms/shared/NewstickerContent';
import GalleryContent from '../../shared/GalleryContent';
import DownloadContent from '../../shared/DownloadContent';
import VideoContent from '../../shared/VideoContent';
import InfoboxContent from '../../../shared/shared/InfoboxContent';
import ContactContent from '../../shared/ContactContent';

const Body = (props) => {
    const { element, open, sceneName } = props;
    const t = useTranslate();

    const renderMobileSceneElement = () => {
        if (element.infoboxData) {
            return (
                <InfoboxContent
                    title={t(element.infoboxData.title)}
                    sceneName={sceneName}
                >
                    {t(element.infoboxData.content)}
                </InfoboxContent>
            );
        }
        if (element.videoData) {
            if (element.videoData.items) {
                return (
                    <VideoContent
                        videos={element.videoData.items}
                        title={t(element.videoData.title)}
                        sceneName={sceneName}
                    />
                );
            }
            return (
                <VideoContent
                    videos={[element.videoData]}
                    sceneName={sceneName}
                />
            );
        }
        if (element.downloadData) {
            return (
                <DownloadContent
                    title={t(element.downloadData.title)}
                    downloads={element.downloadData.downloads}
                    sceneName={sceneName}
                />
            );
        }
        if (element.galleryData) {
            return (
                <GalleryContent
                    images={element.galleryData.images}
                    sceneName={props.sceneName}
                    title={t(element.galleryData.title)}
                />
            );
        }
        if (element.imageData) {
            return (
                <Image
                    url={t(element.imageData.file).url}
                    style={{ width: '100%' }}
                />
            );
        }
        if (element.newstickerData) {
            return (
                <NewstickerContent>
                    {t(element.newstickerData.text)}
                </NewstickerContent>
            );
        }
        if (element.contactData) {
            return (
                <ContactContent
                    sections={element.contactData.sections}
                    sceneName={props.sceneName}
                />
            );
        }
        return null;
    };
    return (
        open ? (
            <Card.Body className="mobile-scene-accordion-card-body">
                <div>
                    {renderMobileSceneElement()}
                </div>
            </Card.Body>
        ) : null
    );
};

Body.propTypes = {
    element: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    sceneName: PropTypes.string.isRequired,
};

export default Body;
