import React from 'react';
import PropTypes from 'prop-types';
import Dialog from 'web/molecules/Dialog';
import ContactContent from './shared/ContactContent';

const ContactDialog = (props) => (
    <Dialog
        isOpen={props.isOpen}
        onClose={props.onClose}
        title={props.title}
    >
        <ContactContent
            sections={props.sections}
            sceneName={props.sceneName}
        />
    </Dialog>
);

ContactDialog.propTypes = {
    title: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    sections: PropTypes.array.isRequired,
    sceneName: PropTypes.string.isRequired,
};

export default ContactDialog;
