import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import EmailValidator from 'email-validator';
import useTranslate from 'hooks/useTranslate';
import useTracking from 'hooks/useTracking';
import useToggle from 'hooks/useToggle';
import { isValidFormValue } from 'helpers/formFields';
import { TRACKING_CREATE_VISITOR } from 'helpers/tracking';
import ProjectModel from 'models/web/project';
import Checkbox from 'web/atoms/Checkbox';
import Select from 'web/atoms/Select';
import TextField from 'web/atoms/TextField';
import Dialog from 'web/molecules/Dialog';
import FormDialog from 'web/molecules/FormDialog';
import InnerHTML from 'InnerHTML';
// import useGeoLocation from 'hooks/useGeoLocation';
import useDeviceDetect from 'hooks/useDeviceDetect';

const Registration = (props) => {
    const t = useTranslate();
    const [success, showSuccess, hideSuccess] = useToggle(false);
    const tracking = useTracking();
    const [state, setState] = useState({
        email: '',
        registrationData: [...props.registrationFields]
            .sort((a, b) => a.order - b.order)
            .map(({ id, description, label, mandatory, options, type }) => ({
                fieldId: id,
                description,
                label,
                mandatory,
                options,
                type,
                value: '',
            })),
    });

    const validators = [
        {
            name: 'email',
            message: () => t('web.common.error.email'),
            isValid: (value) => !!value.trim() && EmailValidator.validate(value),
        },
        {
            name: 'email',
            message: () => t('web.registration.error.alreadyExists'),
            serverError: 'VISITOR_ALREADY_EXISTS',
        },
        ...state.registrationData.reduce((result, current, index) => [
            ...result, {
                name: `registrationData.${index}`,
                message: () => t('web.common.error.fieldRequired'),
                isValid: isValidFormValue,
            },
        ], []),
    ];

    useEffect(() => () => {
        hideSuccess();
    }, [props.isOpen]);

    // const geolocation = useGeoLocation();
    const deviceDetect = useDeviceDetect();
    const confirm = async (values) => {
        const { data } = await props.registerVisitor({
            projectId: props.projectId,
            email: values.email.trim(),
            registrationData: values.registrationData.map(({ value, fieldId }) => ({
                value: value.toString().trim(),
                fieldId,
            })),
        });
        const visitor = data.registerVisitor;
        //  TRACKCORE:
        //   event:'createVisitor', ressource: visitor.account.email, data: {customer: customerId, registration: 'Registration', scene: props.sceneName, Latitude: Num, Longitude: Num, Device: String, Browser: String}
        tracking.trackCoreEvent({
            action: 'createVisitor',
            resourceType: 'visitorEmail',
            resourceName: visitor.account.email,
            data: [
                {
                    name: 'customer',
                    value: 'customerName',
                },
                {
                    name: 'registration',
                    value: 'Registration',
                },
                {
                    name: 'platform',
                    value: deviceDetect.platform,
                },
                {
                    name: 'browser',
                    value: deviceDetect.browser,
                },
                {
                    name: 'device',
                    value: deviceDetect.device,
                },
                {
                    name: 'resolution',
                    value: deviceDetect.res,
                },
            ],
        });
        tracking.trackCoreEvent({
            action: 'createVisitor',
            resourceType: 'visitorEmail',
            resourceName: visitor.account.email,
            data: [
                // {
                //     name: 'latitude',
                //     value: geolocation.latitude,
                // },
                // {
                //     name: 'longitude',
                //     value: geolocation.longitude,
                // },
            ],
        });
        tracking.trackEvent('Registration', TRACKING_CREATE_VISITOR, visitor.account.email);
        showSuccess();
    };

    const renderSuccess = () => (
        <Dialog
            isOpen={props.isOpen && success}
            onClose={props.onClose}
            title={t('web.registration.title')}
        >
            {t('web.registration.success')}
        </Dialog>
    );

    const renderFormField = (field, index, onChangeByEvent, onChangeByValue, errors) => {
        switch (field.type) {
            case 'CHECKBOX':
                return (
                    <Checkbox
                        checked={!!field.value}
                        key={field.fieldId}
                        name={`registrationData.${index}.value`}
                        onChange={onChangeByValue(`registrationData.${index}.value`)}
                        error={errors[`registrationData.${index}`]}
                    >
                        <InnerHTML>{t(field.description)}</InnerHTML>
                    </Checkbox>
                );
            case 'DROPDOWN':
                return (
                    <Select
                        key={field.fieldId}
                        value={field.value}
                        label={t(field.label)}
                        name={`registrationData.${index}.value`}
                        onChange={onChangeByEvent}
                        options={
                            [...field.options]
                                .sort((a, b) => a.order - b.order)
                                .map((option) => ({
                                    label: t(option.label),
                                    value: option.value,
                                }))
                        }
                        emptyOption={{
                            label: t('web.registration.chooseDropdownValue'),
                        }}
                        error={errors[`registrationData.${index}`]}
                    />
                );
            case 'TEXT':
            default:
                return (
                    <TextField
                        key={field.fieldId}
                        type="text"
                        label={t(field.label)}
                        value={field.value}
                        name={`registrationData.${index}.value`}
                        onChange={onChangeByEvent}
                        error={errors[`registrationData.${index}`]}
                    />
                );
        }
    };

    const renderForm = () => (
        <FormDialog
            isOpen={props.isOpen && !success}
            title={t('web.registration.title')}
            values={state}
            onConfirm={confirm}
            onChange={setState}
            onClose={props.onClose}
            validators={validators}
            withSubmitButton
        >
            {({ errors, onChangeByEvent, onChangeByValue }) => (
                <>
                    <InnerHTML>{t(props.registrationInstructions)}</InnerHTML>
                    <TextField
                        type="email"
                        label={t(props.loginLabelUser) || t('web.login.user')}
                        value={state.email}
                        name="email"
                        onChange={onChangeByEvent}
                        error={errors.email}
                    />
                    {state.registrationData.map((field, index) => (
                        renderFormField(field, index, onChangeByEvent, onChangeByValue, errors)
                    ))}
                </>
            )}
        </FormDialog>
    );

    return (
        <>
            {renderSuccess()}
            {renderForm()}
        </>
    );
};

Registration.defaultProps = {
    registrationInstructions: null,
    loginLabelUser: null,
    onClose: null,
};

Registration.propTypes = {
    registerVisitor: PropTypes.func.isRequired,
    projectId: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    registrationInstructions: PropTypes.object,
    loginLabelUser: PropTypes.object,
    registrationFields: PropTypes.array.isRequired,
};

export default ProjectModel.graphql(Registration, { withoutQuery: true });
