import React from 'react';
import PropTypes from 'prop-types';
import { DatePicker, DateTimePicker as MuiDateTimePicker } from '@material-ui/pickers';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

const DateTimePicker = (props) => (
    <FormControl fullWidth error={!!props.error}>
        {props.onlyDate ? (
            <DatePicker
                label={props.label}
                format="DD.MM.YYYY"
                value={props.value}
                onChange={props.onChange}
                invalidDateMessage={null}
                disableFuture={props.disableFuture}
            />
        ) : (
            <MuiDateTimePicker
                label={props.label}
                format="DD.MM.YYYY HH:mm"
                value={props.value}
                onChange={props.onChange}
                ampm={false}
                invalidDateMessage={null}
                disableFuture={props.disableFuture}
                clearable
            />
        )}

        <FormHelperText>{props.error}</FormHelperText>
    </FormControl>
);

DateTimePicker.defaultProps = {
    label: null,
    value: null,
    error: null,
    onlyDate: false,
    disableFuture: false,
};

DateTimePicker.propTypes = {
    label: PropTypes.string,
    error: PropTypes.string,
    value: PropTypes.object, // moment object
    onlyDate: PropTypes.bool,
    disableFuture: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
};

export default DateTimePicker;
