import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import mediaUrl from 'helpers/mediaUrl';
import Image from 'Image';
import Warning from 'cms/atoms/Warning';
import Card from 'cms/molecules/Card';

const Scene = (props) => {
    const history = useHistory();

    const scenesLinkingToThis = props.scenes.filter((item) => (
        item.actions.some(({ sceneData, exhibitorData }) => (
            (sceneData && sceneData.scene.id === props.id)
            || (exhibitorData && exhibitorData.scene.id === props.id)
        ))
    ));
    const isStartScene = props.startScene && props.startScene.id === props.id;
    const showWarning = !isStartScene && scenesLinkingToThis.length === 0;

    const goToSceneHandler = (id) => () => {
        history.push(`/cms/scene/${id}`);
    };

    const renderSubLabel = () => {
        if (props.type === 'EXHIBITOR') {
            return 'Aussteller';
        }

        return isStartScene ? 'Startszene' : 'Szene';
    };

    return (
        <Card
            headerLabel={props.name}
            subLabel={renderSubLabel()}
            onClick={goToSceneHandler(props.id)}
            image={<Image url={mediaUrl(props.background, 'thumbnail')} />}
        >
            <div className="cms-project-scene">
                {showWarning && (
                    <Warning>Keine Szene verlinkt hierher</Warning>
                )}
            </div>
        </Card>
    );
};

Scene.defaultProps = {
    startScene: null,
};

Scene.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    background: PropTypes.object.isRequired,
    actions: PropTypes.array.isRequired,
    scenes: PropTypes.array.isRequired,
    startScene: PropTypes.object,
};

export default Scene;
