import React from 'react';
import PropTypes from 'prop-types';
import Dialog from 'web/molecules/Dialog';
import DownloadContent from './shared/DownloadContent';

const DownloadDialog = (props) => (
    <Dialog
        isOpen={props.isOpen}
        onClose={props.onClose}
        title={props.title}
        compact
    >
        <DownloadContent
            title={props.title}
            downloads={props.downloads}
            sceneName={props.sceneName}
            onViewDocument={props.onViewDocument}
        />
    </Dialog>
);

DownloadDialog.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    downloads: PropTypes.array.isRequired,
    sceneName: PropTypes.string.isRequired,
    onViewDocument: PropTypes.func.isRequired,
};

export default DownloadDialog;
