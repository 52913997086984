import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import FontFaceObserver from 'fontfaceobserver';
import MuiTabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const Tabs = (props) => {
    const tabsRef = useRef();

    useEffect(() => {
        const font = new FontFaceObserver('Roboto');

        font.load().then(() => {
            // Fix wrong initial indicator position due to font loading asynchronously
            // https://github.com/mui-org/material-ui/issues/16465
            tabsRef.current.updateIndicator();
        });
    }, []);

    useEffect(() => {
        const item = props.items.find(({ value, available }) => (
            available !== false && value === props.value
        ));

        if (!item) {
            props.onChange(props.items[0].value);
        }
    }, [props.value]);

    const onChange = (event, newValue) => {
        props.onChange(newValue);
    };

    return (
        <div className="ui-cms-tabs">
            <MuiTabs
                className="ui-cms-tabs__header"
                value={props.value}
                indicatorColor="primary"
                textColor="primary"
                onChange={onChange}
                action={tabsRef}
            >
                {props.items.map((item) => (item.available !== false ? (
                    <Tab
                        className="ui-cms-tabs__tab"
                        key={item.value}
                        label={item.label}
                        value={item.value}
                    />
                ) : null))}
            </MuiTabs>

            <div className="ui-cms-tabs__content">
                {props.children}
            </div>
        </div>
    );
};

Tabs.defaultProps = {
    children: null,
};

Tabs.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        available: PropTypes.bool,
    })).isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    children: PropTypes.node,
};

export default Tabs;
