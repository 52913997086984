import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { useLocalStorage, writeStorage, deleteFromStorage } from '@rehooks/local-storage';
import queryString from 'query-string';
import { findLoginType } from 'helpers/loginTypes';
import { TRACKING_LOGIN } from 'helpers/tracking';
import useTracking from 'hooks/useTracking';
import useTranslate from 'hooks/useTranslate';
import ProjectModel from 'models/web/project';
import Button from 'web/atoms/Button';
import TextField from 'web/atoms/TextField';
import FormDialog from 'web/molecules/FormDialog';
import InnerHTML from 'InnerHTML';
import { AUTH_TYPE_VISITOR } from 'auth';
import External from './Login/External';

const Login = (props) => {
    const history = useHistory();
    const params = useParams();
    const tracking = useTracking();
    const t = useTranslate();
    const [returnAction, , deleteReturnAction] = useLocalStorage('returnAction');
    const [state, setState] = useState({
        lastName: '',
        email: '',
        password: '',
        ticketId: '',
    });

    const validators = [{
        name: 'password',
        message: () => t('web.login.error.invalidCredentials'),
        serverError: 'INVALID_CREDENTIALS',
    }, {
        name: 'ticketId',
        message: () => t('web.login.error.invalidCredentials'),
        serverError: 'EXTERNAL_LOGIN_ERROR',
    }, {
        name: 'general',
        message: () => t('web.login.error.generic'),
        serverError: 'EXTERNAL_LOGIN_ERROR',
    }];

    const goToReturnPath = () => {
        if (returnAction) {
            switch (returnAction.type) {
                case 'scene':
                    history.push(`/event/${params.projectPath}/${returnAction.value}`);
                    break;

                case 'external':
                    window.open(returnAction.value, '_self');
                    break;

                default:
            }

            deleteReturnAction();
        }
    };

    const removeQueryParam = () => {
        history.replace({ search: '' });
    };

    const setToken = (result) => {
        if (result.roleName === 'admin') {
            props.setAdminToken(result.identityToken);
        } else {
            props.setVisitorToken(result.identityToken);
        }
    };

    useEffect(() => {
        const match = window.location.pathname.match(/\/event\/([a-zäöüß0-9-]+)/);
        if (match) {
            const authType = `${AUTH_TYPE_VISITOR}-${match[1]}`;
            if (props.token && !localStorage.getItem(authType)) {
                const buffer = Buffer.from(props.token, 'base64');
                const data = buffer.toString('ascii').split('::');
                confirm({
                    email: data[0],
                    password: data[1],
                }).then(() => {
                    window.location.href = window.location.pathname;
                });
            }
        }
    }, []);

    const trackLoginResult = (loginResult) => {
        if (!props.loginTracking) {
            return;
        }

        if (loginResult.trackingId) {
            // TRACKCORE:
            //  event:'login', ressource: loginResult.trackingId, data: {customer: customerId, scene: props.sceneName, Latitude: Num, Longitude: Num, Device: String, Browser: String, IsLoggedIn: true? }
            // console.log('%cTRACKCORE: TRACKING_LOGIN', 'background: #00a8ff; font-weight: bold;', loginResult.trackingId);
            tracking.trackCoreEvent({
                action: 'login',
                resourceType: 'trackingId',
                resourceName: loginResult.trackingId,
            });
            tracking.trackEvent(props.sceneName, TRACKING_LOGIN, loginResult.trackingId);
            writeStorage('trackingUserId', loginResult.trackingId);
        } else {
            // TRACKCORE:
            //  event:'login', ressource: , data: {customer: customerId, scene: props.sceneName, Latitude: Num, Longitude: Num, Device: String, Browser: String, IsLoggedIn: false? }
            // console.log('%cTRACKCORE: TRACKING_LOGIN', 'background: #00a8ff; font-weight: bold;', loginResult.trackingId);
            tracking.trackCoreEvent({
                action: 'login',
                resourceType: '',
                resourceName: '',
            });
            tracking.trackEvent(props.sceneName, TRACKING_LOGIN);
            deleteFromStorage('trackingUserId');
        }

        if (loginResult.trackingValue) {
            writeStorage('trackingUserValue', loginResult.trackingValue);
        } else {
            deleteFromStorage('trackingUserValue');
        }
    };

    const confirm = async (values) => {
        if (props.loginType === 'PASSWORD') {
            const { data } = await props.createPasswordProjectToken({
                projectId: props.projectId,
                password: values.password,
            });

            trackLoginResult(data.createPasswordProjectToken);
            props.setVisitorToken(data.createPasswordProjectToken.identityToken);
        } else if (props.loginType === 'COUPON_CODES') {
            const { data } = await props.createCouponCodeProjectToken({
                projectId: props.projectId,
                couponCode: values.password,
            });

            trackLoginResult(data.createCouponCodeProjectToken);
            props.setVisitorToken(data.createCouponCodeProjectToken.identityToken);
        } else if (props.loginType === 'ACCOUNTS') {
            const { data } = await props.createToken({
                projectId: props.projectId,
                email: values.email,
                password: values.password,
            });

            trackLoginResult(data.createToken);
            setToken(data.createToken);
        } else if (props.loginType === 'EXTERNAL_MANAGE') {
            const { code } = queryString.parse(window.location.search);

            removeQueryParam();

            const { data } = await props.createExternalManageProjectToken({
                projectId: props.projectId,
                code,
                redirectUrl: window.location.href,
            });

            trackLoginResult(data.createExternalManageProjectToken);
            setToken(data.createExternalManageProjectToken);
        } else if (props.loginType === 'EXTERNAL_REGASUS') {
            const { jwt } = queryString.parse(window.location.search);

            removeQueryParam();

            const { data } = await props.createExternalRegasusProjectToken({
                projectId: props.projectId,
                token: jwt,
            });

            trackLoginResult(data.createExternalRegasusProjectToken);
            setToken(data.createExternalRegasusProjectToken);
        } else if (props.loginType === 'EXTERNAL_EVENTSAIR') {
            const { id } = queryString.parse(window.location.search);

            // this is used for placeholders in links
            writeStorage('eventsairContactId', id);

            removeQueryParam();

            const { data } = await props.createExternalEventsairProjectToken({
                projectId: props.projectId,
                contactId: id,
            });

            trackLoginResult(data.createExternalEventsairProjectToken);
            setToken(data.createExternalEventsairProjectToken);
        } else if (props.loginType === 'EXTERNAL_HAPAG_LLOYD') {
            const accessToken = queryString.parse(window.location.search).h;

            removeQueryParam();

            const { data } = await props.createExternalHapagLloydProjectToken({
                projectId: props.projectId,
                accessToken,
            });

            trackLoginResult(data.createExternalHapagLloydProjectToken);
            setToken(data.createExternalHapagLloydProjectToken);
        } else if (props.loginType === 'EXTERNAL_PLAZZ') {
            const accessToken = queryString.parse(window.location.search).session;
            removeQueryParam();

            const { data } = await props.createExternalPlazzProjectToken({
                projectId: props.projectId,
                accessToken,
            });

            trackLoginResult(data.createExternalPlazzProjectToken);
            setToken(data.createExternalPlazzProjectToken);
        } else if (props.loginType === 'EXTERNAL_XING_EVENTS') {
            const { data } = await props.createExternalXingEventsProjectToken({
                projectId: props.projectId,
                externalTicketId: state.ticketId,
            });

            trackLoginResult(data.createExternalXingEventsProjectToken);
            setToken(data.createExternalXingEventsProjectToken);
        }

        goToReturnPath();

        props.onClose();
    };

    const renderContent = (dialogParams) => {
        const canRegister = props.loginType === 'ACCOUNTS' && props.registrationEnabled;
        const canResetPassword = props.loginType === 'ACCOUNTS';
        return (
            <>
                <InnerHTML>{t(props.loginInstructions)}</InnerHTML>
                {renderFields(dialogParams)}
                {canRegister && renderRegistrationPrompt()}
                {canResetPassword && renderForgotPasswordPrompt()}
            </>
        );
    };

    const renderFields = ({ errors, onChangeByEvent, onConfirm }) => {
        switch (props.loginType) {
            case 'PASSWORD':
                return (
                    <TextField
                        type="password"
                        label={t(props.loginLabelPassword) || t('web.login.password')}
                        value={state.password}
                        name="password"
                        onChange={onChangeByEvent}
                        onKeyDown={(e) => e.key === 'Enter' && onConfirm()}
                        error={errors.password}
                    />
                );

            case 'COUPON_CODES':
                return (
                    <TextField
                        type="password"
                        label={t(props.loginLabelPassword) || t('web.login.accessCode')}
                        value={state.password}
                        name="password"
                        onChange={onChangeByEvent}
                        error={errors.password}
                    />
                );

            case 'ACCOUNTS':
                return (
                    <>
                        <TextField
                            type="email"
                            label={t(props.loginLabelUser) || t('web.login.user')}
                            value={state.email}
                            name="email"
                            onChange={onChangeByEvent}
                        />

                        <TextField
                            type="password"
                            label={t(props.loginLabelPassword) || t('web.login.password')}
                            value={state.password}
                            name="password"
                            onChange={onChangeByEvent}
                            onKeyDown={(e) => e.key === 'Enter' && onConfirm()}
                            error={errors.password}
                        />
                    </>
                );

            case 'EXTERNAL_MANAGE':
                return (
                    <External
                        errors={errors}
                        onConfirm={onConfirm}
                        loginDialogType={props.loginDialogType}
                        externalLoginUrl={props.externalLoginManageLoginUrl}
                        queryParam="code"
                    />
                );

            case 'EXTERNAL_REGASUS':
                return (
                    <External
                        errors={errors}
                        onConfirm={onConfirm}
                        loginDialogType={props.loginDialogType}
                        externalLoginUrl={props.externalLoginRegasusLoginUrl}
                        queryParam="jwt"
                    />
                );

            case 'EXTERNAL_EVENTSAIR':
                return (
                    <External
                        errors={errors}
                        onConfirm={onConfirm}
                        loginDialogType={props.loginDialogType}
                        externalLoginUrl={props.externalLoginEventsairLoginUrl}
                        queryParam="id"
                    />
                );

            case 'EXTERNAL_HAPAG_LLOYD':
                return (
                    <External
                        errors={errors}
                        onConfirm={onConfirm}
                        loginDialogType={props.loginDialogType}
                        externalLoginUrl={props.externalLoginHapagLloydLoginUrl}
                        queryParam="h"
                    />
                );
            case 'EXTERNAL_PLAZZ':
                return (
                    <External
                        errors={errors}
                        onConfirm={onConfirm}
                        loginDialogType={props.loginDialogType}
                        externalLoginUrl={props.externalLoginPlazzLoginUrl}
                        queryParam="session"
                    />
                );
            case 'EXTERNAL_XING_EVENTS':
                return (
                    <TextField
                        label={t('web.login.external.ticketId')}
                        value={state.ticketId}
                        name="ticketId"
                        onChange={onChangeByEvent}
                        onKeyDown={(e) => e.key === 'Enter' && onConfirm()}
                        error={errors.ticketId}
                        placeholder="1234-5678-9101"
                    />
                );
            default:
                return null;
        }
    };

    const renderRegistrationPrompt = () => (
        <Button
            onClick={showRegistration}
            link
        >
            {t('web.login.register')}
        </Button>
    );

    const renderForgotPasswordPrompt = () => (
        <Button
            onClick={props.onShowResetPassword}
            link
        >
            {t('web.login.forgotPassword')}
        </Button>
    );

    const showRegistration = () => {
        if (props.registrationExternalUrl) {
            window.open(props.registrationExternalUrl, '_blank');
        } else {
            props.onShowRegistration();
        }
    };

    return (
        <FormDialog
            isOpen={props.isOpen}
            title="Login"
            values={state}
            onConfirm={confirm}
            onChange={setState}
            onClose={props.onClose}
            validators={validators}
            withSubmitButton={findLoginType(props.loginType).withSubmitButton}
        >
            {renderContent}
        </FormDialog>
    );
};

Login.defaultProps = {
    loginInstructions: null,
    loginLabelUser: null,
    loginLabelPassword: null,
    registrationExternalUrl: null,
    token: null,
};

Login.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    loginType: PropTypes.string.isRequired,
    loginInstructions: PropTypes.object,
    projectId: PropTypes.string.isRequired,
    visitorTokenKey: PropTypes.string.isRequired,
    createToken: PropTypes.func.isRequired,
    createPasswordProjectToken: PropTypes.func.isRequired,
    createExternalManageProjectToken: PropTypes.func.isRequired,
    createExternalRegasusProjectToken: PropTypes.func.isRequired,
    createExternalEventsairProjectToken: PropTypes.func.isRequired,
    createExternalHapagLloydProjectToken: PropTypes.func.isRequired,
    createExternalPlazzProjectToken: PropTypes.func.isRequired,
    createExternalXingEventsProjectToken: PropTypes.func.isRequired,
    createCouponCodeProjectToken: PropTypes.func.isRequired,
    setAdminToken: PropTypes.func.isRequired,
    setVisitorToken: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onShowRegistration: PropTypes.func.isRequired,
    onShowResetPassword: PropTypes.func.isRequired,
    externalLoginManageLoginUrl: PropTypes.string.isRequired,
    externalLoginRegasusLoginUrl: PropTypes.string.isRequired,
    externalLoginEventsairLoginUrl: PropTypes.string.isRequired,
    externalLoginHapagLloydLoginUrl: PropTypes.string.isRequired,
    externalLoginPlazzLoginUrl: PropTypes.string.isRequired,
    sceneName: PropTypes.string.isRequired,
    loginDialogType: PropTypes.string.isRequired,
    loginTracking: PropTypes.bool.isRequired,
    registrationEnabled: PropTypes.bool.isRequired,
    registrationExternalUrl: PropTypes.string,
    loginLabelUser: PropTypes.object,
    loginLabelPassword: PropTypes.object,
    token: PropTypes.string,
};

export default ProjectModel.graphql(Login, { withoutQuery: true });
