import React from 'react';
import PropTypes from 'prop-types';
import Nav from 'react-bootstrap/Nav';

const Link = (props) => (
    <Nav.Link
        onClick={props.onClick}
        active={props.active}
        style={props.style}
    >
        {props.children}
    </Nav.Link>
);

Link.defaultProps = {
    active: false,
    onClick: () => null,
    style: null,
};

Link.propTypes = {
    onClick: PropTypes.func,
    children: PropTypes.string.isRequired,
    active: PropTypes.bool,
    style: PropTypes.object,
};

export default Link;
