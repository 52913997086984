import React from 'react';
import PropTypes from 'prop-types';
import Dialog from 'web/molecules/Dialog';
import InfoboxContent from './shared/InfoboxContent';

const InfoboxDialog = (props) => (
    <Dialog
        isOpen={props.isOpen}
        onClose={props.onClose}
        title={props.title}
    >
        <InfoboxContent
            sceneName={props.sceneName}
            title={props.title}
        >
            {props.children}
        </InfoboxContent>
    </Dialog>
);

InfoboxDialog.defaultProps = {
    title: 'Dialog',
    children: null,
};

InfoboxDialog.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string,
    children: PropTypes.node,
    sceneName: PropTypes.string.isRequired,
};

export default InfoboxDialog;
