import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { apiResponseToFormState } from 'helpers/form';
import { resolveLanguageName } from 'helpers/languages';
import LocaleContext from 'contexts/locale';
import useProject from 'hooks/graphql/queries/project';
import useCreateVisitorWithAccount from 'hooks/graphql/mutations/createVisitorWithAccount';
import useUpdateVisitorWithAccount from 'hooks/graphql/mutations/updateVisitorWithAccount';
import { findLoginType } from 'helpers/loginTypes';
import EditingAdmin from 'EditingAdmin';
import TextField from 'cms/atoms/TextField';
import CheckboxGroup from 'cms/atoms/CheckboxGroup';
import Select from 'cms/atoms/Select';
import KeyValueTable from 'cms/molecules/KeyValueTable';
import Dialog from 'cms/molecules/Dialog';

const defaultValues = {
    id: undefined,
    email: {
        _default: '',
        _key: 'account',
        _modify: ({ email }) => email,
    },
    password: '',
    name: '',
    company: '',
    position: '',
    phone: '',
    externalId: '',
    trackingId: '',
    salutation: '',
    title: '',
    videoConferenceUrl: '',
    privileges: {
        _map: ({ id }) => id,
    },
    preferredLanguageId: {
        _default: '',
        _key: 'preferredLanguage',
        _modify: ({ id }) => id,
    },
};

const Visitor = (props) => {
    const localeContext = useContext(LocaleContext);
    const params = useParams();

    const { data, loading } = useProject(params.id);
    const { project } = !loading && data;
    const createVisitorWithAccount = useCreateVisitorWithAccount();
    const updateVisitorWithAccount = useUpdateVisitorWithAccount();

    const [state, setState] = useState(apiResponseToFormState(props.visitor, defaultValues));
    const validators = [{
        name: 'email',
        message: () => 'Bitte gib eine E-Mail-Adresse ein.',
        isValid: (value) => !!value.trim(),
    }, {
        name: 'email',
        message: () => 'Es gibt für dieses Projekt bereits einen Account mit dieser E-Mail-Adresse.',
        serverError: 'VISITOR_ALREADY_EXISTS',
    }, {
        name: 'password',
        message: () => 'Bitte gib ein Passwort ein.',
        isValid: (value) => !!props.visitor || !!value.trim(),
    }];

    useEffect(() => {
        if (props.isOpen) {
            setState(apiResponseToFormState(props.visitor, defaultValues));
        }
    }, [props.isOpen]);

    const save = async (values) => {
        const mutation = props.visitor ? updateVisitorWithAccount : createVisitorWithAccount;
        await mutation({
            id: values.id,
            projectId: props.visitor ? undefined : project.id,
            email: values.email.trim(),
            name: values.name.trim() || undefined,
            company: values.company.trim() || undefined,
            position: values.position.trim() || undefined,
            phone: values.phone.trim() || undefined,
            externalId: values.externalId.trim() || undefined,
            trackingId: values.trackingId.trim() || undefined,
            salutation: values.salutation.trim() || undefined,
            title: values.title.trim() || undefined,
            password: values.password.trim() || undefined,
            videoConferenceUrl: values.videoConferenceUrl.trim() || undefined,
            privileges: values.privileges.map((id) => ({ id })),
            preferredLanguageId: values.preferredLanguageId || null,
        });

        props.onClose();
    };

    const handleLanguageChanged = (event) => {
        const languageId = event.target.value;
        const selectedLanguageId = localeContext.languages.find(({ id }) => id === languageId)?.id;
        setState((previous) => ({ ...previous, preferredLanguageId: selectedLanguageId }));
    };

    return !loading && (
        <Dialog
            title={props.visitor ? 'Besucher-Account bearbeiten' : 'Neuen Besucher-Account erstellen'}
            isOpen={props.isOpen}
            onClose={props.onClose}
            onConfirm={save}
            onChange={setState}
            values={state}
            validators={validators}
        >
            {({ errors, onChangeByEvent, onChangeByValue }) => (
                <>
                    {props.visitor && (
                        <EditingAdmin name={`project-visitor-${props.visitor.id}`} />
                    )}

                    <KeyValueTable
                        items={[{
                            key: 'E-Mail-Adresse',
                            value: (
                                <TextField
                                    name="email"
                                    value={state.email}
                                    onChange={onChangeByEvent}
                                    error={errors.email}
                                />
                            ),
                        }, {
                            key: 'Passwort',
                            help: findLoginType(project.loginType).withOauth ? 'Feld leer lassen, um altes secret beizubehalten' : null,
                            value: (
                                <TextField
                                    name="password"
                                    value={state.password}
                                    onChange={onChangeByEvent}
                                    error={errors.password}
                                />
                            ),
                        }, {
                            key: 'Name',
                            value: (
                                <TextField
                                    name="name"
                                    value={state.name}
                                    onChange={onChangeByEvent}
                                />
                            ),
                        }, {
                            key: 'Anrede',
                            value: (
                                <TextField
                                    name="salutation"
                                    value={state.salutation}
                                    onChange={onChangeByEvent}
                                />
                            ),
                        }, {
                            key: 'Titel',
                            value: (
                                <TextField
                                    name="title"
                                    value={state.title}
                                    onChange={onChangeByEvent}
                                />
                            ),
                        }, {
                            key: 'Firma',
                            value: (
                                <TextField
                                    name="company"
                                    value={state.company}
                                    onChange={onChangeByEvent}
                                />
                            ),
                        }, {
                            key: 'Position',
                            value: (
                                <TextField
                                    name="position"
                                    value={state.position}
                                    onChange={onChangeByEvent}
                                />
                            ),
                        }, {
                            key: 'Telefon',
                            value: (
                                <TextField
                                    name="phone"
                                    value={state.phone}
                                    onChange={onChangeByEvent}
                                />
                            ),
                        }, {
                            key: 'Externe ID',
                            value: (
                                <TextField
                                    name="externalId"
                                    value={state.externalId}
                                    onChange={onChangeByEvent}
                                />
                            ),
                        }, {
                            key: 'Tracking ID',
                            value: (
                                <TextField
                                    name="trackingId"
                                    value={state.trackingId}
                                    onChange={onChangeByEvent}
                                />
                            ),
                        }, {
                            key: 'Videokonferenz URL',
                            value: (
                                <TextField
                                    name="videoConferenceUrl"
                                    value={state.videoConferenceUrl}
                                    onChange={onChangeByEvent}
                                />
                            ),
                        }, {
                            key: 'Privilegien',
                            value: (
                                <CheckboxGroup
                                    value={state.privileges}
                                    onChange={onChangeByValue('privileges')}
                                    items={
                                        project.accessGroups
                                            .map(({ id, name }) => ({
                                                label: name,
                                                value: id,
                                            }))
                                    }
                                />
                            ),
                            align: 'top',
                        }, {
                            key: 'Bevorzugte Sprache',
                            value: (
                                <Select
                                    name="Bevorzugte Sprache"
                                    label="Sprache"
                                    value={state.preferredLanguageId || ''}
                                    onChange={handleLanguageChanged}
                                    items={localeContext.languages.map(({ id, code }) => ({
                                        label: resolveLanguageName(code),
                                        value: id,
                                    }))}
                                />
                            ),
                            align: 'top',
                        }]}
                    />
                </>
            )}
        </Dialog>
    );
};

Visitor.defaultProps = {
    visitor: null,
};

Visitor.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    visitor: PropTypes.object,
};

export default Visitor;
