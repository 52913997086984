import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    DataGrid as MuiDataGrid,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
} from '@material-ui/data-grid';
import { deDE as MUIdeDE } from '@material-ui/core/locale';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import deDE from './Datagrid/deDE';

const theme = createTheme({ }, MUIdeDE, deDE);

const Datagrid = (props) => {
    const [selectedIds, setSelectedIds] = useState([]);
    const [pageSize, setPageSize] = useState(25);

    const deleteButtonVisible = props.onDelete && selectedIds.length > 0;
    const editButtonVisible = props.onEdit && selectedIds.length === 1;

    const EditButton = () => (
        <Button
            startIcon={<EditIcon />}
            size="small"
            color="primary"
            onClick={props.onEdit(selectedIds[0])}
        >
            Bearbeiten
        </Button>
    );

    const DeleteButton = () => (
        <Button
            startIcon={<DeleteIcon />}
            className="ui-cms-button--danger"
            size="small"
            onClick={props.onDelete(selectedIds)}
        >
            Löschen
        </Button>
    );

    const handleSelectionChanged = (selection) => {
        setSelectedIds(selection);
        props.onSelectionModelChange(selection);
    };

    const Toolbar = () => (
        <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            { editButtonVisible && <EditButton /> }
            { deleteButtonVisible && <DeleteButton /> }
        </GridToolbarContainer>
    );

    return (
        <div style={{ height: props.height, width: '100%' }}>
            <ThemeProvider theme={theme}>
                <MuiDataGrid
                    {...props}
                    localeText={deDE}
                    components={{
                        Toolbar: Toolbar,
                    }}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                    onSelectionModelChange={handleSelectionChanged}
                    isRowSelectable={() => props.checkboxSelection}
                    disableColumnMenu
                />
            </ThemeProvider>
        </div>
    );
};

Datagrid.defaultProps = {
    checkboxSelection: true,
    height: 400,
    onSelectionModelChange: () => {},
    onDelete: undefined,
    onEdit: undefined,
};

Datagrid.propTypes = {
    checkboxSelection: PropTypes.bool,
    columns: PropTypes.array.isRequired,
    height: PropTypes.number,
    rows: PropTypes.array.isRequired,
    onSelectionModelChange: PropTypes.func,
    onDelete: PropTypes.func,
    onEdit: PropTypes.func,
};

export default Datagrid;
