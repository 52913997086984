import React from 'react';
import PropTypes from 'prop-types';
import useTranslate from 'hooks/useTranslate';
import { hasValue, formatValue } from 'helpers/registrationData';
import Popup from 'cms/molecules/Popup';
import KeyValueTable from 'cms/molecules/KeyValueTable';

const VisitorRegistrationData = (props) => {
    const t = useTranslate();
    const renderContent = (data) => {
        const items = data
            .filter(hasValue)
            .map((dataItem) => ({
                key: t(dataItem.field.label),
                value: formatValue(dataItem),
            }));
        return <KeyValueTable items={items} />;
    };

    return (
        <Popup
            isOpen={props.isOpen}
            onClose={props.onClose}
            title={props.visitor && props.visitor.account.email}
        >
            {props.visitor && renderContent(props.visitor.registrationData)}
        </Popup>
    );
};

VisitorRegistrationData.defaultProps = {
    visitor: null,
};

VisitorRegistrationData.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    visitor: PropTypes.object,
};

export default VisitorRegistrationData;
