import React, { useEffect } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import { AUTH_TYPE_ADMIN } from 'auth';
import AuthContext from 'contexts/auth';
import RestoredScroll from 'RestoredScroll';
import Header from 'cms/Header';
import Home from './Cms/Home';
import Customer from './Cms/Customer';
import Login from './Cms/Login';
import Project from './Cms/Project';
import Scene from './Cms/Scene';
import Settings from './Cms/Settings';
import Admins from './Cms/Admins';
import MeProvider from './Cms/MeProvider';

const Cms = () => {
    const location = useLocation();

    useEffect(() => {
        moment.locale('de');
    }, []);

    return (
        <AuthContext.Provider value={AUTH_TYPE_ADMIN}>
            <Switch location={location}>
                <Route path="/cms/login" component={Login} />
                <Route path="/cms">
                    <MeProvider>
                        <Helmet>
                            <title>Virtuell-X Messe CMS</title>
                            <body className="cms" />
                        </Helmet>

                        <RestoredScroll />

                        <Header />

                        <Switch location={location}>
                            <Route path="/cms/settings" component={Settings} />
                            <Route path="/cms/admins" component={Admins} />
                            <Route path="/cms/customer/:id" component={Customer} />
                            <Route path="/cms/project/:id" component={Project} />
                            <Route path="/cms/scene/:id" component={Scene} />
                            <Route path="/cms" component={Home} />
                        </Switch>
                    </MeProvider>
                </Route>
            </Switch>
        </AuthContext.Provider>
    );
};

export default Cms;
