import React from 'react';
import PropTypes from 'prop-types';

const VariableList = (props) => (
    <div className="ui-cms-variable-list">
        <div className="ui-cms-variable-list__content">
            Projekte und Szenen können individuellen HTML-Code beinhalten,
            welcher beim Aufrufen der Szene eingebettet wird.

            Um JavaScript-Code auszuführen, kann ein &lt;script&gt; Tag
            verwendet werden.
        </div>
        <br />
        <div className="ui-cms-variable-list__header">Verfügbare Variablen</div>
        <div className="ui-cms-variable-list__content">
            {props.items.map(({ variable, description }) => (
                <div className="ui-cms-variable-list__item" key={variable}>
                    <div className="ui-cms-variable-list__variable">
                        window.__cms.{variable}
                    </div>

                    <div className="ui-cms-variable-list__description">
                        {description}
                    </div>
                </div>
            ))}
            {props.replacements.map(({ replacement, description }) => (
                <div className="ui-cms-variable-list__item" key={replacement}>
                    <div className="ui-cms-variable-list__variable">
                        {`\${${replacement}}`}
                    </div>

                    <div className="ui-cms-variable-list__description">
                        {description}
                    </div>
                </div>
            ))}
        </div>
    </div>
);

VariableList.defaultProps = {
    replacements: [],
};

VariableList.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
        variable: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
    })).isRequired,
    replacements: PropTypes.arrayOf(PropTypes.shape({
        replacement: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
    })),
};

export default VariableList;
