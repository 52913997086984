import React from 'react';
import PropTypes from 'prop-types';
import MuiLink from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';

const Link = (props) => (
    <MuiLink
        href={props.href}
        to={props.to}
        component={props.to ? RouterLink : MuiLink}
        target={props.newTab ? '_blank' : null}
    >
        {props.children}
    </MuiLink>
);

Link.defaultProps = {
    href: '',
    newTab: false,
    to: '',
};

const hrefOrTo = (props, propName, componentName) => {
    if (!Object.prototype.hasOwnProperty.call(props, 'href')
            && !Object.prototype.hasOwnProperty.call(props, 'to')) {
        return new Error('Either "href" or "to" is required');
    }

    if ((propName === 'href' && props[propName].constructor !== String)
            || (propName === 'to' && props[propName].constructor !== String)) {
        return new Error(
            `Invalid prop \`${propName}\` supplied to`
                + ` \`${componentName}\`. Validation failed.`
        );
    }

    return false;
};

Link.propTypes = {
    children: PropTypes.string.isRequired,
    href: hrefOrTo,
    to: hrefOrTo,
    newTab: PropTypes.bool,
};

export default Link;
