import { useState, useEffect } from 'react';
import { findSceneByPath } from 'helpers/scenes';

const isAllowed = (domains, referrer) => domains.length === 0 || domains.some((domain) => referrer.includes(domain));
const transformConstraints = (raw) => raw?.split(',')
    .filter((constraint) => constraint !== '') // Empty constraints would essentially override all other constraints
    || [];

export default (project, currentScenePath, referrer) => {
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        const scenes = project?.scenes || [];
        const currentScene = findSceneByPath(currentScenePath, scenes);
        const projectConstraints = transformConstraints(project?.referrerConstraint);

        // If the current scene is a child scene, we need to check the referrer
        // constraints of the parent
        const rootScene = currentScene?.parentScene || currentScene;

        const sceneConstraints = transformConstraints(rootScene?.referrerConstraint);
        const combinedConstraints = [...sceneConstraints, ...projectConstraints];

        if (!rootScene?.referrerConstraintOverridesProjectConstraints && isAllowed(combinedConstraints, referrer)) {
            setHasError(false);
        } else if (rootScene?.referrerConstraintOverridesProjectConstraints && isAllowed(sceneConstraints, referrer)) {
            setHasError(false);
        } else {
            setHasError(true);
        }
    }, [project, currentScenePath]);

    return hasError;
};
