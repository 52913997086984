var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useMutation } from 'apollo-augmented-hooks';
var mutation = "\n    mutation deleteRole($id: ID!) {\n        deleteRole(id: $id) {\n            id\n        }\n    }\n";
export default (function (customer) {
    var mutate = useMutation(mutation)[0];
    return function (input) { return (mutate({
        input: input,
        modifiers: [
            {
                cacheObject: function (item) { return item; },
                evict: true,
            },
            {
                cacheObject: customer,
                fields: {
                    roles: function (_a) {
                        var includeIf = _a.includeIf;
                        return includeIf(false);
                    },
                    admins: function () {
                        var updatedAdmins = customer.admins.map(function (admin) {
                            var _a;
                            if (((_a = admin.role) === null || _a === void 0 ? void 0 : _a.id) === input) {
                                return __assign(__assign({}, admin), { role: null });
                            }
                            return admin;
                        });
                        return updatedAdmins;
                    },
                },
            },
        ],
    })); };
});
