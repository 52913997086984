import React from 'react';
import PropTypes from 'prop-types';
import Headline from 'cms/atoms/Headline';
import { ArrowRight } from '@material-ui/icons';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import moment from 'moment';
import { Divider } from '@material-ui/core';

const Changelog = ({ scene }) => {
    const renderChangeField = (field, { from, to }) => {
        // Only simple textfields can be rendered including its changes
        const changeText = (
            <>
                {from?.toString()} <ArrowRight /> {to?.toString()}
            </>
        );
        return <ListItemText primary={field} secondary={typeof from === 'string' && changeText} />;
    };

    const renderChanges = (activitylog) => activitylog.map((logEntry) => {
        const formattedDate = moment(logEntry.createdAt).fromNow();
        const changeEntries = Object.entries(logEntry.changes.updated).map(([field, change]) => (
            <ListItem key={field}>{renderChangeField(field, change)}</ListItem>
        ));

        return (
            <div key={logEntry.id}>
                <ListItem alignItems="flex-start">
                    <ListItemText
                        primary={logEntry.description}
                        secondary={(
                            <>
                                <Typography component="span" variant="body2" color="textPrimary">
                                    {/* TODO: For some reason there are entries
                                    in the changelog table that don't have an
                                    author. This should not occur since only
                                    logged in users are allowed to make changes.
                                    */}
                                    {logEntry.author?.email || 'Unbekannter Author'}
                                </Typography>
                                {' — '} {formattedDate}
                                <List>{changeEntries}</List>
                            </>
                        )}
                    />
                </ListItem>
                <Divider />
            </div>
        );
    });

    return (
        <div>
            <Headline>Bearbeitungsprotokoll</Headline>
            <List>{renderChanges([...scene.activityLog].reverse())}</List>
        </div>
    );
};

Changelog.propTypes = {
    scene: PropTypes.object.isRequired,
};

export default Changelog;
