import { useContext } from 'react';
import AuthContext from 'contexts/auth';
import { AUTH_TYPE_ADMIN } from '../auth';

export default () => {
    const authType = useContext(AuthContext);

    // Defaulting to `AUTH_TYPE_ADMIN` is a workaround that emulates the
    // behavior of the `react-apollo-models` library used until migrating to
    // `apollo-augmented-hooks`. Without it, we won't be able to access the
    // user authentication in the project/cms components without refactoring
    // the component structure.
    return { authType: authType || AUTH_TYPE_ADMIN };
};
