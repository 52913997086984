import BaseModel from 'react-apollo-models';
import { getAuthType } from 'auth';
import { FORM } from 'hooks/graphql/queries/project';

const TRANSLATION = `
    id
    entries {
        id
        text
        languageId
    }
`;

const mediaTranslation = ({ thumbnail, standard, preload } = {}) => `
    id
    mediaEntries {
        id
        languageId
        media {
            id
            url
            filename
            mimeType
            ${standard ? 'standard: variation(type: STANDARD) { url }' : ''}
            ${thumbnail ? 'thumbnail: variation(type: THUMBNAIL) { url }' : ''}
            ${preload ? 'thumbnail: variation(type: PRELOAD) { url }' : ''}
        }
    }
`;

const ACTION = `
    id
    name {
        ${TRANSLATION}
    }
    active
    mobile
    x
    y
    position {
        id
        x
        y
        z
    }
    rotation {
        id
        x
        y
        z
    }
    scale {
        id
        x
        y
        z
    }
    withLabel
    button {
        id
        image {
            id
            url
            mini: variation(type: MINI) {
                url
            }
            thumbnail: variation(type: THUMBNAIL) {
                url
            }
        }
    }
    tasks {
        id
    }
    sceneData {
        id
        scene {
            id
            path
            panorama
            panoramaChildScenes {
                id
                path
            }
            panoramaStartScene {
                id
                path
            }
            defaultViewMode
        }
    }
    exhibitorData {
        id
        scene {
            id
            path
            panorama
            panoramaChildScenes {
                id
                path
            }
            panoramaStartScene {
                id
                path
            }
            defaultViewMode
        }
    }
    infoboxData {
        id
        title {
            ${TRANSLATION}
        }
        content  {
            ${TRANSLATION}
        }
    }
    contactData {
        id
        title {
            ${TRANSLATION}
        }
        sections {
            id
            image {
                ${mediaTranslation({ standard: true })}
            }
            entries {
                id
                value {
                    ${TRANSLATION}
                }
                type
                order
            }
            order
        }
    }
    galleryData {
        id
        title {
            ${TRANSLATION}
        }
        images {
            id
            order
            file {
                ${mediaTranslation({ standard: true, thumbnail: true })}
            }
            description {
                ${TRANSLATION}
            }
        }
    }
    downloadData {
        id
        title {
            ${TRANSLATION}
        }
        downloads {
            id
            order
            thumbnailMediaId
            file {
                ${mediaTranslation()}
            }
            thumbnail {
                url
            }
            label {
                ${TRANSLATION}
            }
            description {
                ${TRANSLATION}
            }
        }
    }
    videoData {
        id
        title {
            ${TRANSLATION}
        }
        items {
            id
            url {
                ${TRANSLATION}
            }
            order
            label {
                ${TRANSLATION}
            }
        }
    }
    externalData {
        id
        url {
            ${TRANSLATION}
        }
        openInNewTab
    }
    loginData {
        id
        hideWhenLoggedIn
    }
    callingCardData {
        id
        contactEmail
        contactSubject
        contactInstructions {
            ${TRANSLATION}
        }
        contactTitle {
            ${TRANSLATION}
        }
        form {
            ${FORM}
        }
    }
    audioData {
        id
        file {
            ${mediaTranslation()}
        }
        stopicon {
            id
            url
        }
    }
`;

const SCENE_OBJECT = `
    id
    name
    position {
        id
        x
        y
        z
    }
    rotation {
        id
        x
        y
        z
    }
    scale {
        id
        x
        y
        z
    }
    scene {
        id
    }
    sceneVersion {
        id
    }
    imageData {
        id
        file {
            ${mediaTranslation({ standard: true })}
        }
    }
    videoData {
        id
        file {
            ${mediaTranslation()}
        }
        autoplay
        loop
        mute
    }
`;

const OVERLAY = `
    id
    name
    mobile
    x
    y
    width
    height
    scene {
        id
    }
    sceneVersion {
        id
    }
    imageData {
        id
        file {
            ${mediaTranslation({ standard: true })}
        }
    }
    videoData {
        id
        url {
            ${TRANSLATION}
        }
        autoplay
        loop
        mute
        controls
    }
    newstickerData {
        id
        text {
            ${TRANSLATION}
        }
    }
    htmlData {
        id
        html {
            ${TRANSLATION}
        }
    }
`;

const SCENE = `
    id
    type
    name
    path
    widget {
        ${TRANSLATION}
    }
    sponsorType
    contactInstructions {
        ${TRANSLATION}
    }
    contactTitle {
        ${TRANSLATION}
    }
    contactEmail
    contactSubject
    callingCardForm {
        ${FORM}
    }
    panorama
    panoramaStartScene {
        id
        path
    }
    showViewModeToggle
    parentScene {
        id
        path
        showViewModeToggle
        files {
            file {
                id
                url
                filename
            }
        }
        presenceTrackingEnabled
        presenceTrackingRationale {
            ${TRANSLATION}
        }
        presenceTrackingTextFieldMode
        presenceTrackingTextFieldDescription {
            ${TRANSLATION}
        }
        presenceTrackingCheckboxMode
        presenceTrackingCheckboxDescription {
            ${TRANSLATION}
        }
        referrerConstraint
        referrerConstraintOverridesProjectConstraints
        referrerConstraintErrorMessage {
            ${TRANSLATION}
        }
    }
    background {
        id
        url
        standard: variation(type: STANDARD) {
            url
        }
        preload: variation(type: PRELOAD) {
            url
        }
    }
    videoBackground {
        ${TRANSLATION}
    }
    videoBackgroundEnabled
    audioBackground {
        id
        media {
            id
            filename
            url
        }
        loop
    }
    actions {
        ${ACTION}
    }
    objects {
        ${SCENE_OBJECT}
    }
    overlays {
        ${OVERLAY}
    }
    tags {
        id
        name
    }
    tasks {
        id
    }
    files {
        file {
            id
            url
            filename
        }
    }
    versions {
        id
        name
        startTime
        endTime
        widget {
            ${TRANSLATION}
        }
        videoBackground {
            ${TRANSLATION}
        }
        videoBackgroundEnabled
        audioBackground {
            id
            media {
                id
                filename
                url
            }
            loop
        }
        background {
            id
            url
            standard: variation(type: STANDARD) {
                url
            }
        }
        actions {
            ${ACTION}
        }
        objects {
            ${SCENE_OBJECT}
        }
        overlays {
            ${OVERLAY}
        }
    }
    presenceTrackingEnabled
    presenceTrackingRationale {
        ${TRANSLATION}
    }
    presenceTrackingTextFieldMode
    presenceTrackingTextFieldDescription {
        ${TRANSLATION}
    }
    presenceTrackingCheckboxMode
    presenceTrackingCheckboxDescription {
        ${TRANSLATION}
    }
    referrerConstraint
    referrerConstraintOverridesProjectConstraints
    referrerConstraintErrorMessage {
        ${TRANSLATION}
    }
`;

const PROJECT = `
    id
    name
    openingType
    openingTimes {
        id
        startTime
        endTime
        description
        expectedVisitors
    }
    path
    websiteTitle {
        ${TRANSLATION}
    }
    audioOnLabel {
        ${TRANSLATION}
    }
    audioOffLabel {
        ${TRANSLATION}
    }
    closedScreen {
        ${TRANSLATION}
    }
    scaleType
    widget {
        ${TRANSLATION}
    }
    loginType
    loginDialogType
    loginInstructions {
        ${TRANSLATION}
    }
    externalLoginManageLoginUrl
    externalLoginRegasusLoginUrl
    externalLoginEventsairLoginUrl
    externalLoginHapagLloydLoginUrl
    externalLoginPlazzLoginUrl
    demoMode
    loginTracking
    registrationEnabled
    registrationExternalUrl
    audioBackgrounds {
        id
    }
    registrationInstructions {
        ${TRANSLATION}
    }
    registrationFields {
        id
        label {
            ${TRANSLATION}
        }
        mandatory
        type
        description {
            ${TRANSLATION}
        }
        order
        options {
            id
            label {
                ${TRANSLATION}
            }
            value
            order
        }
    }
    tosEnabled
    tosType
    tosCustomContent {
        ${TRANSLATION}
    }
    tosCustomCheckbox {
        ${TRANSLATION}
    }
    defaultLanguage {
        id
        code
        systemCode
    }
    languages {
        id
        code
        systemCode
    }
    exhibitorListEnabled
    exhibitorListLabel {
        ${TRANSLATION}
    }
    loginLabelUser {
        ${TRANSLATION}
    }
    loginLabelPassword {
        ${TRANSLATION}
    }
    referrerConstraint
    referrerErrorMessage {
        ${TRANSLATION}
    }
    logo {
        id
        url
        thumbnail: variation(type: THUMBNAIL) {
            url
        }
    }
    startScene {
        id
    }
    triggers {
        id
        name
    }
    scenes {
        ${SCENE}
        panoramaChildScenes {
            ${SCENE}
        }
    }
    files {
        file {
            id
            url
            filename
        }
    }
    challenge {
        id
        active
        allowPartialSubmission
        name {
            ${TRANSLATION}
        }
        description {
            ${TRANSLATION}
        }
        email
        submitInstructions {
            ${TRANSLATION}
        }
        form {
            id
            fields {
                id
                label {
                    ${TRANSLATION}
                }
                mandatory
                type
                description {
                    ${TRANSLATION}
                }
                options {
                    id
                    label {
                        ${TRANSLATION}
                    }
                    value
                    order
                }
            }
        }
        tasks {
            id
            name {
                ${TRANSLATION}
            }
            description {
                ${TRANSLATION}
            }
            count
            order
            systemTriggers
            scenes {
                id
            }
            actions {
                id
            }
            triggers {
                id
            }
        }
    }
    menuItems {
        id
        order
        label {
            ${TRANSLATION}
        }
        sceneData {
            id
            scene {
                id
                path
            }
        }
        infoboxData {
            id
            title {
                ${TRANSLATION}
            }
            content {
                ${TRANSLATION}
            }
        }
        externalData {
            id
            url {
                ${TRANSLATION}
            }
            openInNewTab
        }
    }
    styling {
        id
        backgroundColor
        textColor
        transparentNavbar
        font {
            id
            url
        }
    }
    customer {
        id
    }
    presenceTrackingEnabled
    presenceTrackingInterval
`;

const VISITOR = `
    id
    externalId
    project {
        name
    }
    account {
        email
    }
    registrationData {
        value
        field {
            label {
                ${TRANSLATION}
            }
        }
    }
`;

const ME = `
    id
    name
    company
    position
    email
    salutation
    title
    phone
    preferredLanguage {
        code
    }
    videoConferenceUrl
    externalId
`;

export default class ProjectModel extends BaseModel {
    static query = {
        query: `
            query projectByPath($path: String!) {
                projectByPath(path: $path) {
                    ${PROJECT}
                }
            }
        `,
        variables: (props) => ({
            path: props.match.params.projectPath,
        }),
        context: (props) => ({
            authType: getAuthType(props.match.params.projectPath),
        }),
    }

    static mutations = [{
        mutation: `
            mutation createToken($input: CreateTokenInput!) {
                createToken(input: $input) {
                    identityToken
                    project {
                        ${PROJECT}
                    }
                    roleName
                    me {
                        ${ME}
                    }
                    myAccount {
                        id
                        admin {
                            id
                            isSuperAdmin
                            customer {
                                id
                            }
                        }
                    }
                    trackingId
                    trackingValue
                }
            }
        `,
        optimisticResponse: null,
        cacheUpdatePath: ({ item }) => ({
            me: {
                $set: item.me,
            },
            myAccount: {
                $set: item.myAccount,
            },
        }),
    }, {
        mutation: `
            mutation createPasswordProjectToken($input: CreatePasswordProjectTokenInput!) {
                createPasswordProjectToken(input: $input) {
                    identityToken
                    project {
                        ${PROJECT}
                    }
                    trackingId
                    trackingValue
                }
            }
        `,
        optimisticResponse: null,
    }, {
        mutation: `
            mutation createCouponCodeProjectToken($input: CreateCouponCodeProjectTokenInput!) {
                createCouponCodeProjectToken(input: $input) {
                    identityToken
                    project {
                        ${PROJECT}
                    }
                    me {
                        ${ME}
                    }
                    trackingId
                    trackingValue
                }
            }
        `,
        optimisticResponse: null,
        cacheUpdatePath: ({ item }) => ({
            me: {
                $set: item.me,
            },
        }),
    }, {
        mutation: `
            mutation createExternalManageProjectToken($input: CreateExternalManageProjectTokenInput!) {
                createExternalManageProjectToken(input: $input) {
                    identityToken
                    project {
                        ${PROJECT}
                    }
                    me {
                        ${ME}
                    }
                    trackingId
                    trackingValue
                }
            }
        `,
        optimisticResponse: null,
        cacheUpdatePath: ({ item }) => ({
            me: {
                $set: item.me,
            },
        }),
    }, {
        mutation: `
            mutation createExternalRegasusProjectToken($input: CreateExternalRegasusProjectTokenInput!) {
                createExternalRegasusProjectToken(input: $input) {
                    identityToken
                    project {
                        ${PROJECT}
                    }
                    me {
                        ${ME}
                    }
                    trackingId
                    trackingValue
                }
            }
        `,
        optimisticResponse: null,
        cacheUpdatePath: ({ item }) => ({
            me: {
                $set: item.me,
            },
        }),
    }, {
        mutation: `
            mutation createExternalEventsairProjectToken($input: CreateExternalEventsairProjectTokenInput!) {
                createExternalEventsairProjectToken(input: $input) {
                    identityToken
                    project {
                        ${PROJECT}
                    }
                    me {
                        ${ME}
                    }
                    trackingId
                    trackingValue
                }
            }
        `,
        optimisticResponse: null,
        cacheUpdatePath: ({ item }) => ({
            me: {
                $set: item.me,
            },
        }),
    }, {
        mutation: `
            mutation createExternalHapagLloydProjectToken($input: CreateExternalHapagLloydProjectTokenInput!) {
                createExternalHapagLloydProjectToken(input: $input) {
                    identityToken
                    project {
                        ${PROJECT}
                    }
                    me {
                        ${ME}
                    }
                    trackingId
                    trackingValue
                }
            }
        `,
        optimisticResponse: null,
        cacheUpdatePath: ({ item }) => ({
            me: {
                $set: item.me,
            },
        }),
    }, {
        mutation: `
            mutation createExternalPlazzProjectToken($input: CreateExternalPlazzProjectTokenInput!) {
                createExternalPlazzProjectToken(input: $input) {
                    identityToken
                    project {
                        ${PROJECT}
                    }
                    me {
                        ${ME}
                    }
                    trackingId
                    trackingValue
                }
            }
        `,
        optimisticResponse: null,
        cacheUpdatePath: ({ item }) => ({
            me: {
                $set: item.me,
            },
        }),
    }, {
        mutation: `
            mutation createExternalXingEventsProjectToken($input: CreateExternalXingEventsProjectTokenInput!) {
                createExternalXingEventsProjectToken(input: $input) {
                    identityToken
                    project {
                        ${PROJECT}
                    }
                    me {
                        ${ME}
                    }
                    trackingId
                    trackingValue
                }
            }
        `,
        optimisticResponse: null,
        cacheUpdatePath: ({ item }) => ({
            me: {
                $set: item.me,
            },
        }),
    }, {
        mutation: `
            mutation sendCallingCardViaScene($input: SendCallingCardViaSceneInput!) {
                sendCallingCardViaScene(input: $input) {
                    id
                }
            }
        `,
        optimisticResponse: null,
        context: (props) => ({
            authType: getAuthType(props.match.params.projectPath),
        }),
        cacheUpdatePath: ({ mutationVariables, cacheData }) => {
            if (cacheData.me) {
                return {
                    me: {
                        name: { $set: mutationVariables.callingCard.name },
                        company: { $set: mutationVariables.callingCard.company },
                        position: { $set: mutationVariables.callingCard.position },
                        email: { $set: mutationVariables.callingCard.email },
                        phone: { $set: mutationVariables.callingCard.phone },
                        videoConferenceUrl: {
                            $set: mutationVariables.callingCard.videoConferenceUrl,
                        },
                    },
                };
            }

            return {};
        },
    }, {
        mutation: `
            mutation sendCallingCardViaAction($input: SendCallingCardViaActionInput!) {
                sendCallingCardViaAction(input: $input) {
                    id
                }
            }
        `,
        optimisticResponse: null,
        context: (props) => ({
            authType: getAuthType(props.match.params.projectPath),
        }),
        cacheUpdatePath: ({ mutationVariables, cacheData }) => {
            if (cacheData.me) {
                return {
                    me: {
                        name: { $set: mutationVariables.callingCard.name },
                        company: { $set: mutationVariables.callingCard.company },
                        position: { $set: mutationVariables.callingCard.position },
                        email: { $set: mutationVariables.callingCard.email },
                        phone: { $set: mutationVariables.callingCard.phone },
                        videoConferenceUrl: {
                            $set: mutationVariables.callingCard.videoConferenceUrl,
                        },
                    },
                };
            }

            return {};
        },
    }, {
        mutation: `
            mutation requestPasswordReset($input: RequestPasswordResetInput!) {
                requestPasswordReset(input: $input)
            }
        `,
        optimisticResponse: null,
    }, {
        mutation: `
            mutation setVisitorPassword($input: SetVisitorPasswordInput!) {
                setVisitorPassword(input: $input) {
                    ${VISITOR}
                }
            }
        `,
        optimisticResponse: null,
    }, {
        mutation: `
            mutation registerVisitor($input: RegisterVisitorInput!) {
                registerVisitor(input: $input) {
                    ${VISITOR}
                }
            }
        `,
        optimisticResponse: null,
    }, {
        mutation: `
            mutation finishChallenge($input: FinishChallengeInput!) {
                finishChallenge(input: $input) {
                    id
                }
            }
        `,
        optimisticResponse: null,
        context: (props) => ({
            authType: getAuthType(props.match.params.projectPath),
        }),
    }]
}
