import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';

const ErrorAlert = (props) => (
    <Alert variant="danger">
        {props.children}
    </Alert>
);

ErrorAlert.propTypes = {
    children: PropTypes.string.isRequired,
};

export default ErrorAlert;
