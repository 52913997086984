import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import MeContext from 'contexts/me';
import Button from 'cms/atoms/Button';
import Headline from 'cms/atoms/Headline';
import Datagrid from 'cms/atoms/Datagrid';
import { notificationPositions } from 'helpers/notifications';
import useTranslate from 'hooks/useTranslate';
import Editor from '../editors/Project/Notification';

const Live = (props) => {
    const t = useTranslate();
    const [notificationEditorState, setNotificationEditorState] = useState({
        isOpen: false,
        id: null,
    });

    const openNotificationEditorHandler = (id) => () => {
        setNotificationEditorState({ isOpen: true, id });
    };

    const closeNotificationEditorHandler = () => {
        setNotificationEditorState({ ...notificationEditorState, isOpen: false });
    };

    const me = useContext(MeContext);
    const { notifications } = props.project;

    return (
        <>
            <Headline
                action={me.hasWriteAccessToFeature('project.notifications') ? (
                    <Button
                        icon="add"
                        onClick={openNotificationEditorHandler(null)}
                    >
                        Neue Benachrichtigung
                    </Button>
                ) : null}
            >
                Vergangene Benachrichtigungen
            </Headline>

            <Editor
                isOpen={notificationEditorState.isOpen}
                onClose={closeNotificationEditorHandler}
                project={props.project}
                notification={
                    notifications.find(({ id }) => id === notificationEditorState.id)
                }
            />

            <Datagrid
                checkboxSelection={false}
                pageSize={25}
                columns={[
                    { field: 'title', headerName: 'Titel', width: 200 },
                    { field: 'sentAt', headerName: 'Gesendet am', width: 200 },
                    { field: 'duration', headerName: 'Dauer (in Sekunden)', width: 200 },
                    { field: 'position', headerName: 'Position', width: 200 },
                ]}
                rows={
                    [...notifications]
                        .sort((a, b) => a.sentAt > b.sentAt)
                        .map((notification) => ({
                            id: notification.id,
                            title: t(notification.title),
                            sentAt: notification.sentAt,
                            duration: notification.duration || 'Unbegrenzt',
                            position: notificationPositions.find(({ value }) => value === notification.position).label,
                        }))
                }
            />
        </>
    );
};

Live.propTypes = {
    project: PropTypes.object.isRequired,
};

export default Live;
