import { useMutation } from 'apollo-augmented-hooks';
import { BUTTON } from '../queries/settings';

const mutation = `
    mutation updateDefaultButtons($input: UpdateDefaultButtonsInput!) {
        updateDefaultButtons(input: $input) {
            buttons {
                ${BUTTON}
            }
        }
    }
`;

export default () => {
    const [mutate] = useMutation(mutation);

    return (input) => (
        mutate({
            input,
            modifiers: [{
                fields: {
                    buttons: ({ includeIf }) => includeIf(true),
                },
            }],
        })
    );
};
