import React, { useEffect, useState } from 'react';
import VideoPlayer from 'react-player/lazy';
import useTranslate from 'hooks/useTranslate';
import useTracking from 'hooks/useTracking';
import { TRACKING_SHOW_VIDEOS, TRACKING_PLAY_VIDEO } from 'helpers/tracking';
import PropTypes from 'prop-types';
import Icon from 'Icon';

const VideoContent = (props) => {
    const tracking = useTracking();
    const t = useTranslate();
    const [fullSizeVideo, setFullSizeVideo] = useState(null);
    const videos = [...props.videos].sort((a, b) => a.order - b.order);

    useEffect(() => {
        closeFullSizeVideo();
        if (props.title) {
            // TRACKCORE:
            //  event:'showVideos', ressource: props.title, data: {scene: props.sceneName, customer: customerId}
            //  SCENE CONTENT: video button clicked
            // console.log('%cTRACKCORE: showVideos', 'background: #00a8ff; font-weight: bold;', props.title);
            tracking.trackCoreEvent({
                action: 'showVideos',
                resourceType: 'title',
                resourceName: props.title,
            });
            tracking.trackEvent(props.sceneName, TRACKING_SHOW_VIDEOS, props.title);
        }
    }, []);

    const onVideoReady = (player) => {
        setFullSizeVideo(player.props.url);
    };

    const onVideoPlayHandler = (url) => () => {
        // TRACKCORE:
        //  event:'playVideos', ressource: url, data: {scene: props.sceneName, customer: customerId}
        //  SCENE CONTENT: video button clicked --> play button clicked
        // console.log('%cTRACKCORE: playVideos', 'background: #00a8ff; font-weight: bold;', url);
        tracking.trackCoreEvent({
            action: 'playVideos',
            resourceType: 'url',
            resourceName: url,
        });
        tracking.trackEvent(props.sceneName, TRACKING_PLAY_VIDEO, url);
    };

    const closeFullSizeVideo = () => {
        setFullSizeVideo(null);
    };

    const renderVideo = () => {
        if (videos.length === 1) {
            const url = t(videos[0].url);
            return (
                <div className="web-video-dialog__video">
                    <VideoPlayer
                        url={url}
                        controls
                        height="100%"
                        width="100%"
                        onPlay={onVideoPlayHandler(url)}
                    />
                </div>
            );
        }

        if (fullSizeVideo) {
            return (
                <>
                    <div
                        className="web-video-dialog__back"
                        onClick={closeFullSizeVideo}
                    >
                        <Icon small type="back" /> {t('web.videos.back')}
                    </div>

                    <div className="web-video-dialog__video">
                        <VideoPlayer
                            url={fullSizeVideo}
                            controls
                            playing
                            height="100%"
                            width="100%"
                            onPlay={onVideoPlayHandler(fullSizeVideo)}
                        />
                    </div>
                </>
            );
        }

        return videos.map((video) => (
            <div
                className="web-video-dialog__item"
                key={video.id}
            >
                <div className="web-video-dialog__video">
                    <VideoPlayer
                        url={t(video.url)}
                        light
                        controls
                        height="100%"
                        width="100%"
                        onReady={onVideoReady}
                    />
                </div>

                <div className="web-video-dialog__label">
                    {t(video.label)}
                </div>
            </div>
        ));
    };

    return (
        <div className="web-video-dialog">
            {renderVideo()}
        </div>
    );
};

VideoContent.defaultProps = {
    title: null,
};

VideoContent.propTypes = {
    title: PropTypes.string,
    videos: PropTypes.array.isRequired,
    sceneName: PropTypes.string.isRequired,
};

export default VideoContent;
