import React, { useContext } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import BsToast from 'react-bootstrap/Toast';
import StylingContext from 'contexts/styling';

const Toast = (props) => {
    const styling = useContext(StylingContext);

    return (
        <BsToast
            show={props.isOpen}
            onClose={props.onClose}
            delay={props.delay}
            autohide={!!props.delay}
        >
            <BsToast.Header
                closeButton={!!props.onClose}
                style={{
                    backgroundColor: styling?.backgroundColor,
                    color: styling?.textColor,
                }}
            >
                <strong className="mr-auto">{props.title}</strong>
            </BsToast.Header>

            <BsToast.Body
                className={classNames(
                    'ui-web-toast',
                    props.centeredText && 'ui-web-toast--centered-text'
                )}
            >
                {props.children}
            </BsToast.Body>
        </BsToast>
    );
};

Toast.defaultProps = {
    onClose: null,
    title: '',
    delay: null,
    centeredText: false,
};

Toast.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    title: PropTypes.string,
    children: PropTypes.node.isRequired,
    delay: PropTypes.number,
    centeredText: PropTypes.bool,
};

export default Toast;
