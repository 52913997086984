import React, { useState } from 'react';
import PropTypes from 'prop-types';
import HomeModel from 'models/cms/home';
import Button from 'cms/atoms/Button';
import Headline from 'cms/atoms/Headline';
import Card from 'cms/molecules/Card';
import Grid from 'cms/molecules/Grid';
import Container from 'cms/organisms/Container';
import CustomerEditor from './editors/Customer';

const Home = (props) => {
    const [isCustomerEditorOpen, setIsCustomerEditorOpen] = useState(false);

    const goToCustomerHandler = (id) => () => {
        props.history.push(`/cms/customer/${id}`);
    };

    const openCustomerEditor = () => {
        setIsCustomerEditorOpen(true);
    };

    const closeCustomerEditor = () => {
        setIsCustomerEditorOpen(false);
    };

    const renderContent = () => {
        const customers = [...props.data.customers].sort((a, b) => a.name.localeCompare(b.name));

        return (
            <>
                <Headline
                    action={(
                        <Button
                            icon="add"
                            onClick={openCustomerEditor}
                        >
                            Neuer Kunde
                        </Button>
                    )}
                >
                    Kunden
                </Headline>

                <CustomerEditor
                    isOpen={isCustomerEditorOpen}
                    onClose={closeCustomerEditor}
                />

                <Grid>
                    {customers.map((customer) => (
                        <Grid item size={12} key={customer.id}>
                            <Card
                                headerLabel={customer.name}
                                onClick={goToCustomerHandler(customer.id)}
                            />
                        </Grid>
                    ))}
                </Grid>
            </>
        );
    };

    return (
        <Container>{!props.data.loading && renderContent()}</Container>
    );
};

Home.propTypes = {
    data: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};

export default HomeModel.graphql(Home);
