import React, { useState } from 'react';
import mediaUrl from 'helpers/mediaUrl';
import useSettings from 'hooks/graphql/queries/settings';
import Button from 'cms/atoms/Button';
import ButtonList from 'cms/atoms/ButtonList';
import Headline from 'cms/atoms/Headline';
import KeyValueTable from 'cms/molecules/KeyValueTable';
import Container from 'cms/organisms/Container';
import SettingsEditor from './editors/Settings';

const Settings = () => {
    const [isEditorOpen, setIsEditorOpen] = useState(false);
    const { data, loading } = useSettings();

    const openEditor = () => {
        setIsEditorOpen(true);
    };

    const closeEditor = () => {
        setIsEditorOpen(false);
    };

    const renderContent = () => {
        const buttons = [...data.buttons].sort((a, b) => (
            a.name.localeCompare(b.name)
        ));

        return (
            <>
                <Headline
                    action={(
                        <Button
                            icon="edit"
                            onClick={openEditor}
                        >
                            Bearbeiten
                        </Button>
                    )}
                >
                    Szenen
                </Headline>

                <SettingsEditor
                    isOpen={isEditorOpen}
                    onClose={closeEditor}
                />

                <KeyValueTable
                    wrappingDividers
                    items={[
                        {
                            key: 'Default-Buttons',
                            value: (
                                <ButtonList
                                    buttons={buttons.map((button) => ({
                                        image: mediaUrl(button.image, 'mini'),
                                        name: button.name,
                                        type: button.type,
                                    }))}
                                />
                            ),
                            align: 'top',
                        },
                    ]}
                />
            </>
        );
    };

    return (
        <Container>{!loading && renderContent()}</Container>
    );
};

export default Settings;
