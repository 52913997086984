import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import FontRatioContext from 'contexts/fontRatio';
import useToggle from 'hooks/useToggle';
import useTranslate from 'hooks/useTranslate';
import mediaUrl from 'helpers/mediaUrl';
import { findLoginType } from 'helpers/loginTypes';
import viewModes from 'helpers/viewModes';
import Image from 'Image';
import Action from 'shared/atoms/Action';
import Overlay from 'shared/atoms/Overlay';
import Button from 'cms/atoms/Button';
import FormFieldList from 'cms/atoms/FormFieldList';
import Link from 'cms/atoms/Link';
import Headline from 'cms/atoms/Headline';
import FileList from 'cms/atoms/FileList';
import TaskList from 'cms/atoms/TaskList';
import Warning from 'cms/atoms/Warning';
import LabelList from 'cms/atoms/LabelList';
import Confirm from 'components/ui/cms/molecules/Confirm';
import MeContext from 'contexts/me';
import KeyValueTable from 'cms/molecules/KeyValueTable';
import SceneEditor from '../editors/Scene';

const General = (props) => {
    const t = useTranslate();
    const history = useHistory();
    const me = useContext(MeContext);
    const [imageSize, setImageSize] = useState(0);
    const [sceneEditorState, setSceneEditorState] = useState({
        isOpen: false,
        isDuplicatedScene: false,
    });
    const [isDeleteDialogOpen, openDeleteDialog, closeDeleteDialog] = useToggle(false);
    const showPanoramaChildSceneData = (
        props.scene.panoramaChildScenes.length > 0
        && !props.scene.panorama
    );

    const openSceneEditor = (isDuplicatedScene) => {
        setSceneEditorState({
            ...sceneEditorState,
            isOpen: true,
            isDuplicatedScene: !!isDuplicatedScene,
        });
    };

    const closeSceneEditor = () => {
        setSceneEditorState({
            ...sceneEditorState,
            isOpen: false,
            isDuplicatedScene: false,
        });
    };

    const storeImageSize = (event) => {
        setImageSize(event.target.width);
    };

    const deleteScene = () => {
        props.deleteScene(props.scene.id);
        history.replace(`/cms/project/${props.scene.project.id}`);
    };

    const renderSceneDisplay = () => {
        if (!props.scene.background) {
            return '';
        }

        return (
            <div className="cms-scene__display">
                <FontRatioContext.Provider value={imageSize}>
                    <Image
                        url={mediaUrl(props.scene.background, 'standard')}
                        onLoad={storeImageSize}
                    />

                    {props.scene.overlays.map((overlay) => (
                        <Overlay
                            key={overlay.id}
                            width={overlay.width}
                            height={overlay.height}
                            style={{
                                left: `${overlay.x * 100}%`,
                                top: `${overlay.y * 100}%`,
                            }}
                            overlay={overlay}
                            preview
                        />
                    ))}

                    {props.scene.actions.map((action) => (
                        <Action
                            key={action.id}
                            image={mediaUrl(action.button.image, 'mini')}
                            position={{
                                type: 'within-scene',
                                left: action.x * 100,
                                top: action.y * 100,
                            }}
                            label={action.withLabel ? action.name : null}
                        />
                    ))}

                    <Action
                        image="/images/back.png"
                        position={{
                            type: 'over-scene',
                            left: 3,
                            top: 3,
                        }}
                    />

                    {props.scene.contactEmail && (
                        <Action
                            image="/images/callingCard.png"
                            position={{
                                type: 'over-scene',
                                left: 97,
                                top: 3,
                            }}
                        />
                    )}
                </FontRatioContext.Provider>
            </div>
        );
    };

    const renderContact = () => (
        <>
            <p>{props.scene.contactEmail || 'inaktiv'}</p>
            <div>Dialog-Titel: {t(props.scene.contactTitle) || 'Standard'}</div>
            <div>Anleitung: {t(props.scene.contactInstructions) || 'Standard'}</div>
            <div>Betreff: {props.scene.contactSubject || 'Standard'}</div>
        </>
    );

    const renderPresenceTracking = () => {
        const textFieldEnabled = props.scene.presenceTrackingTextFieldMode !== 'DISABLED';
        const checkboxEnabled = props.scene.presenceTrackingCheckboxMode !== 'DISABLED';
        if (!props.presenceTrackingEnabled || !props.scene.presenceTrackingEnabled) {
            return 'Inaktiv';
        }
        if (textFieldEnabled || checkboxEnabled) {
            return 'Aktiv (mit Dialog)';
        }
        return 'Aktiv';
    };

    const renderQuota = () => (
        <>{props.scene.quota?.name || 'Keine Einschränkung'}</>
    );

    return (
        <>
            <Headline
                action={me.hasWriteAccessToFeature('scene.general') ? (
                    <Button
                        icon="edit"
                        onClick={() => openSceneEditor(false)}
                    >
                        Bearbeiten
                    </Button>
                ) : null}
            >
                {props.scene.name}
            </Headline>

            <SceneEditor
                isOpen={sceneEditorState.isOpen}
                onClose={closeSceneEditor}
                isDuplicatedScene={sceneEditorState.isDuplicatedScene}
                id={props.scene.id}
                projectId={props.scene.project.id}
                buttons={props.buttons}
                projectButtons={props.scene.project.buttons}
                scenes={props.scene.project.scenes}
                challenge={props.scene.project.challenge}
                projectFiles={props.scene.project.files}
                project={props.scene.project}
                accessGroups={props.scene.project.accessGroups}
                loginType={props.scene.project.loginType}
                tags={props.scene.project.tags}
                projectPresenceTrackingEnabled={props.presenceTrackingEnabled}
            />

            <KeyValueTable
                wrappingDividers
                items={[
                    {
                        key: 'Art',
                        value: props.scene.type === 'SCENE' ? 'Szene' : 'Aussteller',
                    },
                    {
                        key: 'Vorschau',
                        value: (
                            <Button
                                icon="view"
                                href={`/event/${props.scene.project.path}/${props.scene.path}`}
                                newTab
                            >
                                Ansehen
                            </Button>
                        ),
                    },
                    {
                        key: 'Pfad in der URL',
                        value: props.scene.path,
                    },
                    {
                        key: 'Sponsor',
                        value: props.scene.sponsorType === 'STANDARD' ? 'Ja' : 'Nein',
                        available: props.scene.type === 'EXHIBITOR',
                    },
                    {
                        key: 'Schlagwörter',
                        value: props.scene.tags.length > 0
                            ? props.scene.tags.map(({ name }) => name).join(', ')
                            : 'Keine',
                        available: props.scene.type === 'EXHIBITOR',
                    },
                    {
                        key: 'Visitenkarten-Briefkasten',
                        value: props.scene.contactEmail ? renderContact() : 'inaktiv',
                        available: props.scene.type === 'EXHIBITOR',
                    },
                    {
                        key: 'Visitenkarten-Felder',
                        available: props.scene.type === 'EXHIBITOR' && props.scene.callingCardForm,
                        value: props.scene.callingCardForm && (
                            <FormFieldList
                                fields={[...props.scene.callingCardForm.fields].sort((a, b) => a.order - b.order)}
                                showCount
                            />
                        ),
                    },
                    {
                        key: 'Szenendateien',
                        value: props.scene.files.length > 0 ? (
                            <FileList
                                files={
                                    [...props.scene.files]
                                        .map((({ file }) => ({ label: file.filename })))
                                        .sort((a, b) => a.label.localeCompare(b.label))
                                }
                            />
                        ) : 'Keine',
                        align: 'top',
                    },
                    {
                        key: 'Audiokulisse',
                        value: props.scene.audioBackground ? props.scene.audioBackground.media.filename : 'Keine',
                        align: 'top',
                    },
                    {
                        key: 'Eigener Code',
                        value: t(props.scene.widget) ? 'Ja' : 'Nein',
                    },
                    {
                        key: 'Zugriffsbeschränkungen',
                        value: props.scene.restrictions.length > 0 ? (
                            props.scene.restrictions.map(({ name }) => name).join(', ')
                        ) : (
                            'Keine'
                        ),
                        available: findLoginType(props.scene.project.loginType).withAccessGroups,
                        align: 'top',
                    },
                    {
                        key: 'Referrer-Einschränkung',
                        value: props.scene.referrerConstraint ? (
                            <LabelList
                                labels={props.scene.referrerConstraint.split(',').map((constraint) => ({
                                    label: constraint,
                                }))}
                            />
                        ) : 'keine',
                        align: 'top',
                    },
                    {
                        key: 'Aufgaben für Rally',
                        value: (
                            <TaskList
                                tasks={[...props.scene.tasks]
                                    .sort((a, b) => a.order - b.order)
                                    .map(({ id, name }) => ({ id, name }))}
                            />
                        ),
                        available: props.scene.tasks.length > 0,
                        align: 'top',
                    },
                    {
                        key: 'Kontingent',
                        value: renderQuota(),
                    },
                    {
                        key: 'Panorama-Szene',
                        value: props.scene.panorama ? 'Ja' : 'Nein',
                    },
                    {
                        key: 'Start-Panorama-Unterszene',
                        value: props.scene.panoramaStartScene ? (
                            props.scene.panoramaStartScene.name
                        ) : (
                            <Warning>
                                Es wurde noch keine Panorama-Startszene festgelegt
                            </Warning>
                        ),
                        available: showPanoramaChildSceneData,
                    },
                    {
                        key: 'Initiale Ansicht',
                        value: viewModes.find(({ value }) => (
                            value === props.scene.defaultViewMode
                        )).label,
                        available: showPanoramaChildSceneData,
                    },
                    {
                        key: 'Wechsel der Ansicht erlauben',
                        value: props.scene.showViewModeToggle ? 'Ja' : 'Nein',
                        available: showPanoramaChildSceneData,
                    },
                    {
                        key: 'Videohintergrund',
                        value: props.scene.videoBackground && (
                            <Link
                                href={t(props.scene.videoBackground)}
                                newTab
                            >
                                {t(props.scene.videoBackground)}
                            </Link>
                        ),
                        available: props.scene.videoBackgroundEnabled,
                    },
                    {
                        key: 'Ansicht',
                        value: renderSceneDisplay(),
                        align: 'top',
                    },
                    {
                        key: 'Anwesenheitstracking',
                        value: renderPresenceTracking(),
                        available: props.presenceTrackingEnabled,
                    },
                ]}
            />

            <div className="ui-cms-button-container--centered">
                {me.hasWriteAccessToFeature('scene.new') ? (
                    <Button
                        icon="copy"
                        onClick={() => openSceneEditor(true)}
                        className="ui-cms-button"
                    >
                        Szene duplizieren
                    </Button>
                ) : null}

                {me.hasWriteAccessToFeature('scene.delete') ? (
                    <Button
                        icon="delete"
                        onClick={openDeleteDialog}
                        className="ui-cms-button--danger"
                    >
                        Szene löschen
                    </Button>
                ) : null}
            </div>

            <Confirm
                title="Soll diese Szene wirklich gelöscht werden?"
                onConfirm={deleteScene}
                confirmLabel="Ja, löschen"
                onCancel={closeDeleteDialog}
                cancelLabel="Nein, abbrechen"
                isOpen={isDeleteDialogOpen}
                destructive
            >
                <Warning>
                    Achtung: Alle Aktionen und Navigationspunkte, die auf diese Szene verlinken,
                    sowie alle Unterszenen dieser Szene werden ebenfalls gelöscht!
                </Warning>
            </Confirm>
        </>
    );
};

General.defaultProps = {
    presenceTrackingEnabled: false,
};

General.propTypes = {
    scene: PropTypes.object.isRequired,
    buttons: PropTypes.array.isRequired,
    deleteScene: PropTypes.func.isRequired,
    presenceTrackingEnabled: PropTypes.bool,
};

export default General;
