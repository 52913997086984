import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { apiResponseToFormState } from 'helpers/form';
import useProject from 'hooks/graphql/queries/project';
import useCreateProjectVisitorsWithAccounts from 'hooks/graphql/mutations/createProjectVisitorsWithAccounts';
import useCreateProjectVisitorsWithCouponCodes from 'hooks/graphql/mutations/createProjectVisitorsWithCouponCodes';
import EditingAdmin from 'EditingAdmin';
import CodeEditor from 'cms/atoms/CodeEditor';
import Dialog from 'cms/molecules/Dialog';
import KeyValueTable from 'cms/molecules/KeyValueTable';

const GENERAL_HEADERS = 'privileges;name;salutation;title;preferredLanguage;trackingId;company;position;phone;videoConferenceUrl';
const ACCOUNT_HEADERS = `email;password;${GENERAL_HEADERS}`;
const COUPON_HEADERS = `couponCode;${GENERAL_HEADERS}`;

const Visitors = (props) => {
    const params = useParams();

    const { data, loading } = useProject(params.id);
    const { project } = !loading && data;

    const createProjectVisitorsWithAccounts = useCreateProjectVisitorsWithAccounts();
    const createProjectVisitorsWithCouponCodes = useCreateProjectVisitorsWithCouponCodes();
    const csvHeaders = !loading && project.loginType === 'ACCOUNTS' ? ACCOUNT_HEADERS : COUPON_HEADERS;
    const defaultValues = { csv: csvHeaders };
    const [state, setState] = useState(apiResponseToFormState(project, defaultValues));
    const validators = [{
        name: 'csv',
        message: () => 'Bitte gib CSV-Daten ein.',
        serverError: 'CSV_EMPTY',
    }, {
        name: 'csv',
        message: () => `Bitte nutze die folgenden Header: ${csvHeaders}.`,
        serverError: 'CSV_INVALID_HEADERS',
    }, {
        name: 'csv',
        message: () => 'Eine oder mehrere Zeilen entsprechen nicht den Headern.',
        serverError: 'CSV_RECORD_DONT_MATCH_COLUMNS_LENGTH',
    }, {
        name: 'csv',
        message: () => 'Eine oder mehrere Zeilen haben Zugriffsrechte, die nicht existieren.',
        serverError: 'CSV_PRIVILEGE_DOES_NOT_EXIST',
    }, {
        name: 'csv',
        message: () => 'Eine oder mehrere Zeilen beinhalten duplizierte E-Mail-Adressen.',
        serverError: 'CSV_DUPLICATE_EMAILS',
    }, {
        name: 'csv',
        message: () => 'Eine oder mehrere Zeilen beinhalten E-Mail-Adressen, die in diesem Projekt schon genutzt werden.',
        serverError: 'CSV_EMAIL_IN_USE',
    }];

    useEffect(() => {
        if (props.isOpen) {
            setState(apiResponseToFormState(project, defaultValues));
        }
    }, [props.isOpen]);

    const save = async (values) => {
        const mutation = project.loginType === 'ACCOUNTS'
            ? createProjectVisitorsWithAccounts
            : createProjectVisitorsWithCouponCodes;

        await mutation({
            id: project.id,
            csv: values.csv.trim(),
        });

        props.onClose();
    };

    const renderCsvHelp = () => (
        <>
            <p>
                Das Ende eines Parameters wird mit einem Semikolon dargestellt.
                Um mehrere Parameter, z.B. für privileges, zu verwenden, trenne sie mit einem Komma voneinander.
            </p>
            <p>
                Wenn kein Passwort gesetzt ist, erhält der Besucher eine E-Mail mit einem Link zur Auswahl des Passworts.
            </p>
            <p>
                Vereinfachtes Beispiel:<br />
                email;password;privileges;name<br />
                alice@example.com;;Gruppe A;Alice<br />
                bob@example.com;passw0rd;Gruppe A,Gruppe B;Bob<br />
            </p>
        </>
    );

    return !loading && (
        <>
            <Dialog
                title={project.loginType === 'ACCOUNTS' ? 'Besucher-Accounts bearbeiten' : 'Gutschein-Codes bearbeiten'}
                isOpen={props.isOpen}
                onClose={props.onClose}
                onConfirm={save}
                onChange={setState}
                values={state}
                validators={validators}
            >
                {({ errors, onChangeByValue }) => (
                    <>
                        <EditingAdmin name={`project-visitors-${project.id}`} />

                        <KeyValueTable
                            items={[
                                {
                                    key: 'CSV',
                                    help: renderCsvHelp(),
                                    value: (
                                        <CodeEditor
                                            value={state.csv}
                                            onChange={onChangeByValue('csv')}
                                            error={errors.csv}
                                        />
                                    ),
                                    align: 'top',
                                },
                            ]}
                        />
                    </>
                )}
            </Dialog>
        </>
    );
};

Visitors.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default Visitors;
