export const notificationPositions = [
    {
        label: 'Oben links',
        value: 'TOP_LEFT',
    },
    {
        label: 'Oben rechts',
        value: 'TOP_RIGHT',
    },
    {
        label: 'Unten links',
        value: 'BOTTOM_LEFT',
    },
    {
        label: 'Unten rechts',
        value: 'BOTTOM_RIGHT',
    },
    {
        label: 'Zentriert (Dialog)',
        value: 'CENTERED',
    },
];
