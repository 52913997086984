import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Upload from 'Upload';
import Image from 'Image';
import Icon from 'Icon';

const ImageUpload = (props) => {
    const [error, setError] = useState(null);

    return (
        <FormControl fullWidth error={!!props.error || !!error}>
            <div
                className={classNames(
                    'ui-cms-image-upload',
                    {
                        'ui-cms-image-upload--uploading': props.url === 'placeholder',
                        'ui-cms-image-upload--with-image': props.url && props.url !== 'placeholder',
                    }
                )}
            >
                {props.url ? (
                    <div className="ui-cms-image-upload__image">
                        {props.imageDecorator((
                            <Image
                                url={props.url}
                                maxWidth={props.maxWidth}
                                onLoad={props.onImageLoad}
                            />
                        ))}

                        {props.onUpload && (
                            <div className="ui-cms-image-upload__reupload">
                                <Upload
                                    onChange={props.onUpload}
                                    onError={setError}
                                >
                                    <Icon type="upload" />
                                </Upload>
                            </div>
                        )}

                        {props.onDelete && (
                            <div
                                className="ui-cms-image-upload__delete"
                                onClick={props.onDelete}
                            >
                                <Icon type="delete" />
                            </div>
                        )}
                    </div>
                ) : (
                    <Upload
                        onChange={props.onUpload}
                        onError={setError}
                    >
                        <div className="ui-cms-image-upload__upload">
                            <Icon type="upload" />
                        </div>
                    </Upload>
                )}
            </div>

            <FormHelperText>{props.error || error}</FormHelperText>
        </FormControl>
    );
};

ImageUpload.defaultProps = {
    onUpload: null,
    url: null,
    error: null,
    imageDecorator: (image) => image,
    onDelete: null,
    maxWidth: null,
    onImageLoad: null,
    className: '',
};

ImageUpload.propTypes = {
    className: PropTypes.any,
    onUpload: PropTypes.func,
    onDelete: PropTypes.func,
    imageDecorator: PropTypes.func,
    url: PropTypes.string,
    error: PropTypes.string,
    maxWidth: PropTypes.number,
    onImageLoad: PropTypes.func,
};

export default ImageUpload;
