export const TRACKING_CLICK_EXTERNAL_LINK = 'click-external-link';
export const TRACKING_CREATE_VISITOR = 'create-visitor';
export const TRACKING_DISPLAY_GALLERY_IMAGE = 'display-gallery-image';
export const TRACKING_DOWNLOAD_FILE = 'download-file';
export const TRACKING_LOGIN = 'login';
export const TRACKING_PLAY_AUDIO = 'play-audio';
export const TRACKING_PLAY_VIDEO = 'play-video';
export const TRACKING_REQUEST_RESET_PASSWORD = 'request-reset-password';
export const TRACKING_SEARCH_EXHIBITOR = 'search-exhibitor';
export const TRACKING_SET_PASSWORD = 'set-password';
export const TRACKING_SHOW_CONTACT = 'show-contact';
export const TRACKING_SHOW_DOCUMENT = 'show-document';
export const TRACKING_SHOW_DOWNLOADS = 'show-downloads';
export const TRACKING_SHOW_EXHIBITOR_LIST = 'show-exhibitor-list';
export const TRACKING_SHOW_GALLERY = 'show-gallery';
export const TRACKING_SHOW_INFOBOX = 'show-infobox';
export const TRACKING_SHOW_MAILBOX = 'show-mailbox';
export const TRACKING_SHOW_VIDEOS = 'show-videos';
export const TRACKING_SUBMIT_CHALLENGE = 'submit-challenge';
export const TRACKING_SUBMIT_MAILBOX = 'submit-mailbox';
export const TRACKING_VIEW_FILE = 'view-file';
export const TRACKING_VISIT_EXHIBITOR_FROM_LIST = 'visit-exhibitor-from-list';

export const trackingLabelMap = {
    [TRACKING_CLICK_EXTERNAL_LINK]: {
        label: 'Externen Link anklicken',
        visibleInStatistics: true,
    },
    [TRACKING_CREATE_VISITOR]: {
        label: 'Als Besucher registrieren',
        visibleInStatistics: false,
    },
    [TRACKING_DISPLAY_GALLERY_IMAGE]: {
        label: 'Galerie-Bild anzeigen',
        visibleInStatistics: true,
    },
    [TRACKING_DOWNLOAD_FILE]: {
        label: 'Datei herunterladen',
        visibleInStatistics: true,
    },
    [TRACKING_LOGIN]: { label: 'Login', visibleInStatistics: false },
    [TRACKING_PLAY_AUDIO]: {
        label: 'Audio abspielen',
        visibleInStatistics: true,
    },
    [TRACKING_PLAY_VIDEO]: {
        label: 'Video abspielen',
        visibleInStatistics: true,
    },
    [TRACKING_REQUEST_RESET_PASSWORD]: {
        label: 'Passwort zurücksetzen',
        visibleInStatistics: false,
    },
    [TRACKING_SEARCH_EXHIBITOR]: {
        label: 'Aussteller im Verzeichnis suchen',
        visibleInStatistics: true,
    },
    [TRACKING_SET_PASSWORD]: {
        label: 'Passwort festlegen',
        visibleInStatistics: false,
    },
    [TRACKING_SHOW_CONTACT]: {
        label: 'Kontaktfenster anzeigen',
        visibleInStatistics: true,
    },
    [TRACKING_SHOW_DOCUMENT]: {
        label: 'Download anzeigen',
        visibleInStatistics: true,
    },
    [TRACKING_SHOW_DOWNLOADS]: {
        label: 'Downloads öffnen',
        visibleInStatistics: true,
    },
    [TRACKING_SHOW_EXHIBITOR_LIST]: {
        label: 'Ausstellerverzeichnis öffnen',
        visibleInStatistics: true,
    },
    [TRACKING_SHOW_GALLERY]: {
        label: 'Bildergalerie öffnen',
        visibleInStatistics: true,
    },
    [TRACKING_SHOW_INFOBOX]: {
        label: 'Infobox öffnen',
        visibleInStatistics: true,
    },
    [TRACKING_SHOW_MAILBOX]: {
        label: 'Visitenkarten-Briefkasten öffnen',
        visibleInStatistics: true,
    },
    [TRACKING_SHOW_VIDEOS]: {
        label: 'Videos öffnen',
        visibleInStatistics: true,
    },
    [TRACKING_SUBMIT_CHALLENGE]: {
        label: 'Rally abschicken',
        visibleInStatistics: true,
    },
    [TRACKING_SUBMIT_MAILBOX]: {
        label: 'Visitenkarte abschicken',
        visibleInStatistics: true,
    },
    [TRACKING_VIEW_FILE]: {
        label: 'Datei öffnen',
        visibleInStatistics: true,
    },
    [TRACKING_VISIT_EXHIBITOR_FROM_LIST]: {
        label: 'Aussteller aus dem Verzeichnis besuchen',
        visibleInStatistics: true,
    },
};
