import React, { useEffect } from 'react';
import Gallery from 'web/molecules/Gallery';
import mediaUrl from 'helpers/mediaUrl';
import { TRACKING_DISPLAY_GALLERY_IMAGE, TRACKING_SHOW_GALLERY } from 'helpers/tracking';
import Image from 'Image';
import PropTypes from 'prop-types';
import useTracking from 'hooks/useTracking';
import useTranslate from 'hooks/useTranslate';

const GalleryContent = (props) => {
    const t = useTranslate();
    const tracking = useTracking();
    const text = props.images
        .map(({ description }) => t(description));
    const items = props.images
        .map(({ file }) => t(file))
        .map((file) => ({
            src: mediaUrl(file, 'standard'),
            width: 1280,
            height: 720,
            srcSet: [
                { src: mediaUrl(file, 'thumbnail'), width: 100, height: 100 },
            ],
        }));
    const result = items.map(({ src, width, height, srcSet }, i) => ({ src, width, height, srcSet, description: text[i] === '' ? undefined : text[i] }));

    useEffect(() => {
        // TRACKCORE:
        //  event:'showGallery', ressource: props.title, data: {scene: props.sceneName, customer: customerId}
        //  SCENE CONTENT: gallery button clicked
        // console.log('%cTRACKCORE: showGallery', 'background: #00a8ff; font-weight: bold;', props.title);
        tracking.trackCoreEvent({
            action: 'showGallery',
            resourceType: 'title',
            resourceName: props.title,
        });
        tracking.trackEvent(props.sceneName, TRACKING_SHOW_GALLERY, props.title);
        // TRACKCORE:
        //  event:'displayGalleryImage', ressource: t(props.images[0].file).filename), data: {scene: props.sceneName, customer: customerId}
        //  SCENE CONTENT: gallery button clicked --> only first image
        // console.log('%cTRACKCORE: displayGalleryImage', 'background: #00a8ff; font-weight: bold;', t(props.images[0].file).filename);
        tracking.trackCoreEvent({
            action: 'displayGalleryImage',
            resourceType: 'filename',
            resourceName: t(props.images[0].file).filename,
        });
        tracking.trackEvent(props.sceneName, TRACKING_DISPLAY_GALLERY_IMAGE, t(props.images[0].file).filename);
    }, []);

    const onSlide = (index) => {
        // TRACKCORE:
        //  event:'displayGalleryImage', ressource: t(props.images[index].file).filename, data: {scene: props.sceneName, customer: customerId}
        //  SCENE CONTENT: gallery button clicked --> next image
        //  todo: fix gallery indexing
        // tracking.trackCoreEvent({
        //     action: 'displayGalleryImage',
        //     resourceType: 'filename',
        //     resourceName: t(props.images[index].file).filename,
        // });
        tracking.trackEvent(
            props.sceneName,
            TRACKING_DISPLAY_GALLERY_IMAGE,
            t(props.images[index].file).filename
        );
    };
    // disable background scroll when gallery is showing
    const setHidden = () => {
        if (document.body.style.overflowY !== 'hidden') {
            document.body.style.overflowY = 'hidden';
        } else {
            document.body.style.overflowY = 'auto';
        }
    };

    // Yet Another React Lightbox: Zoom plugin requires image width/height attributes in order to work
    const onImgLoad = ({ target: img }) => {
        result.map((v) => Object.assign(v, { height: img.offsetHeight, width: img.offsetWidth }));
    };

    const renderItem = (item) => (
        <Image
            className="yarl__slide_image"
            onLoad={onImgLoad}
            url={item.src}
        />
    );
    setHidden();
    return (
        <Gallery
            render={renderItem}
            onSlide={onSlide}
            items={result}
        />
    );
};
GalleryContent.propTypes = {
    images: PropTypes.array.isRequired,
    sceneName: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
};

export default GalleryContent;
