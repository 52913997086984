var isBoolean = function (value) { return typeof value === 'boolean'; };
var isString = function (value) { return typeof value === 'string' || value instanceof String; };
export var isValidFormValue = function (value) { return (!value.mandatory
    || (isBoolean(value.value) && value.value === true)
    || (isString(value.value) && !!value.value.trim())); };
export var formFieldTypes = [
    {
        label: 'Checkbox',
        value: 'CHECKBOX',
    },
    {
        label: 'Dropdown',
        value: 'DROPDOWN',
    },
    {
        label: 'Text',
        value: 'TEXT',
    },
];
export var defaultFormFieldType = 'TEXT';
export var findFormFieldType = function (formFieldType) { return (formFieldTypes.find(function (_a) {
    var value = _a.value;
    return value === formFieldType;
})); };
export var defaultFormFieldFormState = function () { return ({
    id: undefined,
    label: { _translation: 'text' },
    description: { _translation: 'text' },
    value: '',
    mandatory: false,
    type: defaultFormFieldType,
    options: {
        _sort: function (a, b) { return a.order - b.order; },
        _map: function () { return ({
            id: undefined,
            label: { _translation: 'text' },
            value: '',
            order: undefined,
        }); },
    },
}); };
export var isFormEmpty = function (form) { return form.fields.length === 0
    && form.id === undefined; };
