import React from 'react';
import PropTypes from 'prop-types';
import mediaUrl from 'helpers/mediaUrl';
import Image from 'Image';

const PreloadImages = (props) => {
    const imagesToPreload = props.actions.reduce((result, { sceneData, exhibitorData }) => {
        const data = sceneData || exhibitorData;

        if (data) {
            const scene = props.scenes.find(({ id }) => id === data.scene.id);

            if (scene) {
                const url = mediaUrl(scene.background, 'standard');

                if (result.includes(url)) {
                    return [...result, mediaUrl(scene.background, 'standard')];
                }
            }
        }

        return result;
    }, []);

    return (
        <div className="web-project-preload">
            {imagesToPreload.map((url) => (
                <Image url={url} key={url} />
            ))}
        </div>
    );
};

PreloadImages.propTypes = {
    actions: PropTypes.array.isRequired,
    scenes: PropTypes.array.isRequired,
};

export default PreloadImages;
