import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const FaxIcon = (props) => (
    <SvgIcon {...props}>
        <path d="M11 6h5v2h-5V6M8 9V3h11v6a3 3 0 0 1 3 3v6h-3v3H8v-3H7V9h1m2-4v4h7V5h-7m0 10v4h7v-4h-7m9-4a1 1 0 0 0-1 1a1 1 0 0 0 1 1a1 1 0 0 0 1-1a1 1 0 0 0-1-1M4 9h1a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H4a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2z" />
    </SvgIcon>
);

export default FaxIcon;
