import { useQuery } from 'apollo-augmented-hooks';
import { BUTTON } from 'hooks/graphql/queries/settings';
import { TRANSLATION, MEDIA_TRANSLATION } from 'hooks/graphql/helpers/translations';
import { QUOTA } from './customer';
import { FORM } from './project';

export const ACTION = `
    id
    name {
        ${TRANSLATION}
    }
    active
    mobile
    x
    y
    position {
        id
        x
        y
        z
    }
    rotation {
        id
        x
        y
        z
    }
    scale {
        id
        x
        y
        z
    }
    withLabel
    button {
        id
        image {
            id
            url
            mini: variation(type: MINI) {
                url
            }
        }
    }
    restrictions {
        id
        name
    }
    scene {
        id
    }
    sceneVersion {
        id
    }
    tasks {
        id
    }
    sceneData {
        id
        scene {
            id
        }
    }
    exhibitorData {
        id
        scene {
            id
        }
    }
    infoboxData {
        id
        title {
            ${TRANSLATION}
        }
        content  {
            ${TRANSLATION}
        }
    }
    galleryData {
        id
        title {
            ${TRANSLATION}
        }
        images {
            id
            order
            file {
                ${MEDIA_TRANSLATION}
            }
            description {
                ${TRANSLATION}
            }
        }
    }
    contactData {
        id
        title {
            ${TRANSLATION}
        }
        sections {
            id
            image {
                ${MEDIA_TRANSLATION}
            }
            entries {
                id
                value {
                    ${TRANSLATION}
                }
                type
                order
            }
            order
        }
    }
    downloadData {
        id
        title {
            ${TRANSLATION}
        }
        downloads {
            id
            order
            thumbnailMediaId
            file {
                ${MEDIA_TRANSLATION}
            }
            thumbnail {
                url
            }
            label {
                ${TRANSLATION}
            }
            description {
                ${TRANSLATION}
            }
        }
    }
    videoData {
        id
        title {
            ${TRANSLATION}
        }
        items {
            id
            url {
                ${TRANSLATION}
            }
            order
            label {
                ${TRANSLATION}
            }
        }
    }
    externalData {
        id
        url {
            ${TRANSLATION}
        }
        openInNewTab
    }
    loginData {
        id
        hideWhenLoggedIn
    }
    callingCardData {
        id
        contactEmail
        contactSubject
        contactInstructions {
            ${TRANSLATION}
        }
        contactTitle {
            ${TRANSLATION}
        }
        form {
            ${FORM}
        }
    }
    audioData {
        id
        file {
            ${MEDIA_TRANSLATION}
        }
        stopicon {
            id
            url
        }
    }
`;

export const SCENE_OBJECT = `
    id
    name
    position {
        id
        x
        y
        z
    }
    rotation {
        id
        x
        y
        z
    }
    scale {
        id
        x
        y
        z
    }
    scene {
        id
    }
    sceneVersion {
        id
    }
    imageData {
        id
        file {
            ${MEDIA_TRANSLATION}
        }
    }
    videoData {
        id
        file {
            ${MEDIA_TRANSLATION}
        }
        autoplay
        loop
        mute
    }
`;

export const OVERLAY = `
    id
    name
    mobile
    x
    y
    width
    height
    scene {
        id
    }
    sceneVersion {
        id
    }
    imageData {
        id
        file {
            ${MEDIA_TRANSLATION}
        }
    }
    videoData {
        id
        url {
            ${TRANSLATION}
        }
        autoplay
        loop
        mute
        controls
    }
    newstickerData {
        id
        text {
            ${TRANSLATION}
        }
    }
    htmlData {
        id
        html {
            ${TRANSLATION}
        }
    }
`;

export const ACTIVITY_LOG = `
    id
    changes
    description
    author {
        email
    }
    createdAt
`;

export const SCENE = `
    id
    name
    type
    path
    panorama
    widget {
        ${TRANSLATION}
    }
    sponsorType
    contactInstructions {
        ${TRANSLATION}
    }
    contactTitle {
        ${TRANSLATION}
    }
    contactEmail
    contactSubject
    callingCardForm {
        ${FORM}
    }
    background {
        id
        url
        standard: variation(type: STANDARD) {
            url
        }
    }
    actions {
        ${ACTION}
    }
    objects {
        ${SCENE_OBJECT}
    }
    overlays {
        ${OVERLAY}
    }
    files {
        file {
            id
            url
            filename
            mimeType
        }
        inUseByActions
    }
    restrictions {
        id
        name
    }
    tags {
        id
        name
    }
    tasks {
        id
        name {
            ${TRANSLATION}
        }
        order
    }
    panoramaStartScene {
        id
        name
    }
    activityLog {
        ${ACTIVITY_LOG}
    }
    defaultViewMode
    showViewModeToggle
    videoBackground {
        ${TRANSLATION}
    }
    videoBackgroundEnabled
    audioBackgroundId
    audioBackground {
        id
        media {
            id
            filename
        }
    }
    presenceTrackingEnabled
    presenceTrackingRationale {
        ${TRANSLATION}
    }
    presenceTrackingTextFieldMode
    presenceTrackingTextFieldDescription {
        ${TRANSLATION}
    }
    presenceTrackingCheckboxMode
    presenceTrackingCheckboxDescription {
        ${TRANSLATION}
    }
    referrerConstraint
    referrerConstraintOverridesProjectConstraints
    referrerConstraintErrorMessage {
        ${TRANSLATION}
    }
    quota {
        ${QUOTA}
    }
`;

const query = `
    query scene($id: ID!) {
        scene(id: $id) {
            ${SCENE}
            panoramaChildScenes {
                ${SCENE}
            }
            versions {
                id
                name
                startTime
                endTime
                widget {
                    ${TRANSLATION}
                }
                background {
                    id
                    url
                    standard: variation(type: STANDARD) {
                        url
                    }
                    thumbnail: variation(type: THUMBNAIL) {
                        url
                    }
                }
                actions {
                    ${ACTION}
                }
                objects {
                    ${SCENE_OBJECT}
                }
                overlays {
                    ${OVERLAY}
                }
                videoBackground {
                    ${TRANSLATION}
                }
                videoBackgroundEnabled
                audioBackgroundId
            }
            project {
                id
                name
                path
                loginType
                defaultLanguage {
                    id
                    code
                    systemCode
                }
                languages {
                    id
                    code
                    systemCode
                }
                buttons {
                    ${BUTTON}
                }
                accessGroups {
                    id
                    name
                }
                scenes {
                    id
                    name
                    type
                }
                customer {
                    id
                    name
                    quotas {
                        ${QUOTA}
                    }
                }
                files {
                    file {
                        id
                        url
                        filename
                        mimeType
                    }
                    inUseByActions
                }
                audioBackgrounds {
                    id
                    media {
                        id
                        filename
                    }
                }
                tags {
                    id
                    name
                }
                challenge {
                    id
                    tasks {
                        id
                        name {
                            ${TRANSLATION}
                        }
                        order
                    }
                }
                styling {
                    id
                    backgroundColor
                    textColor
                    transparentNavbar
                    font {
                        id
                        filename
                    }
                }
                presenceTrackingEnabled
            }
        }
        buttons {
            ${BUTTON}
        }
    }
`;

export default (id, params) => (
    useQuery(query, {
        variables: {
            id,
        },
        ...params,
    })
);
