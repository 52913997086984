import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import mediaUrl from 'helpers/mediaUrl';
import MeContext from 'contexts/me';
import Image from 'Image';
import Icon from 'Icon';
import Button from 'cms/atoms/Button';
import Headline from 'cms/atoms/Headline';
import Confirm from 'components/ui/cms/molecules/Confirm';
import Grid from 'cms/molecules/Grid';
import Card from 'cms/molecules/Card';
import ScenePanoramaEditor from '../editors/ScenePanorama';

const PanoramaChildScenes = (props) => {
    const me = useContext(MeContext);
    const [scenePanoramaEditorState, setScenePanoramaEditorState] = useState({
        isOpen: false,
        id: null,
    });
    const [deletePanoramaDialogState, setDeletePanoramaDialogState] = useState(null);

    const openScenePanoramaEditorHandler = (id) => () => {
        setScenePanoramaEditorState({
            isOpen: true,
            id,
        });
    };

    const closeScenePanoramaEditor = () => {
        setScenePanoramaEditorState({
            ...scenePanoramaEditorState,
            isOpen: false,
        });
    };

    const openDeletePanoramaDialogHandler = (id) => (event) => {
        event.stopPropagation();
        setDeletePanoramaDialogState(id);
    };

    const closeDeletePanoramaDialog = () => {
        setDeletePanoramaDialogState(null);
    };

    const deletePanorama = () => {
        props.deleteScene(deletePanoramaDialogState);
        closeDeletePanoramaDialog();
    };

    const renderPanoramaChildScene = (childScene) => (
        <Grid item size={4} key={childScene.id}>
            <Card
                headerLabel={childScene.name}
                onClick={openScenePanoramaEditorHandler(childScene.id)}
                image={<Image url={mediaUrl(childScene.background, 'thumbnail')} />}
            >
                <div className="cms-scene__version">
                    {me.hasWriteAccessToFeature('scene.delete') && (
                        <div
                            className="cms-scene__version-delete"
                            onClick={openDeletePanoramaDialogHandler(childScene.id)}
                        >
                            <Icon type="delete" />
                        </div>
                    )}
                </div>
            </Card>
        </Grid>
    );

    return (
        <>
            <Headline
                action={me.hasWriteAccessToFeature('scene.panorama.new') ? (
                    <Button
                        icon="add"
                        onClick={openScenePanoramaEditorHandler(null)}
                    >
                        Neue Panorama-Unterszene
                    </Button>
                ) : null}
            >
                Panorama-Unterszenen
            </Headline>

            <ScenePanoramaEditor
                scene={
                    scenePanoramaEditorState.id
                        ? props.scene.panoramaChildScenes.find(({ id }) => (
                            id === scenePanoramaEditorState.id
                        ))
                        : null
                }
                parentSceneId={props.scene.id}
                parentSceneAudioBackgroundId={props.scene.audioBackgroundId}
                type={props.scene.type}
                isOpen={scenePanoramaEditorState.isOpen}
                onClose={closeScenePanoramaEditor}
                buttons={props.buttons}
                projectButtons={props.scene.project.buttons}
                projectFiles={props.scene.project.files}
                sceneFiles={props.scene.files}
                projectId={props.scene.project.id}
                scenes={[
                    ...props.scene.project.scenes,
                    ...props.scene.panoramaChildScenes,
                ]}
                challenge={props.scene.project.challenge}
                project={props.scene.project}
            />

            <Grid>
                {
                    [...props.scene.panoramaChildScenes]
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map(renderPanoramaChildScene)
                }
            </Grid>

            <Confirm
                title="Soll diese Panorama-Unterszene wirklich gelöscht werden?"
                onConfirm={deletePanorama}
                confirmLabel="Ja, löschen"
                onCancel={closeDeletePanoramaDialog}
                cancelLabel="Nein, abbrechen"
                isOpen={deletePanoramaDialogState !== null}
                destructive
            />
        </>
    );
};

PanoramaChildScenes.propTypes = {
    scene: PropTypes.object.isRequired,
    buttons: PropTypes.array.isRequired,
    deleteScene: PropTypes.func.isRequired,
};

export default PanoramaChildScenes;
