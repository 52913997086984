import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import useTranslate from 'hooks/useTranslate';
import LocaleContext from 'contexts/locale';
import { apiResponseToFormState } from 'helpers/form';
import { emptyTranslation, hasRequiredTranslation } from 'helpers/languages';
import EditorList from 'cms/molecules/EditorList';
import TranslationField from 'cms/molecules/TranslationField';
import TextField from 'cms/atoms/TextField';
import Dialog from 'cms/molecules/Dialog';

const defaultValues = {
    options: [],
};

const FormFieldOptionEditor = (props) => {
    const t = useTranslate();
    const localeContext = useContext(LocaleContext);
    const [state, setState] = useState(apiResponseToFormState(props.field, defaultValues));

    const validators = state.options.reduce((result, current, index) => [
        ...result, {
            name: `options.${index}.label`,
            message: () => 'Bitte gib eine Beschriftung für die Standard-Sprache ein.',
            isValid: (value) => hasRequiredTranslation(value, localeContext.defaultLanguage.id),
        }, {
            name: `options.${index}.value`,
            message: () => 'Bitte gib einen Wert für die Auswertung ein.',
            isValid: (value) => !!value.trim(),
        },
    ], []);

    useEffect(() => {
        if (props.isOpen) {
            setState(apiResponseToFormState(props.field, defaultValues));
        }
    }, [props.isOpen]);

    const addOption = () => {
        setState((previous) => ({
            ...previous,
            options: [
                ...previous.options,
                {
                    label: emptyTranslation,
                    value: '',
                },
            ],
        }));
    };

    const removeOption = (index) => {
        setState((previous) => ({
            ...previous,
            options: [
                ...previous.options.slice(0, index),
                ...previous.options.slice(index + 1),
            ],
        }));
    };

    const changeOrder = (newIndex, oldIndex) => {
        const options = [...state.options];
        const movedOption = options[oldIndex];

        options.splice(oldIndex, 1);
        options.splice(newIndex, 0, movedOption);

        setState({ ...state, options });
    };

    const save = async (values) => {
        props.onSave(values.options);
        props.onClose();
    };

    return (
        <Dialog
            title="Werte verwalten"
            isOpen={props.isOpen}
            onClose={props.onClose}
            onConfirm={save}
            onChange={setState}
            validators={validators}
            values={state}
        >
            {({ errors, onChangeByEvent, onChangeByValue }) => (
                <>
                    <EditorList
                        addLabel="Feld hinzufügen"
                        onAdd={addOption}
                        removeLabel="Feld löschen"
                        onRemove={removeOption}
                        onSort={changeOrder}
                        sortModeContents={state.options.map((option) => (
                            `${t(option.label) || 'Keine Bezeichnung'} (${option.value || 'Kein Wert'})`
                        ))}
                    >
                        {state.options.map((option, index) => (
                            <React.Fragment key={option.id || index}>
                                <TranslationField
                                    label="Beschriftung"
                                    name={`options.${index}.label`}
                                    value={option.label}
                                    onChange={onChangeByValue(`options.${index}.label`)}
                                    error={errors[`options.${index}.label`]}
                                />
                                <TextField
                                    label="Wert"
                                    name={`options.${index}.value`}
                                    value={option.value}
                                    onChange={onChangeByEvent}
                                    error={errors[`options.${index}.value`]}
                                />
                            </React.Fragment>
                        ))}
                    </EditorList>
                </>
            )}
        </Dialog>
    );
};

FormFieldOptionEditor.defaultProps = {
    field: null,
};

FormFieldOptionEditor.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    field: PropTypes.object,
};

export default FormFieldOptionEditor;
