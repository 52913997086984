import { useEffect } from 'react';

const resizeHandlers = {};

window.addEventListener('resize', () => {
    Object.values(resizeHandlers).forEach((handler) => handler());
});

export default (name, callback) => {
    useEffect(() => {
        resizeHandlers[name] = callback;

        return () => {
            delete resizeHandlers[name];
        };
    }, []);
};
