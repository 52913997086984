import { useMutation } from 'apollo-augmented-hooks';
import { ADMIN } from '../queries/admins';

const mutation = `
    mutation updateAdmin($input: UpdateAdminInput!) {
        updateAdmin(input: $input) {
            ${ADMIN}
        }
    }
`;

export default () => {
    const [mutate] = useMutation(mutation);

    return (input) => (
        mutate({ input })
    );
};
