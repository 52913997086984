import React from 'react';
import PropTypes from 'prop-types';

const StatList = (props) => (
    <div className="cms-stat-list">
        <div className="cms-stat-list__head">
            <div className="cms-stat-list__cell cms-stat-list__cell--grow">Wert</div>
            <div className="cms-stat-list__cell">Besuche</div>
            <div className="cms-stat-list__cell">Eindeutige Besucher</div>
        </div>

        {props.items.map((item) => (
            <div
                className="cms-stat-list__row"
                key={item.key || item.label}
            >
                <div className="cms-stat-list__cell cms-stat-list__cell--grow">{item.label}</div>
                <div className="cms-stat-list__cell">{item.totalClicks}</div>
                <div className="cms-stat-list__cell">{item.uniqueClicks}</div>
            </div>
        ))}
    </div>
);

StatList.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string,
        label: PropTypes.node.isRequired,
        totalClicks: PropTypes.number.isRequired,
        uniqueClicks: PropTypes.number.isRequired,
    })).isRequired,
};

export default StatList;
