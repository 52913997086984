import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import MuiBreadCrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles(() => ({
    root: {
        fontSize: 12,
        marginBottom: 32,
    },
    link: {
        cursor: 'pointer',
    },
    current: {
        fontSize: 12,
    },
}));

const Breadcrumbs = (props) => {
    const classes = useStyles();

    const navigateHandler = (href) => () => {
        props.navigate(href);
    };

    const renderItem = (item, index) => {
        if (item.available === false) {
            return null;
        }

        if (item.href) {
            return (
                <Link
                    key={index}
                    className={classes.link}
                    onClick={navigateHandler(item.href)}
                >
                    {item.label}
                </Link>
            );
        }

        return (
            <Typography
                key={index}
                className={classes.current}
                color="textPrimary"
            >
                {item.label}
            </Typography>
        );
    };

    return (
        <MuiBreadCrumbs className={classes.root}>
            {props.items.map(renderItem)}
        </MuiBreadCrumbs>
    );
};

Breadcrumbs.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        href: PropTypes.string,
        available: PropTypes.bool,
    })).isRequired,
    navigate: PropTypes.func.isRequired,
};

export default Breadcrumbs;
