import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import MuiGrid from '@material-ui/core/Grid';

const Grid = (props) => (
    props.item ? (
        <MuiGrid
            className={props.className}
            item
            xs={props.size}
        >
            {props.children}
        </MuiGrid>
    ) : (
        <MuiGrid
            className={classnames('ui-cms-grid', props.className)}
            container
            spacing={2}
        >
            {props.children}
        </MuiGrid>
    )
);

Grid.defaultProps = {
    className: null,
    item: false,
    size: 12,
};

Grid.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    item: PropTypes.bool,
    size: PropTypes.number,
};

export default Grid;
