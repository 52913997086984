import { useHistory, useParams } from 'react-router-dom';
import { useLocalStorage } from '@rehooks/local-storage';
import { AUTH_TYPE_ADMIN, TERMS_TYPE_VISITOR } from 'auth';
import useCreateTracking from 'hooks/graphqlCore/mutations/createTracking';
import useProjectByPath from 'hooks/graphql/queries/projectByPath';
import { findSceneByPath } from 'helpers/scenes';

let currentUrl = null;

export default () => {
    const history = useHistory();
    const params = useParams();
    const [adminToken] = useLocalStorage(AUTH_TYPE_ADMIN);
    const [trackingUserId] = useLocalStorage('trackingUserId');
    const [trackingUserValue] = useLocalStorage('trackingUserValue');
    const [visitorTermsKey] = useLocalStorage(`${TERMS_TYPE_VISITOR}-${params.projectPath}`);
    const [trackingAnonymousUserId] = useLocalStorage('trackingAnonymousUserId');
    const basePath = window.location.origin;
    const isProd = basePath === 'https://app.virtuell-x.com';
    const isStage = basePath === 'https://app.stage.virtuell-x.com';
    const trackingEnabled = !adminToken && (isProd || isStage);

    const createTracking = useCreateTracking();

    const { projectPath, scenePath } = useParams();
    const projectByPathQuery = useProjectByPath(projectPath);
    const project = projectByPathQuery.data?.projectByPath;
    const scene = (scenePath && project) ? findSceneByPath(scenePath, project?.scenes, project?.startScene) : null;
    return {
        trackPageView: (projectName, websiteTitle) => {
            if (visitorTermsKey) {
                let consentBool = false;
                window._paq.push([function () {
                    consentBool = this.hasRememberedConsent();
                }]);
                const trackingFunc = () => {
                    if (!trackingEnabled) {
                        console.info(`Tracking page view for event "${projectName}": ${history.location.pathname}`);
                        return;
                    }

                    if (currentUrl) {
                        window._paq.push(['setReferrerUrl', `${basePath}${currentUrl}`]);
                    }

                    currentUrl = history.location.pathname;
                    window._paq.push(['setCustomUrl', `${basePath}${currentUrl}`]);
                    window._paq.push(['setGenerationTimeMs', 0]);
                    window._paq.push(['setCustomDimension', 1, projectName]);
                    if (trackingUserId) {
                        window._paq.push(['setCustomDimension', 2, trackingUserId]);
                    }
                    if (trackingUserValue) {
                        window._paq.push(['setCustomDimension', 3, trackingUserValue]);
                    }

                    window._paq.push(['trackPageView', websiteTitle]);
                };

                /*  window._paq.push(['setConsentGiven']); */
                if (!consentBool) {
                    window._paq.push(['rememberConsentGiven', 9000]);
                    console.log(consentBool);
                    trackingFunc();
                } else {
                    console.log(consentBool);
                    trackingFunc();
                }
            }
        },
        trackEvent: (sceneName, action, item) => {
            if (visitorTermsKey) {
                if (!trackingEnabled) {
                    console.info(`Tracking event "${action}"${item ? ` with the name "${item}"` : ''} for scene "${sceneName}"`);
                    return;
                }
                console.log('trackEvent', sceneName, action, item);
                window._paq.push(['trackEvent', sceneName, action, item]);
            }
        },
        // TRACKCORE
        trackCoreEvent: (args) => {
            if (visitorTermsKey) {
                const sceneName = scene ? scene.name : 'Startszene';
                const data = [...args.data || [], {
                    name: 'customer',
                    value: project.customer.name,
                }, {
                    name: 'scene',
                    value: sceneName,
                },
                ];
                const user = args.user || trackingUserId || trackingUserValue || trackingAnonymousUserId;
                createTracking({
                    user: user,
                    isLoggedIn: !!(args.user || trackingUserId || trackingUserValue),
                    project: project.name,
                    resourceType: args.resourceType,
                    resourceName: args.resourceName,
                    action: args.action,
                    data: data,
                });
            }
        },
    };
};
