import React, { useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { useLocalStorage } from '@rehooks/local-storage';
import { v4 as uuid } from 'uuid';
import moment from 'moment';
import axios from 'axios';
import appConfig from 'appConfig';
import AuthContext from 'contexts/auth';
import DialogContext from 'contexts/dialog';
import usePrepareUpload from 'hooks/graphql/mutations/prepareUpload';

const MAX_FILE_SIZE_MB = 100;

const typeMap = {
    image: {
        accept: ['.png', '.jpg', '.jpeg', '.gif', '.webp'],
    },
    file: {
        accept: ['.png', '.jpg', '.jpeg', '.gif', '.webp', '.pdf', '.mp3', '.mp4'],
    },
    font: {
        accept: ['.otf', '.ttf', '.woff', '.woff2'],
    },
};

const Upload = (props) => {
    const prepareUpload = usePrepareUpload();
    const authType = useContext(AuthContext);
    const [authToken] = useLocalStorage(authType);
    const dialog = useContext(DialogContext);
    const inputRef = useRef();

    const metadata = (file) => {
        const createdAt = moment(file.lastModified).toISOString();

        return {
            key: file.key,
            filename: file.name,
            mimeType: file.type,
            createdAt,
        };
    };

    const preview = async (files) => {
        files.forEach((file) => {
            const reader = new FileReader();

            reader.onload = async (readerEvent) => {
                const data = {
                    ...metadata(file),
                    url: readerEvent.target.result,
                };

                props.onChange({
                    ...data,
                    url: URL.createObjectURL(file),
                });
            };
            reader.readAsDataURL(file);
        });
    };

    const hasInvalidFileTypes = async (files) => {
        const fileTypeTestResults = await Promise.all(files.map(async (file) => {
            const [extension] = file.name.match(/\.\w+$/);

            return typeMap[props.type].accept.includes(extension.toLowerCase());
        }));

        return fileTypeTestResults.some((isValid) => !isValid);
    };

    const upload = async (files) => {
        props.onError(null);

        files = files.map((file) => {
            // Append a unique key to the file objects so they can be referenced throughout the
            // upload process
            file.key = uuid();
            return file;
        });

        if (inputRef.current) {
            // Reset input so it's possible to upload the same file twice (edge case, but better
            // than zero feedback)
            inputRef.current.value = '';
        }

        if (files.length > props.maxFiles) {
            props.onError(`Es dürfen noch maximal ${props.maxFiles} Dateien hochgeladen werden.`);
            return;
        }

        if (files.some(({ size }) => size > MAX_FILE_SIZE_MB * 1024 * 1024)) {
            props.onError(`Eine oder mehrere Dateien überschreiten das Upload-Limit von ${MAX_FILE_SIZE_MB} MB.`);
            return;
        }

        if (await hasInvalidFileTypes(files)) {
            props.onError(`Der Typ einer oder mehrerer Dateien ist ungültig. Bitte lade eine Datei der folgenden Typen hoch: ${typeMap[props.type].accept.join(', ')}`);
            return;
        }

        if (props.uniqueFiles.some((filename) => (files.some(({ name }) => name === filename)))) {
            props.onError('Es gibt bereits eine oder mehrere Dateien mit diesem Namen.');
            return;
        }

        if (props.type === 'image') {
            files.forEach((file) => {
                props.onChange({
                    ...metadata(file),
                    url: 'placeholder',
                });
            });
        }

        props.onBeforeUpload(null);

        if (dialog) {
            dialog.changeUploadProgress(0);
        }

        preview(files);

        await Promise.all(files.map(async (file) => (
            prepareUpload({}, { authType }).then(async ({ data }) => {
                const { media, key } = data.prepareUpload;
                const formData = new FormData();

                formData.append('id', media.id);
                formData.append('key', key);
                formData.append('file', file);

                return axios.post(`${appConfig.apiUrl}media/`, formData, {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                        'Content-Type': 'multipart/form-data',
                    },
                    onUploadProgress: ({ loaded, total }) => {
                        file.uploadProgress = loaded / total;

                        const totalProgress = files.reduce((result, current) => (
                            result + (current.uploadProgress || 0)
                        ), 0) / files.length;

                        if (dialog) {
                            dialog.changeUploadProgress(totalProgress);
                        }
                    },
                }).then((response) => {
                    if (response.status !== 200) {
                        return Promise.reject();
                    }

                    props.onChange({
                        ...metadata(file),
                        id: media.id,
                    });

                    return Promise.resolve();
                });
            }).catch(() => {
                props.onChange({
                    ...metadata(file),
                    url: null,
                });
                props.onError('Es ist ein Fehler aufgetreten. Bitte versuche es erneut.');

                if (dialog) {
                    dialog.changeUploadProgress(null);
                }
            })
        )));

        if (dialog) {
            dialog.changeUploadProgress(null);
        }
    };

    const onChange = (event) => {
        upload(Array.from(event.target.files));
    };

    return (
        <div className="upload">
            {typeof props.children === 'function' ? (
                props.children(upload)
            ) : (
                <>
                    {props.children}

                    <input
                        ref={inputRef}
                        className="upload__input"
                        type="file"
                        onChange={onChange}
                        accept={typeMap[props.type].accept.join(',').toLowerCase()}
                        multiple={props.maxFiles > 1}
                    />
                </>
            )}
        </div>
    );
};

Upload.defaultProps = {
    onBeforeUpload: () => null,
    onPreview: () => null,
    onUpload: () => null,
    onError: () => null,
    type: 'image',
    maxFiles: 1,
    uniqueFiles: [],
};

Upload.propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
    onChange: PropTypes.func.isRequired,
    onPreview: PropTypes.func,
    onUpload: PropTypes.func,
    onError: PropTypes.func,
    onBeforeUpload: PropTypes.func,
    type: PropTypes.string,
    maxFiles: PropTypes.number,
    uniqueFiles: PropTypes.arrayOf(PropTypes.string),
};

export default Upload;
