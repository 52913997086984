import match from 'bcp-47-match';
import de from 'translations/de';
import en from 'translations/en';

export const systemLanguages = [
    {
        code: 'de',
        name: 'Deutsch',
        translations: de,
    },
    {
        code: 'en',
        name: 'English',
        translations: en,
    },
];

export const userLanguages = [
    {
        code: 'de',
        name: 'Deutsch',
        systemCode: 'de',
    },
    {
        code: 'en',
        name: 'English',
        systemCode: 'en',
    },
    {
        code: 'es',
        name: 'Español',
    },
    {
        code: 'fr',
        name: 'Français',
    },
    {
        code: 'it',
        name: 'Italiano',
    },
    {
        code: 'ja',
        name: '日本語',
    },
    {
        code: 'ko',
        name: '한국어',
    },
    {
        code: 'ru',
        name: 'русский',
    },
    {
        code: 'zh-hans',
        name: '简体',
    },
    {
        code: 'zh-hant',
        name: '繁體',
    },
];

export const findSupportedLanguage = (window, availableLanguages, defaultLanguage) => {
    const browserLanguages = window.navigator.languages || ['en'];
    const supportedLocales = availableLanguages.map(({ code }) => code);
    const supportedLocale = match.lookup(supportedLocales, browserLanguages)
        || defaultLanguage.code;
    return availableLanguages.find(({ code }) => code === supportedLocale);
};

export const filterRemovedLanguage = (translation, languageId) => {
    if (!translation) {
        return undefined;
    }
    return {
        ...translation,
        entries: translation.entries?.filter((entry) => (
            entry.languageId !== languageId
        )),
        mediaEntries: translation.mediaEntries?.filter((mediaEntry) => (
            mediaEntry.languageId !== languageId
        )),
    };
};

export const findMediaId = (translation, languageId) => (
    translation.mediaEntries
        .find((mediaEntry) => mediaEntry.languageId === languageId)
        ?.mediaId
);

export const hasAnyTranslation = (translation) => (
    !!translation && (
        translation.entries?.some((entry) => !!entry.text.trim())
        || translation.mediaEntries?.some((mediaEntry) => !!mediaEntry.mediaId)
    )
);

export const hasRequiredTranslation = (translation, languageId) => {
    if (!translation) {
        return false;
    }
    if (translation.entries) {
        return translation.entries.some((entry) => (
            entry.languageId === languageId && !!entry.text.trim()
        ));
    }
    if (translation.mediaEntries) {
        return translation.mediaEntries.some((mediaEntry) => (
            mediaEntry.languageId === languageId && !!mediaEntry.mediaId
        ));
    }
    return false;
};

export const hasOptionalTranslation = (translation, defaultLanguageId) => (
    !hasAnyTranslation(translation) || hasRequiredTranslation(translation, defaultLanguageId)
);

export const resolveLanguageName = (code) => (
    // Falls back to language code for unknown languages
    userLanguages.find((language) => language.code === code)?.name || code
);

export const createUpdateTranslationInput = (translation) => {
    if (!translation) {
        return null;
    }
    return {
        id: translation.id || undefined,
        entries: translation.entries?.map((entry) => ({
            id: entry.id || undefined,
            text: entry.text.trim(),
            languageId: entry.languageId,
        })),
        mediaEntries: translation.mediaEntries
            ?.filter((mediaEntry) => !!mediaEntry.mediaId)
            ?.map((mediaEntry) => ({
                id: mediaEntry.id || undefined,
                mediaId: mediaEntry.mediaId,
                languageId: mediaEntry.languageId,
            })),
    };
};

export const createUpdateTranslationInputNoIds = (translation) => {
    if (!translation) {
        return undefined;
    }
    return {
        id: undefined,
        entries: translation.entries?.map((entry) => ({
            id: undefined,
            text: entry.text.trim(),
            languageId: entry.languageId,
        })),
        mediaEntries: translation.mediaEntries?.map((mediaEntry) => ({
            id: undefined,
            mediaId: mediaEntry.mediaId || undefined,
            languageId: mediaEntry.languageId,
        })),
    };
};

export const emptyTranslation = {
    id: undefined,
    entries: [],
};

export const emptyMediaTranslation = {
    id: undefined,
    mediaEntries: [],
};
