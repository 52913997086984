import { useMutation } from 'apollo-augmented-hooks';
import moment from 'moment';

const mutation = `
    mutation startProject($id: ID!) {
        startProject(id: $id) {
            id
            openingTimes {
                id
                startTime
                endTime
                description
                expectedVisitors
            }
        }
    }
`;

export default (project) => {
    const [mutate] = useMutation(mutation);

    return (input) => {
        const now = moment();
        const index = project.openingTimes.findIndex(({ startTime }) => (
            now.isBefore(startTime)
        ));
        const openingTimes = index >= 0 ? [
            ...project.openingTimes.slice(0, index),
            {
                ...project.openingTimes[index],
                startTime: now.startOf('minute').toISOString(),
            },
            ...project.openingTimes.slice(index + 1),
        ] : [
            ...project.openingTimes,
            {
                __typename: 'OpeningTime',
                id: null,
                startTime: now.startOf('minute').toISOString(),
                endTime: now.startOf('minute').add(8, 'hours').toISOString(),
            },
        ];

        return mutate({
            input,
            optimisticResponse: {
                __typename: 'Project',
                openingTimes,
            },
        });
    };
};
