export default (image, type) => {
    if (!image || !image.url) {
        return null;
    }

    if (image.url.startsWith('blob')) {
        return image.url;
    }

    if (image[type] && image[type].url) {
        return image[type].url;
    }

    return image.url;
};
