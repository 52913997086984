import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useDeleteRole from 'hooks/graphql/mutations/deleteRole';
import Button from 'cms/atoms/Button';
import Headline from 'cms/atoms/Headline';
import TextField from 'cms/atoms/TextField';
import DataTable from 'cms/molecules/DataTable';
import Confirm from 'components/ui/cms/molecules/Confirm';
import RoleEditor from '../editors/Customer/Role';

const Roles = (props) => {
    const deleteRole = useDeleteRole(props.customer);
    const [roleEditorState, setRoleEditorState] = useState({ isOpen: false, id: null });
    const [filter, setFilter] = useState('');
    const [deleteRoleDialogState, setDeleteRoleDialogState] = useState({
        isOpen: false, id: null,
    });

    const filterByName = (event) => {
        setFilter(event.target.value);
    };

    const openRoleEditorHandler = (id) => () => {
        setRoleEditorState({ isOpen: true, id });
    };

    const closeRoleEditor = () => {
        setRoleEditorState({ ...roleEditorState, isOpen: false });
    };

    const openDeleteRoleDialogHandler = (id) => () => {
        setDeleteRoleDialogState({ isOpen: true, id });
    };

    const closeDeleteRoleDialog = () => {
        setDeleteRoleDialogState({ ...deleteRoleDialogState, isOpen: false });
    };

    const handleDeleteRole = () => {
        deleteRole(deleteRoleDialogState.id);
        closeDeleteRoleDialog();
    };

    return (
        <>
            <Headline
                action={(
                    <Button
                        icon="add"
                        onClick={openRoleEditorHandler(null)}
                    >
                        Neue Rolle
                    </Button>
                )}
            >
                Rollen
            </Headline>

            <RoleEditor
                isOpen={roleEditorState.isOpen}
                onClose={closeRoleEditor}
                customerId={props.customer.id}
                role={props.customer.roles.find(({ id }) => id === roleEditorState.id)}
                roleFeatures={props.roleFeatures}
            />

            <Confirm
                title="Soll diese Rolle wirklich gelöscht werden?"
                onConfirm={handleDeleteRole}
                confirmLabel="Ja, löschen"
                onCancel={closeDeleteRoleDialog}
                cancelLabel="Nein, abbrechen"
                isOpen={deleteRoleDialogState.isOpen}
                destructive
            >
                Accounts, welche gerade diese Rolle besitzen, verlieren ihre Rolle und sind dann nicht mehr in ihren Rechten eingeschränkt.
            </Confirm>

            <DataTable
                emptyMessage="Es wurden keine Rollen gefunden."
                filters={(
                    <TextField
                        value={filter}
                        onChange={filterByName}
                        label="Nach Name filtern"
                        icon="search"
                    />
                )}
                columns={[
                    { dataKey: 'name', label: 'Name' },
                    { dataKey: 'actions', label: 'Aktionen', width: 100, align: 'center' },
                ]}
                rows={
                    props.customer.roles
                        .filter((role) => {
                            const sanitizedFilter = filter.trim().toLowerCase();

                            return (
                                !sanitizedFilter
                                || role.name.toLowerCase().includes(sanitizedFilter)
                            );
                        })
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((role) => ({
                            name: role.name,
                            actions: (
                                <>
                                    <Button
                                        icon="edit"
                                        onClick={openRoleEditorHandler(role.id)}
                                    />

                                    <Button
                                        icon="delete"
                                        onClick={openDeleteRoleDialogHandler(role.id)}
                                        color="secondary"
                                    />
                                </>
                            ),
                        }))
                }
            />
        </>
    );
};

Roles.propTypes = {
    customer: PropTypes.object.isRequired,
    roleFeatures: PropTypes.array.isRequired,
};

export default Roles;
