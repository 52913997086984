import { useMutation } from 'apollo-augmented-hooks';
import { TRANSLATION } from '../helpers/translations';
import { PROJECT } from '../queries/project';

const mutation = `
    mutation sendNotification($input: SendNotificationInput!) {
        sendNotification(input: $input) {
            id
            title {
                ${TRANSLATION}
            }
            body {
                ${TRANSLATION}
            }
            project {
                ${PROJECT}
            }
        }
    }
`;

export default () => {
    const [mutate] = useMutation(mutation);
    return (input) => mutate({
        input,
        modifiers: [{
            fields: {
                projects: ({ includeIf }) => includeIf(true),
            },
        }, {
            cacheObject: (item) => item.project,
            fields: {
                notifications: ({ includeIf, item }) => includeIf(!!item.project),
            },
        },
        ],
    });
};
