import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import FontRatioContext from 'contexts/fontRatio';
import MeContext from 'contexts/me';
import LocaleContext from 'contexts/locale';
import exposedVariables from 'helpers/exposedVariables';
import { buildQuotaError } from 'helpers/scenes';
import mediaUrl from 'helpers/mediaUrl';
import { defaultActionFormState } from 'helpers/actions';
import { defaultOverlayFormState } from 'helpers/overlays';
import { isFormEmpty } from 'helpers/formFields';
import { defaultSceneObjectFormState } from 'helpers/sceneObjects';
import { apiResponseToFormState } from 'helpers/form';
import {
    hasOptionalTranslation, hasRequiredTranslation,
    createUpdateTranslationInput, createUpdateTranslationInputNoIds,
} from 'helpers/languages';
import useCreateSceneVersion from 'hooks/graphql/mutations/createSceneVersion';
import useUpdateSceneVersion from 'hooks/graphql/mutations/updateSceneVersion';
import useTranslate from 'hooks/useTranslate';
import EditingAdmin from 'EditingAdmin';
import TextField from 'cms/atoms/TextField';
import TranslationField from 'cms/molecules/TranslationField';
import TranslationCodeInput from 'cms/molecules/TranslationCodeInput';
import DateTimePicker from 'cms/atoms/DateTimePicker';
import ImageUpload from 'cms/atoms/ImageUpload';
import VariableList from 'cms/atoms/VariableList';
import KeyValueTable from 'cms/molecules/KeyValueTable';
import Dialog from 'cms/molecules/Dialog';
import Grid from 'cms/molecules/Grid';
import Switch from 'cms/atoms/Switch';
import Select from 'cms/atoms/Select';
import Background from './Scene/Background';
import Panorama from './Scene/Panorama';

const defaultValues = {
    id: undefined,
    name: '',
    audioBackgroundId: '',
    widget: { _translation: 'text' },
    startTime: {
        _default: moment().startOf('day'),
        _modify: (value) => moment(value),
    },
    endTime: {
        _default: moment().endOf('day'),
        _modify: (value) => moment(value),
    },
    videoBackground: { _translation: 'text' },
    videoBackgroundEnabled: false,
    background: {
        _default: {},
        _modify: () => ({
            id: undefined,
            url: '',
        }),
    },
    actions: {
        _map: defaultActionFormState,
    },
    objects: {
        _map: defaultSceneObjectFormState,
    },
    overlays: {
        _sort: (a, b) => a.width * a.height - b.width * b.height,
        _map: defaultOverlayFormState,
    },
};

const filterAccessibleFields = (scene, me) => ({
    // TODO Only fill in values, the user has writeAccess to.
    // Otherwise the values that are still shown (as disabled) but will not be saved.
    ...scene,
    background: me.hasWriteAccessToFeature('scene.background')
        ? scene.background
        : null,
});

/**
 * This is the editor for scene versions.
 * A new scene version is initally filled with copies of all values from the original scene, that are "versionable".
 * Changes in the original scene do not affect the "versioned" values in the scene version and vice versa.
 * Only subset of scene values "versionable", so this editor has reduced scope.
 */
const SceneVersion = (props) => {
    const me = useContext(MeContext);
    const localeContext = useContext(LocaleContext);
    const t = useTranslate();

    const createSceneVersion = useCreateSceneVersion();
    const updateSceneVersion = useUpdateSceneVersion();

    const isUpdateToExistingVersion = !!props.version;
    const sourceScene = isUpdateToExistingVersion ? props.version : filterAccessibleFields(props.scene, me);
    const [state, setState] = useState(
        apiResponseToFormState(sourceScene, defaultValues)
    );
    const [imageSize, setImageSize] = useState(0);

    const validators = [{
        name: 'name',
        message: () => 'Bitte gib einen Namen ein.',
        isValid: (value) => !!value.trim(),
    }, {
        name: 'widget',
        message: () => 'Bitte gib ebenfalls einen Inhalt für die Standard-Sprache ein.',
        isValid: (value) => hasOptionalTranslation(value, localeContext.defaultLanguage.id),
    }, {
        name: 'background',
        message: () => 'Bitte lade ein Hintergrundbild hoch.',
        isValid: (value) => !!value.url,
    }, {
        name: 'background',
        message: (_, error) => buildQuotaError(t, error.subjects),
        serverError: 'QUOTA_EXCEEDED',
    }, {
        name: 'startTime',
        message: () => 'Bitte gib einen Startzeitpunkt ein.',
        isValid: (value) => !!value,
    }, {
        name: 'startTime',
        message: () => 'Der Zeitraum überschneidet sich mit dem Zeitraum einer anderen Version.',
        isValid: (value) => (
            !value
            || !props.scene.versions.some(({ id, startTime, endTime }) => (
                (!props.version || props.version.id !== id)
                && value.isBetween(startTime, endTime)
            ))
        ),
    }, {
        name: 'endTime',
        message: () => 'Bitte gib einen Endzeitpunkt ein.',
        isValid: (value) => !!value,
    }, {
        name: 'endTime',
        message: () => 'Der Endzeitpunkt muss nach dem Startzeitpunkt liegen.',
        isValid: (value, { startTime }) => (
            value.isAfter(startTime)
        ),
    }, {
        name: 'endTime',
        message: () => 'Der Zeitraum überschneidet sich mit dem Zeitraum einer anderen Version.',
        isValid: (value) => (
            !value
            || !props.scene.versions.some(({ id, startTime, endTime }) => (
                (!props.version || props.version.id !== id)
                && value.isBetween(startTime, endTime)
            ))
        ),
    }, {
        name: 'videoBackground',
        message: () => 'Bitte gib einen Vimeo-Link für die Standard-Sprache ein.',
        isValid: (value) => !state.videoBackgroundEnabled || hasRequiredTranslation(value, localeContext.defaultLanguage.id),
    }, {
        name: 'videoBackground',
        message: () => 'Bitte verwende nur gültige Vimeo-Links.',
        isValid: (value) => !state.videoBackgroundEnabled || value?.entries?.every((entry) => (
            !entry.text.trim() || entry.text.includes('vimeo.com')
        )),
    }];

    useEffect(() => {
        if (props.isOpen) {
            setState(
                apiResponseToFormState(sourceScene, defaultValues)
            );
        } else {
            // Reset image size on close so its recalculation on the next image load triggers a
            // re-render and updates the container ref
            setImageSize(0);
        }
    }, [props.isOpen]);

    const storeImageSize = (event) => {
        setImageSize(event.target.width);
    };

    const changeBackground = (file) => {
        setState((previous) => ({
            ...previous,
            background: {
                ...previous.background,
                ...file,
            },
        }));
    };

    const changeBackgroundItems = (type, index, action) => {
        setState((previous) => ({
            ...previous,
            [type]: index === null ? [
                ...previous[type],
                action,
            ] : [
                ...previous[type].slice(0, index),
                {
                    ...previous[type][index],
                    ...action,
                },
                ...previous[type].slice(index + 1),
            ],
        }));
    };

    const deleteBackgroundItem = (type, index) => {
        setState((previous) => ({
            ...previous,
            [type]: [
                ...previous[type].slice(0, index),
                ...previous[type].slice(index + 1),
            ],
        }));
    };

    const createUpdateTranslationVersionInput = (translation) => (
        isUpdateToExistingVersion
            ? createUpdateTranslationInput(translation)
            : createUpdateTranslationInputNoIds(translation)
    );

    const createUpdateFormFieldInput = (field, fieldIndex) => ({
        id: isUpdateToExistingVersion ? field.id : undefined,
        label: createUpdateTranslationVersionInput(field.label),
        description: createUpdateTranslationVersionInput(field.description),
        mandatory: field.mandatory,
        type: field.type,
        options: (field.options || []).map((option, optionIndex) => ({
            id: isUpdateToExistingVersion ? option.id : undefined,
            label: createUpdateTranslationVersionInput(option.label),
            value: option.value.trim(),
            order: optionIndex,
        })),
        order: fieldIndex,
    });

    const save = async (values) => {
        const mutation = isUpdateToExistingVersion ? updateSceneVersion : createSceneVersion;
        const input = {
            // drop ids here, if this is a new version, as we want to copy values,
            // instead of updating the parent scene's values
            id: isUpdateToExistingVersion ? props.version.id : undefined,
            name: values.name.trim(),
            widget: createUpdateTranslationVersionInput(values.widget),
            backgroundMediaId: values.background.id,
            startTime: values.startTime ? values.startTime.toISOString() : null,
            endTime: values.endTime ? values.endTime.toISOString() : null,
            sceneId: isUpdateToExistingVersion ? undefined : props.scene.id,
            videoBackground: createUpdateTranslationVersionInput(values.videoBackground),
            videoBackgroundEnabled: values.videoBackgroundEnabled,
            audioBackgroundId: values.audioBackgroundId || null,
            actions: values.actions.map((action) => ({
                id: isUpdateToExistingVersion ? action.id : undefined,
                name: createUpdateTranslationVersionInput(action.name),
                active: action.active,
                x: action.x,
                y: action.y,
                position: action.position ? {
                    x: action.position.x,
                    y: action.position.y,
                    z: action.position.z,
                    id: isUpdateToExistingVersion ? action.position.id : undefined,
                } : undefined,
                rotation: action.rotation ? {
                    x: action.rotation.x,
                    y: action.rotation.y,
                    z: action.rotation.z,
                    id: isUpdateToExistingVersion ? action.rotation.id : undefined,
                } : undefined,
                scale: action.scale ? {
                    x: action.scale.x,
                    y: action.scale.y,
                    z: action.scale.z,
                    id: isUpdateToExistingVersion ? action.scale.id : undefined,
                } : undefined,
                withLabel: action.withLabel,
                buttonId: action.buttonId,
                restrictions: action.restrictions.map((id) => ({ id })),
                tasks: action.tasks.map((id) => ({ id })),
                sceneData: action.type === 'SCENE' ? {
                    id: isUpdateToExistingVersion ? action.sceneData.id : undefined,
                    sceneId: action.sceneData.sceneId,
                } : null,
                exhibitorData: action.type === 'EXHIBITOR' ? {
                    id: isUpdateToExistingVersion ? action.exhibitorData.id : undefined,
                    sceneId: action.exhibitorData.sceneId,
                } : null,
                infoboxData: action.type === 'INFOBOX' ? {
                    id: isUpdateToExistingVersion ? action.infoboxData.id : undefined,
                    title: createUpdateTranslationVersionInput(action.infoboxData.title),
                    content: createUpdateTranslationVersionInput(action.infoboxData.content),
                } : null,
                contactData: action.type === 'CONTACT' ? {
                    id: isUpdateToExistingVersion ? action.contactData.id : undefined,
                    title: createUpdateTranslationVersionInput(action.contactData.title),
                    sections: action.contactData.sections.map((section, order) => ({
                        id: isUpdateToExistingVersion ? section.id : undefined,
                        image: createUpdateTranslationVersionInput(section.image),
                        entries: section.entries.map((entry, entryOrder) => ({
                            id: isUpdateToExistingVersion ? entry.id : undefined,
                            type: entry.type,
                            value: createUpdateTranslationVersionInput(entry.value),
                            order: entryOrder,
                        })),
                        order,
                    })),
                } : null,
                galleryData: action.type === 'GALLERY' ? {
                    id: isUpdateToExistingVersion ? action.galleryData.id : undefined,
                    title: createUpdateTranslationVersionInput(action.galleryData.title),
                    images: action.galleryData.images.map((image, order) => ({
                        id: isUpdateToExistingVersion ? image.id : undefined,
                        file: createUpdateTranslationVersionInput(image.file),
                        order,
                        description: createUpdateTranslationVersionInput(image.description),
                    })),
                } : null,
                downloadData: action.type === 'DOWNLOAD' ? {
                    id: isUpdateToExistingVersion ? action.downloadData.id : undefined,
                    title: createUpdateTranslationVersionInput(action.downloadData.title),
                    downloads: action.downloadData.downloads.map((download, order) => ({
                        id: isUpdateToExistingVersion ? download.id : undefined,
                        file: createUpdateTranslationVersionInput(download.file),
                        thumbnailMediaId: download.thumbnailMediaId || undefined,
                        order,
                        label: createUpdateTranslationVersionInput(download.label),
                        description: createUpdateTranslationVersionInput(download.description),
                    })),
                } : null,
                videoData: action.type === 'VIDEO' ? {
                    id: isUpdateToExistingVersion ? action.videoData.id : undefined,
                    title: createUpdateTranslationVersionInput(action.videoData.title),
                    items: action.videoData.items.map((item, order) => ({
                        id: isUpdateToExistingVersion ? item.id : undefined,
                        order,
                        label: createUpdateTranslationVersionInput(item.label),
                        url: createUpdateTranslationVersionInput(item.url),
                    })),
                } : null,
                externalData: action.type === 'EXTERNAL' ? {
                    id: isUpdateToExistingVersion ? action.externalData.id : undefined,
                    url: createUpdateTranslationVersionInput(action.externalData.url),
                    openInNewTab: action.externalData.openInNewTab,
                } : null,
                loginData: action.type === 'LOGIN' ? {
                    id: isUpdateToExistingVersion ? action.loginData.id : undefined,
                    hideWhenLoggedIn: action.loginData.hideWhenLoggedIn,
                } : null,
                audioData: action.type === 'AUDIO' ? {
                    id: isUpdateToExistingVersion ? action.audioData.id : undefined,
                    file: createUpdateTranslationVersionInput(action.audioData.file),
                    stopiconMediaId: action.audioData.stopIconMediaId,
                } : null,
                callingCardData: action.type === 'CALLING_CARD' ? {
                    id: isUpdateToExistingVersion ? action.callingCardData.id : undefined,
                    contactEmail: action.callingCardData.contactEmail,
                    contactSubject: action.callingCardData.contactSubject,
                    contactInstructions: createUpdateTranslationVersionInput(action.callingCardData.contactInstructions),
                    contactTitle: createUpdateTranslationVersionInput(action.callingCardData.contactTitle),
                    form: isFormEmpty(action.callingCardData.form) ? null : {
                        id: isUpdateToExistingVersion ? action.callingCardData.form.id : undefined,
                        fields: action.callingCardData.form.fields.map(createUpdateFormFieldInput),
                    },
                } : null,
            })),
            objects: values.objects.map((sceneObject) => ({
                id: isUpdateToExistingVersion ? sceneObject.id : undefined,
                name: sceneObject.name,
                position: sceneObject.position ? {
                    x: sceneObject.position.x,
                    y: sceneObject.position.y,
                    z: sceneObject.position.z,
                    id: isUpdateToExistingVersion ? sceneObject.position.id : undefined,
                } : undefined,
                rotation: sceneObject.rotation ? {
                    x: sceneObject.rotation.x,
                    y: sceneObject.rotation.y,
                    z: sceneObject.rotation.z,
                    id: isUpdateToExistingVersion ? sceneObject.rotation.id : undefined,
                } : undefined,
                scale: sceneObject.scale ? {
                    x: sceneObject.scale.x,
                    y: sceneObject.scale.y,
                    z: sceneObject.scale.z,
                    id: isUpdateToExistingVersion ? sceneObject.scale.id : undefined,
                } : undefined,
                imageData: sceneObject.type === 'IMAGE' ? {
                    id: isUpdateToExistingVersion ? sceneObject.imageData.id : undefined,
                    file: createUpdateTranslationVersionInput(sceneObject.imageData.file),
                } : null,
                videoData: sceneObject.type === 'VIDEO' ? {
                    id: isUpdateToExistingVersion ? sceneObject.videoData.id : undefined,
                    file: createUpdateTranslationVersionInput(sceneObject.videoData.file),
                    autoplay: sceneObject.videoData.autoplay,
                    loop: sceneObject.videoData.loop,
                    mute: sceneObject.videoData.mute,
                } : null,
            })),
            overlays: values.overlays.map((overlay) => ({
                id: isUpdateToExistingVersion ? overlay.id : undefined,
                name: overlay.name,
                x: overlay.x,
                y: overlay.y,
                width: overlay.width,
                height: overlay.height,
                imageData: overlay.type === 'IMAGE' ? {
                    id: isUpdateToExistingVersion ? overlay.imageData.id : undefined,
                    file: createUpdateTranslationVersionInput(overlay.imageData.file),
                } : null,
                videoData: overlay.type === 'VIDEO' ? {
                    id: isUpdateToExistingVersion ? overlay.videoData.id : undefined,
                    url: createUpdateTranslationVersionInput(overlay.videoData.url),
                    autoplay: overlay.videoData.autoplay,
                    mute: overlay.videoData.mute,
                    loop: overlay.videoData.loop,
                    controls: overlay.videoData.controls,
                } : null,
                newstickerData: overlay.type === 'NEWSTICKER' ? {
                    id: isUpdateToExistingVersion ? overlay.newstickerData.id : undefined,
                    text: createUpdateTranslationVersionInput(overlay.newstickerData.text),
                } : null,
                htmlData: overlay.type === 'HTML' ? {
                    id: isUpdateToExistingVersion ? overlay.htmlData.id : undefined,
                    html: createUpdateTranslationVersionInput(overlay.htmlData.html),
                } : null,
            })),
        };

        await mutation(input);

        props.onClose();
    };

    const renderBackground = (errors) => (props.scene.panorama ? (
        <ImageUpload
            onUpload={changeBackground}
            url={mediaUrl(state.background, 'mini')}
            error={errors.background}
            imageDecorator={() => (
                <Panorama
                    background={state.background}
                    actions={state.actions}
                    objects={state.objects}
                    buttons={props.buttons}
                    projectButtons={props.projectButtons}
                    scenes={props.scenes}
                    challenge={props.challenge}
                    onSave={changeBackgroundItems}
                    onDelete={deleteBackgroundItem}
                    projectFiles={props.projectFiles}
                    sceneFiles={props.scene.files}
                    isPanoramaChildScene
                />
            )}
        />
    ) : (
        <ImageUpload
            onUpload={changeBackground}
            url={mediaUrl(state.background, 'standard')}
            error={errors.background}
            onImageLoad={storeImageSize}
            imageDecorator={(image) => (
                <FontRatioContext.Provider value={imageSize}>
                    <Background
                        image={image}
                        actions={state.actions}
                        overlays={state.overlays}
                        buttons={props.buttons}
                        projectButtons={props.projectButtons}
                        scenes={props.scenes}
                        onSave={changeBackgroundItems}
                        onDelete={deleteBackgroundItem}
                        projectFiles={props.projectFiles}
                        sceneFiles={props.scene.files}
                        withMailbox={!!props.scene.contactEmail}
                        loginType={props.loginType}
                        accessGroups={props.accessGroups}
                        challenge={props.challenge}
                    />
                </FontRatioContext.Provider>
            )}
        />
    ));

    return (
        <Dialog
            title={props.version ? 'Szenenversion bearbeiten' : 'Neue Szenenversion erstellen'}
            isOpen={props.isOpen}
            onClose={props.onClose}
            onConfirm={save}
            onChange={setState}
            values={state}
            validators={validators}
            minWidth={80}
            preventFirstTextFieldFocus={!!props.version}
        >
            {({ errors, onChangeByEvent, onChangeByValue }) => (
                <>
                    {props.version && (
                        <EditingAdmin name={`scene-version-${props.version.id}`} />
                    )}

                    <KeyValueTable
                        items={[
                            {
                                key: 'Name',
                                value: (
                                    <TextField
                                        name="name"
                                        value={state.name}
                                        onChange={onChangeByEvent}
                                        error={errors.name}
                                    />
                                ),
                                disabled: !me.hasWriteAccessToFeature('scene.name'),
                            },
                            {
                                key: 'Zeitraum',
                                value: (
                                    <Grid>
                                        <Grid item size={6}>
                                            <DateTimePicker
                                                label="von"
                                                value={state.startTime}
                                                onChange={onChangeByValue('startTime')}
                                                error={errors.startTime}
                                            />
                                        </Grid>

                                        <Grid item size={6}>
                                            <DateTimePicker
                                                label="bis"
                                                value={state.endTime}
                                                onChange={onChangeByValue('endTime')}
                                                error={errors.endTime}
                                            />
                                        </Grid>
                                    </Grid>
                                ),
                            },
                            {
                                key: 'Eigener Code',
                                value: (
                                    <TranslationCodeInput
                                        value={state.widget}
                                        onChange={onChangeByValue('widget')}
                                        projectFiles={props.projectFiles}
                                        sceneFiles={props.scene.files}
                                        error={errors.widget}
                                    />
                                ),
                                help: <VariableList items={exposedVariables} />,
                                align: 'top',
                                disabled: !me.hasWriteAccessToFeature('scene.widget'),
                            },
                            {
                                key: 'Hintergrundbild',
                                value: renderBackground(errors),
                                align: 'top',
                                disabled: !me.hasWriteAccessToFeature('scene.background'),
                            },
                            {
                                key: 'Audiokulisse',
                                value: props.project.audioBackgrounds ? (
                                    <Select
                                        name="audioBackgroundId"
                                        value={state.audioBackgroundId}
                                        onChange={onChangeByEvent}
                                        items={
                                            [
                                                {
                                                    label: 'Ohne',
                                                    value: '',
                                                },
                                                ...props.project.audioBackgrounds
                                                    .map((audioBackground) => ({
                                                        label: audioBackground.media.filename,
                                                        value: audioBackground.id,
                                                    })),
                                            ]
                                        }
                                    />
                                ) : null,
                                available: props.project.audioBackgrounds.length > 0,
                                disabled: !me.hasWriteAccessToFeature('scene.audioBackground'),
                            },
                            {
                                key: 'Videohintergrund',
                                value: (
                                    <Grid>
                                        <Grid item size={2}>
                                            <Switch
                                                name="videoBackgroundEnabled"
                                                checked={state.videoBackgroundEnabled}
                                                onChange={onChangeByEvent}
                                                error={errors.videoBackgroundEnabled}
                                            />
                                        </Grid>

                                        <Grid item size={10}>
                                            <TranslationField
                                                name="videoBackground"
                                                label="Vimeo-Link"
                                                value={state.videoBackground}
                                                onChange={onChangeByValue('videoBackground')}
                                                error={errors.videoBackground}
                                                disabled={!state.videoBackgroundEnabled}
                                            />
                                        </Grid>
                                    </Grid>
                                ),
                                align: 'top',
                                available: me.hasReadAccessToFeature('scene.videoBackground'),
                                disabled: !me.hasWriteAccessToFeature('scene.videoBackground'),
                            },
                        ]}
                    />
                </>
            )}
        </Dialog>
    );
};

SceneVersion.defaultProps = {
    version: null,
    challenge: null,
};

SceneVersion.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    version: PropTypes.object,
    scene: PropTypes.object.isRequired,
    buttons: PropTypes.array.isRequired,
    projectButtons: PropTypes.array.isRequired,
    scenes: PropTypes.array.isRequired,
    projectFiles: PropTypes.array.isRequired,
    accessGroups: PropTypes.array.isRequired,
    loginType: PropTypes.string.isRequired,
    challenge: PropTypes.object,
    project: PropTypes.object.isRequired,
};

export default SceneVersion;
