import { useMutation } from 'apollo-augmented-hooks';
import { ADMIN } from '../queries/admins';

const mutation = `
    mutation createAdmin($input: CreateAdminInput!) {
        createAdmin(input: $input) {
            ${ADMIN}
        }
    }
`;

export default () => {
    const [mutate] = useMutation(mutation);

    return (input) => (
        mutate({
            input,
            modifiers: [{
                fields: {
                    admins: ({ includeIf }) => includeIf(true),
                },
            }, {
                cacheObject: (item) => item.customer,
                fields: {
                    admins: ({ includeIf, item }) => includeIf(!!item.customer),
                },
            }],
        })
    );
};
