import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import LocaleContext from 'contexts/locale';
import useTranslate from 'hooks/useTranslate';
import useUpdateProject from 'hooks/graphql/mutations/updateProject';
import { apiResponseToFormState } from 'helpers/form';
import { defaultMenuItemFormState } from 'helpers/menuItems';
import {
    emptyTranslation,
    createUpdateTranslationInput,
    hasOptionalTranslation, hasRequiredTranslation,
} from 'helpers/languages';
import EditingAdmin from 'EditingAdmin';
import TranslationField from 'cms/molecules/TranslationField';
import Select from 'cms/atoms/Select';
import TranslationCodeInput from 'cms/molecules/TranslationCodeInput';
import KeyValueTable from 'cms/molecules/KeyValueTable';
import Switch from 'cms/atoms/Switch';
import Dialog from 'cms/molecules/Dialog';
import Grid from 'cms/molecules/Grid';
import EditorList from 'cms/molecules/EditorList';

const defaultValues = {
    exhibitorListEnabled: true,
    exhibitorListLabel: { _translation: 'text' },
    menuItems: {
        _sort: (a, b) => a.order - b.order,
        _map: defaultMenuItemFormState,
    },
};

const Navigation = (props) => {
    const t = useTranslate();
    const localeContext = useContext(LocaleContext);

    const updateProject = useUpdateProject();

    const [state, setState] = useState(apiResponseToFormState(props.project, defaultValues));
    const typeSpecificValidators = (menuItem, index) => {
        switch (menuItem.type) {
            case 'SCENE':
                return [{
                    name: `menuItems.${index}.sceneData.sceneId`,
                    message: () => 'Bitte wähle eine Szene aus.',
                    isValid: (value) => !!value,
                }];

            case 'INFOBOX':
                return [{
                    name: `menuItems.${index}.infoboxData.title`,
                    message: () => 'Bitte gib einen Titel für die Standard-Sprache ein.',
                    isValid: (value) => hasRequiredTranslation(value, localeContext.defaultLanguage.id),
                }, {
                    name: `menuItems.${index}.infoboxData.content`,
                    message: () => 'Bitte gib einen Inhalt für die Standard-Sprache ein.',
                    isValid: (value) => hasRequiredTranslation(value, localeContext.defaultLanguage.id),
                }];

            case 'EXTERNAL':
                return [{
                    name: `menuItems.${index}.externalData.url`,
                    message: () => 'Bitte gib eine URL für die Standard-Sprache ein.',
                    isValid: (value) => hasRequiredTranslation(value, localeContext.defaultLanguage.id),
                }];

            default:
                return [];
        }
    };
    const validators = [
        {
            name: 'exhibitorListLabel',
            message: () => 'Bitte gib ebenfalls einen Inhalt für die Standard-Sprache ein.',
            isValid: (value) => !state.exhibitorListEnabled
                || hasOptionalTranslation(value, localeContext.defaultLanguage.id),
        },
        ...state.menuItems.reduce((result, menuItem, index) => [
            ...result,
            {
                name: `menuItems.${index}.label`,
                message: () => 'Bitte gib einen Namen für die Standard-Sprache ein.',
                isValid: (value) => hasRequiredTranslation(value, localeContext.defaultLanguage.id),
            },
            {
                name: `menuItems.${index}.type`,
                message: () => 'Bitte wähle eine Funktion aus.',
                isValid: (value) => !!value.trim(),
            },
            ...typeSpecificValidators(menuItem, index),
        ], []),
    ];

    useEffect(() => {
        if (props.isOpen) {
            setState(apiResponseToFormState(props.project, defaultValues));
        }
    }, [props.isOpen]);

    const addMenuItem = () => {
        setState((previous) => ({
            ...previous,
            menuItems: [
                {
                    label: emptyTranslation,
                    type: '',
                    sceneData: {
                        sceneId: '',
                    },
                    infoboxData: {
                        title: emptyTranslation,
                        content: emptyTranslation,
                    },
                    externalData: {
                        url: emptyTranslation,
                        openInNewTab: true,
                    },
                },
                ...previous.menuItems,
            ],
        }));
    };

    const removeMenuItem = (index) => {
        setState((previous) => ({
            ...previous,
            menuItems: [
                ...previous.menuItems.slice(0, index),
                ...previous.menuItems.slice(index + 1),
            ],
        }));
    };

    const changeMenuItemOrder = (newIndex, oldIndex) => {
        const menuItems = [...state.menuItems];
        const movedMenuItem = menuItems[oldIndex];

        menuItems.splice(oldIndex, 1);
        menuItems.splice(newIndex, 0, movedMenuItem);

        setState({ ...state, menuItems });
    };

    const save = async (values) => {
        await updateProject({
            id: props.project.id,
            exhibitorListEnabled: values.exhibitorListEnabled,
            exhibitorListLabel: createUpdateTranslationInput(values.exhibitorListLabel),
            menuItems: values.menuItems.map((menuItem, index) => ({
                id: menuItem.id || undefined,
                order: index,
                label: createUpdateTranslationInput(menuItem.label),
                sceneData: menuItem.type === 'SCENE' ? {
                    id: menuItem.sceneData.id || undefined,
                    sceneId: menuItem.sceneData.sceneId,
                } : null,
                infoboxData: menuItem.type === 'INFOBOX' ? {
                    id: menuItem.infoboxData.id || undefined,
                    title: createUpdateTranslationInput(menuItem.infoboxData.title),
                    content: createUpdateTranslationInput(menuItem.infoboxData.content),
                } : null,
                externalData: menuItem.type === 'EXTERNAL' ? {
                    id: menuItem.externalData.id || undefined,
                    url: createUpdateTranslationInput(menuItem.externalData.url),
                    openInNewTab: menuItem.externalData.openInNewTab,
                } : null,
            })),
        });

        props.onClose();
    };

    const typeSpecificOptions = (menuItem, index, errors, onChangeByEvent, onChangeByValue) => {
        switch (menuItem.type) {
            case 'SCENE':
                return (
                    <Grid item size={12}>
                        <Select
                            label="Szene"
                            name={`menuItems.${index}.sceneData.sceneId`}
                            value={menuItem.sceneData.sceneId}
                            onChange={onChangeByEvent}
                            items={
                                props.scenes.map((scene) => ({
                                    label: scene.name,
                                    value: scene.id,
                                }))
                            }
                            error={errors[`menuItems.${index}.sceneData.sceneId`]}
                        />
                    </Grid>
                );

            case 'INFOBOX':
                return (
                    <>
                        <Grid item size={12}>
                            <TranslationField
                                label="Titel"
                                name={`menuItems.${index}.infoboxData.title`}
                                value={menuItem.infoboxData.title}
                                onChange={onChangeByValue(`menuItems.${index}.infoboxData.title`)}
                                error={errors[`menuItems.${index}.infoboxData.title`]}
                            />
                        </Grid>

                        <Grid item size={12}>
                            <TranslationCodeInput
                                label="Inhalt"
                                value={menuItem.infoboxData.content}
                                onChange={onChangeByValue(`menuItems.${index}.infoboxData.content`)}
                                error={errors[`menuItems.${index}.infoboxData.content`]}
                                projectFiles={props.project.files}
                            />
                        </Grid>
                    </>
                );

            case 'EXTERNAL':
                return (
                    <>
                        <Grid item size={12}>
                            <TranslationField
                                label="URL"
                                name={`menuItems.${index}.externalData.url`}
                                value={menuItem.externalData.url}
                                onChange={onChangeByValue(`menuItems.${index}.externalData.url`)}
                                error={errors[`menuItems.${index}.externalData.url`]}
                            />
                        </Grid>

                        <Grid item size={12}>
                            <Switch
                                label="In neuem Tab öffnen"
                                name={`menuItems.${index}.externalData.openInNewTab`}
                                checked={menuItem.externalData.openInNewTab}
                                onChange={onChangeByEvent}
                            />
                        </Grid>
                    </>
                );

            default:
                return [];
        }
    };

    const renderMenuItems = (errors, onChangeByEvent, onChangeByValue) => (
        <EditorList
            addLabel="Navigationspunkt hinzufügen"
            onAdd={addMenuItem}
            removeLabel="Navigationspunkt löschen"
            onRemove={removeMenuItem}
            onSort={changeMenuItemOrder}
            sortModeContents={state.menuItems.map((menuItem) => t(menuItem.label) || 'Kein Name')}
        >
            {state.menuItems.map((menuItem, index) => (
                <Grid key={index}>
                    <Grid item size={12}>
                        <TranslationField
                            label="Name"
                            name={`menuItems.${index}.label`}
                            value={menuItem.label}
                            onChange={onChangeByValue(`menuItems.${index}.label`)}
                            error={errors[`menuItems.${index}.label`]}
                        />
                    </Grid>

                    <Grid item size={12}>
                        <Select
                            label="Funktion"
                            name={`menuItems.${index}.type`}
                            value={menuItem.type}
                            onChange={onChangeByEvent}
                            items={[
                                { label: 'Szene', value: 'SCENE' },
                                { label: 'Info-Box', value: 'INFOBOX' },
                                { label: 'Externe URL', value: 'EXTERNAL' },
                            ]}
                            error={errors[`menuItems.${index}.type`]}
                        />
                    </Grid>

                    {typeSpecificOptions(
                        menuItem, index, errors, onChangeByEvent, onChangeByValue
                    )}
                </Grid>
            ))}
        </EditorList>
    );

    return (
        <Dialog
            title="Navigation bearbeiten"
            isOpen={props.isOpen}
            onClose={props.onClose}
            onConfirm={save}
            onChange={setState}
            values={state}
            validators={validators}
        >
            {({ errors, onChangeByEvent, onChangeByValue }) => (
                <>
                    <EditingAdmin name={`project-navigation-${props.project.id}`} />
                    <KeyValueTable
                        items={[
                            {
                                key: 'Ausstellerverzeichnis',
                                value: (
                                    <Grid>
                                        <Grid item size={2}>
                                            <Switch
                                                name="exhibitorListEnabled"
                                                checked={state.exhibitorListEnabled}
                                                onChange={onChangeByEvent}
                                            />
                                        </Grid>
                                        <Grid item size={10}>
                                            <TranslationField
                                                name="exhibitorListLabel"
                                                label="Beschriftung"
                                                value={state.exhibitorListLabel}
                                                error={errors.exhibitorListLabel}
                                                onChange={onChangeByValue('exhibitorListLabel')}
                                                disabled={!state.exhibitorListEnabled}
                                            />
                                        </Grid>
                                    </Grid>
                                ),
                            },
                            {
                                key: 'Navigationspunkte',
                                /* eslint-disable no-template-curly-in-string */
                                help: (
                                    <div>
                                        Externe URLs unterstützen folgende Platzhalter:<br />
                                        <ul>
                                            <li>{'https://www.example.com?contactId=${eventsairContactId}'}</li>
                                            <li>{'https://www.example.com?contactId=${externalId}'}</li>
                                        </ul>
                                    </div>
                                ),
                                /* eslint-enable no-template-curly-in-string */
                                value: renderMenuItems(errors, onChangeByEvent, onChangeByValue),
                                align: 'top',
                            },
                        ]}
                    />
                </>
            )}
        </Dialog>
    );
};

Navigation.propTypes = {
    project: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    scenes: PropTypes.array.isRequired,
};

export default Navigation;
