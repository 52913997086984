import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import useSceneStatistics from 'hooks/graphql/queries/sceneStatistics';
import Container from 'cms/organisms/Container';
import Selection from '../../statistics/Selection';

const Content = (props) => {
    const params = useParams();
    const { data, error, loading } = useSceneStatistics(
        params.id,
        props.startDate,
        props.endDate,
    );

    const renderContent = () => (
        <Selection
            statistics={data.scene?.statistics}
            error={error}
        />
    );

    return (
        <Container>{!loading && renderContent()}</Container>
    );
};

Content.propTypes = {
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
};

export default Content;
