import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import useToggle from 'hooks/useToggle';
import useDeleteCustomer from 'hooks/graphql/mutations/deleteCustomer';
import MeContext from 'contexts/me';
import Button from 'cms/atoms/Button';
import Headline from 'cms/atoms/Headline';
import Warning from 'cms/atoms/Warning';
import Confirm from 'components/ui/cms/molecules/Confirm';
import KeyValueTable from 'cms/molecules/KeyValueTable';
import CustomerEditor from '../editors/Customer';

const General = (props) => {
    const history = useHistory();
    const me = useContext(MeContext);
    const deleteCustomer = useDeleteCustomer();

    const [isCustomerEditorOpen, openCustomerEditor, closeCustomerEditor] = useToggle(false);
    const [isDeleteDialogOpen, openDeleteDialog, closeDeleteDialog] = useToggle(false);
    const { contact } = props.customer;

    const handleDeleteCustomer = () => {
        deleteCustomer(props.customer.id);
        history.replace('/cms');
    };

    return (
        <>
            <Headline
                action={me.admin.isCustomerAdmin ? null : (
                    <Button
                        icon="edit"
                        onClick={openCustomerEditor}
                    >
                        Bearbeiten
                    </Button>
                )}
            >
                {props.customer.name}
            </Headline>

            <CustomerEditor
                isOpen={isCustomerEditorOpen}
                onClose={closeCustomerEditor}
                customer={props.customer}
            />

            <KeyValueTable
                wrappingDividers
                items={[
                    {
                        key: 'Ansprechpartner',
                        value: contact ? (
                            <>
                                <div>{`${contact.firstName} ${contact.lastName}`}</div>
                                <div>{contact.street}</div>
                                {contact.zip && contact.city && (
                                    <div>{`${contact.zip} ${contact.city}`}</div>
                                )}
                                <div>{contact.phone}</div>
                                <div>{contact.email}</div>
                            </>
                        ) : 'Nicht angegeben',
                        align: 'top',
                    },
                ]}
            />

            <div className="ui-cms-button-container--centered">
                {me.admin.isBaseAdmin && (
                    <>
                        <Button
                            icon="delete"
                            onClick={openDeleteDialog}
                            className="ui-cms-button--danger"
                        >
                            Kunden löschen
                        </Button>

                        <Confirm
                            title="Soll dieser Kunde inklusive all seiner Projekte wirklich gelöscht werden?"
                            confirmationCode="delete"
                            onConfirm={handleDeleteCustomer}
                            confirmLabel="Ja, löschen"
                            onCancel={closeDeleteDialog}
                            cancelLabel="Nein, abbrechen"
                            isOpen={isDeleteDialogOpen}
                            destructive
                        >
                            <Warning>
                                Achtung: Der gesamte Kunde inklusive all seiner Projekte wird
                                unwiderruflich gelöscht!
                            </Warning>
                        </Confirm>
                    </>
                )}
            </div>
        </>
    );
};

General.propTypes = {
    customer: PropTypes.object.isRequired,
};

export default General;
