import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Upload from 'Upload';
import Button from 'cms/atoms/Button';
import FileList from 'cms/atoms/FileList';

const FileUpload = (props) => {
    const [error, setError] = useState(null);

    return (
        <FormControl fullWidth error={!!props.error || !!error}>
            <div className="ui-cms-file-upload">
                <Upload
                    onChange={props.onUpload}
                    onError={setError}
                    maxFiles={Infinity}
                    type="file"
                    uniqueFiles={props.files.map((file) => file.label)}
                >
                    <Button icon="upload">
                        Dateien hochladen
                    </Button>
                </Upload>

                <FileList
                    onRemove={props.onRemove}
                    files={props.files}
                />
            </div>

            <FormHelperText>{props.error || error}</FormHelperText>
        </FormControl>
    );
};

FileUpload.defaultProps = {
    error: null,
};

FileUpload.propTypes = {
    onUpload: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    files: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        button: PropTypes.shape({
            icon: PropTypes.string,
            onClick: PropTypes.func,
        }),
        onClick: PropTypes.func,
    })).isRequired,
    error: PropTypes.string,
};

export default FileUpload;
