import React, { useState, useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { useLocalStorage, writeStorage } from '@rehooks/local-storage';
import moment from 'moment';
import AudioContext from 'contexts/audio';
import AuthContext from 'contexts/auth';
import FontRatioContext from 'contexts/fontRatio';
import mediaUrl from 'helpers/mediaUrl';
import { TRACKING_CLICK_EXTERNAL_LINK } from 'helpers/tracking';
import { evaluateTrackingState } from 'helpers/presenceTracking';
import useWindowResize from 'hooks/useWindowResize';
import usePresenceTracking from 'hooks/usePresenceTracking';
import useTranslate from 'hooks/useTranslate';
import useCustomCodeCleanup from 'hooks/useCustomCodeCleanup';
import useTracking from 'hooks/useTracking';
import useImage from 'hooks/useImage';
import Image, { makeBlobUrl } from 'Image';
import Widget from 'Widget';
import Action from 'shared/atoms/Action';
import Overlay from 'shared/atoms/Overlay';
import { isMobile, withOrientationChange } from 'react-device-detect';
// import useGeoLocation from 'hooks/useGeoLocation';
import useDeviceDetect from 'hooks/useDeviceDetect';
import { v4 as uuid } from 'uuid';
import { TERMS_TYPE_VISITOR } from 'auth';
import Mailbox from './Background/Mailbox';
import ContactDialog from './Background/ContactDialog';
import DocumentDialog from './Background/DocumentDialog';
import DownloadDialog from './Background/DownloadDialog';
import GalleryDialog from './Background/GalleryDialog';
import TrackingDialog from './Background/TrackingDialog';
import VideoDialog from './Background/VideoDialog';
import PanoramaDialog from './Background/PanoramaDialog';
import Panorama from './Background/Panorama';
import AudioPlayer from './Background/Audioplayer';
import MobileSceneAccordion from './Background/MobileSceneAccordion';
import InfoboxDialog from './shared/InfoboxDialog';
import BackgroundVideo from './Background/BackgroundVideo';

const Background = (props) => {
    const history = useHistory();
    const params = useParams();
    const tracking = useTracking();
    const t = useTranslate();
    const cleanupCustomCode = useCustomCodeCleanup(props.scene.id);
    const backgroundRef = useRef();
    const imageRef = useRef();
    const authType = useContext(AuthContext);
    const [visitorTermsKey] = useLocalStorage(`${TERMS_TYPE_VISITOR}-${params.projectPath}`);
    const [fullsizedBackgroundUrl, setFullsizedBackgroundUrl] = useState(null);
    const { hasLoaded: backgroundLoaded } = useImage(fullsizedBackgroundUrl);
    const activeBackgroundUrl = mediaUrl(props.version.background, backgroundLoaded ? 'standard' : 'preload');

    useEffect(() => {
        makeBlobUrl(mediaUrl(props.version.background, 'standard'), authType).then(setFullsizedBackgroundUrl);
    }, []);

    const wrapperRef = useRef();
    const [, setReturnAction, deleteReturnAction] = useLocalStorage('returnAction');
    const [eventsairContactId] = useLocalStorage('eventsairContactId');
    const [presenceTrackingState, setPresenceTrackingState] = useLocalStorage('presenceTracking', {});
    const [transformOrigin, setTransformOrigin] = useState({ x: 0.5, y: 0.5 });
    const [activeAction, setActiveAction] = useState(null);
    const [imageSize, setImageSize] = useState({ width: null, height: null });
    const accordionRefs = useRef({});
    const [infoboxState, setInfoboxState] = useState({
        isOpen: false,
        title: null,
        content: null,
    });
    const [galleryDialogState, setGalleryDialogState] = useState({
        isOpen: false,
        title: '',
        images: [],
    });
    const [downloadDialogState, setDownloadDialogState] = useState({
        isOpen: false,
        title: '',
        downloads: [],
    });
    const [contactDialogState, setContactDialogState] = useState({
        isOpen: false,
        title: '',
        sections: [],
    });
    const [documentDialogState, setDocumentDialogState] = useState({
        isOpen: false,
        title: '',
        mimeType: '',
        fileUrl: '',
    });
    const [videoDialogState, setVideoDialogState] = useState({
        isOpen: false,
        title: '',
        videos: [],
    });

    const [mailboxDialogState, setMailboxDialogState] = useState({
        isOpen: false,
        action: null,
    });

    const [currentAudioFile, setCurrentAudioFile] = useState(null);
    const [panoramaDialogFor, setPanoramaDialogFor] = useState(null);
    const [openAccordionItems, setOpenAccordionItems] = useState([]);

    const now = moment();
    const rootScene = props.scene.parentScene ? props.scene.parentScene : props.scene;
    const presenceTracking = evaluateTrackingState(now, props.project, rootScene, presenceTrackingState);
    // const geolocation = useGeoLocation();
    const deviceDetect = useDeviceDetect();

    const [trackingAnonymousUserId] = useLocalStorage('trackingAnonymousUserId');

    usePresenceTracking(
        rootScene.id,
        presenceTracking.trackingText,
        presenceTracking.trackingCheckbox,
        props.project.path,
        props.project.presenceTrackingInterval,
        presenceTracking.isTrackingAllowed,
    );

    // useEffect(() => {
    //     const isUndefinedGeo = Object.values(geolocation).every(
    //         (value) => value === null || (value === undefined && !(value || false))
    //     );
    //     // TRACKCORE:
    //     //  event:'pageView', ressource: t(props.project.websiteTitle), data: {Latitude: Num, Longitude: Num, Device: String, Browser: String, visitLength: num}
    //     //  NOTE: user-object is not available before login --> calculate visitLength only after login?
    //     if (!isUndefinedGeo) {
    //         tracking.trackCoreEvent({
    //             resourceType: 'websiteTitle',
    //             resourceName: t(props.project.websiteTitle),
    //             action: 'pageView',
    //             data: [
    //                 // {
    //                 //     name: 'latitude',
    //                 //     value: geolocation.latitude,
    //                 // },
    //                 // {
    //                 //     name: 'longitude',
    //                 //     value: geolocation.longitude,
    //                 // },
    //             ],
    //         });
    //     }
    //     // console.log('%cs PROPS', 'color: #00f; font-size: 0.8rem;', props);
    // }, [geolocation.latitude]);

    useEffect(() => {
        if (visitorTermsKey) tracking.trackPageView(props.project.name, t(props.project.websiteTitle));
    }, [visitorTermsKey]);

    useEffect(() => {
        const isUndefinedDevice = Object.values(deviceDetect).every(
            (value) => value === false
        );
        if (visitorTermsKey) {
            if (!trackingAnonymousUserId) writeStorage('trackingAnonymousUserId', uuid());
            if (!isUndefinedDevice) {
                tracking.trackCoreEvent({
                    resourceType: 'websiteTitle',
                    resourceName: t(props.project.websiteTitle),
                    action: 'pageView',
                    data: [
                        {
                            name: 'platform',
                            value: deviceDetect.platform,
                        },
                        {
                            name: 'browser',
                            value: deviceDetect.browser,
                        },
                        {
                            name: 'device',
                            value: deviceDetect.device,
                        },
                        {
                            name: 'resolution',
                            value: deviceDetect.res,
                        },
                    ],
                });
            }
        }
    }, [deviceDetect.platform, visitorTermsKey]);

    useEffect(() => {
        props.onChallengeItemCompleted(props.scene.id);
        /* tracking.trackPageView(props.project.name, t(props.project.websiteTitle)); */
        if (props.scene.panorama) {
            calculateImageSize();
        }
    }, []);

    useEffect(() => {
        if (props.transitionState === 'exiting') {
            cleanupCustomCode();
        }
    }, [props.transitionState]);

    useEffect(() => {
        if (!activeAction) {
            return;
        }

        const { sceneData, exhibitorData } = activeAction;
        const data = sceneData || exhibitorData;

        if (data) {
            const {
                path,
                panorama,
                panoramaStartScene,
                panoramaChildScenes,
                defaultViewMode,
            } = data.scene;
            let scenePath = path;

            if (!panorama && panoramaChildScenes.length > 0) {
                if (defaultViewMode === 'CHOOSE') {
                    setPanoramaDialogFor(data.scene);
                    return;
                }

                if (defaultViewMode === 'PANORAMA') {
                    scenePath = panoramaStartScene
                        ? panoramaStartScene.path
                        : panoramaChildScenes[0].path;
                }
            }

            history.push(`/event/${params.projectPath}/${scenePath}`);
        }
    }, [activeAction]);

    useWindowResize(props.scene.id, () => calculateImageSize());

    const openInfobox = (title, content) => {
        setInfoboxState({
            isOpen: true,
            title,
            content: replaceTemplates(t(content)),
        });
    };

    const closeInfobox = () => {
        setInfoboxState({
            ...infoboxState,
            isOpen: false,
        });
    };

    const openGalleryDialog = (title, images) => {
        setGalleryDialogState({
            isOpen: true,
            title: t(title) || t('web.imageGallery.defaultTitle'),
            images,
        });
    };

    const closeGalleryDialog = () => {
        setGalleryDialogState({
            ...galleryDialogState,
            isOpen: false,
        });
    };

    const openDownloadDialog = (title, downloads) => {
        setDownloadDialogState({
            isOpen: true,
            title: t(title) || t('web.downloads.defaultTitle'),
            downloads,
        });
    };

    const closeDownloadDialog = () => {
        setDownloadDialogState({
            ...downloadDialogState,
            isOpen: false,
        });
    };

    const toggleAudioPlayer = (file) => {
        if (!file) {
            setCurrentAudioFile(null);
            return;
        }
        const isAlreadyPlaying = currentAudioFile?.id === file.id;
        setCurrentAudioFile(isAlreadyPlaying ? null : file);
    };

    const isCurrentAudioFile = (file) => (
        file && currentAudioFile && currentAudioFile.id === file.id
    );

    const openContactDialog = (title, sections) => {
        setContactDialogState({
            isOpen: true,
            title: t(title) || t('web.contact.defaultTitle'),
            sections,
        });
    };

    const closeContactDialog = () => {
        setContactDialogState({
            ...contactDialogState,
            isOpen: false,
        });
    };

    const openDocumentDialog = (title, mimeType, fileUrl) => {
        setDocumentDialogState({
            isOpen: true,
            title,
            mimeType,
            fileUrl,
        });
    };

    const closeDocumentDialog = () => {
        setDocumentDialogState({
            ...documentDialogState,
            isOpen: false,
        });
    };

    const openVideoDialog = (title, videos) => {
        setVideoDialogState({
            isOpen: true,
            title: t(title) || (
                videos.length > 1
                    ? t('web.videos.defaultTitle.plural')
                    : t('web.videos.defaultTitle')
            ),
            videos,
        });
    };

    const closeVideoDialog = () => {
        setVideoDialogState({
            ...videoDialogState,
            isOpen: false,
        });
    };

    const closePanoramaDialog = () => {
        setPanoramaDialogFor(null);
        setActiveAction(null);
    };

    const openMailboxDialog = (action = null) => {
        setMailboxDialogState({
            isOpen: true,
            action,
        });
    };

    const closeMailboxDialog = () => {
        setMailboxDialogState({
            ...mailboxDialogState,
            isOpen: false,
        });
    };

    const confirmTracking = (trackingCheckbox, trackingText) => {
        setPresenceTrackingState({
            ...presenceTrackingState,
            [rootScene.id]: {
                timestamp: moment(),
                trackingCheckbox,
                trackingText,
            },
        });
    };

    const toggleAccordionItemOpenState = (identifier) => {
        const updatedOpenAccordionItems = [...openAccordionItems];
        const accordionItemIndex = openAccordionItems.indexOf(identifier);
        if (accordionItemIndex > -1) {
            updatedOpenAccordionItems.splice(accordionItemIndex, 1);
        } else {
            updatedOpenAccordionItems.push(identifier);
        }
        setOpenAccordionItems(updatedOpenAccordionItems);
    };

    const setAccordionRefHandler = (id) => (element) => {
        accordionRefs.current[id] = element;
    };

    const showMobileView = () => (
        isMobile
        && props.isPortrait
        && !props.scene.panorama
    );

    const actionClickHandler = (action, allowClosingAccordionItem = false) => (event) => {
        props.onChallengeItemCompleted(action.id);

        const useStandardBehaviorOnMobile = (
            action.sceneData
            || action.exhibitorData
            || action.externalData
            || action.audioData
        );

        if (
            !props.isLoggedIn
            && (action.sceneData || action.exhibitorData || action.externalData)
        ) {
            if (action.sceneData) {
                setReturnAction({
                    type: 'scene',
                    value: action.sceneData.scene.path,
                });
            } else if (action.exhibitorData) {
                setReturnAction({
                    type: 'scene',
                    value: action.exhibitorData.scene.path,
                });
            } else if (action.externalData) {
                setReturnAction({
                    type: 'external',
                    value: t(action.externalData.url),
                });
            }

            props.openLogin();
            return;
        }

        if (
            !showMobileView()
            || useStandardBehaviorOnMobile
        ) {
            if (action.sceneData || action.exhibitorData) {
                const buttonRect = event.target.getBoundingClientRect();
                const wrapperRect = wrapperRef.current.getBoundingClientRect();

                const clientX = event.clientX || buttonRect.x;
                const clientY = event.clientY || buttonRect.y;

                setActiveAction(action);
                setTransformOrigin({
                    x: (clientX - wrapperRect.left) / wrapperRect.width,
                    y: (clientY - wrapperRect.top) / wrapperRect.height,
                });
            } else if (action.infoboxData) {
                openInfobox(action.infoboxData.title, action.infoboxData.content);
            } else if (action.galleryData) {
                openGalleryDialog(action.galleryData.title, action.galleryData.images);
            } else if (action.contactData) {
                openContactDialog(action.contactData.title, action.contactData.sections);
            } else if (action.downloadData) {
                openDownloadDialog(action.downloadData.title, action.downloadData.downloads);
            } else if (action.videoData) {
                openVideoDialog(action.videoData.title, action.videoData.items);
            } else if (action.externalData) {
                const url = t(action.externalData.url);
                // TRACKCORE:
                //  event:'clickExternalLink', ressource: url, scene: props.scene.name, customer: customerId
                tracking.trackCoreEvent({
                    action: 'clickExternalLink',
                    resourceType: 'url',
                    resourceName: url,
                });
                tracking.trackEvent(
                    props.scene.name,
                    TRACKING_CLICK_EXTERNAL_LINK,
                    url
                );
                window.open(
                    replaceTemplates(url),
                    action.externalData.openInNewTab ? '_blank' : '_self',
                );
            } else if (action.loginData) {
                deleteReturnAction();
                props.openLogin();
            } else if (action.audioData) {
                toggleAudioPlayer(action.audioData.file);
            } else if (action.callingCardData) {
                openMailboxDialog(action);
            }
        } else {
            if (!openAccordionItems.includes(action.id) || allowClosingAccordionItem) {
                toggleAccordionItemOpenState(action.id);
            }
            /**
             * We need this timeout, because immediate scrolling
             * would result in wrong scrolling due to the opening animation
             */
            setTimeout(
                () => {
                    accordionRefs.current[action.id].scrollIntoView({ behavior: 'smooth' });
                },
                500
            );
        }
    };

    const goBack = () => {
        history.goBack();
    };

    const toggleViewMode = () => {
        if (props.scene.parentScene) {
            history.replace(`/event/${params.projectPath}/${props.scene.parentScene.path}`);
        } else {
            const scenePath = props.scene.panoramaStartScene
                ? props.scene.panoramaStartScene.path
                : props.scene.panoramaChildScenes[0].path;

            history.replace(`/event/${params.projectPath}/${scenePath}`);
        }
    };

    // TODO: Find a clean way to extract and reuse templates in other places (see Header.jsx).
    const replaceTemplates = (text) => (
        /* eslint-disable no-template-curly-in-string */
        text
            ?.replace('${externalId}', props.me?.externalId)
            ?.replace('${eventsairContactId}', eventsairContactId)
        /* eslint-enable no-template-curly-in-string */
    );

    const calculateImageSize = () => {
        // Use html tag rather than window to determine viewport size - on mobile, the window's
        // innerWidth might be larger than the viewport depending on zoom
        const htmlTag = document.querySelector('html');

        if (props.scene.panorama) {
            setImageSize({
                width: htmlTag.clientWidth,
                height: htmlTag.clientHeight - backgroundRef.current.getBoundingClientRect().top,
            });
            return;
        }

        if (
            !imageRef.current
            || imageRef.current.naturalWidth === 0
            || imageRef.current.naturalHeight === 0
        ) {
            setImageSize({
                height: null,
                width: null,
            });
            return;
        }

        const imageRatio = imageRef.current.naturalHeight / imageRef.current.naturalWidth;

        if (props.project.scaleType === 'FILL_WIDTH') {
            setImageSize({
                width: htmlTag.clientWidth,
                height: imageRatio * htmlTag.clientWidth,
            });
            return;
        }

        if (props.project.scaleType === 'CENTER_INSIDE') {
            const visibleRatio = 9 / 16;
            const viewPortWidth = htmlTag.clientWidth;
            const viewPortHeight = (
                htmlTag.clientHeight - backgroundRef.current.getBoundingClientRect().top
            );
            const ratioHeight = viewPortWidth * visibleRatio;
            let width = ratioHeight > viewPortHeight
                ? viewPortHeight / visibleRatio
                : viewPortWidth;
            let height = width * imageRatio;

            if (height < viewPortHeight) {
                height = viewPortHeight;
                width = height / imageRatio;

                if (width > viewPortWidth) {
                    width = viewPortWidth;
                    height = width * imageRatio;
                }
            }

            setImageSize({ width, height });
            setTransformOrigin({
                x: 0.5,
                y: (viewPortHeight / Math.max(height, viewPortHeight)) / 2,
            });
        }
    };

    const renderAction = (action) => {
        const { sceneData, exhibitorData, loginData } = action;
        if (
            !props.isStartScene
            && (
                (
                    sceneData
                    && !props.project.scenes.some(({ id }) => id === sceneData.scene.id)
                ) || (
                    exhibitorData
                    && !props.project.scenes.some(({ id }) => id === exhibitorData.scene.id)
                )
            )
        ) {
            return null;
        }

        if (props.isLoggedIn && loginData && loginData.hideWhenLoggedIn) {
            return null;
        }

        const actionButtonIcon = isCurrentAudioFile(action.audioData?.file)
            ? mediaUrl(action.audioData.stopicon, 'mini')
            : mediaUrl(action.button.image, 'mini');

        return (
            <Action
                key={action.id}
                onClick={actionClickHandler(action)}
                image={actionButtonIcon}
                withAnimation
                position={{
                    type: 'within-scene',
                    left: action.x * 100,
                    top: action.y * 100,
                }}
                label={action.withLabel ? action.name : null}
            />
        );
    };

    return (
        <AudioContext.Provider value={{ currentAudioFile, isCurrentAudioFile }}>
            <FontRatioContext.Provider value={imageSize.width}>
                <div
                    className="web-project-background"
                    ref={backgroundRef}
                    style={imageSize}
                >
                    <div className="web-project-background__frame">
                        <div
                            className="web-project-background__wrapper"
                            ref={wrapperRef}
                            style={{
                                transformOrigin: `${transformOrigin.x * 100}% ${transformOrigin.y * 100}%`,
                            }}
                        >
                            {props.scene.panorama ? (
                                <Panorama
                                    actions={props.version.actions.filter((action) => action.active)}
                                    objects={props.version.objects}
                                    url={activeBackgroundUrl}
                                    width={imageSize.width}
                                    height={imageSize.height}
                                    cameraOrientation={props.cameraOrientation}
                                    setCameraOrientation={props.setCameraOrientation}
                                    onChallengeItemCompleted={props.onChallengeItemCompleted}
                                    handleActionClick={(action, event) => (
                                        actionClickHandler(action)(event)
                                    )}
                                />
                            ) : (
                                <>
                                    <Image
                                        url={activeBackgroundUrl}
                                        onLoad={calculateImageSize}
                                        imageRef={imageRef}
                                    />
                                    {props.version.videoBackgroundEnabled && (
                                        <BackgroundVideo
                                            url={t(props.version.videoBackground)}
                                            width={imageSize.width}
                                        />
                                    )}
                                </>
                            )}

                            <TrackingDialog
                                isOpen={presenceTracking.isTrackingDialogOpen}
                                onConfirmTracking={confirmTracking}
                                rationale={rootScene.presenceTrackingRationale}
                                textFieldMode={rootScene.presenceTrackingTextFieldMode}
                                textFieldDescription={rootScene.presenceTrackingTextFieldDescription}
                                checkboxMode={rootScene.presenceTrackingCheckboxMode}
                                checkboxDescription={rootScene.presenceTrackingCheckboxDescription}
                            />

                            <InfoboxDialog
                                isOpen={infoboxState.isOpen}
                                onClose={closeInfobox}
                                title={t(infoboxState.title)}
                                sceneName={props.scene.name}
                            >
                                {infoboxState.content}
                            </InfoboxDialog>

                            <GalleryDialog
                                isOpen={galleryDialogState.isOpen}
                                title={galleryDialogState.title}
                                images={galleryDialogState.images}
                                onClose={closeGalleryDialog}
                                sceneName={props.scene.name}
                            />

                            <ContactDialog
                                title={contactDialogState.title}
                                isOpen={contactDialogState.isOpen}
                                sections={contactDialogState.sections}
                                onClose={closeContactDialog}
                                sceneName={props.scene.name}
                            />

                            <DownloadDialog
                                isOpen={downloadDialogState.isOpen}
                                title={downloadDialogState.title}
                                downloads={downloadDialogState.downloads}
                                onClose={closeDownloadDialog}
                                onViewDocument={openDocumentDialog}
                                sceneName={props.scene.name}
                            />

                            <DocumentDialog
                                isOpen={documentDialogState.isOpen}
                                title={documentDialogState.title}
                                mimeType={documentDialogState.mimeType}
                                fileUrl={documentDialogState.fileUrl}
                                onClose={closeDocumentDialog}
                                sceneName={props.scene.name}
                            />

                            <VideoDialog
                                isOpen={videoDialogState.isOpen}
                                title={videoDialogState.title}
                                videos={videoDialogState.videos}
                                onClose={closeVideoDialog}
                                sceneName={props.scene.name}
                            />

                            <AudioPlayer
                                file={t(currentAudioFile)}
                                sceneName={props.scene.name}
                                onEnded={() => toggleAudioPlayer(null)}
                            />

                            <PanoramaDialog
                                onClose={closePanoramaDialog}
                                scene={panoramaDialogFor}
                            />

                            {props.version.overlays.map((overlay) => (
                                <Overlay
                                    key={overlay.id}
                                    width={overlay.width}
                                    height={overlay.height}
                                    style={{
                                        left: `${overlay.x * 100}%`,
                                        top: `${overlay.y * 100}%`,
                                    }}
                                    overlay={overlay}
                                    sceneName={props.scene.name}
                                    containerWidth={imageSize.width}
                                    containerHeight={imageSize.height}
                                />
                            ))}

                            {!props.scene.panorama && (
                                props.version.actions.filter(
                                    (action) => action.active
                                ).map(renderAction)
                            )}

                            <Widget>{props.version.widget}</Widget>
                        </div>

                        {props.showBackButton && (
                            <Action
                                onClick={goBack}
                                image="/images/back.png"
                                withAnimation
                                position={{
                                    type: 'over-scene',
                                    left: 3,
                                    top: 3,
                                }}
                            />
                        )}

                        {props.showViewModeToggle && (
                            <Action
                                onClick={toggleViewMode}
                                image={props.scene.parentScene ? '/images/2dAction.png' : '/images/360Action.png'}
                                withAnimation
                                position={{
                                    type: 'over-scene',
                                    left: 3,
                                    top: 9,
                                }}
                            />
                        )}

                        { props.scene.contactEmail && (
                            <Action
                                onClick={() => openMailboxDialog()}
                                image="/images/callingCard.png"
                                withAnimation
                                position={{
                                    type: 'over-scene',
                                    left: 97,
                                    top: 3,
                                }}
                            />
                        )}

                        <Mailbox
                            isOpen={mailboxDialogState.isOpen}
                            onClose={closeMailboxDialog}
                            scene={props.scene}
                            action={mailboxDialogState.action}
                            me={props.me}
                            onChallengeItemCompleted={props.onChallengeItemCompleted}
                        />
                    </div>

                    {showMobileView() && (
                        <MobileSceneAccordion
                            openAccordionItems={openAccordionItems}
                            project={props.project}
                            scene={props.scene}
                            showBackButton={props.showBackButton}
                            version={props.version}
                            setAccordionRefHandler={setAccordionRefHandler}
                            actionClickHandler={actionClickHandler}
                            toggleNavbarState={props.toggleNavbarState}
                            showMenuButton={props.showMenuButton}
                        />
                    )}
                </div>
            </FontRatioContext.Provider>
        </AudioContext.Provider>
    );
};

Background.defaultProps = {
    me: null,
    cameraOrientation: null,
};

Background.propTypes = {
    scene: PropTypes.object.isRequired,
    version: PropTypes.object.isRequired,
    project: PropTypes.object.isRequired,
    showBackButton: PropTypes.bool.isRequired,
    transitionState: PropTypes.string.isRequired,
    setCameraOrientation: PropTypes.func.isRequired,
    cameraOrientation: PropTypes.object,
    me: PropTypes.object,
    openLogin: PropTypes.func.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    isStartScene: PropTypes.bool.isRequired,
    onChallengeItemCompleted: PropTypes.func.isRequired,
    showViewModeToggle: PropTypes.bool.isRequired,
    isPortrait: PropTypes.bool.isRequired,
    toggleNavbarState: PropTypes.func.isRequired,
    showMenuButton: PropTypes.bool.isRequired,
};

export default withOrientationChange(Background);
