import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/client';
import { Router } from 'react-router-dom';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import 'polyfills';
import history from 'browserHistory';
import apolloClient from 'apolloClient';
import App from 'components/App';
import ErrorBoundary from './components/ErrorBoundary';
import '../style/app.sass';

const render = (AppComponent) => {
    ReactDOM.render(
        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale="de">
            <ApolloProvider client={apolloClient}>
                <Router history={history}>
                    <ErrorBoundary>
                        <AppComponent />
                    </ErrorBoundary>
                </Router>
            </ApolloProvider>
        </MuiPickersUtilsProvider>,
        document.getElementById('app'),
    );
};

render(App);

if (module.hot) {
    module.hot.accept();
}
