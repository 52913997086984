import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import LocaleContext from 'contexts/locale';
import Grid from 'cms/molecules/Grid';
import { resolveLanguageName } from 'helpers/languages';
import TextField from 'cms/atoms/TextField';
import Select from 'cms/atoms/Select';
import update from 'immutability-helper';

const TranslationField = (props) => {
    const localeContext = useContext(LocaleContext);

    const entries = props.value?.entries || [];
    const activeLanguageId = localeContext.language?.id;
    const currentEntryIndex = entries
        .findIndex(({ languageId }) => languageId === activeLanguageId);
    const currentEntry = entries[currentEntryIndex];

    const handleTextChange = (event) => {
        if (!activeLanguageId) {
            return; // no language chosen yet -> prevent input
        }
        const text = event.target.value;
        let newEntries;
        if (currentEntry) {
            // update
            newEntries = update(entries, {
                [currentEntryIndex]: {
                    text: {
                        $set: text,
                    },
                },
            });
        } else {
            // insert
            newEntries = update(entries, {
                $push: [{
                    text,
                    languageId: activeLanguageId,
                }],
            });
        }
        props.onChange({
            id: props.value?.id,
            entries: newEntries,
        });
    };

    const handleLanguageChanged = (event) => {
        const languageId = event.target.value;
        const selectedLanguage = localeContext.languages.find(({ id }) => id === languageId);
        localeContext.setLanguage(selectedLanguage);
    };

    return (
        <Grid>
            <Grid item size={8}>
                <TextField
                    name={props.name}
                    label={props.label}
                    value={currentEntry?.text || ''}
                    onChange={handleTextChange}
                    error={props.error}
                    disabled={props.disabled}
                    maxLength={props.maxLength}
                />
            </Grid>
            <Grid item size={4}>
                <Select
                    name={`${props.name}.activeLanguageId`}
                    label={props.label ? 'Sprache' : null} // keep height aligned with textfield
                    error={props.error ? ' ' : null} // keep height aligned with textfield
                    helper={props.maxLength ? ' ' : null} // keep height aligned with textfield
                    value={activeLanguageId || ''}
                    onChange={handleLanguageChanged}
                    items={localeContext.languages.map(({ id, code }) => ({
                        label: resolveLanguageName(code),
                        value: id,
                    }))}
                    disabled={props.disabled}
                />
            </Grid>
        </Grid>
    );
};

TranslationField.defaultProps = {
    name: null,
    error: null,
    label: null,
    disabled: false,
    maxLength: null,
    value: {
        entries: [],
    },
};

TranslationField.propTypes = {
    name: PropTypes.string,
    value: PropTypes.object,
    label: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    error: PropTypes.string,
    disabled: PropTypes.bool,
    maxLength: PropTypes.number,
};

export default TranslationField;
