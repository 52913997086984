import React, { useState, forwardRef, useImperativeHandle, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import mediaUrl from 'helpers/mediaUrl';
import useToggle from 'hooks/useToggle';
import MeContext from 'contexts/me';
import Action from 'shared/atoms/Action';
import IconButton from 'cms/atoms/IconButton';
import Confirm from 'components/ui/cms/molecules/Confirm';
import Editor from './Actions/Editor';
import AddButton from './Actions/AddButton';

const Actions = forwardRef((props, ref) => {
    const [origin, setOrigin] = useState({ x: 0, y: 0 });
    const [isAddButtonVisible, setIsAddButtonVisible] = useState(false);
    const [addButtonX, setAddButtonX] = useState(0);
    const [addButtonY, setAddButtonY] = useState(0);
    const [editorState, setEditorState] = useState({
        isOpen: false,
        item: null,
    });
    const [isDeleteDialogOpen, openDeleteDialog, closeDeleteDialog] = useToggle(false);
    const me = useContext(MeContext);
    const itemToDeleteRef = useRef(null);

    useImperativeHandle(ref, () => ({
        onContainerMouseMove: (event) => {
            if (editorState.isOpen || !me.hasWriteAccessToFeature('scene.actions')) {
                return;
            }

            const { left, top, width, height } = props.containerRef.current.getBoundingClientRect();
            const x = (event.clientX - Math.floor(left)) / width;
            const y = (event.clientY - Math.floor(top)) / height;

            if (x < 0 || x > 1 || y < 0 || y > 1 || itemToDeleteRef.current !== null) {
                setIsAddButtonVisible(false);
                return;
            }

            setIsAddButtonVisible(true);
            setAddButtonX(x);
            setAddButtonY(y);
        },
        onContainerMouseLeave: () => {
            setIsAddButtonVisible(false);
        },
    }));

    const onActionMouseEnter = () => {
        setIsAddButtonVisible(false);
    };

    const onActionMouseMove = (event) => {
        event.stopPropagation();
    };

    const rememberOriginHandler = (x, y) => () => {
        setOrigin({ x, y });
    };

    const calculatePosition = (event) => {
        const { left, top, width, height } = props.containerRef.current.getBoundingClientRect();
        const x = (event.clientX - left) / width;
        const y = (event.clientY - top) / height;

        return { x, y };
    };

    const changePositionHandler = (index) => (event) => {
        const { x, y } = calculatePosition(event);

        itemToDeleteRef.current = (
            x < 0 || x > 1 || y < 0 || y > 1
                ? index
                : null
        );

        props.onSave('actions', index, { x, y });
    };

    const openEditorHandler = (index) => () => {
        setEditorState({
            isOpen: true,
            item: index,
        });
    };

    const closeEditor = () => {
        setEditorState({
            ...editorState,
            isOpen: false,
        });
        setIsAddButtonVisible(false);
    };

    const handleDelete = () => {
        if (itemToDeleteRef.current !== null) {
            openDeleteDialog();
        }
    };

    const cancelDelete = () => {
        props.onSave('actions', itemToDeleteRef.current, origin);
        closeDeleteDialog();
        itemToDeleteRef.current = null;
    };

    const deleteAction = () => {
        props.onDelete('actions', itemToDeleteRef.current);
        closeDeleteDialog();
        itemToDeleteRef.current = null;
    };

    return (
        <div
            className={classNames(
                'cms-actions-editor',
                { 'cms-actions-editor--add-visible': isAddButtonVisible }
            )}
        >
            <AddButton
                onClick={openEditorHandler(null)}
                x={addButtonX}
                y={addButtonY}
            />

            {props.actions.map((action, index) => (
                itemToDeleteRef.current === index ? (
                    <div
                        key={index}
                        className="cms-actions-editor__delete"
                        style={{
                            transform: props.containerRef.current
                                ? `translate(${action.x * props.containerRef.current.offsetWidth}px, ${action.y * props.containerRef.current.offsetHeight}px)`
                                : null,
                        }}
                    >
                        <IconButton type="delete" secondary />
                    </div>
                ) : (
                    <div
                        key={index}
                        className="cms-actions-editor__action"
                    >
                        <Action
                            onMouseEnter={onActionMouseEnter}
                            onMouseMove={onActionMouseMove}
                            onClick={me.hasWriteAccessToFeature('scene.actions') ? openEditorHandler(index) : null}
                            onDragStart={rememberOriginHandler(action.x, action.y)}
                            onDrag={me.hasWriteAccessToFeature('scene.actions') ? changePositionHandler(index) : null}
                            onDragEnd={handleDelete}
                            image={mediaUrl(
                                [...props.buttons, ...props.projectButtons].find(({ id }) => (
                                    id === action.buttonId
                                )).image,
                                'mini',
                            )}
                            label={action.withLabel ? action.name : null}
                            position={{
                                type: 'within-scene',
                                left: action.x * 100,
                                top: action.y * 100,
                            }}
                        />
                    </div>
                )
            ))}

            <div className="cms-actions-editor__action">
                <Action
                    onMouseEnter={onActionMouseEnter}
                    onMouseMove={onActionMouseMove}
                    image="/images/back.png"
                    position={{
                        type: 'over-scene',
                        left: 3,
                        top: 3,
                    }}
                />
            </div>

            {props.withMailbox && (
                <div className="cms-actions-editor__action">
                    <Action
                        onMouseEnter={onActionMouseEnter}
                        onMouseMove={onActionMouseMove}
                        image="/images/callingCard.png"
                        position={{
                            type: 'over-scene',
                            left: 97,
                            top: 3,
                        }}
                    />
                </div>
            )}

            <Editor
                actions={props.actions}
                action={editorState.item}
                isOpen={editorState.isOpen}
                onClose={closeEditor}
                onSave={props.onSave}
                buttons={props.buttons}
                projectButtons={props.projectButtons}
                addButtonX={addButtonX}
                addButtonY={addButtonY}
                scenes={props.scenes}
                projectFiles={props.projectFiles}
                sceneFiles={props.sceneFiles}
                loginType={props.loginType}
                accessGroups={props.accessGroups}
                challenge={props.challenge}
                isPanoramaChildScene={props.isPanoramaChildScene}
            />

            <Confirm
                title="Soll diese Aktion wirklich gelöscht werden?"
                onConfirm={deleteAction}
                confirmLabel="Ja, löschen"
                onCancel={cancelDelete}
                cancelLabel="Nein, abbrechen"
                isOpen={isDeleteDialogOpen}
                destructive
            />
        </div>
    );
});

Actions.defaultProps = {
    loginType: null,
    challenge: null,
};

Actions.propTypes = {
    actions: PropTypes.array.isRequired,
    buttons: PropTypes.array.isRequired,
    projectButtons: PropTypes.array.isRequired,
    scenes: PropTypes.array.isRequired,
    onSave: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    projectFiles: PropTypes.array.isRequired,
    sceneFiles: PropTypes.array.isRequired,
    withMailbox: PropTypes.bool.isRequired,
    loginType: PropTypes.string,
    accessGroups: PropTypes.array.isRequired,
    challenge: PropTypes.object,
    containerRef: PropTypes.object.isRequired,
    isPanoramaChildScene: PropTypes.bool.isRequired,
};

export default Actions;
