import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import LocaleContext from 'contexts/locale';
import { ensureFormStateValues } from 'helpers/form';
import { emptyTranslation, hasRequiredTranslation } from 'helpers/languages';
import EditorList from 'cms/molecules/EditorList';
import Select from 'cms/atoms/Select';
import TranslationField from 'cms/molecules/TranslationField';
import Dialog from 'cms/molecules/Dialog';
import TranslationCodeInput from 'cms/molecules/TranslationCodeInput';
import contactEntryTypes, { findContactEntryType, contactEntryValidator, defaultContactEntryFormState } from 'helpers/contactEntryTypes';

const ContactSections = (props) => {
    const localeContext = useContext(LocaleContext);
    const [state, setState] = useState(
        ensureFormStateValues(props.section, defaultContactEntryFormState())
    );

    useEffect(() => {
        if (props.isOpen) {
            setState(
                ensureFormStateValues(props.section, defaultContactEntryFormState())
            );
        }
    }, [props.isOpen]);

    const validators = state.entries.reduce((result, entry, index) => [
        ...result,
        {
            name: `entries.${index}.value`,
            message: () => 'Bitte gib einen Inhalt für die Standard-Sprache ein.',
            isValid: (value) => hasRequiredTranslation(value, localeContext.defaultLanguage.id),
        },
        ...localeContext.languages.map((language) => ({
            name: `entries.${index}.value`,
            ...contactEntryValidator(entry.type, language),
        })),
    ], []);

    const addEntry = () => {
        setState((previous) => ({
            ...previous,
            entries: [
                {
                    label: '',
                    value: emptyTranslation,
                    type: 'TITLE',
                },
                ...previous.entries,
            ],
        }));
    };

    const removeEntry = (index) => {
        setState((previous) => ({
            ...previous,
            entries: [
                ...previous.entries.slice(0, index),
                ...previous.entries.slice(index + 1),
            ],
        }));
    };

    const changeOrder = (newIndex, oldIndex) => {
        const entries = [...state.entries];
        const movedEntry = entries[oldIndex];

        entries.splice(oldIndex, 1);
        entries.splice(newIndex, 0, movedEntry);

        setState({ ...state, entries });
    };

    const saveAction = async (values) => {
        props.onSave(values.entries);
        props.onClose();
    };

    const renderEntry = (entry, index, onChangeByEvent, onChangeByValue, errors) => {
        const contactEntryType = findContactEntryType(entry.type);
        return (
            <React.Fragment key={entry.id || index}>
                <Select
                    name={`entries.${index}.type`}
                    label="Typ"
                    value={entry.type}
                    onChange={onChangeByEvent}
                    items={contactEntryTypes}
                />
                {contactEntryType.isMultiline ? (
                    <TranslationCodeInput
                        label="Wert"
                        value={entry.value}
                        onChange={onChangeByValue(`entries.${index}.value`)}
                        error={errors[`entries.${index}.value`]}
                    />
                ) : (
                    <TranslationField
                        label="Wert"
                        name={`entries.${index}.value`}
                        value={entry.value}
                        onChange={onChangeByValue(`entries.${index}.value`)}
                        error={errors[`entries.${index}.value`]}
                        maxLength={contactEntryType.maxLength}
                    />
                )}
            </React.Fragment>
        );
    };

    return (
        <Dialog
            title="Einträge verwalten"
            isOpen={props.isOpen}
            onClose={props.onClose}
            onConfirm={saveAction}
            onChange={setState}
            values={state}
            validators={validators}
        >
            {({ errors, onChangeByEvent, onChangeByValue }) => (
                <EditorList
                    addLabel="Eintrag hinzufügen"
                    onAdd={addEntry}
                    removeLabel="Eintrag löschen"
                    onRemove={removeEntry}
                    onSort={changeOrder}
                    sortModeContents={
                        state.entries.map((entry) => findContactEntryType(entry.type).label)
                    }
                >
                    {state.entries.map((entry, index) => (
                        renderEntry(entry, index, onChangeByEvent, onChangeByValue, errors)
                    ))}
                </EditorList>
            )}
        </Dialog>
    );
};

ContactSections.defaultProps = {
    section: null,
};

ContactSections.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    section: PropTypes.object,
};

export default ContactSections;
