import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import useToggle from 'hooks/useToggle';
import useTranslate from 'hooks/useTranslate';
import MeContext from 'contexts/me';
import Button from 'cms/atoms/Button';
import Headline from 'cms/atoms/Headline';
import TaskList from 'cms/atoms/TaskList';
import FormFieldList from 'cms/atoms/FormFieldList';
import { availableItemsForTask } from 'helpers/challenge';
import KeyValueTable from 'cms/molecules/KeyValueTable';
import ChallengeEditor from '../editors/Project/Challenge';
import Triggers from './Challenge/Triggers';

const Challenge = (props) => {
    const [isChallengeEditorOpen, openChallengeEditor, closeChallengeEditor] = useToggle(false);
    const me = useContext(MeContext);
    const t = useTranslate();

    const renderEmpty = () => (
        <KeyValueTable
            wrappingDividers
            items={[
                {
                    key: 'Aktiv',
                    value: 'Nein',
                },
            ]}
        />
    );

    const renderContent = (challenge) => (
        <KeyValueTable
            wrappingDividers
            items={[
                {
                    key: 'Aktiv',
                    value: challenge.active ? 'Ja' : 'Nein',
                },
                {
                    key: 'Jederzeit abschließbar',
                    value: challenge.allowPartialSubmission ? 'Ja' : 'Nein',
                },
                {
                    key: 'Name',
                    value: t(challenge.name) || 'Rally',
                },
                {
                    key: 'Anleitung',
                    value: t(challenge.description) || 'Keine',
                },
                {
                    key: 'Spielleiter E-Mail-Adresse',
                    value: challenge.email || 'Keine Benachrichtigung',
                },
                {
                    key: 'Anleitung für Email-Eingabe',
                    value: t(challenge.submitInstructions) || 'Standard',
                },
                {
                    key: 'Aufgaben',
                    value: challenge.tasks.length > 0
                        ? renderTasks(challenge.tasks)
                        : 'Keine',
                    align: 'top',
                },
                {
                    key: 'Versandformular',
                    value: challenge.form && challenge.form.fields.length > 0
                        ? renderFormFields(challenge.form.fields)
                        : 'Keines',
                    align: 'top',
                },
            ]}
        />
    );

    const renderTasks = (tasks) => (
        <TaskList
            tasks={
                [...tasks]
                    .sort((a, b) => a.order - b.order)
                    .map((task) => ({
                        ...task,
                        available: availableItemsForTask(task, props.project.scenes),
                    }))
            }
            showCount
        />
    );

    const renderFormFields = (fields) => (
        <FormFieldList
            fields={[...fields].sort((a, b) => a.order - b.order)}
            showCount
        />
    );

    return (
        <>
            <Headline
                action={me.hasWriteAccessToFeature('project.challenge') ? (
                    <Button
                        icon="edit"
                        onClick={openChallengeEditor}
                    >
                        Bearbeiten
                    </Button>
                ) : null}
            >
                Rally
            </Headline>

            <ChallengeEditor
                isOpen={isChallengeEditorOpen}
                onClose={closeChallengeEditor}
                project={props.project}
            />

            {props.project.challenge ? renderContent(props.project.challenge) : renderEmpty()}

            <Triggers project={props.project} />
        </>
    );
};

Challenge.propTypes = {
    project: PropTypes.object.isRequired,
};

export default Challenge;
