import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import useToggle from 'hooks/useToggle';
import useTranslate from 'hooks/useTranslate';
import { findLoginType } from 'helpers/loginTypes';
import { findLoginDialogType } from 'helpers/loginDialogTypes';
import MeContext from 'contexts/me';
import Button from 'cms/atoms/Button';
import Headline from 'cms/atoms/Headline';
import LabelList from 'cms/atoms/LabelList';
import KeyValueTable from 'cms/molecules/KeyValueTable';
import VisitorsWithAccount from './Login/VisitorsWithAccount';
import VisitorsWithCouponCode from './Login/VisitorsWithCouponCode';
import Presences from './Presences';
import Editor from '../editors/Project/Login';

const Login = (props) => {
    const t = useTranslate();
    const [isEditorOpen, openEditor, closeEditor] = useToggle(false);
    const me = useContext(MeContext);

    return (
        <>
            <Headline
                action={me.hasWriteAccessToFeature('project.login') ? (
                    <Button
                        icon="edit"
                        onClick={openEditor}
                    >
                        Bearbeiten
                    </Button>
                ) : null}
            >
                Login
            </Headline>

            <Editor
                isOpen={isEditorOpen}
                onClose={closeEditor}
                project={props.project}
            />

            <KeyValueTable
                wrappingDividers
                items={[
                    {
                        key: 'Login-Art',
                        value: findLoginType(props.project.loginType).label,
                    },
                    {
                        key: 'Login-Anweisung (individuell)',
                        value: t(props.project.loginInstructions) ? 'Eigene' : 'Keine',
                        available: props.project.loginType !== 'NONE',
                    },
                    {
                        key: 'Login-Anweisung (generiert)',
                        value: findLoginDialogType(props.project.loginDialogType)?.label,
                        available: findLoginType(props.project.loginType).withLoginDialogType,
                    },
                    {
                        key: 'Benutzer-Feld',
                        value: t(props.project.loginLabelUser) || 'Standard',
                        available: findLoginType(props.project.loginType).withUserLabel,
                    },
                    {
                        key: 'Passwort-Feld',
                        value: t(props.project.loginLabelPassword) || 'Standard',
                        available: findLoginType(props.project.loginType).withPasswordLabel,
                    },
                    {
                        key: 'Passwort',
                        value: props.project.password,
                        available: props.project.loginType === 'PASSWORD',
                    },
                    {
                        key: 'Externe Event ID',
                        value: props.project.externalEventId,
                        available: findLoginType(props.project.loginType).withEventId,
                    },
                    {
                        key: 'Externe Client ID',
                        value: props.project.externalClientId,
                        available: findLoginType(props.project.loginType).withOauth,
                    },
                    {
                        key: 'Login-Tracking',
                        value: props.project.loginTracking ? 'Ja' : 'Nein',
                        available: findLoginType(props.project.loginType).withLoginTracking,
                    },
                    {
                        key: 'Registrierung',
                        value: props.project.registrationEnabled ? 'Ja' : 'Nein',
                        available: ['ACCOUNTS'].includes(props.project.loginType),
                    },
                    {
                        key: 'Registrierungs-Anweisung',
                        value: t(props.project.registrationInstructions) ? 'Eigene' : 'Keine',
                        available: ['ACCOUNTS'].includes(props.project.loginType) && props.project.registrationEnabled,
                    },
                    {
                        key: 'Externe Registrierung',
                        value: props.project.registrationExternalUrl ? props.project.registrationExternalUrl : 'Nein',
                        available: ['ACCOUNTS'].includes(props.project.loginType) && props.project.registrationEnabled,
                    },
                    {
                        key: 'Registrierungs-Felder',
                        value: (
                            <LabelList
                                labels={props.project.registrationFields.map((field) => ({
                                    label: t(field.label),
                                    remark: field.mandatory ? 'Erforderlich' : null,
                                }))}
                            />
                        ),
                        available: ['ACCOUNTS'].includes(props.project.loginType) && props.project.registrationEnabled,
                        align: 'top',
                    },
                    {
                        key: 'Registrierungs-Widget',
                        value: (
                            <Button icon="view" href={`/registration/${props.project.path}`} newTab>
                                Ansehen
                            </Button>
                        ),
                        available: ['ACCOUNTS'].includes(props.project.loginType) && props.project.registrationEnabled,
                    },
                    {
                        key: 'Zugriffsgruppen',
                        value: props.project.accessGroups.length > 0 ? (
                            props.project.accessGroups.map(({ name }) => name).join(', ')
                        ) : (
                            'Keine'
                        ),
                        available: findLoginType(props.project.loginType).withAccessGroups,
                        align: 'top',
                    },
                    {
                        key: 'Referrer-Einschränkung',
                        value: props.project.referrerConstraint
                            ? props.project.referrerConstraint.split(',').map((constraint) => (
                                <div key={constraint}>
                                    {constraint}
                                </div>
                            ))
                            : 'Keine',
                        align: 'top',
                    },
                    {
                        key: 'Referrer-Fehlermeldung',
                        value: t(props.project.referrerErrorMessage) ? 'Eigene' : 'Standard',
                    },
                ]}
            />

            {props.project.loginType === 'COUPON_CODES' && (
                <VisitorsWithCouponCode project={props.project} />
            )}

            {props.project.loginType === 'ACCOUNTS' && (
                <VisitorsWithAccount project={props.project} />
            )}

            {props.project.presenceTrackingEnabled && me.hasReadAccessToFeature('project.presenceTrackingExport') && (
                <Presences project={props.project} />
            )}
        </>
    );
};

Login.propTypes = {
    project: PropTypes.object.isRequired,
};

export default Login;
