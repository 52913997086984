import React from 'react';
import PropTypes from 'prop-types';
import useTranslate from 'hooks/useTranslate';
import InnerHTML from 'InnerHTML';

const Widget = (props) => {
    const t = useTranslate();

    return props.children && (
        <InnerHTML>
            {t(props.children)}
        </InnerHTML>
    );
};

Widget.defaultProps = {
    children: null,
};

Widget.propTypes = {
    children: PropTypes.object,
};

export default Widget;
