import { useQuery } from 'apollo-augmented-hooks';
import { BUTTON } from 'hooks/graphql/queries/settings';
import { QUOTA } from 'hooks/graphql/queries/customer';
import { TRANSLATION } from '../helpers/translations';

export const FORM = `
    id
    fields {
        id
        order
        label {
            ${TRANSLATION}
        }
        mandatory
        type
        description {
            ${TRANSLATION}
        }
        options {
            id
            label {
                ${TRANSLATION}
            }
            value
            order
        }
    }
`;

export const REGISTRATION_FIELD = `
    id
    label {
        ${TRANSLATION}
    }
    mandatory
    type
    description {
        ${TRANSLATION}
    }
    order
    options {
        id
        label {
            ${TRANSLATION}
        }
        value
        order
    }
`;

export const VISITOR = `
    id
    name
    company
    position
    phone
    externalId
    trackingId
    salutation
    title
    couponCode
    videoConferenceUrl
    preferredLanguage {
        id
    }
    account {
        id
        email
    }
    project {
        id
    }
    privileges {
        id
        name
    }
    registrationData {
        id
        value
        field {
            ${REGISTRATION_FIELD}
        }
    }
`;

export const PROJECT = `
    id
    name
    contact {
        id
        firstName
        lastName
        email
        phone
        street
        zip
        city
    }
    openingType
    openingTimes {
        id
        startTime
        endTime
        description
        expectedVisitors
    }
    path
    audioBackgrounds {
        id
        media {
            id
            filename
        }
        loop
    }
    audioOnLabel {
        ${TRANSLATION}
    }
    audioOffLabel {
        ${TRANSLATION}
    }
    websiteTitle {
        ${TRANSLATION}
    }
    closedScreen {
        ${TRANSLATION}
    }
    scaleType
    widget {
        ${TRANSLATION}
    }
    loginType
    loginDialogType
    loginInstructions {
        ${TRANSLATION}
    }
    password
    externalEventId
    externalClientId
    demoMode
    loginTracking
    registrationEnabled
    registrationExternalUrl
    registrationInstructions {
        ${TRANSLATION}
    }
    registrationFields {
        ${REGISTRATION_FIELD}
    }
    tosEnabled
    tosType
    tosCustomContent {
        ${TRANSLATION}
    }
    tosCustomCheckbox {
        ${TRANSLATION}
    }
    defaultLanguage {
        id
        code
        systemCode
    }
    languages {
        id
        code
        systemCode
    }
    exhibitorListEnabled
    exhibitorListLabel {
        ${TRANSLATION}
    }
    loginLabelUser {
        ${TRANSLATION}
    }
    loginLabelPassword {
        ${TRANSLATION}
    }
    referrerConstraint
    referrerErrorMessage {
        ${TRANSLATION}
    }
    logo {
        id
        url
        thumbnail: variation(type: THUMBNAIL) {
            url
        }
    }
    startScene {
        id
        name
    }
    buttons {
        ${BUTTON}
    }
    triggers {
        id
        name
        tasks {
            id
            name {
                ${TRANSLATION}
            }
            order
        }
    }
    accessGroups {
        id
        name
    }
    files {
        file {
            id
            url
            filename
            mimeType
        }
        inUseByActions
    }
    challenge {
        id
        active
        allowPartialSubmission
        name {
            ${TRANSLATION}
        }
        description {
            ${TRANSLATION}
        }
        email
        submitInstructions {
            ${TRANSLATION}
        }
        form {
            ${FORM}
        }
        tasks {
            id
            name {
                ${TRANSLATION}
            }
            description {
                ${TRANSLATION}
            }
            count
            order
            systemTriggers
            scenes {
                id
            }
            actions {
                id
            }
            triggers {
                id
            }
        }
    }
    tags {
        id
        name
    }
    menuItems {
        id
        order
        label {
            ${TRANSLATION}
        }
        sceneData {
            id
            scene {
                id
            }
        }
        infoboxData {
            id
            title {
                ${TRANSLATION}
            }
            content {
                ${TRANSLATION}
            }
        }
        externalData {
            id
            url {
                ${TRANSLATION}
            }
            openInNewTab
        }
    }
    styling {
        id
        backgroundColor
        textColor
        transparentNavbar
        font {
            id
            filename
        }
    }
    notifications {
        id
        title {
            ${TRANSLATION}
        }
        body {
            ${TRANSLATION}
        }
        sentAt
        position
        duration
    }
    presenceTrackingEnabled
    presenceTrackingInterval
    ipWhitelist
`;

const query = `
    query project($id: ID!) {
        project(id: $id) {
            ${PROJECT}
            visitors {
                ${VISITOR}
            }
            scenes {
                id
                name
                type
                contactEmail
                background {
                    id
                    url
                    thumbnail: variation(type: THUMBNAIL) {
                        url
                    }
                }
                audioBackground {
                    id
                }
                versions {
                    id
                    audioBackgroundId
                }
                actions {
                    id
                    sceneData {
                        id
                        scene {
                            id
                            name
                        }
                    }
                    exhibitorData {
                        id
                        scene {
                            id
                            name
                        }
                    }
                }
                presenceTrackingEnabled
            }
            customer {
                id
                name
                quotas {
                    ${QUOTA}
                }
            }
        }
        buttons {
            ${BUTTON}
        }
    }
`;

export default (id, params) => useQuery(query, {
    variables: {
        id,
    },
    ...params,
});
