import React, { useContext, Fragment } from 'react';
import reactStringReplace from 'react-string-replace';
import LocaleContext from 'contexts/locale';
import { systemLanguages } from 'helpers/languages';

const getUserLanguage = () => {
    const userLanguage = navigator.language || navigator.userLanguage;
    return {
        code: userLanguage,
        systemCode: userLanguage.split('-')[0],
    };
};

const systemTranslations = Object.fromEntries(
    systemLanguages.map(({ code, translations }) => [code, translations])
);

/* eslint-disable react/jsx-filename-extension */
const replaceParams = (string, params) => (
    Object.entries(params).reduce((result, [key, value]) => (
        reactStringReplace(result, `{${key}}`, () => (
            <Fragment key={key}>{value}</Fragment>
        ))
    ), string)
);

const translateSystemKey = (key, params, selectedLanguage, defaultLanguage) => {
    const translationsForSelectedLanguage = systemTranslations[selectedLanguage.systemCode];
    const translation = translationsForSelectedLanguage && translationsForSelectedLanguage[key];
    if (translation) {
        return replaceParams(translation, params);
    }

    const translationsForDefaultLanguage = systemTranslations[defaultLanguage.systemCode];
    const fallback = translationsForDefaultLanguage && translationsForDefaultLanguage[key];
    if (fallback) {
        return replaceParams(fallback, params);
    }

    throw new Error(`Invalid translation key: ${key}`);
};

const translateProjectText = ({ entries }, selectedLanguage, defaultLanguage) => {
    const translation = entries.find(({ languageId }) => languageId === selectedLanguage.id);
    if (translation && translation.text) {
        return translation.text;
    }

    const fallback = entries.find(({ languageId }) => languageId === defaultLanguage.id);
    if (fallback && fallback.text) {
        return fallback.text;
    }

    return ''; // this may be an optional text
};

const translateMedia = ({ mediaEntries }, selectedLanguage, defaultLanguage) => {
    const translation = mediaEntries.find(({ languageId }) => languageId === selectedLanguage.id);
    if (translation && translation.media) {
        return translation.media;
    }

    const fallback = mediaEntries.find(({ languageId }) => languageId === defaultLanguage.id);
    if (fallback && fallback.media) {
        return fallback.media;
    }

    return null;
};

export default () => {
    const context = useContext(LocaleContext);
    return (element, params = []) => {
        if (!context && typeof element === 'string') {
            return translateSystemKey(element, params, getUserLanguage(), { systemCode: 'en' });
        }
        if (!context.language || !element) {
            return null;
        }
        if (typeof element === 'string') {
            return translateSystemKey(element, params, context.language, context.defaultLanguage);
        }
        if (element.entries) {
            return translateProjectText(element, context.language, context.defaultLanguage);
        }
        if (element.mediaEntries) {
            return translateMedia(element, context.language, context.defaultLanguage);
        }
        throw new Error(`Unsupported translation element: ${JSON.stringify(element)}`);
    };
};
