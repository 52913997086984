import React, { useState, useContext, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useLocalStorage } from '@rehooks/local-storage';
import StylingContext from 'contexts/styling';
import MeContext from 'contexts/me';
import LocaleContext from 'contexts/locale';
import useProject from 'hooks/graphql/queries/project';
import Tabs from 'cms/molecules/Tabs';
import BreadCrumbs from 'cms/organisms/BreadCrumbs';
import Container from 'cms/organisms/Container';
import Challenge from './Project/Challenge';
import General from './Project/General';
import Login from './Project/Login';
import Navigation from './Project/Navigation';
import OpeningTimes from './Project/OpeningTimes';
import Scenes from './Project/Scenes';
import Statistics from './Project/Statistics';
import Styling from './Project/Styling';
import TermsOfService from './Project/TermsOfService';
import PushNotifications from './Project/PushNotifications';

const Project = () => {
    const me = useContext(MeContext);
    const params = useParams();
    const history = useHistory();
    const [languageCode, setLanguageCode] = useLocalStorage('language');
    // FIXME error when user does not have access to active tab
    const [activeTab, setActiveTab] = useState('scenes');
    const { data, loading } = useProject(params.id);
    const { project, buttons } = !loading && data;

    const setLanguage = (newLanguage) => setLanguageCode(newLanguage.code);
    const language = project?.languages.find(({ code }) => code === languageCode);

    useEffect(() => {
        // set initial language
        if (project && !language) {
            setLanguage(project.defaultLanguage);
        }
    }, [project]);

    const renderContent = () => {
        const { startScene, languages, defaultLanguage } = project;
        const scenes = [...project.scenes].sort((a, b) => {
            if (startScene) {
                const startSceneDiff = (
                    (b.id === startScene.id) - (a.id === startScene.id)
                );

                if (startSceneDiff !== 0) {
                    return startSceneDiff;
                }
            }

            const typeWeight = ['SCENE', 'EXHIBITOR'];
            const typeDiff = typeWeight.indexOf(a.type) - typeWeight.indexOf(b.type);

            return typeDiff !== 0
                ? typeDiff
                : a.name.toLowerCase().localeCompare(b.name.toLowerCase());
        });

        return (
            <LocaleContext.Provider
                value={{ language, languages, defaultLanguage, setLanguage }}
            >
                <StylingContext.Provider value={project.styling}>
                    <BreadCrumbs
                        items={[
                            { label: 'Dashboard', href: '/cms', available: me.admin.isBaseAdmin },
                            { label: project.customer.name, href: `/cms/customer/${project.customer.id}` },
                            { label: project.name },
                        ]}
                        navigate={history.push}
                    />

                    <Tabs
                        items={[
                            { value: 'general', label: 'Allgemeines' },
                            { value: 'scenes', label: 'Szenen', available: me.hasReadAccessToFeature('project.scenes') },
                            { value: 'challenge', label: 'Rally', available: me.hasReadAccessToFeature('project.challenge') },
                            { value: 'openingTimes', label: 'Live', available: me.hasReadAccessToFeature('project.openingTimes') },
                            { value: 'login', label: 'Login', available: me.hasReadAccessToFeature('project.login') },
                            { value: 'styling', label: 'Styling', available: me.hasReadAccessToFeature('project.styling') },
                            { value: 'navigation', label: 'Navigation', available: me.hasReadAccessToFeature('project.navigation') },
                            { value: 'tos', label: 'AGB', available: me.hasReadAccessToFeature('project.tos') },
                            { value: 'pushNotifications', label: 'Push-Nachrichten', available: me.hasReadAccessToFeature('project.notifications') },
                            { value: 'statistics', label: 'Statistik', available: me.hasReadAccessToFeature('project.statistics') },
                        ]}
                        value={activeTab}
                        onChange={setActiveTab}
                    >
                        {activeTab === 'general' && (
                            <General
                                project={project}
                                scenes={scenes}
                            />
                        )}

                        {activeTab === 'scenes' && (
                            <Scenes
                                buttons={buttons}
                                project={project}
                                scenes={scenes}
                            />
                        )}

                        {activeTab === 'challenge' && (
                            <Challenge project={project} />
                        )}

                        {activeTab === 'openingTimes' && (
                            <OpeningTimes project={project} />
                        )}

                        {activeTab === 'login' && (
                            <Login project={project} />
                        )}

                        {activeTab === 'styling' && (
                            <Styling project={project} />
                        )}

                        {activeTab === 'navigation' && (
                            <Navigation
                                project={project}
                                scenes={scenes}
                                loginType={project.loginType}
                            />
                        )}

                        {activeTab === 'tos' && (
                            <TermsOfService project={project} />
                        )}

                        {activeTab === 'pushNotifications' && (
                            <PushNotifications project={project} />
                        )}

                        {activeTab === 'statistics' && (
                            <Statistics />
                        )}
                    </Tabs>
                </StylingContext.Provider>
            </LocaleContext.Provider>
        );
    };

    return (
        <Container>{!loading && renderContent()}</Container>
    );
};

export default Project;
