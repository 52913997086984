import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

const filter = createFilterOptions();

const TagInput = (props) => {
    const changeTags = (event, value) => {
        props.onChange(
            [...new Set(value.map((option) => option.inputValue || option))]
        );
    };

    const onKeyPress = (event) => {
        if (event.which === 13) {
            // Prevent submitting the form on enter
            event.preventDefault();
        }
    };

    const filterOptions = (options, params) => {
        const filtered = filter(options, params);
        const inputValue = params.inputValue.trim();

        if (inputValue !== '') {
            filtered.push({
                inputValue,
                title: `"${inputValue}" hinzufügen`,
            });
        }

        return filtered;
    };

    const getOptionLabel = (option) => (
        option.title || option
    );

    return (
        <div className="ui-cms-tag-input">
            <Autocomplete
                multiple
                value={props.value}
                options={props.availableTags}
                filterOptions={filterOptions}
                getOptionLabel={getOptionLabel}
                onChange={changeTags}
                onKeyPress={onKeyPress}
                handleHomeEndKeys
                filterSelectedOptions
                noOptionsText="Keine Vorschläge"
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Schlagwort hinzufügen"
                    />
                )}
            />
        </div>
    );
};

TagInput.propTypes = {
    value: PropTypes.arrayOf(PropTypes.string).isRequired,
    availableTags: PropTypes.arrayOf(PropTypes.string).isRequired,
    onChange: PropTypes.func.isRequired,
};

export default TagInput;
