import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import LocaleContext from 'contexts/locale';
import useSendNotification from 'hooks/graphql/mutations/sendNotification';
import { apiResponseToFormState } from 'helpers/form';
import { notificationPositions } from 'helpers/notifications';
import {
    createUpdateTranslationInput,
    hasOptionalTranslation,
    hasRequiredTranslation,
} from 'helpers/languages';
import EditingAdmin from 'EditingAdmin';
import TranslationCodeInput from 'cms/molecules/TranslationCodeInput';
import TranslationField from 'cms/molecules/TranslationField';
import Select from 'cms/atoms/Select';
import DateTimePicker from 'cms/atoms/DateTimePicker';
import Switch from 'cms/atoms/Switch';
import TextField from 'cms/atoms/TextField';
import KeyValueTable from 'cms/molecules/KeyValueTable';
import Dialog from 'cms/molecules/Dialog';

const Notification = (props) => {
    const defaultValues = {
        id: undefined,
        title: { _translation: 'text' },
        body: { _translation: 'text' },
        position: 'CENTERED',
        sentAt: {
            _default: moment(),
            _modify: (value) => moment(value),
        },
        sendType: 'immediately',
        duration: '3',
        hasDuration: {
            _default: false,
            _key: 'duration',
            _modify: (duration) => !!duration,
        },
    };

    const localeContext = useContext(LocaleContext);
    const [state, setState] = useState(apiResponseToFormState(props.notification, defaultValues));

    const sendNotification = useSendNotification();

    const validators = [
        {
            name: 'title',
            message: () => 'Bitte gib eine Beschriftung für die Standard-Sprache ein.',
            isValid: (value) => hasRequiredTranslation(value, localeContext.defaultLanguage.id),
        }, {
            name: 'title',
            message: () => 'Bitte gib ebenfalls einen Inhalt für die Standard-Sprache ein.',
            isValid: (value) => hasOptionalTranslation(value, localeContext.defaultLanguage.id),
        }, {
            name: 'body',
            message: () => 'Bitte gib eine Beschriftung für die Standard-Sprache ein.',
            isValid: (value) => hasRequiredTranslation(value, localeContext.defaultLanguage.id),
        }];

    useEffect(() => {
        if (props.isOpen) {
            setState(apiResponseToFormState(props.notification, defaultValues));
        }
    }, [props.isOpen]);

    const save = async (values) => {
        await sendNotification({
            projectId: props.project.id,
            title: createUpdateTranslationInput(values.title),
            body: createUpdateTranslationInput(values.body),
            position: values.position,
            duration: values.hasDuration ? parseInt(values.duration, 10) : null,
            sentAt: values.sendType === 'immediately' ? null : values.sentAt.toISOString(),
        });

        props.onClose();
    };

    return (
        <Dialog
            title={props.notification?.id ? 'Benachrichtigung bearbeiten' : 'Neue Benachrichtigung erstellen'}
            isOpen={props.isOpen}
            onClose={props.onClose}
            onConfirm={save}
            onChange={setState}
            values={state}
            validators={validators}
        >
            {({ errors, onChangeByEvent, onChangeByValue }) => (
                <>
                    <EditingAdmin name={`project-terms-of-service-${props.project.id}`} />

                    <KeyValueTable
                        items={[{
                            key: 'Titel',
                            value: (
                                <TranslationField
                                    name="title"
                                    value={state.title}
                                    onChange={onChangeByValue('title')}
                                    error={errors.title}
                                />
                            ),
                        }, {
                            key: 'Inhalt',
                            value: (
                                <TranslationCodeInput
                                    value={state.body}
                                    onChange={onChangeByValue('body')}
                                    projectFiles={props.project.files}
                                    error={errors.body}
                                />
                            ),
                            align: 'top',
                        }, {
                            key: 'Position',
                            value: (
                                <Select
                                    name="position"
                                    value={state.position}
                                    onChange={onChangeByEvent}
                                    items={notificationPositions}
                                />
                            ),
                            align: 'top',
                        }, {
                            key: 'Versandart',
                            // Scheduling notifications are disabled until they
                            // can be revoked or changed after they have been
                            // created
                            // See: https://vrtualx.atlassian.net/browse/VRX-169
                            available: false,
                            value: (
                                <Select
                                    name="sendType"
                                    value={state.sendType}
                                    onChange={onChangeByEvent}
                                    items={
                                        [{
                                            label: 'Sofort',
                                            value: 'immediately',
                                        }, {
                                            label: 'Zeitgesteuert',
                                            value: 'scheduled',
                                        }]

                                    }
                                />
                            ),
                        }, {
                            key: 'Zeitpunkt',
                            available: state.sendType === 'scheduled',
                            value: (
                                <DateTimePicker
                                    value={state.sentAt}
                                    onChange={onChangeByValue('sentAt')}
                                    error={errors.sentAt}
                                />
                            ),
                            align: 'top',
                        }, {
                            key: 'Dauer (in Sekunden)',
                            value: (
                                <>
                                    <Switch
                                        label="Begrenzt"
                                        name="hasDuration"
                                        checked={state.hasDuration}
                                        onChange={onChangeByEvent}
                                    />
                                    { state.hasDuration && (
                                        <TextField
                                            label="Dauer"
                                            type="number"
                                            min={1}
                                            name="duration"
                                            value={state.duration}
                                            onChange={onChangeByEvent}
                                            error={errors.duration}
                                        />
                                    ) }
                                </>
                            ),
                            align: 'top',
                        }]}
                    />
                </>
            )}
        </Dialog>
    );
};

Notification.defaultProps = {
    notification: null,
};

Notification.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    notification: PropTypes.object,
    project: PropTypes.object.isRequired,
};

export default Notification;
