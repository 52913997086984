import { useMutation } from 'apollo-augmented-hooks';
import { PROJECT } from '../queries/project';

const mutation = `
    mutation createProject($input: CreateProjectInput!) {
        createProject(input: $input) {
            ${PROJECT}
            customer {
                id
            }
        }
    }
`;

export default () => {
    const [mutate] = useMutation(mutation);

    return (input) => mutate({
        input,
        modifiers: [{
            fields: {
                projects: ({ includeIf }) => includeIf(true),
            },
        }, {
            cacheObject: (item) => item.customer,
            fields: {
                projects: ({ includeIf, item }) => includeIf(!!item.customer),
            },
        },
        ],
    });
};
