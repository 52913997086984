import React, { useState } from 'react';
import useAdmins from 'hooks/graphql/queries/admins';
import useDeleteAdmin from 'hooks/graphql/mutations/deleteAdmin';
import Icon from 'Icon';
import Avatar from 'cms/atoms/Avatar';
import Button from 'cms/atoms/Button';
import Headline from 'cms/atoms/Headline';
import Card from 'cms/molecules/Card';
import Grid from 'cms/molecules/Grid';
import Confirm from 'components/ui/cms/molecules/Confirm';
import Container from 'cms/organisms/Container';
import AdminEditor from './editors/Admin';

const Admins = () => {
    const [editorState, setEditorState] = useState({ isOpen: false, id: null });
    const [deleteDialogState, setDeleteDialogState] = useState(null);
    const { data, loading } = useAdmins();
    const deleteAdmin = useDeleteAdmin();

    const openEditorHandler = (id) => () => {
        setEditorState({ isOpen: true, id });
    };

    const closeEditor = () => {
        setEditorState({ ...editorState, isOpen: false });
    };

    const openDeleteDialogHandler = (id) => (event) => {
        event.stopPropagation();
        setDeleteDialogState(id);
    };

    const closeDeleteDialog = () => {
        setDeleteDialogState(null);
    };

    const confirmDelete = () => {
        deleteAdmin(deleteDialogState);

        closeDeleteDialog();
    };

    const renderContent = () => {
        const admins = [...data.admins].sort((a, b) => (
            (b.isSuperAdmin - a.isSuperAdmin)
            || `${a.firstName} ${a.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`)
        ));

        return (
            <>
                <Headline
                    action={(
                        <Button
                            icon="edit"
                            onClick={openEditorHandler(null)}
                        >
                            Neuer Admin
                        </Button>
                    )}
                >
                    Admins
                </Headline>

                <AdminEditor
                    isOpen={editorState.isOpen}
                    admin={admins.find(({ id }) => id === editorState.id)}
                    onClose={closeEditor}
                />

                <Grid>
                    {admins.map((admin) => (
                        <Grid item size={12} key={admin.id}>
                            <Card
                                headerLabel={(
                                    <div className="cms-admins__card-header">
                                        <Avatar person={admin} />

                                        {`${admin.firstName} ${admin.lastName}`}

                                        {admin.isSuperAdmin && (
                                            <span className="cms-admins__super-admin">Super-Admin</span>
                                        )}
                                    </div>
                                )}
                                onClick={openEditorHandler(admin.id)}
                            >
                                {!admin.isSuperAdmin && (
                                    <div
                                        className="cms-admins__delete"
                                        onClick={openDeleteDialogHandler(admin.id)}
                                    >
                                        <Icon type="delete" />
                                    </div>
                                )}
                            </Card>
                        </Grid>
                    ))}
                </Grid>

                <Confirm
                    title="Soll dieser Admin wirklich gelöscht werden?"
                    onConfirm={confirmDelete}
                    confirmLabel="Ja, löschen"
                    onCancel={closeDeleteDialog}
                    cancelLabel="Nein, abbrechen"
                    isOpen={deleteDialogState !== null}
                    destructive
                />
            </>
        );
    };

    return (
        <Container>{!loading && renderContent()}</Container>
    );
};

export default Admins;
