import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { deleteAuthTokens } from 'auth';
import MeContext from 'contexts/me';
import Container from 'cms/organisms/Container';
import useMe from 'hooks/graphql/queries/me';

const MeProvider = (props) => {
    const { data, loading } = useMe();
    const { myAccount } = !loading && data;

    useEffect(() => {
        if (!loading && !myAccount) {
            deleteAuthTokens();
            window.location.href = '/cms/login';
        }
    }, [data]);

    const hasReadAccessToFeature = (admin) => (featureName) => {
        if (!admin.role) {
            return true;
        }

        const roleFeature = admin.role.features.find((feature) => feature.name === featureName);
        if (!roleFeature) {
            console.error(`role feature '${featureName}' is not defined`);
            return false;
        }

        return roleFeature.readAccess;
    };

    const hasWriteAccessToFeature = (admin) => (featureName) => {
        if (!admin.role) {
            return true;
        }

        const roleFeature = admin.role.features.find((feature) => feature.name === featureName);
        if (!roleFeature) {
            console.error(`role feature '${featureName}' is not defined`);
            return false;
        }

        return roleFeature.writeAccess;
    };

    const hasAccessToProject = (admin) => (projectId) => {
        if (admin.fullProjectAccess) {
            return true;
        }

        return admin.accessibleProjects.some((project) => project.id === projectId);
    };

    const hasAccessToScene = (admin) => (sceneId) => {
        if (admin.fullSceneAccess) {
            return true;
        }

        return admin.accessibleScenes.some((scene) => scene.id === sceneId);
    };

    const renderContent = () => {
        if (!myAccount) {
            return null;
        }

        const admin = myAccount.admin;

        // this is equivalent to resolveTokens in API
        const value = {
            ...myAccount,
            hasReadAccessToFeature: hasReadAccessToFeature(admin),
            hasWriteAccessToFeature: hasWriteAccessToFeature(admin),
            hasAccessToProject: hasAccessToProject(admin),
            hasAccessToScene: hasAccessToScene(admin),
        };

        return (
            <MeContext.Provider value={value}>
                {myAccount && props.children}
            </MeContext.Provider>
        );
    };

    return (
        <Container>{!loading && renderContent()}</Container>
    );
};

MeProvider.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.func,
    ]).isRequired,
};

export default MeProvider;
