export const hasValue = (dataItem) => (
    dataItem.field.type === 'CHECKBOX' || !!dataItem.value
);

export const formatValue = (dataItem) => {
    if (dataItem.field.type === 'CHECKBOX') {
        return dataItem.value === 'true' ? 'Ja' : 'Nein';
    }
    return dataItem.value;
};
