import React, { useContext } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import StylingContext from 'contexts/styling';
import LocaleContext from 'contexts/locale';
import { resolveLanguageName } from 'helpers/languages';

const LanguageSelector = () => {
    const styling = useContext(StylingContext);
    const localeContext = useContext(LocaleContext);

    const renderLanguage = (language) => {
        const active = language === localeContext.language;
        const style = active ? undefined : { // keep bootstrap colors for active item
            backgroundColor: styling.backgroundColor,
            color: styling.textColor,
        };
        return (
            <Dropdown.Item
                key={language.id}
                className="web-project-language-selector__language"
                active={active}
                style={style}
                onClick={() => localeContext.setLanguage(language)}
            >
                {resolveLanguageName(language.code)}
            </Dropdown.Item>
        );
    };

    return (
        <Dropdown.Menu
            className="web-project-language-selector"
            style={{ backgroundColor: styling.backgroundColor }}
        >
            {localeContext.languages.map(renderLanguage)}
        </Dropdown.Menu>
    );
};

export default LanguageSelector;
