import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import useTracking from 'hooks/useTracking';
import useCustomCodeCleanup from 'hooks/useCustomCodeCleanup';
import { TRACKING_SHOW_INFOBOX } from 'helpers/tracking';
import InnerHTML from 'InnerHTML';

const InfoboxContent = (props) => {
    const tracking = useTracking();

    useCustomCodeCleanup('infobox');

    useEffect(() => {
        // TRACKCORE:
        //  event:'showInfobox', ressource: props.title, data: {customer: customerId, scene: props.sceneName }
        //  SCENE CONTENT: info button clicked
        // console.log('%cTRACKCORE: showInfobox-content', 'background: #00a8ff; font-weight: bold;', props.title);
        tracking.trackCoreEvent({
            action: 'showInfobox',
            resourceType: 'title',
            resourceName: props.title,
        });
        tracking.trackEvent(props.sceneName, TRACKING_SHOW_INFOBOX, props.title);
    }, []);

    return (
        <InnerHTML>{props.children}</InnerHTML>
    );
};

InfoboxContent.defaultProps = {
    children: null,
    title: '',
};

InfoboxContent.propTypes = {
    children: PropTypes.node,
    sceneName: PropTypes.string.isRequired,
    title: PropTypes.string,
};

export default InfoboxContent;
