import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Draggable from 'Draggable';

const Sortable = (props) => {
    const [draggingState, setDraggingState] = useState('none');
    const draggingIndex = useRef(null);
    const droppingIndex = useRef(null);
    const dropping = useRef(false);
    const originalX = useRef(0);
    const originalY = useRef(0);
    const offsetX = useRef(0);
    const offsetY = useRef(0);
    const draggables = useRef([]);

    const onDragStartHandler = (index) => (event) => {
        draggables.current = Array
            .from(event.currentTarget.closest('.draggable').parentNode.querySelectorAll('.draggable'))
            .map((draggable) => {
                const rect = draggable.getBoundingClientRect();

                return {
                    bottom: rect.bottom,
                    offsetLeft: draggable.offsetLeft,
                    offsetTop: draggable.offsetTop,
                    height: rect.height,
                    left: rect.left,
                    right: rect.right,
                    top: rect.top,
                    width: rect.width,
                };
            });
        draggingIndex.current = index;
        droppingIndex.current = index;
        originalX.current = event.touches ? event.touches[0].clientX : event.clientX;
        originalY.current = event.touches ? event.touches[0].clientY : event.clientY;
        setDraggingState('dragStart');
    };

    const onDrag = (event) => {
        const clientX = event.touches ? event.touches[0].clientX : event.clientX;
        const clientY = event.touches ? event.touches[0].clientY : event.clientY;

        offsetX.current = clientX - originalX.current;
        offsetY.current = clientY - originalY.current;

        const newIndex = draggables.current.findIndex((draggable) => (
            clientX >= draggable.left
            && clientX <= draggable.right
            && clientY >= draggable.top
            && clientY <= draggable.bottom
        ));

        if (newIndex >= 0) {
            droppingIndex.current = newIndex;
        }

        setDraggingState(`${offsetX.current}${offsetY.current}`);
    };

    const onDragEnd = () => {
        const oldIndex = draggingIndex.current;
        const newIndex = droppingIndex.current;

        dropping.current = true;
        offsetX.current = draggables.current[newIndex].left - draggables.current[oldIndex].left;
        offsetY.current = draggables.current[newIndex].top - draggables.current[oldIndex].top;

        window.setTimeout(() => {
            if (newIndex !== oldIndex) {
                props?.onChange(newIndex, oldIndex);
            }

            draggingIndex.current = null;
            droppingIndex.current = null;
            dropping.current = false;
            offsetX.current = 0;
            offsetY.current = 0;
            draggables.current = [];

            setDraggingState('none');
        }, 300);

        setDraggingState('dragEnd');
    };

    const draggableStyleHandler = (index) => {
        if (
            draggingIndex.current !== null
            && droppingIndex.current !== null
            && index !== draggingIndex.current
        ) {
            const originalRect = draggables.current[index];
            let targetRect;

            if (
                droppingIndex.current > draggingIndex.current
                && index <= droppingIndex.current
                && index > draggingIndex.current
            ) {
                targetRect = draggables.current[index - 1];
            } else if (
                droppingIndex.current < draggingIndex.current
                && index >= droppingIndex.current
                && index < draggingIndex.current
            ) {
                targetRect = draggables.current[index + 1];
            } else if (droppingIndex.current === draggingIndex.current) {
                targetRect = originalRect;
            } else {
                return {
                    transition: 'transform 300ms',
                    zIndex: 1,
                };
            }

            return {
                transform: `translate(${targetRect.left - originalRect.left}px, ${targetRect.top - originalRect.top}px)`,
                transition: 'transform 300ms',
                zIndex: 1,
            };
        }

        if (index !== draggingIndex.current) {
            return null;
        }

        return {
            content: draggingState,
            zIndex: 2,
            transform: `translate(${offsetX.current}px, ${offsetY.current}px)`,
            transition: dropping.current ? 'transform 300ms' : null,
        };
    };

    const placeholderStyleHandler = (draggable, index) => ({
        height: draggable.height,
        left: draggable.offsetLeft,
        opacity: index === droppingIndex.current ? 1 : 0,
        width: draggable.width,
        top: draggable.offsetTop,
    });

    return (
        <>
            {React.Children.map(props.children, (child, index) => (
                <Draggable
                    onDragStart={onDragStartHandler(index)}
                    onDrag={onDrag}
                    onDragEnd={onDragEnd}
                    style={draggableStyleHandler(index)}
                    disabled={dropping.current}
                    ignoreSelector={props.ignoreSelector}
                    handle={props.handle}
                >
                    {child}
                </Draggable>
            ))}

            {draggables.current.map((draggable, index) => (
                <div
                    className="sortable__placeholder"
                    key={index}
                    style={placeholderStyleHandler(draggable, index)}
                />
            ))}
        </>
    );
};

Sortable.defaultProps = {
    ignoreSelector: null,
    handle: null,
    onChange: null,
};

Sortable.propTypes = {
    children: PropTypes.node.isRequired,
    onChange: PropTypes.func,
    ignoreSelector: PropTypes.string,
    handle: PropTypes.string,
};

export default Sortable;
