import React from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Grid = (props) => {
    if (props.row) {
        return (
            <Row>{props.children}</Row>
        );
    }

    if (props.column) {
        return (
            <Col xs={props.size}>{props.children}</Col>
        );
    }

    return (
        <Container
            className="ui-web-grid"
            spacing={2}
        >
            {props.children}
        </Container>
    );
};

Grid.defaultProps = {
    row: false,
    column: false,
    size: 12,
};

Grid.propTypes = {
    children: PropTypes.node.isRequired,
    size: PropTypes.number,
    row: PropTypes.bool,
    column: PropTypes.bool,
};

export default Grid;
