import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import animateScrollTo from 'animated-scroll-to';
import usePrevious from 'hooks/usePrevious';
import DialogContext from 'contexts/dialog';

const DialogHelper = (props) => {
    const dialogRef = useRef();
    const [loading, setLoading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(null);
    const uploadProgressRef = useRef(uploadProgress);
    const [errors, setErrors] = useState({});
    const previousErrors = usePrevious(errors);
    const valuesRef = useRef(props.values);
    let progressTimeout;

    useEffect(() => {
        if (props.isOpen) {
            // Hacky but pragmatic way to always focus the first input in the dialog
            window.setTimeout(() => {
                if (props.firstInputSelector) {
                    const inputs = [
                        ...dialogRef.current.querySelectorAll(props.firstInputSelector),
                    ];
                    const firstInput = inputs.find((input) => !input.closest('[data-disabled="true"]'));

                    if (!props.preventFirstTextFieldFocus && firstInput) {
                        firstInput.focus();
                    }
                }
            }, 10);

            setErrors({});
        }
    }, [props.isOpen]);

    useEffect(() => {
        valuesRef.current = props.values;
    }, [props.values]);

    useEffect(() => {
        if (Object.keys(errors).length > Object.keys(previousErrors).length) {
            const scrollElement = dialogRef.current.querySelector('.MuiDialogContent-root');

            if (scrollElement) {
                const errorElement = dialogRef.current.querySelector('.MuiFormHelperText-root.Mui-error');

                if (!errorElement) {
                    return;
                }

                const inputElement = errorElement.parentNode.querySelector('input');

                // Scroll up to the first error
                animateScrollTo(errorElement, {
                    elementToScroll: scrollElement,
                    verticalOffset: -100,
                });

                const isDisabled = !!errorElement.closest('[data-disabled="true"]');

                if (inputElement && !isDisabled) {
                    inputElement.focus();
                }
            }
        }
    }, [errors]);

    useEffect(() => () => {
        window.clearTimeout(progressTimeout);
    }, []);

    const validate = (validators, values, graphQLErrors) => (
        validators.reduce((result, { name, serverError, isValid, message }) => {
            const segments = name.split('.');
            const value = segments.reduce((previous, current) => (
                previous[current]
            ), values);

            if (result[name]) {
                return result;
            }

            if (serverError) {
                const error = graphQLErrors.find((e) => e.message === serverError);
                if (error) {
                    return {
                        ...result,
                        [name]: message(value, error),
                    };
                }
                return result;
            }

            return (
                isValid(value, values, graphQLErrors) ? (
                    result
                ) : ({
                    ...result,
                    [name]: message(value),
                })
            );
        }, {})
    );

    const handleErrors = (validators, graphQLErrors) => {
        const validationResult = validate(validators, props.values, graphQLErrors);

        setErrors(validationResult);

        return Object.keys(validationResult).length > 0;
    };

    const onConfirm = async (event) => {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }

        // React batches state updates unless they are wrapped in a promise. The errors need to be
        // reset before the errors are handled again so the scroll effect handler works even if the
        // number of errors hasn't changed
        await Promise.resolve();
        setErrors({});

        if (handleErrors(props.validators.filter(({ serverError }) => !serverError))) {
            return;
        }

        if (uploadProgressRef.current !== null) {
            setLoading(true);
            progressTimeout = window.setTimeout(onConfirm, 300);
            return;
        }

        const confirmPromise = props.onConfirm(valuesRef.current);

        if (confirmPromise && confirmPromise.then) {
            setLoading(true);

            try {
                await confirmPromise;
                setLoading(false);
            } catch (error) {
                setLoading(false);

                if (!handleErrors(
                    props.validators.filter(({ serverError }) => !!serverError),
                    error.graphQLErrors,
                )) {
                    throw error;
                }
            }
        }
    };

    const computeValues = (currentState, segments, value) => {
        const nextSegments = [...segments];
        const segment = nextSegments.shift();
        const safeValue = typeof value === 'object' && value.constructor.name === 'Object' ? {
            ...currentState[segment],
            ...value,
        } : value;

        if (segment.match(/^\d+$/)) {
            const index = parseInt(segment, 10);

            return [
                ...currentState.slice(0, index),
                nextSegments.length > 0
                    ? computeValues(currentState[index], nextSegments, value)
                    : safeValue,
                ...currentState.slice(index + 1),
            ];
        }

        return {
            ...currentState,
            [segment]: nextSegments.length > 0
                ? computeValues(currentState[segment], nextSegments, value)
                : safeValue,
        };
    };

    const resetError = (name) => {
        const nextErrors = { ...errors };

        delete nextErrors[name];

        setErrors(nextErrors);
    };

    const onChangeByEvent = (event) => {
        const { name, type, checked, value } = event.target;

        props.onChange((state) => computeValues(
            state,
            name.split('.'),
            type === 'checkbox' ? checked : value,
        ));

        resetError(name);
    };

    const onChangeByValue = (name) => (value) => {
        props.onChange((state) => computeValues(
            state,
            name.split('.'),
            value,
        ));

        resetError(name);
    };

    const changeUploadProgress = (value) => {
        setUploadProgress(value);
        uploadProgressRef.current = value;
    };

    return (
        <DialogContext.Provider value={{ changeUploadProgress }}>
            {props.children({
                onConfirm,
                onChangeByEvent,
                onChangeByValue,
                loading,
                uploadProgress,
                errors,
                dialogRef,
            })}
        </DialogContext.Provider>
    );
};

DialogHelper.defaultProps = {
    validators: [],
    preventFirstTextFieldFocus: false,
    firstInputSelector: null,
};

DialogHelper.propTypes = {
    values: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    children: PropTypes.func.isRequired,
    validators: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        message: PropTypes.func.isRequired,
        isValid: PropTypes.func,
        serverError: PropTypes.string,
    })),
    preventFirstTextFieldFocus: PropTypes.bool,
    firstInputSelector: PropTypes.string,
};

export default DialogHelper;
