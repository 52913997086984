import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useTranslate from 'hooks/useTranslate';
import InnerHTML from 'InnerHTML';
import Button from 'web/atoms/Button';
import Checkbox from 'web/atoms/Checkbox';
import Dialog from 'web/molecules/Dialog';

const TermsOfService = (props) => {
    const t = useTranslate();
    const [isConfirmed, setConfirmed] = useState(false);

    const customContentTranslation = t(props.customContent);
    const customCheckboxTranslation = t(props.customCheckbox);

    const renderPopup = () => (
        <Dialog
            isOpen={props.isOpen}
            title={t('web.tos.title')}
        >
            {customContentTranslation ? (
                <InnerHTML>{customContentTranslation}</InnerHTML>
            ) : (
                <>
                    <p>{t('web.tos.browser')}</p>
                    <p>{t('web.tos.tracking')}</p>
                </>
            )}

            <div className="web-terms-of-service__check">
                <Checkbox
                    checked={isConfirmed}
                    onChange={setConfirmed}
                >
                    {customCheckboxTranslation ? (
                        <InnerHTML>{customCheckboxTranslation}</InnerHTML>
                    ) : (
                        t('web.tos.agreement', {
                            tosLink: (
                                <a href="https://vrtual-x.com/agb/" target="_blank" rel="noreferrer">
                                    {t('web.tos.tosLinkText')}
                                </a>
                            ),
                            privacyLink: (
                                <a href="https://vrtual-x.com/datenschutz/" target="_blank" rel="noreferrer">
                                    {t('web.tos.privacyLinkText')}
                                </a>
                            ),
                        })
                    )}
                </Checkbox>
            </div>

            <Button
                onClick={props.onConfirm}
                disabled={!isConfirmed}
            >
                {t('web.tos.confirm')}
            </Button>
        </Dialog>
    );

    const renderBanner = () => {
        if (!props.isOpen) {
            return null;
        }

        return (
            <div className="web-terms-of-service web-terms-of-service--banner">
                {customContentTranslation ? (
                    <InnerHTML>{customContentTranslation}</InnerHTML>
                ) : (
                    t('web.tos.tracking')
                )}

                <Button onClick={props.onConfirm}>OK</Button>
            </div>
        );
    };

    return props.type === 'POPUP' ? renderPopup() : renderBanner();
};

TermsOfService.defaultProps = {
    customContent: null,
    customCheckbox: null,
};

TermsOfService.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onConfirm: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    customContent: PropTypes.object,
    customCheckbox: PropTypes.object,
};

export default TermsOfService;
