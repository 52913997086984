export const findSceneByPath = (scenePath, scenes, startScene) => (
    scenes.find(({ path }) => path === scenePath)
    || scenes.reduce((result, current) => {
        const childScene = current.panoramaChildScenes.find(({ path }) => (
            path === scenePath
        ));

        if (childScene) {
            // If the path matches a scene's panorama child scene, but that scene is a panorama
            // scene itself, we need to render that scene. A panorama scene's panorama child
            // scenes are not available.
            return current.panorama ? current : childScene;
        }

        return result;
    }, null)
    || (startScene && scenes.find(({ id }) => id === startScene.id))
);

export const buildQuotaError = (t, subjects) => {
    const translatedSubjects = subjects
        .map((subject) => subject.name)
        .map(t)
        .join(',');

    return `Das Kontingent folgender Elemente wurde überschritten: ${translatedSubjects}`;
};
