import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'Icon';
import Button from './Button';

const FileList = (props) => {
    const removeHandler = (index) => () => {
        props.onRemove(index);
    };

    return (
        <div className="ui-cms-file-list">
            {props.files.map((file, index) => (
                <div key={index} className="ui-cms-file-list__file">
                    {props.onRemove && (
                        <div
                            className="ui-cms-file-list__delete"
                            onClick={removeHandler(index)}
                        >
                            <Icon type="delete" />
                        </div>
                    )}

                    <div className="ui-cms-file-list__name">
                        <span>{file.label}</span>
                        {file.button && (
                            <Button
                                icon={file.button.icon}
                                onClick={() => file.button.onClick(index)}
                            />
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
};

FileList.defaultProps = {
    onRemove: null,
};

FileList.propTypes = {
    onRemove: PropTypes.func,
    files: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        button: PropTypes.shape({
            icon: PropTypes.string,
            onClick: PropTypes.func,
        }),
    })).isRequired,
};

export default FileList;
