import { useMutation } from 'apollo-augmented-hooks';

const mutation = `
    mutation deleteVisitors($ids: [ID!]) {
        deleteVisitors(ids: $ids) {
            id
            project {
                id
            }
        }
    }
`;

export default (project) => {
    const [mutate] = useMutation(mutation);

    return (ids) => (
        mutate({
            input: ids,
            modifiers: [{
                cacheObject: project,
                fields: {
                    visitors: ({ includeIf, item, toReference }) => (
                        includeIf(false, { subjects: item.map((visitor) => toReference(visitor)) })
                    ),
                },
            },
            (item) => ({
                cacheObject: item,
                evict: true,
            }),
            ],
        })
    );
};
