import { useQuery } from 'apollo-augmented-hooks';

export const BUTTON = `
    id
    name
    type
    image {
        id
        url
        mini: variation(type: MINI) {
            url
        }
    }
    inUseByActions
`;

const query = `
    query settings {
        buttons {
            ${BUTTON}
        }
    }
`;

export default () => (
    useQuery(query)
);
