import { useState, useEffect } from 'react';
var useImage = function (src) {
    var _a = useState(false), hasLoaded = _a[0], setHasLoaded = _a[1];
    var _b = useState(false), hasError = _b[0], setHasError = _b[1];
    var _c = useState(false), hasStartedInitialFetch = _c[0], setHasStartedInitialFetch = _c[1];
    useEffect(function () {
        setHasStartedInitialFetch(true);
        setHasLoaded(false);
        setHasError(false);
        // Here's where the magic happens.
        var image = new Image();
        image.src = src;
        var handleError = function () {
            setHasError(true);
        };
        var handleLoad = function () {
            setHasLoaded(true);
            setHasError(false);
        };
        image.onerror = handleError;
        image.onload = handleLoad;
        return function () {
            image.removeEventListener('error', handleError);
            image.removeEventListener('load', handleLoad);
        };
    }, [src]);
    return { hasLoaded: hasLoaded, hasError: hasError, hasStartedInitialFetch: hasStartedInitialFetch };
};
export default useImage;
