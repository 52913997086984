import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import useTranslate from 'hooks/useTranslate';
import ErrorAlert from 'web/atoms/ErrorAlert';

const External = (props) => {
    const t = useTranslate();
    const [loggingIn, setLoggingIn] = useState(false);

    useEffect(() => {
        (async () => {
            const urlHasQueryParam = queryString.parse(window.location.search)[props.queryParam];

            if (urlHasQueryParam) {
                setLoggingIn(true);
                await props.onConfirm();
                setLoggingIn(false);
            }
        })();
    }, []);

    let loginDialogTranslationKey = 'web.login.external.message.register';

    if (props.loginDialogType === 'LOGIN') {
        loginDialogTranslationKey = 'web.login.external.message.login';
    }

    return loggingIn ? (
        <p>{t('web.login.external.loggingIn')}</p>
    ) : (
        <>
            {props.errors.general && (
                <ErrorAlert>{props.errors.general}</ErrorAlert>
            )}
            {props.loginDialogType !== 'HIDDEN' && (
                <p>
                    {t(loginDialogTranslationKey, {
                        loginLink: (
                            <a href={props.externalLoginUrl}>
                                {t('web.login.external.loginLinkText')}
                            </a>
                        ),
                    })}
                </p>
            )}
        </>
    );
};

External.propTypes = {
    queryParam: PropTypes.string.isRequired,
    externalLoginUrl: PropTypes.string.isRequired,
    loginDialogType: PropTypes.string.isRequired,
    onConfirm: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
};

export default External;
