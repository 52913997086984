import { useMutation } from 'apollo-augmented-hooks';
import { TRANSLATION } from '../helpers/translations';
import { ACTION, OVERLAY } from '../queries/scene';

const mutation = `
    mutation createSceneVersion($input: CreateSceneVersionInput!) {
        createSceneVersion(input: $input) {
            id
            name
            startTime
            endTime
            widget {
                ${TRANSLATION}
            }
            background {
                id
                url
                standard: variation(type: STANDARD) {
                    url
                }
                thumbnail: variation(type: THUMBNAIL) {
                    url
                }
            }
            actions {
                ${ACTION}
            }
            objects {
                id
            }
            overlays {
                ${OVERLAY}
            }
            scene {
                id
            }
            videoBackground {
                ${TRANSLATION}
            }
            videoBackgroundEnabled
            audioBackgroundId
        }
    }
`;

export default () => {
    const [mutate] = useMutation(mutation);

    return (input) => mutate({
        input,
        modifiers: [{
            cacheObject: (item) => item.scene,
            fields: {
                versions: ({ includeIf }) => includeIf(true),
            },
        },
        ],
    });
};
