import { useMutation } from 'apollo-augmented-hooks';
import { VISITOR } from '../queries/project';

const mutation = `
    mutation createVisitorWithAccount($input: CreateVisitorWithAccountInput!) {
        createVisitorWithAccount(input: $input) {
            ${VISITOR}
        }
    }
`;

export default () => {
    const [mutate] = useMutation(mutation);

    return (input) => mutate({
        input,
        modifiers: [{
            fields: {
                projects: ({ includeIf }) => includeIf(true),
            },
        }, {
            cacheObject: (item) => item.project,
            fields: {
                visitors: ({ includeIf, item }) => includeIf(!!item.project),
            },
        },
        ],
    });
};
