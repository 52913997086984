import React, { useRef, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import stripTags from 'striptags';
import AuthContext from 'contexts/auth';
import FileContext from 'contexts/files';
import { makeBlobUrl } from 'Image';

const InnerHTML = (props) => {
    const divRef = useRef(null);
    const authType = useContext(AuthContext);
    const files = useContext(FileContext);

    useEffect(() => {
        (async () => {
            const matches = props.children.match(/\[image:.+]/g) || [];
            const blobUrls = await matches.reduce(async (previousPromise, match) => {
                const result = await previousPromise;
                const file = files.find(({ file: { id } }) => id === match.match(/\[image:(.+)]/)[1]);

                if (!file) {
                    return result;
                }

                return {
                    ...result,
                    [match]: await makeBlobUrl(file.file.url, authType),
                };
            }, Promise.resolve({}));
            const children = Object.entries(blobUrls).reduce((result, [match, blobUrl]) => (
                result.replace(new RegExp(match.replace(/[[\]]/g, '\\$&'), 'g'), blobUrl)
            ), props.children);
            const htmlWithStrippedTags = props.allowedTags
                ? stripTags(children, props.allowedTags)
                : children;
            const slotHtml = document
                .createRange()
                .createContextualFragment(htmlWithStrippedTags);

            if (!divRef.current) {
                return;
            }

            divRef.current.innerHTML = '';
            divRef.current.appendChild(slotHtml);
        })();
    }, [props.children]);

    return (
        <div ref={divRef} />
    );
};

InnerHTML.defaultProps = {
    allowedTags: null,
    children: '',
};

InnerHTML.propTypes = {
    children: PropTypes.string,
    allowedTags: PropTypes.arrayOf(PropTypes.string),
};

export default InnerHTML;
