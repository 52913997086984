import React, { useEffect } from 'react';
import { Switch, Route, useHistory, useLocation } from 'react-router-dom';
import Cms from './App/Cms';
import Web from './App/Web';

const App = () => {
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        const hash = location.hash.substring(1);

        if (hash && location.pathname === '/') {
            history.replace(`${hash}${location.search}`);
        }
    }, []);

    return (
        <div>
            <Switch location={location}>
                <Route path="/cms" component={Cms} />
                <Route path="/" component={Web} />
            </Switch>
        </div>
    );
};

export default App;
