import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import useToggle from 'hooks/useToggle';
import MeContext from 'contexts/me';
import Button from 'cms/atoms/Button';
import Headline from 'cms/atoms/Headline';
import KeyValueTable from 'cms/molecules/KeyValueTable';
import Editor from '../editors/Project/Styling';

const Styling = (props) => {
    const [isEditorOpen, openEditor, closeEditor] = useToggle(false);
    const me = useContext(MeContext);

    const renderColor = (color) => (
        <div
            className="cms-project-styling__color"
            style={{ backgroundColor: color }}
        />
    );

    return (
        <>
            <Headline
                action={me.hasWriteAccessToFeature('project.styling') ? (
                    <Button
                        icon="edit"
                        onClick={openEditor}
                    >
                        Bearbeiten
                    </Button>
                ) : null}
            >
                Styling
            </Headline>

            <Editor
                isOpen={isEditorOpen}
                onClose={closeEditor}
                project={props.project}
            />

            <KeyValueTable
                wrappingDividers
                items={[
                    {
                        key: 'Hintergrundfarbe',
                        value: renderColor(props.project.styling.backgroundColor),
                    },
                    {
                        key: 'Textfarbe',
                        value: renderColor(props.project.styling.textColor),
                    },
                    {
                        key: 'Schriftart',
                        value: props.project.styling.font
                            ? (props.project.styling.font.filename || 'Benutzerdefiniert')
                            : 'Standard',
                    },
                    {
                        key: 'Transparente Menüleiste',
                        value: props.project.styling.transparentNavbar ? 'Ja' : 'Nein',
                    },
                ]}
            />
        </>
    );
};

Styling.propTypes = {
    project: PropTypes.object.isRequired,
};

export default Styling;
