import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import useToggle from 'hooks/useToggle';
import useTranslate from 'hooks/useTranslate';
import tosTypes from 'helpers/tosTypes';
import MeContext from 'contexts/me';
import Button from 'cms/atoms/Button';
import Headline from 'cms/atoms/Headline';
import KeyValueTable from 'cms/molecules/KeyValueTable';
import Editor from '../editors/Project/TermsOfService';

const TermsOfService = (props) => {
    const t = useTranslate();
    const [isEditorOpen, openEditor, closeEditor] = useToggle(false);
    const me = useContext(MeContext);

    return (
        <>
            <Headline
                action={me.hasWriteAccessToFeature('project.tos') ? (
                    <Button
                        icon="edit"
                        onClick={openEditor}
                    >
                        Bearbeiten
                    </Button>
                ) : null}
            >
                AGB
            </Headline>

            <Editor
                isOpen={isEditorOpen}
                onClose={closeEditor}
                project={props.project}
            />

            <KeyValueTable
                wrappingDividers
                items={[
                    {
                        key: 'AGB-Bestätigung',
                        value: props.project.tosEnabled ? 'Ja' : 'Nein',
                    },
                    {
                        key: 'Darstellung der AGB-Bestätigung',
                        value: tosTypes.find(({ value }) => value === props.project.tosType).label,
                        available: props.project.tosEnabled,
                    },
                    {
                        key: 'Individueller Inhalt für AGB-Bestätigung',
                        value: t(props.project.tosCustomContent) ? 'Ja' : 'Nein',
                        available: props.project.tosEnabled,
                    },
                    {
                        key: 'Individueller Checkbox-Text für AGB-Bestätigung',
                        value: t(props.project.tosCustomCheckbox) ? 'Ja' : 'Nein',
                        available: props.project.tosEnabled && props.project.tosType === 'POPUP',
                    },
                ]}
            />
        </>
    );
};

TermsOfService.propTypes = {
    project: PropTypes.object.isRequired,
};

export default TermsOfService;
