import { useEffect } from 'react';

const snapshots = {};
const windowExceptions = [
    'YT', // Needed for YouTube videos in react-player
    'Vimeo', // Needed for Vimeo videos in react-player
    '__cms', // our API

    // Objects related to Tawk SDK.
    //
    // FIXME: This is a workaround for this ticket:
    // https://vrtual-x.monday.com/boards/583307710/pulses/1333780025
    //
    // Overlay code is loaded **after** the snapshot of the window object is
    // taken. Tawk (and probably other libraries as well) throw new elements
    // onto the `window` object at will, which is not captured by the snapshots
    // taken by this hook.
    /.*Tawk.*/,
];

const isException = (prop) => windowExceptions.some((exception) => {
    if (exception instanceof RegExp) {
        return exception.test(prop);
    }

    return prop === exception;
});

const getWindowProps = () => (
    Object.entries(window)
        .filter(([, value]) => value !== undefined)
        .map(([key]) => key)
);

const storeDocumentSnapshot = (key) => {
    snapshots[key] = {
        windowProps: getWindowProps(),
        bodyChildren: [...document.body.children],
        headChildren: [...document.head.children],
    };
};

const removeWidgetArtifacts = (key) => {
    if (snapshots[key]) {
        const { windowProps, bodyChildren, headChildren } = snapshots[key];

        getWindowProps().forEach((prop) => {
            if (!windowProps.includes(prop) && !isException(prop)) {
                const { writable } = Object.getOwnPropertyDescriptor(window, prop);

                if (writable) {
                    if (process.env.NODE_ENV === 'development') {
                        console.info(`Custom code cleanup: removing "${prop}" from window object`, window[prop]);
                    }

                    window[prop] = undefined;
                }
            }
        });

        [...document.body.children].forEach((child) => {
            if (!bodyChildren.some((oldChild) => oldChild === child)) {
                document.body.removeChild(child);
            }
        });

        [...document.head.children].forEach((child) => {
            if (
                !headChildren.some((oldChild) => oldChild === child)
                // Leave style tags lazy loaded by material-ui alive
                && !child.dataset.meta
            ) {
                document.head.removeChild(child);
            }
        });

        delete snapshots[key];
    }
};

// The dangers of allowing user HTML: Cache the state of the window object and the body and head
// tag right when the component is mounted. When the CSS transition state is "exiting" (meaning
// the scene has just started transitioning to the next), remove anything from the window object
// and the body and head that wasn't there before. The widget itself is displayed only when the
// scene transition is done, to prevent it from polluting the document before we have cached it.
export default (key, toggle) => {
    if (toggle !== undefined) {
        useEffect(() => {
            if (toggle) {
                storeDocumentSnapshot(key);
            } else {
                removeWidgetArtifacts(key);
            }
        }, [toggle]);
    } else {
        useEffect(() => {
            storeDocumentSnapshot(key);

            return () => removeWidgetArtifacts(key);
        }, []);
    }

    return () => removeWidgetArtifacts(key);
};
