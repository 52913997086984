import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import MuiIconButton from '@material-ui/core/IconButton';
import Icon from 'Icon';

const useStyles = makeStyles((theme) => ({
    root: (props) => ({
        backgroundColor: props.secondary
            ? theme.palette.secondary.main
            : theme.palette.primary.main,
    }),
}));

const IconButton = (props) => {
    const classes = useStyles(props);

    return (
        <div
            className={classNames(
                'ui-cms-icon-button',
                classes.root,
                { 'ui-cms-icon-button--secondary': props.secondary }
            )}
        >
            <MuiIconButton
                onClick={props.onClick}
                className="ui-cms-icon-button__button"
                size="small"
            >
                <Icon type={props.type} />
            </MuiIconButton>
        </div>
    );
};

IconButton.defaultProps = {
    onClick: null,
    secondary: false,
};

IconButton.propTypes = {
    type: PropTypes.string.isRequired,
    secondary: PropTypes.bool,
    onClick: PropTypes.func,
};

export default IconButton;
