import React from 'react';
import PropTypes from 'prop-types';
import IconButton from 'cms/atoms/IconButton';

const AddButton = (props) => (
    <div
        className="cms-actions-editor__add"
        style={{
            left: `${props.x * 100}%`,
            top: `${props.y * 100}%`,
        }}
        onClick={props.onClick}
    >
        <IconButton type="add" />
    </div>
);

AddButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
};

export default React.memo(AddButton, (prevProps, nextProps) => (
    prevProps.x === nextProps.x && prevProps.y === nextProps.y
));
