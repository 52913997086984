import { useQuery } from 'apollo-augmented-hooks';

const query = `
    query projectStatistics($id: ID!, $filter: StatisticsFilter!) {
        project(id: $id) {
            id
            statistics(filter: $filter) {
                totalClicks
                uniqueClicks
                eventActions {
                    label
                    totalClicks
                    uniqueClicks
                    names {
                        label
                        totalClicks
                        uniqueClicks
                    }
                }
                cities {
                    label
                    totalClicks
                    uniqueClicks
                    latitude
                    longitude
                }
                countries {
                    label
                    totalClicks
                    uniqueClicks
                    code
                }
                hours {
                    label
                    totalClicks
                    uniqueClicks
                }
            }
        }
    }
`;

export default (id, startDate, endDate) => (
    useQuery(query, {
        variables: {
            id,
            filter: {
                from: startDate,
                to: endDate,
            },
        },
    })
);
