import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { apiResponseToFormState } from 'helpers/form';
import EditingAdmin from 'EditingAdmin';
import TextField from 'cms/atoms/TextField';
import Select from 'cms/atoms/Select';
import MultiSelect from 'cms/atoms/MultiSelect';
import KeyValueTable from 'cms/molecules/KeyValueTable';
import Dialog from 'cms/molecules/Dialog';
import useCreateAdmin from 'hooks/graphql/mutations/createAdmin';
import useUpdateAdmin from 'hooks/graphql/mutations/updateAdmin';

const defaultValues = {
    id: undefined,
    roleId: {
        _default: '',
        _key: 'role',
        _modify: ({ id }) => id,
    },
    firstName: '',
    lastName: '',
    account: {
        id: undefined,
        email: '',
        password: '',
        passwordConfirm: '',
    },
    fullProjectAccess: true,
    fullSceneAccess: true,
    accessibleProjects: {
        _map: ({ id }) => id,
    },
    accessibleScenes: {
        _map: ({ id }) => id,
    },
};

const Admin = (props) => {
    const createAdmin = useCreateAdmin();
    const updateAdmin = useUpdateAdmin();
    const [state, setState] = useState(apiResponseToFormState(props.admin, defaultValues));
    const isCustomerAdmin = !!props.customerId;

    const validators = [{
        name: 'firstName',
        message: () => 'Bitte gib einen Vornamen ein.',
        isValid: (value) => !!value.trim(),
    }, {
        name: 'lastName',
        message: () => 'Bitte gib einen Nachnamen ein.',
        isValid: (value) => !!value.trim(),
    }, {
        name: 'account.email',
        message: () => 'Bitte gib eine E-Mail-Adresse ein.',
        isValid: (value) => !!value.trim(),
    }, {
        name: 'account.email',
        message: () => 'Es gibt bereits einen Admin mit dieser E-Mail-Adresse.',
        serverError: 'EMAIL_ALREADY_IN_USE',
    }, {
        name: 'account.password',
        message: () => 'Bitte gib ein Passwort ein.',
        isValid: (value) => !!props.admin || !!value,
    }, {
        name: 'account.passwordConfirm',
        message: () => 'Die Passwörter stimmen nicht überein.',
        isValid: (value, { account }) => value === account.password,
    }, {
        name: 'roleId',
        message: () => 'Bitte wähle eine Rolle aus.',
        isValid: (value) => !isCustomerAdmin || !!value,
    }];

    useEffect(() => {
        if (props.isOpen) {
            setState(apiResponseToFormState(props.admin, defaultValues));
        }
    }, [props.isOpen]);

    const save = async (values) => {
        const mutation = props.admin ? updateAdmin : createAdmin;

        await mutation({
            id: values.id,
            customerId: !values.id ? props.customerId : undefined,
            roleId: values.roleId || null,
            firstName: values.firstName.trim(),
            lastName: values.lastName.trim(),
            account: {
                id: values.account.id,
                email: values.account.email.trim(),
                password: values.account.password || undefined, // don't overwrite existing if nothing is entered
            },
            fullProjectAccess: isCustomerAdmin ? values.fullProjectAccess : true,
            fullSceneAccess: isCustomerAdmin ? values.fullSceneAccess : true,
            accessibleProjects: values.accessibleProjects.map((id) => ({ id })),
            accessibleScenes: values.accessibleScenes.map((id) => ({ id })),
        });

        props.onClose();
    };

    return (
        <Dialog
            title={props.admin ? 'Kunden-Account bearbeiten' : 'Neuen Kunden-Account erstellen'}
            isOpen={props.isOpen}
            onClose={props.onClose}
            onConfirm={save}
            onChange={setState}
            values={state}
            validators={validators}
        >
            {({ errors, onChangeByEvent, onChangeByValue }) => (
                <>
                    {props.admin && (
                        <EditingAdmin name={`admin-${props.admin.id}`} />
                    )}

                    <KeyValueTable
                        items={[
                            {
                                key: 'Vorname',
                                value: (
                                    <TextField
                                        name="firstName"
                                        value={state.firstName}
                                        onChange={onChangeByEvent}
                                        error={errors.firstName}
                                    />
                                ),
                            },
                            {
                                key: 'Nachname',
                                value: (
                                    <TextField
                                        name="lastName"
                                        value={state.lastName}
                                        onChange={onChangeByEvent}
                                        error={errors.lastName}
                                    />
                                ),
                            },
                            {
                                key: 'E-Mail-Adresse',
                                value: (
                                    <TextField
                                        name="account.email"
                                        value={state.account.email}
                                        onChange={onChangeByEvent}
                                        error={errors['account.email']}
                                    />
                                ),
                            },
                            {
                                key: 'Passwort',
                                value: (
                                    <TextField
                                        name="account.password"
                                        type="password"
                                        value={state.account.password}
                                        onChange={onChangeByEvent}
                                        error={errors['account.password']}
                                    />
                                ),
                            },
                            {
                                key: 'Passwort bestätigen',
                                value: (
                                    <TextField
                                        name="account.passwordConfirm"
                                        type="password"
                                        value={state.account.passwordConfirm}
                                        onChange={onChangeByEvent}
                                        error={errors['account.passwordConfirm']}
                                    />
                                ),
                            },
                            {
                                key: 'Rolle',
                                value: (
                                    <Select
                                        name="roleId"
                                        value={state.roleId}
                                        onChange={onChangeByEvent}
                                        items={
                                            props.roles
                                                .map(({ name, id }) => ({
                                                    label: name,
                                                    value: id,
                                                }))
                                                .sort((a, b) => a.label.localeCompare(b.label))
                                        }
                                        error={errors.roleId}
                                    />
                                ),
                                available: isCustomerAdmin,
                            },
                            {
                                key: 'Projekt-Zugriff',
                                value: (
                                    <MultiSelect
                                        name="accessibleProjects"
                                        selectAll={state.fullProjectAccess}
                                        onSelectAllChange={onChangeByValue('fullProjectAccess')}
                                        values={state.accessibleProjects}
                                        onValuesChange={onChangeByValue('accessibleProjects')}
                                        items={
                                            props.customerProjects.map(({ name, id }) => ({
                                                label: name,
                                                value: id,
                                            }))
                                        }
                                        error={errors.accessibleProjects}
                                    />
                                ),
                                available: isCustomerAdmin,
                            },
                            {
                                key: 'Szenen-Zugriff',
                                value: (
                                    <MultiSelect
                                        name="accessibleScenes"
                                        selectAll={state.fullSceneAccess}
                                        onSelectAllChange={onChangeByValue('fullSceneAccess')}
                                        values={state.accessibleScenes}
                                        onValuesChange={onChangeByValue('accessibleScenes')}
                                        items={
                                            props.customerProjects.flatMap((project) => (
                                                project.scenes.map((scene) => ({
                                                    label: scene.name,
                                                    value: scene.id,
                                                    group: project.name,
                                                }))
                                            ))
                                        }
                                        error={errors.accessibleProjects}
                                    />
                                ),
                                available: isCustomerAdmin,
                            },
                        ]}
                    />
                </>
            )}
        </Dialog>
    );
};

Admin.defaultProps = {
    admin: null,
    customerId: null,
    roles: [],
    customerProjects: [],
};

Admin.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    customerId: PropTypes.string,
    customerProjects: PropTypes.array,
    admin: PropTypes.object,
    roles: PropTypes.array,
};

export default Admin;
