import React, { useContext, useEffect, useRef, useState } from 'react';
import { isIOS } from 'react-device-detect';
import AuthContext from 'contexts/auth';
import useTracking from 'hooks/useTracking';
import useTranslate from 'hooks/useTranslate';
import useToggle from 'hooks/useToggle';
import { TRACKING_PLAY_AUDIO } from 'helpers/tracking';
import PropTypes from 'prop-types';
import { makeBlobUrl } from 'Image';
import Alert from 'web/molecules/Alert';

const AudioPlayer = (props) => {
    const audioRef = useRef(null);
    const [blobUrl, setBlobUrl] = useState('');
    const [isAlertVisible, showAlert, hideAlert] = useToggle(false);
    const authType = useContext(AuthContext);
    const tracking = useTracking();
    const t = useTranslate();

    // Note: iOS does not support autoplay or setting volume without user interaction.
    // Only call stacks that are triggered by a user interaction may do so.
    // So fading is disabled and a dialog for user interaction is shown when needed.

    useEffect(() => {
        if (props.file) {
            downloadAndPlay(props.file.url);
        } else {
            stopPlayback();
        }
    }, [props.file]);

    const downloadAndPlay = async (url) => {
        const audioBlob = await makeBlobUrl(url, authType);
        await fadeOut();
        setBlobUrl(audioBlob);
        await play();
    };

    const stopPlayback = async () => {
        await fadeOut();
        setBlobUrl('');
    };

    const play = async () => {
        try {
            audioRef.current.volume = 1.0;
            await audioRef.current.play();
            trackPlay();
        } catch (error) {
            if (error.name === 'NotAllowedError') {
                // iOS requires user interaction
                showAlert();
            }
        }
    };

    const confirmPlayAudio = () => {
        hideAlert();
        audioRef.current.volume = 1.0;
        audioRef.current.play();
        trackPlay();
    };

    const cancelPlayAudio = () => {
        hideAlert();
        props.onEnded();
    };

    const trackPlay = () => {
        // TRACKCORE:
        //  event:'playAudio', ressource: props.file.filename, data: {scene: props.sceneNme, customer: customerId}
        //  SCENE CONTENT: Audioplayer Button clicked (load audio file and button in edit scene)
        // console.log('%cTRACKCORE: playAudio', 'background: #00a8ff; font-weight: bold;', props.file.filename);
        tracking.trackCoreEvent({
            action: 'playAudio',
            resourceType: 'filename',
            resourceName: props.file.filename,
        });
        tracking.trackEvent(props.sceneName, TRACKING_PLAY_AUDIO, props.file.filename);
    };

    const fadeOut = () => new Promise((resolve) => {
        if (!props.fadeOut || isIOS) {
            if (audioRef.current) {
                audioRef.current.volume = 0;
            }
            resolve();
            return;
        }

        const fadeAudio = setInterval(() => {
            if (!audioRef.current || audioRef.current.volume <= 0.1) {
                clearInterval(fadeAudio);
                resolve();
            } else {
                audioRef.current.volume -= 0.1;
            }
        }, 100);
    });

    return (
        <>
            <audio
                src={blobUrl}
                ref={audioRef}
                onEnded={props.onEnded}
                loop={props.loop}
            />

            <Alert
                title={t('web.audio.confirmation.title')}
                onConfirm={confirmPlayAudio}
                confirmLabel={t('web.audio.confirmation.yes')}
                onCancel={cancelPlayAudio}
                cancelLabel={t('web.audio.confirmation.no')}
                isOpen={isAlertVisible}
            >
                {t('web.audio.confirmation.text')}
            </Alert>
        </>
    );
};

AudioPlayer.defaultProps = {
    file: null,
    loop: false,
    fadeOut: false,
    onEnded: () => {},
};

AudioPlayer.propTypes = {
    file: PropTypes.object,
    sceneName: PropTypes.string.isRequired,
    onEnded: PropTypes.func,
    loop: PropTypes.bool,
    fadeOut: PropTypes.bool,
};

export default AudioPlayer;
