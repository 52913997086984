import React from 'react';
import Card from 'react-bootstrap/Card';
import PropTypes from 'prop-types';
import Header from './Element/Header';
import Body from './Element/Body';

const Element = (props) => {
    const {
        actionClickHandler,
        element,
        project,
        open,
        sceneName,
        accordionRef,
    } = props;

    return (
        <Card>
            <Header
                handleClick={actionClickHandler(element, true)}
                accordionRef={accordionRef}
                element={element}
            />
            <Body
                element={element}
                open={open}
                project={project}
                sceneName={sceneName}
            />
        </Card>
    );
};

Element.propTypes = {
    element: PropTypes.object.isRequired,
    project: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    sceneName: PropTypes.string.isRequired,
    accordionRef: PropTypes.func.isRequired,
    actionClickHandler: PropTypes.func.isRequired,
};
export default Element;
