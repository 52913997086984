import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import MuiAvatar from '@material-ui/core/Avatar';
import mediaUrl from 'helpers/mediaUrl';
import AuthContext from 'contexts/auth';
import { makeBlobUrl } from 'Image';

const Avatar = (props) => {
    const authType = useContext(AuthContext);
    const [url, setUrl] = useState(null);

    useEffect(() => {
        (async () => {
            setUrl(await makeBlobUrl(mediaUrl(props.person.avatar, 'mini'), authType));
        })();
    }, [props.person.avatar]);

    return (
        <MuiAvatar
            className="ui-cms-avatar"
            src={url}
        >
            {`${props.person.firstName.substring(0, 1)}${props.person.lastName.substring(0, 1)}`}
        </MuiAvatar>
    );
};

Avatar.propTypes = {
    person: PropTypes.shape({
        avatar: PropTypes.object,
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
    }).isRequired,
};

export default Avatar;
