import React from 'react';
import PropTypes from 'prop-types';
import MuiCard from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import Typography from '@material-ui/core/Typography';

const Card = (props) => (
    <MuiCard className="ui-cms-card">
        <CardActionArea onClick={props.onClick}>
            <CardContent>
                <Typography
                    variant="h6"
                    component="h2"
                    className="ui-cms-card__header"
                >
                    {props.headerLabel}
                </Typography>

                {props.subLabel && (
                    <Typography
                        color="textSecondary"
                        className="ui-cms-card__sublabel"
                    >
                        {props.subLabel}
                    </Typography>
                )}

                {props.children}
            </CardContent>

            {props.image && (
                <div className="ui-cms-card__image">
                    {props.image}
                </div>
            )}
        </CardActionArea>
    </MuiCard>
);

Card.defaultProps = {
    subLabel: null,
    image: null,
    children: null,
};

Card.propTypes = {
    headerLabel: PropTypes.node.isRequired,
    subLabel: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    image: PropTypes.node,
    children: PropTypes.node,
};

export default Card;
