import React from 'react';
import PropTypes from 'prop-types';
import KeyValueTable from 'cms/molecules/KeyValueTable';

const Overview = (props) => (
    <KeyValueTable
        wrappingDividers
        items={[
            {
                key: 'Besuche',
                value: props.totalClicks,
            },
            {
                key: 'Eindeutige Besucher',
                value: props.uniqueClicks || (
                    'Diese Metrik ist leider nur für einzelne Tage verfügbar.'
                ),
            },
        ]}
    />
);

Overview.defaultProps = {
    uniqueClicks: null,
};

Overview.propTypes = {
    totalClicks: PropTypes.number.isRequired,
    uniqueClicks: PropTypes.number,
};

export default Overview;
