import { useMutation } from 'apollo-augmented-hooks';
var mutation = "\n    mutation createCustomer($input: CreateCustomerInput!) {\n        createCustomer(input: $input) {\n            id\n            name\n        }\n    }\n";
export default (function () {
    var mutate = useMutation(mutation)[0];
    return function (input) { return (mutate({
        input: input,
        modifiers: [{
                fields: {
                    customers: function (_a) {
                        var includeIf = _a.includeIf;
                        return includeIf(true);
                    },
                },
            }],
    })); };
});
