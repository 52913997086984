import React, { useState, useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import StylingContext from 'contexts/styling';
import NewstickerContent from '../shared/NewstickerContent';

const Newsticker = (props) => {
    const styling = useContext(StylingContext);
    const textRef = useRef();
    const [fontSize, setFontSize] = useState(null);
    const [borderWidth, setBorderWidth] = useState(null);
    const [animationDuration, setAnimationDuration] = useState(null);

    useEffect(() => {
        setFontSize(Math.floor((props.containerHeight * props.height) / 2));
        setBorderWidth((props.containerHeight * props.height) / 30);
    }, [props.containerHeight]);

    useEffect(() => {
        setAnimationDuration(`${textRef.current.offsetWidth / 150}s`);
    }, [fontSize]);

    return (
        <div
            className="ui-shared-overlay-newsticker"
            style={{
                borderWidth,
                fontSize,
                borderColor: styling.textColor,
                backgroundColor: styling.backgroundColor,
            }}
        >
            <div
                className="ui-shared-overlay-newsticker__text"
                ref={textRef}
                style={{
                    animationDuration,
                    color: styling.textColor,
                }}
            >
                <NewstickerContent>
                    {props.children}
                </NewstickerContent>
            </div>
        </div>
    );
};

Newsticker.defaultProps = {
    containerHeight: null,
};

Newsticker.propTypes = {
    children: PropTypes.string.isRequired,
    containerHeight: PropTypes.number,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
};

export default Newsticker;
