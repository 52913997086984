import Card from 'react-bootstrap/Card';
import React, { useContext } from 'react';
import Image from 'Image';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import AudioContext from 'contexts/audio';
import StylingContext from 'contexts/styling';
import useTranslate from 'hooks/useTranslate';

const Header = (props) => {
    const { accordionRef, element, handleClick } = props;
    const audioContext = useContext(AudioContext);
    const styling = useContext(StylingContext);
    const t = useTranslate();
    const renderElementButton = () => {
        const isElementPlayingAudio = audioContext.isCurrentAudioFile(element.audioData?.file);
        const imageUrl = isElementPlayingAudio
            ? element.audioData.stopicon.url
            : element.button.image.url;
        return <Image url={imageUrl} />;
    };
    return (
        <Card.Header
            onClick={handleClick}
            className="mobile-scene-accordion-card-header"
            style={{
                backgroundColor: styling.backgroundColor,
                borderColor: styling.textColor,
                color: styling.textColor,
            }}
        >
            <div className="mobile-scene-accordion-card-header__icon">
                {(element.button && (
                    renderElementButton()
                ))}
            </div>
            <h2
                className={classNames(
                    'h1',
                    'mobile-scene-accordion-card-header__label'
                )}
                ref={accordionRef}
            >
                {typeof element.name === 'string' ? element.name : t(element.name)}
            </h2>
        </Card.Header>
    );
};

Header.defaultProps = {
    accordionRef: {},
};

Header.propTypes = {
    accordionRef: PropTypes.func,
    element: PropTypes.object.isRequired,
    handleClick: PropTypes.func.isRequired,
};

export default Header;
