import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { apiResponseToFormState } from 'helpers/form';
import {
    isReadEnabled, isWriteEnabled,
    isReadChecked, isWriteChecked,
} from 'helpers/roleFeatures';
import useCreateRole from 'hooks/graphql/mutations/createRole';
import useUpdateRole from 'hooks/graphql/mutations/updateRole';
import EditingAdmin from 'EditingAdmin';
import TextField from 'cms/atoms/TextField';
import Switch from 'cms/atoms/Switch';
import KeyValueTable from 'cms/molecules/KeyValueTable';
import Dialog from 'cms/molecules/Dialog';

const defaultValues = {
    id: undefined,
    customerId: undefined,
    name: '',
    features: {
        _map: () => ({
            id: undefined,
            name: '',
            behavior: 'DEFAULT',
            writeAccess: false,
            readAccess: false,
        }),
    },
};

const Role = (props) => {
    const createRole = useCreateRole();
    const updateRole = useUpdateRole();

    const roleWithFeatures = {
        ...props.role,
        features: props.role?.features || props.roleFeatures,
    };
    const [state, setState] = useState(apiResponseToFormState(roleWithFeatures, defaultValues));
    const validators = [{
        name: 'name',
        message: () => 'Bitte gib einen Namen ein.',
        isValid: (value) => !!value.trim(),
    }];

    useEffect(() => {
        if (props.isOpen) {
            setState(apiResponseToFormState(roleWithFeatures, defaultValues));
        }
    }, [props.isOpen]);

    const save = async (values) => {
        const mutation = props.role ? updateRole : createRole;

        await mutation({
            id: values.id,
            customerId: values.id ? undefined : props.customerId,
            name: values.name.trim(),
            features: values.features.map((feature) => ({
                id: feature.id,
                name: feature.name,
                writeAccess: feature.writeAccess,
                readAccess: feature.readAccess,
            })),
        });

        props.onClose();
    };

    // TODO some rights depend on a parent right and don't make sense without them:
    // 'customer.accounts.new' depends on 'customer.accounts'

    // TODO some rights require other rights:
    // 'project.new' requires at least 'project.language' and 'project.websiteTitle'

    const renderFeature = (feature, index, onChangeByEvent) => (
        <>
            <Switch
                name={`features.${index}.readAccess`}
                checked={isReadChecked(feature)}
                onChange={onChangeByEvent}
                label="Lesen"
                disabled={!isReadEnabled(feature)}
            />
            <Switch
                name={`features.${index}.writeAccess`}
                checked={isWriteChecked(feature)}
                onChange={onChangeByEvent}
                label="Schreiben"
                disabled={!isWriteEnabled(feature)}
            />
        </>
    );

    return (
        <Dialog
            title={props.role ? 'Rolle bearbeiten' : 'Neue Rolle erstellen'}
            isOpen={props.isOpen}
            onClose={props.onClose}
            onConfirm={save}
            onChange={setState}
            values={state}
            validators={validators}
        >
            {({ errors, onChangeByEvent }) => (
                <>
                    {props.role && (
                        <EditingAdmin name={`role-${props.role.id}`} />
                    )}
                    <KeyValueTable
                        items={[
                            {
                                key: 'Name',
                                value: (
                                    <TextField
                                        name="name"
                                        value={state.name}
                                        onChange={onChangeByEvent}
                                        error={errors.name}
                                    />
                                ),
                            },
                            ...state.features.map((feature, index) => ({
                                key: props.roleFeatures
                                    .find(({ name }) => name === feature.name)
                                    .label,
                                value: renderFeature(feature, index, onChangeByEvent),
                            })),
                        ]}
                    />
                </>
            )}
        </Dialog>
    );
};

Role.defaultProps = {
    role: null,
};

Role.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    customerId: PropTypes.string.isRequired,
    role: PropTypes.object,
    roleFeatures: PropTypes.array.isRequired,
};

export default Role;
