import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import LocaleContext from 'contexts/locale';
import useTranslate from 'hooks/useTranslate';
import useUpdateProject from 'hooks/graphql/mutations/updateProject';
import { apiResponseToFormState } from 'helpers/form';
import { formFieldTypes, defaultFormFieldType, defaultFormFieldFormState } from 'helpers/formFields';
import {
    hasRequiredTranslation, hasOptionalTranslation,
    emptyTranslation,
    createUpdateTranslationInput,
} from 'helpers/languages';
import EditingAdmin from 'EditingAdmin';
import Button from 'cms/atoms/Button';
import TranslationCodeInput from 'cms/molecules/TranslationCodeInput';
import Dialog from 'cms/molecules/Dialog';
import EditorList from 'cms/molecules/EditorList';
import Grid from 'cms/molecules/Grid';
import KeyValueTable from 'cms/molecules/KeyValueTable';
import LabelList from 'cms/atoms/LabelList';
import Select from 'cms/atoms/Select';
import Switch from 'cms/atoms/Switch';
import TextField from 'cms/atoms/TextField';
import TranslationField from 'cms/molecules/TranslationField';
import FormFieldOptionEditor from 'FormFieldOptionEditor';

const defaultValues = {
    active: false,
    allowPartialSubmission: false,
    name: { _translation: 'text' },
    email: '',
    description: { _translation: 'text' },
    submitInstructions: { _translation: 'text' },
    tasks: {
        _sort: (a, b) => a.order - b.order,
        _map: () => ({
            id: undefined,
            name: { _translation: 'text' },
            description: { _translation: 'text' },
            count: {
                _modify: (count) => count.toString(10),
            },
        }),
    },
    form: {
        id: undefined,
        fields: {
            _sort: (a, b) => a.order - b.order,
            _map: defaultFormFieldFormState,
        },
    },
};

const Challenge = (props) => {
    const t = useTranslate();
    const localeContext = useContext(LocaleContext);
    const updateProject = useUpdateProject();
    const [state, setState] = useState(apiResponseToFormState(props.project.challenge, defaultValues));
    const [formFieldOptionEditorState, setFormFieldOptionEditorState] = useState({
        isOpen: false,
        index: null,
    });

    const validators = [
        {
            name: 'name',
            message: () => 'Bitte gib ebenfalls einen Inhalt für die Standard-Sprache ein.',
            isValid: (value) => hasOptionalTranslation(value, localeContext.defaultLanguage.id),
        },
        {
            name: 'description',
            message: () => 'Bitte gib ebenfalls einen Inhalt für die Standard-Sprache ein.',
            isValid: (value) => hasOptionalTranslation(value, localeContext.defaultLanguage.id),
        },
        {
            name: 'submitInstructions',
            message: () => 'Bitte gib ebenfalls einen Inhalt für die Standard-Sprache ein.',
            isValid: (value) => hasOptionalTranslation(value, localeContext.defaultLanguage.id),
        },
        ...state.tasks.reduce((result, task, index) => [
            ...result,
            {
                name: `tasks.${index}.name`,
                message: () => 'Bitte gib einen Titel für die Standard-Sprache ein.',
                isValid: (value) => hasRequiredTranslation(value, localeContext.defaultLanguage.id),
            },
            {
                name: `tasks.${index}.description`,
                message: () => 'Bitte gib ebenfalls einen Inhalt für die Standard-Sprache ein.',
                isValid: (value) => hasOptionalTranslation(value, localeContext.defaultLanguage.id),
            },
            {
                name: `tasks.${index}.count`,
                message: () => 'Bitte gib eine Anzahl an.',
                isValid: (value) => value > 0,
            },
        ], []),
        ...state.form.fields.reduce((result, field, index) => [
            ...result,
            {
                name: `form.fields.${index}.label`,
                message: () => 'Bitte gib eine Beschriftung für die Standard-Sprache ein.',
                isValid: (value) => hasRequiredTranslation(value, localeContext.defaultLanguage.id),
            },
            {
                name: `form.fields.${index}.description`,
                message: () => 'Bitte gib ebenfalls eine Beschreibung für die Standard-Sprache ein.',
                isValid: (value) => hasOptionalTranslation(value, localeContext.defaultLanguage.id),
            },
        ], []),
    ];

    useEffect(() => {
        if (props.isOpen) {
            setState(apiResponseToFormState(props.project.challenge, defaultValues));
        }
    }, [props.isOpen]);

    const addTask = () => {
        setState((previous) => ({
            ...previous,
            tasks: [
                {
                    name: emptyTranslation,
                    description: emptyTranslation,
                    count: '1',
                },
                ...previous.tasks,
            ],
        }));
    };

    const removeTask = (index) => {
        setState((previous) => ({
            ...previous,
            tasks: [
                ...previous.tasks.slice(0, index),
                ...previous.tasks.slice(index + 1),
            ],
        }));
    };

    const changeTaskOrder = (newIndex, oldIndex) => {
        const tasks = [...state.tasks];
        const movedTask = tasks[oldIndex];

        tasks.splice(oldIndex, 1);
        tasks.splice(newIndex, 0, movedTask);

        setState({ ...state, tasks });
    };

    const addFormField = () => {
        setState((previous) => ({
            ...previous,
            form: {
                id: previous.form.id,
                fields: [
                    ...previous.form.fields,
                    {
                        label: emptyTranslation,
                        mandatory: false,
                        type: defaultFormFieldType,
                        description: emptyTranslation,
                        options: [],
                    },
                ],
            },
        }));
    };

    const removeFormField = (index) => {
        setState((previous) => ({
            ...previous,
            form: {
                id: previous.form.id,
                fields: [
                    ...previous.form.fields.slice(0, index),
                    ...previous.form.fields.slice(index + 1),
                ],
            },
        }));
    };

    const changeFormFieldOrder = (newIndex, oldIndex) => {
        const fields = [...state.form.fields];
        const movedField = fields[oldIndex];

        fields.splice(oldIndex, 1);
        fields.splice(newIndex, 0, movedField);

        setState({ ...state, form: { id: state.form.id, fields } });
    };

    const handleOpenFormFieldOptionEditor = (index) => () => {
        setFormFieldOptionEditorState({ isOpen: true, index });
    };

    const handleCloseFormFieldOptionEditor = () => {
        setFormFieldOptionEditorState({ ...formFieldOptionEditorState, isOpen: false });
    };

    const handleSaveFormFieldOptionEditor = (options) => {
        setState((previous) => {
            const formFields = previous.form.fields;
            return {
                ...previous,
                form: {
                    id: previous.form.id,
                    fields: [
                        ...previous.form.fields.slice(0, formFieldOptionEditorState.index),
                        {
                            ...formFields[formFieldOptionEditorState.index],
                            options,
                        },
                        ...previous.form.fields.slice(formFieldOptionEditorState.index + 1),
                    ],
                },
            };
        });
    };

    const save = async (values) => {
        const formIsEmpty = values.form.fields.length === 0 && values.form.id === undefined;
        await updateProject({
            id: props.project.id,
            challenge: {
                id: props.project.challenge ? props.project.challenge.id : undefined,
                active: values.active,
                allowPartialSubmission: values.allowPartialSubmission,
                name: createUpdateTranslationInput(values.name),
                description: createUpdateTranslationInput(values.description),
                email: values.email.trim(),
                submitInstructions: createUpdateTranslationInput(values.submitInstructions),
                tasks: values.tasks.map((task, index) => ({
                    id: task.id || undefined,
                    name: createUpdateTranslationInput(task.name),
                    description: createUpdateTranslationInput(task.description),
                    count: parseInt(task.count, 10),
                    order: index,
                })),
                form: formIsEmpty ? undefined : {
                    id: values.form.id,
                    fields: values.form.fields.map((field, fieldIndex) => ({
                        id: field.id,
                        label: createUpdateTranslationInput(field.label),
                        description: createUpdateTranslationInput(field.description),
                        mandatory: field.mandatory,
                        type: field.type,
                        options: (field.options || []).map((option, optionIndex) => ({
                            id: option.id,
                            label: createUpdateTranslationInput(option.label),
                            value: option.value.trim(),
                            order: optionIndex,
                        })),
                        order: fieldIndex,
                    })),
                },
            },
        });

        props.onClose();
    };

    const renderTasks = (errors, onChangeByEvent, onChangeByValue) => (
        <EditorList
            addLabel="Aufgabe hinzufügen"
            onAdd={addTask}
            removeLabel="Aufgabe löschen"
            onRemove={removeTask}
            onSort={changeTaskOrder}
            sortModeContents={state.tasks.map((task) => t(task.name) || 'Kein Name')}
        >
            {state.tasks.map((task, index) => (
                <Grid key={index}>
                    <Grid item size={12}>
                        <TranslationField
                            label="Name"
                            name={`tasks.${index}.name`}
                            value={task.name}
                            onChange={onChangeByValue(`tasks.${index}.name`)}
                            error={errors[`tasks.${index}.name`]}
                        />
                    </Grid>

                    <Grid item size={12}>
                        <TranslationField
                            label="Beschreibung"
                            name={`tasks.${index}.description`}
                            value={task.description}
                            onChange={onChangeByValue(`tasks.${index}.description`)}
                            error={errors[`tasks.${index}.description`]}
                        />
                    </Grid>

                    <Grid item size={12}>
                        <TextField
                            label="Anzahl"
                            type="number"
                            min={1}
                            name={`tasks.${index}.count`}
                            value={task.count}
                            onChange={onChangeByEvent}
                            error={errors[`tasks.${index}.count`]}
                        />
                    </Grid>
                </Grid>
            ))}
        </EditorList>
    );

    const renderFormFields = (errors, onChangeByEvent, onChangeByValue) => (
        <>
            <EditorList
                addLabel="Formularfeld hinzufügen"
                onAdd={addFormField}
                removeLabel="Formularfeld löschen"
                onRemove={removeFormField}
                onSort={changeFormFieldOrder}
                sortModeContents={state.form.fields.map((field) => t(field.label) || 'Keine Bezeichnung')}
            >
                {state.form.fields.map((field, index) => (
                    <Grid key={index}>
                        <Grid item size={12}>
                            <TranslationField
                                label="Beschriftung"
                                name={`form.fields.${index}.label`}
                                value={field.label}
                                onChange={onChangeByValue(`form.fields.${index}.label`)}
                                error={errors[`form.fields.${index}.label`]}
                            />
                        </Grid>
                        <Grid item size={12}>
                            <Switch
                                label="Erforderlich"
                                name={`form.fields.${index}.mandatory`}
                                checked={field.mandatory}
                                onChange={onChangeByEvent}
                            />
                        </Grid>
                        <Grid item size={12}>
                            <Select
                                name={`form.fields.${index}.type`}
                                label="Typ"
                                value={field.type}
                                onChange={onChangeByEvent}
                                items={formFieldTypes}
                            />
                        </Grid>

                        {field.type === 'CHECKBOX' && (
                            <Grid item size={12}>
                                <TranslationCodeInput
                                    label="Beschreibung"
                                    value={field.description}
                                    onChange={onChangeByValue(`form.fields.${index}.description`)}
                                    error={errors[`form.fields.${index}.description`]}
                                />
                            </Grid>
                        )}

                        {field.type === 'DROPDOWN' && (
                            <Grid item size={12}>
                                <Button
                                    icon="edit"
                                    onClick={handleOpenFormFieldOptionEditor(index)}
                                >
                                    Werte Bearbeiten
                                </Button>
                                <LabelList
                                    labels={
                                        field.options.map((option) => ({
                                            label: t(option.label),
                                        }))
                                    }
                                />
                            </Grid>
                        )}
                    </Grid>
                ))}
            </EditorList>
            <FormFieldOptionEditor
                isOpen={formFieldOptionEditorState.isOpen}
                onClose={handleCloseFormFieldOptionEditor}
                onSave={handleSaveFormFieldOptionEditor}
                field={
                    state
                        .form
                        ?.fields[formFieldOptionEditorState.index]
                }
            />
        </>
    );

    return (
        <Dialog
            title="Rally bearbeiten"
            isOpen={props.isOpen}
            onClose={props.onClose}
            onConfirm={save}
            onChange={setState}
            values={state}
            validators={validators}
        >
            {({ errors, onChangeByEvent, onChangeByValue }) => (
                <>
                    <EditingAdmin name={`project-challenge-${props.project.id}`} />
                    <KeyValueTable
                        items={[
                            {
                                key: 'Aktiv',
                                value: (
                                    <Switch
                                        name="active"
                                        checked={state.active}
                                        onChange={onChangeByEvent}
                                    />
                                ),
                            },
                            {
                                key: 'Jederzeit abschließbar',
                                help: 'Ob die Rally abgesendet werden darf, bevor alle Aufgaben erfüllt sind.',
                                value: (
                                    <Switch
                                        name="allowPartialSubmission"
                                        checked={state.allowPartialSubmission}
                                        onChange={onChangeByEvent}
                                    />
                                ),
                            },
                            {
                                key: 'Name',
                                value: (
                                    <TranslationField
                                        name="name"
                                        value={state.name}
                                        onChange={onChangeByValue('name')}
                                        error={errors.name}
                                    />
                                ),
                            },
                            {
                                key: 'Anleitung',
                                value: (
                                    <TranslationField
                                        name="description"
                                        value={state.description}
                                        onChange={onChangeByValue('description')}
                                        error={errors.description}
                                    />
                                ),
                            },
                            {
                                key: 'Spielleiter E-Mail-Adresse',
                                value: (
                                    <TextField
                                        name="email"
                                        value={state.email}
                                        onChange={onChangeByEvent}
                                    />
                                ),
                                help: 'Der Spielleiter erhält eine E-Mail für jede abgeschickte Rally. Diese enthält Zeitstempel und E-Mail-Adresse des Teilnehmers.',
                            },
                            {
                                key: 'Anleitung für Email-Eingabe',
                                value: (
                                    <TranslationField
                                        name="submitInstructions"
                                        value={state.submitInstructions}
                                        onChange={onChangeByValue('submitInstructions')}
                                        error={errors.submitInstructions}
                                    />
                                ),
                                help: 'Dieser Text erscheint, wenn der Spieler seine E-Mail-Adresse, beim Absenden der Rally, eingeben muss.',
                            },
                            {
                                key: 'Versandformular',
                                value: renderFormFields(errors, onChangeByEvent, onChangeByValue),
                                align: 'top',
                            },
                            {
                                key: 'Aufgaben',
                                value: renderTasks(errors, onChangeByEvent, onChangeByValue),
                                align: 'top',
                            },
                        ]}
                    />
                </>
            )}
        </Dialog>
    );
};

Challenge.propTypes = {
    project: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default Challenge;
