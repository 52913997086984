import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useNotificationSent from 'hooks/graphql/subscriptions/notificationSent';
import Notification from './Project/Notification';

const NotificationWidget = (props) => {
    const [notificationStack, setNotificationStack] = useState([]);

    const showNotification = (notification) => {
        setNotificationStack([...notificationStack, notification]);
    };

    const closeNotification = (notification) => {
        setNotificationStack(
            notificationStack.filter((stackNotification) => stackNotification.id !== notification.id)
        );
    };

    const buildNotificationView = (notification) => (
        <Notification
            key={notification.id}
            isOpen={!!notification}
            onClose={() => closeNotification(notification)}
            notification={notification}
        />
    );

    const notificationSentSubscription = useNotificationSent(props.projectId || '');

    useEffect(() => {
        const notification = notificationSentSubscription.data?.notificationSent;
        if (notification) {
            showNotification(notification);
        }
    }, [notificationSentSubscription.data]);

    const getNotificationContainerClassNames = (position) => `web-project-notification-container--${position}`;

    const topLeftNotifications = notificationStack
        .filter((notification) => notification.position === 'TOP_LEFT');

    const topRightNotifications = notificationStack
        .filter((notification) => notification.position === 'TOP_RIGHT');

    const bottomLeftNotifications = notificationStack
        .filter((notification) => notification.position === 'BOTTOM_LEFT');

    const bottomRightNotifications = notificationStack
        .filter((notification) => notification.position === 'BOTTOM_RIGHT');

    const centeredNotifications = notificationStack
        .filter((notification) => notification.position === 'CENTERED');

    return (
        <>
            <div className={getNotificationContainerClassNames('top-left')}>
                { topLeftNotifications.map(buildNotificationView) }
            </div>
            <div className={getNotificationContainerClassNames('top-right')}>
                { topRightNotifications.map(buildNotificationView) }
            </div>
            <div className={getNotificationContainerClassNames('bottom-left')}>
                { bottomLeftNotifications.map(buildNotificationView) }
            </div>
            <div className={getNotificationContainerClassNames('bottom-right')}>
                { bottomRightNotifications.map(buildNotificationView) }
            </div>
            { centeredNotifications.map(buildNotificationView) }
        </>
    );
};

NotificationWidget.propTypes = {
    projectId: PropTypes.string.isRequired,
};

export default NotificationWidget;
