import React from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';

const TextField = (props) => (
    <Form.Group className="ui-web-text-field has-fe">
        {props.label && (
            <Form.Label>{props.label}</Form.Label>
        )}

        <Form.Control
            name={props.name}
            type={props.type}
            value={props.value}
            placeholder={props.placeholder}
            onChange={props.onChange}
            onBlur={props.onBlur}
            isInvalid={!!props.error}
            onKeyPress={props.onKeyDown}
            as={props.multiline ? 'textarea' : 'input'}
        />

        {props.error && (
            <Form.Control.Feedback type="invalid">
                {props.error}
            </Form.Control.Feedback>
        )}
    </Form.Group>
);

TextField.defaultProps = {
    type: 'text',
    name: null,
    error: null,
    label: null,
    placeholder: null,
    multiline: false,
    onBlur: null,
    onKeyDown: () => {},
};

TextField.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    onKeyDown: PropTypes.func,
    placeholder: PropTypes.string,
    error: PropTypes.string,
    multiline: PropTypes.bool,
};

export default TextField;
