import React, { useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as THREE from 'three';
import { isIOS } from 'react-device-detect';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import AudioContext from 'contexts/audio';
import AuthContext from 'contexts/auth';
import LocaleContext from 'contexts/locale';
import StylingContext from 'contexts/styling';
import useTranslate from 'hooks/useTranslate';
import useWindowResize from 'hooks/useWindowResize';
import { useLocalStorage } from '@rehooks/local-storage';
import { createAction, changeIconTexture, cancelVideo } from 'helpers/actions';
import { createSceneObject } from 'helpers/sceneObjects';
import { makeBlobUrl } from 'Image';
import Icon from 'Icon';
import { initializeRaycaster } from 'helpers/three';

const Panorama = (props) => {
    const t = useTranslate();
    const renderer = useRef();
    const baseGroup = useRef();
    const camera = useRef();
    const sphereRef = useRef();
    const rayCaster = useRef();
    const scene = useRef();
    const controls = useRef();
    const animationFrameId = useRef();
    const panoramaRef = useRef();
    const audioContext = useContext(AudioContext);
    const authType = useContext(AuthContext);
    const localeContext = useContext(LocaleContext);
    const styling = useContext(StylingContext);
    const [interacted, setInteracted] = useLocalStorage('PanoramaInteracted', false);

    useEffect(() => {
        if (renderer.current || !props.width || !props.height) {
            return;
        }

        (async () => {
            const rendererInstance = setUpRenderer();
            scene.current = new THREE.Scene();
            rayCaster.current = new THREE.Raycaster();
            baseGroup.current = new THREE.Group();
            sphereRef.current = await setUpBackground(scene.current);
            await setUpActions(scene.current);
            const cameraInstance = setUpCamera();
            await setUpSceneObjects();
            setUpControls(rendererInstance, scene.current, cameraInstance);
            scene.current.add(baseGroup.current);
            panoramaRef.current.appendChild(rendererInstance.domElement);
            animate();
        })();
    }, [props.width, props.height]);

    useEffect(() => {
        (async () => {
            if (sphereRef.current) {
                replaceBackground(await makeBlobUrl(props.url, authType));
            }
        })();
    }, [props.url]);

    useEffect(() => {
        if (localeContext.language && baseGroup.current) {
            resetActionsAndObjects();
        }
    }, [localeContext.language]);

    useEffect(() => {
        if (scene.current) {
            const updateAudioActionIcons = async () => (
                props.actions
                    .filter(({ audioData }) => audioData)
                    .map(async (action) => {
                        const object = baseGroup.current.getObjectByName(`action-icon-${action.id}`);
                        const showStopIcon = audioContext.isCurrentAudioFile(action.audioData.file);
                        await changeIconTexture({ action, object, authType, showStopIcon });
                    })
            );
            updateAudioActionIcons();
        }
    }, [audioContext.currentAudioFile]);

    useEffect(() => () => {
        window.cancelAnimationFrame(animationFrameId.current);
        if (renderer.current) {
            renderer.current.forceContextLoss();
            renderer.current.dispose();
            panoramaRef.current?.removeChild(renderer.current.domElement);
        }
        if (baseGroup.current) {
            cancelVideos();
        }
    }, []);

    useWindowResize('panorama', () => {
        window.setTimeout(() => {
            const { offsetWidth, offsetHeight } = panoramaRef.current;
            renderer.current.setSize(offsetWidth, offsetHeight);
            camera.current.aspect = offsetWidth / offsetHeight;
            camera.current.updateProjectionMatrix();
        }, 1);
    });

    const resetActionsAndObjects = async () => {
        cancelVideos();
        baseGroup.current.remove(...baseGroup.current.children);
        await setUpActions(scene.current);
        await setUpSceneObjects();
    };

    const cancelVideos = () => baseGroup.current.children
        .filter((child) => child.video)
        .map((child) => cancelVideo(child.video));

    const setUpRenderer = () => {
        const instance = new THREE.WebGLRenderer({ alpha: true, antialias: true });

        instance.setSize(props.width, props.height);
        instance.setClearColor(0x000000, 0);

        // Setting pixel ratio on iOS devices crashes the browser tab after ~8 scene changes.
        // Setting antialias to false still crashes after ~20 scene changes.
        // See https://stackoverflow.com/questions/41084172/three-js-setpixelratio-crash-on-ios-browser
        if (!isIOS) {
            instance.setPixelRatio(window.devicePixelRatio);
        }

        renderer.current = instance;
        return instance;
    };

    const setUpCamera = () => {
        const aspect = props.width / props.height;
        const instance = new THREE.PerspectiveCamera(75, aspect, 0.1, 1000);
        instance.position.set(1, 0, 0);
        camera.current = instance;
        return instance;
    };

    const setUpBackground = async (sceneInstance) => {
        const geometry = new THREE.SphereBufferGeometry(500, 60, 40);
        geometry.scale(-1, 1, 1); // Invert the geometry, so the faces point inward.
        const texture = new THREE.TextureLoader().load(await makeBlobUrl(props.url, authType));
        texture.minFilter = THREE.LinearFilter;
        const material = new THREE.MeshBasicMaterial({ map: texture });
        material.needsUpdate = true;
        const sphere = new THREE.Mesh(geometry, material);
        sceneInstance.add(sphere);
        return sphere;
    };

    const setUpControls = (rendererInstance, sceneInstance, cameraInstance) => {
        const instance = new OrbitControls(cameraInstance, rendererInstance.domElement);
        instance.rotateSpeed = -0.2;
        instance.enableZoom = false;
        instance.autoRotate = false;

        if (props.cameraOrientation) {
            instance.object.position.set(
                props.cameraOrientation.x,
                props.cameraOrientation.y,
                props.cameraOrientation.z,
            );
            instance.object.lookAt(0, 0, 0);
            instance.update();
        }

        controls.current = instance;
    };

    const animate = () => {
        renderer.current.render(scene.current, camera.current);
        animationFrameId.current = window.requestAnimationFrame(animate);
    };

    const setUpAction = async (action) => baseGroup.current.add(
        await createAction({
            action,
            buttons: [action.button],
            authType,
            t,
            styling,
            showStopIcon: audioContext.isCurrentAudioFile(action.audioData?.file),
        })
    );

    const setUpActions = async () => props.actions.map(setUpAction);

    const setUpSceneObject = async (sceneObject) => baseGroup.current.add(
        await createSceneObject({
            sceneObject,
            translateFile: t,
            authType,
            showVideoPlayer: false,
        })
    );

    const setUpSceneObjects = async () => (
        props.objects.forEach(setUpSceneObject)
    );

    const replaceBackground = (url) => (
        new THREE.TextureLoader().load(url, (texture) => {
            renderer.current.initTexture(texture);
            texture.minFilter = THREE.LinearFilter;
            sphereRef.current.material.map = texture;
        })
    );

    const onMouseDown = () => setInteracted(true);

    const onClick = (event) => {
        setInteracted(true); // also set for touch events, not only onMouseDown

        initializeRaycaster(event, panoramaRef.current, rayCaster.current, camera.current);
        const intersectedObjects = rayCaster
            .current
            .intersectObjects(baseGroup.current.children, true)
            .filter((intersect) => intersect.object.name
                && intersect.object.type !== 'Line'
                && intersect.object.name !== 'world-sphere');

        intersectedObjects.forEach((intersect) => {
            const action = props.actions.find(({ id }) => intersect.object.name.endsWith(id));

            if (!action) {
                return;
            }

            if (action.sceneData || action.exhibitorData) {
                const lookVector = (new THREE.Vector3())
                    .subVectors(controls.current.object.position, intersect.object.position)
                    .normalize();
                props.setCameraOrientation(lookVector);
            }
            props.handleActionClick(action, event);
        });
    };

    const renderPanInfo = () => (
        <div
            className={classNames(
                'web-background-panorama-info',
                { 'web-background-panorama-info--hidden': interacted },
            )}
        >
            <Icon type="pan" />
            <span className="web-background-panorama-info__text">{t('web.panorama.panInfo')}</span>
        </div>
    );

    return (
        <>
            <div
                className="web-background-panorama"
                ref={panoramaRef}
                onMouseDown={onMouseDown}
                onClick={onClick}
                onTouchStart={onClick}
            />
            {renderPanInfo()}
        </>
    );
};

Panorama.defaultProps = {
    cameraOrientation: null,
    height: null,
    width: null,
};

Panorama.propTypes = {
    url: PropTypes.string.isRequired,
    width: PropTypes.number,
    height: PropTypes.number,
    actions: PropTypes.array.isRequired,
    objects: PropTypes.array.isRequired,
    setCameraOrientation: PropTypes.func.isRequired,
    cameraOrientation: PropTypes.object,
    onChallengeItemCompleted: PropTypes.func.isRequired,
    handleActionClick: PropTypes.func.isRequired,
};

export default Panorama;
