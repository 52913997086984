import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import useTranslate from 'hooks/useTranslate';
import Icon from 'Icon';

const TaskList = (props) => {
    const t = useTranslate();

    const renderTask = ({ id, name, count, available }) => {
        const showWarning = props.showCount && available < count;
        return (
            <div
                key={id}
                className={classNames(
                    'ui-cms-task-list-task',
                    { 'ui-cms-task-list-task--warning': showWarning }
                )}
            >
                {t(name)}
                {props.showCount && ` (${available} von ${count} in der Messe verwendet)`}
                {showWarning && renderWarningIcon()}
            </div>
        );
    };

    const renderWarningIcon = () => (
        <div className="ui-cms-task-list-task__icon">
            <Icon type="warning" />
        </div>
    );

    return (
        <div className="ui-cms-task-list">
            {props.tasks.map((task) => renderTask(task))}
        </div>
    );
};

TaskList.defaultProps = {
    showCount: false,
};

TaskList.propTypes = {
    tasks: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.object.isRequired,
        count: PropTypes.number,
        available: PropTypes.number,
    })).isRequired,
    showCount: PropTypes.bool,
};

export default TaskList;
