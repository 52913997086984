import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import Card from 'react-bootstrap/Card';
import PropTypes from 'prop-types';
import StylingContext from 'contexts/styling';
import Icon from 'Icon';
import Image from 'Image';

const Header = (props) => {
    const history = useHistory();
    const styling = useContext(StylingContext);

    const onBackButtonClickHandler = () => history.goBack();

    return (
        <Card
            style={{
                backgroundColor: styling.backgroundColor,
                borderColor: styling.textColor,
                color: styling.textColor,
            }}
        >
            <Card.Header className="mobile-scene-accordion-header">
                <div
                    onClick={props.toggleNavbarState}
                    className="mobile-scene-accordion-header__menu-button"
                >
                    {props.showMenuButton && <Icon type="menu" />}
                </div>

                {props.showBackButton && (
                    <div
                        className="mobile-scene-accordion-header__icon"
                        onClick={onBackButtonClickHandler}
                    >
                        <Image url="/images/back.png" />
                    </div>
                )}
            </Card.Header>
        </Card>
    );
};

Header.propTypes = {
    showBackButton: PropTypes.bool.isRequired,
    toggleNavbarState: PropTypes.func.isRequired,
    showMenuButton: PropTypes.bool.isRequired,
};

export default Header;
