import moment from 'moment';

/**
 * Requirement by customer to reset presence tracking confirmations every day.
 * This is a momentjs time unit.
 */
const PRESENCE_TRACKING_RESET_TIME_UNIT = 'day';

const findTrackingChoice = (sceneId, now, trackingState) => {
    const trackingChoice = trackingState[sceneId];
    if (trackingChoice && moment(trackingChoice.timestamp).isSame(now, PRESENCE_TRACKING_RESET_TIME_UNIT)) {
        return trackingChoice;
    }
    return null;
};

export const evaluateTrackingState = (now, project, scene, presenceTrackingState) => {
    const trackingEnabled = project.presenceTrackingEnabled && scene.presenceTrackingEnabled;
    const showTextField = scene.presenceTrackingTextFieldMode !== 'DISABLED';
    const showCheckBox = scene.presenceTrackingCheckboxMode !== 'DISABLED';
    const trackingChoiceRequired = showTextField || showCheckBox;
    const trackingChoice = findTrackingChoice(scene.id, now, presenceTrackingState);

    const isTrackingDialogOpen = trackingEnabled && trackingChoiceRequired && !trackingChoice;
    const isTrackingAllowed = trackingEnabled && (!trackingChoiceRequired || trackingChoice);

    return {
        trackingText: trackingChoice?.trackingText,
        trackingCheckbox: trackingChoice?.trackingCheckbox,
        isTrackingAllowed,
        isTrackingDialogOpen,
    };
};

export const presenceTrackingIntervals = [
    { label: '1 Minute', value: 60000 },
    { label: '5 Minuten', value: 300000 },
    { label: '15 Minuten', value: 900000 },
    { label: '30 Minuten', value: 1800000 },
    { label: '1 Stunde', value: 3600000 },
];

export const defaultPresenceTrackingInterval = presenceTrackingIntervals[0].value;

export const trackingFieldModes = [
    { label: 'Aus', value: 'DISABLED' },
    { label: 'Optional', value: 'OPTIONAL' },
    { label: 'Erforderlich', value: 'REQUIRED' },
];

export const presenceTrackingIntervalMap = presenceTrackingIntervals
    .reduce((curr, next) => ({
        ...curr,
        [next.value]: next.label,
    }), {});
