import React, { useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import LocaleContext from 'contexts/locale';
import { generateCsv, saveFile } from 'helpers/csvExport';
import { hasValue, formatValue } from 'helpers/registrationData';
import useToggle from 'hooks/useToggle';
import useProject from 'hooks/graphql/queries/project';
import useDeleteVisitors from 'hooks/graphql/mutations/deleteVisitors';
import MeContext from 'contexts/me';
import Button from 'cms/atoms/Button';
import Headline from 'cms/atoms/Headline';
import Datagrid from 'cms/atoms/Datagrid';
import Confirm from 'components/ui/cms/molecules/Confirm';
import VisitorEditor from '../../editors/Project/Login/VisitorWithAccount';
import VisitorRegistrationData from '../../editors/Project/Login/VisitorRegistrationData';
import CSVImportEditor from '../../editors/Project/Login/CSVImport';

const VisitorsWithAccount = () => {
    const localeContext = useContext(LocaleContext);
    const params = useParams();

    const { data, loading } = useProject(params.id);
    const { project } = !loading && data;
    const deleteVisitors = useDeleteVisitors(project);

    const [visitorEditorState, setVisitorEditorState] = useState({
        isOpen: false,
        id: null,
    });
    const [visitorDataDialogState, setVisitorDataDialogState] = useState({
        isOpen: false,
        id: null,
    });
    const [deleteVisitorDialogState, setDeleteVisitorDialogState] = useState({
        isOpen: false,
        selectedVisitorIds: [],
    });
    const [isImportEditorOpen, openImportEditor, closeImportEditor] = useToggle(false);
    const me = useContext(MeContext);

    const openVisitorDataDialogHandler = (id) => () => {
        setVisitorDataDialogState({ isOpen: true, id });
    };

    const openVisitorEditorHandler = (id) => () => {
        setVisitorEditorState({ isOpen: true, id });
    };

    const closeVisitorEditor = () => {
        setVisitorEditorState({ ...visitorEditorState, isOpen: false });
    };

    const closeVisitorDataDialog = () => {
        setVisitorDataDialogState({ ...visitorDataDialogState, isOpen: false });
    };

    const openDeleteVisitorDialogHandler = (selectedVisitorIds) => () => {
        setDeleteVisitorDialogState({ isOpen: true, selectedVisitorIds });
    };

    const closeDeleteVisitorDialog = () => {
        setDeleteVisitorDialogState({ ...deleteVisitorDialogState, isOpen: false });
    };

    const handleDeleteVisitor = async () => {
        await deleteVisitors(deleteVisitorDialogState.selectedVisitorIds);
        closeDeleteVisitorDialog();
    };

    const translateInDefaultLanguage = (translation) => (
        translation?.entries
            .find((entry) => entry.languageId === localeContext.defaultLanguage.id)
            ?.text
    );

    const generateExportColumns = (fields) => {
        const fieldColumns = fields.map(({ id, label }) => ({
            key: id,
            header: translateInDefaultLanguage(label) || '',
        }));
        const emailColumn = { key: 'email', header: 'Email' };
        return [emailColumn, ...fieldColumns];
    };

    const generateExportRow = (visitor) => (
        visitor.registrationData.reduce(
            (row, dataItem) => {
                row[dataItem.field.id] = formatValue(dataItem);
                return row;
            },
            { email: visitor.account ? visitor.account.email : 'Keine' }
        )
    );

    const exportVisitors = async () => {
        const columns = generateExportColumns(project.registrationFields);
        const rows = project.visitors.map(generateExportRow);
        const output = await generateCsv(rows, columns);
        saveFile(output, `${project.path}_accounts.csv`);
    };

    return !loading && (
        <>
            <Headline
                action={(
                    <>
                        {me.hasWriteAccessToFeature('login.accounts') && (
                            <Button
                                icon="upload"
                                onClick={openImportEditor}
                            >
                                CSV-Import
                            </Button>
                        )}

                        {me.hasReadAccessToFeature('login.accounts') && (
                            <Button
                                icon="download"
                                onClick={exportVisitors}
                            >
                                CSV-Export
                            </Button>
                        )}

                        {me.hasWriteAccessToFeature('login.accounts') && (
                            <Button
                                icon="add"
                                onClick={openVisitorEditorHandler(null)}
                            >
                                Neuer Account
                            </Button>
                        )}
                    </>
                )}
            >
                Besucher-Accounts
            </Headline>

            <CSVImportEditor
                isOpen={isImportEditorOpen}
                onClose={closeImportEditor}
                project={project}
            />

            <VisitorEditor
                isOpen={visitorEditorState.isOpen}
                onClose={closeVisitorEditor}
                project={project}
                visitor={
                    project.visitors.find(({ id }) => id === visitorEditorState.id)
                }
            />

            <VisitorRegistrationData
                isOpen={visitorDataDialogState.isOpen}
                onClose={closeVisitorDataDialog}
                project={project}
                visitor={
                    project.visitors.find(({ id }) => id === visitorDataDialogState.id)
                }
            />

            <Confirm
                title="Soll die Auswahl wirklich gelöscht werden?"
                onConfirm={handleDeleteVisitor}
                confirmLabel="Ja, löschen"
                onCancel={closeDeleteVisitorDialog}
                cancelLabel="Nein, abbrechen"
                isOpen={deleteVisitorDialogState.isOpen}
                destructive
            />

            <Datagrid
                checkboxSelection={me.hasWriteAccessToFeature('login.accounts')}
                pageSize={25}
                onDelete={me.hasWriteAccessToFeature('login.accounts') && openDeleteVisitorDialogHandler}
                onEdit={me.hasWriteAccessToFeature('login.accounts') && openVisitorEditorHandler}
                columns={[
                    { field: 'email', headerName: 'E-Mail-Adresse', width: 200 },
                    { field: 'name', headerName: 'Name', width: 200 },
                    { field: 'privileges', headerName: 'Privilegien', width: 200 },
                    {
                        field: 'data',
                        headerName: 'Registrierungsdaten',
                        width: 210,
                        renderCell: (cellData) => {
                            const visitor = cellData.row;
                            return visitor.data.filter(hasValue).length !== 0 && (
                                <Button
                                    icon="data"
                                    onClick={openVisitorDataDialogHandler(cellData.id)}
                                />
                            );
                        },
                    },
                ]}
                rows={
                    project.visitors
                        .filter(({ account }) => !!account)
                        .sort((a, b) => a.account.email.localeCompare(b.account.email))
                        .map((visitor) => ({
                            id: visitor.id,
                            email: visitor.account.email,
                            name: visitor.name,
                            privileges: visitor.privileges.map(({ name }) => name).join(', '),
                            data: visitor.registrationData,
                        }))
                }
            />
        </>
    );
};

export default VisitorsWithAccount;
