import * as THREE from 'three';
import { calculateLines, roundedRectangle } from 'helpers/canvas';
import { makeBlobUrl } from 'Image';

export const addPlaneByRaycaster = (rayCaster, camera, scene, distance) => {
    const geometry = new THREE.PlaneGeometry(50, 50, 32);
    const material2 = new THREE.MeshBasicMaterial({
        color: 0xffff00,
        side: THREE.DoubleSide,
    });
    const plane = new THREE.Mesh(geometry, material2);

    plane.name = `plane-${Date.now()}`;
    const origin = rayCaster.ray.origin.clone();
    const direction = rayCaster.ray.direction.clone();
    const position = origin.add(direction.multiplyScalar(distance));
    plane.position.set(position.x, position.y, position.z);
    plane.lookAt(camera.getWorldPosition());

    scene.add(plane);
};

export const getTransformInFrontOfCamera = (camera, distance) => {
    const object = new THREE.Object3D();
    object.quaternion.copy(camera.quaternion);
    const cwd = new THREE.Vector3();
    camera.getWorldDirection(cwd);
    cwd.multiplyScalar(distance);
    cwd.add(camera.position);
    object.position.set(cwd.x, cwd.y, cwd.z);
    const { position, rotation, scale } = object;
    return {
        position: {
            x: position.x,
            y: position.y,
            z: position.z,
        },
        rotation: {
            x: rotation.x,
            y: rotation.y,
            z: rotation.z,
        },
        scale: {
            x: scale.x,
            y: scale.y,
            z: scale.z,
        },
    };
};

export const initializeRaycaster = (event, panorama, rayCaster, camera) => {
    const x = event.clientX || event.touches[0].clientX;
    const y = event.clientY || event.touches[0].clientY;
    const { left, top, width, height } = panorama.getBoundingClientRect();
    const mouse = new THREE.Vector2(
        ((x - left) / width) * 2 - 1,
        -((y - top) / height) * 2 + 1
    );

    rayCaster.setFromCamera(mouse, camera);
};

export const createPlane = (material, sceneObject) => {
    const geometry = new THREE.PlaneGeometry(50, 50, 32);
    const plane = new THREE.Mesh(geometry, material);
    plane.material.side = THREE.DoubleSide;
    plane.rayCaster = true;
    plane.name = `scene-object-${sceneObject.id}`;

    const group = new THREE.Group();
    group.uuid = sceneObject.id;
    group.entityType = 'objects';
    group.name = `scene-object-group-${sceneObject.id}`;
    setTransforms(group, sceneObject);
    group.add(plane);
    return group;
};

export const createActionIcon = async (action, iconUrl, authType, t, styling) => {
    const texture = new THREE.TextureLoader().load(
        await makeBlobUrl(iconUrl, authType)
    );
    const material = new THREE.SpriteMaterial({
        map: texture,
        transparent: true,
        alphaTest: 0.5,
    });
    const sprite = new THREE.Sprite(material);
    sprite.rayCaster = true;
    sprite.name = `action-icon-${action.id}`;
    sprite.scale.set(50, 50, 50);

    const group = new THREE.Group();
    group.uuid = action.id;
    group.name = `action-group-${action.id}`;
    group.entityType = 'actions';
    group.add(sprite);
    setTransforms(group, action);
    if (action.withLabel) {
        const label = await createActionLabel(action, t, styling);
        group.add(label);
    }

    return group;
};

const createActionLabel = async (action, t, styling) => {
    const maxWidth = 500;
    const fontSize = 40;
    const fontFamily = styling.font ? 'Project_Font' : 'Arial';
    const lineHeight = fontSize + 10;
    const padding = lineHeight / 2;
    const font = `Bold ${fontSize}px ${fontFamily}`;

    // create canvas and determine height/width depending on text length
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    canvas.width = maxWidth;
    context.font = font;
    context.textAlign = 'center';
    context.textBaseline = 'top';
    const lines = calculateLines(t(action.name), context, maxWidth - padding * 2);
    const maxLineWidth = Math.max(...lines.map((line) => context.measureText(line).width));
    canvas.width = maxLineWidth + padding * 2;
    canvas.height = lines.length * lineHeight + padding * 1.5;

    // draw background
    context.fillStyle = styling.backgroundColor;
    context.beginPath();
    roundedRectangle(context, 0, 0, canvas.width, canvas.height, padding);
    context.fill();

    // draw lines
    context.fillStyle = styling.textColor;
    // text styling needs to be set again after changing canvas size:
    // https://stackoverflow.com/a/13482879
    context.font = font;
    context.textAlign = 'center';
    context.textBaseline = 'top';
    let y = 0;
    for (const line of lines) {
        context.fillText(line, canvas.width / 2, padding + y);
        y += lineHeight;
    }

    // create material
    const texture = new THREE.CanvasTexture(canvas);
    const material = new THREE.SpriteMaterial({
        map: texture,
        transparent: true,
        alphaTest: 0.2,
    });

    // create sprite and determine scale depending on canvas size
    const sprite = new THREE.Sprite(material);
    const spriteWidth = 20 * (canvas.width / maxWidth);
    const spriteHeight = spriteWidth / (canvas.width / canvas.height);
    const labelOffset = 15 + spriteHeight * 10;
    sprite.position.set(0, -labelOffset, 0);
    sprite.scale.set(spriteWidth * 10, spriteHeight * 10, 1);
    sprite.rayCaster = true;
    sprite.name = `action-label-${action.id}`;
    return sprite;
};

export const changeObjectTexture = async (object, url, authType) => {
    object.material.map = new THREE.TextureLoader().load(
        await makeBlobUrl(url, authType)
    );
    object.material.needsUpdate = true;
};

export const setTransforms = (object3D, entity) => {
    object3D.position.set(entity.position.x, entity.position.y, entity.position.z);

    if (entity.rotation) {
        object3D.rotation.set(entity.rotation.x, entity.rotation.y, entity.rotation.z);
    }

    if (entity.scale) {
        object3D.scale.set(entity.scale.x, entity.scale.y, entity.scale.z);
    }
};
