import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import Button from 'cms/atoms/Button';

const ErrorScreen = (props) => {
    const history = useHistory();
    const location = useLocation();
    const selectAll = (event) => {
        const range = document.createRange();
        const selection = window.getSelection();

        range.selectNode(event.target);
        selection.removeAllRanges();
        selection.addRange(range);
    };

    const goBack = () => {
        const { pathname } = location;
        let target = '/';

        if (pathname.startsWith('/event')) {
            [target] = pathname.match(/\/event\/[a-zäöüß0-9-]+/);
        } else if (pathname.startsWith('/cms')) {
            target = '/cms';
        }

        history.replace(target);
        window.location.reload();
    };

    return (
        <div className="global-error">
            <div className="global-error__info">
                {props.info || (
                    'Leider ist ein Fehler aufgetreten. Bitte kopiere die Fehlermeldung und schicke sie uns zu, damit wir den Fehler schnellstmöglich beheben können. Vielen Dank!'
                )}
            </div>

            {props.message && (
                <pre
                    className="global-error__stack-trace"
                    onClick={selectAll}
                >
                    Path: {location.pathname}<br />
                    Message: &quot;{props.message}&quot;<br />
                    {props.stackTrace && (
                        `Stack trace: ${props.stackTrace}`
                    )}
                </pre>
            )}

            <div className="global-error__button">
                <Button onClick={goBack}>
                    Zurück zur App
                </Button>
            </div>
        </div>
    );
};

ErrorScreen.defaultProps = {
    info: null,
    message: null,
    stackTrace: null,
};

ErrorScreen.propTypes = {
    info: PropTypes.string,
    message: PropTypes.string,
    stackTrace: PropTypes.string,
};

export default ErrorScreen;
