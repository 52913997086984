import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import MeContext from 'contexts/me';
import mediaUrl from 'helpers/mediaUrl';
import { apiResponseToFormState } from 'helpers/form';
import filterPath from 'helpers/filterPath';
import { buildQuotaError } from 'helpers/scenes';
import { createUpdateTranslationInput } from 'helpers/languages';
import { isFormEmpty } from 'helpers/formFields';
import useTranslate from 'hooks/useTranslate';
import useCreateScene from 'hooks/graphql/mutations/createScene';
import useUpdateScene from 'hooks/graphql/mutations/updateScene';
import EditingAdmin from 'EditingAdmin';
import TextField from 'cms/atoms/TextField';
import ImageUpload from 'cms/atoms/ImageUpload';
import KeyValueTable from 'cms/molecules/KeyValueTable';
import Dialog from 'cms/molecules/Dialog';
import { defaultActionFormState } from 'helpers/actions';
import { defaultSceneObjectFormState, sceneObjectToSaveableJson } from 'helpers/sceneObjects';
import Select from 'cms/atoms/Select';
import Panorama from './Scene/Panorama';

const defaultValues = {
    id: undefined,
    name: '',
    path: '',
    audioBackgroundId: '',
    actions: {
        _map: defaultActionFormState,
    },
    objects: {
        _map: defaultSceneObjectFormState,
    },
    background: {
        _default: {},
        _modify: () => ({
            id: undefined,
            url: '',
        }),
    },
};

/**
 * This is the editor for panorama child scenes.
 * Child scenes reuse most of the parent scenes properties, so this editor has reduced scope.
 * For regular panorama scenes, the normal scene editor is used.
 */
const ScenePanorama = (props) => {
    const sceneWithAudioBackground = {
        ...props.scene,
        // use parent audio background when creating new panorama child scene
        audioBackgroundId: props.scene ? props.scene.audioBackgroundId : props.parentSceneAudioBackgroundId,
    };
    const [state, setState] = useState(
        apiResponseToFormState(sceneWithAudioBackground, defaultValues)
    );
    const me = useContext(MeContext);
    const t = useTranslate();

    const createScene = useCreateScene();
    const updateScene = useUpdateScene();

    const validators = [{
        name: 'name',
        message: () => 'Bitte gib einen Namen ein.',
        isValid: (value) => !!value.trim(),
    }, {
        name: 'path',
        message: () => 'Es gibt in diesem Projekt bereits eine Szene mit diesem Pfad.',
        serverError: 'SCENE_HAS_DUPLICATE_PATH',
    }, {
        name: 'path',
        message: () => 'Bitte gib einen Pfad ein.',
        isValid: (value) => !!filterPath(value).trim(),
    }, {
        name: 'background',
        message: () => 'Bitte lade ein Hintergrundbild hoch.',
        isValid: (value) => !!value.url,
    }, {
        name: 'background',
        message: (_, error) => buildQuotaError(t, error.subjects),
        serverError: 'QUOTA_EXCEEDED',
    }];

    useEffect(() => {
        if (props.isOpen) {
            setState(
                apiResponseToFormState(sceneWithAudioBackground, defaultValues)
            );
        }
    }, [props.isOpen]);

    const generatePath = () => {
        if (!state.path.trim() && me.hasWriteAccessToFeature('scene.path')) {
            setState({
                ...state,
                path: filterPath(state.name),
            });
        }
    };

    const changeBackground = (file) => {
        setState((previous) => ({
            ...previous,
            background: {
                ...previous.background,
                ...file,
            },
        }));
    };

    const changeBackgroundItems = (type, index, entity) => {
        setState((previous) => ({
            ...previous,
            [type]: index === null ? [
                ...previous[type],
                entity,
            ] : [
                ...previous[type].slice(0, index),
                {
                    ...previous[type][index],
                    ...entity,
                },
                ...previous[type].slice(index + 1),
            ],
        }));
    };

    const deleteBackgroundItem = (type, index) => {
        setState((previous) => ({
            ...previous,
            [type]: [
                ...previous[type].slice(0, index),
                ...previous[type].slice(index + 1),
            ],
        }));
    };

    const createUpdateFormFieldInput = (field, fieldIndex) => ({
        id: field.id || undefined,
        label: createUpdateTranslationInput(field.label),
        description: createUpdateTranslationInput(field.description),
        mandatory: field.mandatory,
        type: field.type,
        options: (field.options || []).map((option, optionIndex) => ({
            id: option.id || undefined,
            label: createUpdateTranslationInput(option.label),
            value: option.value.trim(),
            order: optionIndex,
        })),
        order: fieldIndex,
    });

    const save = async (values) => {
        const mutation = props.scene ? updateScene : createScene;

        const input = {
            name: values.name.trim(),
            type: props.type,
            path: values.path.trim(),
            panorama: true,
            backgroundMediaId: values.background.id,
            id: props.scene ? props.scene.id : undefined,
            projectId: !props.scene ? props.projectId : undefined,
            parentSceneId: !props.scene ? props.parentSceneId : undefined,
            audioBackgroundId: values.audioBackgroundId || null,
            actions: values.actions.map((action) => ({
                id: action.id || undefined,
                name: createUpdateTranslationInput(action.name),
                active: action.active,
                x: action.x,
                y: action.y,
                position: action.position ? {
                    x: action.position.x,
                    y: action.position.y,
                    z: action.position.z,
                    id: action.position.id || undefined,
                } : undefined,
                rotation: action.rotation ? {
                    x: action.rotation.x,
                    y: action.rotation.y,
                    z: action.rotation.z,
                    id: action.rotation.id || undefined,
                } : undefined,
                scale: action.scale ? {
                    x: action.scale.x,
                    y: action.scale.y,
                    z: action.scale.z,
                    id: action.scale.id || undefined,
                } : undefined,
                withLabel: action.withLabel,
                buttonId: action.buttonId,
                restrictions: action.restrictions.map((id) => ({ id })),
                tasks: action.tasks.map((id) => ({ id })),
                sceneData: action.type === 'SCENE' ? {
                    id: action.sceneData.id || undefined,
                    sceneId: action.sceneData.sceneId,
                } : null,
                exhibitorData: action.type === 'EXHIBITOR' ? {
                    id: action.exhibitorData.id || undefined,
                    sceneId: action.exhibitorData.sceneId,
                } : null,
                infoboxData: action.type === 'INFOBOX' ? {
                    id: action.infoboxData.id || undefined,
                    title: createUpdateTranslationInput(action.infoboxData.title),
                    content: createUpdateTranslationInput(action.infoboxData.content),
                } : null,
                contactData: action.type === 'CONTACT' ? {
                    id: action.contactData.id || undefined,
                    title: createUpdateTranslationInput(action.contactData.title),
                    sections: action.contactData.sections.map((section, order) => ({
                        id: section.id || undefined,
                        image: createUpdateTranslationInput(section.image),
                        entries: section.entries.map((entry, entryOrder) => ({
                            id: entry.id || undefined,
                            type: entry.type,
                            value: createUpdateTranslationInput(entry.value),
                            order: entryOrder,
                        })),
                        order,
                    })),
                } : null,
                galleryData: action.type === 'GALLERY' ? {
                    id: action.galleryData.id || undefined,
                    title: createUpdateTranslationInput(action.galleryData.title),
                    images: action.galleryData.images.map((image, order) => ({
                        id: image.id || undefined,
                        file: createUpdateTranslationInput(image.file),
                        order,
                        description: createUpdateTranslationInput(image.description),
                    })),
                } : null,
                downloadData: action.type === 'DOWNLOAD' ? {
                    id: action.downloadData.id || undefined,
                    title: createUpdateTranslationInput(action.downloadData.title),
                    downloads: action.downloadData.downloads.map((download, order) => ({
                        id: download.id || undefined,
                        file: createUpdateTranslationInput(download.file),
                        thumbnailMediaId: download.thumbnailMediaId || undefined,
                        order,
                        label: createUpdateTranslationInput(download.label),
                        description: createUpdateTranslationInput(download.description),
                    })),
                } : null,
                videoData: action.type === 'VIDEO' ? {
                    id: action.videoData.id || undefined,
                    title: createUpdateTranslationInput(action.videoData.title),
                    items: action.videoData.items.map((item, order) => ({
                        id: item.id,
                        order,
                        label: createUpdateTranslationInput(item.label),
                        url: createUpdateTranslationInput(item.url),
                    })),
                } : null,
                audioData: action.type === 'AUDIO' ? {
                    id: action.audioData.id || undefined,
                    file: createUpdateTranslationInput(action.audioData.file),
                    stopiconMediaId: action.audioData.stopIconMediaId,
                } : null,
                externalData: action.type === 'EXTERNAL' ? {
                    id: action.externalData.id || undefined,
                    url: createUpdateTranslationInput(action.externalData.url),
                    openInNewTab: action.externalData.openInNewTab,
                } : null,
                loginData: action.type === 'LOGIN' ? {
                    id: action.loginData.id || undefined,
                    hideWhenLoggedIn: action.loginData.hideWhenLoggedIn,
                } : null,
                callingCardData: action.type === 'CALLING_CARD' ? {
                    id: action.callingCardData.id || undefined,
                    contactEmail: action.callingCardData.contactEmail,
                    contactSubject: action.callingCardData.contactSubject,
                    contactInstructions: createUpdateTranslationInput(action.callingCardData.contactInstructions),
                    contactTitle: createUpdateTranslationInput(action.callingCardData.contactTitle),
                    form: isFormEmpty(action.callingCardData.form) ? null : {
                        id: action.callingCardData.form.id || undefined,
                        fields: action.callingCardData.form.fields.map(createUpdateFormFieldInput),
                    },
                } : null,
            })),
            objects: values.objects.map(sceneObjectToSaveableJson),
        };

        await mutation(input);

        props.onClose();
    };

    return (
        <Dialog
            title={props.scene ? 'Panorama-Unterszene bearbeiten' : 'Neue Panorama-Unterszene erstellen'}
            isOpen={props.isOpen}
            onClose={props.onClose}
            onConfirm={save}
            onChange={setState}
            values={state}
            validators={validators}
            minWidth={80}
            preventFirstTextFieldFocus={!!props.scene}
        >
            {({ errors, onChangeByEvent }) => (
                <>
                    {props.scene && (
                        <EditingAdmin name={`scene-panorama-${props.scene.id}`} />
                    )}

                    <KeyValueTable
                        items={[
                            {
                                key: 'Name',
                                value: (
                                    <TextField
                                        name="name"
                                        value={state.name}
                                        onChange={onChangeByEvent}
                                        onBlur={generatePath}
                                        error={errors.name}
                                    />
                                ),
                                disabled: !me.hasWriteAccessToFeature('scene.name'),
                            },
                            {
                                key: 'Pfad in der URL',
                                value: (
                                    <TextField
                                        name="path"
                                        value={state.path}
                                        onChange={onChangeByEvent}
                                        filter={filterPath}
                                        error={errors.path}
                                    />
                                ),
                                disabled: !me.hasWriteAccessToFeature('scene.path'),
                            },
                            {
                                key: 'Audiokulisse',
                                value: props.project.audioBackgrounds ? (
                                    <Select
                                        name="audioBackgroundId"
                                        value={state.audioBackgroundId || ''}
                                        onChange={onChangeByEvent}
                                        items={
                                            [
                                                {
                                                    label: 'Ohne',
                                                    value: '',
                                                },
                                                ...props.project.audioBackgrounds
                                                    .map((audioBackground) => ({
                                                        label: audioBackground.media.filename,
                                                        value: audioBackground.id,
                                                    })),
                                            ]
                                        }
                                    />
                                ) : null,
                                available: props.project.audioBackgrounds.length > 0,
                                disabled: !me.hasWriteAccessToFeature('scene.audioBackground'),
                            },
                            {
                                key: 'Hintergrundbild',
                                value: (
                                    <ImageUpload
                                        onUpload={changeBackground}
                                        url={mediaUrl(state.background, 'mini')}
                                        error={errors.background}
                                        imageDecorator={() => (
                                            <Panorama
                                                background={state.background}
                                                actions={state.actions}
                                                objects={state.objects}
                                                buttons={props.buttons}
                                                projectButtons={props.projectButtons}
                                                scenes={props.scenes}
                                                challenge={props.challenge}
                                                onSave={changeBackgroundItems}
                                                onDelete={deleteBackgroundItem}
                                                projectFiles={props.projectFiles}
                                                sceneFiles={props.sceneFiles}
                                                isPanoramaChildScene
                                            />
                                        )}
                                    />
                                ),
                                align: 'top',
                                disabled: !me.hasWriteAccessToFeature('scene.background'),
                            },
                        ]}
                    />
                </>
            )}
        </Dialog>
    );
};

ScenePanorama.defaultProps = {
    scene: null,
    challenge: null,
    parentSceneAudioBackgroundId: '',
};

ScenePanorama.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    scene: PropTypes.object,
    buttons: PropTypes.array.isRequired,
    projectButtons: PropTypes.array.isRequired,
    projectId: PropTypes.string.isRequired,
    scenes: PropTypes.array.isRequired,
    type: PropTypes.string.isRequired,
    parentSceneId: PropTypes.string.isRequired,
    challenge: PropTypes.object,
    projectFiles: PropTypes.array.isRequired,
    sceneFiles: PropTypes.array.isRequired,
    project: PropTypes.object.isRequired,
    parentSceneAudioBackgroundId: PropTypes.string,
};

export default ScenePanorama;
