import { useMutation } from 'apollo-augmented-hooks';
import { ROLE } from '../queries/admins';
var mutation = "\n    mutation createRole($input: CreateRoleInput!) {\n        createRole(input: $input) {\n            ".concat(ROLE, "\n            customer {\n                id\n            }\n        }\n    }\n");
export default (function () {
    var mutate = useMutation(mutation)[0];
    return function (input) { return (mutate({
        input: input,
        modifiers: [{
                fields: {
                    customers: function (_a) {
                        var includeIf = _a.includeIf;
                        return includeIf(true);
                    },
                },
            }, {
                cacheObject: function (item) { return item.customer; },
                fields: {
                    roles: function (_a) {
                        var includeIf = _a.includeIf, item = _a.item;
                        return includeIf(!!item.customer);
                    },
                },
            }],
    })); };
});
