import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { apiResponseToFormState } from 'helpers/form';
import MeContext from 'contexts/me';
import useCreateQuota from 'hooks/graphql/mutations/createQuota';
import useUpdateQuota from 'hooks/graphql/mutations/updateQuota';
import EditingAdmin from 'EditingAdmin';
import TextField from 'cms/atoms/TextField';
import Switch from 'cms/atoms/Switch';
import KeyValueTable from 'cms/molecules/KeyValueTable';
import Dialog from 'cms/molecules/Dialog';

const defaultValues = {
    id: undefined,
    customerId: undefined,
    name: '',
    subjects: {
        _map: () => ({
            id: undefined,
            name: '',
            active: false,
            value: 0,
        }),
    },
};

const Quota = (props) => {
    const me = useContext(MeContext);
    const createQuota = useCreateQuota();
    const updateQuota = useUpdateQuota();

    const quotaWithSubjects = {
        ...props.quota,
        subjects: props.quota?.subjects || props.quotaSubjects,
    };
    const [state, setState] = useState(apiResponseToFormState(quotaWithSubjects, defaultValues));
    const validators = [{
        name: 'name',
        message: () => 'Bitte gib einen Namen ein.',
        isValid: (value) => !!value.trim(),
    },
    ...state.subjects.reduce((result, current, index) => [
        ...result, {
            name: `subjects.${index}.value`,
            message: () => 'Bitte gib einen Wert ein.',
            isValid: (value) => !current.active || value >= 0,
        },
    ], []),
    ];

    useEffect(() => {
        if (props.isOpen) {
            setState(apiResponseToFormState(quotaWithSubjects, defaultValues));
        }
    }, [props.isOpen]);

    const save = async (values) => {
        const mutation = props.quota ? updateQuota : createQuota;

        await mutation({
            id: values.id,
            customerId: values.id ? undefined : props.customerId,
            name: values.name.trim(),
            subjects: values.subjects.map((subject) => ({
                id: subject.id,
                name: subject.name,
                active: subject.active,
                value: parseInt(subject.value, 10),
            })),
        });

        props.onClose();
    };

    const renderSubject = (subject, index, onChangeByEvent, errors) => (
        <>
            <Switch
                name={`subjects.${index}.active`}
                checked={subject.active}
                onChange={onChangeByEvent}
                label="Aktiv"
                disabled={!me.hasWriteAccessToFeature('customer.quotas')}
            />
            <TextField
                name={`subjects.${index}.value`}
                onChange={onChangeByEvent}
                label="Kontingent"
                type="number"
                disabled={!me.hasWriteAccessToFeature('customer.quotas') || !subject.active}
                fullWidth={false}
                error={errors[`subjects.${index}.value`]}
                value={subject.value.toString()}
            />
        </>
    );

    return (
        <Dialog
            title={props.quota ? 'Kontingent bearbeiten' : 'Neues Kontingent erstellen'}
            isOpen={props.isOpen}
            onClose={props.onClose}
            onConfirm={me.hasWriteAccessToFeature('customer.quotas') ? save : props.onClose}
            confirmLabel={me.hasWriteAccessToFeature('customer.quotas') ? 'Speichern' : 'Fertig'}
            onChange={setState}
            values={state}
            validators={validators}
        >
            {({ errors, onChangeByEvent }) => (
                <>
                    {props.quota && (
                        <EditingAdmin name={`quota-${props.quota.id}`} />
                    )}
                    <KeyValueTable
                        items={[
                            {
                                key: 'Name',
                                value: (
                                    <TextField
                                        name="name"
                                        value={state.name}
                                        onChange={onChangeByEvent}
                                        error={errors.name}
                                        disabled={!me.hasWriteAccessToFeature('customer.quotas')}
                                    />
                                ),
                            },
                            ...state.subjects.map((subject, index) => ({
                                key: props.quotaSubjects
                                    .find(({ name }) => name === subject.name)
                                    .label,
                                value: renderSubject(subject, index, onChangeByEvent, errors),
                            })),
                        ]}
                    />
                </>
            )}
        </Dialog>
    );
};

Quota.defaultProps = {
    quota: null,
};

Quota.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    customerId: PropTypes.string.isRequired,
    quota: PropTypes.object,
    quotaSubjects: PropTypes.array.isRequired,
};

export default Quota;
