import React, { useEffect } from 'react';
import pdfSupport from 'helpers/pdfSupport';
import PropTypes from 'prop-types';
import useTracking from 'hooks/useTracking';
import useTranslate from 'hooks/useTranslate';
import mediaUrl from 'helpers/mediaUrl';
import {
    TRACKING_SHOW_DOWNLOADS, TRACKING_DOWNLOAD_FILE, TRACKING_VIEW_FILE,
} from 'helpers/tracking';
import Download from 'Download';
import Button from 'web/atoms/Button';
import { saveAs } from 'file-saver';
import Image from 'Image';
import InnerHTML from 'InnerHTML';
import Icon from 'Icon';

const DownloadContent = (props) => {
    const tracking = useTracking();
    const t = useTranslate();

    useEffect(() => {
        // TRACKCORE:
        //  event:'showDownloads', resource: props.title, data: {scene: props.sceneName, customer: customerId}
        //  SCENE CONTENT: download button clicked
        // console.log('%cTRACKCORE: showDownloads', 'background: #00a8ff; font-weight: bold;', props.title);
        tracking.trackCoreEvent({
            action: 'showDownloads',
            resourceType: 'title',
            resourceName: props.title,
        });
        tracking.trackEvent(props.sceneName, TRACKING_SHOW_DOWNLOADS, props.title);
    }, []);

    const onDownloadHandler = (download, performDownload) => () => {
        // TRACKCORE:
        //  event:'downloadFile', resource: t(download.file).filename, data: {scene: props.sceneName, customer: customerId}
        //  SCENE CONTENT: download button clicked --> click on download button
        console.log('%cTRACKCORE: downloadFile', 'background: #00a8ff; font-weight: bold;', t(download.file).filename);
        tracking.trackCoreEvent({
            action: 'downloadFile',
            resourceType: 'filename',
            resourceName: t(download.file).filename,
        });
        tracking.trackEvent(props.sceneName, TRACKING_DOWNLOAD_FILE, t(download.file).filename);
        performDownload();
    };

    const onViewHandler = (download, performDownload) => () => {
        // TRACKCORE:
        //  event:'viewFile', resource: t(download.file).filename, data: {scene: props.sceneName, customer: customerId}
        //  SCENE CONTENT: download button clicked --> view button clicked
        console.log('%cTRACKCORE: viewFile', 'background: #00a8ff; font-weight: bold;', t(download.file).filename);
        tracking.trackCoreEvent({
            action: 'viewFile',
            resourceType: 'filename',
            resourceName: t(download.file).filename,
        });
        tracking.trackEvent(props.sceneName, TRACKING_VIEW_FILE, t(download.file).filename);
        performDownload();
    };

    const isViewable = (download) => {
        const mimeType = t(download.file).mimeType;
        if (!mimeType) return false;
        const isImage = mimeType.startsWith('image/');
        const isPdf = mimeType === 'application/pdf';
        return isImage || (pdfSupport() && isPdf);
    };

    const renderDownloadItem = (download) => (
        <React.Fragment key={download.id}>
            {download.thumbnail && renderThumbnail(download)}
            {renderFileInfo(download)}
            {isViewable(download) && typeof props.onViewDocument === 'function' && renderActionView(download)}
            {renderActionDownload(download)}
            <div className="web-downloads__divider" />
        </React.Fragment>
    );

    const renderThumbnail = (download) => (
        <Image
            url={mediaUrl(download.thumbnail, 'standard')}
            className="web-downloads__thumb"
        />
    );

    const renderFileInfo = (download) => (
        <div
            className="web-downloads__info"
        >
            <div className="web-downloads__info__label">
                {t(download.label) || t(download.file).filename}
            </div>
            {t(download.description) && (
                <div className="web-downloads__info__description">
                    <InnerHTML allowedTags={['br']}>{t(download.description)}</InnerHTML>
                </div>
            )}
        </div>
    );

    const renderActionView = (download) => (
        <Download
            file={t(download.file)}
            onDownload={(blob) => props.onViewDocument(
                t(download.label) || t(download.file).filename,
                t(download.file).mimeType,
                URL.createObjectURL(blob)
            )}
            openInNewTab
        >
            {(performDownload) => (
                <div className="web-downloads__view">
                    <Button secondary onClick={onViewHandler(download, performDownload)}>
                        <Icon type="view" /> {t('web.downloads.view')}
                    </Button>
                </div>
            )}
        </Download>
    );

    const renderActionDownload = (download) => (
        <Download
            file={t(download.file)}
            onDownload={(blob) => saveAs(blob, t(download.file).filename)}
        >
            {(performDownload) => (
                <div className="web-downloads__download">
                    <Button secondary onClick={onDownloadHandler(download, performDownload)}>
                        <Icon type="download" /> {t('web.downloads.download')}
                    </Button>
                </div>
            )}
        </Download>
    );
    return (
        <div className="web-downloads">
            {[...props.downloads].sort((a, b) => a.order - b.order).map(renderDownloadItem)}
        </div>
    );
};

DownloadContent.defaultProps = {
    onViewDocument: null,
};

DownloadContent.propTypes = {
    title: PropTypes.string.isRequired,
    downloads: PropTypes.array.isRequired,
    sceneName: PropTypes.string.isRequired,
    onViewDocument: PropTypes.func,
};
export default DownloadContent;
