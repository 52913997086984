import React from 'react';
import PropTypes from 'prop-types';
import { Editor } from '@tinymce/tinymce-react';

const TINY_MCE_API_KEY = 'vlm2lemntolja0l4cuzovz21u8mckcn40zbx2iu9104fg8vl';

const VisualEditor = (props) => {
    const imageList = [...props.files]
        .sort((a, b) => (
            a.file.filename.localeCompare(b.file.filename)
        ))
        .map(({ file }) => ({
            title: file.filename,
            value: `[image:${file.id}]`,
        }));

    return (
        <Editor
            apiKey={TINY_MCE_API_KEY}
            init={{
                language: 'de',
                plugins: [
                    'advlist autolink autoresize charmap code emoticons fullscreen help hr image link lists media paste preview searchreplace table visualblocks visualchars',
                ],
                valid_elements: '*[*]', // allow scripts, styles, etc.
                custom_elements: 'style',
                menubar: 'edit view insert format table help',
                toolbar: 'undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist',
                image_list: imageList,
            }}
            onFocus={props.onFocus}
            onBlur={props.onBlur}
            value={props.value}
            onEditorChange={props.onChange}
        />
    );
};

VisualEditor.defaultProps = {
    files: null,
    onFocus: null,
    onBlur: null,
};

VisualEditor.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    files: PropTypes.array,
};

export default VisualEditor;
