import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Icon from 'Icon';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import StylingContext from 'contexts/styling';

const Help = (props) => {
    const styling = useContext(StylingContext);

    const renderTooltip = (tooltipProps) => (
        <Tooltip {...tooltipProps}>
            {props.children}
        </Tooltip>
    );

    return (
        <OverlayTrigger
            placement="bottom"
            delay={{ show: 100, hide: 200 }}
            overlay={renderTooltip}
        >
            <button
                type="button"
                aria-label={props.children}
                className="ui-web-help__button"
                style={{ color: styling.textColor }}
            >
                <Icon type="help" />
            </button>
        </OverlayTrigger>
    );
};

Help.propTypes = {
    children: PropTypes.string.isRequired,
};

export default Help;
