import React, { useEffect } from 'react';
import { TRACKING_SHOW_CONTACT } from 'helpers/tracking';
import useTracking from 'hooks/useTracking';
import useTranslate from 'hooks/useTranslate';
import Image from 'Image';
import Icon from 'Icon';
import InnerHTML from 'InnerHTML';
import mediaUrl from 'helpers/mediaUrl';
import PropTypes from 'prop-types';

const ContactContent = (props) => {
    const t = useTranslate();
    const tracking = useTracking();
    const iconMap = {
        PHONE: 'phone',
        FAX: 'fax',
        EMAIL: 'email',
        WEBSITE: 'language',
        TIMES: 'schedule',
        VIDEOCONFERENCE: 'videocam',
        ADDRESS: 'business',
        LINKEDIN: 'linkedin',
        XING: 'xing',
        FACEBOOK: 'facebook',
        INSTAGRAM: 'instagram',
        TWITTER: 'twitter',
    };

    useEffect(() => {
        // TRACKCORE:
        //  event:'showContact', data: {scene: props.sceneName, customer: customerId}
        tracking.trackCoreEvent({
            action: 'showContact',
            resourceType: '',
            resourceName: '',
        });
        tracking.trackEvent(props.sceneName, TRACKING_SHOW_CONTACT);
    }, []);

    const sortByOrder = (a, b) => a.order - b.order;
    const sections = [...props.sections]
        .map((section) => ({ ...section, entries: [...section.entries].sort(sortByOrder) }))
        .sort(sortByOrder);

    const renderEntryBlock = (element, index, type) => (
        <div key={index} className="contact-section__row">
            {iconMap[type] !== undefined ? (
                <div className="contact-dialog__icon-text-wrapper">
                    <span className="contact-dialog__icon">
                        <Icon type={iconMap[type]} />
                    </span> {element}
                </div>
            ) : element}
        </div>
    );

    const renderEntry = ({ value, type }, index) => {
        const localizedValue = t(value);
        if (!localizedValue) {
            return null; // TODO make value not nullable in API?
        }
        switch (type) {
            case 'TEXT':
            case 'TIMES':
            case 'ADDRESS':
                return renderEntryBlock(<InnerHTML>{localizedValue}</InnerHTML>, index, type);
            case 'TITLE':
                return renderEntryBlock(<div className="contact-dialog__title">{localizedValue}</div>, index, type);
            case 'EMAIL':
                return renderEntryBlock(<a href={`mailto:${localizedValue}`}>{localizedValue}</a>, index, type);
            case 'WEBSITE':
            case 'VIDEOCONFERENCE':
            case 'LINKEDIN':
            case 'XING':
            case 'FACEBOOK':
            case 'INSTAGRAM':
            case 'TWITTER':
                return renderEntryBlock(<a href={localizedValue} target="_blank" rel="noreferrer">{localizedValue}</a>, index, type);
            default:
                return renderEntryBlock(localizedValue, index, type);
        }
    };

    const renderSection = (section, index) => {
        const image = t(section.image);
        return (
            <div className="contact-section__row" key={index}>
                {image && (
                    <div className="contact-section__column">
                        <Image
                            className="contact-section__image"
                            url={mediaUrl(image, 'standard')}
                        />
                    </div>
                )}
                <div className="contact-section__column">
                    {[...section.entries].sort((a, b) => a.order - b.order).map(renderEntry)}
                </div>
            </div>
        );
    };

    return (
        <>
            {[...sections].sort((a, b) => a.order - b.order).map(renderSection)}
        </>
    );
};

ContactContent.propTypes = {
    sections: PropTypes.array.isRequired,
    sceneName: PropTypes.string.isRequired,
};

export default ContactContent;
