import axios from 'axios';
import { useContext } from 'react';
import { useLocalStorage } from '@rehooks/local-storage';
import AuthContext from '../contexts/auth';

export default () => {
    const [authToken] = useLocalStorage(useContext(AuthContext));

    return (options) => {
        const {
            url,
            method,
            data,
            headers,
            responseType,
        } = options;

        return axios[method.toLowerCase()](url, data, {
            headers: {
                Authorization: `Bearer ${authToken}`,
                ...headers,
            },
            responseType,
        });
    };
};
